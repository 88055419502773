import { FC } from 'react';

const Icon: FC = () => (
  <svg
    fill="none"
    height="64"
    viewBox="0 0 65 64"
    width="65"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6.01801 36.0336C6.01787 36.0682 6.01781 36.1028 6.01781 36.1374C6.0178 50.7631 17.8742 62.6195 32.4999 62.6195C47.1255 62.6195 58.9819 50.7631 58.9819 36.1374C58.9819 36.1028 58.9819 36.0682 58.9817 36.0336L6.01801 36.0336Z"
      fill="#007F79"
      fillOpacity="0.5"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12.3853 15.5343C12.3852 15.5604 12.3852 15.5864 12.3852 15.6125C12.3852 26.7216 21.3909 35.7273 32.5 35.7273C43.609 35.7273 52.6147 26.7216 52.6147 15.6125C52.6147 15.5864 52.6147 15.5604 52.6146 15.5343L12.3853 15.5343Z"
      fill="#007F79"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M19.2976 2.1313C19.2975 2.14857 19.2975 2.16586 19.2975 2.18315C19.2975 9.47487 25.2086 15.386 32.5003 15.386C39.792 15.386 45.7031 9.47488 45.7031 2.18315C45.7031 2.16586 45.7031 2.14857 45.703 2.1313L19.2976 2.1313Z"
      fill="#EFA53A"
      fillRule="evenodd"
    />
  </svg>
);

export default Icon;
