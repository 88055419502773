import { useState } from 'react';

import * as Sentry from '@sentry/react';

const useLocalStorage = <T extends unknown>(
  key: string,
  initialValue: T,
): UseLocalStorageState<T> => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      const valueToStore = item ? JSON.parse(item) : initialValue;

      localStorage.setItem(key, JSON.stringify(valueToStore));

      return valueToStore;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      localStorage.setItem(key, JSON.stringify(initialValue));
      return initialValue;
    }
  });

  const getValueFromLocalStorage = (): T => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : storedValue;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      return storedValue;
    }
  };

  const setValue = (value: T): void => {
    try {
      setStoredValue(value);
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  const removeValue = (): void => {
    try {
      setStoredValue(initialValue);
      localStorage.removeItem(key);
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  return { getValueFromLocalStorage, storedValue, setValue, removeValue };
};

export default useLocalStorage;
