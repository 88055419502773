import { ChatUser, MemberMetaData } from '@equip.health/ui';
import { User } from '@sendbird/chat';
import { GroupChannel, Member } from '@sendbird/chat/groupChannel';
import { isNil } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { DateTime } from 'luxon';

import {
  ADMIN_MESSAGE_TYPES,
  ALLOWED_CHANNEL_PREFIXES,
  FROZEN,
  FROZEN_MESSAGE,
  GROUP_CHANNEL_URL_PREFIXES,
  INFORMATIONAL_MODAL_GROUP_CHANNEL_PREFIXES,
  UNFROZEN,
  UNFROZEN_MESSAGE,
  USER_ROLES,
} from '~/lib/constants/chat/chat.constants';
import {
  ChatChannelMember,
  ChatMessage,
  GroupChannelMetadata,
  SplitGroupChannels,
} from '~/lib/types/chat';
import { ChannelId } from '~/lib/util/chat/chatChannel.util';
import StringUtil from '~/lib/util/string.util';

const ChatUtil = {
  // a. if created date is 0-2 days ago, returns relative date (e.g. "yesterday", "today")
  // b. else if created date was in the same year, returns "{dayOfWeek}, {month} {day}"
  // c. else if created date was in any other year, returns "{month} {day}, {year}"
  calculateRelativeDate: (
    createdDate: number,
    ignoreWeekDay = false,
  ): string => {
    const incomingDate = DateTime.fromMillis(createdDate);
    const today = DateTime.now();

    if (
      [
        today.toLocaleString(),
        today.minus({ days: 1 }).toLocaleString(),
      ].includes(incomingDate.toLocaleString())
    ) {
      return incomingDate.toRelativeCalendar({ unit: 'days' });
    }

    if (incomingDate.year === today.year) {
      return incomingDate
        .toFormat(ignoreWeekDay ? 'LLLL d' : 'cccc, LLLL d')
        .replace(today.year.toFixed(), '');
    }

    return incomingDate.toFormat('LLLL d, yyyy');
  },

  excludeCurrentUserId: (
    memberIds: string[],
    currentUserId: string,
  ): string[] => {
    return memberIds.filter((x) => x !== currentUserId);
  },

  findMessageIndex: (
    messages: ChatMessage[],
    messageIdToFind: number,
  ): number => {
    if (messages)
      return messages.findIndex((x) => x.messageId === messageIdToFind);

    return -1;
  },

  getAllMentionedUserIds: (messages: ChatMessage[]): string[] => {
    const output: string[] = [];

    if (messages) {
      messages.forEach((message) =>
        output.push(...(message.allMentionedUserIds ?? [])),
      );

      return [...new Set(output)];
    }

    return [];
  },

  getChannelMembersToRender: (
    members: ChatChannelMember[],
    max: number,
  ): ChatChannelMember[] => {
    if (!members?.length || !max) return [];

    const extensionSymbol: ChatChannelMember = cloneDeep(members[0]);
    extensionSymbol.isExtension = true;

    return members.length > max
      ? members.slice(0, max).concat(extensionSymbol)
      : members;
  },

  getMessagesLength: (items: Array<ChatMessage>): number => {
    return items ? items.length : 0;
  },

  getMentionedSegments: (item: ChatMessage) => {
    const mentionedUserNames = (item.mentionedUsers ?? []).map(
      (user) => `@${user.nickname}`,
    );

    let processingMessage = item.message ?? '';
    const segments = [];

    while (processingMessage.length > 0) {
      const currentMentionedUser = mentionedUserNames.find((userName) =>
        processingMessage.startsWith(userName),
      );

      if (currentMentionedUser?.trim()?.length) {
        processingMessage = processingMessage.substring(
          currentMentionedUser.length,
        );

        if (currentMentionedUser?.trim().length)
          segments.push(currentMentionedUser.trim());
      } else {
        const firstWord = processingMessage.split(' ')[0];

        processingMessage =
          processingMessage.substring(firstWord.length)?.trim() ?? '';

        if (firstWord?.trim()?.length) segments.push(firstWord.trim());
      }
    }

    return segments;
  },

  getRepliesText: (replyCount?: number): string => {
    let output = '';

    switch (replyCount ?? 0) {
      case 0:
        break;

      case 1:
        output = `${replyCount} reply`;
        break;

      default:
        output = `${replyCount} replies`;
        break;
    }

    return output;
  },

  getUrl: (input: string): string => {
    try {
      const url = new URL(input);

      return url.href;
    } catch {
      return `https://${input}`;
    }
  },

  getUserIds: (channelMembers: ChatUser[]): string[] => {
    return (channelMembers ?? []).map((x) => x.userId);
  },

  isChannelMember: (memberIds: string[], currentUserId: string): boolean => {
    return (memberIds ?? []).includes(currentUserId);
  },

  isValidUrl: (urlString: string): boolean => {
    try {
      const url = new URL(urlString);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (_) {
      return false;
    }
  },

  mergeMessages: (
    oldMessages: ChatMessage[],
    newMessages: ChatMessage[],
  ): ChatMessage[] => {
    const oldMessageIds = (oldMessages ?? []).map((x) => x.messageId);
    const messages = (newMessages ?? []).filter(
      (message) => !oldMessageIds.includes(message.messageId),
    );
    return [...new Set((oldMessages ?? []).concat(messages))].sort(
      (a, b) => a.createdAt - b.createdAt,
    );
  },

  replaceAll: (
    originalString: string,
    findString: string,
    replaceWith: string,
  ): string => {
    const searchResults = (originalString ?? '').match(
      new RegExp(`${findString}`, 'gi'),
    );

    let output: string = originalString ?? '';
    if (searchResults) {
      for (const result of searchResults) {
        output = StringUtil.replaceAll(
          output,
          result,
          replaceWith.replace('#', result),
        );
      }
    }

    return output;
  },

  sortByCreatedDate: (messages: ChatMessage[]): ChatMessage[] => {
    if (messages) return messages.sort((a, b) => a.createdAt - b.createdAt);

    return [];
  },

  splitChannels: (
    channels: GroupChannel[],
    currentUserId: string,
  ): SplitGroupChannels => {
    const myChannels = [];
    const readOnlyChannels = [];

    (channels ?? []).forEach((channel) => {
      if (
        ChatUtil.isChannelMember(
          (channel.members ?? []).map((item) => item.userId),
          currentUserId,
        )
      ) {
        myChannels.push(channel);
      } else {
        readOnlyChannels.push(channel);
      }
    });

    return { myChannels, readOnlyChannels };
  },
};

export const filterChannelMembers = (
  userIds: string[],
  channelMembers: ChatUser[],
): string[] => {
  return userIds.filter((userId: string) =>
    channelMembers.some(
      (channelMember: ChatUser) => channelMember.userId === userId,
    ),
  );
};

/// Extract user from admin messages if the message contains user actions of joining or leaving the channel
export const getUserFromAdminMessage = (message: string): User => {
  let output = '';
  const metaData: MemberMetaData = { providerType: '' };

  const USER_ACTIONS = [ADMIN_MESSAGE_TYPES.JOINED, ADMIN_MESSAGE_TYPES.LEFT];

  if (message) {
    for (const action of USER_ACTIONS) {
      if (message.includes(action)) {
        output = message.replace(` ${action}`, '');
      }
    }
  }

  return {
    friendName: output,
    isActive: true,
    metaData,
    nickname: output,
    userId: '0',
  } as User;
};

export const getAdminMessageType = (message: string): ADMIN_MESSAGE_TYPES => {
  let output: ADMIN_MESSAGE_TYPES = ADMIN_MESSAGE_TYPES.UNKNOWN;
  if (message) {
    const segments = message.split(' ');
    for (const action of Object.values(ADMIN_MESSAGE_TYPES)) {
      if (segments.some((x) => x.startsWith(action))) output = action;
    }
  }
  return output;
};

export const getAdminMessageText = (
  message: string,
  senderNickname: string,
): string => {
  if (!message) return null;

  if (message.endsWith(UNFROZEN)) return UNFROZEN_MESSAGE;
  if (message.endsWith(FROZEN)) return FROZEN_MESSAGE;

  if (senderNickname) return message.replace(senderNickname, 'has');

  return message;
};

export const isIndividualChannel = (channelUrl: string): boolean => {
  if (channelUrl) {
    return !GROUP_CHANNEL_URL_PREFIXES.some((prefix) =>
      channelUrl.startsWith(prefix),
    );
  }

  return false;
};

export const isInformationalModalGroupChannel = (
  channelUrl: string,
): boolean => {
  if (channelUrl) {
    const groupChannels = INFORMATIONAL_MODAL_GROUP_CHANNEL_PREFIXES.some(
      (prefix) => channelUrl?.startsWith(prefix),
    );

    return groupChannels;
  }

  return false;
};

export const isAdmissionsRelatedChannel = (channelUrl: string): boolean => {
  const patientToAdmissionsPrefix = ChannelId.PT_ADMIT;
  const proxyToAdmissionsPrefix = ChannelId.PR_AS;

  if (isNil(channelUrl)) {
    return false;
  }

  return (
    channelUrl?.startsWith(patientToAdmissionsPrefix) ||
    channelUrl?.startsWith(proxyToAdmissionsPrefix)
  );
};

export const groupedChatChannelsByGroupType = (
  channels: GroupChannel[],
): {
  admissionsChannel: GroupChannel[];
  directMessageChannels: GroupChannel[];
  groupChannels: GroupChannel[];
} => {
  const admissionsChannel: GroupChannel[] = [];
  const groupChannels: GroupChannel[] = [];
  const directMessageChannels: GroupChannel[] = [];

  channels?.forEach((channel) => {
    if (
      isIndividualChannel(channel.url) &&
      !isAdmissionsRelatedChannel(channel.url)
    ) {
      directMessageChannels.push(channel);
    }

    if (isInformationalModalGroupChannel(channel.url)) {
      groupChannels.push(channel);
    }

    if (isAdmissionsRelatedChannel(channel.url)) {
      admissionsChannel.push(channel);
    }
  });

  return {
    admissionsChannel,
    directMessageChannels,
    groupChannels,
  };
};

export const getInformationalModalGroupChannelDescription = (
  channelUrl: string,
  patientName: string,
  isPatient: boolean,
): string => {
  const {
    PR_FAMILY,
    PR_THERAPIST,
    PR_DIETITIAN,
    PR_MEDICAL,
    PR_PSYCH,
    PR_CCT,
    PT_CCT,
    PT_PR_CCT,
  } = ChannelId;
  if (channelUrl?.startsWith(PR_CCT) || channelUrl?.startsWith(PT_CCT)) {
    return 'Your entire provider team';
  }
  if (channelUrl?.startsWith(PT_PR_CCT)) {
    if (isPatient) {
      return `Your entire provider team and supports`;
    }
    return `Your entire provider team, ${patientName} and supports`;
  }
  if (!isPatient) {
    if (channelUrl?.startsWith(PR_FAMILY)) {
      return 'All family mentors and supports';
    }
    if (channelUrl?.startsWith(PR_THERAPIST)) {
      return 'All therapists and supports';
    }
    if (channelUrl?.startsWith(PR_DIETITIAN)) {
      return 'All dietitians and supports';
    }
    if (channelUrl?.startsWith(PR_MEDICAL)) {
      return 'Your entire medical team and supports';
    }
    if (channelUrl?.startsWith(PR_PSYCH)) {
      return 'Your entire psychiatry team and supports';
    }
  }

  return undefined;
};

// a. if created date is 0-2 days ago, returns relative date (e.g. "yesterday", "today")
// b. else if message within 7 days then display day of week abbreviated Mon. Tue. , etc...
// c. else if message older than 7 days display mm/dd/yy
export const calculateChannelRelativeDate = (createdDate: number): string => {
  if (createdDate) {
    const incomingDate = DateTime.fromMillis(createdDate);
    const today = DateTime.now();

    if (
      [
        today.toLocaleString(),
        today.minus({ days: 1 }).toLocaleString(),
      ].includes(incomingDate.toLocaleString())
    ) {
      return incomingDate.toRelativeCalendar({ unit: 'days' });
    }

    if (
      [
        today.minus({ days: 2 }).toLocaleString(),
        today.minus({ days: 3 }).toLocaleString(),
        today.minus({ days: 4 }).toLocaleString(),
        today.minus({ days: 5 }).toLocaleString(),
        today.minus({ days: 6 }).toLocaleString(),
        today.minus({ days: 7 }).toLocaleString(),
      ].includes(incomingDate.toLocaleString())
    ) {
      return incomingDate.toFormat('ccc');
    }

    return incomingDate.toFormat('LL/dd/yy');
  }

  return '';
};

const getPatientChannelPrefixes = (isInTreatment: boolean) => {
  if (isInTreatment) {
    return ALLOWED_CHANNEL_PREFIXES.inTreatment;
  }
  return ALLOWED_CHANNEL_PREFIXES.admissions;
};

const getIsChatChannelPatientInTreatment = (
  channel: GroupChannel,
  patientsInTreatment: string[],
): boolean => {
  const { patientId } = channel.cachedMetaData as GroupChannelMetadata;
  return patientsInTreatment.includes(patientId);
};

export const filterUserChannels = (
  channels: GroupChannel[],
  userId: string,
  patientsInTreatment: string[],
): GroupChannel[] => {
  if (channels && userId) {
    return channels.filter((channel) => {
      const isCurrentUserMember = channel?.members?.some(
        (member) => member?.userId === userId,
      );

      if (!isCurrentUserMember) {
        return false;
      }

      const isChatPatientInTreatment = getIsChatChannelPatientInTreatment(
        channel,
        patientsInTreatment,
      );
      const isAllowedChannel = getPatientChannelPrefixes(
        isChatPatientInTreatment,
      ).some((chn) => channel.url.startsWith(chn));

      return isAllowedChannel;
    });
  }

  return [];
};

export const sortChannelMembers = (
  members: Member[],
  currentUserId: string,
): Member[] => {
  const providers = [];
  const supports = [];
  const patient = [];
  const currentUser = [];

  (members ?? []).forEach((member) => {
    const memberId = (member as unknown as { externalId: string })?.externalId;
    const memberMetaData = member.metaData as { userType: USER_ROLES };

    if (memberId === currentUserId) currentUser.push(member);
    else if (memberMetaData?.userType === USER_ROLES.PROVIDER)
      providers.push(member);
    else if (
      [USER_ROLES.PROXY, USER_ROLES.SUPPORT].includes(memberMetaData?.userType)
    )
      supports.push(member);
    else if (memberMetaData?.userType === USER_ROLES.PATIENT)
      patient.push(member);
  });

  return [...providers, ...supports, ...patient, ...currentUser];
};
export default ChatUtil;

export const getPatientsWithUnreadMessages = (
  channels: GroupChannel[],
): string[] => {
  if (!channels) return null;
  const patientIds = channels?.reduce<string[]>((result, channel) => {
    const { unreadMessageCount, cachedMetaData } = channel;
    const { patientId } = cachedMetaData as GroupChannelMetadata;

    if (unreadMessageCount === 0 || result.includes(patientId)) {
      return result;
    }
    return [...result, patientId];
  }, []);

  return patientIds;
};

export const removeUUIDFromFilename = (fileName: string): string => {
  const uuidPattern = /[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}-/; // UUID pattern
  const match = fileName?.match(uuidPattern);

  if (match) {
    // Replace UUID with an empty string
    return fileName.replace(match[0], '');
  }

  // If not enough parts or no UUID found, keep the original filename
  return fileName;
};

export const getDocumentExternalIdFromFileName = (filename: string) => {
  if (filename) {
    const result = filename.match(
      /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/gm,
    );
    if (result?.length > 0) {
      return result[0];
    }
  }
  return '';
};

export const getUserTypeFromChatUser = (user?: User): EquipUserType => {
  const memberMetaData = user?.metaData as { userType: USER_ROLES };

  if (memberMetaData?.userType === USER_ROLES.PROVIDER) {
    return 'provider';
  }

  if (
    [USER_ROLES.PROXY, USER_ROLES.SUPPORT].includes(memberMetaData?.userType)
  ) {
    return 'support';
  }

  if (memberMetaData?.userType === USER_ROLES.PATIENT) {
    return 'patient';
  }

  return undefined;
};
