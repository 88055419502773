import { FC } from 'react';

import ErrorView from '~/components/common/ErrorView';

const PageNotFound: FC = () => {
  return (
    <ErrorView errorMessage="Page not found! Please check that the URL entered is correct." />
  );
};

export default PageNotFound;
