import { FC } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  EquipUIFireflyV2Theme,
  FAIconName,
  FireflyButton,
  Icon,
  Modal,
  Text,
} from '@equip.health/ui';

const { neutral } = EquipUIFireflyV2Theme.colors;

const ZoomErrorModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  onRetry: () => void;
}> = ({ isOpen, onClose, onRetry }) => {
  const onTryAgain = () => {
    onRetry();
    onClose();
  };
  return (
    <Modal
      hideCloseButton
      hideHeader
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <ChakraFlex alignItems="center" direction="column" marginBottom="20px">
        <ChakraFlex
          alignItems="center"
          direction="row-reverse"
          justifyContent="space-between"
          marginBottom="4px"
          width="100%"
        >
          <ChakraBox
            height="32px"
            onClick={onClose}
            paddingBottom="8px"
            paddingTop="8px"
            role="button"
            width="32px"
          >
            <Icon
              color={neutral.secondary}
              name={FAIconName.timesSolid}
              size="lg"
            />
          </ChakraBox>
        </ChakraFlex>
        <Text color="neutral.primary" textAlign="center" variant="h3">
          Hmm... something went wrong
        </Text>
        <Text
          color="neutral.primary"
          marginBottom="24px"
          marginTop="12px"
          textAlign="center"
        >
          We were unable to connect to your appointment.
          <br /> Please try again, or message your provider to get help.
        </Text>
        <FireflyButton
          id="zoom-error-modal__try-again"
          onClick={onTryAgain}
          size="normal"
          variant="primary"
        >
          Try Again
        </FireflyButton>
      </ChakraFlex>
    </Modal>
  );
};

export default ZoomErrorModal;
