import { FC, useEffect, useMemo } from 'react';

import {
  AngleDown,
  AutoComplete,
  AutoCompleteOption,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

import { autoCompleteProps } from '~/lib/constants/inquiry.constants';
import {
  Enumerations,
  useEnumerationContext,
} from '~/lib/context/EnumerationContext';
import useFeatureFlags from '~/lib/hooks/useFeatureFlags';

type StateSelectProps = {
  label?: string;
  onSelect: (st: string) => void;
  state: string;
};

const { teal } = EquipUIFireflyV1Theme.colors;

const StateSelect: FC<StateSelectProps> = ({
  label = 'Your current state',
  onSelect,
  state,
}) => {
  const { fetchEnumerations, states } = useEnumerationContext();
  const { isInquiryV2Enabled } = useFeatureFlags();

  useEffect(() => fetchEnumerations([Enumerations.STATES]), []);

  const statesMenuOptions = useMemo<AutoCompleteOption[]>(
    () =>
      (states ?? []).map(
        ({ externalId, label }: { externalId: string; label: string }) => ({
          primaryText: label,
          value: isInquiryV2Enabled ? externalId : label,
        }),
      ),
    [states],
  );

  return (
    <AutoComplete
      {...autoCompleteProps}
      endIcon={<AngleDown fill={teal[100]} height="15px" width="15px" />}
      hideAvatar
      id="state"
      isRequired
      isTextInput
      marginBottom="16px"
      onSelect={({ value }) => onSelect(value)}
      options={statesMenuOptions}
      placeholder="Select..."
      selected={
        state ? [statesMenuOptions.find(({ value }) => value === state)] : []
      }
      title={label}
      toggleListOnFocus
    />
  );
};

export default StateSelect;
