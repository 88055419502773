import { FC } from 'react';

import { ChakraFlex, FAIconName, FireflyButton, Text } from '@equip.health/ui';

import { PatientObservationData } from '~/lib/types/vitals';
import { getObservationLabel } from '~/lib/util/vitals.util';

const TOP_LEVEL_ID = 'vitals__weight__summary';

const weightSummaryCount = 2;

interface VitalsSummaryProps {
  observations: PatientObservationData[];
  onHistoryClick: () => void;
}

const VitalsSummary: FC<VitalsSummaryProps> = ({
  observations,
  onHistoryClick,
}) => {
  const shouldShowWeightHistory = observations?.length > weightSummaryCount + 1;

  if (observations?.length === 0) {
    return null;
  }

  return (
    <ChakraFlex
      alignItems="start"
      flexDirection="column"
      gridGap="16px"
      id={`${TOP_LEVEL_ID}__container`}
    >
      {observations?.slice(1, 3).map((vital) => {
        return (
          <ChakraFlex
            color="black.60"
            gridGap="4px"
            id={`${TOP_LEVEL_ID}__${vital.observation.externalId}`}
            key={vital.observation.externalId}
          >
            <Text
              id={`${TOP_LEVEL_ID}__${vital.observation.externalId}__value`}
              variant="captionMedium"
            >
              {vital.observation.value}
            </Text>
            <Text
              id={`${TOP_LEVEL_ID}__${vital.observation.externalId}__description`}
              variant="caption"
            >
              {getObservationLabel(vital)}
            </Text>
          </ChakraFlex>
        );
      })}
      {shouldShowWeightHistory && (
        <FireflyButton
          id={`${TOP_LEVEL_ID}__history`}
          onClick={onHistoryClick}
          rightIcon={FAIconName.chevronRightRegular}
          size="small"
          variant="tertiary"
        >
          See weight history
        </FireflyButton>
      )}
    </ChakraFlex>
  );
};

export default VitalsSummary;
