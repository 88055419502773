import { FC } from 'react';

const EquipBranding: FC<{ style?: Record<string, string> }> = ({ style }) => (
  <svg
    fill="none"
    height="31"
    style={style}
    viewBox="0 0 122 31"
    width="122"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1522_70313)">
      <rect
        fill="white"
        height="30"
        transform="translate(0 0.5)"
        width="121.579"
      />
      <path
        d="M7.16396 24.7078C7.15332 24.7215 7.14876 24.7275 7.1366 24.7503C6.98311 25.0155 7.17307 25.3914 7.47854 25.4247C7.50286 25.4277 7.52109 25.4277 7.55453 25.4277L19.2383 25.4202C19.2717 25.4202 19.2899 25.4202 19.3142 25.4171C19.6197 25.3838 19.8097 25.0079 19.6547 24.7427C19.6425 24.7215 19.6364 24.7139 19.6273 24.7003C18.217 22.7966 15.9496 21.5629 13.3934 21.5645C10.8372 21.566 8.57123 22.8027 7.16396 24.7078Z"
        fill="#007F79"
      />
      <path
        d="M0.498525 13.7023C0.481808 13.7007 0.47269 13.6992 0.448374 13.6992C0.141387 13.6992 -0.0896129 14.0508 0.0334858 14.3312C0.044124 14.354 0.0517226 14.3691 0.0699595 14.3979L5.91943 24.4843C5.93614 24.5131 5.94526 24.5283 5.96046 24.548C6.14283 24.795 6.5638 24.7708 6.71729 24.5055C6.72945 24.4843 6.73249 24.4767 6.74008 24.4616C7.68688 22.2912 7.62457 19.7162 6.34495 17.5095C5.06533 15.3027 2.85868 13.9645 0.500045 13.7007L0.498525 13.7023Z"
        fill="#007F79"
      />
      <path
        d="M6.7233 2.44363C6.71722 2.42848 6.71266 2.4209 6.70051 2.39968C6.54701 2.13445 6.12605 2.1102 5.94368 2.35724C5.92848 2.37695 5.92088 2.3921 5.90264 2.42242L0.0698946 12.5164C0.0531775 12.5467 0.044059 12.5603 0.0349406 12.5831C-0.0881581 12.8635 0.142842 13.2166 0.451349 13.2151C0.475664 13.2151 0.484783 13.2151 0.5015 13.212C2.86013 12.9453 5.06375 11.604 6.34033 9.39574C7.61691 7.1875 7.67618 4.61247 6.72482 2.44363H6.7233Z"
        fill="#007F79"
      />
      <path
        d="M19.6123 2.18867C19.6229 2.17502 19.6275 2.16896 19.6397 2.14623C19.7932 1.881 19.6032 1.50512 19.2977 1.47178C19.2734 1.46875 19.2552 1.46875 19.2217 1.46875L7.538 1.47633C7.50457 1.47633 7.48633 1.47633 7.46201 1.47936C7.15655 1.5127 6.96658 1.88857 7.12159 2.15381C7.13375 2.17502 7.13983 2.1826 7.14895 2.19624C8.55926 4.09985 10.8267 5.33356 13.3829 5.33053C15.9391 5.32901 18.205 4.09227 19.6123 2.18715V2.18867Z"
        fill="#007F79"
      />
      <path
        d="M26.2808 13.1947C26.2975 13.1962 26.3066 13.1977 26.331 13.1977C26.6379 13.1977 26.8689 12.8461 26.7458 12.5657C26.7352 12.543 26.7276 12.5278 26.7109 12.499L20.8599 2.41113C20.8432 2.38082 20.8341 2.36718 20.8189 2.34748C20.6365 2.10043 20.2155 2.12468 20.062 2.38992C20.0499 2.41113 20.0468 2.41871 20.0392 2.43387C19.0924 4.60422 19.1548 7.17925 20.4344 9.38598C21.714 11.5927 23.9207 12.931 26.2793 13.1947H26.2808Z"
        fill="#007F79"
      />
      <path
        d="M20.0529 24.4546C20.059 24.4698 20.0635 24.4773 20.0757 24.4986C20.2292 24.7653 20.6502 24.788 20.8325 24.541C20.8477 24.5213 20.8553 24.5061 20.8736 24.4758L26.7078 14.3803C26.7246 14.35 26.7337 14.3364 26.7428 14.3137C26.8659 14.0333 26.6349 13.6801 26.3264 13.6816C26.3021 13.6816 26.2929 13.6816 26.2762 13.6847C23.9176 13.9514 21.714 15.2927 20.4374 17.501C19.1608 19.7092 19.1016 22.2843 20.0529 24.4531V24.4546Z"
        fill="#007F79"
      />
      <path
        d="M13.3827 19.7574C16.8827 19.7574 19.72 16.9277 19.72 13.4373C19.72 9.94683 16.8827 7.11719 13.3827 7.11719C9.88276 7.11719 7.04541 9.94683 7.04541 13.4373C7.04541 16.9277 9.88276 19.7574 13.3827 19.7574Z"
        fill="#007F79"
      />
      <path
        d="M39.665 1.58789H56.0387V5.61335H44.2334V11.0029H54.6041V14.9616H44.2334V20.8483H56.0387V24.8737H39.665V1.58789Z"
        fill="#007F79"
      />
      <path
        d="M70.844 7.74431H75.1464V30.4967H70.844V22.3472C69.7772 24.0432 68.0766 25.2754 65.5082 25.2754C61.1056 25.2754 57.8047 21.45 57.8047 16.2939C57.8047 11.1378 61.1056 7.3457 65.5082 7.3457C68.0751 7.3457 69.7772 8.54304 70.844 10.2072V7.74582V7.74431ZM70.9109 16.3272C70.9109 13.0338 69.1434 11.1711 66.5766 11.1711C64.0098 11.1711 62.2074 13.0338 62.2074 16.3272C62.2074 19.6206 64.0083 21.45 66.5766 21.45C69.145 21.45 70.9109 19.6206 70.9109 16.3272Z"
        fill="#007F79"
      />
      <path
        d="M78.2832 17.9897V7.74414H82.6175V17.7229C82.6175 20.2843 83.9853 21.4498 85.853 21.4498C87.7208 21.4498 89.087 20.2858 89.087 17.7229V7.74414H93.4228V17.9897C93.4228 22.5805 90.1888 25.2737 85.853 25.2737C81.5172 25.2737 78.2832 22.5789 78.2832 17.9897Z"
        fill="#007F79"
      />
      <path
        d="M95.9592 3.21973C95.9592 1.62379 97.1933 0.625 98.7267 0.625C100.26 0.625 101.494 1.62379 101.494 3.21973C101.494 4.81566 100.262 5.78111 98.7267 5.78111C97.1917 5.78111 95.9592 4.84901 95.9592 3.21973ZM98.71 7.74382C99.8984 7.74382 100.86 8.70472 100.86 9.88993V24.8748H96.558V9.88993C96.558 8.70472 97.5215 7.74382 98.7084 7.74382H98.71Z"
        fill="#007F79"
      />
      <path
        d="M121.535 16.2939C121.535 21.45 118.267 25.2754 113.831 25.2754C111.264 25.2754 109.562 24.0447 108.495 22.3472V30.4967H104.193V7.74582H108.495V10.2072C109.562 8.54455 111.263 7.3457 113.831 7.3457C118.267 7.3457 121.535 11.1711 121.535 16.2939ZM117.132 16.3272C117.132 13.0338 115.331 11.1711 112.798 11.1711C110.264 11.1711 108.429 13.0338 108.429 16.3272C108.429 19.6206 110.23 21.45 112.798 21.45C115.366 21.45 117.132 19.6206 117.132 16.3272Z"
        fill="#007F79"
      />
    </g>
    <defs>
      <clipPath id="clip0_1522_70313">
        <rect
          fill="white"
          height="30"
          transform="translate(0 0.5)"
          width="121.579"
        />
      </clipPath>
    </defs>
  </svg>
);

export default EquipBranding;
