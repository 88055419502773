import { FC } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraLink,
  ChakraText,
  Download,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

import { RenderHTMLQuestion, styles } from './RenderHTMLQuestion';
import { QuestionNameConstant } from '~/lib/constants/safetyplan.constants';
import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';
import GeneratePDF from '~/lib/util/safetyplan.util';
import { parseTelephone } from '~/lib/util/survey.util';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { EVENT } from '~/lib/constants/analytics';

const { black } = EquipUIFireflyV1Theme.colors;
type LayoutProps = {
  definition: SurveyDefinition;
  jsonResult: Record<string, any>;
  patientName: string;
  surveyCompletionDate: string;
};

export const TelephoneLayout: FC<{ result: string }> = ({ result }) => {
  return (
    <ChakraLink
      color="teal.500"
      href={`tel:${result}`}
      {...styles.telephoneLink}
    >
      {parseTelephone(result)}
    </ChakraLink>
  );
};

const RenderAnser: FC<{
  isMobile: boolean;
  questionName: string;
  result: Record<string, any>;
}> = ({ isMobile, questionName, result }) => {
  if (questionName === QuestionNameConstant.sp_imminent_risk_statement) {
    return (
      <ChakraFlex flexDirection="column">
        <ul style={{ marginLeft: isMobile ? '24px' : null }}>
          <li>
            <ChakraText>
              Call{' '}
              <ChakraLink
                color="teal.500"
                href="tel:988"
                {...styles.telephoneLink}
              >
                911
              </ChakraLink>
            </ChakraText>
          </li>
          <li>
            <ChakraText>Go to the nearest emergency room</ChakraText>
          </li>
        </ul>
      </ChakraFlex>
    );
  }
  if (Array.isArray(result)) {
    return (
      <ChakraFlex flexDirection="column">
        <ul style={{ marginLeft: isMobile ? '24px' : null }}>
          {(result || []).map((elem) => {
            if (Object.keys(elem).length === 0) return null;
            const keyName = elem[Object.keys(elem)[0]];
            return (
              <li
                key={`${QuestionNameConstant[questionName]}_response_${keyName}`}
              >
                <ChakraText>
                  {Object.entries(elem).map(([key, value], i) => {
                    return (
                      <ChakraText
                        as="span"
                        key={`${QuestionNameConstant[questionName]}_${value}_${key}`}
                      >
                        {questionName ===
                          QuestionNameConstant.sp_names_phones_supports &&
                        i % 2 ? (
                          <>
                            {' '}
                            -
                            <ChakraText as="span" marginX="4px">
                              <TelephoneLayout result={value.toString()} />
                            </ChakraText>
                          </>
                        ) : (
                          <>
                            {i % 2 ? ` - ` : ''}
                            {value}
                          </>
                        )}
                      </ChakraText>
                    );
                  })}
                </ChakraText>
              </li>
            );
          })}
        </ul>
      </ChakraFlex>
    );
  }
  if (typeof result === 'object' && !Array.isArray(result) && result !== null) {
    return (
      <ChakraFlex flexDirection="column">
        <ul style={{ marginLeft: isMobile ? '24px' : null }}>
          {Object.entries(result).map(([key, value]) => {
            return (
              <li key={`${QuestionNameConstant[questionName]}_${value}_${key}`}>
                <ChakraText>{value}</ChakraText>
              </li>
            );
          })}
        </ul>
      </ChakraFlex>
    );
  }
  if (questionName === QuestionNameConstant.sp_local_ems) {
    return (
      <ChakraText>
        <TelephoneLayout result={result.toString()} />
      </ChakraText>
    );
  }
  return (
    <ChakraBox key={`${QuestionNameConstant[questionName]}`}>
      <ul style={{ marginLeft: isMobile ? '24px' : null }}>
        <li>
          <ChakraText>{result}</ChakraText>
        </li>
      </ul>
    </ChakraBox>
  );
};

const Layout: FC<LayoutProps> = ({
  definition,
  jsonResult,
  patientName,
  surveyCompletionDate,
}: LayoutProps) => {
  const isLowRisk =
    jsonResult[QuestionNameConstant.sp_risk_level]?.toLowerCase() === 'low';
  const isMobile = useMobileBreakpoint();
  const { track } = useAnalytics();
  const downLoadPdfHandler = () => {
    track(EVENT.SafetyPlanDownLoadClick);
    GeneratePDF({
      endDate: surveyCompletionDate,
      jsonResult,
      patientName,
      surveyDefinition: definition,
      surveyName: definition?.title,
    });
  };
  return (
    <ChakraBox>
      <ChakraFlex>
        {surveyCompletionDate && (
          <ChakraText
            color="black.60"
            fontSize="13px"
          >{`Completed on ${surveyCompletionDate}`}</ChakraText>
        )}
        <ChakraBox
          border={`solid 1px ${black[10]}`}
          marginX="16px"
          width="1px"
        />
        <ChakraFlex
          cursor="pointer"
          gridGap="10px"
          onClick={downLoadPdfHandler}
        >
          <ChakraBox position="relative" top="4px">
            <Download fill="#007F79" height={13} width={12} />
          </ChakraBox>
          <ChakraText color="teal.500" fontSize="13px">
            Download
          </ChakraText>
        </ChakraFlex>
      </ChakraFlex>
      {isLowRisk ? (
        <ChakraFlex {...styles.listWrapper}>
          <ChakraBox {...styles.listLeftItem}>
            <ChakraText>Local EMS:</ChakraText>
          </ChakraBox>
          <ChakraBox {...styles.listRightItem}>
            <ChakraText>
              <TelephoneLayout
                result={jsonResult[
                  QuestionNameConstant.sp_local_ems
                ].toString()}
              />
            </ChakraText>
          </ChakraBox>
        </ChakraFlex>
      ) : (
        <>
          <ChakraBox>
            {definition.pages.map((page) =>
              page.elements.map((question) => {
                if (
                  question.name === QuestionNameConstant.sp_risk_level ||
                  question.name === QuestionNameConstant.sp_resources
                ) {
                  return null;
                }
                return (
                  <ChakraFlex
                    {...styles.listWrapper}
                    key={`${question.name}_wrapper`}
                  >
                    <ChakraBox {...styles.listLeftItem}>
                      {question.name ===
                      QuestionNameConstant.sp_imminent_risk_statement ? (
                        <ChakraText>
                          If you are at risk of hurting yourself:
                        </ChakraText>
                      ) : (
                        <ChakraText>{question.title}</ChakraText>
                      )}
                    </ChakraBox>
                    {(jsonResult[question.name] ||
                      question.name ===
                        QuestionNameConstant.sp_imminent_risk_statement) && (
                      <ChakraBox {...styles.listRightItem}>
                        <RenderAnser
                          isMobile={isMobile}
                          questionName={question.name}
                          result={jsonResult[question.name]}
                        />
                      </ChakraBox>
                    )}
                  </ChakraFlex>
                );
              }),
            )}
          </ChakraBox>
          <RenderHTMLQuestion isMobile={isMobile} />
        </>
      )}
    </ChakraBox>
  );
};
export default Layout;
