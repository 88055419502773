import { FC } from 'react';

const ErrorSVG: FC<BaseSVG> = ({ fillColor = '#007F79', width = 500 }) => (
  <svg
    fill="none"
    viewBox="0 0 500 171"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66.9686 140.527C106.473 140.527 134.125 112.893 134.125 72.4756C134.125 32.0585 106.473 4.42456 66.9686 4.42456C27.6528 4.42456 0 32.0585 0 72.4756C0 112.893 27.6528 140.527 66.9686 140.527ZM66.9686 117.028C43.6425 117.028 26.9003 100.298 26.9003 72.4756C26.9003 44.6536 43.6425 27.9229 66.9686 27.9229C90.4829 27.9229 107.225 44.6536 107.225 72.4756C107.225 100.298 90.4829 117.028 66.9686 117.028Z"
      fill={fillColor}
    />
    <path
      d="M314.029 39.202C299.544 39.202 289.95 45.9695 283.93 55.3688V41.4579H259.664V170.04H283.93V123.984C289.95 133.571 299.544 140.527 314.029 140.527C339.048 140.527 357.483 118.908 357.483 89.7703C357.483 60.8204 339.048 39.202 314.029 39.202ZM308.197 118.908C293.712 118.908 283.554 108.569 283.554 89.9583C283.554 71.3477 293.712 60.8204 308.197 60.8204C322.494 60.8204 332.652 71.3477 332.652 89.9583C332.652 108.569 322.494 118.908 308.197 118.908Z"
      fill={fillColor}
    />
    <path
      d="M410.843 140.527C435.486 140.527 451.663 128.308 451.663 110.449C451.663 95.7859 441.505 86.0106 421.001 81.123L411.595 78.6791C400.12 75.8594 392.972 73.7915 392.972 67.588C392.972 62.7003 399.18 58.9406 408.773 58.9406C419.684 58.9406 428.525 63.8282 430.595 75.6714L450.723 70.4077C448.089 51.0452 431.347 39.202 408.773 39.202C385.259 39.202 370.022 50.8572 370.022 68.1519C370.022 83.3788 379.428 92.7781 401.813 98.2297L411.407 100.486C423.258 103.493 428.525 105.937 428.525 111.577C428.525 117.404 421.753 120.976 410.843 120.976C398.615 120.976 388.833 114.773 386.012 101.989L366.448 108.381C371.715 130.563 390.15 140.527 410.843 140.527Z"
      fill={fillColor}
    />
    <path
      d="M495.297 93.718H472.912L468.209 4.04858H500L495.297 93.718ZM467.832 125.112C467.832 119.347 469.4 115.336 472.535 113.081C475.671 110.699 479.496 109.509 484.01 109.509C488.4 109.509 492.162 110.699 495.297 113.081C498.432 115.336 500 119.347 500 125.112C500 130.626 498.432 134.636 495.297 137.143C492.162 139.524 488.4 140.715 484.01 140.715C479.496 140.715 475.671 139.524 472.535 137.143C469.4 134.636 467.832 130.626 467.832 125.112Z"
      fill={fillColor}
    />
    <path
      clipRule="evenodd"
      d="M166.996 138.208L139.473 90.5367L139.181 90.0297L167.201 41.3921L223.321 41.4453L223.327 41.446L223.33 41.4501L223.334 41.4574C223.269 42.3568 221.338 45.0847 220.854 45.6764C216.544 50.9544 210.575 54.3685 204.255 56.1149C201.997 56.759 199.672 57.1608 197.321 57.3073C202.226 57.6129 207.138 59.0324 211.685 61.6633C215.927 64.1176 219.413 67.3751 222.068 71.1329C222.504 71.9038 222.959 72.691 223.426 73.5004L223.727 74.0204C224.194 74.8301 224.648 75.6176 225.098 76.381C227.025 80.5588 228.102 85.2069 228.107 90.1073C228.112 95.3605 226.885 100.324 224.697 104.725C226 102.763 227.51 100.95 229.197 99.3163C233.869 94.7162 239.811 91.2541 246.537 90.1602C247.291 90.0371 250.619 89.7286 251.431 90.1219L251.435 90.1292L251.437 90.1337L251.435 90.1389L223.42 138.767L167.289 138.715L166.996 138.208ZM197.274 122.855C206.338 123.365 214.47 127.388 220.331 133.584C219.426 130.502 218.936 127.24 218.936 123.866C218.93 118.264 220.261 112.973 222.628 108.301C217.093 116.615 207.858 122.247 197.274 122.855ZM170.359 133.547C176.211 127.357 184.338 123.346 193.406 122.851C182.799 122.226 173.544 116.557 167.998 108.208C170.386 112.895 171.736 118.202 171.741 123.821C171.744 127.2 171.264 130.462 170.359 133.547ZM167.909 71.9918C171.999 63.8877 172.581 54.8328 170.145 46.6594C172.361 48.9843 174.942 51.0393 177.864 52.7268C182.712 55.5324 187.96 57.0256 193.189 57.3121C183.221 57.9485 173.727 63.1294 167.909 71.9918ZM145.191 89.9548C153.478 87.9823 161.015 82.9488 165.977 75.3439C161.215 84.8424 161.497 95.6914 165.955 104.668C163.09 100.258 159.168 96.4347 154.305 93.6205C151.38 91.9284 148.315 90.714 145.191 89.9548Z"
      fill={fillColor}
      fillRule="evenodd"
    />
    <path
      d="M227.358 0L252.286 52.1509C241.034 50.8226 230.687 43.9287 225.44 32.9504C220.192 21.9721 221.325 9.5907 227.358 0Z"
      fill={fillColor}
    />
  </svg>
);

export default ErrorSVG;
