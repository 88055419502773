import { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import { CONTENT_TYPE_HEADER } from '../constants/api.constants';

type FileUploadState = {
  error: string;
  isFileUploaded: boolean;
  loading: boolean;
  statusCode: number;
};

type SendFileUploadRequestParams<T> = {
  url: string;
  file: File;
  callback?: (isFileUploaded: boolean) => void;
};

const defaultState: FileUploadState = {
  error: null,
  isFileUploaded: false,
  loading: false,
  statusCode: null,
};

const axiosInstance: AxiosInstance = axios.create();

const useS3FileUpload = () => {
  const [state, setState] = useState<FileUploadState>(defaultState);
  const [cancelTokenSource] = useState<CancelTokenSource>(
    axios.CancelToken.source(),
  );

  useEffect(() => {
    return () => {
      if (cancelTokenSource) cancelTokenSource.cancel();
    };
  }, []);

  const sendRequest = async ({
    url,
    file,
    callback,
  }: SendFileUploadRequestParams<FileUploadState>): Promise<any> => {
    setState({
      ...defaultState,
      loading: true,
    });

    const newState: FileUploadState = {
      ...defaultState,
    };

    const options = {
      headers: {
        [CONTENT_TYPE_HEADER]: file.type,
      },
      cancelTokenSource: cancelTokenSource?.token,
    };

    try {
      const { status } = await axiosInstance.put(url, file, options);

      newState.statusCode = status;
      newState.isFileUploaded = status === 200;
    } catch (err) {
      newState.statusCode = 500;
    }
    newState.error =
      newState.statusCode === 200 ? null : 'Unable to upload file.';
    if (newState.error) Sentry.captureException(newState.error);

    setState(newState);
    callback(newState.isFileUploaded);
  };

  return {
    ...state,
    sendRequest,
  };
};

export default useS3FileUpload;
