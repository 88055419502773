import { FC, ReactNode } from 'react';

import { ChakraFlex, ChakraText, FireflyButton, Modal } from '@equip.health/ui';

export interface DiscardChangesModalProps {
  cancelButtonText?: string;
  confirmButtonText?: string;
  description: string | ReactNode;
  handleClose: () => void;
  handleConfirmDiscard: () => void;
  isOpen: boolean;
  showCancelButton?: boolean;
  title: string;
}

const DiscardChangesModal: FC<DiscardChangesModalProps> = ({
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  description,
  handleClose,
  handleConfirmDiscard,
  isOpen,
  showCancelButton,
  title,
}) => (
  <Modal
    footerContent={
      <ChakraFlex
        flexDirection="row-reverse"
        justifyContent="stretch"
        width="100%"
      >
        <FireflyButton onClick={handleConfirmDiscard} variant="accent.critical">
          {confirmButtonText}
        </FireflyButton>

        {showCancelButton && (
          <ChakraFlex alignItems="center" marginRight="32px">
            <FireflyButton onClick={handleClose} variant="tertiary">
              {cancelButtonText}
            </FireflyButton>
          </ChakraFlex>
        )}
      </ChakraFlex>
    }
    id="discard-changes"
    isOpen={isOpen}
    onClose={handleClose}
    size="sm"
    title={
      <ChakraText
        color="black.90"
        fontSize="19px"
        fontWeight="600"
        lineHeight="24px"
      >
        {title}
      </ChakraText>
    }
  >
    <ChakraText
      color="black.90"
      fontSize="15px"
      fontWeight="400"
      lineHeight="19px"
      marginBottom="8px"
    >
      {description}
    </ChakraText>
  </Modal>
);

export default DiscardChangesModal;
