import { FC, useEffect, useRef, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import {
  ChakraBox,
  ChakraFlex,
  useBreakpointValue,
  useDisclosure,
} from '@equip.health/ui';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import * as Sentry from '@sentry/react';
import isNil from 'lodash/isNil';
import { use100vh } from 'react-div-100vh';
import { Redirect } from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';

import ChatChannelList from '~/components/chat/ChatChannelList';
import ChatHeader from '~/components/chat/ChatHeader';
import ChatPanel from '~/components/chat/ChatPanel';
import EmptyChat from '~/components/chat/EmptyChat';
import MessageInputBox from '~/components/chat/MessageInputBox';
import Loading from '~/components/common/Loading';
import { ApiCommand } from '~/lib/Api';
import { AUTHENTICATED_NAV_LAYOUT, Path } from '~/lib/constants';
import {
  CHAT_LAYOUT,
  EM_DASH,
  NEW_MESSAGE,
} from '~/lib/constants/chat/chat.constants';
import urlConstants from '~/lib/constants/url.constants';
import { useChatChannelContext } from '~/lib/context/ChatChannelContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useApi } from '~/lib/hooks';
import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';
import useS3FileUpload from '~/lib/hooks/useS3FileUpload';
import { ChatMessage, ChatUser, GroupChannelMetadata } from '~/lib/types/chat';
import ChatUtil, { isIndividualChannel } from '~/lib/util/chat/chat.utils';
import {
  filterTaggableMembers,
  getChannelName,
} from '~/lib/util/chat/chatChannel.util';
import { getPreferredFullName } from '~/lib/utils';

import AttachmentDetailModal from './AttachmentDetailModal';
import { isChatAttachmentsEnabled } from './ChatAttachments';
import ChatMembersWarningView from './ChatMembersWarningView';

const { mobileHeight: mobileNavHeight, desktopHeight: desktopNavHeight } =
  AUTHENTICATED_NAV_LAYOUT;

const idPrefix = 'Chat';
const idPostfix = 'Messages';

const { getAttachmentUploadUrl: postPatientDocumentUrl } = urlConstants.chat;

interface MessagesContainerProps {
  channels: GroupChannel[];
  hasMoreMessages: boolean;
  loadMessages: (
    onSuccess?: (messages: ChatMessage[], hasMoreMessages: boolean) => void,
  ) => void;
  markMessagesAsRead: (onSuccess?: () => void, onFailure?: () => void) => void;
  messages: ChatMessage[];
  selectedChannel: GroupChannel;
  sendUserMessage: (messageToSend: ChatMessage, onSuccess?: () => void) => void;
  sendFileMessage: (
    file: File,
    fileName: string,
    fileS3Url: string,
    onSuccess?: () => void,
  ) => void;
  setSelectedChannel: (channel: GroupChannel) => void;
}

const MessagesContainer: FC<MessagesContainerProps> = ({
  channels,
  hasMoreMessages,
  loadMessages,
  markMessagesAsRead,
  messages,
  selectedChannel,
  sendFileMessage,
  sendUserMessage,
  setSelectedChannel,
}: MessagesContainerProps) => {
  const { isAuthenticated, isLoading: isAuth0Loading } = useAuth0();
  const { userProfile } = useUserProfileContext();
  const { patientId, setPatientId } = useChatChannelContext();

  const isSmallBreakpoint = useMobileBreakpoint();

  const navBarHeight = useBreakpointValue({
    base: mobileNavHeight,
    md: desktopNavHeight,
  });

  const {
    isOpen: isMessagesPanelOpen,
    onOpen: onMessagesPanelOpen,
    onClose: onMessagesPanelClose,
  } = useDisclosure();

  const [isUploadingAttachment, setIsUploadingAttachment] =
    useState<boolean>(false);

  const [selectedChannelName, setSelectedChannelName] = useState<string>();
  const [chatPatient, setChatPatient] = useState<ChatUser>(null);

  const channelsRef = useRef<GroupChannel[]>(null);
  const chatPanelContainerRef = useRef<HTMLDivElement>(null);

  const { sendRequest: sendAttachmentUploadRequest } = useS3FileUpload();

  const { sendRequest: getAttachmentUploadUrlSendRequest } = useApi<{
    data?: {
      attachmentUploadUrl?: string;
    };
  }>();

  const viewportHeight = use100vh();

  if (channels) {
    channelsRef.current = channels;
  }

  const channelNameSegments = getChannelName(
    selectedChannel,
    chatPatient,
    userProfile?.isPatient,
  );
  const channelName = channelNameSegments.primaryName;
  const channelSubTitle = channelNameSegments.secondaryName?.replace(
    EM_DASH,
    '',
  );

  const patientChannels = channels?.filter(
    (channel) =>
      (channel.cachedMetaData as GroupChannelMetadata).patientId === patientId,
  );

  useEffect(() => {
    if (userProfile?.isPatient) {
      setChatPatient({
        metaData: null,
        nickname: getPreferredFullName(
          userProfile?.chosenName,
          userProfile?.firstName,
          userProfile?.lastName,
        ),
        userId: userProfile.externalId,
      });
    } else {
      const selectedPatient = userProfile?.linkedPatients?.find(
        (p) => p?.externalId === patientId,
      );

      setChatPatient({
        metaData: null,
        nickname: getPreferredFullName(
          selectedPatient?.chosenName,
          selectedPatient?.firstName,
          selectedPatient?.lastName,
        ),
        userId: selectedPatient?.externalId,
      });
    }
  }, [userProfile, patientId]);

  useEffect(() => {
    if (userProfile && isNil(patientId)) {
      const patients = userProfile?.isPatient
        ? [userProfile]
        : userProfile?.linkedPatients;

      setPatientId(patients?.[0]?.externalId);
    }
  }, [userProfile, patientChannels]);

  const selectFirstPatientChannel = () => {
    setSelectedChannel(patientChannels[0]);
    setSelectedChannelName(patientChannels[0].url);
  };

  useEffect(() => {
    if (!patientId || isNil(patientChannels)) {
      return;
    }

    // If there are no channels for this patient, clear the selected channel
    if (patientChannels?.length === 0) {
      setSelectedChannel(null);
      return;
    }

    // If there is no selected channel, select the first patient channel
    if (isNil(selectedChannel)) {
      selectFirstPatientChannel();
      return;
    }

    // If the selected channel is not a patient channel, that indicates that the user has switched patients.
    // In this case, select the first channel for the new patient.
    const isNotAPatientChannel = isNil(
      patientChannels?.find((e) => e.url === selectedChannel?.url),
    );
    if (isNotAPatientChannel) {
      selectFirstPatientChannel();
    }
  }, [patientId, patientChannels, selectedChannel]);

  useEffect(() => {
    if (isSmallBreakpoint && selectedChannel && selectedChannelName) {
      onMessagesPanelOpen();
    } else {
      onMessagesPanelClose();
    }
  }, [isSmallBreakpoint]);

  if (isAuth0Loading || !userProfile || !channelsRef.current) {
    return <Loading />;
  }

  const handleSendMessageWithAttachment = (
    file: File,
    onMessageSent?: () => void,
  ) => {
    const fileID = uuidv1();
    const currentFileName = file.name;
    const newFileName = `${fileID}-${currentFileName}`;
    setIsUploadingAttachment(true);

    getAttachmentUploadUrlSendRequest({
      command: ApiCommand.POST,
      options: {
        channelId: selectedChannel.url,
        documentExternalId: fileID,
        fileName: currentFileName,
        patientExternalId: patientId,
      },
      url: postPatientDocumentUrl,
      callback: (response) => {
        const attachmentUploadUrl = response?.data?.attachmentUploadUrl;

        if (!attachmentUploadUrl) {
          Sentry.captureException(
            `Unable to upload attachment ${currentFileName} - no upload URL`,
          );
        }

        const handleUploadURLRequestSuccess = (success: boolean) => {
          setIsUploadingAttachment(false);

          if (success) {
            sendFileMessage(file, newFileName, attachmentUploadUrl, () => {
              chatPanelContainerRef.current?.scrollTo({
                behavior: 'smooth',
                top: 0,
              });

              onMessageSent?.();
            });
          } else {
            Sentry.captureException(
              `Unable to upload attachment ${currentFileName}`,
            );
          }
        };

        sendAttachmentUploadRequest({
          file,
          url: attachmentUploadUrl,
          callback: handleUploadURLRequestSuccess,
        });
      },
    });
  };

  const handleSendMessage = (
    message: string,
    attachmentFiles?: File[],
    mentionedUserIds?: string[],
    onMessageSent?: () => void,
  ) => {
    if (message) {
      const newChatMessage: ChatMessage = {
        ...NEW_MESSAGE,
        channelUrl: selectedChannel.url,
        mentionedUserIds,
        message,
      };

      sendUserMessage(newChatMessage, () => {
        chatPanelContainerRef.current?.scrollTo({
          behavior: 'smooth',
          top: 0,
        });
        if (attachmentFiles.length === 0) {
          onMessageSent();
        }
      });
    }

    if (isChatAttachmentsEnabled && attachmentFiles.length) {
      const file = attachmentFiles[0];

      if (file) {
        handleSendMessageWithAttachment(file, onMessageSent);
      }
    }
  };

  const isDisabledState = () => {
    return (
      !ChatUtil.isChannelMember(
        ChatUtil.getUserIds(selectedChannel?.members),
        userProfile?.externalId,
      ) ||
      selectedChannel.isFrozen ||
      !ChatUtil.isChannelMember(
        ChatUtil.getUserIds(selectedChannel?.members as ChatUser[]),
        userProfile?.externalId,
      )
    );
  };

  const handleChatChannelDismiss = () => {
    setSelectedChannelName(null);
    onMessagesPanelClose();
  };

  const handleChannelSelected = (channel: GroupChannel) => {
    setSelectedChannel(channel);
    setSelectedChannelName(channel.url);
    chatPanelContainerRef?.current?.scrollTo({ behavior: 'smooth', top: 0 });
    if (isSmallBreakpoint) {
      onMessagesPanelOpen();
    }
  };

  const chatContainerHeight = isSmallBreakpoint
    ? `calc(${viewportHeight}px - ${navBarHeight})`
    : `100%`;

  return !isAuthenticated ? (
    <Redirect to={Path.LOGIN_V2} />
  ) : (
    <>
      {selectedChannel ? (
        <ChakraFlex
          direction="row"
          height={`calc(${viewportHeight}px - ${navBarHeight})`}
          id={`${idPrefix}__page__${idPostfix}`}
          width="100%"
        >
          <ChakraBox
            display={isMessagesPanelOpen ? 'none' : 'block'}
            flexGrow={0}
            flexShrink={0}
            height="100%"
            id={`${idPrefix}__channel-list-container__${idPostfix}`}
            overflow="auto"
            width={{
              base: '100%',
              md: CHAT_LAYOUT.channelListPanelWidthDesktop,
            }}
          >
            <ChatChannelList
              channels={patientChannels}
              id="equip"
              isPatient={userProfile?.isPatient}
              onChannelChanged={handleChannelSelected}
              patient={chatPatient}
              selectedChannelUrl={selectedChannel.url}
            />
          </ChakraBox>
          {messages && (
            <ChakraFlex
              backgroundColor="white.100"
              direction="column"
              display={
                isSmallBreakpoint && !isMessagesPanelOpen ? 'none' : 'flex'
              }
              height={chatContainerHeight}
              position={isMessagesPanelOpen ? 'absolute' : 'relative'}
              width={{
                base: '100%',
                md: `calc(100vw - ${CHAT_LAYOUT.channelListPanelWidthDesktop})`,
              }}
            >
              <ChatHeader
                id={selectedChannel.url}
                isGroupChannel={!isIndividualChannel(selectedChannel?.url)}
                members={selectedChannel.members}
                name={channelName}
                onDismiss={handleChatChannelDismiss}
                subTitle={channelSubTitle}
              />
              <ChakraBox
                display="flex"
                flexDirection="column"
                height="100%"
                id={`${idPrefix}__messages-panel-container__${idPostfix}`}
                overflow="auto"
                width="100%"
              >
                <ChatPanel
                  currentUserId={userProfile.externalId}
                  hasMoreMessages={hasMoreMessages}
                  id="equip"
                  loadMoreMessages={loadMessages}
                  markMessagesAsRead={markMessagesAsRead}
                  messages={messages}
                  ref={chatPanelContainerRef}
                />
                <ChatMembersWarningView selectedChannel={selectedChannel} />
                <MessageInputBox
                  id="equip"
                  isDisabled={isDisabledState()}
                  isUploadingAttachment={isUploadingAttachment}
                  members={filterTaggableMembers(selectedChannel)}
                  onSendMessage={handleSendMessage}
                />
              </ChakraBox>
            </ChakraFlex>
          )}
        </ChakraFlex>
      ) : (
        <EmptyChat
          currentUserId={userProfile.externalId}
          id={idPrefix}
          topMargin={navBarHeight}
        />
      )}
      <AttachmentDetailModal />
    </>
  );
};

export default MessagesContainer;
