import { FC, useEffect, useState } from 'react';

import { ChakraBox, ChakraFlex, FAIconName, Icon, Tab } from '@equip.health/ui';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import SectionTitle from './SectionTitle';
import { getPreferredFirstName } from '~/lib/utils';

interface BackButtonProps {
  onClick: () => void;
}

const BackButton: FC<BackButtonProps> = ({ onClick }) => (
  <ChakraBox as="button" height="40px" onClick={onClick} width="40px">
    <Icon name={FAIconName.chevronLeftRegular} size="lg" />
  </ChakraBox>
);

export interface PatientTabItemProps {
  isActive?: boolean;
  patientName: string;
  patientId: string;
  badgeCount?: number;
}

const topLevelId = 'user-selector';

interface PatientTabSelectorProps {
  id: string;
  initialPatientId?: string;
  isBackButtonVisible?: boolean;
  onBackButtonClick?: () => void;
  onPatientClick: (patientId: string) => void;
  patientTabs: PatientTabItemProps[];
  title: string;
  type?: 'page' | 'section';
}

// Created a ticket to clean up and refactor this component to improve readability and maintainability
// https://teamequip.atlassian.net/browse/EQ-15028

export const getProfilesToTabs = (
  userProfile: UserProfile,
  linkedPatients: UserProfile[],
): PatientTabItemProps[] => {
  if (userProfile?.isPatient) {
    return [];
  }

  const patientsToTabs: PatientTabItemProps[] =
    linkedPatients
      ?.filter((p) => p.isProxy)
      .map((p) => ({
        patientId: p.externalId,
        patientName: getPreferredFirstName(p.chosenName, p.firstName),
      })) ?? [];

  return [
    {
      patientId: userProfile?.externalId,
      patientName: 'You',
    },
    ...patientsToTabs,
  ];
};

export const getAllUserSelectorTabs = (
  userProfile: UserProfile,
): PatientTabItemProps[] => {
  if (userProfile.isPatient) {
    return [];
  }
  const patientsToTabs: PatientTabItemProps[] =
    userProfile.linkedPatients
      ?.filter((p) => p.isProxy)
      .map((p) => ({
        patientId: p.externalId,
        patientName: getPreferredFirstName(p.chosenName, p.firstName),
      })) ?? [];

  return [
    {
      patientId: userProfile.externalId,
      patientName: 'You',
    },
    ...patientsToTabs,
  ];
};

export const PatientTabSelector: FC<PatientTabSelectorProps> = ({
  id,
  initialPatientId,
  isBackButtonVisible,
  onBackButtonClick,
  onPatientClick,
  patientTabs,
  type: style,
  title,
}) => {
  const [selectedTabId, setSelectedTabId] = useState<string>();
  useEffect(() => {
    if (patientTabs?.length && isNil(selectedTabId)) {
      if (initialPatientId) {
        setSelectedTabId(initialPatientId);
      } else {
        setSelectedTabId(patientTabs[0].patientId);
      }
    }
  }, [patientTabs]);

  const handleTabChange = (id: string) => {
    onPatientClick(id);
    setSelectedTabId(id);
  };

  if (isEmpty(patientTabs)) {
    return null;
  }

  return (
    <ChakraFlex
      direction={{
        base: 'column',
        lg: 'row',
      }}
      gridGap={{
        base: '24px',
        lg: '40px',
      }}
      id={`${id}__${topLevelId}`}
      width={{
        base: '100%',
        md: 'auto',
      }}
    >
      <ChakraFlex alignItems="center">
        {isBackButtonVisible && <BackButton onClick={onBackButtonClick} />}
        <SectionTitle
          id={`${id}__${topLevelId}__title`}
          title={title}
          type={style}
        />
      </ChakraFlex>
      <ChakraFlex
        gridGap="8px"
        overflowX="scroll"
        width={{
          base: '100%',
          md: 'auto',
        }}
      >
        {patientTabs?.map((patientTab) => {
          return (
            <Tab
              counter={patientTab.badgeCount}
              id={`${id}__${topLevelId}__${patientTab}`}
              isActive={patientTab.patientId === selectedTabId}
              key={patientTab.patientId}
              onClick={() => handleTabChange(patientTab.patientId)}
              title={patientTab.patientName}
              variant="bubble"
            />
          );
        })}
      </ChakraFlex>
    </ChakraFlex>
  );
};
