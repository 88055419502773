interface RawHtmlProps {
  html: string;
  id: string;
}

const idPrefix = 'Raw';
const idPostfix = 'Html';

const RawHtml = ({ html, id }: RawHtmlProps) => {
  return (
    <span
      dangerouslySetInnerHTML={{ __html: html }}
      id={`${idPrefix}__${id}__${idPostfix}`}
    />
  );
};

RawHtml.displayName = 'RawHtml';
export default RawHtml;
