import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { isNil } from 'lodash';

import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { sortUserProfileByAgeASC } from '~/lib/util/profile.util';
import { getAssigneesName } from '~/lib/util/task.util';
import { getPreferredProfileFirstName } from '~/lib/utils';

export const YOUR_TASK_TAB_INDEX = 0;

export type TodoListState = {
  assigneesName: string;
  isAllTabLoaded: boolean;
  isProxySupport: boolean;
  isProxySupportWithNoTodo: boolean;
  isTodoLoading: boolean;
  tabIndex: number;
  tabList: TabItem[];
  patientAllTodoCountMapper: Record<string, number>;
  patientPendingTodoCountMapper: Record<string, number>;
  refreshValue: number;
  resetAndIntializeTodoList: () => void;
  setRefreshValue: Dispatch<SetStateAction<number>>;
  setTabIndex: Dispatch<SetStateAction<number>>;
  updatePatientAllTodoCountMapper: (
    patientId: string,
    pendingTodoCount: number,
  ) => void;
  updatePatientPendingTodoCountMapper: (
    patientId: string,
    allTodoCount: number,
  ) => void;
};

const useTodoList = (): TodoListState => {
  const [tabIndex, setTabIndex] = useState<number>(YOUR_TASK_TAB_INDEX);

  const [assigneesName, setAssigneesName] = useState<string>('');

  const [tabList, setTabList] = useState<TabItem[]>([]);
  const [isAllTabLoaded, setAllTabLoaded] = useState<boolean>(false);

  /**
   * Now we have multiple tabs, we cannot refetch multiple tab child component from Parent after completing a task.
   * To update that we have this refreshValue. When refreshValue is updated, all tabs will be refetched.
   */
  const [refreshValue, setRefreshValue] = useState<number>(0);

  const [patientPendingTodoCountMapper, setPatientPendingTodoCountMapper] =
    useState<Record<string, number>>({});
  const [patientAllTodoCountMapper, setPatientAllTodoCountMapper] = useState<
    Record<string, number>
  >({});

  // State to indicate no task for Proxy support and all patient.
  const [isProxySupportWithNoTodo, setIsProxySupportWithNoTodo] =
    useState<boolean>(false);

  const updatePatientPendingTodoCountMapper = (
    patientId: string,
    pendingTodoCount: number,
  ) => {
    if (!patientId) return;
    patientPendingTodoCountMapper[patientId] = pendingTodoCount;
    setPatientPendingTodoCountMapper({ ...patientPendingTodoCountMapper });
  };

  const updatePatientAllTodoCountMapper = (
    patientId: string,
    allTodoCount: number,
  ) => {
    if (!patientId) return;
    const updatedMapper = { ...patientAllTodoCountMapper };
    updatedMapper[patientId] = allTodoCount;
    setPatientAllTodoCountMapper(updatedMapper);
  };

  useEffect(() => {
    const patientAllTodoCountMapperValues = Object.values(
      patientAllTodoCountMapper,
    );

    const isAllTodoValuesAreSet =
      patientAllTodoCountMapperValues.length === tabList.length &&
      !patientAllTodoCountMapperValues.some(
        (value) => typeof value !== 'number',
      );

    if (isAllTodoValuesAreSet && (tabList ?? []).length > 0) {
      setIsProxySupportWithNoTodo(
        !patientAllTodoCountMapperValues.some((value) => Boolean(value)),
      );
      setAllTabLoaded(true);
    }
  }, [patientAllTodoCountMapper]);

  const { userProfile } = useUserProfileContext();
  const {
    externalId: currentUserExternalId,
    isPatient,
    linkedPatients,
  } = userProfile ?? {};

  useEffect(() => {
    if (
      linkedPatients?.length &&
      currentUserExternalId &&
      (assigneesName ?? '').length === 0
    ) {
      setAssigneesName(
        getAssigneesName(
          [
            userProfile as FamilyAssignee,
            ...(linkedPatients ?? [])
              .filter((patient) => patient.isProxy)
              .map((patient) => ({
                chosenName: patient.chosenName,
                externalId: patient.externalId,
                firstName: patient.firstName,
              })),
          ],
          currentUserExternalId,
        ),
      );
    }
  }, [linkedPatients, currentUserExternalId]);

  const generateTabList = () => {
    if (linkedPatients?.length && currentUserExternalId) {
      const proxyPatients = linkedPatients
        .filter((patient) => patient.isProxy)
        .sort(sortUserProfileByAgeASC);

      if (proxyPatients?.length) {
        const proxyPatientsTabs = proxyPatients.map((p) => ({
          tabCount: patientPendingTodoCountMapper[p.externalId],
          tabName: getPreferredProfileFirstName(p),
          userId: p.externalId,
        }));

        setTabList([
          {
            tabCount: patientPendingTodoCountMapper[currentUserExternalId],
            tabName: 'You',
            userId: currentUserExternalId,
          },
          ...proxyPatientsTabs,
        ]);
      }
    }
  };

  const resetAndIntializeTodoList = () => {
    setTabList([]);
    setRefreshValue(0);
    setPatientPendingTodoCountMapper({});
    setPatientAllTodoCountMapper({});
    setIsProxySupportWithNoTodo(false);

    generateTabList();
  };

  useEffect(generateTabList, [
    linkedPatients?.length,
    patientPendingTodoCountMapper,
    currentUserExternalId,
  ]);

  const isProxySupport = useMemo<boolean>(
    () =>
      !isPatient && (linkedPatients ?? []).some((patient) => patient.isProxy),
    [isPatient, linkedPatients],
  );

  const isTodoLoading = useMemo<boolean>(
    () => (!isPatient && isNil(linkedPatients)) || !currentUserExternalId,
    [linkedPatients, currentUserExternalId],
  );

  return {
    assigneesName,
    isAllTabLoaded,
    isProxySupport,
    isProxySupportWithNoTodo,
    isTodoLoading,
    patientAllTodoCountMapper,
    patientPendingTodoCountMapper,
    refreshValue,
    resetAndIntializeTodoList,
    setRefreshValue,
    setTabIndex,
    tabIndex,
    tabList,
    updatePatientAllTodoCountMapper,
    updatePatientPendingTodoCountMapper,
  };
};

export default useTodoList;
