import React, { FC } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  FAIconName,
  FireflyButton,
} from '@equip.health/ui';

import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';

interface AuxButtonProps {
  onClick: () => void;
  title: string;
  icon?: React.ReactNode;
}

export interface ModalFooterProps {
  auxButton?: AuxButtonProps;
  isBackButtonVisible?: boolean;
  isContinueButtonDisabled?: boolean;
  isForwardButtonHidden?: boolean;
  isLastStep?: boolean;
  isLoading?: boolean;
  onBackwardNavigation?: () => void;
  onForwardNavigation: () => void;
  stepId: string;
}

enum ButtonTitles {
  back = 'Back',
  continue = 'Continue',
  bookAppointment = 'Book appointment',
}

const BookAppointmentModalFooter: FC<ModalFooterProps> = ({
  auxButton,
  isBackButtonVisible,
  isContinueButtonDisabled,
  isForwardButtonHidden,
  isLastStep,
  isLoading,
  onBackwardNavigation,
  onForwardNavigation,
  stepId,
}) => {
  const forwardButtonTitle = isLastStep
    ? ButtonTitles.bookAppointment
    : ButtonTitles.continue;

  const { title: auxButtonTitle, onClick: onAuxButtonClick } = auxButton || {};
  const shouldShowAuxButton = auxButtonTitle && !!onAuxButtonClick;
  const isMobile = useMobileBreakpoint();

  return (
    <ChakraFlex flexDirection="column" id={`${stepId}__footer`}>
      {shouldShowAuxButton && isMobile && (
        <ChakraBox padding="12px 0">
          <FireflyButton
            id={`${stepId}__add-button`}
            leftIcon={FAIconName.plusCircleRegular}
            onClick={onAuxButtonClick}
            size="normal"
            variant="secondary"
          >
            {auxButtonTitle}
          </FireflyButton>
        </ChakraBox>
      )}
      <ChakraFlex
        alignItems="center"
        justifyContent={isBackButtonVisible ? 'space-between' : 'flex-end'}
        padding="12px 0"
        width="100%"
      >
        {isBackButtonVisible && (
          <FireflyButton
            id={`${stepId}__back-button`}
            leftIcon={FAIconName.arrowLeftRegular}
            onClick={onBackwardNavigation}
            size="normal"
            variant={isMobile ? 'tertiary' : 'secondary'}
          >
            {ButtonTitles.back}
          </FireflyButton>
        )}

        <ChakraFlex gridGap="40px">
          {shouldShowAuxButton && !isMobile && (
            <FireflyButton
              id={`${stepId}__add-button`}
              leftIcon={FAIconName.plusCircleRegular}
              onClick={onAuxButtonClick}
              size="normal"
              variant="secondary"
            >
              {auxButtonTitle}
            </FireflyButton>
          )}
          {!isForwardButtonHidden && (
            <FireflyButton
              id={`${stepId}__continue-button`}
              isDisabled={isContinueButtonDisabled}
              isLoading={isLoading}
              leftIcon={isLastStep && FAIconName.checkRegular}
              onClick={onForwardNavigation}
              rightIcon={!isLastStep && FAIconName.arrowRightRegular}
              variant="primary"
            >
              {forwardButtonTitle}
            </FireflyButton>
          )}
        </ChakraFlex>
      </ChakraFlex>
    </ChakraFlex>
  );
};

export default BookAppointmentModalFooter;
