import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useUserProfileContext } from './UserProfileContext';
import { BOOK_APPOINTMENT_STEP } from '~/lib/constants/bookAppointments';
import { Recurrence } from '~/lib/util/schedule.util';

type BookAppointmentContextState = {
  availableAttendees: BookAppointmentAttendee[];
  currentStep: BOOK_APPOINTMENT_STEP;
  initialStep: BOOK_APPOINTMENT_STEP;
  isPatientAttending: boolean;
  patientId?: string;
  providerParticipants: BookAppointmentAttendee[];
  reset: () => void;
  selectedAppointmentRecurrence: Recurrence;
  selectedAppointmentType: ProviderAppointmentTypes;
  selectedAppointmentTypeId: string;
  selectedOptionalAttendees: BookAppointmentAttendee[];
  selectedTimeSlot: {
    startTime: string;
    endTime: string;
  };
  requiredAttendees: BookAppointmentAttendee[];
  setAvailableAttendees: (attendees: BookAppointmentAttendee[]) => void;
  setNextStep: (step: BOOK_APPOINTMENT_STEP) => void;
  setPatientId: (patientid: string) => void;
  setSelectedAppointmentRecurrence: (recurrence: Recurrence) => void;
  setSelectedAppointmentTypeId: (appointmentTypeId: string) => void;
  setSelectedOptionalAttendees: (attendees: BookAppointmentAttendee[]) => void;
  setSelectedProvider: (providerType: ProviderAppointmentTypes) => void;
  setSelectedTimeSlot: (startTime: string, endTime: string) => void;
  supportAttendes: BookAppointmentAttendee[];
};

const useBookAppointment = (): BookAppointmentContextState => {
  const { userProfile } = useUserProfileContext();
  const isPatient = userProfile?.isPatient;
  const initialStep = isPatient
    ? BOOK_APPOINTMENT_STEP.appointmentType
    : BOOK_APPOINTMENT_STEP.patient;

  const [timeSlot, setTimeSlot] = useState<{
    startTime: string;
    endTime: string;
  }>();

  const [currentStep, setCurrentStep] =
    useState<BOOK_APPOINTMENT_STEP>(initialStep);

  const [patientId, setPatientId] = useState<string>(
    isPatient ? userProfile.externalId : null,
  );

  const [providerType, setProviderType] = useState<ProviderAppointmentTypes>();

  const [appointmentTypeId, setAppointmentTypeId] = useState<string>();
  const [appointmentRecurrence, setAppointmentRecurrence] =
    useState<Recurrence>();

  const [availableAttendees, setAvailableAttendees] = useState<
    BookAppointmentAttendee[]
  >([]);

  const [selectedOptionalAttendees, setSelectedOptionalAttendees] = useState<
    BookAppointmentAttendee[]
  >([]);

  const reset = () => {
    setCurrentStep(initialStep);

    if (!isPatient) {
      setPatientId(null);
    }

    setProviderType(null);
    setSelectedAppointmentRecurrence(null);
    setAppointmentTypeId(null);
    setAvailableAttendees([]);
    setSelectedOptionalAttendees([]);
  };

  const setNextStep = (step: BOOK_APPOINTMENT_STEP) => {
    if (step) {
      setCurrentStep(step);
    }
  };

  const handleSetPatientId = (newPatientId: string) => {
    if (patientId !== newPatientId) {
      setPatientId(newPatientId);
      setProviderType(null);
      setAvailableAttendees([]);
      setSelectedOptionalAttendees([]);
    }
  };

  const setSelectedProvider = (newProvider: ProviderAppointmentTypes) => {
    if (providerType?.providerExternalId !== newProvider.providerExternalId) {
      setProviderType(newProvider);
      setAvailableAttendees([]);
      setSelectedOptionalAttendees([]);
    }
  };

  const setSelectedAppointmentTypeId = (appointmentTypeId: string) => {
    setAppointmentTypeId(appointmentTypeId);
  };

  const setSelectedAppointmentRecurrence = (recurrence: Recurrence) => {
    setAppointmentRecurrence(recurrence);
  };

  const handleSetAvailableAttendees = (
    attendees: BookAppointmentAttendee[],
  ) => {
    setAvailableAttendees(attendees);
  };

  const setOptionalAttendees = (attendees: BookAppointmentAttendee[]) => {
    setSelectedOptionalAttendees(attendees);
  };

  const setSelectedTimeSlot = (startTime: string, endTime: string) => {
    setTimeSlot({ startTime, endTime });
  };

  const requiredAttendees = useMemo<BookAppointmentAttendee[]>(() => {
    return availableAttendees.filter((attendee) => attendee.isRequired);
  }, [availableAttendees]);

  const isPatientAttending = useMemo<boolean>(() => {
    const isPatientRequired = requiredAttendees.some(
      (attendee) => attendee.userType === 'patient',
    );

    const isPatientOptional = selectedOptionalAttendees.some(
      (attendee) => attendee.userType === 'patient',
    );

    return isPatientOptional || isPatientRequired;
  }, [requiredAttendees, selectedOptionalAttendees]);

  const providerParticipants = useMemo<BookAppointmentAttendee[]>(() => {
    const requiredProviders = requiredAttendees.filter(
      (attendee) => attendee.userType === 'provider',
    );

    const optionalProviders = selectedOptionalAttendees.filter(
      (attendee) => attendee.userType === 'provider',
    );

    return [...requiredProviders, ...optionalProviders];
  }, [requiredAttendees, selectedOptionalAttendees]);

  const supportAttendes = useMemo<BookAppointmentAttendee[]>(() => {
    const requiredSupports = requiredAttendees.filter(
      (attendee) => attendee.userType === 'support',
    );

    const optionalSupports = selectedOptionalAttendees.filter(
      (attendee) => attendee.userType === 'support',
    );

    return [...requiredSupports, ...optionalSupports];
  }, [requiredAttendees, selectedOptionalAttendees]);

  return {
    availableAttendees,
    currentStep,
    initialStep,
    isPatientAttending,
    patientId,
    providerParticipants,
    requiredAttendees,
    reset,
    selectedAppointmentRecurrence: appointmentRecurrence,
    selectedAppointmentType: providerType,
    selectedAppointmentTypeId: appointmentTypeId,
    selectedOptionalAttendees,
    selectedTimeSlot: timeSlot,
    setAvailableAttendees: handleSetAvailableAttendees,
    setNextStep,
    setPatientId: handleSetPatientId,
    setSelectedAppointmentRecurrence,
    setSelectedAppointmentTypeId,
    setSelectedOptionalAttendees: setOptionalAttendees,
    setSelectedProvider,
    setSelectedTimeSlot,
    supportAttendes,
  };
};

const BookAppointmentContext = createContext<
  BookAppointmentContextState | undefined
>(undefined);

interface BookAppointmentContextProviderProps {
  children: ReactElement | ReactElement[];
}

const BookAppointmentContextProvider: FC<
  BookAppointmentContextProviderProps
> = ({ children }: BookAppointmentContextProviderProps) => {
  const value = useBookAppointment();

  return (
    <BookAppointmentContext.Provider value={value}>
      {children}
    </BookAppointmentContext.Provider>
  );
};

const useBookAppointmentContext = (): BookAppointmentContextState => {
  const context = useContext(BookAppointmentContext);

  if (context === undefined) {
    throw new Error(
      'useBookAppointmentContext must be used within a BookAppointmentContextProvider',
    );
  }

  return context;
};

export { BookAppointmentContextProvider, useBookAppointmentContext };
