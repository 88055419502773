import { FC } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  EquipUIFireflyV2Theme,
  FAIconName,
  FireflyButton,
  Icon,
  Text,
} from '@equip.health/ui';
import { DateTime } from 'luxon';

import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import useFeatureFlags from '~/lib/hooks/useFeatureFlags';
import {
  Recurrence,
  checkIsToday,
  checkIsTomorrow,
  generateHostedByStringFromNames,
  generateParticipantNameList,
  generateParticipantNameString,
  getRecurrenceDisplayString,
  transformDateFromISOtoLocal,
} from '~/lib/util/schedule.util';

import ScheduleViewItemMenu, {
  GroupClassEventItemMenu,
} from './ScheduleViewItemMenu';
import ZoomErrorModal from './ZoomErrorModal';
import useZoomMeeting from '../../lib/hooks/useZoomMeeting';
import { getPreferredFullName } from '../../lib/utils';
import { Participant } from '../common/Participant';

const { neutral } = EquipUIFireflyV2Theme.colors;

interface DateLabelProps {
  appointmentStartDateTime: string;
  isSummary?: boolean;
}
export const DateLabel: FC<DateLabelProps> = ({
  appointmentStartDateTime,
  isSummary,
}) => {
  const appointmentStartDate = DateTime.fromISO(appointmentStartDateTime);
  const isToday = checkIsToday(appointmentStartDateTime);
  const isTomorrow = checkIsTomorrow(appointmentStartDateTime);
  if (isToday) {
    return (
      <Text
        color="primary.100"
        id={`${appointmentStartDateTime}__date_label`}
        variant="bodyMedium"
      >
        Today
      </Text>
    );
  }
  if (isTomorrow) {
    return (
      <Text
        color="primary.100"
        id={`${appointmentStartDateTime}__date_label`}
        variant="bodyMedium"
      >
        Tomorrow
      </Text>
    );
  }
  let dayName: string;
  if (isSummary) {
    dayName = appointmentStartDate.toFormat('LLLL d');
  } else {
    dayName = appointmentStartDate.toFormat('cccc, L/d');
  }
  return (
    <Text
      color="neutral.primary"
      id={`${appointmentStartDateTime}__date_label`}
      variant="bodyMedium"
      whiteSpace="nowrap"
    >
      {dayName}
    </Text>
  );
};

interface TimeLabelProps {
  startTime: string;
  endTime: string;
}

const TimeLabel: FC<TimeLabelProps> = ({ startTime, endTime }) => {
  const startAppointmentTime = transformDateFromISOtoLocal(startTime);
  const endAppointmentTime = transformDateFromISOtoLocal(endTime);
  return (
    <Text
      color="neutral.primary"
      id={`${startTime}__time_label`}
      variant="caption"
      whiteSpace="nowrap"
    >
      {startAppointmentTime} - {endAppointmentTime}
    </Text>
  );
};

type ParticipantGeneratorProps = {
  eventExternalId: string;
  host?: ProviderAttendee;
  isGroupClass?: boolean;
  isPatientAttending?: boolean;
  isSummary?: boolean;
  patient?: AppointmentPatient;
  providerAttendees?: ProviderAttendee[];
  supportAttendees?: SupportAttendee[];
};

const ParticipantGenerator: FC<ParticipantGeneratorProps> = ({
  eventExternalId,
  host,
  isGroupClass = false,
  isPatientAttending,
  isSummary,
  patient,
  providerAttendees,
  supportAttendees,
}) => {
  const { userProfile } = useUserProfileContext();
  const dataObject = {
    host,
    isPatientAttending,
    patient,
    providerAttendees,
    supportAttendees,
  } as AppointmentDetailResponse;
  if (isSummary) {
    return (
      <Text
        color="neutral.secondary"
        id={`${eventExternalId}__participants_label`}
        variant="caption"
      >
        {isGroupClass
          ? generateHostedByStringFromNames(
              providerAttendees.map((p) => p.name),
            )
          : generateParticipantNameString(dataObject, userProfile)}
      </Text>
    );
  }
  const nameList = generateParticipantNameList(
    dataObject,
    userProfile.externalId,
  );

  return (
    <>
      {nameList.map((participant, index) => {
        const isMe = participant.externalId === userProfile.externalId;
        return (
          <Participant
            avatarSize="xs"
            id={`schedule_${index}`}
            key={`${participant.externalId}_${participant.name}`}
            listSource="schedule"
            marginTop={index === 0 ? '18px' : '14px'}
            primaryLabel={participant.name}
            secondaryLabel={isMe ? 'You' : participant.role}
            userId={participant.externalId}
            userType={
              participant.userType === 'host'
                ? 'provider'
                : participant.userType
            }
          />
        );
      })}
    </>
  );
};

const ZoomButton: FC<{ isLoading: boolean; onClick: () => void }> = ({
  isLoading,
  onClick,
}) => {
  return (
    <FireflyButton
      id="join_zoom"
      isLoading={isLoading}
      onClick={onClick}
      size="normal"
      variant="secondary"
    >
      Join Zoom
    </FireflyButton>
  );
};

interface ScheduleViewItemProps {
  endTime: string;
  eventExternalId: string;
  groupClassExternalId?: string;
  isCancellable?: boolean;
  index: number;
  isGroupClass?: boolean;
  isLast?: boolean;
  isSummary?: boolean;
  isSensitive?: boolean;
  participantsComponent: JSX.Element;
  patientExternalId?: string;
  recurrence?: Recurrence;
  refreshData?: () => void;
  startTime: string;
  title: string;
}

const ScheduleViewItem = ({
  endTime,
  eventExternalId,
  groupClassExternalId,
  isCancellable,
  index,
  isGroupClass = false,
  isLast = false,
  isSummary = false,
  isSensitive,
  participantsComponent,
  patientExternalId,
  recurrence,
  refreshData,
  startTime,
  title,
}: ScheduleViewItemProps) => {
  const {
    closeZoomErrorModal,
    getZoomURLLoading,
    openZoomSession,
    shouldShowZoomButton,
    showZoomErrorModal,
  } = useZoomMeeting({
    eventExternalId,
    isGroupClass,
    patientExternalId,
    startTime,
  });
  const scheduleCancellationAllowed = !isSummary && isCancellable;
  const { isSensitiveGroupClassesEnabled } = useFeatureFlags();

  return (
    <ChakraFlex
      alignItems={isSummary ? 'center' : 'flex-start'}
      borderBottom={
        (isSummary && index == 2) || isLast === true
          ? 'none'
          : `solid 1px ${neutral.background.primary}`
      }
      gridGap="16px"
      justifyContent="space-between"
      marginTop={!isSummary && index === 0 ? '0' : '26px'}
      paddingBottom="26px"
      position="relative"
    >
      <ZoomErrorModal
        isOpen={showZoomErrorModal}
        onClose={closeZoomErrorModal}
        onRetry={openZoomSession}
      />

      <ChakraFlex flexDirection={{ base: 'column', md: 'row' }} width="100%">
        {isSummary && (
          <ChakraFlex
            alignItems={{ base: 'center', md: 'flex-start' }}
            direction={{ base: 'row', md: 'column' }}
            marginBottom={isSummary ? '0px' : '12px'}
            paddingRight="16px"
            width={{ base: '160px', md: '220px' }}
          >
            <DateLabel
              appointmentStartDateTime={startTime}
              isSummary={isSummary}
            />
            <Text display={{ base: 'block', md: 'none' }} marginX="8px">
              -
            </Text>
            <ChakraBox marginTop={{ base: '0px', md: '4px' }}>
              <TimeLabel endTime={endTime} startTime={startTime} />
            </ChakraBox>
          </ChakraFlex>
        )}
        <ChakraBox width="100%">
          <ChakraFlex gridGap="12px" justifyContent="space-between">
            <ChakraBox>
              <Text
                color="neutral.primary"
                id={`${eventExternalId}__primary_label`}
                marginBottom="4px"
                variant="body"
              >
                {title}
              </Text>
              {!isSummary && (
                <TimeLabel endTime={endTime} startTime={startTime} />
              )}
              {!isSummary && (
                <ChakraBox marginTop="12px">
                  {isGroupClass && (
                    <ChakraFlex marginTop="4px">
                      <Icon
                        color="neutral.tertiary"
                        name={FAIconName.userGroupRegular}
                        size="sm"
                      />
                      <Text
                        color="neutral.secondary"
                        marginLeft="12px"
                        variant="caption"
                      >
                        Group class
                      </Text>
                    </ChakraFlex>
                  )}
                  {isSensitiveGroupClassesEnabled && isSensitive && (
                    <ChakraFlex gap="12px" marginTop="4px">
                      <Icon
                        color="neutral.tertiary"
                        name="Eye Slash Regular"
                        size="sm"
                      />
                      <Text color="neutral.secondary" variant="caption">
                        Only you and your provider team can see your
                        registration
                      </Text>
                    </ChakraFlex>
                  )}
                  {recurrence && recurrence !== Recurrence.DOES_NOT_REPEAT && (
                    <ChakraFlex marginTop="4px">
                      <Icon
                        color="neutral.tertiary"
                        name={FAIconName.repeatRegular}
                        size="sm"
                      />
                      <Text
                        color="neutral.secondary"
                        marginLeft="12px"
                        variant="caption"
                      >
                        {getRecurrenceDisplayString(startTime, recurrence)}
                      </Text>
                    </ChakraFlex>
                  )}
                </ChakraBox>
              )}
            </ChakraBox>
            {shouldShowZoomButton && (
              <ChakraBox
                display={{ base: isSummary ? 'none' : 'block', md: 'none' }}
              >
                <ZoomButton
                  isLoading={getZoomURLLoading}
                  onClick={openZoomSession}
                />
              </ChakraBox>
            )}
          </ChakraFlex>
          <ChakraBox marginTop={isSummary ? '4px' : '8px'}>
            {participantsComponent}
          </ChakraBox>
        </ChakraBox>
      </ChakraFlex>

      <ChakraFlex alignItems="center" gap="12px" justifyContent="center">
        {shouldShowZoomButton && (
          <ChakraBox
            display={{ base: isSummary ? 'block' : 'none', md: 'block' }}
          >
            <ZoomButton
              isLoading={getZoomURLLoading}
              onClick={openZoomSession}
            />
          </ChakraBox>
        )}

        {!isSummary && isGroupClass && (
          <GroupClassEventItemMenu
            groupClassEventExternalId={eventExternalId}
            groupClassExternalId={groupClassExternalId}
            patientExternalId={patientExternalId}
            refreshData={refreshData}
          />
        )}
        {scheduleCancellationAllowed && refreshData && (
          <ScheduleViewItemMenu
            appointmentExternalId={eventExternalId}
            onCancelAppointment={refreshData}
            recurrence={recurrence}
          />
        )}
      </ChakraFlex>
    </ChakraFlex>
  );
};

interface AppointmentViewItemProps {
  data: AppointmentDetailResponse;
  isCancellable?: boolean;
  index: number;
  isLast?: boolean;
  isSummary?: boolean;
  refreshData?: () => void;
}

export const AppointmentViewItem = ({
  data,
  isCancellable,
  index,
  isLast = false,
  isSummary = false,
  refreshData,
}: AppointmentViewItemProps) => {
  const participantsComponent = (
    <ParticipantGenerator
      {...data}
      eventExternalId={data.appointmentExternalId}
      isSummary={isSummary}
    />
  );
  return (
    <ScheduleViewItem
      endTime={data.appointmentEndDateTime}
      eventExternalId={data.appointmentExternalId}
      index={index}
      isCancellable={isCancellable}
      isLast={isLast}
      isSummary={isSummary}
      participantsComponent={participantsComponent}
      recurrence={data.recurrence as Recurrence}
      refreshData={refreshData}
      startTime={data.appointmentStartDateTime}
      title={data.appointmentType}
    />
  );
};

export const GroupClassEventViewItem: FC<{
  data: GroupClassEventDetails;
  index: number;
  isLast?: boolean;
  isSummary?: boolean;
  patientExternalId?: string;
  refreshData?: () => void;
}> = ({ data, index, isLast, isSummary, patientExternalId, refreshData }) => {
  const participantsComponent = (
    <ParticipantGenerator
      eventExternalId={data.groupClassEventExternalId}
      isGroupClass
      isSummary={isSummary}
      providerAttendees={data.hosts.map((h) => ({
        externalId: h.externalId,
        name: getPreferredFullName(null, h.firstName, h.lastName),
        providerType: h.providerType,
      }))}
    />
  );
  return (
    <ScheduleViewItem
      endTime={data.endTime}
      eventExternalId={data.groupClassEventExternalId}
      groupClassExternalId={data.groupClassExternalId}
      index={index}
      isCancellable={false}
      isGroupClass
      isLast={isLast}
      isSensitive={data.isSensitive}
      isSummary={isSummary}
      participantsComponent={participantsComponent}
      patientExternalId={patientExternalId}
      recurrence={data.frequency as Recurrence}
      refreshData={refreshData}
      startTime={data.startTime}
      title={data.title}
    />
  );
};
