import { FC, useEffect } from 'react';

import ListLoadingView from '~/components/schedule/BookAppointment/base/LoadingView';
import BookAppointmentStepContainer, {
  ListContainer,
} from '~/components/schedule/BookAppointment/base/StepContainer';
import AppointmentAttendeeCard from '~/components/schedule/BookAppointment/cards/AppointmentAttendeeCard';
import AppointmentUserAvatarCard from '~/components/schedule/BookAppointment/cards/AppointmentUserAvatarCard';
import ErrorViewCard from '~/components/schedule/BookAppointment/cards/ErrorViewCard';
import { ApiCommand } from '~/lib/Api';
import { BOOK_APPOINTMENT_STEP } from '~/lib/constants/bookAppointments';
import urlConstants from '~/lib/constants/url.constants';
import { useBookAppointmentContext } from '~/lib/context/BookAppointmentContext';
import { useApi } from '~/lib/hooks';

const topLevelId = 'book-appointment__attendee';

const { getAppointmentAttendees: getAppointmentAttendeesUrl } =
  urlConstants.schedule;

const BookAppointmentAttendeesStep: FC = () => {
  const {
    setNextStep,
    selectedAppointmentType: selectedProvider,
    patientId,
    setAvailableAttendees,
    selectedOptionalAttendees,
    setSelectedOptionalAttendees,
    availableAttendees,
    requiredAttendees,
  } = useBookAppointmentContext();

  const {
    error,
    sendRequest: getAppointmentAttendees,
    loading: isLoading,
  } = useApi<GetAppointmentAttendeesResponse>();

  const fetchAppointmentAttendees = () => {
    /*
      Provider type group is not exposed in the top level provider type object.
      It is only exposed in the appointment type object but it is the same for all appointment types
      given a provider type.
  
      Use the first appointment type to get the provider type group.
       */
    const providerTypeGroup =
      selectedProvider?.appointmentTypes[0].appointmentTypeGroup;

    if (availableAttendees.length === 0 && selectedProvider) {
      getAppointmentAttendees({
        url: getAppointmentAttendeesUrl(patientId, providerTypeGroup),
        command: ApiCommand.GET,
        callback: (response) => {
          if (response?.data) {
            setAvailableAttendees(response?.data);
          }
        },
      });
    }
  };

  useEffect(fetchAppointmentAttendees, [patientId, selectedProvider]);

  const handleForwardNavigation = () => {
    setNextStep(BOOK_APPOINTMENT_STEP.duration);
  };

  const handleBackwardNavigation = () => {
    setNextStep(BOOK_APPOINTMENT_STEP.appointmentType);
  };

  const handleShowOptionalAttendees = () => {
    setNextStep(BOOK_APPOINTMENT_STEP.optionalAttendees);
  };

  const handleRemoveOptionalAttendee = (attendee: BookAppointmentAttendee) => {
    const { externalId } = attendee;
    if (externalId) {
      setSelectedOptionalAttendees(
        selectedOptionalAttendees.filter((e) => e.externalId !== externalId),
      );
    }
  };

  const shouldShowOptionalAttendees =
    availableAttendees.filter((e) => e.isRequired === false).length > 0;

  return (
    <BookAppointmentStepContainer
      auxButton={
        shouldShowOptionalAttendees && {
          onClick: handleShowOptionalAttendees,
          title: 'Add someone else',
        }
      }
      isBackButtonVisible
      isContinueButtonDisabled={Boolean(error)}
      isLoading={isLoading}
      onBackwardNavigation={handleBackwardNavigation}
      onForwardNavigation={handleForwardNavigation}
      stepId={topLevelId}
      title="The following people will be invited to this appointment"
    >
      {error && <ErrorViewCard onTryAgain={fetchAppointmentAttendees} />}
      {!error && (
        <ListContainer>
          {isLoading ? (
            <ListLoadingView listType="userAvatar" />
          ) : (
            <>
              {selectedProvider && (
                <AppointmentUserAvatarCard
                  id={selectedProvider.providerExternalId}
                  isHoverDisabled
                  isSelected={false}
                  key={selectedProvider.providerExternalId}
                  primaryLabel={selectedProvider.providerName}
                  secondaryLabel={selectedProvider.providerType}
                  userType="provider"
                />
              )}

              {requiredAttendees?.map((attendee) => {
                return (
                  <AppointmentAttendeeCard
                    attendee={attendee}
                    isHoverDisabled
                    key={attendee.externalId}
                  />
                );
              })}
              {selectedOptionalAttendees?.map((attendee) => {
                return (
                  <AppointmentAttendeeCard
                    attendee={attendee}
                    isRemoveButtonVisible
                    key={attendee.externalId}
                    onRemove={() => handleRemoveOptionalAttendee(attendee)}
                  />
                );
              })}
            </>
          )}
        </ListContainer>
      )}
    </BookAppointmentStepContainer>
  );
};

export default BookAppointmentAttendeesStep;
