import { Text } from '@equip.health/ui';

type ErrorMessageProps = {
  color?: string;
  id: string;
  margin?: string;
  message: string;
  textAlign?: string;
};

const ErrorMessage = ({
  color = 'critical.text',
  id,
  margin,
  message,
  textAlign,
}: ErrorMessageProps) =>
  !message ? null : (
    <Text
      align={textAlign}
      color={color}
      id={`${id}__error-message`}
      margin={margin}
    >
      {message}
    </Text>
  );

export default ErrorMessage;
