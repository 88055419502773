import { FC, useEffect, useMemo, useState } from 'react';

import {
  ChakraBox,
  ChakraSimpleGrid,
  DatePicker,
  EquipUIFireflyV1Theme,
  NumberInput,
  TextInput,
  format,
  getAgeFromDob,
} from '@equip.health/ui';

import FormBackButton from '~/components/common/FormBackButton';
import FormButtonBar from '~/components/common/FormButtonBar';
import StateSelect from '~/components/common/StateSelect';
import { MAX_NAME_LENGTH } from '~/lib/constants';
import { textInputLabelProps } from '~/lib/constants/inquiry.constants';
import { validateAgeField } from '~/lib/util/inquiry.util';

const TOP_LEVEL_ID = 'referral-step-two';

const { captionSemibold } = EquipUIFireflyV1Theme.typography;

export type PatientInfo = {
  age: number;
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  state: string;
};

type PartialPatientInfoForm = Partial<PatientInfo>;

type PatientInfoProps = {
  advanceStep: () => void;
  formData: PatientInfo;
  previousStep: () => void;
  updateTextFields: (
    keys: Array<string>,
    newFormData: Partial<PatientInfo>,
  ) => void;
};

const PatientInfoForm: FC<PatientInfoProps> = ({
  advanceStep,
  formData,
  previousStep,
  updateTextFields,
}) => {
  const [isDateOfBirthValid, setIsDateOfBirthValid] = useState<boolean>(false);
  const formIsInvalid = useMemo<boolean>(() => {
    return (
      Object.keys(formData)
        .filter((key) => !['age', 'dateOfBirth'].includes(key))
        .some((key) => !formData[key]?.trim()) ||
      !(formData.age || (formData.age === 0 && isDateOfBirthValid))
    );
  }, [formData]);

  const handleChange = (
    keys: string[],
    values: Array<string | number | boolean>,
  ) => {
    const newData: PartialPatientInfoForm = {};

    keys.forEach((key: string, i: number) => {
      const value = values[i].toString();
      if (key === 'age' && formData.dateOfBirth) {
        newData.dateOfBirth = null;
      }
      if (value.length <= MAX_NAME_LENGTH) {
        newData[key] = value;
      }
    });

    updateTextFields(keys, newData);
  };

  useEffect(() => {
    if (formData.dateOfBirth && isDateOfBirthValid) {
      updateTextFields(['age'], {
        ...formData,
        age: getAgeFromDob(formData.dateOfBirth),
      });
    }
  }, [formData.dateOfBirth, isDateOfBirthValid]);

  return (
    <ChakraBox>
      <FormBackButton
        {...captionSemibold}
        id={TOP_LEVEL_ID}
        onClickBack={previousStep}
      />
      <ChakraSimpleGrid columns={1} spacing="48px">
        <ChakraSimpleGrid columns={2} display={{ sm: 'grid' }} spacing="16px">
          <TextInput
            {...textInputLabelProps}
            formMarginBottom="16px"
            id={`${TOP_LEVEL_ID}__first-name`}
            isRequired
            label="Patient's first name"
            onChange={(e) => handleChange(['firstName'], [e.target.value])}
            value={formData.firstName}
          />
          <TextInput
            {...textInputLabelProps}
            formMarginBottom="16px"
            id={`${TOP_LEVEL_ID}__last-name`}
            isRequired
            label="Patient's last name"
            onChange={(e) => handleChange(['lastName'], [e.target.value])}
            value={formData.lastName}
          />
        </ChakraSimpleGrid>
        <StateSelect
          label="Patient's current state"
          onSelect={(st) => handleChange(['state'], [st])}
          state={formData.state}
        />
        <ChakraSimpleGrid columns={2} display={{ sm: 'grid' }} spacing="16px">
          <ChakraBox>
            <DatePicker
              {...captionSemibold}
              color="mono.70"
              id={`${TOP_LEVEL_ID}__date-of-birth`}
              label="Patient's date of birth (optional)"
              labelForErrorMessage="Date of birth"
              maxDate={new Date()}
              onSelect={(date) =>
                handleChange(['dateOfBirth'], [date ? format(date) : ''])
              }
              onValidateDate={(isValid: boolean): void =>
                setIsDateOfBirthValid(isValid)
              }
              placeHolderText="MM/DD/YYYY"
              value={formData.dateOfBirth}
            />
          </ChakraBox>
          <NumberInput
            {...textInputLabelProps}
            id={`${TOP_LEVEL_ID}__age`}
            isRequired
            label="Patient's age"
            min={0}
            onChange={(val) => {
              handleChange(['age'], [validateAgeField(val)]);
            }}
            value={formData.age}
          />
        </ChakraSimpleGrid>
      </ChakraSimpleGrid>
      <FormButtonBar
        {...captionSemibold}
        containerMarginTop="40px"
        id={TOP_LEVEL_ID}
        isProceedButtonDisabled={formIsInvalid}
        isRightIcon={false}
        onClickProceed={advanceStep}
      />
    </ChakraBox>
  );
};

export default PatientInfoForm;
