import { FC } from 'react';

import {
  ChakraVStack,
  EquipUIFireflyV2Theme,
  FAIconName,
  Icon,
  PopoverToolTip,
  Text,
} from '@equip.health/ui';

const { neutral } = EquipUIFireflyV2Theme.colors;
const NonEditablePopoverToolTip: FC = () => (
  <PopoverToolTip
    placement="top-start"
    trigger={
      <Icon color={neutral.secondary} name={FAIconName.infoCircleRegular} />
    }
  >
    <ChakraVStack align="start" spacing="16px">
      <Text color="white" variant="caption">
        Some info can&#39;t be changed via the app.
      </Text>
      <Text color="white" variant="caption">
        If you need help, please reach out to the Equip team.
      </Text>
    </ChakraVStack>
  </PopoverToolTip>
);

export default NonEditablePopoverToolTip;
