import { FC, useEffect, useState } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraMenu,
  ChakraMenuButton,
  ChakraMenuItem,
  ChakraMenuList,
  EquipUIFireflyV1Theme,
  EquipUIFireflyV2Theme,
  FAIconName,
  Icon,
  Text,
  useDisclosure,
} from '@equip.health/ui';

import { useUserProfileContext } from '~/lib/context/UserProfileContext';

import CancelAppointmentModal from './CancelAppointmentModal';
import { ApiCommand } from '../../lib/Api';
import urlConstants from '../../lib/constants/url.constants';
import { useApi } from '../../lib/hooks';
import { Recurrence } from '../../lib/util/schedule.util';
import GroupClassDetailModal from '../groupClasses/GroupClassDetailModal';

const { mono } = EquipUIFireflyV1Theme.colors;
const { neutral } = EquipUIFireflyV2Theme.colors;

const ScheduleViewItemMenu: FC<{
  appointmentExternalId: string;
  onCancelAppointment: () => void;
  recurrence: string;
}> = ({ appointmentExternalId, onCancelAppointment, recurrence }) => {
  const [showCancelAppointmentModal, setShowCancelAppointmentModal] =
    useState<boolean>(false);

  return (
    <>
      <CancelAppointmentModal
        appointmentId={appointmentExternalId}
        isOpen={showCancelAppointmentModal}
        isRecurring={recurrence && recurrence !== Recurrence.DOES_NOT_REPEAT}
        onCancelAppointment={onCancelAppointment}
        onClose={() => setShowCancelAppointmentModal(false)}
      />

      <ChakraMenu placement="bottom-end">
        <ChakraMenuButton as={ChakraBox} cursor="pointer">
          <Icon
            color={neutral.primary}
            name={FAIconName.ellipsisVerticalRegular}
            size="md"
          />
        </ChakraMenuButton>
        <ChakraMenuList border={`solid 1px ${neutral.line}`}>
          <ChakraMenuItem
            onClick={() => setShowCancelAppointmentModal(true)}
            paddingX="0"
          >
            <ChakraFlex paddingX="16px" paddingY="6px">
              <Icon
                color={neutral.primary}
                name={FAIconName.trashCanRegular}
                size="sm"
              />{' '}
              <Text color="neutral.primary" marginLeft="12px" variant="body">
                Cancel event
              </Text>
            </ChakraFlex>
          </ChakraMenuItem>
        </ChakraMenuList>
      </ChakraMenu>
    </>
  );
};

type GroupClassEventItemMenuProps = {
  groupClassEventExternalId: string;
  groupClassExternalId: string;
  patientExternalId?: string;
  refreshData: () => void;
};

export const GroupClassEventItemMenu: FC<GroupClassEventItemMenuProps> = ({
  groupClassEventExternalId,
  groupClassExternalId,
  patientExternalId,
  refreshData,
}) => {
  const { userProfile } = useUserProfileContext();
  const TOP_LEVEL_ID = `event-options_${groupClassEventExternalId}`;

  const {
    isOpen: isGroupClassDetailModalOpen,
    onOpen: onGroupClassDetailModalOpen,
    onClose: onGroupClassDetailModalClose,
  } = useDisclosure();

  const {
    sendRequest: sendDeclineEventRequest,
    statusCode: declineEventStatusCode,
  } = useApi<ApiResponse<string>>();
  // TODO handle error declining event by showing error bar (EQ-13096)

  const { data: groupClassData, sendRequest: fetchGroupClassData } =
    useApi<ApiResponse<GroupClass>>();

  const handleViewClassDetailsClick = () => {
    fetchGroupClassData({
      callback: (data) => {
        if (data?.data) onGroupClassDetailModalOpen();
      },
      command: ApiCommand.GET,
      options: patientExternalId
        ? { 'patient-external-id': patientExternalId }
        : undefined,
      url: urlConstants.schedule.getGroupClassById(groupClassExternalId),
    });
  };

  const onDeclineEvent = () => {
    sendDeclineEventRequest({
      command: ApiCommand.POST,
      options: patientExternalId ? { patientExternalId } : {},
      url: urlConstants.schedule.declineGroupClassEvent(
        groupClassEventExternalId,
      ),
    });
  };

  useEffect(() => {
    if (declineEventStatusCode === 200) {
      refreshData();
    }
  }, [declineEventStatusCode]);

  return (
    <>
      <GroupClassDetailModal
        groupClass={groupClassData?.data}
        isOpen={isGroupClassDetailModalOpen}
        isProxy={userProfile?.isProxy || !userProfile?.isPatient}
        onClassRegistrationChange={refreshData}
        onClose={onGroupClassDetailModalClose}
        patientExternalId={patientExternalId}
      />
      <ChakraMenu id={TOP_LEVEL_ID} placement="bottom-end">
        <ChakraMenuButton as={ChakraBox} cursor="pointer">
          <Icon
            color={neutral.primary}
            name={FAIconName.ellipsisVerticalRegular}
            size="md"
          />
        </ChakraMenuButton>
        <ChakraMenuList border={`solid 1px ${mono[10]}`}>
          <ChakraMenuItem onClick={onDeclineEvent} paddingX="0">
            <ChakraFlex paddingX="16px" paddingY="6px">
              <Icon
                color={neutral.primary}
                name={FAIconName.eyeSlashRegular}
                size="sm"
              />{' '}
              <Text color="mono.90" marginLeft="12px" variant="body">
                Decline this event
              </Text>
            </ChakraFlex>
          </ChakraMenuItem>
          <ChakraMenuItem onClick={handleViewClassDetailsClick} paddingX="0">
            <ChakraFlex paddingX="16px" paddingY="6px">
              <Icon
                color={neutral.primary}
                name={FAIconName.infoCircleRegular}
                size="sm"
              />{' '}
              <Text color="mono.90" marginLeft="12px" variant="body">
                View class details
              </Text>
            </ChakraFlex>
          </ChakraMenuItem>
        </ChakraMenuList>
      </ChakraMenu>
    </>
  );
};

export default ScheduleViewItemMenu;
