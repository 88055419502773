import { useMemo, useRef, useState } from 'react';

import {
  AngleLeft,
  Button,
  ChakraBox,
  ChakraFlex,
  ChakraText,
  Email,
  EquipUIFireflyV1Theme,
  PhoneNumber,
  TextInput,
} from '@equip.health/ui';
import { validate as validateEmail } from 'email-validator';
import { DateTime } from 'luxon';
import ReCAPTCHA from 'react-google-recaptcha';

import ErrorMessage from '~/components/common/ErrorMessage';
import { PHONE_NUMBER_INPUT_PLACEHOLDER } from '~/lib/constants';
import { textInputLabelProps } from '~/lib/constants/inquiry.constants';
import {
  formatSelectedSlotDateString,
  isRequestedTimeSlotNotAvailableClientError,
} from '~/lib/util/schedule.util';
import {
  parsePhoneNumberWithDefaultCountry,
  validatePhoneNumber,
} from '~/lib/utils';

const { captionSemibold, h4, paragraphSemibold } =
  EquipUIFireflyV1Theme.typography;
const { mono, teal } = EquipUIFireflyV1Theme.colors;

const TOP_LEVEL_ID = 'inquiry__schedule-appointment__add-contact-info';

const { VITE_GOOGLE_RECAPTCHA_KEY } = import.meta.env;

type AddContactInfoProps = {
  email: string;
  error: string;
  isLoading: boolean;
  onBackClick: () => void;
  onConfirmClick: (email: string, phone: string, token: string) => void;
  phone: string;
  selectedSlot: string;
};

const AddContactInfo = ({
  email,
  error,
  isLoading,
  onBackClick,
  onConfirmClick,
  phone,
  selectedSlot,
}: AddContactInfoProps) => {
  const [contactEmail, setContactEmail] = useState<string>(email);
  const [contactPhone, setContactPhone] = useState<string>(phone);
  const selectedSlotDt = DateTime.fromJSDate(new Date(selectedSlot));

  const isDisabled = useMemo<boolean>(
    () =>
      isLoading ||
      !validateEmail(contactEmail) ||
      !validatePhoneNumber(contactPhone),
    [contactEmail, contactPhone, isLoading],
  );

  const contactPhoneValue = parsePhoneNumberWithDefaultCountry(contactPhone);

  const recaptchaRef = useRef(null);

  const handleConfirm = (token: string): void => {
    onConfirmClick(contactEmail, contactPhone, token);
  };

  return (
    <>
      <ChakraFlex
        align="center"
        direction="column"
        maxWidth="570px"
        width="100%"
      >
        <ChakraFlex
          align="center"
          justify="space-between"
          paddingBottom="22.5px"
          width="100%"
        >
          <ChakraBox
            _hover={{ backgroundColor: 'brand.50', cursor: 'pointer' }}
            borderRadius="16px"
            id={`${TOP_LEVEL_ID}__back-button`}
            onClick={onBackClick}
            paddingY="16px"
          >
            <AngleLeft fill={teal[100]} height="10.2px" />
          </ChakraBox>
          <ChakraText {...h4} id={`${TOP_LEVEL_ID}__title`}>
            Please confirm your contact information
          </ChakraText>
          <ChakraBox width="50px" />
        </ChakraFlex>
        <ChakraFlex align="center" paddingBottom="51px">
          <ChakraText
            {...paragraphSemibold}
            color="mono.70"
            id={`${TOP_LEVEL_ID}__selected-slot-time-date`}
          >
            {formatSelectedSlotDateString(selectedSlotDt)}
          </ChakraText>
          <ChakraText
            {...captionSemibold}
            _hover={{ cursor: 'pointer' }}
            color="primary.100"
            id={`${TOP_LEVEL_ID}__edit-button`}
            marginLeft="16px"
            onClick={onBackClick}
          >
            Change
          </ChakraText>
        </ChakraFlex>

        <ChakraBox marginBottom="44px" width="100%">
          <PhoneNumber
            {...captionSemibold}
            id={`${TOP_LEVEL_ID}__phone-number`}
            isInvalid={contactPhone && !validatePhoneNumber(contactPhone)}
            isRequired
            label="Mobile phone number"
            labelOffset="0px"
            onChange={(value: string) => {
              setContactPhone(value);
            }}
            placeholder={PHONE_NUMBER_INPUT_PLACEHOLDER}
            value={contactPhoneValue}
          />
        </ChakraBox>

        <TextInput
          {...textInputLabelProps}
          errorMessage="Please enter a valid email"
          id={`${TOP_LEVEL_ID}__email`}
          isInvalid={contactEmail && !validateEmail(contactEmail)}
          isRequired
          label="Email address"
          onChange={(e) => {
            setContactEmail(e.target.value);
          }}
          startIcon={<Email fill={mono[50]} width="20px" />}
          type="email"
          value={contactEmail}
        />

        <ChakraBox marginTop="40px" width="100%">
          <ErrorMessage
            color="mono.70"
            id={`${TOP_LEVEL_ID}__save-appointment`}
            margin="0 0 16px"
            message={error}
            textAlign="center"
          />
          <Button
            {...captionSemibold}
            colorScheme="teal"
            fontSize="16px"
            id={`${TOP_LEVEL_ID}__confirm`}
            isDisabled={
              isDisabled || isRequestedTimeSlotNotAvailableClientError(error)
            }
            isFullWidth
            isLoading={isLoading}
            onClick={() => recaptchaRef.current?.execute()}
          >
            Finish scheduling
          </Button>
        </ChakraBox>
      </ChakraFlex>
      <ReCAPTCHA
        onChange={handleConfirm}
        ref={recaptchaRef}
        sitekey={VITE_GOOGLE_RECAPTCHA_KEY}
        size="invisible"
      />
    </>
  );
};

export default AddContactInfo;
