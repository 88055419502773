import { isNil } from 'lodash';

import { CHAT_EMERGENCY_CONSENT } from '../constants';
import StringUtil from '../util/string.util';
import PrivacyPolicyDocument from '~/lib/legalDocuments/privacyPolicy';
import TermsOfUseDocument from '~/lib/legalDocuments/termsOfUse';

export interface Consent {
  consentName: string;
  consentVersion: string;
  createAt: string;
}

export interface LegalDocumentType {
  date: string;
  key: string;
  name: string;
  version: string;
  Component: React.FC;
}

export const ChatEmergencyConsent = {
  consentName: CHAT_EMERGENCY_CONSENT.apiPropValue,
  consentVersion: CHAT_EMERGENCY_CONSENT.version,
};

enum LegalDocuments {
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERMS_OF_USE = 'TERMS_OF_USE',
}

export const LEGAL_DOCUMENTS: Record<LegalDocuments, LegalDocumentType> = {
  [LegalDocuments.TERMS_OF_USE]: {
    Component: TermsOfUseDocument,
    date: '08-10-2023',
    key: 'Terms of use',
    name: 'Terms of Use',
    version: '2023.08.10',
  },
  [LegalDocuments.PRIVACY_POLICY]: {
    Component: PrivacyPolicyDocument,
    date: '12-15-2022',
    key: 'Privacy policy',
    name: 'Privacy Policy',
    version: '1.0',
  },
};

const getAllLegalDocuments = (): LegalDocumentType[] => {
  return Object.keys(LEGAL_DOCUMENTS).map(
    (key): LegalDocumentType => LEGAL_DOCUMENTS[key],
  );
};

export const joinLegalDocumentNames = (
  legalDocuments: LegalDocumentType[],
): string => {
  const documentNames = legalDocuments.map((document) => document.name);
  return StringUtil.joinStringList(documentNames);
};

export const getPendingLegalDocuments = (
  consents: Consent[],
): LegalDocumentType[] => {
  if (isNil(consents) || consents.length === 0) {
    return getAllLegalDocuments();
  }

  const pendingLegalDocuments = getAllLegalDocuments().reduce<
    LegalDocumentType[]
  >((prev, currentLegalDoc) => {
    const consentedLegalDocument = consents.find(
      (consent) =>
        consent.consentName === currentLegalDoc.key &&
        consent.consentVersion === currentLegalDoc.version,
    );

    if (consentedLegalDocument === undefined) {
      return [...prev, currentLegalDoc];
    }
    return [...prev];
  }, []);

  return pendingLegalDocuments;
};
