import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UserPreferencesSliceState {
  isWeightPrivacyModeEnabled: boolean;
}

const initialState: UserPreferencesSliceState = {
  isWeightPrivacyModeEnabled: true,
};

export const userPreferencesSlice = createSlice({
  initialState,
  name: 'userPreferences',
  reducers: {
    setIsWeightPrivacyModeEnabled: (state, action: PayloadAction<boolean>) => {
      state.isWeightPrivacyModeEnabled = action.payload;
    },
  },
});

export const { setIsWeightPrivacyModeEnabled } = userPreferencesSlice.actions;
export default userPreferencesSlice.reducer;
