export const VITE_MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN;
export const VITE_API_GATEWAY = import.meta.env.VITE_API_GATEWAY;
export const VITE_API_REQUEST_TIMEOUT_MS = import.meta.env
  .VITE_API_REQUEST_TIMEOUT_MS;
export const VITE_AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
export const VITE_EQUIP_BEHAVIORAL_HEALTH_ADDRESS = import.meta.env
  .VITE_EQUIP_BEHAVIORAL_HEALTH_ADDRESS;
export const VITE_EQUIP_WEBSITE_ADDRESS = import.meta.env
  .VITE_EQUIP_WEBSITE_ADDRESS;

export const VITE_SENDBIRD_APP_ID = import.meta.env.VITE_SENDBIRD_APP_ID;

export const VITE_MYEQUIP_APP_VERSION = import.meta.env
  .VITE_MYEQUIP_APP_VERSION;

export const VITE_NR_ACCOUNT_ID = import.meta.env.VITE_NR_ACCOUNT_ID;
export const VITE_NR_AGENT_ID = import.meta.env.VITE_NR_AGENT_ID;
export const VITE_NR_ALLOWED_ORIGIN = import.meta.env.VITE_NR_ALLOWED_ORIGIN;
export const VITE_NR_APPLICATION_ID = import.meta.env.VITE_NR_APPLICATION_ID;
export const VITE_NR_LICENSE_KEY = import.meta.env.VITE_NR_LICENSE_KEY;
export const VITE_NR_TRUST_KEY = import.meta.env.VITE_NR_TRUST_KEY;
