import { useEffect, useMemo, useState } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  Modal,
  useBreakpointValue,
} from '@equip.health/ui';
import { Member } from '@sendbird/chat/groupChannel';
import { DateTime } from 'luxon';

import DocumentList from './DocumentList';
import ParticipantList from './ParticipantList';
import ModalHeader from '~/components/common/modals/ModalHeader';
import NavItem from '~/components/common/NavItem';
import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { useChatContext } from '~/lib/context/ChatContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useApi } from '~/lib/hooks';
import { sortChannelMembers } from '~/lib/util/chat/chat.utils';

interface ChannelDetailsModalProps {
  onClose: () => void;
  isOpen: boolean;
  members: Member[];
  patientId: string;
  channelId: string;
}

const id = 'channel-details';

enum LIST_TYPE {
  participants = 'PARTICIPANTS',
  documents = 'DOCUMENTS',
}

const ChannelDetailsModal = ({
  onClose,
  isOpen,
  members,
  patientId,
  channelId,
}: ChannelDetailsModalProps) => {
  const { userProfile } = useUserProfileContext();
  const modalSize = useBreakpointValue({ base: 'full', md: 'sm' });

  const { chatIndicatorCache, setChatIndicatorCache } = useChatContext();
  const newPatientDocumentReceived = chatIndicatorCache?.newPatientDocument;

  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [patientDocuments, setPatientDocuments] = useState<PatientDocument[]>(
    [],
  );
  const [activeListType, setActiveListType] = useState<LIST_TYPE>(
    LIST_TYPE.participants,
  );

  const sortedMembers = useMemo(() => {
    return sortChannelMembers(members, userProfile.externalId);
  }, [members, userProfile]);

  const { sendRequest: getPatientDocuments } =
    useApi<PatientDocumentsResponse>();

  useEffect(() => {
    if (isOpen && (isInitialLoad || newPatientDocumentReceived)) {
      getPatientDocuments({
        url: urlConstants.clinicalData.getDocuments(patientId, channelId),
        command: ApiCommand.GET,
        callback: (response) => {
          if (response?.data) {
            const documents = response.data;

            documents.sort((a, b) => {
              const dateA = DateTime.fromISO(a.createdAt);
              const dateB = DateTime.fromISO(b.createdAt);

              return dateA > dateB ? -1 : 1;
            });
            setPatientDocuments(documents);
            setIsInitialLoad(false);
            setChatIndicatorCache({
              ...chatIndicatorCache,
              newPatientDocument: false,
            });
          }
        },
      });
    }
  }, [isOpen, newPatientDocumentReceived]);

  return (
    <Modal
      hideCloseButton
      hideHeader
      isOpen={isOpen}
      onClose={onClose}
      size={modalSize}
    >
      <ChakraBox>
        <ModalHeader id={id} onClose={onClose} title="Provider team" />
        <ChakraBox borderBottom="1px solid rgba(0, 0, 0, 0.1)">
          <ChakraFlex alignItems="center">
            <NavItem
              isSelected={activeListType === LIST_TYPE.participants}
              marginRight="24px"
              onClick={() => setActiveListType(LIST_TYPE.participants)}
              title="Participants"
            />
            <NavItem
              isSelected={activeListType === LIST_TYPE.documents}
              marginLeft="24px"
              marginRight="24px"
              onClick={() => setActiveListType(LIST_TYPE.documents)}
              title="Files"
            />
          </ChakraFlex>
        </ChakraBox>
        <ChakraBox
          height={{ base: 'unset', md: '50vh', lg: '55vh' }}
          overflow="scroll"
          paddingTop="24px"
        >
          {activeListType === LIST_TYPE.participants && (
            <ParticipantList id={id} members={sortedMembers} />
          )}
          {activeListType === LIST_TYPE.documents && (
            <DocumentList documents={patientDocuments} />
          )}
        </ChakraBox>
      </ChakraBox>
    </Modal>
  );
};

export default ChannelDetailsModal;
