import { EquipUIFireflyV2Theme } from '@equip.health/ui';

const { colors, typography } = EquipUIFireflyV2Theme;
const { body } = typography;

const defaultStyle = {
  '&multiLine': {
    control: {},
    highlighter: {
      border: `1px solid transparent`,
      maxHeight: '120px',
      overflow: 'hidden',
      padding: '9px',
      ...body,
    },
    input: {
      backgroundColor: '#00000008',
      border: `1px solid ${colors.neutral.tertiary}`,
      borderRadius: '12px',
      outlineColor: colors.primary[50],
      overflow: 'auto',
      padding: '9px',
      ...body,
    },
  },

  '&singleLine': {
    display: 'inline-block',

    highlighter: {
      padding: '1px',
    },
    input: {
      backgroundColor: 'green',
      border: '2px inset',
      padding: '1px',
    },

    width: '180px',
  },

  control: {
    borderRadius: '12px',
    fontSize: '12px',
    fontWeight: '700',
    maxHeight: '120px',
    outline: 'none',
  },

  suggestions: {
    border: '1px solid rgba(0,0,0,0.15)',
    borderRadius: '8px',
    item: {
      '&focused': {
        backgroundColor: '#0000000d',
      },
      minWidth: '280px',
      padding: '16px 10px',
    },
    list: {
      backgroundColor: 'white',
      borderRadius: '8px',
      maxHeight: '45vh',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    overflow: 'hidden',
  },
};

export default defaultStyle;
