import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import { useApi } from '.';
import { ApiCommand } from '../Api';
import urlConstants from '../constants/url.constants';
import { useUserProfileContext } from '../context/UserProfileContext';
import { isOrHasPatientInTreatment } from '../util/profile.util';
import {
  combineAppointmentsWithClasses,
  groupCombinedEventsByDate,
} from '../util/schedule.util';

type UseScheduledEventsState = {
  fetchDataForPage: (n: number) => void;
  groupedEvents: GroupedCombinedScheduleDataListType[];
  isScheduleLoading: boolean;
  pageNumber: number;
  resetAndLoadListData: () => void;
  totalEventCount: number;
  ungroupedEvents: ScheduledEventItem[];
};

type UseScheduledEventsProps = {
  isPastEvents?: boolean;
  onSuccessfulDataFetch?: () => void;
  pageSize?: number;
  patientExternalId?: string;
};

const useScheduledEvents = ({
  isPastEvents = false,
  onSuccessfulDataFetch,
  pageSize = 20,
  patientExternalId,
}: UseScheduledEventsProps): UseScheduledEventsState => {
  const [totalEventCount, setTotalEventCount] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [lastAppointmentsPageNumber, setLastAppointmentsPageNumber] =
    useState<number>(0);
  const [lastClassesPageNumber, setLastClassesPageNumber] = useState<number>(0);
  const [ungroupedAppointments, setUngroupedAppointments] = useState<
    AppointmentDetailResponse[]
  >([]);
  const [ungroupedClasses, setUngroupedClasses] = useState<
    GroupClassEventDetails[]
  >([]);
  const [ungroupedEvents, setUngroupedEvents] = useState<ScheduledEventItem[]>(
    [],
  );
  const [groupedEvents, setGroupedEvents] = useState<
    GroupedCombinedScheduleDataListType[]
  >([]);
  const [isScheduleLoading, setIsScheduleLoading] = useState<boolean>(true);

  const { userProfile } = useUserProfileContext();

  const shouldFetchGroupClassEvents = isOrHasPatientInTreatment(userProfile);

  const {
    data: appointmentsData,
    error: appointmentsError,
    sendRequest: getAppointmentsData,
  } = useApi<ScheduleAPIResponse>();

  const {
    data: groupClassesData,
    error: groupClassesError,
    sendRequest: getGroupClassesData,
  } = useApi<GroupClassEventsResponse>();

  const resetAndLoadListData = () => {
    setTotalEventCount(1);
    setUngroupedAppointments([]);
    setUngroupedClasses([]);
    setUngroupedEvents([]);
    setGroupedEvents([]);
    setLastAppointmentsPageNumber(0);
    setLastClassesPageNumber(0);
    setIsScheduleLoading(true);
    setPageNumber(1);
    fetchSchedule(1);
  };

  const fetchSchedule = (pageNum: number): void => {
    const options = {
      'page-number': pageNum,
      'page-size': pageSize,
    };

    if (isPastEvents) {
      options['filter-by'] = {
        'end-date': new Date().toISOString(),
        status: 'scheduled',
      };
      options['sort-order'] = 'DESC';
    } else {
      /*
        NOTE: 
        Substracting 1 hour from current time to account for active events.
        If the event end time is lower than current time, it will be excluded from the list
        since it would have already finished.
      */
      const todayMinus1H = DateTime.now()
        .minus({ minutes: 60 })
        .toJSDate()
        .toISOString();
      options['filter-by'] = {
        'start-date': todayMinus1H,
        status: 'scheduled',
      };
    }

    if (patientExternalId) {
      options['patient-id'] = patientExternalId;
    }

    getAppointmentsData({
      command: ApiCommand.GET,
      url: urlConstants.schedule.getSchedule,
      options,
    });

    if (shouldFetchGroupClassEvents) {
      if (patientExternalId) {
        options['patient-external-id'] = patientExternalId;
      }
      getGroupClassesData({
        command: ApiCommand.GET,
        url: urlConstants.schedule.getGroupClassEvents,
        options: isPastEvents
          ? {
            ...options,
            'filter-by': {
              'start-date': DateTime.now()
                .minus({ days: 7 })
                .toJSDate()
                .toISOString(),
              ...options['filter-by'], // Start date needs to be first to avoid 400 error
            },
          }
          : options,
      });
    }
  };

  const handleScheduleData = () => {
    if (
      (!appointmentsData && !appointmentsError) ||
      (shouldFetchGroupClassEvents && !groupClassesData && !groupClassesError)
    ) {
      return;
    }
    setIsScheduleLoading(false);

    const {
      data: rawAppointmentsData = [],
      pageNumber: appointmentsPageNumber,
    } = appointmentsData;
    const { data: rawClassesData = [], pageNumber: classesPageNumber } =
      groupClassesData?.data ?? {};

    const timeNow = DateTime.now();
    let numberOfExcludedAppointments = 0;
    const filteredAppointments: AppointmentDetailResponse[] =
      rawAppointmentsData.filter((appt) => {
        const endTime = DateTime.fromISO(appt.appointmentEndDateTime);
        const includeEvent = isPastEvents
          ? timeNow > endTime
          : timeNow < endTime;
        numberOfExcludedAppointments += Number(!includeEvent);
        return includeEvent;
      });
    const filteredClasses: GroupClassEventDetails[] = rawClassesData.filter(
      (appt) => {
        const endTime = DateTime.fromISO(appt.endTime);
        const includeEvent = isPastEvents
          ? timeNow > endTime
          : timeNow < endTime;
        numberOfExcludedAppointments += Number(!includeEvent);
        return includeEvent;
      },
    );

    let allAppointments = [];
    if (appointmentsPageNumber !== lastAppointmentsPageNumber) {
      allAppointments = isPastEvents
        ? [...filteredAppointments, ...ungroupedAppointments]
        : [...ungroupedAppointments, ...filteredAppointments];
      setUngroupedAppointments(allAppointments);
      setLastAppointmentsPageNumber(appointmentsPageNumber);
    }
    let allClasses = [];
    if (classesPageNumber !== lastClassesPageNumber) {
      allClasses = isPastEvents
        ? [...filteredClasses, ...ungroupedClasses]
        : [...ungroupedClasses, ...filteredClasses];
      setUngroupedClasses(allClasses);
      setLastClassesPageNumber(classesPageNumber);
    }

    const allEvents = combineAppointmentsWithClasses(
      allAppointments,
      allClasses,
    );
    setUngroupedEvents(allEvents);

    if (pageNumber === 1) {
      setTotalEventCount(
        rawAppointmentsData.length +
        rawClassesData.length -
        numberOfExcludedAppointments,
      );
    }

    if (!isPastEvents) {
      const groupedData = groupCombinedEventsByDate(allEvents);
      setGroupedEvents(groupedData);
    }

    if (onSuccessfulDataFetch) onSuccessfulDataFetch();
  };

  useEffect(handleScheduleData, [
    appointmentsData,
    groupClassesData,
    appointmentsError,
    groupClassesError,
  ]);

  const fetchDataForPage = (n: number): void => {
    setPageNumber(n);
    fetchSchedule(n);
  };

  return {
    fetchDataForPage,
    groupedEvents,
    isScheduleLoading,
    pageNumber,
    resetAndLoadListData,
    totalEventCount,
    ungroupedEvents,
  };
};

export default useScheduledEvents;
