import { FC, useEffect } from 'react';

import {
  Add,
  Button,
  ChakraFlex,
  ChakraImage,
  ChakraLink,
  ChakraText,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

import GroupIllustrationImg from '~/assets/img/group_illustration.png';
import {
  ADMISSIONS_PHONE_NUMBER,
  GAEventForRouting,
  MY_EQUIP_V2_DIMENSIONS,
} from '~/lib/constants';
import { FormType } from '~/lib/constants/referral.constants';
import GAEventsTracker from '~/lib/util/useGAEventsTracker.util';
import { capitalize } from '~/lib/utils';

const { VITE_EQUIP_WEBSITE_ADDRESS: EQUIP_WEBSITE_ADDRESS } = import.meta.env;

const { paragraph, h3, captionSemibold } = EquipUIFireflyV1Theme.typography;
const { teal } = EquipUIFireflyV1Theme.colors;

type InquiryReferralConfirmationProps = {
  formType: FormType;
  isPayorOutOfNetwork?: boolean;
  patientAge?: number;
  startAnotherReferral?: () => void;
};

const PayorOutOfNetworkBody = () => {
  return (
    <>
      <ChakraText>
        Unfortunately Equip is not in-network yet with the insurance you
        provided us. We are actively working to expand our insurance partners
        and will save your information to reach out if we become in network.
      </ChakraText>
      <br />
      <ChakraText>
        We know that finding a treatment program for yourself or a loved one can
        feel overwhelming, and we also know that there is hope and full recovery
        is possible. To support your search for treatment options, we will email
        you a list of resources on how to find in-network treatment.
      </ChakraText>
      <br />
      <ChakraText>
        {`If you are interested in moving forward with private pay or want to discuss
        your options further, please reach out to our admissions team at `}
        <ChakraLink color="primary.100" href={`tel:${ADMISSIONS_PHONE_NUMBER}`}>
          {ADMISSIONS_PHONE_NUMBER}
        </ChakraLink>
      </ChakraText>
    </>
  );
};

interface ConfirmationBodyProps {
  formType: FormType;
  isInquiry?: boolean;
}

const ConfirmationBody = ({ formType, isInquiry }: ConfirmationBodyProps) => {
  const contact = isInquiry ? 'Admissions ' : '';
  return (
    <ChakraText>
      {`Thank you for your interest in Equip. We have received your ${formType}, and someone from our ${contact}
      team will be in touch with you shortly.`}
    </ChakraText>
  );
};

const InquiryReferralConfirmation: FC<InquiryReferralConfirmationProps> = ({
  formType,
  isPayorOutOfNetwork,
  startAnotherReferral,
}) => {
  const TOP_LEVEL_ID = `${formType}-confirmation`;

  useEffect(() => {
    window.history.replaceState(null, '', 'thankyou');
    GAEventsTracker(GAEventForRouting);
  }, []);

  const returnToHome = () => {
    window.location.href = EQUIP_WEBSITE_ADDRESS;
  };

  const isInquiry = formType === 'inquiry';
  const title = isPayorOutOfNetwork
    ? 'Your insurance is not in network'
    : `${capitalize(formType)} received`;

  return (
    <ChakraFlex
      alignItems="center"
      flexDirection="column"
      justifyContent="flex-start"
      width="100%"
    >
      <ChakraImage
        alt="Form sent"
        id={`${TOP_LEVEL_ID}__submit-image`}
        src={GroupIllustrationImg}
        width={['100%', '100%', '325px', '380px', '380px']}
      />
      <ChakraText
        {...h3}
        color="mono.100"
        fontWeight="500"
        id={`${TOP_LEVEL_ID}__header`}
        paddingTop="40px"
        textAlign="center"
      >
        {title}
      </ChakraText>
      <ChakraFlex
        {...paragraph}
        color="mono.70"
        id={`${TOP_LEVEL_ID}__description`}
        paddingTop="16px"
        textAlign="center"
      />

      <ChakraFlex
        {...paragraph}
        color="mono.70"
        flexDirection="column"
        id={`${TOP_LEVEL_ID}__description`}
        maxWidth={MY_EQUIP_V2_DIMENSIONS.desktopPageMaxWidth}
        paddingTop="16px"
        textAlign="center"
      >
        {isPayorOutOfNetwork ? (
          <PayorOutOfNetworkBody />
        ) : (
          <ConfirmationBody formType={formType} isInquiry={isInquiry} />
        )}
      </ChakraFlex>

      <ChakraFlex gap="8px" justifyContent="center" paddingTop="10">
        {!isInquiry && (
          <Button
            {...captionSemibold}
            colorScheme="teal"
            id={`${TOP_LEVEL_ID}__add`}
            margin="0 16px 0 0"
            onClick={startAnotherReferral}
            padding="0 0 0 12px"
            rightIcon={<Add fill={teal[100]} height="16px" />}
            variant="outline"
          >
            Add another referral
          </Button>
        )}
        <Button
          {...captionSemibold}
          colorScheme="teal"
          id={`${TOP_LEVEL_ID}__return-to-website`}
          onClick={returnToHome}
        >
          Return to website
        </Button>
      </ChakraFlex>
    </ChakraFlex>
  );
};

export default InquiryReferralConfirmation;
