import { FC } from 'react';

import { ChakraFlex, useBreakpointValue } from '@equip.health/ui';

import Loading from '~/components/common/Loading';
import AuthenticatedPageLayout from '~/layouts/AuthenticatedPageLayout';
import { AUTHENTICATED_NAV_LAYOUT } from '~/lib/constants';

const { mobileHeight: mobileNavHeight, desktopHeight: desktopNavHeight } =
  AUTHENTICATED_NAV_LAYOUT;

const AuthenticatedPageLoader: FC = () => {
  const navBarHeight = useBreakpointValue({
    base: mobileNavHeight,
    md: desktopNavHeight,
  });

  return (
    <AuthenticatedPageLayout hiddenFooter>
      <ChakraFlex
        alignItems="center"
        height={`calc(100vh - ${navBarHeight})`}
        justifyContent="center"
      >
        <Loading />
      </ChakraFlex>
    </AuthenticatedPageLayout>
  );
};

export default AuthenticatedPageLoader;
