import { FC, ReactNode, useMemo } from 'react';

import { ChakraBox, Text } from '@equip.health/ui';

import ProfileTitleAndValue from './ProfileTitleAndValue';
import { useProfilePageContext } from '~/lib/context/ProfilePageContext';
import { ProfileSection } from '~/lib/hooks/useProfilePage';
import ProfileField from '~/lib/util/profileFields.util';
import { getPreferredFirstName } from '~/lib/utils';

interface ProfileInfoContainerProps {
  children: ReactNode;
  title: string;
}

const ProfileInfoSection: FC<ProfileInfoContainerProps> = ({
  children,
  title,
}) => {
  return (
    <>
      <Text variant="h5">{title}</Text>
      <ChakraBox
        border={['none', '1px solid rgba(0, 0, 0, 0.1)']}
        borderRadius="16px"
        marginBottom="32px"
        marginTop="16px"
        padding={['0', '0 40px 20px 40px']}
      >
        {children}
      </ChakraBox>
    </>
  );
};

const CONTACT_SECTION_TYPES = [
  ProfileField.EMAIL,
  ProfileField.CELLPHONE,
  ProfileField.CONTACT_PREFERENCES,
  ProfileField.ADDRESS,
  ProfileField.TIMEZONE,
];

const ProfileInfoView: FC = () => {
  const {
    activeProfile,
    isUserProfile,
    profileEditViewPermission: permission,
  } = useProfilePageContext();

  const isContactSectionVisible = useMemo<boolean>(
    () => CONTACT_SECTION_TYPES.some((type) => permission[type]?.isViewable),
    [permission],
  );

  const preferredFirstName = useMemo<string>(
    () =>
      getPreferredFirstName(
        activeProfile?.chosenName ?? '',
        activeProfile?.firstName ?? '',
      ),
    [activeProfile],
  );

  return (
    <ChakraBox paddingTop="32px">
      <ProfileInfoSection title="Basic info">
        <ProfileTitleAndValue
          sectionType={ProfileSection.BASIC_NAME}
          title="Name"
        />
        <ProfileTitleAndValue
          sectionType={ProfileSection.BASIC_DOB}
          title="Date of birth"
        />
        <ProfileTitleAndValue
          sectionType={ProfileSection.BASIC_GENDER}
          title="Gender and pronouns"
        />

        {isUserProfile ? (
          <ProfileTitleAndValue
            sectionType={ProfileSection.BASIC_RELATIONSHIP_TO_PATIENT}
            title="Relationship to patient(s)"
          />
        ) : (
          <ProfileTitleAndValue
            sectionType={ProfileSection.BASIC_RELATIONSHIP_TO_PATIENT}
            title={`Relationship to ${preferredFirstName}`}
          />
        )}
        <ProfileTitleAndValue
          sectionType={ProfileSection.BASIC_LANGUAGE_SPOKEN}
          title="Languages spoken"
        />
        {isUserProfile && (
          <ProfileTitleAndValue
            sectionType={ProfileSection.BASIC_SUPPORTED_BY}
            title="Supported by"
          />
        )}
      </ProfileInfoSection>
      {isContactSectionVisible && (
        <ProfileInfoSection title="Contact info">
          <ProfileTitleAndValue
            sectionType={ProfileSection.CONTACT_EMAIL}
            title="Email address"
          />
          <ProfileTitleAndValue
            sectionType={ProfileSection.CONTACT_PHONE_NUMBER}
            title="Cell phone"
          />
          <ProfileTitleAndValue
            sectionType={ProfileSection.CONTACT_PREFERENCES}
            title="Contact preference"
          />
          <ProfileTitleAndValue
            sectionType={ProfileSection.CONTACT_ADDRESS}
            title="Address"
          />
        </ProfileInfoSection>
      )}
      {isUserProfile && (
        <ProfileInfoSection title="Equip app notifications">
          <ProfileTitleAndValue
            sectionType={ProfileSection.NOTIFICATION_SETTINGS}
            title="Notification preferences"
          />
        </ProfileInfoSection>
      )}
    </ChakraBox>
  );
};

export default ProfileInfoView;
