import { FC, MouseEventHandler } from 'react';

import {
  ChakraFlex,
  ChakraText,
  EquipUITheme,
  Exclamation,
} from '@equip.health/ui';

const { paragraphBold } = EquipUITheme.typography;
export interface CalloutProps {
  onClick: MouseEventHandler<HTMLDivElement>;
}

const Callout: FC<CalloutProps> = ({ onClick }) => (
  <ChakraFlex
    _hover={{
      background: 'brand.50',
    }}
    alignItems="center"
    borderColor="mono.10"
    borderRadius="28px"
    borderWidth="2px"
    cursor="pointer"
    marginTop="12px"
    onClick={onClick}
    padding="10px 20px"
  >
    <Exclamation fill="teal" height="18px" width="18px" />
    <ChakraText
      {...paragraphBold}
      color="brand.500"
      id="callout-text"
      marginLeft="8px"
    >
      What happens after submitting this form?
    </ChakraText>
  </ChakraFlex>
);

export default Callout;
