import { FC } from 'react';

import {
  ChakraFlex,
  EquipUIFireflyV2Theme,
  FAIconName,
  Icon,
  Text,
} from '@equip.health/ui';

import { ListContainer } from '../base/StepContainer';
import { Participant } from '~/components/common/Participant';
import BaseCard, {
  BaseCardProps,
} from '~/components/schedule/BookAppointment/base/BaseCard';

const { neutral } = EquipUIFireflyV2Theme.colors;

export interface AttendeeItemProps {
  id: string;
  name: string;
  label: string;
  userType: EquipUserType;
}
export interface AppointmentOverviewCardProps extends BaseCardProps {
  date: string;
  appointmentTitle: string;
  recurrence?: string;
  time: string;
  attendeeList: AttendeeItemProps[];
}

const AppointmentOverviewCard: FC<AppointmentOverviewCardProps> = ({
  appointmentTitle,
  attendeeList,
  date,
  id,
  recurrence,
  time,
}) => {
  return (
    <BaseCard id={`${id}__container`} isHoverDisabled>
      <ChakraFlex
        direction={{ base: 'column', md: 'row' }}
        gridGap={{ base: '18px', md: '80px' }}
        padding="12px"
      >
        <ChakraFlex alignItems="flex-start" gridGap="8px">
          <Text
            color="neutral.primary"
            id={`${id}__date-label`}
            variant="bodyMedium"
          >
            {date}
          </Text>
        </ChakraFlex>
        <ChakraFlex
          alignItems="flex-start"
          flexDirection="column"
          gridGap="6px"
        >
          <Text
            color="neutral.primary"
            id={`${id}__primary-label`}
            variant="body"
          >
            {`${appointmentTitle} Session`}
          </Text>
          <Text
            color="neutral.primary"
            id={`${id}__time-label`}
            variant="caption"
            whiteSpace="nowrap"
          >
            {time}
          </Text>
          {recurrence && (
            <ChakraFlex marginTop={{ base: '14px', md: '0' }}>
              <Icon
                color={neutral.tertiary}
                name={FAIconName.repeatRegular}
                size="sm"
              />
              <Text
                color="neutral.secondary"
                marginLeft="12px"
                variant="caption"
              >
                {recurrence}
              </Text>
            </ChakraFlex>
          )}
          <ListContainer gridGap="8px" marginTop="14px">
            {attendeeList.map(({ id: attendeeId, name, label, userType }) => {
              return (
                <Participant
                  avatarSize="xs"
                  id={`${id}__${attendeeId}`}
                  key={attendeeId}
                  listSource="schedule"
                  primaryLabel={name}
                  secondaryLabel={label}
                  userId={id}
                  userType={userType}
                />
              );
            })}
          </ListContainer>
        </ChakraFlex>
      </ChakraFlex>
    </BaseCard>
  );
};

export default AppointmentOverviewCard;
