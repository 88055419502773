import { FC, useEffect, useState } from 'react';

import {
  ChakraFlex,
  ChakraTabPanel,
  ChakraTabPanels,
  ChakraTabs,
  ChakraVStack,
} from '@equip.health/ui';

import {
  PatientTabItemProps,
  PatientTabSelector,
} from '~/components/common/PatientTabSelector';
import AddInsuranceCardModal from '~/components/todoList/modal/AddInsuranceCardModal/AddInsuranceCardModal';
import { FamilyTaskSubcategory } from '~/lib/constants/tasks.constants';
import { useTodoListContext } from '~/lib/context/TodoListContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';

import AddVitalsModal from './modal/AddVitalsModal/AddVitalsModal';
import SurveyTaskModal from './modal/SurveyTaskModal';
import TaskModal from './modal/TaskModal';
import TaskCardList from './TaskCardList';
import { YOUR_TASK_TAB_INDEX } from './useTodoList';
import SectionTitle from '../common/SectionTitle';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { EVENT } from '~/lib/constants/analytics';

const LOG_WEIGHT_VITAL_TASK_NAME = 'Log weight';

const TOP_LEVEL_ID = 'to-do-list';

const ToDoList: FC = () => {
  const [showAddInsuranceModal, setShowAddInsuranceModal] =
    useState<Task | null>();
  const [showAddVitalModal, setShowAddVitalModal] = useState<Task | null>();

  const [isTaskModalOpen, setIsTaskModalOpen] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>();
  const [isSurveyTaskDetailVisible, setIsSurveyTaskDetailVisible] =
    useState<boolean>(false);
  const { track } = useAnalytics();

  const closeTaskModal = (): void => {
    setIsTaskModalOpen(false);
    setSelectedTask(null);
  };

  const handleSurveyTaskDetailClose = () => {
    setIsSurveyTaskDetailVisible(false);
  };

  const handleTaskStart = (selectedTask: Task) => {
    if (selectedTask?.linkedSurveyId) {
      setSelectedTask(selectedTask);
      setIsSurveyTaskDetailVisible(true);
    } else if (
      selectedTask?.taskSubCategoryName ===
      FamilyTaskSubcategory.INSURANCE_VERIFICATION
    ) {
      setShowAddInsuranceModal(selectedTask);
    } else if (
      selectedTask?.taskSubCategoryName === FamilyTaskSubcategory.VITALS &&
      selectedTask?.taskName === LOG_WEIGHT_VITAL_TASK_NAME
    )
      setShowAddVitalModal(selectedTask);
    else {
      setSelectedTask(selectedTask);
      setIsTaskModalOpen(true);
    }
  };

  const {
    isProxySupport,
    tabList,
    refreshValue,
    resetAndIntializeTodoList,
    setRefreshValue,
    setTabIndex,
    tabIndex,
  } = useTodoListContext();

  useEffect(resetAndIntializeTodoList, []);

  const { userProfile } = useUserProfileContext();
  const { externalId: currentUserExternalId } = userProfile ?? {};

  const patientSelectorItems: PatientTabItemProps[] =
    tabList?.map((t) => ({
      badgeCount: t.tabCount || undefined, // If tabCount is 0, don't show number in badge
      patientId: t.userId,
      patientName: t.tabName,
    })) ?? [];

  const handleUserSelectorClick = (patientId: string) => {
    track(EVENT.HomeTodoListPatientSelectorClick);
    const index = tabList.findIndex((t) => t.userId === patientId);
    setTabIndex(index);
  };

  return (
    <>
      <ChakraVStack
        alignItems="start"
        as="section"
        gridGap="24px"
        id={`${TOP_LEVEL_ID}__section`}
      >
        {isProxySupport && (tabList ?? []).length > 0 ? (
          <>
            <PatientTabSelector
              id={TOP_LEVEL_ID}
              onPatientClick={handleUserSelectorClick}
              patientTabs={patientSelectorItems}
              title="To-dos"
            />
            <ChakraTabs
              index={tabIndex}
              marginTop="0"
              onChange={setTabIndex}
              size="md"
              variant="enclosed"
              width="100%"
            >
              <ChakraTabPanels>
                {tabList.map((tab, index) => (
                  <ChakraTabPanel key={tab.userId} padding="0">
                    <TaskCardList
                      handleTaskStart={handleTaskStart}
                      isForCurrentUser={index === YOUR_TASK_TAB_INDEX}
                      key={tab.userId}
                      patientId={tab.userId}
                      patientName={tab.tabName}
                    />
                  </ChakraTabPanel>
                ))}
              </ChakraTabPanels>
            </ChakraTabs>
          </>
        ) : (
          <>
            {currentUserExternalId && (
              <ChakraFlex flexDirection="column" gridGap="24px" width="100%">
                <SectionTitle id={TOP_LEVEL_ID} title="To-dos" />
                <TaskCardList
                  handleTaskStart={handleTaskStart}
                  isForCurrentUser
                  patientId={currentUserExternalId}
                />
              </ChakraFlex>
            )}
          </>
        )}
      </ChakraVStack>
      <AddInsuranceCardModal
        reloadTodoList={() => {
          setRefreshValue(refreshValue + 1);
        }}
        setShowAddInsuranceModal={setShowAddInsuranceModal}
        showAddInsuranceModal={showAddInsuranceModal}
      />
      <AddVitalsModal
        reloadTodoList={() => setRefreshValue(refreshValue + 1)}
        setShowAddVitalModal={setShowAddVitalModal}
        showAddVitalModal={showAddVitalModal}
      />
      {selectedTask && (
        <SurveyTaskModal
          isVisible={isSurveyTaskDetailVisible}
          onClose={handleSurveyTaskDetailClose}
          task={selectedTask}
        />
      )}
      <TaskModal
        estimatedDuration={selectedTask?.estimatedDuration}
        handleClose={closeTaskModal}
        isOpen={isTaskModalOpen}
        taskDescription={selectedTask?.taskDescription}
        taskName={selectedTask?.taskName}
        taskSubCategoryName={selectedTask?.taskSubCategoryName}
      />
    </>
  );
};

export default ToDoList;
