import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  VITE_API_GATEWAY,
  VITE_API_REQUEST_TIMEOUT_MS,
  VITE_AUTH0_AUDIENCE,
} from '~/lib/constants/env';
import { security } from '~/lib/security';

const baseQuery = fetchBaseQuery({
  baseUrl: VITE_API_GATEWAY,
  prepareHeaders: async (headers) => {
    const access_token = await security.getAccessTokenSilently()({
      authorizationParams: {
        audience: VITE_AUTH0_AUDIENCE,
        scope: 'offline_access',
      },
    });
    if (access_token) {
      headers.set('Authorization', `Bearer ${access_token}`);
    }
    return headers;
  },
  timeout: Number(VITE_API_REQUEST_TIMEOUT_MS),
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

const api = createApi({
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({}),
  keepUnusedDataFor: 100,
  tagTypes: ['vitals', 'group-classes', 'basic-info'],
});

export default api;
