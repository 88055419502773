import api from './api';
import urlConstants from '~/lib/constants/url.constants';
import { getWeightObservationOptions } from '~/lib/constants/vitals.contants';
import { PatientObservationsResponse } from '~/lib/types/vitals';

const { getPatientVitals: getPatientVitalsUrl } = urlConstants.clinicalData;
export interface WeightObservationsRequest {
  patientId: string;
  pageNumber: number;
}

export const vitalsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWeightObservations: build.query<
      PatientObservationsResponse,
      WeightObservationsRequest
    >({
      query: ({ patientId, pageNumber }) => {
        return {
          params: getWeightObservationOptions(pageNumber),
          url: getPatientVitalsUrl(patientId),
        };
      },
    }),
  }),
});

export const { useGetWeightObservationsQuery } = vitalsApi;
