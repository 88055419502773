import * as CronofyElements from 'cronofy-elements';

export enum CalendarResource {
  DATE_TIME_PICKER = 'Date Time Picker',
}

export const CalendarResourceMapper: Record<
  CalendarResource,
  CalendarResourceConfig
> = {
  [CalendarResource.DATE_TIME_PICKER]: {
    element: CronofyElements.DateTimePicker,
    options: {
      config: {
        mode: 'no_confirm',
      },
      styles: {
        colors: {
          button: 'transparent',
          buttonActive: '#007F79',
          buttonActiveText: '#FFFFFF',
          buttonHover: '#E7FDF9',
        },
        prefix: 'scheduler',
      },
      target_id: 'cronofy-date-time-picker',
    },
  },
};

export const AVAILABILITY_RANGE_UPPER_BOUND_WEEKS = 2;

export const AVAILABILITY_SLOT_INTERVAL_MINUTES = 30;

export const QUALIFYING_CALL = 'Qualifying call';

export const AVAILABILITY_BASE_URL = 'https://api.cronofy.com/v1/availability';

export const CRONOFY_ELEMENT_VERSION = 'v1.50.0';

export const CRONOFY_ELEMENT_HEADER = 'cronofy-element';

export const LEAD_TIME_MINUTES = 120;

export const REQUESTED_TIME_SLOT_NOT_AVAILABLE_SERVER_ERROR_MESSAGE =
  'The requested time slot is no longer available';

export const REQUESTED_TIME_SLOT_NOT_AVAILABLE_CLIENT_ERROR_MESSAGE =
  'The requested time is no longer available. Please select a different time.';

export const SAVE_APPOINTMENT_ERROR_MESSAGE =
  'Appointment could not be created';

export enum CronofyAvailabilityError {
  AVAILABILITY_RULE_ID_UNKNOWN = 'availability_rule_id_unknown',
  CALENDAR_ID_INVALID = 'calendar_id_invalid',
  INVALID_FORMAT = 'invalid_format',
  NOT_RECOGNIZED = 'not_recognized',
}

export const ZOOM_BEFORE_AVAILABLE_MINUTES = 15;

type InitializeSaveAppointmentRequestParams = Pick<
  SaveAppointmentRequest,
  | 'appointmentEndDateTime'
  | 'appointmentStartDateTime'
  | 'appointmentType'
  | 'calendarIds'
  | 'createdBy'
  | 'hostExternalId'
  | 'inquiryExternalId'
  | 'otherAttendees'
  | 'patientExternalId'
  | 'patientName'
  | 'subject'
  | 'taskExternalId'
>;

export const initializeSaveAppointmentRequest = ({
  appointmentEndDateTime,
  appointmentStartDateTime,
  appointmentType,
  calendarIds,
  createdBy,
  hostExternalId,
  inquiryExternalId,
  otherAttendees,
  patientExternalId,
  patientName,
  subject,
  taskExternalId,
}: InitializeSaveAppointmentRequestParams): SaveAppointmentRequest => ({
  appointmentEndDateTime,
  appointmentStartDateTime,
  appointmentType,
  calendarIds,
  createdBy,
  hostExternalId,
  inquiryExternalId,
  otherAttendees,
  patientExternalId,
  patientName,
  subject,
  taskExternalId,
});

export const UNRECOGNIZED_PARTICIPANT_REG_EXP =
  /^participants\[0].members\[(\d+)]/;

export const EMPTY_SCHEDULE_TEXT = {
  noUpcomingEventsTitle: 'No upcoming events',
  noUpcomingEventsMessage:
    "Once scheduled, you'll see upcoming appointments and group classes here.",
  noUpcomingEventsMessageSummary:
    "Once your treatment begins, you'll see upcoming appointments and group classes here.",
  noPastEventsMessage:
    "Here's where you'll find a list of past appointments and group classes.",
};
