import { FC } from 'react';

import {
  ChakraFlex,
  EquipUIFireflyV2Theme,
  FAIconName,
  Icon,
  Text,
} from '@equip.health/ui';

const { primary } = EquipUIFireflyV2Theme.colors;
const { cornerRadius } = EquipUIFireflyV2Theme;

interface EmptyListViewProps {
  emptyDescription?: string;
}

const EmptyListView: FC<EmptyListViewProps> = ({ emptyDescription }) => {
  return (
    <ChakraFlex
      alignItems="center"
      backgroundColor="neutral.background.secondary"
      borderRadius={cornerRadius.large}
      gridGap="12px"
      padding="24px"
      width="100%"
    >
      <Icon color={primary[100]} name={FAIconName.checkRegular} />
      <Text textAlign="center">
        {emptyDescription || 'You have no to-dos.'}
      </Text>
    </ChakraFlex>
  );
};

export default EmptyListView;
