import { FC } from 'react';

import { ChakraFlex, Text } from '@equip.health/ui';

const TOP_LEVEL_ID = 'vitals__mini-card';

interface VitalsMiniCardProps {
  description: string;
  id: string;
  unit?: string;
  value: string;
}
const VitalsMiniCard: FC<VitalsMiniCardProps> = ({
  description,
  id,
  unit,
  value,
}) => {
  return (
    <ChakraFlex flexDirection="column" gridGap="8px">
      <ChakraFlex alignItems="baseline" gridGap="8px">
        <Text
          color="neutral.primary"
          id={`${TOP_LEVEL_ID}__${id}__value`}
          variant="h3"
        >
          {value}
        </Text>
        {unit && (
          <Text
            color="neutral.primary"
            id={`${TOP_LEVEL_ID}__${id}__unit`}
            variant="h5"
          >
            {unit}
          </Text>
        )}
      </ChakraFlex>

      <Text
        color="neutral.secondary"
        id={`${TOP_LEVEL_ID}__${id}__description`}
        variant="caption"
      >
        {description}
      </Text>
    </ChakraFlex>
  );
};
export default VitalsMiniCard;
