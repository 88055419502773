export enum QuestionNameConstant {
  sp_imminent_risk_plan = 'sp_imminent_risk_plan',
  sp_imminent_risk_statement = 'sp_imminent_risk_statement',
  sp_internal_coping_skills = 'sp_internal_coping_skills',
  sp_local_ems = 'sp_local_ems',
  sp_names_phones_supports = 'sp_names_phones_supports',
  sp_other_warning_signs = 'sp_other_warning_signs',
  sp_reason_to_live = 'sp_reason_to_live',
  sp_risk_level = 'sp_risk_level',
  sp_warning_signs = 'sp_warning_signs',
  sp_resources = 'sp_resources',
}
export const StaticImportantPhoneNumbers: StaticImportantPhoneNumber[] = [
  {
    phone: '988',
    title: 'National Suicide & Crisis Lifeline: Dial',
  },
  { phone: '1-800-799-7233', title: 'National Domestic Violence Hotline:' },
  { phone: '1-866-488-7386', title: 'The Trevor Project Hotline:' },
];

export const StaticLinkDetails: StaticLink[] = [
  {
    label: 'Chat suicide line',
    link: 'https://suicidepreventionlifeline.org/chat',
  },
  {
    label: 'Text suicide line',
    link: 'https://www.crisistextline.org',
    subLabel: 'Text “HOME” to 741741 to connect with a Crisis Counselor',
  },
  {
    label: 'Website with skills to use in a crisis',
    link: 'https://nowmattersnow.org',
  },
];
