import { FC, ReactNode } from 'react';

import { ChakraFlex, FireflyButton, Modal, Text } from '@equip.health/ui';

export interface WarnChangesModalViewDataProps {
  description: string | ReactNode;
  cancelButtonText?: string;
  confirmButtonText?: string;
  title: string;
}

export interface WarnChangesModalProps {
  handleClose: () => void;
  handleConfirmDiscard: () => void;
  isOpen: boolean;
  showCancelButton?: boolean;
}

const TOP_LEVEL_ID = 'discard-changes-modal';

const WarnChangesModal: FC<
  WarnChangesModalProps & WarnChangesModalViewDataProps
> = ({
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  description,
  handleClose,
  handleConfirmDiscard,
  isOpen,
  showCancelButton,
  title,
}) => (
  <Modal
    footerContent={
      <ChakraFlex justifyContent="flex-end" width="100%">
        {showCancelButton && (
          <ChakraFlex alignItems="center" marginRight="32px">
            <FireflyButton
              id={`${TOP_LEVEL_ID}__confirm`}
              onClick={handleConfirmDiscard}
              variant="tertiary"
            >
              {confirmButtonText}
            </FireflyButton>
          </ChakraFlex>
        )}
        {showCancelButton && (
          <FireflyButton id={`${TOP_LEVEL_ID}__cancel`} onClick={handleClose}>
            {cancelButtonText}
          </FireflyButton>
        )}
      </ChakraFlex>
    }
    id="discard-changes"
    isOpen={isOpen}
    onClose={handleClose}
    shouldCloseOnOverlayClick={false}
    size="lg"
    title={
      <Text color="neutral.primary" id={`${TOP_LEVEL_ID}__title`} variant="h5">
        {title}
      </Text>
    }
  >
    <Text
      color="neutral.primary"
      id={`${TOP_LEVEL_ID}__description`}
      marginBottom="8px"
    >
      {description}
    </Text>
  </Modal>
);

export default WarnChangesModal;
