import { PropsWithChildren } from 'react';

import {
  ChakraSkeleton,
  FAIconName,
  Icon,
  TableColumn,
  Text,
} from '@equip.health/ui';

interface VitalsTableColumn {
  header: string;
  key: string;
}

export const desktopColumns: TableColumn<VitalsTableColumn>[] = [
  { header: 'Date', key: 'date' },
  { header: 'Time', key: 'time' },
  { header: 'Logged by', key: 'loggedBy' },
  { header: 'Weight', key: 'weight' },
];

export const mobileColumns: TableColumn<VitalsTableColumn>[] = [
  { header: 'Date', key: 'date' },
  { header: 'Time', key: 'time' },
  { header: 'Weight', key: 'weight' },
];

export const loadingRows = [...Array(10)].map((_, i) => {
  return {
    key: `vitals-loader-${i}`,
    object: {
      date: <ChakraSkeleton height="20px" width="60px" />,
      loggedBy: <ChakraSkeleton height="20px" width="60px" />,
      time: <ChakraSkeleton height="20px" width="60px" />,
      weight: <ChakraSkeleton height="20px" width="60px" />,
    },
    value: `vitals-loader-${i}`,
  };
});

export const TableLabel = ({ children }: PropsWithChildren) => {
  return <Text color="neutral.primary">{children}</Text>;
};

export const EditIcon = <Icon name={FAIconName.ellipsisVerticalRegular} />;
