import { FC } from 'react';

import { ChakraFlex, Text } from '@equip.health/ui';

import EmptyScheduleIcon from '~/assets/svg/EmptySchedule';
import { EMPTY_SCHEDULE_TEXT } from '~/lib/constants/schedule.constants';

interface EmptyScheduleViewProps {
  id: string;
}

const TOP_LEVEL_ID = 'empty-schedule';
const TOP_LEVEL_ID_SUMMARY = 'empty-schedule-summary';

export const EmptyScheduleView: FC<EmptyScheduleViewProps> = ({ id }) => {
  return (
    <ChakraFlex
      align="center"
      direction="column"
      gridGap="16px"
      id={`${TOP_LEVEL_ID}-${id}`}
    >
      <EmptyScheduleIcon />
      <Text
        color="neutral.primary"
        id={`${TOP_LEVEL_ID}-${id}__title`}
        variant="h4"
      >
        {EMPTY_SCHEDULE_TEXT.noUpcomingEventsTitle}
      </Text>
      <Text
        color="neutral.secondary"
        id={`${TOP_LEVEL_ID}-${id}__message`}
        variant="body"
      >
        {EMPTY_SCHEDULE_TEXT.noUpcomingEventsMessage}
      </Text>
    </ChakraFlex>
  );
};

export const EmptyScheduleViewSummary: FC<EmptyScheduleViewProps> = ({
  id,
}) => {
  return (
    <ChakraFlex
      align="flex-start"
      direction="column"
      gridGap="8px"
      id={`${TOP_LEVEL_ID_SUMMARY}-${id}`}
    >
      <Text
        color="neutral.secondary"
        id={`${TOP_LEVEL_ID_SUMMARY}-${id}__title`}
        variant="bodyMedium"
      >
        {EMPTY_SCHEDULE_TEXT.noUpcomingEventsTitle}
      </Text>
      <Text
        color="neutral.secondary"
        id={`${TOP_LEVEL_ID_SUMMARY}-${id}__message`}
        variant="caption"
      >
        {EMPTY_SCHEDULE_TEXT.noUpcomingEventsMessageSummary}
      </Text>
    </ChakraFlex>
  );
};
