import { FC, useEffect } from 'react';

import { initialize, openSession } from '@braze/web-sdk';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import '@sendbird/uikit-react/dist/index.css';

import ScrollToTop from '~/components/common/ScrollToTop';
import Routes from '~/routes';

const {
  VITE_BRAZE_API_KEY,
  VITE_BRAZE_SDK_URL,
  VITE_FACEBOOK_PIXEL_ID,
  VITE_GOOGLE_ANALYTICS_KEY,
  VITE_HELPSCOUT_BEACON_ID,
} = import.meta.env;

const App: FC = () => {
  useEffect(() => {
    if (VITE_BRAZE_API_KEY) {
      try {
        initialize(VITE_BRAZE_API_KEY, {
          baseUrl: VITE_BRAZE_SDK_URL,
        });
        openSession();
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Equip</title>
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${VITE_GOOGLE_ANALYTICS_KEY}');`}
        </script>
        <script>
          {`
          !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
          n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
          document,'script','https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${VITE_FACEBOOK_PIXEL_ID}');
          fbq('track', 'PageView');`}
        </script>
        <script type="text/javascript">{`!function(e,t,n){function a() { var e = t.getElementsByTagName("script")[0], n = t.createElement("script"); n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e) }if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({ method: t, options: n, data: a })},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){ });`}</script>
        <script type="text/javascript">{`window.Beacon('init', '${VITE_HELPSCOUT_BEACON_ID}'); window.Beacon('on', 'ready', () => {
          const beaconContainer = document.getElementById('beacon-container');beaconContainer.style.display = 'none';});`}</script>
      </Helmet>
      <Router>
        <ScrollToTop />
        <Routes />
      </Router>
    </HelmetProvider>
  );
};

export default App;
