import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  ChakraBox,
  ChakraImage,
  ChakraSimpleGrid,
  ChakraText,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';
import { Prompt, useHistory, useLocation } from 'react-router-dom';

import GetInTouchImg from '~/assets/img/get_in_touch.png';
import ExitConfirm from '~/components/common/ExitConfirm';
import Footer from '~/components/common/Footer';
import InquiryReferralConfirmation from '~/components/common/InquiryReferralConfirmation';
import Navbar from '~/components/common/Navbar';
import StepsComponent from '~/components/common/StepsComponent';
import Scheduler from '~/components/inquiry/InquiryFormStepFour';
import InquiryFormStepOne from '~/components/inquiry/InquiryFormStepOne';
import InquiryFormStepThree from '~/components/inquiry/InquiryFormStepThree';
import InquiryFormStepTwo from '~/components/inquiry/InquiryFormStepTwo';
import { INQUIRY_REFERRAL_HORIZONTAL_MARGINS } from '~/lib/constants';
import { InquiryStep, stepsData } from '~/lib/constants/inquiry.constants';
import { FormType } from '~/lib/constants/referral.constants';
import { InquiryReferralContext } from '~/lib/context/InquiryReferralContext';
import { getInitialInquiryFormValues } from '~/lib/util/inquiry.util';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { PAGE_NAME } from '~/lib/constants/analytics';

const { h1, caption } = EquipUIFireflyV1Theme.typography;
const { mono, blue } = EquipUIFireflyV1Theme.colors;
const NAVBAR_ID = 'navigation-bar';
const TOP_LEVEL_ID = 'inquiry-page';

const InquiryPage: FC = () => {
  const [steps, setSteps] = useState<Step[]>(stepsData);
  const [selectedStep, setSelectedStep] = useState<InquiryStep>(
    InquiryStep.PATIENT_INFORMATION,
  );
  const [inquiryFormData, setInquiryFormData] = useState<InquiryForm>(
    getInitialInquiryFormValues(),
  );
  const [inquiryDesiredPath, setInquiryDesiredPath] = useState<any>(null);
  const [inquiryConfirmNavigation, setInquiryConfirmNavigation] =
    useState<boolean>(false);
  const [inquiryAppointmentDetails, setInquiryAppointmentDetails] =
    useState<InquiryAppointmentDetails>(null);

  const inquiryHistory = useHistory();
  const inquiryLocation = useLocation();
  const { trackPageView } = useAnalytics();

  const [
    isInquiryUnsavedChangesModalOpen,
    setIsInquiryUnsavedChangesModalOpen,
  ] = useState<boolean>(false);

  const { isInquiryReferralEdited, setIsInquiryReferralEdited } = useContext(
    InquiryReferralContext,
  );

  const inquiryData = {
    setter: setInquiryFormData,
    value: inquiryFormData,
  };

  const handleCancel = (): void => {
    setInquiryFormData(getInitialInquiryFormValues());
  };

  useEffect(() => {
    trackPageView(PAGE_NAME.inquiry);
  }, []);

  useEffect(() => {
    if (
      JSON.stringify(inquiryFormData) !==
      JSON.stringify(getInitialInquiryFormValues())
    ) {
      setIsInquiryReferralEdited(true);
    } else {
      setIsInquiryReferralEdited(false);
    }
  }, [inquiryFormData]);

  /** Blocks navigation if the user is attempting to navigate away from this page with unsaved changes */
  const handleNavigateWithInquiryUnsavedChanges = ({
    pathname,
  }: any): boolean => {
    if (
      !inquiryConfirmNavigation &&
      isInquiryReferralEdited &&
      selectedStep < InquiryStep.SCHEDULE_APPOINTMENT
    ) {
      setIsInquiryUnsavedChangesModalOpen(true);
      setInquiryDesiredPath(
        pathname === inquiryLocation.pathname
          ? inquiryLocation.pathname
          : pathname,
      );
      return false;
    }
    return true;
  };

  const handleInquiryExitCancel = (): void => {
    setInquiryDesiredPath(null);
    setIsInquiryUnsavedChangesModalOpen(false);
  };

  /** If user is trying to navigate away with unsaved changes, store confirmation in state, else close/clean up */
  const handleInquiryExitConfirm = (): void => {
    if (inquiryDesiredPath) {
      setInquiryConfirmNavigation(true);
    } else {
      handleCancel();
      inquiryHistory.push(inquiryLocation.pathname);
    }
  };

  // Navigate to the previous blocked location and close/clean up
  useEffect(() => {
    if (inquiryConfirmNavigation && inquiryDesiredPath) {
      inquiryHistory.push(inquiryDesiredPath);
      handleCancel();
    }
  }, [inquiryConfirmNavigation, inquiryDesiredPath]);

  const onStepChangeMount = useCallback(
    (
      currentStep: InquiryStep,
      inquiryAppointmentDetails?: InquiryAppointmentDetails,
    ) => {
      setSteps(
        steps.map((step: Step) => ({
          ...step,
          canEdit: step.id < currentStep,
          isStepActiveStatus: step.id === currentStep,
        })),
      );

      setSelectedStep(currentStep);
      setInquiryAppointmentDetails(inquiryAppointmentDetails ?? null);
      document.getElementById(`${NAVBAR_ID}__logo-section`).scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
    },
    [steps],
  );

  const stepMapper: Record<InquiryStep, ReactNode> = {
    [InquiryStep.PATIENT_INFORMATION]: (
      <InquiryFormStepOne
        inquiryData={inquiryData}
        onStepChangeMount={onStepChangeMount}
      />
    ),
    [InquiryStep.CONTACT_INFORMATION]: (
      <InquiryFormStepTwo
        inquiryData={inquiryData}
        onStepChangeMount={onStepChangeMount}
      />
    ),
    [InquiryStep.REVIEW]: (
      <InquiryFormStepThree
        inquiryData={inquiryData}
        onStepChangeMount={onStepChangeMount}
      />
    ),
    [InquiryStep.SCHEDULE_APPOINTMENT]: (
      <Scheduler inquiryAppointmentDetails={inquiryAppointmentDetails} />
    ),
    [InquiryStep.THANK_YOU]: (
      <InquiryReferralConfirmation
        formType={FormType.INQUIRY}
        patientAge={inquiryData?.value.patientAge}
      />
    ),
    [InquiryStep.OUT_OF_NETWORK]: (
      <InquiryReferralConfirmation
        formType={FormType.INQUIRY}
        isPayorOutOfNetwork
      />
    ),
  };
  return (
    <ChakraBox>
      <Prompt
        message={handleNavigateWithInquiryUnsavedChanges}
        when={isInquiryReferralEdited}
      />
      <ExitConfirm
        handleClose={handleInquiryExitCancel}
        handleConfirm={handleInquiryExitConfirm}
        isOpen={isInquiryUnsavedChangesModalOpen}
      />
      <Navbar id={NAVBAR_ID} />
      <ChakraSimpleGrid
        columns={2}
        display={{ md: 'flex' }}
        id={`${TOP_LEVEL_ID}__body-container`}
        marginTop="74px"
        minHeight="600px"
        paddingLeft={INQUIRY_REFERRAL_HORIZONTAL_MARGINS}
        paddingRight={INQUIRY_REFERRAL_HORIZONTAL_MARGINS}
        spacing="40px"
      >
        {selectedStep < InquiryStep.SCHEDULE_APPOINTMENT && (
          <ChakraBox
            id={`${TOP_LEVEL_ID}__left-section`}
            width={['100%', '100%', '100%', '40%', '50%']}
          >
            <ChakraImage
              alt="Inquiry"
              height="100px"
              id={`${TOP_LEVEL_ID}__image`}
              marginBottom="40px"
              src={GetInTouchImg}
              width="100px"
            />
            <ChakraText
              {...h1}
              color={blue.shade}
              id={`${TOP_LEVEL_ID}__header`}
              marginBottom="16px"
            >
              Get in touch
            </ChakraText>

            <ChakraBox
              {...caption}
              color={mono[70]}
              id={`${TOP_LEVEL_ID}__description`}
              marginBottom="40px"
            >
              <ChakraText as="span" fontWeight="bold" padding="0 8px 0 0">
                Anything you provide is confidential.
              </ChakraText>
              We won’t share it with anyone else.
              <br />
              <br />
              <ChakraText>This form is secure and HIPAA-compliant.</ChakraText>
            </ChakraBox>

            <StepsComponent
              onStepChangeMount={onStepChangeMount}
              selectedStep={selectedStep}
              steps={steps}
            />
          </ChakraBox>
        )}
        <ChakraBox
          id={`${TOP_LEVEL_ID}__form-section`}
          paddingTop={{ base: '50px', lg: '10px' }}
          width={
            selectedStep < InquiryStep.SCHEDULE_APPOINTMENT
              ? ['100%', '100%', '100%', '60%', '50%']
              : ['100%', '100%', '100%', '100%', '100%']
          }
        >
          {stepMapper[selectedStep]}
        </ChakraBox>
      </ChakraSimpleGrid>
      <Footer />
    </ChakraBox>
  );
};

export default InquiryPage;
