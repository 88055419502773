import { FC, useCallback, useMemo } from 'react';

import { TaskCard } from '@equip.health/ui';

import { FamilyTaskSubcategory, TaskStatusLabel } from '~/lib/constants/tasks.constants';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { getAssigneesName } from '~/lib/util/task.util';
import { getPreferredFirstName } from '~/lib/utils';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { getAnalyticsValueForTaskStatus } from '~/lib/util/analytics/tasks.utils';

const useLastEditUser = (familyAssigneeUsers, lastUpdated) => {
  return useMemo(() => {
    const lastUpdatedUser = familyAssigneeUsers?.find(
      (user) => user?.externalId === lastUpdated?.lastUpdatedByUserId,
    );
    return lastUpdatedUser?.chosenName || lastUpdatedUser?.firstName;
  }, [familyAssigneeUsers, lastUpdated]);
};

interface ToDoListTaskCardProps {
  duration?: string;
  familyAssigneeUsers?: FamilyAssignee[];
  isCompleted: boolean;
  onStart?: (taskId: string) => void;
  patientId?: string;
  taskId: string;
  taskStatus?: string;
  title: string;
  isCurrentUser?: boolean;
  lastUpdated?: LastUpdatedUserResponse;
  taskSubCategoryName?: string;
}

const ToDoListTaskCard: FC<ToDoListTaskCardProps> = ({
  isCompleted,
  duration,
  familyAssigneeUsers,
  onStart,
  patientId,
  taskId,
  taskStatus,
  title,
  isCurrentUser,
  lastUpdated,
  taskSubCategoryName,
}) => {
  const { track } = useAnalytics();
  const { userProfile } = useUserProfileContext();

  const handleClick = useCallback(() => {
    onStart?.(taskId);
  }, [onStart, taskId]);

  const currentUserExternalId = userProfile?.externalId;

  const assigneesName = getAssigneesName(
    familyAssigneeUsers,
    userProfile?.externalId,
  );

  const getLinkedPatient = userProfile?.linkedPatients?.find(
    (patient) => patient.externalId === patientId,
  );

  const patientName = getPreferredFirstName(
    getLinkedPatient?.chosenName,
    getLinkedPatient?.firstName,
  );

  const getStatusFromTaskStatus = (
    taskStatus: string,
    isCompleted: boolean,
  ) => {
    if (isCompleted) {
      return 'completed';
    }

    switch (taskStatus) {
      case TaskStatusLabel.TO_DO:
        return 'not-started';
      case TaskStatusLabel.IN_PROGRESS:
        return 'in-progress';
      case TaskStatusLabel.DONE:
        return 'completed';
      default:
        return undefined;
    }
  };

  const isAssessmentEditable = useMemo(() => {
    if (
      !lastUpdated ||
      taskSubCategoryName !== FamilyTaskSubcategory.SUB_CATEGORY_TYPE_ASSESSMENT
    )
      return true;
    if (taskStatus === TaskStatusLabel.TO_DO) return true;
    if (
      !familyAssigneeUsers.some(
        (user) => user.externalId === currentUserExternalId,
      ) &&
      lastUpdated.lastUpdatedByUserId !== currentUserExternalId
    )
      return false;
    return true;
  }, [lastUpdated, taskSubCategoryName, taskStatus, familyAssigneeUsers]);

  const editorName = useLastEditUser(familyAssigneeUsers, lastUpdated);

  const trackTaskClick = (source: string) => {
    track('Task Clicked', {
      Source: source,
      Status: getAnalyticsValueForTaskStatus(taskStatus, isCompleted),
      'Task ID': taskId,
      'Task Subcategory': taskSubCategoryName,
    });
  };

  const handleTaskActionClick = () => {
    trackTaskClick('Action Button');
    handleClick();
  };

  const handleTaskCardClick = () => {
    trackTaskClick('Card');
    handleClick();
  };

  return (
    <TaskCard
      assignees={assigneesName}
      duration={duration}
      isEditable={isAssessmentEditable}
      isSelfSupport={isCurrentUser}
      lastUpdatedBy={editorName}
      onActionClick={handleTaskActionClick}
      onCardClick={handleTaskCardClick}
      patientName={patientName}
      status={getStatusFromTaskStatus(taskStatus, isCompleted)}
      taskID={taskId}
      title={title}
    />
  );
};

export default ToDoListTaskCard;
