import { FC, useEffect, useState } from 'react';

import {
  ChakraFlex,
  FAIconName,
  FireflyButton,
  ScheduleOverviewEventCard,
  SimpleCardGrid,
} from '@equip.health/ui';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import { EmptyScheduleViewSummary } from './EmptyScheduleView';
import ZoomErrorModal from './ZoomErrorModal';
import SectionTitle from '~/components/common/SectionTitle';
import ScheduleLoader from '~/components/schedule/ScheduleLoader';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import useScheduledEvents from '~/lib/hooks/useScheduledEvents';
import useZoomMeeting from '~/lib/hooks/useZoomMeeting';
import {
  formatAppointmentStartDate,
  generateHostedByStringFromNames,
  generateParticipantNameString,
  getAppointmentStartingSoonText,
  transformDateFromISOtoLocal,
} from '~/lib/util/schedule.util';
import { getPreferredFullName } from '~/lib/utils';

const TOP_LEVEL_ID = 'schedule-overview';
const NUM_EVENTS_TO_SHOW = 3;

interface ScheduleListProps {
  items: ScheduledEventItem[];
}

type ScheduleOverviewItemProps = {
  item: ScheduledEventItem;
};

const ScheduleOverviewItem: FC<ScheduleOverviewItemProps> = ({ item }) => {
  const [appointmentStartingText, setAppointmentStartingText] =
    useState<string>('');

  const { track } = useAnalytics();

  const { userProfile } = useUserProfileContext();

  let eventExternalId: string;
  let startTime: string;
  let endTime: string;
  let eventTitle: string;
  let participants: string;
  if (item.isGroupClassEvent) {
    const eventInfo = item.eventInfo as GroupClassEventDetails;
    eventExternalId = eventInfo.groupClassEventExternalId;
    startTime = eventInfo.startTime;
    endTime = eventInfo.endTime;
    eventTitle = eventInfo.title;
    participants = generateHostedByStringFromNames(
      eventInfo.hosts.map((h) =>
        getPreferredFullName(null, h.firstName, h.lastName),
      ),
    );
  } else {
    const eventInfo = item.eventInfo as AppointmentDetailResponse;
    eventExternalId = eventInfo.appointmentExternalId;
    startTime = eventInfo.appointmentStartDateTime;
    endTime = eventInfo.appointmentEndDateTime;
    eventTitle = eventInfo.appointmentType;
    participants = generateParticipantNameString(eventInfo, userProfile);
  }

  const updateAppointmentStartingText = () => {
    setAppointmentStartingText(getAppointmentStartingSoonText(startTime));
  };

  useEffect(updateAppointmentStartingText, []);

  const trackEventJoin = (source: 'Card' | 'Button') => {
    track('Event Join Clicked', {
      'Event ID': eventExternalId,
      'Event Type': item.isGroupClassEvent ? 'Group Class' : 'Appointment',
      Source: source,
    });
  };

  const {
    closeZoomErrorModal,
    getZoomURLLoading,
    openZoomSession,
    shouldShowZoomButton,
    showZoomErrorModal,
  } = useZoomMeeting({
    eventExternalId,
    intervalCallback: updateAppointmentStartingText,
    isGroupClass: item.isGroupClassEvent,
    startTime,
  });

  const handleCardClick = () => {
    trackEventJoin('Card');
    openZoomSession();
  };

  const handleEventJoin = () => {
    trackEventJoin('Button');
    openZoomSession();
  };

  return (
    <>
      <ZoomErrorModal
        isOpen={showZoomErrorModal}
        onClose={closeZoomErrorModal}
        onRetry={openZoomSession}
      />
      <ScheduleOverviewEventCard
        date={formatAppointmentStartDate(startTime)}
        eventInfo={participants}
        id={eventExternalId}
        isLoading={getZoomURLLoading}
        joinTitle={shouldShowZoomButton && appointmentStartingText}
        onCardClick={handleCardClick}
        onEventJoin={handleEventJoin}
        startTime={`${transformDateFromISOtoLocal(
          startTime,
        )} - ${transformDateFromISOtoLocal(endTime)}`}
        title={eventTitle}
      />
    </>
  );
};

const ScheduleListItems: FC<ScheduleListProps> = ({ items }) => {
  if (isEmpty(items)) {
    return <EmptyScheduleViewSummary id={TOP_LEVEL_ID} />;
  }

  return (
    <SimpleCardGrid id={`${TOP_LEVEL_ID}-cards-container`}>
      {items.map((item) => (
        <ScheduleOverviewItem
          item={item}
          key={
            item.isGroupClassEvent
              ? (item.eventInfo as GroupClassEventDetails)
                .groupClassEventExternalId
              : (item.eventInfo as AppointmentDetailResponse)
                .appointmentExternalId
          }
        />
      ))}
    </SimpleCardGrid>
  );
};

const ScheduleOverview: FC = () => {
  const history = useHistory();
  const { isFullProfileInfoFetched } = useUserProfileContext();

  const {
    fetchDataForPage,
    isScheduleLoading,
    ungroupedEvents: scheduleData,
  } = useScheduledEvents({
    pageSize: NUM_EVENTS_TO_SHOW,
  });

  useEffect(() => {
    if (isFullProfileInfoFetched) {
      fetchDataForPage(1);
    }
  }, [isFullProfileInfoFetched]);

  return (
    <ChakraFlex
      as="section"
      flexDirection="column"
      gridGap="24px"
      id={`${TOP_LEVEL_ID}__section`}
      marginTop="40px"
    >
      <ChakraFlex
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <SectionTitle id={TOP_LEVEL_ID} title="Upcoming events" />
        <FireflyButton
          onClick={() => history.push('/schedule')}
          rightIcon={FAIconName.arrowRightRegular}
          variant="tertiary"
        >
          View schedule
        </FireflyButton>
      </ChakraFlex>
      {isScheduleLoading ? (
        <ScheduleLoader isScheduleOverview />
      ) : (
        <ScheduleListItems items={scheduleData.slice(0, NUM_EVENTS_TO_SHOW)} />
      )}
    </ChakraFlex>
  );
};

export default ScheduleOverview;
