import { isNil } from 'lodash';
import { DateTime } from 'luxon';

import { Recurrence } from '../schedule.util';

export const getGroupClassStartTime = (
  start: string,
  recurrence: Recurrence,
): string => {
  const recurrenceOption = recurrence?.toLowerCase();
  const dateTime = DateTime.fromISO(start);

  const isRecurring =
    !isNil(recurrenceOption) &&
    recurrenceOption !== Recurrence.DOES_NOT_REPEAT.toLowerCase();

  const dayComponent = `${dateTime.toFormat('cccc')}${isRecurring ? 's' : ''}`;

  const timeComponnet = dateTime
    .toFormat('h:mma')
    .replace(':00', '')
    .toLowerCase();

  if (isRecurring) {
    return `${dayComponent} at ${timeComponnet}`;
  }

  const dateComponent = dateTime.toFormat('LLL d');

  return `${dayComponent}, ${dateComponent} at ${timeComponnet}`;
};

const getGroupClassRecurrenceValue = (recurrence: string): string => {
  if (!recurrence) {
    return null;
  }

  const recurrenceOption = (recurrence as Recurrence).toLowerCase();

  if (recurrenceOption === Recurrence.WEEKLY.toLowerCase()) {
    return 'Weekly';
  }
  if (recurrenceOption === Recurrence.BIWEEKLY.toLowerCase()) {
    return 'Biweekly';
  }
  if (recurrenceOption === Recurrence.MONTHLY.toLowerCase()) {
    return 'Every 4 weeks';
  }

  return null;
};

export const getGroupClassRecurrenceDisplayString = (
  start: string,
  end: string,
  recurrence: string,
): string => {
  const recurrenceValue = getGroupClassRecurrenceValue(recurrence);

  if (!recurrenceValue) {
    return null;
  }

  const startDate = DateTime.fromISO(start).toFormat('LLL d');
  const endDate = end && DateTime.fromISO(end).toFormat('LLL d');

  if (endDate && startDate) {
    return `${recurrence}, ${startDate} - ${endDate}`;
  }

  if (
    startDate &&
    recurrence.toLowerCase() !== Recurrence.DOES_NOT_REPEAT.toLowerCase()
  ) {
    return recurrenceValue;
  }

  return null;
};

export const sortGroupClassesByRegistrationStatusAndStartDate = (
  groupClasses: GroupClass[],
  shouldSortSensitiveGroupClasses: boolean = true,
): GroupClass[] => {
  return groupClasses?.slice().sort((a, b) => {
    const event1StartDate = DateTime.fromISO(a?.nextStartDate);
    const event2StartDate = DateTime.fromISO(b?.nextStartDate);

    if (!shouldSortSensitiveGroupClasses) {
      if (
        a.isUserRegistered &&
        !a.isSensitive &&
        !(b.isUserRegistered && !b.isSensitive)
      )
        return -1;
      if (
        !(a.isUserRegistered && !a.isSensitive) &&
        b.isUserRegistered &&
        !b.isSensitive
      )
        return 1;
    } else {
      if (a.isUserRegistered && !b.isUserRegistered) return -1;
      if (!a.isUserRegistered && b.isUserRegistered) return 1;
    }

    return event1StartDate < event2StartDate ? -1 : 1;
  });
};
