import { FC, useMemo } from 'react';

import {
  AngleDown,
  AutoComplete,
  AutoCompleteOption,
  ChakraBox,
  ChakraFlex,
  ChakraHeading,
  ChakraRadioGroup,
  ChakraSimpleGrid,
  ChakraText,
  ChakraVStack,
  EquipUIFireflyV1Theme,
  PhoneNumber,
  Radio,
  TextInput,
} from '@equip.health/ui';

import FormBackButton from '~/components/common/FormBackButton';
import FormButtonBar from '~/components/common/FormButtonBar';
import { PHONE_NUMBER_INPUT_PLACEHOLDER, ReferralMode } from '~/lib/constants';
import {
  autoCompleteProps,
  textInputLabelProps,
} from '~/lib/constants/inquiry.constants';
import { useEnumerationContext } from '~/lib/context/EnumerationContext';
import useFeatureFlags from '~/lib/hooks/useFeatureFlags';
import {
  parsePhoneNumberWithDefaultCountry,
  validatePhoneNumber,
} from '~/lib/utils';

const { teal, black } = EquipUIFireflyV1Theme.colors;
const { bodySemibold, captionSemibold, overline } =
  EquipUIFireflyV1Theme.typography;

const TOP_LEVEL_ID = 'referral-step-three';

export type ContactInfo = {
  contact: 'patient' | 'primarycarer';
  patientPhoneNumber: string;
  primaryCarerFirstName: string;
  primaryCarerLastName: string;
  primaryCarerRelationWithPatient: string;
  primaryCarerPhoneNumber: string;
};

type ContactInfoFormProps = {
  advanceStep: () => void;
  formData: ContactInfo;
  patientIsMinor: boolean;
  previousStep: () => void;
  updateTextField: (key: string, value: string) => void;
};

const ContactInfoForm: FC<ContactInfoFormProps> = ({
  advanceStep,
  formData,
  patientIsMinor,
  previousStep,
  updateTextField,
}) => {
  const {
    contact,
    patientPhoneNumber: patientPhoneNumberValue,
    primaryCarerFirstName,
    primaryCarerLastName,
    primaryCarerRelationWithPatient,
    primaryCarerPhoneNumber: primaryCarerPhoneNumberValue,
  } = formData;

  const isContactPatientDirectly = contact === ReferralMode.PATIENT;
  const isContactPrimaryCarer = contact === ReferralMode.PRIMARY_CARER;

  const patientPhoneNumber =
    parsePhoneNumberWithDefaultCountry(patientPhoneNumberValue) || '';
  const primaryCarerPhoneNumber =
    parsePhoneNumberWithDefaultCountry(primaryCarerPhoneNumberValue) || '';

  const { isInquiryV2Enabled } = useFeatureFlags();

  const formIsInvalid = useMemo<boolean>(() => {
    switch (contact) {
      case ReferralMode.PATIENT: {
        return !validatePhoneNumber(patientPhoneNumber);
      }
      case ReferralMode.PRIMARY_CARER: {
        return (
          !(primaryCarerFirstName?.trim() && primaryCarerLastName?.trim()) ||
          !validatePhoneNumber(primaryCarerPhoneNumber) ||
          !primaryCarerRelationWithPatient
        );
      }
      default:
        return true;
    }
  }, [
    contact,
    patientPhoneNumber,
    primaryCarerFirstName,
    primaryCarerLastName,
    primaryCarerPhoneNumber,
    primaryCarerRelationWithPatient,
  ]);

  const { isRelationsToPatientLoading, relationsToPatientInquiry } =
    useEnumerationContext();

  const relations = useMemo<AutoCompleteOption[]>(() => {
    if (!isInquiryV2Enabled) {
      return (
        ((relationsToPatientInquiry as InquiryRelationToPatient[]) ?? []).map(
          ({ relationToPatientExternalId, relationToPatientName }) => ({
            primaryText: relationToPatientName,
            value: relationToPatientExternalId,
          }),
        ) ?? []
      );
    }
    return (
      ((relationsToPatientInquiry as InquiryRelationToPatientV2[]) ?? []).map(
        ({ id, label }) => ({
          primaryText: label,
          value: id,
        }),
      ) ?? []
    );
  }, [relationsToPatientInquiry?.length]);

  const selectedRelationOption: AutoCompleteOption = relations?.find(
    ({ primaryText, value }) =>
      isInquiryV2Enabled
        ? primaryText === primaryCarerRelationWithPatient
        : value === primaryCarerRelationWithPatient,
  );

  return (
    <ChakraBox>
      <FormBackButton
        {...captionSemibold}
        id={TOP_LEVEL_ID}
        onClickBack={previousStep}
      />
      <ChakraBox marginBottom="16px" marginTop="24px">
        <ChakraHeading
          {...bodySemibold}
          color="mono.70"
          id={`${TOP_LEVEL_ID}__contact-preferences`}
        >
          How should we reach out to the patient?
        </ChakraHeading>
        <ChakraText
          {...overline}
          color={black[70]}
          id={`${TOP_LEVEL_ID}__contact-description`}
          marginTop="4px"
        >
          Our admissions team will call this person.
        </ChakraText>
      </ChakraBox>

      <ChakraRadioGroup
        marginTop="16px"
        onChange={(value) => {
          updateTextField('contact', value);
        }}
        value={contact}
      >
        <ChakraVStack spacing="8px">
          {!patientIsMinor && (
            <ChakraBox
              background="mono.5"
              borderRadius="20px"
              id={`${TOP_LEVEL_ID}__contact-patient-section`}
              padding="20px"
              width="100%"
            >
              <ChakraBox paddingBottom="32px">
                <ChakraFlex>
                  <Radio
                    id={`${TOP_LEVEL_ID}__patient`}
                    paddingRight="12px"
                    value={ReferralMode.PATIENT}
                  />
                  <ChakraBox>
                    <ChakraText
                      {...bodySemibold}
                      color="mono.100"
                      id={`${TOP_LEVEL_ID}__contact-patient-header`}
                    >
                      Contact the patient directly
                    </ChakraText>
                  </ChakraBox>
                </ChakraFlex>
              </ChakraBox>
              <PhoneNumber
                {...captionSemibold}
                id={`${TOP_LEVEL_ID}__patient__phone-number`}
                isDisabled={isContactPrimaryCarer}
                isInvalid={!validatePhoneNumber(patientPhoneNumber, true)}
                isRequired
                label="Patient's mobile phone"
                onChange={(value) => {
                  updateTextField('patientPhoneNumber', value);
                }}
                placeholder={PHONE_NUMBER_INPUT_PLACEHOLDER}
                value={patientPhoneNumber}
              />
            </ChakraBox>
          )}
          <ChakraBox
            background="mono.5"
            borderRadius="20px"
            id={`${TOP_LEVEL_ID}__contact-carer-section`}
            padding="20px"
            width="100%"
          >
            <ChakraBox paddingBottom="32px">
              <ChakraFlex>
                {!patientIsMinor && (
                  <Radio
                    id={`${TOP_LEVEL_ID}__carer`}
                    paddingRight="12px"
                    value={ReferralMode.PRIMARY_CARER}
                  />
                )}
                <ChakraBox>
                  <ChakraText
                    {...bodySemibold}
                    color="mono.100"
                    id={`${TOP_LEVEL_ID}__contact-carer-header`}
                  >
                    Contact the parent or guardian
                  </ChakraText>
                </ChakraBox>
              </ChakraFlex>
            </ChakraBox>
            <ChakraSimpleGrid
              columns={2}
              display={{ sm: 'grid' }}
              mt="4"
              spacing="16px"
            >
              <TextInput
                {...textInputLabelProps}
                formLabelMarginLeft="0px"
                id={`${TOP_LEVEL_ID}__primary-carer__first-name`}
                isDisabled={isContactPatientDirectly}
                isRequired
                label="Parent/guardian first name"
                onChange={(e) => {
                  updateTextField('primaryCarerFirstName', e.target.value);
                }}
                value={primaryCarerFirstName}
              />
              <TextInput
                {...textInputLabelProps}
                formLabelMarginLeft="0px"
                id={`${TOP_LEVEL_ID}__primary-carer__last-name`}
                isDisabled={isContactPatientDirectly}
                isRequired
                label="Parent/guardian last name"
                onChange={(e) => {
                  updateTextField('primaryCarerLastName', e.target.value);
                }}
                value={primaryCarerLastName}
              />
            </ChakraSimpleGrid>
            <ChakraSimpleGrid
              columns={1}
              display={{ sm: 'grid' }}
              mt="4"
              spacing="16px"
            >
              <PhoneNumber
                {...captionSemibold}
                id={`${TOP_LEVEL_ID}__primary-carer__phone-number`}
                isDisabled={isContactPatientDirectly}
                isInvalid={!validatePhoneNumber(primaryCarerPhoneNumber, true)}
                isRequired
                label="Parent/guardian mobile phone"
                onChange={(value) => {
                  updateTextField('primaryCarerPhoneNumber', value || '');
                }}
                placeholder={PHONE_NUMBER_INPUT_PLACEHOLDER}
                value={primaryCarerPhoneNumber}
              />
            </ChakraSimpleGrid>
            <ChakraSimpleGrid
              columns={1}
              display={{ sm: 'grid' }}
              mt="4"
              spacing="16px"
            >
              <AutoComplete
                {...autoCompleteProps}
                endIcon={
                  <AngleDown fill={teal[100]} height="15px" width="15px" />
                }
                hideAvatar
                id={`${TOP_LEVEL_ID}__primary-carer__relation-with-patient`}
                isDisabled={isContactPatientDirectly}
                isLoading={isRelationsToPatientLoading}
                isRequired
                isTextInput
                marginBottom="16px"
                onSelect={(selected) => {
                  const valueToUpdate = isInquiryV2Enabled
                    ? selected.primaryText
                    : selected.value;
                  updateTextField(
                    'primaryCarerRelationWithPatient',
                    valueToUpdate,
                  );
                }}
                options={relations ?? []}
                placeholder="I am a..."
                selected={[
                  ...(selectedRelationOption ? [selectedRelationOption] : []),
                ]}
                title="Relation to patient"
                toggleListOnFocus
              />
            </ChakraSimpleGrid>
          </ChakraBox>
        </ChakraVStack>
      </ChakraRadioGroup>

      <FormButtonBar
        {...captionSemibold}
        containerMarginTop="40px"
        id={TOP_LEVEL_ID}
        isProceedButtonDisabled={formIsInvalid}
        isRightIcon={false}
        onClickProceed={advanceStep}
      />
    </ChakraBox>
  );
};

export default ContactInfoForm;
