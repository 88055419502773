enum ProfileField {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  CHOSEN_NAME = 'CHOSEN_NAME',
  GENDER = 'GENDER',
  PRONOUNS = 'PRONOUNS',
  LANGUAGES = 'LANGUAGES',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  RELATIONSHIP_TO_PATIENT = 'RELATIONSHIP_TO_PATIENT',
  SUPPORTED_BY = 'SUPPORTED_BY',
  ADDRESS = 'ADDRESS',
  EMAIL = 'EMAIL',
  CELLPHONE = 'CELLPHONE',
  TIMEZONE = 'TIMEZONE',
  CONTACT_PREFERENCES = 'CONTACT_PREFERENCES',
  NOTIFICATION = 'NOTIFICATION',
}

export default ProfileField;
