import { ChakraBox } from '@equip.health/ui';

interface UnreadMessageIndicatorProps {
  id: string;
}

const UnreadMessageIndicator = ({ id }: UnreadMessageIndicatorProps) => {
  return (
    <ChakraBox
      backgroundColor="orange"
      borderRadius="50%"
      flexShrink={0}
      height="6px"
      id={`unread-message__indicator__${id}`}
      width="6px"
    />
  );
};

UnreadMessageIndicator.displayName = 'UnreadMessageIndicator';
export default UnreadMessageIndicator;
