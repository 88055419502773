import { useEffect, useState } from 'react';

import {
  ChakraFlex,
  ChakraSimpleGrid,
  EquipUIFireflyV2Theme,
} from '@equip.health/ui';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import CollapsibleContainer from '~/components/common/Collapsible/CollapsibleContainer';
import Loading from '~/components/common/Loading';
import LineChart from '~/components/vitals/LineChart';
import WeightPatientTabSelector from '~/components/vitals/WeightPatientTabSelector';
import { EquipAppPath } from '~/lib/constants';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import {
  PatientObservationData,
  PatientObservationsResponse,
} from '~/lib/types/vitals';
import {
  getLastObservationDescription,
  getLastWeekReferenceObservation,
} from '~/lib/util/vitals.util';
import { useInTreatmentProxyPatientsSelector } from '~/store/hooks/user';
import {
  useUserPreferencesDispatch,
  useUserPreferencesSelector,
} from '~/store/hooks/userPreferences';
import { useGetWeightObservationsQuery } from '~/store/services/vitals';

import EmptyWeightView from './EmptyWeightView';
import VitalsMiniCard from './VitalsMinicard';
import VitalsSummary from './VitalsSummaryCard';
import WeightTrendMiniCard from './WeightTrendMiniCard';

const { normal } = EquipUIFireflyV2Theme.cornerRadius;
const TOP_LEVEL_ID = 'vitals__weight__overview';

interface PatientVitalsContentProps {
  lastObservation: PatientObservationData;
  lastObservationInWeek: PatientObservationData;
  nextObservation: PatientObservationData;
  observations: PatientObservationData[];
  onHistoryClick: () => void;
  isLoading?: boolean;
}

const PatientVitalsContent = ({
  lastObservation,
  lastObservationInWeek,
  nextObservation,
  observations,
  onHistoryClick,
  isLoading,
}: PatientVitalsContentProps) => {
  if (isLoading) {
    return (
      <ChakraFlex
        height="100%"
        id={`${TOP_LEVEL_ID}_loader`}
        justifyContent="center"
        width="100%"
      >
        <Loading />
      </ChakraFlex>
    );
  }

  if (isEmpty(observations)) {
    return <EmptyWeightView />;
  }

  return (
    <ChakraSimpleGrid
      columns={{ base: 1, lg: 3 }}
      spacing={{ base: '16px', lg: '32px' }}
      width="100%"
    >
      <ChakraFlex gridGap="64px">
        <VitalsMiniCard
          description={getLastObservationDescription(lastObservation)}
          id="last-observation"
          unit={lastObservation?.observation.unit}
          value={lastObservation?.observation.value.toString()}
        />
        <WeightTrendMiniCard
          lastObservation={lastObservation}
          lastObservationInWeek={lastObservationInWeek}
          nextObservation={nextObservation}
        />
      </ChakraFlex>
      <LineChart observations={observations} />
      <VitalsSummary
        observations={observations}
        onHistoryClick={onHistoryClick}
      />
    </ChakraSimpleGrid>
  );
};
const PatientVitalsSection = () => {
  const history = useHistory();
  const { track } = useAnalytics();

  const { setIsWeightPrivacyModeEnabled } = useUserPreferencesDispatch();
  const { isFullProfileInfoFetched } = useUserProfileContext();
  const { isWeightPrivacyModeEnabled } = useUserPreferencesSelector();
  const activeProxyPatients = useInTreatmentProxyPatientsSelector();

  const [selectedPatientId, setSelectedPatientId] = useState<string>();

  const [observationsData, setObservationsData] =
    useState<PatientObservationsResponse>();

  const { data, isUninitialized, isFetching } = useGetWeightObservationsQuery(
    {
      pageNumber: 1,
      patientId: selectedPatientId,
    },
    { skip: !selectedPatientId },
  );

  const handlePatientChange = (patientId: string) => {
    setSelectedPatientId(patientId);
  };

  useEffect(() => {
    if (data) {
      setObservationsData(data);
    }
  }, [data]);

  useEffect(() => {
    if (isFullProfileInfoFetched && activeProxyPatients?.length > 0) {
      const patientId = activeProxyPatients[0].externalId;
      handlePatientChange(patientId);
    }
  }, [isFullProfileInfoFetched]);

  const shouldShowLoader = isFetching || isUninitialized;
  const lastObservation = observationsData?.data?.[0];

  const lastObservationInWeek = getLastWeekReferenceObservation(
    observationsData?.data,
  );

  const nextObservation = observationsData?.data?.[1] ?? null;

  const handleHistoryClick = () => {
    history.push(EquipAppPath.VITALS_WEIGHT, {
      patientId: selectedPatientId,
    });
    track('Weight Vitals History Clicked', {
      'Patient ID': selectedPatientId,
    });
  };

  if (activeProxyPatients?.length === 0 || isUninitialized) {
    return null;
  }

  const handleTogglePrivacyMode = (isCollapsed: boolean) => {
    setIsWeightPrivacyModeEnabled(!isWeightPrivacyModeEnabled);
    track('Weight Vitals Privacy Mode Changed', {
      'Is Visible': !isCollapsed,
      'Patient ID': selectedPatientId,
    });
  };

  return (
    <ChakraFlex
      flexDirection="column"
      gridGap="16px"
      id={`${TOP_LEVEL_ID}__section`}
    >
      <WeightPatientTabSelector onPatientSelected={handlePatientChange} />
      <ChakraFlex flexDirection="column" width="100%">
        <CollapsibleContainer
          collapsedTitle="Show weight"
          expandedTitle="Hide weight"
          isCollapsedInitially={isWeightPrivacyModeEnabled}
          onCollapseChange={handleTogglePrivacyMode}
        >
          <ChakraFlex
            alignItems="center"
            borderColor="neutral.line"
            borderRadius={normal}
            borderWidth="1px"
            boxSizing="content-box"
            flexDirection={{ base: 'column', md: 'row' }}
            id={`${TOP_LEVEL_ID}_container`}
            justifyContent={{ base: 'center', md: 'space-between' }}
            minHeight={{ base: '80px', md: '120px' }}
            padding="24px"
          >
            <PatientVitalsContent
              isLoading={shouldShowLoader}
              lastObservation={lastObservation}
              lastObservationInWeek={lastObservationInWeek}
              nextObservation={nextObservation}
              observations={observationsData?.data}
              onHistoryClick={handleHistoryClick}
            />
          </ChakraFlex>
        </CollapsibleContainer>
      </ChakraFlex>
    </ChakraFlex>
  );
};

export default PatientVitalsSection;
