import { FC, useEffect, useMemo, useState } from 'react';

import {
  ChakraBox,
  ChakraLink,
  ChakraSimpleGrid,
  ChakraText,
  DatePicker,
  Email,
  EquipUIFireflyV1Theme,
  NumberInput,
  PhoneNumber,
  TextInput,
  format,
  getAgeFromDob,
} from '@equip.health/ui';
import { validate as validateEmail } from 'email-validator';

import FormBackButton from '~/components/common/FormBackButton';
import FormButtonBar from '~/components/common/FormButtonBar';
import PayorSelect from '~/components/common/PayorSelect';
import StateSelect from '~/components/common/StateSelect';
import {
  ADMISSIONS_PHONE_NUMBER,
  InquiryMode,
  PHONE_NUMBER_INPUT_PLACEHOLDER,
} from '~/lib/constants';
import {
  InquiryStep,
  OTHER_PAYOR,
  textInputLabelProps,
} from '~/lib/constants/inquiry.constants';
import { validateAgeField } from '~/lib/util/inquiry.util';
import {
  parsePhoneNumberWithDefaultCountry,
  validatePhoneNumber,
} from '~/lib/utils';

const { captionSemibold, overline } = EquipUIFireflyV1Theme.typography;

const { mono, black } = EquipUIFireflyV1Theme.colors;

const TOP_LEVEL_ID = 'inquiry-step-two';

type PartialInquiryForm = Partial<InquiryForm>;

type InquiryFormStepTwoProps = {
  onStepChangeMount: (currentStep: InquiryStep) => void;
  inquiryData: InquiryFormType;
};

const InquiryFormStepTwo: FC<InquiryFormStepTwoProps> = ({
  onStepChangeMount,
  inquiryData,
}: InquiryFormStepTwoProps) => {
  const {
    customPayorName,
    dateOfBirth,
    email,
    inquiryAbout,
    patientAge,
    patientState,
    payor,
    phoneNumber: phoneNumberValue,
  } = inquiryData.value;

  const [isDateOfBirthValid, setIsDateOfBirthValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inquiryValueForMySelf = inquiryAbout === InquiryMode.MYSELF;

  const phoneNumber =
    parsePhoneNumberWithDefaultCountry(phoneNumberValue) || '';

  const handleConfirm = (): void => {
    setIsLoading(true);
    onStepChangeMount(InquiryStep.REVIEW);
  };

  /**
   * Accepts an array of inquiry form "keys" and a matching array of "values",
   * allows updating multiple fields at a time,
   *   i.e. selecting both the payor name and payor external ID
   */
  const handleChange = (
    keys: string[],
    values: (string | number | boolean)[],
  ) => {
    const newData: PartialInquiryForm = {};

    keys.forEach((key: string, i: number) => {
      newData[key] = values[i];
      if (key === 'patientAge' && dateOfBirth) {
        newData.dateOfBirth = null;
      }
    });
    inquiryData.setter({
      ...inquiryData.value,
      ...newData,
    });
  };

  const isValidPhoneNumber = useMemo(
    () => validatePhoneNumber(phoneNumber),
    [phoneNumber],
  );

  // if email/phone is checked it must exist; if email/phone exists it must be valid
  const isStepTwoFormInvalid = useMemo<boolean>(
    () =>
      !patientState ||
      !(inquiryValueForMySelf
        ? dateOfBirth && isDateOfBirthValid
        : (patientAge || patientAge === 0) && isDateOfBirthValid) ||
      !email ||
      !phoneNumber ||
      (email && !validateEmail(email)) ||
      (phoneNumber && !isValidPhoneNumber) ||
      (payor === OTHER_PAYOR && !customPayorName),

    [
      customPayorName,
      dateOfBirth,
      email,
      inquiryValueForMySelf,
      isDateOfBirthValid,
      isValidPhoneNumber,
      patientAge,
      patientState,
      phoneNumber,
      payor,
    ],
  );

  useEffect(() => {
    if (dateOfBirth && isDateOfBirthValid) {
      inquiryData.setter({
        ...inquiryData.value,
        patientAge: getAgeFromDob(dateOfBirth),
      });
    }
  }, [dateOfBirth, isDateOfBirthValid]);

  const datePickerComponent = (
    <DatePicker
      {...captionSemibold}
      color="mono.70"
      id={`${TOP_LEVEL_ID}__date-of-birth`}
      isRequired
      label="What's your date of birth?"
      labelForErrorMessage="Date of birth"
      maxDate={new Date()}
      onSelect={(date) =>
        handleChange(['dateOfBirth'], [date ? format(date) : ''])
      }
      onValidateDate={(isValid: boolean): void =>
        setIsDateOfBirthValid(isValid)
      }
      placeHolderText="MM/DD/YYYY"
      value={dateOfBirth}
    />
  );

  return (
    <>
      <FormBackButton
        {...captionSemibold}
        id={TOP_LEVEL_ID}
        onClickBack={() => onStepChangeMount(InquiryStep.PATIENT_INFORMATION)}
      />
      <ChakraSimpleGrid columns={2} display={{ md: 'grid' }} spacing="16px">
        <StateSelect
          label={
            inquiryValueForMySelf
              ? 'Where do you currently live?'
              : 'Where does your loved one currently live?'
          }
          onSelect={(value) => {
            handleChange(['patientState'], [value]);
          }}
          state={patientState}
        />
        <ChakraBox>
          <PayorSelect
            label={
              inquiryValueForMySelf
                ? 'Your insurance provider?'
                : "Who is your loved one's insurance provider?"
            }
            onSelect={(
              payorName: string,
              payorExternalId: string,
              isInNetwork: boolean,
            ) =>
              handleChange(
                ['payor', 'payorExternalId', 'isPayorInNetwork'],
                [payorName, payorExternalId, isInNetwork],
              )
            }
            selectedPayor={payor}
          />
          <ChakraText
            {...overline}
            color={black[70]}
            id={`${TOP_LEVEL_ID}__insurance-helper__text`}
            marginLeft="8px"
            marginTop="-8px"
          >
            Our team will determine your insurance eligibility.
          </ChakraText>
        </ChakraBox>
      </ChakraSimpleGrid>
      {payor === OTHER_PAYOR && (
        <TextInput
          {...textInputLabelProps}
          formMarginBottom="16px"
          id={`${TOP_LEVEL_ID}__insurance_provider`}
          isRequired={payor === OTHER_PAYOR}
          label="Please enter your insurance provider"
          onChange={(e) => {
            handleChange(['customPayorName'], [e.target.value]);
          }}
          type="text"
          value={customPayorName}
        />
      )}

      <ChakraBox paddingTop="24px">
        {inquiryValueForMySelf ? (
          datePickerComponent
        ) : (
          <ChakraSimpleGrid columns={2} spacing="16px">
            <ChakraBox>
              <DatePicker
                {...captionSemibold}
                color="mono.70"
                id={`${TOP_LEVEL_ID}__loved-one's__date-of-birth`}
                label="What is your loved one's date of birth? (optional)"
                labelForErrorMessage="Date of birth"
                maxDate={new Date()}
                onSelect={(date) =>
                  handleChange(['dateOfBirth'], [date ? format(date) : ''])
                }
                onValidateDate={(isValid: boolean): void =>
                  setIsDateOfBirthValid(isValid)
                }
                placeHolderText="MM/DD/YYYY"
                value={dateOfBirth}
              />
            </ChakraBox>
            <NumberInput
              {...textInputLabelProps}
              id={`${TOP_LEVEL_ID}__age`}
              isRequired
              label="What's your loved one's age?"
              min={0}
              onChange={(val) => {
                handleChange(['patientAge'], [validateAgeField(val)]);
              }}
              value={patientAge}
            />
          </ChakraSimpleGrid>
        )}
      </ChakraBox>
      <ChakraBox marginTop="24px">
        <ChakraSimpleGrid
          columns={2}
          display={{ md: 'grid' }}
          id={`${TOP_LEVEL_ID}__contact-sections`}
          marginTop="8px"
          spacing="16px"
        >
          <ChakraBox>
            <PhoneNumber
              {...captionSemibold}
              formMarginBottom="16px"
              id={`${TOP_LEVEL_ID}__phone-number`}
              isInvalid={phoneNumber && !isValidPhoneNumber}
              isRequired
              label="Your phone number"
              onChange={(value) => {
                handleChange(['phoneNumber'], [value || '']);
              }}
              placeholder={PHONE_NUMBER_INPUT_PLACEHOLDER}
              value={phoneNumber}
            />
          </ChakraBox>
          <TextInput
            {...textInputLabelProps}
            errorMessage="Please enter a valid email"
            formMarginBottom="16px"
            id={`${TOP_LEVEL_ID}__email`}
            isInvalid={email && !validateEmail(email)}
            isRequired
            label="Your email"
            onChange={(e) => {
              handleChange(['email'], [e.target.value]);
            }}
            startIcon={<Email fill={mono[70]} width="20px" />}
            type="email"
            value={email}
          />
        </ChakraSimpleGrid>
        <ChakraText
          {...overline}
          color={black[70]}
          id={`${TOP_LEVEL_ID}__contact-information`}
          marginLeft="10px"
          marginTop="4px"
        >
          Don't want to provide this information? Call us at&nbsp;
          <ChakraLink
            color="teal.100"
            href={`tel:${ADMISSIONS_PHONE_NUMBER}`}
            sx={{ textDecoration: 'none !important' }}
          >
            {ADMISSIONS_PHONE_NUMBER}
          </ChakraLink>
        </ChakraText>

        <FormButtonBar
          {...captionSemibold}
          containerMarginTop="40px"
          id={TOP_LEVEL_ID}
          isLoading={isLoading}
          isProceedButtonDisabled={isStepTwoFormInvalid}
          isRightIcon={false}
          onClickProceed={handleConfirm}
        />
      </ChakraBox>
    </>
  );
};

export default InquiryFormStepTwo;
