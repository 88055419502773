import React, { useState } from 'react';

import {
  Avatar,
  ChakraBox,
  ChakraFlex,
  EquipUIFireflyV1Theme,
  FAIconName,
  Icon,
  Text,
  useMediaQuery,
} from '@equip.health/ui';
import { Member } from '@sendbird/chat/groupChannel';

import ChannelDetailsModal from './ChannelDetailsModal';
import {
  CHAT_LAYOUT,
  NAME_CONNECTOR,
  SPACE,
} from '~/lib/constants/chat/chat.constants';
import { useChatChannelContext } from '~/lib/context/ChatChannelContext';
import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';
import { getUserTypeFromChatUser } from '~/lib/util/chat/chat.utils';

interface ChatHeaderProps {
  id: string;
  members: Member[];
  name: string;
  subTitle?: string;
  onDismiss?: () => void;
  isGroupChannel?: boolean;
}

const { black } = EquipUIFireflyV1Theme.colors;

const idPrefix = 'Chat';
const idPostfix = 'Header';

const ChatHeader = React.forwardRef<HTMLDivElement, ChatHeaderProps>(
  (
    { name, id, members, subTitle, onDismiss, isGroupChannel }: ChatHeaderProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [isParticipantListOpen, setOpenParticipantListOpen] =
      useState<boolean>(false);
    const isSmallBreakpoint = useMobileBreakpoint();

    const onParticipantListModalClose = () => setOpenParticipantListOpen(false);

    const [isLargeScreen] = useMediaQuery('(min-width: 840px)');

    const { patientId } = useChatChannelContext();

    return (
      <ChakraFlex
        backgroundColor="white.100"
        direction="row"
        height={CHAT_LAYOUT.headerHeight}
        id={`${idPrefix}-container__${id}__${idPostfix}`}
        ref={ref}
        width="100%"
      >
        <ChannelDetailsModal
          channelId={id}
          isOpen={isParticipantListOpen}
          members={members}
          onClose={onParticipantListModalClose}
          patientId={patientId}
        />
        <ChakraFlex
          alignItems="center"
          direction="row"
          gridGap="10px"
          id={`${idPrefix}-message-area-header__${id}__${idPostfix}`}
          padding="12px"
          shadow="md"
          width="100%"
        >
          {isSmallBreakpoint && (
            <ChakraBox
              as="button"
              height="40px"
              onClick={onDismiss}
              width="30px"
            >
              <Icon name={FAIconName.chevronLeftRegular} size="lg" />
            </ChakraBox>
          )}

          <ChakraFlex
            alignItems="center"
            cursor="pointer"
            gridGap="10px"
            id={`${idPrefix}-message-area-header__${id}__${idPostfix}`}
            justifyContent="space-between"
            width="100%"
          >
            <ChakraFlex alignItems="center" gridGap="10px" overflow="none">
              {isGroupChannel === false && (
                <Avatar
                  id={`${idPrefix}-avatar__${id}__${idPostfix}`}
                  name={`${name?.replace(NAME_CONNECTOR, SPACE)} ${subTitle}`}
                  userType="provider"
                />
              )}
              {subTitle ? (
                <ChakraFlex
                  direction="column"
                  gridGap="4px"
                  justifyContent="start"
                >
                  <Text
                    color="neutral.primary"
                    id={`${idPrefix}- provider - name__${id}__${idPostfix}`}
                    noOfLines={1}
                    variant="h5"
                  >
                    {name}
                  </Text>
                  <Text
                    color="neutral.secondary"
                    id={`${idPrefix}-provider-name-subtitle__${id}__${idPostfix}`}
                    variant="caption"
                  >
                    {subTitle}
                  </Text>
                </ChakraFlex>
              ) : (
                <>
                  <Text
                    color="neutral.primary"
                    id={`${idPrefix}-provider-name__${id}__${idPostfix}`}
                    variant="h5"
                  >
                    {name}
                  </Text>
                  {isGroupChannel && (
                    <Icon name={FAIconName.userGroupSolid} size="sm" />
                  )}
                </>
              )}
            </ChakraFlex>
            {members?.length > 0 && (
              <ChakraFlex
                alignItems="center"
                border={`1px solid ${black[30]}`}
                borderRadius="8px"
                height="40px"
                marginRight="8px"
                onClick={(event) => {
                  setOpenParticipantListOpen(true);
                  event.stopPropagation();
                }}
                paddingLeft="8px"
                paddingRight="10px"
              >
                {members
                  .slice(0, isLargeScreen ? 3 : 1)
                  .map((member, index, array) => (
                    <ChakraBox
                      background="white"
                      borderRadius="50%"
                      key={member?.userId}
                      marginLeft={`${(index > 0 ? 1 : 0) * -6}px`}
                      padding="2px"
                      zIndex={array.length - index}
                    >
                      <Avatar
                        name={member?.nickname}
                        size="2xs"
                        userType={getUserTypeFromChatUser(member)}
                      />
                    </ChakraBox>
                  ))}
                <Text
                  color="neutral.secondary"
                  marginLeft="6px"
                  noOfLines={1}
                  variant="caption"
                >
                  {members.length + (isLargeScreen ? ' participants' : '')}
                </Text>
              </ChakraFlex>
            )}
          </ChakraFlex>
        </ChakraFlex>
      </ChakraFlex>
    );
  },
);

ChatHeader.displayName = 'ChatHeader';
export default ChatHeader;
