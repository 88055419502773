import { FC } from 'react';

const Icon: FC<BaseSVG> = ({ width, height }) => (
  <svg
    fill="none"
    height={height || '72'}
    viewBox="0 0 72 72"
    width={width || '72'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M70.0814 35.4104C70.0816 35.366 70.0817 35.3216 70.0817 35.2772C70.0817 16.4815 54.8448 1.24463 36.0491 1.24463C17.2535 1.24463 2.0166 16.4815 2.0166 35.2772C2.0166 35.3216 2.01669 35.366 2.01686 35.4104H70.0814Z"
      fill="#007F79"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M2.01722 36.7337C2.01705 36.7781 2.01697 36.8225 2.01697 36.8669C2.01697 55.6625 17.2539 70.8994 36.0495 70.8994C54.8451 70.8994 70.082 55.6625 70.082 36.8669C70.082 36.8225 70.0819 36.7781 70.0818 36.7337L2.01722 36.7337Z"
      fill="#007F79"
      fillOpacity="0.5"
      fillRule="evenodd"
    />
  </svg>
);

export default Icon;
