import {
  GroupChannel,
  GroupChannelListQuery,
  GroupChannelModule,
} from '@sendbird/chat/groupChannel';
import * as Sentry from '@sentry/react';

// eslint-disable-next-line import/prefer-default-export
export const fetchMySendbirdChannels = (
  groupChannelModule: GroupChannelModule,
  completion: (channels: GroupChannel[]) => void,
): void => {
  const publicGroupChannelListQuery =
    groupChannelModule.createMyGroupChannelListQuery({
      includeEmpty: true,
      includeMetaData: true,
      limit: 100,
    });

  const publicGroupChannels: GroupChannel[] = [];

  const fetchChannels = (
    query: GroupChannelListQuery,
    completion: (channels: GroupChannel[]) => void,
  ) => {
    query
      .next()
      .then((foundChannels: GroupChannel[]) => {
        publicGroupChannels.push(...foundChannels);
        if (query.hasNext) {
          fetchChannels(query, completion);
        } else {
          completion(publicGroupChannels);
        }
      })
      .catch((error) => {
        completion([]);
        Sentry.addBreadcrumb({
          category: 'chat',
          message: 'fetchMySendbirdChannels',
        });
        Sentry.captureException(error);
      });
  };

  fetchChannels(publicGroupChannelListQuery, completion);
};
