import { FC, Fragment } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraSkeleton,
  ChakraStack,
} from '@equip.health/ui';

import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';

const TOP_LEVEL_ID = 'schedule-loader';

interface ScheduleLoaderProps {
  isFullWidth?: boolean;
  isQualifyingCall?: boolean;
  isScheduleOverview?: boolean;
}

const loaderStartColor = '#F7F7F7';
const loaderEndColor = '#F7F7F740';

const ScheduleLoader: FC<ScheduleLoaderProps> = ({
  isFullWidth = false,
  isQualifyingCall = false,
  isScheduleOverview,
}) => {
  const isMobile = useMobileBreakpoint();

  return (
    <ChakraBox marginTop="20px" width="100%">
      <ChakraStack spacing={isMobile ? '20px' : '40px'}>
        {[...Array(5)]
          .map((_, i) => i + 1)
          .map((val) => {
            return (
              <Fragment key={`${TOP_LEVEL_ID}_${val}`}>
                {isMobile ? (
                  <ChakraSkeleton
                    endColor={loaderEndColor}
                    height="80px"
                    startColor={loaderStartColor}
                    width="100%"
                  />
                ) : (
                  <ChakraFlex
                    direction={isQualifyingCall ? 'column' : 'row'}
                    gridGap="12px"
                    justify="start"
                  >
                    <ChakraSkeleton
                      endColor={loaderEndColor}
                      height="40px"
                      startColor={loaderStartColor}
                      width="20%"
                    />

                    <ChakraSkeleton
                      endColor={loaderEndColor}
                      height={isScheduleOverview ? '40px' : '80px'}
                      startColor={loaderStartColor}
                      width={isFullWidth ? '100%' : '60%'}
                    />
                  </ChakraFlex>
                )}
              </Fragment>
            );
          })}
      </ChakraStack>
    </ChakraBox>
  );
};

export default ScheduleLoader;
