import { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import Api from '~/lib/Api';
import { VITE_AUTH0_AUDIENCE } from '~/lib/constants/env';
import { AUTH0ERROR, EquipAppPath } from '../constants';

const API_ERROR = 'Error occurred';

const getError = (error: string | ApiError | undefined): string => {
  console.log(typeof error, error);
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error?.data?._embedded?.errors?.[0]?.message === 'string') {
    return error.data?._embedded.errors[0].message;
  }
  if (typeof error?.message === 'string') {
    return error?.message;
  }
  return API_ERROR;
};

const useApi = <T extends unknown>(): UseApiHookState<T> => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const defaultState: UseApiState<T> = {
    data: null,
    didTimeOut: false,
    error: null,
    errorData: null,
    loading: false,
    statusCode: null,
  };

  const [state, setState] = useState<UseApiState<T>>(defaultState);

  const resetError = (): void => {
    setState({
      ...state,
      error: defaultState.error,
      errorData: defaultState.errorData,
    });
  };

  const resetData = (): void => {
    setState({ ...state, data: defaultState.data });
  };

  /**
   * Hook function to make the HTTP request to our API Gateway.
   *
   * @param command The command to execute in the request (GET, POST, PATCH, etc.).
   * @param url The relative path to our services, relative to the base URL of the API Gateway.
   * @param options Object containing either query params for GET requests or `body` of a POST request.
   * @param mock Flag to determine whether to use a mock API call or our actual API backend.
   */
  const sendRequest = ({
    callback,
    command,
    url,
    options = {},
    mock = false,
    skipToken = false,
    googleRecaptchaToken,
  }: SendRequestParams<T>): void => {
    setState({
      ...defaultState,
      loading: true,
    });

    (async () => {
      const newState: UseApiState<T> = {
        ...defaultState,
        loading: false,
      };

      let accessToken: string;

      if (!skipToken) {
        try {
          accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: VITE_AUTH0_AUDIENCE,
              scope: 'offline_access',
            },
          });
        } catch (e) {
          if (
            e.error === AUTH0ERROR.INVALID_GRANT ||
            e.error === AUTH0ERROR.MISSING_REFRESH_TOKEN ||
            e.error === AUTH0ERROR.LOGIN_REQUIRED
          ) {
            loginWithRedirect({
              authorizationParams: {
                redirectUri: `${window.location.origin}${EquipAppPath.HOME}`,
              },
            });
          }
          console.error('Error getting access token', e);
          newState.data = null;
          newState.statusCode = 501;
          newState.error = 'Authentication error';
        }
      }

      if (accessToken || skipToken) {
        try {
          const { data, statusCode, error } = await Api[command]<T>(
            accessToken,
            url,
            options,
            mock,
            googleRecaptchaToken,
          );

          newState.data = data ?? null;
          newState.statusCode = statusCode ?? null;
          newState.error =
            statusCode === 200 || statusCode === 201 ? null : getError(error);
        } catch (error) {
          newState.data = null;
          newState.statusCode = null;
          newState.error = getError(error);
        }
      }

      setState(newState);
      callback?.(newState.data, newState.statusCode, newState.error);
    })();
  };

  return {
    ...state,
    resetData,
    resetError,
    sendRequest,
  };
};

export default useApi;
