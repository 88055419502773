import { FC, memo } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraLink,
  ChakraText,
} from '@equip.health/ui';

import PROVIDER_MEDICAL_EVALUATION_FORM from '../../assets/pdf/ProviderMedicalEvaluationForm.pdf';
import MEDICAL_EVALUATION_BACKGROUND from '../../assets/pdf/MedicalEvaluationBackground.pdf';
import {
  EmailProvider,
  FamilyTaskSubcategory,
} from '~/lib/constants/tasks.constants';

export interface TaskModalFooterProps {
  taskSubCategoryName?: string;
}

const TaskModalFooter: FC<TaskModalFooterProps> = ({ taskSubCategoryName }) => (
  <>
    {taskSubCategoryName === FamilyTaskSubcategory.MEDICAL_CLEARANCE && (
      <ChakraFlex direction="column" marginBottom="16px">
        <ChakraBox>
          <ChakraLink
            color="teal.500"
            fontSize="sm"
            href={MEDICAL_EVALUATION_BACKGROUND}
            target="_blank"
          >
            Medical clearance instructions (PDF)
          </ChakraLink>
        </ChakraBox>
        <ChakraBox>
          <ChakraLink
            color="teal.500"
            fontSize="sm"
            href={PROVIDER_MEDICAL_EVALUATION_FORM}
            target="_blank"
          >
            Provider medical evaluation form (PDF)
          </ChakraLink>
        </ChakraBox>
      </ChakraFlex>
    )}
    {(taskSubCategoryName ===
      FamilyTaskSubcategory.MEDICAL_RECORDS_COORDINATION ||
      taskSubCategoryName === FamilyTaskSubcategory.CONSENT_FORMS) && (
        <ChakraFlex
          gridGap={{ base: '8px', sm: '10px', md: '24px' }}
          marginBottom="16px"
        >
          <ChakraText
            color="mono.60"
            fontSize="13px"
            fontWeight="400"
            lineHeight="18px"
          >
            Open:
          </ChakraText>
          <ChakraLink
            color="teal.500"
            fontSize="sm"
            href={EmailProvider.GMAIL}
            target="_blank"
          >
            Gmail
          </ChakraLink>
          <ChakraLink
            color="teal.500"
            fontSize="sm"
            href={EmailProvider.AOL}
            target="_blank"
          >
            AOL
          </ChakraLink>
          <ChakraLink
            color="teal.500"
            fontSize="sm"
            href={EmailProvider.OUTLOOK}
            target="_blank"
          >
            Outlook
          </ChakraLink>
        </ChakraFlex>
      )}
  </>
);

export default memo(TaskModalFooter);
