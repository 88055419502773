import { FC } from 'react';

import { ChakraBox, ChakraFlex, ChakraSpacer } from '@equip.health/ui';

import UnreadMessageIndicator from '../chat/UnreadMessageIndicator';
import AuthenticatedMenu from './AuthenticatedMenu';
import MobileAuthNavItem from './MobileAuthNavItem';
import { MyEquipNavItem } from '~/lib/constants/authenticatedNav.constants';

interface MobileAuthenticatedNavProps {
  id?: string;
  isHomeSelected: boolean;
  isMessagesSelected: boolean;
  isScheduleSelected: boolean;
  isShowUnreadMessageIndicator: boolean;
  onHomeClick: () => void;
  onMessagesClick: () => void;
  onScheduleClick: () => void;
}

const MobileAuthenticatedNav: FC<MobileAuthenticatedNavProps> = ({
  id,
  onHomeClick,
  isHomeSelected,
  isMessagesSelected,
  isScheduleSelected,
  isShowUnreadMessageIndicator,
  onMessagesClick,
  onScheduleClick,
}: MobileAuthenticatedNavProps) => {
  return (
    <ChakraFlex
      backgroundColor="white"
      borderTop="1px solid rgba(0, 0, 0, 0.1)"
      bottom="0"
      id={`${id}_container`}
      justifyContent="center"
      position="fixed"
      width="100%"
      zIndex="100"
    >
      <ChakraBox width="100%">
        <ChakraFlex>
          <ChakraSpacer />
          <MobileAuthNavItem
            id={`${id}_nav_bar_tab`}
            isSelected={isHomeSelected}
            itemType={MyEquipNavItem.HOME}
            onClick={onHomeClick}
            title="Home"
          />
          <ChakraSpacer />
          <MobileAuthNavItem
            id={`${id}_nav_bar_tab`}
            isSelected={isScheduleSelected}
            itemType={MyEquipNavItem.SCHEDULE}
            onClick={onScheduleClick}
            title="Schedule"
          />
          <ChakraSpacer />
          <ChakraBox position="relative">
            <MobileAuthNavItem
              id={`${id}_nav_bar_tab`}
              isSelected={isMessagesSelected}
              itemType={MyEquipNavItem.MESSAGES}
              onClick={onMessagesClick}
              title="Messages"
            />
            {isShowUnreadMessageIndicator && (
              <ChakraBox position="absolute" right="15px" top="10px">
                <UnreadMessageIndicator
                  id={`${id}__nav-bar-tab__message-indicator`}
                />
              </ChakraBox>
            )}
          </ChakraBox>
          <ChakraSpacer />
          <ChakraBox id={id} padding="2">
            <ChakraFlex alignItems="center" flexDirection="column">
              <AuthenticatedMenu isMobile />
            </ChakraFlex>
          </ChakraBox>
          <ChakraSpacer />
        </ChakraFlex>
      </ChakraBox>
    </ChakraFlex>
  );
};

export default MobileAuthenticatedNav;
