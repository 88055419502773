import { FC, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { isNil } from 'lodash';
import { Redirect } from 'react-router-dom';

import AuthenticatedPageLoader from '~/components/common/AuthenticatedPageLoader';
import SchedulePageView from '~/components/schedule/SchedulePageView';
import AuthenticatedPageLayout from '~/layouts/AuthenticatedPageLayout';
import { Path } from '~/lib/constants';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { PAGE_NAME } from '~/lib/constants/analytics';

const SchedulePage: FC = () => {
  const { isAuthenticated, isLoading: isAuth0Loading } = useAuth0();
  const { userProfile } = useUserProfileContext();
  const isProfileLoading = isAuth0Loading;

  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView(PAGE_NAME.schedule);
  }, []);

  if (isProfileLoading || isNil(userProfile)) {
    return <AuthenticatedPageLoader />;
  }

  if (!isAuthenticated) {
    return <Redirect to={Path.LOGIN_V2} />;
  }
  return (
    <AuthenticatedPageLayout>
      <SchedulePageView />
    </AuthenticatedPageLayout>
  );
};

export default SchedulePage;
