import { FC } from 'react';

import { Avatar, ChakraFlex, FAIconName, Icon, Text } from '@equip.health/ui';

import { NAME_CONNECTOR, SPACE } from '~/lib/constants/chat/chat.constants';

interface ParticipantProps {
  avatarSize?: 'sm' | 'xs';
  id: string;
  isGroup?: boolean;
  listSource?: 'schedule' | 'chat';
  marginBottom?: string;
  marginTop?: string;
  primaryLabel: string;
  secondaryLabel?: string;
  userId: string;
  userType?: EquipUserType;
}

// eslint-disable-next-line import/prefer-default-export
export const Participant: FC<ParticipantProps> = ({
  avatarSize = 'sm',
  id,
  isGroup,
  listSource,
  marginBottom = '0px',
  marginTop = '0px',
  primaryLabel,
  secondaryLabel,
  userId,
  userType,
}) => {
  const titleVariant = listSource === 'schedule' ? 'body' : 'bodyMedium';
  const cleanPrimaryLabel = primaryLabel.replace(NAME_CONNECTOR, SPACE);

  return (
    <ChakraFlex
      alignItems="center"
      key={`participant__${userId}`}
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      <Avatar
        id={`${id}__participant__avatar__${userId}`}
        name={cleanPrimaryLabel}
        size={avatarSize}
        userType={userType}
      />
      <ChakraFlex direction="column" gridGap="2px" marginLeft="12px">
        <ChakraFlex alignItems="center" gridGap="6px">
          {isGroup && <Icon name={FAIconName.userGroupSolid} size="sm" />}
          <Text
            color="neutral.primary"
            id={`${id}__participant__name__${userId}`}
            variant={titleVariant}
          >
            {primaryLabel}
          </Text>
        </ChakraFlex>
        <Text
          color="neutral.secondary"
          id={`${id}__participant__type__${userId}`}
          variant="caption"
        >
          {secondaryLabel}
        </Text>
      </ChakraFlex>
    </ChakraFlex>
  );
};
