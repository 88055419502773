import { FC, useMemo } from 'react';

import {
  ChakraFlex,
  EquipUIFireflyV1Theme,
  FAIconName,
  Icon,
  Text,
  useDisclosure,
} from '@equip.health/ui';
import { GroupChannel } from '@sendbird/chat/groupChannel';

import {
  CHAT_LAYOUT,
  EMPTY_CHANNEL,
} from '~/lib/constants/chat/chat.constants';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { ChatUser } from '~/lib/types/chat';
import { groupedChatChannelsByGroupType } from '~/lib/util/chat/chat.utils';

import ChannelTile from './ChannelTile';
import ChatPatientSelectorMenu from './ChatPatientSelectorMenu';
import MessagesInformationalModal from './MessagesInformationalModal';

interface ChatChannelListProps {
  channels: GroupChannel[];
  id: string;
  isPatient: boolean;
  onChannelChanged?: (channel: GroupChannel) => void;
  patient: ChatUser;
  selectedChannelUrl?: string;
}

const idPrefix = 'Channel';
const idPostfix = 'List';
const { primary } = EquipUIFireflyV1Theme.colors;

const channelSort = (groupChannel: GroupChannel[]) =>
  groupChannel.sort((a, b) => {
    return b?.lastMessage?.createdAt - a?.lastMessage?.createdAt;
  });

interface ChatChannelSectionProps {
  channels: GroupChannel[];
  id: string;
  isPatient: boolean;
  onChannelChanged?: (channel: GroupChannel) => void;
  patient: ChatUser;
  selectedChannelUrl?: string;
  title: string;
}

const ChatChannelSection: FC<ChatChannelSectionProps> = ({
  channels,
  id,
  isPatient,
  onChannelChanged,
  patient,
  selectedChannelUrl,
  title,
}) => {
  const sortedChannels = useMemo(() => {
    const unreadChannels = channelSort(
      channels.filter((channel) => channel.unreadMessageCount > 0),
    );
    const readChannels = channelSort(
      channels.filter((channel) => channel.unreadMessageCount <= 0),
    );
    return [...unreadChannels, ...readChannels];
  }, [channels]);

  if (sortedChannels.length === 0) {
    return null;
  }

  return (
    <ChakraFlex
      direction="column"
      gridGap="8px"
      id={`${idPrefix}-container__${id}__${idPostfix}`}
      width="100%"
    >
      <Text
        color="black.70"
        id={title}
        paddingLeft="12px"
        variant="captionMedium"
      >
        {title}
      </Text>
      {sortedChannels.map((channel, index) => (
        <ChannelTile
          channel={channel}
          id={channel.url}
          isDividerVisible={index < sortedChannels.length - 1}
          isPatient={isPatient}
          isSelected={channel.url === selectedChannelUrl}
          key={channel.url}
          onClick={(channelUrl) => {
            const foundChannel = (sortedChannels ?? []).find(
              (x) => x.url === channelUrl,
            );
            onChannelChanged(foundChannel);
          }}
          patient={patient}
        />
      ))}
    </ChakraFlex>
  );
};

const ChatChannelList = ({
  channels,
  id,
  isPatient,
  onChannelChanged,
  patient,
  selectedChannelUrl,
}: ChatChannelListProps) => {
  const {
    isOpen: isInformationalModalOpen,
    onOpen: onInformationalModalOpen,
    onClose: onInformationalModalClose,
  } = useDisclosure();

  const { userProfile } = useUserProfileContext();

  const shouldShowInformationalModalSection =
    channels.length > 0 &&
    (userProfile.isPatient || userProfile.linkedPatients[0].isProxy);

  const { directMessageChannels, groupChannels, admissionsChannel } =
    groupedChatChannelsByGroupType(channels);

  return (
    <>
      <MessagesInformationalModal
        channels={channels}
        isOpen={isInformationalModalOpen}
        isPatient={isPatient}
        onClose={onInformationalModalClose}
        patient={patient}
      />
      <ChakraFlex
        borderRight="1px"
        borderRightColor="black.10"
        direction="column"
        height="100%"
        id={`${idPrefix}__channel-list__${idPostfix}`}
      >
        <ChakraFlex
          alignItems="center"
          backgroundColor="white.100"
          direction="row"
          height={CHAT_LAYOUT.headerHeight}
          id={`${idPrefix}-header-bar__${id}__${idPostfix}`}
          justifyContent="space-between"
          padding="15px 24px"
          width="100%"
        >
          <Text id={`${idPrefix}-heading__${id}__${idPostfix}`} variant="h3">
            Messages
          </Text>
          <ChatPatientSelectorMenu />
        </ChakraFlex>

        <ChakraFlex
          direction="column"
          gridGap="8px"
          id={`${idPrefix}-container__${id}__${idPostfix}`}
          padding="12px"
          width="100%"
        >
          {channels?.length === 0 ? (
            <ChannelTile
              channel={null}
              id={EMPTY_CHANNEL.url}
              isEmptyChat
              isPatient={isPatient}
              isSelected={false}
              key={EMPTY_CHANNEL.name}
              patient={patient}
            />
          ) : (
            <>
              <ChatChannelSection
                channels={
                  isPatient
                    ? [...directMessageChannels, ...admissionsChannel]
                    : directMessageChannels
                }
                id={`${idPrefix}-direct-message__${id}__${idPostfix}`}
                isPatient={isPatient}
                onChannelChanged={onChannelChanged}
                patient={patient}
                selectedChannelUrl={selectedChannelUrl}
                title="Direct messages"
              />
              <ChatChannelSection
                channels={
                  isPatient
                    ? groupChannels
                    : [...groupChannels, ...admissionsChannel]
                }
                id={`${idPrefix}-group__${id}__${idPostfix}`}
                isPatient={isPatient}
                onChannelChanged={onChannelChanged}
                patient={patient}
                selectedChannelUrl={selectedChannelUrl}
                title="Group channels"
              />
            </>
          )}
          {shouldShowInformationalModalSection && (
            <ChakraFlex
              as="button"
              gridGap="8px"
              height="40px"
              marginLeft="12px"
              marginTop="24px"
              onClick={onInformationalModalOpen}
            >
              <Icon
                color={primary[100]}
                name={FAIconName.infoCircleRegular}
                size="sm"
              />
              <Text color="primary.100" variant="captionMedium">
                Learn more about messages
              </Text>
            </ChakraFlex>
          )}
        </ChakraFlex>
      </ChakraFlex>
    </>
  );
};

ChatChannelList.displayName = 'ChatChannelList';
export default ChatChannelList;
