import {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import {
  AddressData,
  ChakraBox,
  ChakraFlex,
  ChakraText,
  DatePicker,
  EquipUIFireflyV1Theme,
  FireflyButton,
  MyEquipGooglePlacesAutoComplete,
  PhoneNumber,
  Text,
  TextInput,
  format,
  getDateFromString,
} from '@equip.health/ui';
import * as Sentry from '@sentry/react';
import { validate as validateEmail } from 'email-validator';
import isNil from 'lodash/isNil';

import NonEditablePopoverToolTip from './NonEditablePopoverToolTip';
import { DropdownGridItem, MultiSelectGridItem } from './ProfileEditModeItem';
import ProfileFieldReducer, {
  ProfileFieldActionType,
  ProfileFieldReducerProps,
} from './ProfileFieldReducer';
import useEnumsForProfilePage from './useEnumsForProfilePage';
import NotificationPreferences, {
  NotificationsOffWarningData,
  SMSWarningData,
} from '~/components/common/NotificationPreferences';
import WarnChangesModal, {
  WarnChangesModalViewDataProps,
} from '~/components/common/WarnChangesModal';
import { ApiCommand } from '~/lib/Api';
import {
  GOOGLE_ADDRESS_EMPTY_VALUE,
  PHONE_NUMBER_INPUT_PLACEHOLDER,
} from '~/lib/constants';
import urlConstants from '~/lib/constants/url.constants';
import { useProfilePageContext } from '~/lib/context/ProfilePageContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useApi } from '~/lib/hooks';
import useTimezone from '~/lib/hooks/useTimezone';
import ProfileField from '~/lib/util/profileFields.util';
import { validatePhoneNumber } from '~/lib/utils';

const GOOGLE_PLACES_API_KEY = import.meta.env.VITE_GOOGLE_PLACES_API_KEY;

const TOP_LEVEL_ID = 'profile-edit-mode';

const { mono } = EquipUIFireflyV1Theme.colors;

const {
  updateSupportProfile,
  updateUserProfile,
  updatePatientNotificationPreference: updatePatientNotificationPreferenceUrl,
  updateSupportNotificationPreference: updateSupportNotificationPreferenceUrl,
} = urlConstants.users;

const isAddressFieldsEmpty = (
  addressData: GooglePlacesField,
  requiredFields: string[],
): boolean =>
  Object.entries(addressData ?? {}).every(
    ([key, val]: [string, string | number]) =>
      requiredFields.includes(key)
        ? !val || val.toString().trim() === ''
        : true,
  );

const isAddressAllRequiredFieldsFilled = (
  addressData: GooglePlacesField,
  requiredFields: string[],
): boolean => {
  return Object.entries(addressData ?? {}).every(
    ([key, val]: [string, string | number]) =>
      requiredFields.includes(key)
        ? (val ?? '').toString().trim() !== ''
        : true,
  );
};

const PATIENT_REQUIRED_ADDRESS_FIELD = ['state'];
const SUPPORT_REQUIRED_ADDRESS_FIELD = [
  'addressLine1',
  'city',
  'state',
  'country',
  'zipCode',
];

const ADDRESS_FIELD = [
  'addressLine1',
  'addressLine2',
  'city',
  'state',
  'country',
  'zipCode',
];

const TEXT_INPUT_STYLING = {
  backgroundColor: 'white',
  border: `1px solid ${mono[10]}`,
  color: mono[85],
  formLabelMarginLeft: '0',
};

const ProfileEditMode: FC<{
  fields: ProfileField[];
  handleOnDismiss: () => void;
}> = ({ fields, handleOnDismiss }) => {
  const {
    contactPreferencesOptions,
    gendersMenuOptions,
    isContactPreferencesLoading,
    isGendersLoading,
    isLanguagesLoading,
    isPronounsLoading,
    isStatesLoading,
    isTimezonesLoading,
    languagesMenuOption,
    pronounsMenuOptions,
    statesMenuOptions,
    timezonesMenuOptions,
  } = useEnumsForProfilePage();

  const {
    activeProfile,
    onProfileUpdateSuccessful,
    profileEditViewPermission: permission,
  } = useProfilePageContext();

  const {
    userProfile: currentUserBasicInfo,
    updateBasicInfo: updateCurrentUserBasicInfo,
  } = useUserProfileContext();

  const { getTimezoneFromLatLong, timezoneData } = useTimezone();

  const [contactField, updateContactField] =
    useReducer<ProfileFieldReducerProps>(
      ProfileFieldReducer,
      {} as UserProfile,
    );

  const [showWarningModal, setShowWarningModal] =
    useState<WarnChangesModalViewDataProps | null>(null);

  const {
    error: updateProfileError,
    loading: updateProfileLoading,
    sendRequest: updateProfile,
    statusCode: updateProfileStatusCode,
  } = useApi();

  const {
    address,
    cellPhone,
    chosenName,
    contactPreferences,
    dateOfBirth,
    email,
    firstName,
    gender,
    isPatient,
    languages,
    lastName,
    pronouns,
    preferences,
  } = contactField;

  if (!isNil(address?.timezone) && address.timezone === '') {
    address.timezone = contactField.timezone;
  }

  const { receiveEmails, receiveSms } = preferences?.notifications ?? {};

  useEffect(
    () =>
      updateContactField({
        type: ProfileFieldActionType.INITIALIZE,
        value: activeProfile,
      }),
    [activeProfile?.externalId],
  );

  const requiredAddressFields = useMemo(() => {
    if (activeProfile?.isPatient) return PATIENT_REQUIRED_ADDRESS_FIELD;

    if (
      isAddressFieldsEmpty(address ?? GOOGLE_ADDRESS_EMPTY_VALUE, ADDRESS_FIELD)
    )
      return [];
    return SUPPORT_REQUIRED_ADDRESS_FIELD;
  }, [activeProfile, contactField?.address]);

  const isValidPhoneNumber = useMemo<boolean>(
    () => validatePhoneNumber(cellPhone ?? ''),
    [cellPhone],
  );

  const isSaveButtonDisabled = useMemo(() => {
    if (fields.includes(ProfileField.ADDRESS)) {
      if (isPatient)
        return !isAddressAllRequiredFieldsFilled(
          address,
          requiredAddressFields,
        );
      return !(
        isAddressFieldsEmpty(address, ADDRESS_FIELD) ||
        isAddressAllRequiredFieldsFilled(address, requiredAddressFields)
      );
    }

    if (fields.includes(ProfileField.CELLPHONE)) {
      return !isValidPhoneNumber;
    }

    if (fields.includes(ProfileField.NOTIFICATION)) {
      return (
        activeProfile?.preferences?.notifications?.receiveEmails ===
          receiveEmails &&
        activeProfile?.preferences?.notifications?.receiveSms === receiveSms
      );
    }

    return false;
  }, [
    address,
    fields,
    isPatient,
    isValidPhoneNumber,
    requiredAddressFields,
    receiveEmails,
    receiveSms,
  ]);

  useEffect(() => {
    if (updateProfileStatusCode === 200) {
      handleOnDismiss();
      onProfileUpdateSuccessful({
        ...contactField,
        address: {
          ...contactField?.address,
          zip: contactField?.address?.zipCode,
        },
      });

      const isCurrentUser =
        activeProfile.externalId === currentUserBasicInfo.externalId;

      if (isCurrentUser) {
        updateCurrentUserBasicInfo(chosenName);
      }
    }
  }, [updateProfileStatusCode]);

  const onSaveNotification = () => {
    updateProfile({
      command: ApiCommand.PUT,
      options: {
        receiveNotificationEmails: receiveEmails,
        receiveNotificationSms: receiveSms,
      },
      url: activeProfile.isPatient
        ? updatePatientNotificationPreferenceUrl(activeProfile?.externalId)
        : updateSupportNotificationPreferenceUrl(activeProfile?.externalId),
    });
  };

  const onSaveProfileData = () => {
    const payload = {
      addressLine1: address?.addressLine1,
      addressLine2: address?.addressLine2,
      affiliationName: address?.name,
      cellPhone: cellPhone ?? '',
      chosenName,
      city: address?.city,
      contactPreferences,
      country: address?.country,
      languages,
      latitude: address?.latitude,
      longitude: address?.longitude,
      state: address?.state,
      timezone: address?.timezone,
      zip: address?.zipCode,
      ...(isPatient
        ? { county: address?.county, dateOfBirth }
        : { gender, pronouns }),
    };

    updateProfile({
      command: ApiCommand.PUT,
      options: payload,
      url: isPatient
        ? updateSupportProfile(activeProfile?.externalId)
        : updateUserProfile,
    });
  };

  const onSave = () => {
    if (fields.includes(ProfileField.NOTIFICATION)) {
      if (!receiveEmails && !receiveSms)
        setShowWarningModal(NotificationsOffWarningData);
      else if (!receiveSms) setShowWarningModal(SMSWarningData);
      else onSaveNotification();
    } else onSaveProfileData();
  };

  const isProfileFieldViewable = (profileField: ProfileField) =>
    permission[profileField]?.isViewable && fields.includes(profileField);

  const responsiveStyles = {
    marginTop: { base: '16px', lg: 'unset' },
    marginX: { base: 'unset', lg: '16px' },
  };
  useEffect(() => {
    if (updateProfileError) {
      Sentry.captureException(`Update Profile Error - ${updateProfileError}`);
    }
  }, [updateProfileError]);

  return (
    <>
      <WarnChangesModal
        {...(showWarningModal ?? NotificationsOffWarningData)}
        handleClose={() => setShowWarningModal(null)}
        handleConfirmDiscard={() => {
          setShowWarningModal(null);
          onSaveNotification();
        }}
        isOpen={Boolean(showWarningModal)}
        showCancelButton
      />

      <ChakraFlex direction={{ base: 'column', lg: 'row' }} width="100%">
        {isProfileFieldViewable(ProfileField.FIRST_NAME) && (
          <ChakraBox flex="1">
            <TextInput
              {...TEXT_INPUT_STYLING}
              id={`${TOP_LEVEL_ID}__first-name`}
              isDisabled={!permission[ProfileField.FIRST_NAME]?.isEditable}
              label={
                <ChakraFlex alignItems="center" gridGap="4px">
                  <ChakraText>First name</ChakraText>
                  <NonEditablePopoverToolTip />
                </ChakraFlex>
              }
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                updateContactField({
                  type: ProfileFieldActionType?.UPDATE_FIRST_NAME,
                  value: event.target.value,
                });
              }}
              value={firstName}
            />
          </ChakraBox>
        )}
        {isProfileFieldViewable(ProfileField.CHOSEN_NAME) && (
          <ChakraBox
            flex="1"
            marginTop={responsiveStyles.marginTop}
            marginX={responsiveStyles.marginX}
          >
            <TextInput
              {...TEXT_INPUT_STYLING}
              id={`${TOP_LEVEL_ID}__chosen-name`}
              isDisabled={!permission[ProfileField.CHOSEN_NAME]?.isEditable}
              label="Chosen name"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                updateContactField({
                  type: ProfileFieldActionType?.UPDATE_CHOSEN_NAME,
                  value: event.target.value,
                });
              }}
              value={chosenName}
            />
          </ChakraBox>
        )}
        {isProfileFieldViewable(ProfileField.LAST_NAME) && (
          <ChakraBox flex="1" marginTop={responsiveStyles.marginTop}>
            <TextInput
              {...TEXT_INPUT_STYLING}
              id={`${TOP_LEVEL_ID}__last-name`}
              isDisabled={!permission[ProfileField.LAST_NAME]?.isEditable}
              label={
                <ChakraFlex alignItems="center" gridGap="4px">
                  <ChakraText>Last name</ChakraText>
                  <NonEditablePopoverToolTip />
                </ChakraFlex>
              }
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                updateContactField({
                  type: ProfileFieldActionType?.UPDATE_CHOSEN_NAME,
                  value: event.target.value,
                });
              }}
              value={lastName}
            />
          </ChakraBox>
        )}

        {isProfileFieldViewable(ProfileField.DATE_OF_BIRTH) && (
          <ChakraBox flex="1" maxWidth={{ base: 'unset', lg: '286px' }}>
            <DatePicker
              background="white"
              color={mono[85]}
              id={`${TOP_LEVEL_ID}__date-of-birth`}
              isDisabled={!permission[ProfileField.DATE_OF_BIRTH]?.isEditable}
              maxDate={new Date()}
              onSelect={(date) => {
                updateContactField({
                  type: ProfileFieldActionType?.UPDATE_DATE_OF_BIRTH,
                  value: format(new Date(date), 'yyyy-LL-dd'),
                });
              }}
              placeHolderText="MM/DD/YYYY"
              styleForMyEquip
              value={dateOfBirth ? getDateFromString(dateOfBirth) : null}
            />
          </ChakraBox>
        )}

        {(fields.includes(ProfileField.GENDER) ||
          fields.includes(ProfileField.PRONOUNS)) && (
          <ChakraFlex direction={{ base: 'column', lg: 'row' }} flex="1">
            {isProfileFieldViewable(ProfileField.GENDER) && (
              <ChakraBox
                flex="1"
                marginRight="16px"
                maxWidth={{ base: 'unset', lg: '286px' }}
              >
                <DropdownGridItem
                  id="gender"
                  isDisabled={!permission[ProfileField.GENDER]?.isEditable}
                  isLoading={isGendersLoading}
                  label="Gender"
                  options={gendersMenuOptions}
                  placeholder="Select a gender"
                  updateActionTag={ProfileFieldActionType.UPDATE_GENDER}
                  updateContactField={updateContactField}
                  value={gender}
                />
              </ChakraBox>
            )}
            {isProfileFieldViewable(ProfileField.PRONOUNS) && (
              <ChakraBox
                flex="1"
                marginTop={responsiveStyles.marginTop}
                maxWidth={{ base: 'unset', lg: '286px' }}
              >
                <MultiSelectGridItem
                  id={`${TOP_LEVEL_ID}__pronouns`}
                  isDisabled={!permission[ProfileField.PRONOUNS]?.isEditable}
                  isLoading={isPronounsLoading}
                  label="Pronouns"
                  options={pronounsMenuOptions}
                  placeholder="Select pronouns"
                  updateActionTag={ProfileFieldActionType.UPDATE_PRONOUNS}
                  updateContactField={updateContactField}
                  values={(pronouns ?? []).map((value) => ({
                    node: value,
                    value,
                  }))}
                />
              </ChakraBox>
            )}
          </ChakraFlex>
        )}
        {isProfileFieldViewable(ProfileField.LANGUAGES) && (
          <ChakraBox flex="1" maxWidth={{ base: 'unset', lg: '445px' }}>
            <MultiSelectGridItem
              id="languages-spoken"
              isDisabled={!permission[ProfileField.LANGUAGES]?.isEditable}
              isLoading={isLanguagesLoading}
              options={languagesMenuOption}
              placeholder="Select all that apply..."
              updateActionTag={ProfileFieldActionType.UPDATE_LANGUAGE}
              updateContactField={updateContactField}
              values={(languages ?? []).map((value) => ({
                node: value,
                value,
              }))}
            />
          </ChakraBox>
        )}

        {isProfileFieldViewable(ProfileField.EMAIL) && (
          <ChakraBox flex="1" maxWidth={{ base: 'unset', lg: '286px' }}>
            <TextInput
              errorMessage="Please enter a valid email"
              id={`${TOP_LEVEL_ID}__email`}
              isDisabled={!permission[ProfileField.EMAIL]?.isEditable}
              isInvalid={email && !validateEmail(email)}
              isRequired
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                updateContactField({
                  type: ProfileFieldActionType?.UPDATE_EMAIL,
                  value: event.target.value,
                });
              }}
              type="email"
              value={email}
            />
          </ChakraBox>
        )}

        {isProfileFieldViewable(ProfileField.CELLPHONE) && (
          <ChakraBox flex="1" maxWidth={{ base: 'unset', lg: '286px' }}>
            <PhoneNumber
              {...TEXT_INPUT_STYLING}
              id={`${TOP_LEVEL_ID}__phone-number`}
              isEquipApp
              isInvalid={!cellPhone || !isValidPhoneNumber}
              onChange={(value: string) => {
                updateContactField({
                  type: ProfileFieldActionType?.UPDATE_PHONE_NUMBER,
                  value,
                });
              }}
              placeholder={PHONE_NUMBER_INPUT_PLACEHOLDER}
              value={cellPhone ?? ''}
            />
          </ChakraBox>
        )}
        {isProfileFieldViewable(ProfileField.CONTACT_PREFERENCES) && (
          <ChakraBox flex="1" maxWidth={{ base: 'unset', lg: '286px' }}>
            <MultiSelectGridItem
              id="contact-preference"
              isDisabled={
                !permission[ProfileField.CONTACT_PREFERENCES]?.isEditable
              }
              isLoading={isContactPreferencesLoading}
              options={contactPreferencesOptions}
              placeholder="Select all that apply..."
              updateActionTag={ProfileFieldActionType.UPDATE_CONTACT_PREFERENCE}
              updateContactField={updateContactField}
              values={(contactPreferences ?? []).map((value) => ({
                node: value,
                value,
              }))}
            />
          </ChakraBox>
        )}

        {isProfileFieldViewable(ProfileField.ADDRESS) && (
          <ChakraBox
            flex="1"
            fontWeight="400"
            maxWidth={{ base: 'unset', lg: '580px' }}
          >
            <MyEquipGooglePlacesAutoComplete
              addressData={address ?? GOOGLE_ADDRESS_EMPTY_VALUE}
              apiKey={GOOGLE_PLACES_API_KEY}
              getTimezone={getTimezoneFromLatLong}
              id={`${TOP_LEVEL_ID}__referrer-affiliation`}
              isGetStatesLoading={isStatesLoading}
              isGetTimezonesLoading={isTimezonesLoading}
              requiredFields={requiredAddressFields}
              showAddressFields
              showCounty={isPatient}
              statesMenuOptions={statesMenuOptions}
              timezoneData={timezoneData}
              timezoneMenuOptions={timezonesMenuOptions}
              updateAddressData={(address: AddressData) => {
                updateContactField({
                  type: ProfileFieldActionType?.UPDATE_ADDRESS,
                  value: address,
                });
              }}
            />
          </ChakraBox>
        )}
      </ChakraFlex>

      {isProfileFieldViewable(ProfileField.NOTIFICATION) && (
        <NotificationPreferences
          isEmailChecked={receiveEmails}
          isSmsChecked={receiveSms}
          onEmailCheckboxChange={(isSelected) => {
            updateContactField({
              type: ProfileFieldActionType?.UPDATE_NOTIFICATION_EMAIL,
              value: isSelected,
            });
          }}
          onSmsCheckboxChange={(isSelected) => {
            updateContactField({
              type: ProfileFieldActionType?.UPDATE_NOTIFICATION_SMS,
              value: isSelected,
            });
          }}
        />
      )}

      <ChakraBox marginBottom="32px" marginTop="48px">
        <ChakraFlex gridGap="32px">
          <FireflyButton
            id={`${TOP_LEVEL_ID}__save`}
            isDisabled={isSaveButtonDisabled || updateProfileLoading}
            isLoading={updateProfileLoading}
            onClick={onSave}
          >
            Save
          </FireflyButton>

          <FireflyButton
            id={`${TOP_LEVEL_ID}__discard`}
            isDisabled={updateProfileLoading}
            onClick={handleOnDismiss}
            variant="tertiary"
          >
            Cancel
          </FireflyButton>
        </ChakraFlex>
        {updateProfileError && (
          <Text
            color="critical.text"
            id={`${TOP_LEVEL_ID}__error-message`}
            marginTop="18px"
            variant="caption"
          >
            {updateProfileError}
          </Text>
        )}
      </ChakraBox>
    </>
  );
};

export default ProfileEditMode;
