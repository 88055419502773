import { FC } from 'react';

import { Text } from '@equip.health/ui';

export interface StepCardTitleProps {
  stepId: string;
  title?: string;
}

const StepTitle: FC<StepCardTitleProps> = ({ title, stepId }) => {
  return (
    <Text color="neutral.primary" id={`${stepId}__title`} variant="body">
      {title}
    </Text>
  );
};

export default StepTitle;
