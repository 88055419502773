import { FC } from 'react';

import {
  AngleDown,
  EquipUITheme,
  Menu,
  MenuOption,
  MenuSelect,
  MenuSelectOption,
} from '@equip.health/ui';

import { ProfileFieldActionType } from './ProfileFieldReducer';

const TOP_LEVEL_ID = 'profile-edit-mode';

const { mono, teal } = EquipUITheme.colors;

const menuRightIcon = <AngleDown fill={teal[100]} />;

export const DropdownGridItem: FC<{
  id?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isLoading?: boolean;
  isRequired?: boolean;
  label?: string;
  options: MenuOption[];
  placeholder: string;
  updateActionTag: ProfileFieldActionType;
  updateContactField: (ContactFieldAction) => void;
  value: string;
  withPlaceholderItem?: boolean;
}> = ({
  id,
  isDisabled = false,
  isInvalid,
  isLoading,
  isRequired,
  label,
  options,
  placeholder,
  updateActionTag,
  updateContactField,
  withPlaceholderItem,
  value,
}) => (
  <Menu
    backgroundColor="white"
    border="1px solid"
    borderColor={`${mono[10]}`}
    buttonContent={value}
    buttonHeight="48px"
    buttonVariant="ghost"
    formLabelMarginLeft="0"
    icon={menuRightIcon}
    id={`${TOP_LEVEL_ID}__dropdown__${id}`}
    isDisabled={isDisabled}
    isFullWidth
    isInvalid={isInvalid}
    isLoading={isLoading}
    isRequired={isRequired}
    label={label}
    listMaxHeight="220px"
    onSelect={(value) => updateContactField({ type: updateActionTag, value })}
    options={options}
    overflow="scroll"
    placeholder={placeholder}
    textAlign="left"
    withPlaceholderItem={withPlaceholderItem}
  />
);

type MultiSelectGridItemProps = {
  id: string;
  isDisabled: boolean;
  isLoading: boolean;
  label?: string;
  options: MenuSelectOption[];
  placeholder: string;
  updateActionTag: ProfileFieldActionType;
  updateContactField: (ContactFieldAction) => void;
  values: MenuSelectOption[];
};

export const MultiSelectGridItem: FC<MultiSelectGridItemProps> = ({
  id,
  isDisabled,
  isLoading,
  label,
  options,
  placeholder,
  updateActionTag,
  updateContactField,
  values,
}) => (
  <MenuSelect
    backgroundColor="white"
    border="1px solid"
    borderColor={`${mono[10]}`}
    formLabel={label}
    formLabelMarginLeft="0"
    id={`${TOP_LEVEL_ID}__multi-select__${id}`}
    isDisabled={isDisabled}
    isLoading={isLoading}
    listMaxHeight="220px"
    multiple
    onSelect={(selectedValues: MenuSelectOption[]) =>
      updateContactField({
        type: updateActionTag,
        value: selectedValues.map((value) => value.node),
      })
    }
    options={options}
    placeholder={placeholder}
    selectedValues={values ?? []}
  />
);
