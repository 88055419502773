import { useState } from 'react';

import { useApi } from '.';
import { ApiCommand } from '../Api';
import urlConstants from '../constants/url.constants';

export type TimezoneState = {
  timezoneData: GoogleTimezoneDetails;
  getTimezoneFromLatLong: (latitude: number, longitude: number) => void;
};

const useTimezone = (): TimezoneState => {
  const { sendRequest } = useApi<GoogleTimezoneDetails>();
  const [timezoneData, setTimezoneData] = useState<GoogleTimezoneDetails>(null);

  const getTimezoneFromLatLong = (latitude: number, longitude: number) => {
    sendRequest({
      callback: (data, statusCode) => {
        if (statusCode === 200) {
          setTimezoneData(data);
        }
      },
      command: ApiCommand.GET,
      options: { latitude, longitude },
      url: urlConstants.users.getTimezoneFromLatAndLong,
    });
  };

  return { getTimezoneFromLatLong, timezoneData };
};

export default useTimezone;
