import { ChangeEvent, FC, useRef } from 'react';

import {
  ChakraFlex,
  EquipUIFireflyV2Theme,
  FAIconName,
  Icon,
} from '@equip.health/ui';

import { ImageFileAttachmentTile } from './Common';
import FileAttachmentTile from './FileAttachmentTile';
import { getFileExtension, isAllowedFileType } from '~/lib/util/file.util';
import {
  CHAT_ACCEPTED_IMAGE_EXTENSION,
  CHAT_INPUT_ACCEPT,
} from '~/lib/util/patientDocuments.util';

const { colors } = EquipUIFireflyV2Theme;

const TOP_LEVEL_ID = 'chat-attachments__button';

export const isChatAttachmentsEnabled =
  import.meta.env.VITE_CHAT_ATTACHMENTS_ENABLED === 'true';

export interface AttachmentsButtonProps {
  isDisabled: boolean;
  onInputChangeEvent: (e: Event | ChangeEvent) => void;
}

export const AttachmentsButton: FC<AttachmentsButtonProps> = ({
  isDisabled,
  onInputChangeEvent,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (!isDisabled) {
      inputRef?.current?.click();
    }
  };

  const iconColor = isDisabled
    ? colors.neutral.tertiary
    : colors.neutral.secondary;

  return (
    <ChakraFlex
      alignItems="center"
      as={isDisabled ? 'div' : 'button'}
      height="40px"
      id={TOP_LEVEL_ID}
      isDisabled={isDisabled}
      justifyContent="center"
      onClick={handleClick}
      pointer={isDisabled ? 'default' : 'pointer'}
      width="40px"
    >
      <Icon color={iconColor} name={FAIconName.paperclipRegular} size="lg" />
      <input
        accept={CHAT_INPUT_ACCEPT}
        id={`${TOP_LEVEL_ID}__input`}
        onChange={(e) => {
          onInputChangeEvent(e);
          inputRef.current.value = null;
        }}
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
      />
    </ChakraFlex>
  );
};

export interface AttachmentsPreviewProps {
  files: File[];
  isUploading: boolean;
  onRemoveFile: (index: number) => void;
}

export const AttachmentsPreview: FC<AttachmentsPreviewProps> = ({
  files,
  isUploading,
  onRemoveFile,
}) => {
  return (
    <ChakraFlex
      flexWrap="wrap"
      gridGap="24px"
      marginBottom="12px"
      marginTop="12px"
      paddingTop="10px"
      width="100%"
    >
      {files.map((file, index) => {
        const handleRemoveFile = () => onRemoveFile(index);
        return isAllowedFileType(
          getFileExtension(file?.name),
          CHAT_ACCEPTED_IMAGE_EXTENSION,
        ) ? (
          <ImageFileAttachmentTile
            file={file}
            handleRemoveFile={handleRemoveFile}
            isUploading={isUploading}
            key={`image_attachment_${file.name}`}
            topLevelId="chat_image_attachment"
          />
        ) : (
          <FileAttachmentTile
            fileName={file?.name}
            handleRemoveFile={handleRemoveFile}
            isEditable
            isUploading={isUploading}
            key={`file_attachment_${file.name}`}
            topLevelId="chat_file_attachment"
          />
        );
      })}
    </ChakraFlex>
  );
};
