import StringUtil from './string.util';

export const getAssigneesName = (
  users: FamilyAssignee[],
  currentUserId: string,
): string => {
  const nameList = [];
  if ((users ?? []).length > 1) {
    const otherUserAssignee = users.filter(
      (user) => user.externalId !== currentUserId,
    );
    if (otherUserAssignee.length !== users.length) nameList.push('You');
    otherUserAssignee.forEach((user) =>
      nameList.push(user.chosenName || user.firstName),
    );
  }
  return StringUtil.joinStringList(nameList, '&') ?? '';
};
