import * as linkify from 'linkifyjs';

const removeUrlsFromText = (text: string, replaceWith = ''): string => {
  const linksFound = linkify.find(text, 'url');
  let parsed = text;
  for (const link of linksFound ?? []) {
    parsed = parsed.replace(link.value, replaceWith);
  }
  return parsed;
};

// eslint-disable-next-line import/prefer-default-export
export { removeUrlsFromText };
