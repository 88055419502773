import { FC } from 'react';

import { ChakraFlex, FAIconName, Icon, Text } from '@equip.health/ui';
import isNil from 'lodash/isNil';

import VitalsMiniCard from './VitalsMinicard';
import { PatientObservationData } from '~/lib/types/vitals';
import {
  getPatientTrendValue,
  getWeightTrendDescriptionLabel,
  getWeightTrendValueString,
} from '~/lib/util/vitals.util';

const TOP_LEVEL_ID = 'vitals__weight-trend';

interface WeightTrendMiniCardProps {
  lastObservation?: PatientObservationData;
  lastObservationInWeek?: PatientObservationData;
  nextObservation?: PatientObservationData;
}

const WeightTrendMiniCard: FC<WeightTrendMiniCardProps> = ({
  lastObservation,
  lastObservationInWeek,
  nextObservation,
}) => {
  const referenceObservation = lastObservationInWeek || nextObservation;

  const weightTrend = getPatientTrendValue(
    lastObservation,
    referenceObservation,
  );

  if (isNil(weightTrend) || weightTrend === 0) {
    return (
      <ChakraFlex gridGap="8px" id={TOP_LEVEL_ID}>
        <Icon name={FAIconName.weightRegular} />
        <Text
          color="neutral.secondary"
          id={`${TOP_LEVEL_ID}__label`}
          variant="caption"
        >
          {getWeightTrendValueString(weightTrend)}
        </Text>
      </ChakraFlex>
    );
  }

  return (
    <VitalsMiniCard
      description={getWeightTrendDescriptionLabel(
        lastObservationInWeek,
        nextObservation,
      )}
      id={TOP_LEVEL_ID}
      unit={lastObservation?.observation.unit}
      value={getWeightTrendValueString(weightTrend)}
    />
  );
};

export default WeightTrendMiniCard;
