import { FC } from 'react';

import {
  ChakraBox,
  ChakraCircle,
  ChakraFlex,
  ChakraLink,
  ChakraText,
  Check,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

const {
  bodySemibold,
  caption: captionStyle,
  captionSemibold,
} = EquipUIFireflyV1Theme.typography;

const { primary } = EquipUIFireflyV1Theme.colors;

/** helper function to avoid nested ternary expression in-line */
const getCircleBackground = (
  canEdit: boolean,
  isStepActiveStatus: boolean,
): string => {
  if (canEdit) return primary[10];
  return isStepActiveStatus ? primary[110] : 'white';
};

type StepsComponentProps = {
  onStepChangeMount: (currentStep: number) => void;
  selectedStep: number;
  steps: Step[];
};

const StepsComponent: FC<StepsComponentProps> = ({
  onStepChangeMount,
  selectedStep,
  steps,
}: StepsComponentProps) => {
  const goToStep = (s: number) => {
    onStepChangeMount(s);
  };
  return (
    <ChakraBox id="steps-section">
      {steps
        .slice(0, steps.length - 1)
        .map(({ canEdit, isStepActiveStatus, id, name, caption }: Step) => (
          <ChakraFlex
            id={`step-${name}`}
            justifyContent="space-between"
            key={id}
            paddingTop="16px"
            width="50%"
          >
            <ChakraFlex alignItems="center">
              <ChakraCircle
                {...captionSemibold}
                background={getCircleBackground(canEdit, isStepActiveStatus)}
                border={!canEdit && !isStepActiveStatus ? '2px' : 'none'}
                borderColor={
                  !canEdit && !isStepActiveStatus ? 'navy.25' : 'none'
                }
                color={isStepActiveStatus ? 'white' : 'navy.25'}
                size="40px"
              >
                {canEdit ? <Check fill="navy.100" width="16px" /> : id}
              </ChakraCircle>

              <ChakraBox paddingLeft="20px">
                <ChakraText {...bodySemibold} color="mono.70">
                  {name}
                </ChakraText>
                {caption && (
                  <ChakraText {...captionStyle} color="mono.50">
                    {caption}
                  </ChakraText>
                )}
              </ChakraBox>
            </ChakraFlex>
            {canEdit && id !== selectedStep && (
              <ChakraFlex alignItems="center" paddingRight="20px">
                <ChakraLink
                  {...captionSemibold}
                  color="teal.100"
                  id="edit-button"
                  lineHeight="140%"
                  onClick={() => goToStep(id)}
                >
                  Edit
                </ChakraLink>
              </ChakraFlex>
            )}
          </ChakraFlex>
        ))}
    </ChakraBox>
  );
};

export default StepsComponent;
