import { FC, ReactElement, createContext, useContext } from 'react';

import useSendBird from '~/lib/hooks/chat/useSendBird';
import { SendBirdProps } from '~/lib/types/chat';

const ChatContext = createContext<SendBirdProps>(null);

interface ChatContextProviderProps {
  children: ReactElement | ReactElement[];
}

const ChatContextProvider: FC<ChatContextProviderProps> = ({
  children,
}: ChatContextProviderProps) => {
  const value = useSendBird();

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

const useChatContext = (): SendBirdProps => {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChatContext must be used within a ChatContextProvider');
  }

  return context;
};

export { ChatContextProvider, useChatContext };
