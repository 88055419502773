import { FC } from 'react';

import { ChakraFlex, FireflyButton, Text } from '@equip.health/ui';

const ErrorViewCard: FC<{
  description?: string;
  onTryAgain?: () => void | null;
  title?: string;
}> = ({ description, onTryAgain, title }) => (
  <ChakraFlex
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    paddingTop="200px"
  >
    <Text
      color="neutral.90"
      marginBottom="18px"
      textAlign="center"
      variant="h3"
    >
      {title || 'Hmm... something went wrong'}
    </Text>
    {description && (
      <Text color="neutral.90" marginBottom="24px" textAlign="center">
        {description}
      </Text>
    )}
    {onTryAgain && (
      <FireflyButton
        id="zoom-error-modal__try-again"
        onClick={onTryAgain}
        size="normal"
      >
        Try Again
      </FireflyButton>
    )}
  </ChakraFlex>
);

export default ErrorViewCard;
