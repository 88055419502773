import { FC } from 'react';

import { ChakraFlex, useBreakpointValue } from '@equip.health/ui';

import Loading from '~/components/common/Loading';
import { AUTHENTICATED_NAV_LAYOUT } from '~/lib/constants';

const { mobileHeight: mobileNavHeight, desktopHeight: desktopNavHeight } =
  AUTHENTICATED_NAV_LAYOUT;
const SafetyPlanLoader: FC = () => {
  const navBarHeight = useBreakpointValue({
    base: mobileNavHeight,
    md: desktopNavHeight,
  });
  return (
    <ChakraFlex
      alignItems="center"
      height={`calc(100vh - ${navBarHeight})`}
      justifyContent="center"
    >
      <Loading />
    </ChakraFlex>
  );
};

export default SafetyPlanLoader;
