export const PAGE_NAME = {
  landing: 'Landing',
  login: 'Login',
  home: 'Home',
  inquiry: 'Inquiry',
  schedule: 'Schedule',
  messages: 'Messages',
  profile: 'Profile',
  referral: 'Referral',
  survey: 'Survey',
  thankyou: 'Thankyou',
  weight: 'Weight',
  getSurveyPageName: (surveyName: string) =>
    `${surveyName.charAt(0).toUpperCase() + surveyName.slice(1)}`,
};

export enum EVENT {
  MessagesPatientSelectorClick = 'Messages Patient Selector Clicked',
  SchedulePatientSelectorClick = 'Schedule Patient Selector Clicked',
  ProfilePatientSelectorClick = 'Profile Patient Selector Clicked',
  HomeWeightPatientSelectorClick = 'Home-Weight Patient Selector Clicked',
  HomeTodoListPatientSelectorClick = 'Home-TodoList Patient Selector Clicked',
  HomeGroupClassListPatientSelectorClick = 'Home-GroupClasses Patient Selector Clicked',
  HomeProviderListPatientSelectorClick = 'Home-ProvidersList Patient Selector Clicked',
  ProfilePageClick = 'Profile Page Clicked',
  NavbarScheduleClick = 'Navbar Schedule Clicked',
  NavbarMessagesClick = 'Navbar Messages Clicked',
  NavbarHomeClick = 'Navbar Home Clicked',
  SafetyPlanDownLoadClick = 'Safety plan download clicked',
  SafetyPlanClick = 'Safety Plan Clicked',
}
