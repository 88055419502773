import { getDocumentExternalIdFromFileName } from '../util/chat/chat.utils';

const urlConstants = {
  assessments: {
    familyTaskAssesments: (surveyExternalId: string): string =>
      `/assessments/family/${surveyExternalId}`,
    get: (responseExternalId: string): string =>
      `/assessments/${responseExternalId}`,
    update: (responseExternalId: string): string =>
      `/assessments/${responseExternalId}`,
    verify: (responseExternalId: string): string =>
      `/assessments/${responseExternalId}/verify`,
  },
  calendar: {
    getAppointmentTypes: '/schedule/appointment-types',
    getElementToken: '/schedule/element-token',
    getUserInfo: '/composition/schedule/calendar-infos',
    saveAppointment: '/schedule/external/appointment',
  },
  chat: {
    getChatToken: `/chat/family/token/v1`,
    inviteUserToChannel: '/chat/channel/invite',
    getAttachmentUploadUrl: '/clinical-data/family/patient-document',
    getAttachmentDownloadUrl: (
      fileName: string,
      patientExternalId: string,
    ): string =>
      `/clinical-data/family/patient-document/${patientExternalId}/download-url/${encodeURIComponent(
        fileName, // Ensure that the file name is encoded to avoid invalid URL errors.
      )}?documentExternalId=${getDocumentExternalIdFromFileName(fileName)}`,
  },
  clinicalData: {
    listPayors: `/clinical-data/payors`,
    getDocuments: (patientExternalId: string, channelId: string): string =>
      `/clinical-data/family/patient-document/${patientExternalId}/channel/${channelId}/files/v1`,
    saveVitals: (patientExternalId: string): string =>
      `/clinical-data/family/patients/${patientExternalId}/clinical-profile/observations`,
    getPatientVitals: (patientExternalId: string): string =>
      `/clinical-data/family/patients/${patientExternalId}/clinical-profile/observations`,
  },
  inquiry_referral: {
    addInquiry: '/inquiry-referral/external/add-inquiry',
    addInquiryV2: '/inquiry/external/inquiries',
    addReferral: '/inquiry-referral/external/add-referral',
    getRelationsToPatient: '/inquiry-referral/relation-to-patient',
    getStates: '/clinical-data/us-states',
    listEquipSources: '/inquiry/equip-sources',
    listOfSources: '/inquiry-referral/equip-source',
  },
  safetyPlan: {
    getSurvey: (patientExternalId: string): string =>
      `/surveys/family/safety-plan/${patientExternalId}`,
    isExist: `/surveys/family/safety-plan/exists`,
  },
  schedule: {
    declineGroupClassEvent: (eventId: string): string =>
      `/schedule/family/group-class-events/${eventId}/decline`,
    deleteAppointment: '/schedule/family/appointment',
    getAppointmentAttendees: (
      patientExternalId: string,
      typeGroup: string,
    ): string =>
      `/schedule/family/attendees/${patientExternalId}?type-group=${typeGroup}`,
    getAvailability: '/schedule/family/availability',
    getGroupClassById: (groupClassExternalId: string): string =>
      `/schedule/family/group-classes/${groupClassExternalId}`,
    getGroupClasses: '/schedule/family/group-classes',
    getGroupClassEvents: '/schedule/family/group-class-events',
    getPatientAppointmentTypes: '/schedule/family/appointment-types',
    getSchedule: '/schedule/family/appointment-details',
    getSchedulePermissions: '/schedule/family/appointments/permissions',
    joinGroupClassZoomMeeting: (groupClassEventExternalId: string): string =>
      `/schedule/family/group-class-events/${groupClassEventExternalId}/start`,
    joinZoomMeeting: '/schedule/family/meeting',
    registerForGroupClass: (groupClassExternalId: string): string =>
      `/schedule/family/group-classes/${groupClassExternalId}/register`,
    saveAppointment: '/schedule/family/appointment',
    unregisterForGroupClass: (groupClassExternalId: string): string =>
      `/schedule/family/group-classes/${groupClassExternalId}/unregister`,
  },
  tasks: {
    deleteAttachment: (noteId: string, attachmentId: string): string =>
      `/clinical-data/family/notes/${noteId}/attachments/${attachmentId}`,
    getAttachmentUpload: (noteId: string, fileName: string): string =>
      `/clinical-data/family/notes/${noteId}/attachments/${fileName}/upload-url`,
    getToDoList: '/tasks/family/todo-list?show-completed=true',
    getToDoListForPatient: (patientId: string) =>
      `/tasks/family/patient/${patientId}/todo-list?show-completed=true`,
    updateTask: (taskExternalId: string) => `/tasks/family/${taskExternalId}`,
  },
  users: {
    basicInfo: '/users/family/self/v1',
    consents: '/users/family/consent/v1',
    getContactPreferences: '/users/enums/contact-preferences',
    getGenders: '/users/enums/genders',
    getLanguages: '/users/enums/languages',
    getPronouns: '/users/enums/pronouns',
    getRelationsToPatient: '/users/enums/relations-to-patient',
    getProviders: (patientExternalId: string): string =>
      `/users/family/care-team/${patientExternalId}/v1`,
    getTimezoneFromLatAndLong: `/users/family/timezone-details`,
    getTimezones: '/users/enums/timezones',
    logout: '/users/logout',
    profile: '/users/family/profile/v1',
    updateSupportProfile: (patientExternalId: string): string =>
      `/users/family/patient/${patientExternalId}/v1`,
    updatePatientNotificationPreference: (patientExternalId: string): string =>
      `/users/family/patients/${patientExternalId}/preferences`,
    updateSupportNotificationPreference: (supportExternalId: string): string =>
      `/users/family/supports/${supportExternalId}/preferences`,
    updateUserProfile: '/users/family/support/v1',
    getTags: (patientExternalId: string): string =>
      `/users/family/${patientExternalId}/tags/v1`,
    lastUpdatedUsers: (responseIds: string[]): string =>
      `/assessments/family/last-updated-users?${responseIds
        .map((responseId) => `response-id=${responseId}`)
        .join('&')}`,
  },
};

export default urlConstants;
