import { useEffect, useMemo } from 'react';

import { MenuOption, MenuSelectOption } from '@equip.health/ui';

import {
  Enumerations,
  useEnumerationContext,
} from '~/lib/context/EnumerationContext';

const toMenuOption = (value: string): MenuOption => ({
  node: value,
  value,
});

const useEnumsForProfilePage = (): {
  contactPreferencesOptions: MenuOption[];
  gendersMenuOptions: MenuOption[];
  isContactPreferencesLoading: boolean;
  isGendersLoading: boolean;
  isLanguagesLoading: boolean;
  isPronounsLoading: boolean;
  isStatesLoading: boolean;
  isTimezonesLoading: boolean;
  languagesMenuOption: MenuSelectOption[];
  pronounsMenuOptions: MenuOption[];
  statesMenuOptions: MenuOption[];
  timezonesMenuOptions: MenuOption[];
} => {
  const {
    contactPreferences: contactPreferencesEnum,
    genders: gendersEnum,
    fetchEnumerations,
    isContactPreferencesLoading,
    isGendersLoading,
    isLanguagesLoading,
    isPronounsLoading,
    isStatesLoading,
    isTimezonesLoading,
    languages: languagesEnum,
    pronouns: pronounsEnum,
    states: statesEnum,
    timezones: timezonesEnum,
  } = useEnumerationContext();

  useEffect(() => {
    fetchEnumerations([
      Enumerations.CONTACT_PREFERENCES,
      Enumerations.GENDERS,
      Enumerations.LANGUAGES,
      Enumerations.PRONOUNS,
      Enumerations.STATES,
      Enumerations.TIMEZONES,
    ]);
  }, []);

  const contactPreferencesOptions = useMemo<MenuOption[]>(
    () => (contactPreferencesEnum ?? []).map(toMenuOption) ?? [],
    [contactPreferencesEnum],
  );

  const gendersMenuOptions = useMemo<MenuOption[]>(
    () => (gendersEnum ?? []).map(toMenuOption) ?? [],
    [gendersEnum],
  );

  const languagesMenuOption = useMemo<MenuSelectOption[]>(
    () => (languagesEnum ?? []).map(toMenuOption) ?? [],
    [languagesEnum],
  );

  const pronounsMenuOptions = useMemo<MenuOption[]>(
    () => (pronounsEnum ?? [])?.map(toMenuOption) ?? [],
    [pronounsEnum],
  );

  const statesMenuOptions = useMemo<MenuOption[]>(
    () =>
      (statesEnum ?? []).map(
        (value: { label: string; externalId: string }): MenuOption => ({
          node: value.label,
          value: value.externalId,
        }),
      ),
    [statesEnum],
  );

  const timezonesMenuOptions = useMemo<MenuOption[]>(
    () => (timezonesEnum ?? []).map(toMenuOption) ?? [],
    [timezonesEnum],
  );

  return {
    contactPreferencesOptions,
    gendersMenuOptions,
    isContactPreferencesLoading,
    isGendersLoading,
    isLanguagesLoading,
    isPronounsLoading,
    isStatesLoading,
    isTimezonesLoading,
    languagesMenuOption,
    pronounsMenuOptions,
    statesMenuOptions,
    timezonesMenuOptions,
  };
};

export default useEnumsForProfilePage;
