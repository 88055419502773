import { FC } from 'react';

import { ChakraBox, ChakraFlex, EquipUIFireflyV2Theme } from '@equip.health/ui';

import { useSchedulePermissionsContext } from '~/lib/context/SchedulePermissionsContext';

import {
  AppointmentViewItem,
  DateLabel,
  GroupClassEventViewItem,
} from './ScheduleViewItem';

const { neutral } = EquipUIFireflyV2Theme.colors;

interface UpcomingScheduleViewProps {
  patientExternalId?: string;
  resetAndLoadListData: () => void;
  upcomingScheduleDataList: GroupedCombinedScheduleDataListType[];
}
const UpcomingScheduleView: FC<UpcomingScheduleViewProps> = ({
  patientExternalId,
  resetAndLoadListData,
  upcomingScheduleDataList,
}) => {
  return (
    <>
      {upcomingScheduleDataList?.map((event) => (
        <ScheduleViewContainer
          data={event}
          key={`${event.date}_schedule`}
          patientExternalId={patientExternalId}
          refreshData={resetAndLoadListData}
        />
      ))}
    </>
  );
};

const ScheduleViewContainer: FC<{
  data: GroupedCombinedScheduleDataListType;
  patientExternalId?: string;
  refreshData: () => void;
}> = ({ data, patientExternalId, refreshData }) => {
  const { schedulePermissionsByPatientMapper } =
    useSchedulePermissionsContext();

  return (
    <ChakraFlex
      borderBottom={`solid 1px ${neutral.line}`}
      flexDirection={{ base: 'column', md: 'row' }}
      gridGap={{ base: '12px', md: '120px' }}
      marginBottom="26px"
    >
      <ChakraBox flexShrink={0} width={{ base: '100%', md: '140px' }}>
        <DateLabel appointmentStartDateTime={data.date} isSummary={false} />
      </ChakraBox>
      <ChakraBox flexGrow={1}>
        {(data.schedules || []).map((schedule, index) => {
          if (schedule.isGroupClassEvent) {
            const eventInfo = schedule.eventInfo as GroupClassEventDetails;
            return (
              <GroupClassEventViewItem
                data={eventInfo}
                index={index}
                isLast={data?.schedules?.length === index + 1}
                key={eventInfo.groupClassEventExternalId}
                patientExternalId={patientExternalId}
                refreshData={refreshData}
              />
            );
          }
          const eventInfo = schedule.eventInfo as AppointmentDetailResponse;
          const externalId = eventInfo?.patient?.externalId;
          return (
            <AppointmentViewItem
              data={eventInfo}
              index={index}
              isCancellable={
                schedulePermissionsByPatientMapper[externalId]?.[
                  eventInfo.typeGroup
                ]?.canCancel
              }
              isLast={data?.schedules?.length === index + 1}
              key={eventInfo.appointmentExternalId}
              refreshData={refreshData}
            />
          );
        })}
      </ChakraBox>
    </ChakraFlex>
  );
};

export default UpcomingScheduleView;
