import { FC } from 'react';

import { ChakraBox } from '@equip.health/ui';

const PrivacyPolicy: FC = () => (
  <ChakraBox overflowY="scroll">
    <h2>Privacy Policy</h2>
    <br />
    <p>
      Equip Health&rsquo;s mission is for everyone with an eating disorder to
      have access to affordable, quality treatment, regardless of geography.
      Developed in partnership with leading researchers, Equip Health&rsquo;s
      evidence-based program guides a care team of healthcare professionals and
      support personnel as they deliver coordinated medical and behavioral
      treatment to patients.
    </p>
    <p>
      <br />
    </p>
    <p>
      This Privacy Policy describes how we collect, use, disclose and otherwise
      process personal information we obtain in connection with the services we
      provide, including through our website and mobile applications
      (collectively &ldquo;Site&rdquo;) that link to this Privacy Policy.
    </p>
    <p>
      <br />
    </p>
    <p>
      The Privacy Policy is part of our Terms of Use. When you use our services
      and this Site you accept and agree to both the Terms of Use and to the
      Privacy Policy. If you do not agree to be bound to the Privacy Policy you
      should stop using the Services and this Site immediately or cease to
      provide the Services, as applicable. By accessing and using our platform
      or providing the Services, as applicable, you affirm that you have read
      the Terms of Use and the Privacy Policy and that you understand, agree to
      and acknowledge all the terms contained in both of them.
    </p>
    <p>
      <br />
    </p>
    <p>
      Please note that some of the services we provide are governed by the
      Health Insurance Portability and Accountability Act (&ldquo;HIPAA&rdquo;).
      When we provide services covered by HIPAA, you will receive a separate
      HIPAA privacy notice (&ldquo;Notice of Privacy Practices&rdquo;), which
      will govern the collection, use and disclosure of your protected health
      information. To the extent there are inconsistencies between the Notice of
      Privacy Practices you receive and this Privacy Policy, the Notice of
      Privacy Practices will govern.
    </p>
    <p>
      <br />
    </p>
    <p>Personal Information We Collect</p>
    <p>
      We collect personal information from our program participants, family
      members, and caregivers, as well as the participants&rsquo; care teams,
      which may consist of physicians, other health care professionals, and
      support personnel. These individuals may provide information about
      themselves (e.g., patients who sign up to participate) as well as about
      each other (e.g., physicians who provide information about program
      participants).
    </p>
    <p>
      <br />
    </p>
    <p>This information may include:</p>
    <p>
      <br />
    </p>
    <ul>
      <li>
        <p>
          Health and medical information, such as medical insurance details,
          information about physical and mental health conditions and diagnoses,
          treatments for medical conditions, genetic information, family medical
          history, and medications an individual may take, including the dosage,
          timing, and frequency
        </p>
      </li>
      <li>
        <p>
          Personal and business contact information and preferences, such as
          name, email address, mailing address, phone number, and emergency
          contact information
        </p>
      </li>
      <li>
        <p>
          Biographical and demographic information, such as date of birth, age,
          gender, marital status, and information regarding any parents or legal
          guardians
        </p>
      </li>
      <li>
        <p>
          Audio and video feed, when you engage in Equip Health&rsquo;s
          telemedicine services. We may record your audio and video
          conversations for quality and training purposes, and to advance and
          optimize the telemedicine program
        </p>
      </li>
      <li>
        <p>
          Transaction information, such as information about payments to and
          from you and other details of products or services you have purchased
          from us
        </p>
      </li>
      <li>
        <p>
          Feedback or correspondence, such as information you provide when you
          contact us with questions, feedback, or otherwise correspond with us
          online
        </p>
      </li>
      <li>
        <p>
          Other information you provide to us, such as in emails, on phone
          calls, or in other correspondence with Equip Health or its service
          providers.
        </p>
      </li>
    </ul>
    <p>
      <br />
    </p>
    <p>
      We and our service providers may use cookies, browser web storage (also
      known as locally stored objects, or &quot;LSOs&quot;), web beacons, and
      similar technologies to automatically collect information about your
      interaction with our services through your computer or mobile device,
      including:
    </p>
    <p>
      <br />
    </p>
    <p>
      Device data, such as your computer or mobile device operating system type
      and version number, manufacturer and model, device identifier, browser
      type, screen resolution, IP address, and general location information such
      as city, state or geographic area; and
    </p>
    <p>
      Online activity data, such as the website you visited before browsing to
      our website, and information about your use of and actions on our websites
      and mobile apps, including pages or screens you viewed, how long you spent
      on a page or screen, navigation paths between pages or screens,
      information about your activity on a page or screen, access times, and
      length of access, and how you respond to emails we send you. We may track
      certain actions you take on the Site such as the content of searches
      performed on the Site and information that you enter into the Site (e.g.
      your name and email address when signing up for an email newsletter,
      requesting more information about us and our Services, etc.).
    </p>
    <p>
      <br />
    </p>
    <p>
      Analytics. We may use tools such as Google Analytics, AppsFlyer and
      Mixpanel to help analyze how individuals use the Site. Such third parties
      may use Cookies, APIs, and SDKs in our services to enable them to collect
      and analyze user and device related data and information on our behalf.
      Google Analytics, AppsFlyer, and Mixpanel use Cookies to collect
      information such as how often users visit the Site, what pages they visit,
      and what other sites they used prior to coming to the Site. We use the
      information we get to improve our Site and Services. Although Google
      Analytics plants a persistent Cookie on your web browser to identify you
      as a unique user the next time you visit the Site, the Cookie cannot be
      used by anyone but Google. Google&rsquo;s ability to use and share
      information collected by Google Analytics about your visits to the Site is
      restricted by the Google Analytics Terms of Use and the Google Privacy
      Policy. You may prevent your data from being used by Google Analytics by
      downloading and installing the Google Analytics Opt-out Browser Add-on,
      available at https://tools.google.com/dlpage/gaoptout/. Mixpanel&rsquo;s
      ability to use and share information it collects about your visits to the
      Site is restricted by the Mixpanel Terms of Use located at
      https://mixpanel.com/terms/ and the Mixpanel Privacy Policy located at
      https://mixpanel.com/privacy/. You can opt-out of Mixpanel&rsquo;s
      automatic retention of data collected through your browsers while on our
      Site by clicking on this link https://mixpanel.com/optout/. To track
      opt-outs, Mixpanel uses a persistent opt-out Cookie placed on your
      devices. You can find out the types of information AppsFlyer collects and
      how you can opt-out of AppsFlyer by visiting its website:
      https://www.appsflyer.com/optout or by emailing privacy@appsflyer.com.
    </p>
    <p>How We Use Your Personal Information</p>
    <p>We use your personal information for the following purposes:</p>
    <p>
      <br />
    </p>
    <p>To provide you the services described in Terms of Use, including to:</p>
    <p>
      <br />
    </p>
    <ul>
      <li>
        <p>Establish and maintain your account</p>
      </li>
      <li>
        <p>
          Facilitate your interaction with peers, coaches, health care
          professionals and others, at your request
        </p>
      </li>
      <li>
        <p>
          Provide information about our services through announcements, updates,
          security alerts, and support and administrative messages
        </p>
      </li>
      <li>
        <p>
          Enable the services&rsquo; security features, such as two-factor
          authentication
        </p>
      </li>
      <li>
        <p>
          Understand your needs and interests, and personalize your experience
          with the services and our communications
        </p>
      </li>
      <li>
        <p>
          Provide support and maintenance for the services, analyze and improve
          our services and to develop new products and services
        </p>
      </li>
      <li>
        <p>To respond to your requests, questions and feedback</p>
      </li>
      <li>
        <p>
          For research and public health purposes. We may use your personal
          information for research and public health initiatives, including to
          prepare for symposia, research publications, conferences, and
          scientific and educational events. In connection with this research,
          we will not disclose your name or contact details.
        </p>
      </li>
    </ul>
    <p>
      <br />
    </p>
    <p>
      To comply with law. We use your personal information as we believe
      necessary or appropriate to comply with applicable laws, lawful requests,
      and legal process, such as to respond to subpoenas or requests from
      government authorities.
    </p>
    <p>
      For compliance, fraud prevention, and safety. We may use your personal
      information and disclose it to law enforcement, government authorities,
      and private parties as we believe necessary or appropriate to: (a) protect
      our, your or others&rsquo; rights, privacy, safety or property (including
      by making and defending legal claims); (b) enforce the terms and
      conditions that govern the services; and (c) protect, investigate and
      deter against fraudulent, harmful, unauthorized, unethical or illegal
      activity.
    </p>
    <p>
      With your consent. We may offer you opportunities to opt in to allow us to
      collect, use and share your information for other purposes, and we will do
      so only if you opt in.
    </p>
    <p>
      <br />
    </p>
    <p>How We Share Your Personal Information</p>
    <p>
      Affiliates. We may share your personal information with our corporate
      parent, subsidiaries, and affiliates that are involved in providing the
      services to you, such as EQUIP Health Medical PC.
    </p>
    <p>
      <br />
    </p>
    <p>
      Service providers. We may share your personal information with third party
      companies and individuals that provide services on our behalf or help us
      operate the services (such as customer support, hosting, analytics, email
      delivery, and database management services). These third parties may use
      your personal information only as directed or authorized by us and in a
      manner consistent with this Privacy Policy, and are prohibited from using
      or disclosing your information for any other purpose.Clinical research
      organizations, healthcare organizations or healthcare providers,
      researchers, institutions, and publishers for the research and public
      health purposes described above. We will not disclose your name or contact
      details to these organizations.
    </p>
    <p>
      <br />
    </p>
    <p>
      Your family members, caregivers, and care team. By participating in our
      programs, you direct us to share some of your information with your family
      members, caregivers, and care team. For example, they may be able to view
      the contents of your monitoring history, treatment outcomes, and other
      information contained within the services. Your care team may obtain the
      information about you through the services and store it in their own
      systems. The services may permit your care team to see the names and other
      identifying information about your other health care providers and their
      organizations to facilitate collaborative care.
    </p>
    <p>
      <br />
    </p>
    <p>
      Your insurance provider. When you participate in programs sponsored by
      your insurance provider, you also direct us to provide certain information
      about your use of our services to the insurance provider, including
      engagement and outcome metrics. We may also provide some of your
      information to confirm your eligibility for our services.
    </p>
    <p>
      <br />
    </p>
    <p>
      Third-party platforms. By enabling features or functionality that connect
      your account to a third-party platform (such as by logging in to the
      website or mobile application using your account with the third-party,
      providing your API key or similar access token for the services to a
      third-party, or otherwise linking your account with the services to a
      third-party&rsquo;s services), you chose to disclose certain of your
      personal information with those platforms. We do not control the third
      party&rsquo;s use of your personal information.
    </p>
    <p>
      <br />
    </p>
    <p>
      Professional advisors. We may disclose your personal information to
      professional advisors, such as lawyers, bankers, auditors and insurers,
      where necessary in the course of the professional services that they
      render to us.
    </p>
    <p>
      <br />
    </p>
    <p>
      For compliance, fraud prevention and safety. We may share your personal
      information for the compliance, fraud prevention and safety purposes
      described above.
    </p>
    <p>
      <br />
    </p>
    <p>
      Business transfers. We may sell, transfer or otherwise share some or all
      of our business or assets, including your personal information, in
      connection with a business transaction (or potential business transaction)
      such as a corporate divestiture, merger, consolidation, acquisition,
      reorganization or sale of assets, or in the event of bankruptcy or
      dissolution. In such a case, we will make reasonable efforts to require
      the recipient to honor this Privacy Policy.
    </p>
    <p>
      <br />
    </p>
    <p>
      With your consent. We may offer you opportunities to opt in to allow us to
      collect, use and share your information for other purposes, and we will do
      so only if you opt in.
    </p>
    <p>
      <br />
    </p>
    <p>Your Choices</p>
    <p>
      Access or update your information. If you have registered to use our
      services, you may review and update certain personal information in your
      account profile by logging into the account or emailing us at
      tech@equipbehavioralhealth.com or support@equip.health.
    </p>
    <p>
      <br />
    </p>
    <p>
      Cookies and browser web storage. Most browsers let you remove or reject
      cookies. To do this, follow the instructions in your browser settings.
      Many browsers accept cookies by default until you change your settings.
      Please note that if you set your browser to disable cookies, the services
      may not work properly. Similarly, your browser settings may allow you to
      clear your browser web storage.
    </p>
    <p>
      <br />
    </p>
    <p>
      Do Not Track. Some Internet browsers may be configured to send &ldquo;Do
      Not Track&rdquo; signals to the online services that you visit. We
      currently do not respond to &ldquo;Do Not Track&rdquo; or similar signals.
      To find out more about &ldquo;Do Not Track,&rdquo; please visit
      http://www.allaboutdnt.com.
    </p>
    <p>
      <br />
    </p>
    <p>Security</p>
    <p>
      Equip Health maintains organizational, technical and administrative
      measures designed to protect against unauthorized access, misuse, loss,
      disclosure, alteration and destruction of personal information we
      maintain. Unfortunately, data transmission over the Internet cannot be
      guaranteed as completely secure. Therefore, while we strive to protect
      your personal information, we cannot guarantee the security of personal
      information. In the event that Equip Health is required to notify you
      about a situation involving your data, we may do so by email or telephone
      to the extent permitted by law.
    </p>
    <p>
      <br />
    </p>
    <p>Data Retention</p>
    <p>
      Equip Health retains personal information for as long as necessary to (a)
      provide our services; (b) comply with legal obligations; (c) resolve
      disputes; and (d) enforce the terms of customer agreements.
    </p>
    <p>
      <br />
    </p>
    <p>Data Deletion</p>
    <p>
      You may request deletion of your personal information by us, however, we
      may be required (by law or otherwise) to keep this information and not
      delete it (or to keep this information for a certain time, in which case
      we will comply with your deletion request only after we have fulfilled
      such requirements). When we delete personal information, it will be
      deleted from the active database, but may remain in our archives (subject
      to applicable law) and we may also retain anonymous information about your
      use of our services. Once we disclose some of your personal information to
      third parties, we may not be able to access that personal information any
      longer and cannot force the deletion or modification of any such
      information by the parties to whom we have made those disclosures. After
      we delete personal information, we may retain de-identified information
      and will continue to use de-identified information as permitted under this
      Privacy Policy.
    </p>
    <p>
      <br />
    </p>
    <p>Notice To California Residents</p>
    <p>
      <br />
    </p>
    <p>
      The California Consumer Privacy Act provides California consumers with the
      right to obtain from us information about personal information about you
      that we collect, use, and disclose. You can exercise your rights by
      sending an email to support@equip.health.
    </p>
    <p>
      <br />
    </p>
    <p>Creation of Anonymous Data for Analytics</p>
    <p>
      We may create anonymous data from personal information by excluding
      information that makes the data personally identifiable, and use that
      anonymous data for our lawful business purposes. We de-identify any
      patient data in accordance with applicable HIPAA requirements.
    </p>
    <p>
      <br />
    </p>
    <p>Children</p>
    <p>
      If we have obtained consent from a parent or legal guardian, Equip Health
      may collect, use, and disclose the personal information of a child under
      13 as described throughout this Privacy Policy and in compliance with
      applicable state and federal law. Parents or legal guardians may contact
      us to ask if we have collected their child&apos;s personal information, to
      review or correct that information, and to request that Equip Health stop
      collecting this information or have it deleted. Such requests are subject
      to Equip Health&rsquo;s verifying to our satisfaction that the requester
      is in fact the child&apos;s parent or legal guardian.
    </p>
    <p>
      <br />
    </p>
    <p>Job Applicants</p>
    <p>
      When you visit the &ldquo;Careers&rdquo; portion of our website, we
      collect the information that you provide to us in connection with your job
      application. This includes business and personal contact information,
      professional credentials and skills, educational and work history, and
      other information of the type that may be included in a resume. This may
      also include diversity information that you voluntarily provide. We use
      this information to facilitate our recruitment activities and process
      employment applications, such as by evaluating a job candidate for an
      employment activity, and monitoring recruitment statistics. We may also
      use this information to provide improved administration of the website,
      and as otherwise necessary (a) to comply with relevant laws or to respond
      to subpoenas or warrants served on Equip Health; (b) to protect and defend
      the rights or property of Equip Health or others; (c) in connection with a
      legal investigation; and/or (d) to investigate or assist in preventing any
      violation or potential violation of the law, this Privacy Policy, or Equip
      Health&rsquo;s Terms of Use.
    </p>
    <p>
      <br />
    </p>
    <p>International Data Transfers</p>
    <p>
      Equip Health is headquartered in the United States and has affiliates and
      service providers in other countries, and your personal information may be
      transferred to the United States or other locations outside of your state,
      province, country or other governmental jurisdiction where privacy laws
      may not be as protective as those in your jurisdiction.
    </p>
    <p>
      <br />
    </p>
    <p>Other Sites and Services</p>
    <p>
      For your convenience and information, we may provide links to websites and
      other third-party content that is not owned or operated by Equip Health.
      These links are not an endorsement, authorization or representation that
      we are affiliated with that third party. We do not exercise control over
      third party websites or services, and are not responsible for their
      actions. Other websites and services follow different rules regarding the
      use or disclosure of the personal information you submit to them. We
      encourage you to read the privacy policies of the other websites you visit
      and services you use.
    </p>
    <p>
      <br />
    </p>
    <p>Non-Discrimination</p>
    <p>
      Equip will not discriminate against you for exercising your privacy rights
      as set forth herein. If you choose to exercise your privacy rights, you
      will not receive discriminatory treatment or a lesser degree of service
      from us.
    </p>
    <p>
      <br />
    </p>
    <p>Changes to this Privacy Policy</p>
    <p>
      We may update our Privacy Policy from time to time by posting a new
      version online or within our application. You should check this page
      occasionally to review any changes. If we make material changes to this
      Privacy Policy, we will notify you by email, in-app notification, a notice
      on this website or another method that we believe is reasonably likely to
      reach you.
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>NOTICE OF PRIVACY PRACTICES</p>
    <p>
      <br />
    </p>
    <p>
      THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND
      DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT
      CAREFULLY.
    </p>
    <p>
      <br />
    </p>
    <p>
      Equip Health Medical, P.C. and its associated professional entities
      (collectively &ldquo;Equip Health&rdquo;) is committed to obtaining,
      maintaining, using and disclosing your protected health information
      (&ldquo;PHI&rdquo;) in a manner that protects your privacy. We urge you to
      read this Notice of Privacy Practices (this &ldquo;Notice&rdquo;)
      carefully in order to understand both our commitment to the privacy of
      your PHI and your rights.
    </p>
    <p>
      <br />
    </p>
    <p>
      Equip Health is required by law to maintain the privacy of your PHI and to
      provide you with a notice of our legal duties and privacy practices with
      respect to PHI. PHI is information about you, including basic demographic
      information, that may identify you and that relates to your past, present
      or future physical or mental health condition, treatment or payment for
      health services. This Notice describes how we may use and disclose your
      PHI to carry out treatment, payment or health care operations, and for
      other specified purposes that are permitted or required by law. The Notice
      also describes your rights with respect to your PHI.
    </p>
    <p>
      <br />
    </p>
    <p>
      Equip Health reserves the right to amend this Notice from time to time.
      When material changes are made, Equip Health will promptly post the
      updated Notice on the Equip Health website at www.equip.health, and
      provide a copy to you at your request. Equip Health is required to abide
      by the terms of the Notice currently in effect.
    </p>
    <p>
      <br />
    </p>
    <p>I. Uses and Disclosures of Your Information</p>
    <p>
      <br />
    </p>
    <p>Equip Health may use or disclose your PHI for the following purposes:</p>
    <p>
      <br />
    </p>
    <ul>
      <li>
        <p>
          Treatment. Equip Health may use or disclose your PHI for purposes of
          providing your medical treatment. For example, we may disclose your
          PHI to your primary care physician if needed in order to coordinate
          your medical care.
        </p>
      </li>
      <li>
        <p>
          Payment. Equip Health may use or disclose your PHI for purposes of
          billing and collecting payment for our services. For example, we may
          disclose your PHI to your health plan in order to obtain payment for
          our services.
        </p>
      </li>
      <li>
        <p>
          Health Care Operations. Equip Health may use or disclose your PHI to
          facilitate our health care operations. For example, we may review your
          PHI internally as part of an audit to confirm the quality of services
          being delivered to our patients.
        </p>
      </li>
      <li>
        <p>
          As Required by Law. Equip Health may use or disclose your PHI if
          required to do so by federal or state law.
        </p>
      </li>
      <li>
        <p>
          Disclosures to your Representative and/or Individuals Involved in Your
          Care. Equip Health may disclose your PHI to your family members who
          are involved in your care, including those who are responsible for
          paying for your care. Equip Health may also disclose your PHI to your
          personal representative, as established under applicable law or to an
          administrator or authorized individual associated with your estate.
        </p>
      </li>
      <li>
        <p>
          Disclosures to Business Associates. Equip Health may disclose your PHI
          to certain of our service providers that have agreed to maintain the
          privacy and security thereof. For example, we may disclose your PHI to
          Equip Health, Inc., which provides administrative support services to
          Equip Health.
        </p>
      </li>
      <li>
        <p>
          De-Identification of PHI. Equip Health may de-identify your PHI,
          meaning that Equip Health would remove all identifying features as
          determined by law to make it extremely unlikely that the information
          could identify you. De-identified information no longer qualifies as
          PHI, meaning that Equip Health may use and disclose it for purposes
          not set forth in this Notice.
        </p>
      </li>
      <li>
        <p>
          Equip Health may also use or disclose your PHI in other ways as
          permitted by law. Generally, these are ways that serve the public
          health and/or research. Specifically:
        </p>
      </li>
    </ul>
    <p>
      <br />
    </p>
    <ul>
      <li>
        <p>
          Equip Health may use or disclose your PHI as needed to assist with
          public health and safety issues and may disclose your PHI to law
          enforcement officials when needed, to health oversight agencies for
          authorized activities and for special government functions including
          national security needs.
        </p>
      </li>
      <li>
        <p>
          Equip Health may disclose your PHI as needed to organ procurement
          organizations, medical examiners and funeral directors in the event of
          an individual&rsquo;s death.
        </p>
      </li>
      <li>
        <p>
          Equip Health may use or disclose your PHI to address workers&rsquo;
          compensation claims.
        </p>
      </li>
      <li>
        <p>
          Equip Health may use or disclose your PHI in response to a court or
          administrative order, or in response to a subpoena.
        </p>
      </li>
    </ul>
    <p>
      <br />
    </p>
    <p>
      Uses and disclosures of PHI for purposes other than those described above,
      including for marketing purposes and disclosures that would constitute the
      sale of PHI, will not be made in the absence of a written authorization
      signed by you or your personal representative.&nbsp;
    </p>
    <p>
      <br />
    </p>
    <p>
      Once you sign an authorization, you may revoke it by contacting Equip
      Health at support@equip.health. However, any use or disclosure of your PHI
      already taken in reliance on your authorization prior to your revocation
      cannot be reversed.
    </p>
    <p>
      <br />
    </p>
    <p>II. Your Rights Regarding Your PHI</p>
    <p>
      <br />
    </p>
    <p>You have the following rights with respect to your PHI:</p>
    <p>
      <br />
    </p>
    <ul>
      <li>
        <p>
          You have the right to request restrictions on certain uses and
          disclosures of your PHI. Equip Health will consider every request to
          restrict uses or disclosures of your PHI and will strive to honor
          those that are reasonable. However, Equip Health is not legally
          required to honor each request unless the requested restriction
          involves a disclosure not required by law to a health plan for
          purposes of payment or health care operations, and you have paid for
          the applicable services in full, out of pocket. With respect to any
          requested restriction, if Equip Health agrees to honor it, we will
          document such restriction and continue to abide by it.
        </p>
      </li>
      <li>
        <p>
          You have the right to receive confidential communications of your PHI
          from Equip Health. Specifically, you may request that Equip Health
          communicate with you about your PHI using a specific means, phone
          number or address. Equip Health will accommodate reasonable requests
          regarding confidential communications of your PHI.
        </p>
      </li>
      <li>
        <p>
          Subject to applicable state law, you have the right to inspect and
          copy your PHI. You also have the right to access and receive your PHI
          electronically if readily producible in such format.
        </p>
      </li>
      <li>
        <p>
          You have the right to correct or update your PHI. If you believe that
          there is an error in your PHI, you may request that Equip Health
          update it as appropriate.
        </p>
      </li>
      <li>
        <p>
          You have the right to receive an accounting of certain disclosures of
          your PHI made by Equip Health. Upon receipt of such request, Equip
          Health will provide you with a list of disclosures made by Equip
          Health in the prior six (6) years, not including certain types of
          disclosures such as, by way of example only, those made directly to
          you or pursuant to your written authorization.
        </p>
      </li>
      <li>
        <p>
          You have the right to obtain a paper copy of this Notice upon request.
        </p>
      </li>
    </ul>
    <p>
      <br />
    </p>
    <p>
      To exercise any of these rights, please send written communication to
      Equip Health at support@equip.health.
    </p>
    <p>
      <br />
    </p>
    <p>III. Breach Notification</p>
    <p>
      <br />
    </p>
    <p>
      Equip Health is required by law to notify you in the event that your PHI
      is subject to a security breach unless Equip Health reasonably determines
      that, after fully investigating the situation and assessing the risk
      presented, there is a low probability that the privacy or security of your
      PHI has been compromised. You will be notified without unreasonable delay
      and in no event later than sixty (60) days following discovery of the
      security breach. Such notification will include information about the
      security breach, including steps that Equip Health has taken to mitigate
      potential harm, and a contact person to whom you may address additional
      questions.
    </p>
    <p>
      <br />
    </p>
    <p>IV. Questions, Comments or Complaints</p>
    <p>
      <br />
    </p>
    <p>
      If you have any questions or comments about this Notice, or if you have
      any complaints about Equip Health&rsquo;s privacy practices, please
      contact us at support@equip.health. You may also file a complaint with the
      Secretary of the U.S. Department of Health and Human Services. Equip
      Health will not retaliate against you for filing a complaint.
    </p>
    <p>
      <br />
    </p>
    <p>Contact Us</p>
    <p>
      <br />
    </p>
    <p>
      If you have any questions or concerns at all about our Privacy Policy,
      please contact us at:
    </p>
    <p>
      <br />
    </p>
    <p>PO Box 131747, Carlsbad, California 92013-1747</p>
    <p>
      <br />
    </p>
    <p>or</p>
    <p>
      <br />
    </p>
    <p>support@equip.health.</p>
    <p>
      <br />
    </p>
    <p>Last Updated and Effective: December 15, 2022</p>
  </ChakraBox>
);

export default PrivacyPolicy;
