import { FC, ReactElement, createContext, useContext } from 'react';

import useUserProfile, {
  UserProfileContextState,
} from '~/lib/hooks/useUserProfile';

const UserProfileContext = createContext<UserProfileContextState | undefined>(
  undefined,
);

interface UserProfileContextProviderProps {
  children: ReactElement | ReactElement[];
}

const UserProfileContextProvider: FC<UserProfileContextProviderProps> = ({
  children,
}: UserProfileContextProviderProps) => {
  const value = useUserProfile();

  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  );
};

const useUserProfileContext = (): UserProfileContextState => {
  const context = useContext(UserProfileContext);

  if (context === undefined) {
    throw new Error(
      'useUserProfileContext must be used within a UserProfileContextProvider',
    );
  }

  return context;
};

export { UserProfileContextProvider, useUserProfileContext };
