import { FC } from 'react';

import { ChakraBox, EquipUIFireflyV1Theme, Text } from '@equip.health/ui';

const { paragraph } = EquipUIFireflyV1Theme.typography;
export interface TaskModalBodyProps {
  taskDescription: string;
}

const TaskModalBody: FC<TaskModalBodyProps> = ({ taskDescription }) => (
  <ChakraBox color="black.90" marginBottom="16px" {...paragraph}>
    {taskDescription.split('\n').map((str) => (
      // eslint-disable-next-line react/jsx-key
      <Text marginBottom="18px">{str}</Text>
    ))}
  </ChakraBox>
);

export default TaskModalBody;
