import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './reducers';
import api from './services/api';

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
    preloadedState,
    reducer: rootReducer,
  });
}

const store = setupStore();

export default store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = AppStore['dispatch'];
