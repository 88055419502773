import { FC } from 'react';

import { ChakraBox, EquipUIFireflyV2Theme, Text } from '@equip.health/ui';
import { DateTime } from 'luxon';

import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import {
  generateHostedByString,
  generateParticipantNameString,
  transformDateFromISOtoLocal,
} from '~/lib/util/schedule.util';

const { neutral } = EquipUIFireflyV2Theme.colors;

interface PastScheduleItemProps {
  endDate: string;
  startDate: string;
  subtitle: string;
  title: string;
}

const PastScheduleItem: FC<PastScheduleItemProps> = ({
  endDate,
  startDate,
  subtitle,
  title,
}) => {
  const date = DateTime.fromISO(startDate);
  const month = date.toLocaleString({
    month: 'long',
  });
  const day = date.toLocaleString({
    day: '2-digit',
  });

  const startAppointmentTime = transformDateFromISOtoLocal(startDate);
  const endAppointmentTime = transformDateFromISOtoLocal(endDate);

  return (
    <ChakraBox
      borderBottom={`solid 1px ${neutral.line}`}
      marginBottom="24px"
      paddingBottom="24px"
    >
      <Text color="neutral.secondary" variant="captionMedium">
        {`${month} ${day}`} - {startAppointmentTime} - {endAppointmentTime}
      </Text>
      <Text color="neutral.primary" marginY="4px" variant="bodyMedium">
        {title}
      </Text>
      <Text color="neutral.secondary" variant="caption">
        {subtitle}
      </Text>
    </ChakraBox>
  );
};

export const PastAppointmentItem: FC<{
  eventInfo: AppointmentDetailResponse;
}> = ({ eventInfo }) => {
  const { userProfile } = useUserProfileContext();
  return (
    <PastScheduleItem
      endDate={eventInfo.appointmentEndDateTime}
      startDate={eventInfo.appointmentStartDateTime}
      subtitle={generateParticipantNameString(eventInfo, userProfile)}
      title={eventInfo.appointmentType}
    />
  );
};

export const PastGroupClassItem: FC<{
  eventInfo: GroupClassEventDetails;
}> = ({ eventInfo }) => {
  return (
    <PastScheduleItem
      endDate={eventInfo.endTime}
      startDate={eventInfo.startTime}
      subtitle={generateHostedByString(eventInfo.hosts)}
      title={eventInfo.title}
    />
  );
};
