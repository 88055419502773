import { EquipUIFireflyV1Theme } from '@equip.health/ui';

const { captionSemibold, body } = EquipUIFireflyV1Theme.typography;
const { fontSize, fontFamily, fontWeight, lineHeight } = captionSemibold;
const {
  fontSize: bodyFontSize,
  fontFamily: bodyFontFamily,
  fontWeight: bodyFontWeight,
  lineHeight: bodyLineHeight,
} = body;

export const textInputLabelProps = {
  color: 'mono.100',
  fontSize: bodyFontSize,
  formLabelMarginLeft: '0px',
  labelFontFamily: fontFamily,
  labelFontSize: fontSize,
  labelFontWeight: fontWeight,
  labelLineHeight: lineHeight,
  valueFontFamily: bodyFontFamily,
  valueFontWeight: bodyFontWeight,
  valueLineHeight: bodyLineHeight,
};

export const autoCompleteProps = {
  ...captionSemibold,
  backgroundColor: 'mono.5',
  valueFontColor: 'mono.100',
  valueFontFamily: bodyFontFamily,
  valueFontSize: bodyFontSize,
  valueFontWeight: bodyFontWeight,
  valueLineHeight: bodyLineHeight,
};

export enum InquiryStep {
  PATIENT_INFORMATION = 1,
  CONTACT_INFORMATION = 2,
  REVIEW = 3,
  SCHEDULE_APPOINTMENT = 4,
  THANK_YOU = 5,
  OUT_OF_NETWORK = 6,
}

export enum SchedulerStep {
  SELECT_SLOT,
  ADD_CONTACT_INFO,
  THANK_YOU,
}

export const stepsData: Step[] = [
  {
    canEdit: false,
    id: InquiryStep.PATIENT_INFORMATION,
    isStepActiveStatus: true,
    name: 'Patient information',
  },
  {
    canEdit: false,
    id: InquiryStep.CONTACT_INFORMATION,
    isStepActiveStatus: false,
    name: 'Contact information',
  },
  {
    canEdit: false,
    id: InquiryStep.REVIEW,
    isStepActiveStatus: false,
    name: 'Review',
  },
  {
    canEdit: false,
    id: InquiryStep.SCHEDULE_APPOINTMENT,
    isStepActiveStatus: false,
    name: 'Schedule appointment',
  },
];

export const inquiryReferrerRequiredSourceOptions = [
  'Dietitian',
  'Doctor/clinician',
  'Therapist',
  'Hospital/medical center (please specify)',
];

type UtmKey =
  | 'gclid'
  | 'msclkid'
  | 'utm_adgroup'
  | 'utm_campaign'
  | 'utm_id'
  | 'utm_medium'
  | 'utm_source'
  | 'utm_term';

export type UtmParams = Record<UtmKey, string>;

export const OTHER_PAYOR = 'Other';
