import { FC } from 'react';

import { ChakraFlex, GreetingCard } from '@equip.health/ui';

import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { getGreetingFromCurrentTime } from '~/lib/util/date.util';
import { getPreferredProfileFirstName } from '~/lib/utils';

const TOP_LEVEL_ID = 'greeting-hero';

const GreetingHero: FC = () => {
  const { userProfile } = useUserProfileContext();

  const preferredFirstName = getPreferredProfileFirstName(userProfile);
  const greeting = getGreetingFromCurrentTime();

  return (
    <ChakraFlex
      alignItems="center"
      height="200px"
      id={TOP_LEVEL_ID}
      marginTop="20px"
    >
      <GreetingCard
        id={`${TOP_LEVEL_ID}-card`}
        subtitle="Welcome to your Equip account, your home for all things Equip."
        title={`${greeting}, ${preferredFirstName}`}
      />
    </ChakraFlex>
  );
};

export default GreetingHero;
