import { FC, ReactElement } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  EquipUIFireflyV2Theme,
  FAIconName,
  Icon,
  Text,
} from '@equip.health/ui';

const { colors } = EquipUIFireflyV2Theme;

const topLevelId = 'task-details-modal';
const TaskModalTitle: FC<{
  description?: ReactElement | string;
  duration: string;
  title: ReactElement | string;
}> = ({ description, duration, title }) => (
  <ChakraBox marginRight="40px">
    <Text id={`${topLevelId}__title`} marginBottom="16px" variant="h4">
      {title}
    </Text>
    <ChakraFlex alignItems="center" gridGap="4px">
      <Icon
        color={colors.neutral.secondary}
        name={FAIconName.clockRegular}
        size="sm"
      />
      <Text
        color="neutral.secondary"
        id={`${topLevelId}__duration`}
        variant="caption"
      >
        {duration}
      </Text>
    </ChakraFlex>
    {description && (
      <Text
        color="neutral.primary"
        id={`${topLevelId}__description`}
        marginTop="32px"
      >
        {description}
      </Text>
    )}
  </ChakraBox>
);

export default TaskModalTitle;
