import { FC, useEffect, useState } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraHeading,
  ChevronLeftIcon,
  EquipUITheme,
  Modal,
} from '@equip.health/ui';
import { DateTime } from 'luxon';

import Layout from './Layout';
import SafetyPlanLoader from './SafetyPlanLoader';
import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useApi } from '~/lib/hooks';
import { stringToJson } from '~/lib/util/survey.util';

const TOP_LEVEL_ID = 'safety-plan';

type SafetyPlanModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
const { h4 } = EquipUITheme.typography;
const SafetyPlanModal: FC<SafetyPlanModalProps> = ({
  isOpen,
  onClose,
}: SafetyPlanModalProps) => {
  const {
    patientsCompletedSafetyPlan,
    selectedPatientForSafetyPlan,
    updateIsSafetyPlanOpen,
    updateIsLinkedPatientModalOpen,
  } = useUserProfileContext();
  const {
    data: safetyPlanData,
    loading: fetchSafetyPlanLoading,
    sendRequest: fetchSafetyPlan,
  } = useApi<any>();
  const [definition, setDefinition] = useState(null);
  const [jsonResult, setJsonResult] = useState(null);
  const backClickHandler = () => {
    updateIsSafetyPlanOpen(false);
    updateIsLinkedPatientModalOpen(true);
  };
  const patientName =
    safetyPlanData?.data?.userDetails.chosenName ||
    safetyPlanData?.data?.userDetails.firstName;

  const safetyPlanTitle = `${patientName}'s ${definition?.title}`;

  const surveyCompletionDate = DateTime.fromISO(
    safetyPlanData?.data?.response.endDate,
  ).toFormat('LLLL d, yyyy');

  useEffect(() => {
    if (selectedPatientForSafetyPlan) {
      fetchSafetyPlan({
        callback: (response: SafetyPlanApiResponse) => {
          setDefinition(stringToJson(response.data.surveyDefinition));
          setJsonResult(stringToJson(response.data.response.jsonResult));
        },
        command: ApiCommand.GET,
        url: urlConstants.safetyPlan.getSurvey(selectedPatientForSafetyPlan),
      });
    }
  }, [selectedPatientForSafetyPlan]);

  return (
    <Modal
      bodyStyle={{
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '720px',
      }}
      hideHeader
      id={TOP_LEVEL_ID}
      isOpen={isOpen}
      onClose={onClose}
      size="full"
    >
      {fetchSafetyPlanLoading ? (
        <SafetyPlanLoader />
      ) : (
        <>
          <ChakraFlex width="100%">
            {patientsCompletedSafetyPlan &&
              patientsCompletedSafetyPlan.length > 1 && (
                <ChakraBox
                  as="button"
                  bottom="8px"
                  onClick={backClickHandler}
                  position="relative"
                  right="6px"
                >
                  <ChevronLeftIcon height={10} width={8} />
                </ChakraBox>
              )}
            <ChakraHeading
              as="h4"
              {...h4}
              marginBottom="16px"
              textAlign="left"
              textColor="black.90"
            >
              {safetyPlanTitle}
            </ChakraHeading>
          </ChakraFlex>
          {definition && jsonResult && (
            <Layout
              definition={definition}
              jsonResult={jsonResult}
              patientName={patientName}
              surveyCompletionDate={surveyCompletionDate}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default SafetyPlanModal;
