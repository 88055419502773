import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MenuOption } from '@equip.health/ui';
import * as Sentry from '@sentry/react';
import lodash from 'lodash';
import { DateTime } from 'luxon';
import { Prompt, useHistory } from 'react-router-dom';
import { Model, PageModel, StylesManager } from 'survey-core';
import { Survey as SurveyJS } from 'survey-react-ui';
import 'survey-core/modern.min.css';

import './survey.css';

import Layout from './Layout';
import SurveyErrorModal from './SurveyErrorModal';
import VerifyUserModal from './VerifyUserModal';
import ExitConfirm from '~/components/common/ExitConfirm';
import { ApiCommand } from '~/lib/Api';
import { ASSESSMENT_NOT_FOUND } from '~/lib/constants/survey.constants';
import urlConstants from '~/lib/constants/url.constants';
import { useApi } from '~/lib/hooks';
import useDebounce from '~/lib/hooks/useDebounce';
import {
  filterSurveyDataWithNoTitles,
  getPageTitle,
  stringToJson,
  VerifyStep,
} from '~/lib/util/survey.util';
import { PAGE_NAME } from '~/lib/constants/analytics';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';

export type SurveyResponse = {
  currentPage?: number;
  currentPageNo?: number;
  endDate?: string;
  jsonResults?: string;
  responseExternalId: string;
  status: SurveyResponseStatus;
  submittedSource: string;
  submittedUserId: string;
  surveyDefinition: string;
  surveyExternalId: string;
  surveyName: string;
  toUserEmail?: string;
  updatedDate?: string;
  userId?: string;
};

type SurveyProps = {
  uuid: string;
  isFamilyTask?: boolean;
  onComplete?: () => void;
};

export enum SurveyResponseSource {
  WEB = 'Web',
}

export enum SurveyResponseStatus {
  NEW = 'New',
  REQUESTED = 'Requested',
  IN_PROGRESS = 'In progress',
  DONE = 'Done',
  VOID = 'Void',
  INCOMPLETE = 'Incomplete',
  CANCELLED = 'cancelled',
}

export enum SurveyErrorMsg {
  REQUEST_OTP_ERROR = 'A survey linked to this email was not found. Try another email or click cancel to return home.',
  GET_SURVEY_ERROR = 'Invalid verification code',
}

const customSurveyStyles = {
  page: { root: 'survey-body', title: 'pageTitle' },
};

// Disabling as requested in https://teamequip.atlassian.net/browse/EQ-15340
const isDiscardChangesModalEnabled = false;

StylesManager.applyTheme('modern');

const Survey: FC<SurveyProps> = ({
  uuid,
  isFamilyTask = false,
  onComplete,
}: SurveyProps) => {
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState<number>(null);
  const [desiredPath, setDesiredPath] = useState<any>(null);
  const [confirmNavigation, setConfirmNavigation] = useState<boolean>(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState<boolean>(false);
  const [isVerifyUserModalOpen, setIsVerifyUserModalOpen] = useState<boolean>(
    !isFamilyTask,
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [pages, setPages] = useState<PageModel[]>([]);
  const [code, setCode] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [verifyStep, setVerifyStep] = useState<VerifyStep>(
    VerifyStep.REQUEST_OTP,
  );
  const [endDate, setEndDate] = useState<string>(null);
  const [jsonResults, setJsonResults] = useState<Record<string, any>>(null);
  const [status, setStatus] = useState<SurveyResponseStatus>(null);
  const [surveyDefinition, setSurveyDefinition] =
    useState<SurveyDefinition>(null);
  const surveyRef = useRef<SurveyJS | null>(null);

  const {
    data: surveyData,
    error: getSurveyError,
    loading: isLoadingSurvey,
    sendRequest: getSurvey,
  } = useApi<SurveyResponse>();

  const {
    data: requestOTPResponse,
    error: requestOTPError,
    loading: isOTPRequestProcessing,
    sendRequest: requestOTP,
  } = useApi<{ message: string }>();

  const {
    endDate: _endDate,
    jsonResults: _jsonResults,
    responseExternalId,
    status: _status,
    surveyDefinition: _surveyDefinition,
    surveyExternalId: surveyId,
    surveyName,
    toUserEmail: respondentEmail,
  } = surveyData ?? {};

  const error = useMemo(() => {
    if (requestOTPError) {
      Sentry.captureException(SurveyErrorMsg.REQUEST_OTP_ERROR);
      return SurveyErrorMsg.REQUEST_OTP_ERROR;
    }

    if (getSurveyError) {
      Sentry.captureException(SurveyErrorMsg.GET_SURVEY_ERROR);
      return SurveyErrorMsg.GET_SURVEY_ERROR;
    }

    return '';
  }, [getSurveyError, requestOTPError]);

  const handleMouseWheelEvent = useCallback((event: WheelEvent) => {
    const domElement = event.target as HTMLInputElement;
    if (domElement?.type === 'number') {
      domElement.blur();
    }
  }, []);

  const { trackPageView } = useAnalytics();

  useEffect(() => {
    surveyName && trackPageView(PAGE_NAME.getSurveyPageName(surveyName));
  }, [surveyName]);

  useEffect(() => {
    if (getSurveyError) {
      setIsVerifyUserModalOpen(false);
      setStatus(SurveyResponseStatus.CANCELLED);
    }
  }, [getSurveyError]);

  useEffect(() => {
    if (responseExternalId) {
      setCode('');
      setUserEmail('');
      setVerifyStep(null);
      setIsVerifyUserModalOpen(false);
      setEndDate(_endDate);
      setJsonResults(stringToJson(_jsonResults));
      setStatus(_status);
      setSurveyDefinition(stringToJson(_surveyDefinition));
    }
  }, [responseExternalId]);

  useEffect(() => {
    if (requestOTPResponse) {
      setVerifyStep(VerifyStep.CHECK_OTP);
    }
  }, [requestOTPResponse]);

  const pageMenuOptions: MenuOption[] = useMemo(
    () =>
      pages.map((page) => {
        const pageName = page.getPropertyValue('name');
        return {
          node: getPageTitle(surveyDefinition, pageName),
          value: pageName,
        };
      }),
    [pages.length],
  );

  const fetchAssessment = (): void => {
    if (isFamilyTask) {
      getSurvey({
        command: ApiCommand.GET,
        url: urlConstants.assessments.familyTaskAssesments(uuid),
      });
    } else {
      if (verifyStep === VerifyStep.REQUEST_OTP) {
        requestOTP({
          command: ApiCommand.POST,
          options: { email: userEmail },
          skipToken: true,
          url: urlConstants.assessments.verify(uuid),
        });
      }
      if (verifyStep === VerifyStep.CHECK_OTP) {
        getSurvey({
          command: ApiCommand.POST,
          options: { code, email: userEmail },
          skipToken: true,
          url: urlConstants.assessments.get(uuid),
        });
      }
    }
  };

  useEffect(() => {
    if (isFamilyTask) {
      fetchAssessment();
    }
  }, [isFamilyTask]);

  const handleCloseVerifyModal = () => {
    setIsVerifyUserModalOpen(false);
    history.push('/');
  };

  const {
    error: saveSurveyError,
    loading: isSaveSurveyProcessing,
    sendRequest: saveSurvey,
    statusCode: saveSurveyStatusCode,
  } = useApi();

  useEffect(() => {
    if (
      saveSurveyError === ASSESSMENT_NOT_FOUND &&
      saveSurveyStatusCode === 500
    ) {
      setStatus(SurveyResponseStatus.CANCELLED);
    }
  }, [saveSurveyError]);

  const saveResults = (surveyModel: Model, ignoreStatus = false): void => {
    const status =
      !ignoreStatus && surveyModel.currentPageNo === pages.length - 1
        ? SurveyResponseStatus.DONE
        : SurveyResponseStatus.IN_PROGRESS;

    const surveyModelData = {
      ...surveyModel.data,
      currentPage: surveyModel.currentPage.getPropertyValue('name'),
      currentPageNo: surveyModel.currentPageNo,
    };

    saveSurvey({
      command: ApiCommand.PATCH,
      /** @TODO set submittedUser* values once auth is enabled */
      options: {
        jsonResults: JSON.stringify(surveyModelData),
        status,
        submittedSource: SurveyResponseSource.WEB,
        submittedUserFirstName: null,
        submittedUserId: null,
        submittedUserLastName: null,
        toUserEmail: respondentEmail,
      },
      skipToken: !isFamilyTask,
      url: isFamilyTask
        ? urlConstants.assessments.familyTaskAssesments(responseExternalId)
        : urlConstants.assessments.update(responseExternalId),
    });

    setHasUnsavedChanges(false);

    if (status === SurveyResponseStatus.DONE) setIsSubmitting(true);
  };

  const onSurveyComplete = (sender: Model) => {
    setEndDate(new Date().toISOString());
    setJsonResults(sender?.data ?? {});
    setStatus(SurveyResponseStatus.DONE);
    /** @TODO Add toast with success message here */
  };

  /* eslint-disable no-param-reassign */
  // mutating surveyModel is the only way to programmatically set the current page
  const onSurveyLoad = (surveyModel: Model) => {
    const currentPageNo = jsonResults?.currentPageNo ?? 0;
    setCurrentPage(currentPageNo);
    surveyModel.currentPage = jsonResults?.currentPage;
    surveyModel.currentPageNo = currentPageNo;
    setPages(surveyModel.pages);
  };

  const handlePageChange = (surveyModel: Model) => {
    setCurrentPage(surveyModel.currentPageNo);
  };

  const debounceSaveSurvey = useDebounce(1000 * 10);

  const autoSaveCallbackRef = useRef(null);
  const isFirstChange = useRef(true);

  const onValueChanged = (surveyModel: Model) => {
    setHasUnsavedChanges(true);
    if (isFirstChange.current) {
      isFirstChange.current = false;
      if (autoSaveCallbackRef?.current) {
        saveResults(surveyModel, true);
      }
    } else {
      debounceSaveSurvey(() => {
        if (autoSaveCallbackRef?.current)
          autoSaveCallbackRef.current(surveyModel);
      });
    }
  };

  const survey = useMemo<Model>(() => {
    if (
      surveyId &&
      [
        SurveyResponseStatus.NEW,
        SurveyResponseStatus.REQUESTED,
        SurveyResponseStatus.IN_PROGRESS,
      ].includes(status)
    ) {
      const surveyModel = new Model({ surveyId });

      surveyModel.data = jsonResults;
      surveyModel.css = customSurveyStyles;
      surveyModel.onComplete.add(onSurveyComplete);
      surveyModel.onCurrentPageChanged.add(handlePageChange);
      surveyModel.onLoadedSurveyFromService.add(onSurveyLoad);
      surveyModel.onPartialSend.add((originalSurvey: Model) => {
        const { currentPageNo, pages: _pages } = originalSurvey;
        const surveyToSave: Model = lodash.cloneDeep(originalSurvey);
        if (originalSurvey.currentPageNo < _pages.length - 1) {
          surveyToSave.currentPage =
            _pages[currentPageNo + 1].getPropertyValue('name');
          surveyToSave.currentPageNo = currentPageNo + 1;
        }
        saveResults(surveyToSave);
      });
      surveyModel.onValueChanged.add(onValueChanged);
      surveyModel.sendResultOnPageNext = true;
      surveyModel.showCompletedPage = false;
      surveyModel.showNavigationButtons = false;
      surveyModel.showTitle = false;
      surveyModel.textUpdateMode = 'onTyping';

      return surveyModel;
    }
    return null;
  }, [surveyId, status]);

  // EQ-7848 : Disable default behavior of mouse wheel scroll on input type = 'number'
  useEffect(() => {
    /* Adding ts-ignore temporarily to avoid typescript error for rootRef.
     * rootRef is a private property of surveyjs-react-ui and is not exposed in the types.
     */
    // @ts-ignore
    const currentSurveyRefRootElement = surveyRef?.current?.rootRef
      ?.current as HTMLInputElement;

    if (currentSurveyRefRootElement) {
      currentSurveyRefRootElement?.addEventListener(
        'wheel',
        handleMouseWheelEvent,
      );
    }

    return () => {
      currentSurveyRefRootElement?.removeEventListener(
        'wheel',
        handleMouseWheelEvent,
      );
    };
  }, [survey]);

  useEffect(() => {
    autoSaveCallbackRef.current = (surveyModel: Model) => {
      if (!isSaveSurveyProcessing && hasUnsavedChanges)
        saveResults(surveyModel, true);
    };
  }, [isSaveSurveyProcessing, hasUnsavedChanges]);

  useEffect(() => {
    if (
      currentPage === pages.length - 1 &&
      isSubmitting &&
      saveSurveyStatusCode === 200
    ) {
      if (isFamilyTask && onComplete) {
        onComplete();
      }
      survey?.completeLastPage();
    }
  }, [isSubmitting, saveSurveyStatusCode]);

  useEffect(() => {
    if (saveSurveyStatusCode === 200) {
      setHasUnsavedChanges(false);
      setIsUnsavedChangesModalOpen(false);
    }
  }, [saveSurveyStatusCode]);

  const handlePreviousClick = (): void => {
    survey.prevPage();
    saveResults(survey, true);
  };

  const handleNextClick = (): void => {
    survey.nextPage();
  };

  const handleSkip = (page: string): void => {
    const currentPageNo = pages.findIndex(
      (p) => p.getPropertyValue('name') === page,
    );

    survey.currentPage = page;
    survey.currentPageNo = currentPageNo;
    setCurrentPage(currentPageNo);
    saveResults(survey, true);
  };

  const getFirstPageError = (): string => {
    for (const page of pages) {
      if (survey.hasPageErrors(page)) return page.getPropertyValue('name');
    }
    return null;
  };

  const handleSubmit = () => {
    if (!survey.hasErrors()) {
      setIsSubmitting(true);
      saveResults(survey);
    } else {
      handleSkip(getFirstPageError());
    }
  };

  // Navigate to the previous blocked location and close/clean up
  useEffect(() => {
    if (confirmNavigation && desiredPath) {
      history.push(desiredPath);
    }
  }, [confirmNavigation, desiredPath]);

  /** Blocks navigation if the user is attempting to navigate away from this page with unsaved changes */
  const handleNavigateWithUnsavedChanges = ({ pathname }: any): boolean => {
    if (!confirmNavigation && hasUnsavedChanges) {
      setIsUnsavedChangesModalOpen(true);
      setDesiredPath(pathname);
      return false;
    }
    return true;
  };

  const handleExitConfirm = () => {
    if (desiredPath) {
      setConfirmNavigation(true);
    }
  };

  const showErrorModal = [
    SurveyResponseStatus.CANCELLED,
    SurveyResponseStatus.INCOMPLETE,
  ].includes(status);

  return (
    <>
      <Prompt
        message={handleNavigateWithUnsavedChanges}
        when={hasUnsavedChanges && isDiscardChangesModalEnabled}
      />
      <ExitConfirm
        handleClose={() => setIsUnsavedChangesModalOpen(false)}
        handleConfirm={handleExitConfirm}
        isOpen={isUnsavedChangesModalOpen}
      />
      <VerifyUserModal
        code={code}
        error={error}
        isOpen={isVerifyUserModalOpen}
        isProcessing={isLoadingSurvey || isOTPRequestProcessing}
        onClose={handleCloseVerifyModal}
        onSubmit={fetchAssessment}
        otpPrompt={requestOTPResponse?.message}
        setCode={setCode}
        setUserEmail={setUserEmail}
        step={verifyStep}
        userEmail={userEmail}
      />
      {[
        SurveyResponseStatus.NEW,
        SurveyResponseStatus.REQUESTED,
        SurveyResponseStatus.IN_PROGRESS,
        SurveyResponseStatus.DONE,
      ].includes(status) && (
        <Layout
          currentPage={currentPage}
          data={filterSurveyDataWithNoTitles(surveyDefinition, jsonResults)}
          definition={surveyDefinition}
          isFamilyTask={isFamilyTask}
          isLoading={isSaveSurveyProcessing}
          isReadOnly={[
            SurveyResponseStatus.INCOMPLETE,
            SurveyResponseStatus.DONE,
          ].includes(status)}
          onNext={handleNextClick}
          onPrevious={handlePreviousClick}
          onSkip={handleSkip}
          onSubmit={handleSubmit}
          pages={pageMenuOptions}
          saveError={saveSurveyError}
          surveyCompletionDate={
            !endDate ? null : DateTime.fromISO(endDate).toFormat('LL/dd/yyyy')
          }
          title={`${surveyName} Survey`}
        >
          {survey && <SurveyJS model={survey} ref={surveyRef} />}
        </Layout>
      )}

      <SurveyErrorModal isOpen={showErrorModal} />
    </>
  );
};

export default Survey;
