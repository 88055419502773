import { useFlags } from 'launchdarkly-react-client-sdk';

const useFeatureFlags = (): FeatureFlags => {
  const {
    appAnalytics,
    eventAnalytics,
    inquiryV2Enabled,
    patientMessageTabEmojis,
    sensitiveGroupClasses,
  } = useFlags<LDFlags>();

  return {
    isAppAnalyticsEnabled: appAnalytics,
    isEventAnalyticsEnabled: eventAnalytics,
    isInquiryV2Enabled: inquiryV2Enabled,
    isPatientMessageTabEmojisEnabled: patientMessageTabEmojis,
    isSensitiveGroupClassesEnabled: sensitiveGroupClasses,
  };
};

export default useFeatureFlags;
