import { FC, useCallback } from 'react';

import {
  ChakraFlex,
  Modal,
  useBreakpointValue,
  useDisclosure,
} from '@equip.health/ui';

import BookAppointmentAttendeesStep from './steps/AppointmentAttendeesStep';
import BookAppointmentAvailability from './steps/AppointmentAvailabilityStep';
import BookAppointmentConfirmationStep from './steps/AppointmentConfirmationStep';
import BookAppointmentAppointmentDuration from './steps/AppointmentDurationStep';
import BookAppointmentOptionalAttendeesStep from './steps/AppointmentOptionalAttendeesStep';
import BookingOverviewStep from './steps/AppointmentOverviewStep';
import BookAppointmentAppointmentRecurrence from './steps/AppointmentRecurrenceStep';
import BookAppointmentAppointmentType from './steps/ProviderTypeStep';
import DiscardChangesModal from '~/components/common/DiscardChangesModal';
import ModalHeader from '~/components/common/modals/ModalHeader';
import BookAppointmentSelectPatient from '~/components/schedule/BookAppointment/steps/PatientStep';
import { BOOK_APPOINTMENT_STEP } from '~/lib/constants/bookAppointments';
import { useBookAppointmentContext } from '~/lib/context/BookAppointmentContext';

interface BookAppointmentModalProps {
  isOpen: boolean;
  onBookingComplete?: () => void;
  onClose: () => void;
}

const id = 'book-appointment';

// Setting to null for now until each specific step component is built and added to the step mapper
const StepMapper: Record<BOOK_APPOINTMENT_STEP, FC> = {
  [BOOK_APPOINTMENT_STEP.patient]: BookAppointmentSelectPatient,
  [BOOK_APPOINTMENT_STEP.appointmentType]: BookAppointmentAppointmentType,
  [BOOK_APPOINTMENT_STEP.attendees]: BookAppointmentAttendeesStep,
  [BOOK_APPOINTMENT_STEP.optionalAttendees]:
    BookAppointmentOptionalAttendeesStep,
  [BOOK_APPOINTMENT_STEP.duration]: BookAppointmentAppointmentDuration,
  [BOOK_APPOINTMENT_STEP.recurrence]: BookAppointmentAppointmentRecurrence,
  [BOOK_APPOINTMENT_STEP.availability]: BookAppointmentAvailability,
  [BOOK_APPOINTMENT_STEP.overview]: BookingOverviewStep,
  [BOOK_APPOINTMENT_STEP.confirmation]: BookAppointmentConfirmationStep,
};

const BookAppointmentModal: FC<BookAppointmentModalProps> = ({
  isOpen,
  onBookingComplete,
  onClose,
}) => {
  const { currentStep, reset, initialStep } = useBookAppointmentContext();
  const isFirstStep = initialStep === currentStep;
  const isLastStep = BOOK_APPOINTMENT_STEP.confirmation === currentStep;

  const desktopModalSize = isLastStep ? 'xl' : '5xl';
  const modalSize = useBreakpointValue({ base: 'full', md: desktopModalSize });

  const {
    isOpen: isDiscardChangesModalOpen,
    onOpen: onDiscardChangesModalOpen,
    onClose: onDiscardChangesModalClose,
  } = useDisclosure();

  const resetOnClose = useCallback(() => {
    // Adding a timeout to allow the modal to close before resetting the state.
    // This avoids a resize of the modal size when it's closing and resetting the state.
    setTimeout(() => {
      reset();
    }, 500);
  }, []);

  const shouldOpenDiscardChangesModal = !isFirstStep && !isLastStep;

  const handleConfirmDiscard = useCallback(() => {
    if (isDiscardChangesModalOpen) {
      onDiscardChangesModalClose();
    }
    onClose();
    resetOnClose();
  }, [isOpen, isDiscardChangesModalOpen]);

  const handleModalClose = useCallback(() => {
    if (isLastStep) {
      onBookingComplete();
    }

    if (shouldOpenDiscardChangesModal) {
      onDiscardChangesModalOpen();
    } else {
      onClose();
      resetOnClose();
    }
  }, [shouldOpenDiscardChangesModal, isLastStep]);

  const StepComponent = StepMapper[currentStep];

  return (
    <>
      <DiscardChangesModal
        cancelButtonText="Return to booking"
        confirmButtonText="Leave booking"
        description="If you leave now, your booking request will not be completed."
        handleClose={onDiscardChangesModalClose}
        handleConfirmDiscard={handleConfirmDiscard}
        isOpen={isOpen && isDiscardChangesModalOpen}
        showCancelButton
        title="Are you sure?"
      />
      <Modal
        hideCloseButton
        hideHeader
        id={id}
        isLazy
        isOpen={isOpen}
        onClose={handleModalClose}
        size={modalSize}
      >
        <ChakraFlex
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
        >
          {currentStep === BOOK_APPOINTMENT_STEP.confirmation ? (
            <BookAppointmentConfirmationStep onClose={handleModalClose} />
          ) : (
            <>
              <ModalHeader
                id={id}
                onClose={handleModalClose}
                title="Book an appointment"
              />
              {StepComponent && <StepComponent />}
            </>
          )}
        </ChakraFlex>
      </Modal>
    </>
  );
};

export default BookAppointmentModal;
