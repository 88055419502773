import { FC, useEffect, useMemo, useState } from 'react';

import {
  AngleDown,
  AutoComplete,
  AutoCompleteOption,
  ChakraBox,
  ChakraFlex,
  ChakraRadioGroup,
  ChakraSimpleGrid,
  ChakraText,
  EquipUIFireflyV1Theme,
  Radio,
  TextInput,
} from '@equip.health/ui';

import FormButtonBar from '~/components/common/FormButtonBar';
import { INQUIRY_REFERRAL_DISPLAY_TYPE, InquiryMode } from '~/lib/constants';
import {
  InquiryStep,
  autoCompleteProps,
  textInputLabelProps,
} from '~/lib/constants/inquiry.constants';
import {
  Enumerations,
  useEnumerationContext,
} from '~/lib/context/EnumerationContext';
import useFeatureFlags from '~/lib/hooks/useFeatureFlags';

const { black, teal } = EquipUIFireflyV1Theme.colors;
const { captionSemibold, overline } = EquipUIFireflyV1Theme.typography;

const TOP_LEVEL_ID = 'inquiry-step-one';

type InquiryFormStepOneProps = {
  onStepChangeMount: (currentStep: InquiryStep) => void;
  inquiryData: InquiryFormType;
};
const InquiryFormStepOne: FC<InquiryFormStepOneProps> = ({
  onStepChangeMount,
  inquiryData,
}: InquiryFormStepOneProps) => {
  const {
    firstName,
    lastName,
    patientFirstName,
    patientLastName,
    relation,
    inquiryAbout,
  } = inquiryData.value;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inquiryValueForOthers = inquiryAbout === InquiryMode.SOMEONE_ELSE;
  const inquiryValueForMySelf = inquiryAbout === InquiryMode.MYSELF;

  const handleTextFieldChange = (key: string, value: string) => {
    inquiryData.setter({
      ...inquiryData.value,
      [key]: value,
    });
  };

  const handleMenuSelect = (key: string, value: string) => {
    inquiryData.setter({
      ...inquiryData.value,
      inquiryAbout: InquiryMode.SOMEONE_ELSE,
      [key]: value,
    });
  };
  const handleConfirm = (): void => {
    setIsLoading(true);
    onStepChangeMount(InquiryStep.CONTACT_INFORMATION);
  };

  const isInvalidInquiry = useMemo<boolean>(
    () =>
      !(
        firstName?.trim() &&
        lastName?.trim() &&
        (!inquiryValueForOthers ||
          (patientFirstName?.trim() && patientLastName?.trim() && relation))
      ),
    [
      firstName,
      inquiryValueForOthers,
      lastName,
      patientFirstName,
      patientLastName,
      relation,
    ],
  );

  const { isInquiryV2Enabled } = useFeatureFlags();

  const {
    fetchEnumerations,
    isRelationsToPatientLoading,
    relationsToPatientInquiry,
  } = useEnumerationContext();

  useEffect(() => {
    fetchEnumerations([Enumerations.RELATIONS_TO_PATIENT]);
  }, [isInquiryV2Enabled]);

  const relations = useMemo<AutoCompleteOption[]>(() => {
    if (!isInquiryV2Enabled) {
      return (
        (relationsToPatientInquiry as InquiryRelationToPatient[]) ?? []
      ).map(({ relationToPatientName }) => ({
        primaryText: relationToPatientName,
        value: relationToPatientName,
      }));
    }
    return (
      (relationsToPatientInquiry as InquiryRelationToPatientV2[]) ?? []
    ).map(({ label }) => ({
      primaryText: label,
      value: label,
    }));
  }, [relationsToPatientInquiry?.length]);

  const selectedRelationOption: AutoCompleteOption = relations?.find(
    ({ value }) => value === relation,
  );

  return (
    <ChakraBox>
      <ChakraSimpleGrid
        columns={2}
        display={{ sm: 'grid' }}
        marginBottom="24px"
        spacing="16px"
      >
        <TextInput
          {...textInputLabelProps}
          formMarginBottom="16px"
          id={`${TOP_LEVEL_ID}__first-name`}
          isRequired
          label="Your first name"
          onChange={(e) => {
            handleTextFieldChange('firstName', e.target.value);
          }}
          value={firstName}
        />

        <TextInput
          {...textInputLabelProps}
          formMarginBottom="16px"
          id={`${TOP_LEVEL_ID}__last-name`}
          isRequired
          label="Your last name"
          onChange={(e) => {
            handleTextFieldChange('lastName', e.target.value);
          }}
          value={lastName}
        />
      </ChakraSimpleGrid>

      <ChakraText
        {...captionSemibold}
        color="mono.70"
        id={`${TOP_LEVEL_ID}__inquiring-about-text`}
        marginBottom="16px"
        paddingLeft="14px"
      >
        Who are you inquiring about?
      </ChakraText>
      <ChakraRadioGroup
        onChange={(value) => {
          handleTextFieldChange('inquiryAbout', value);
        }}
        value={inquiryAbout}
      >
        <ChakraBox
          background="mono.5"
          borderRadius="20px"
          id={`${TOP_LEVEL_ID}__myself__radio-box`}
          padding="20px"
          width="100%"
        >
          <ChakraFlex>
            <Radio
              id={`${TOP_LEVEL_ID}__myself`}
              paddingRight="12px"
              value="myself"
            />
            <ChakraBox>
              <ChakraText
                {...captionSemibold}
                color="mono.100"
                fontSize="16px"
                id={`${TOP_LEVEL_ID}__myself__header`}
                lineHeight="140%"
              >
                Myself
              </ChakraText>
              <ChakraText
                {...overline}
                color={black[70]}
                id={`${TOP_LEVEL_ID}__myself__description`}
              >
                I am seeking treatment for myself.
              </ChakraText>
            </ChakraBox>
          </ChakraFlex>
        </ChakraBox>

        <ChakraBox
          background="mono.5"
          borderRadius="20px"
          id={`${TOP_LEVEL_ID}__someone-else__radio-box`}
          marginTop="8px"
          padding="20px"
          width="100%"
        >
          <ChakraFlex paddingBottom="20px">
            <Radio
              id={`${TOP_LEVEL_ID}__someone-else`}
              paddingRight="12px"
              value={InquiryMode.SOMEONE_ELSE}
            />
            <ChakraBox>
              <ChakraText
                {...captionSemibold}
                color="mono.100"
                fontSize="16px"
                id={`${TOP_LEVEL_ID}__someone-else__header`}
                lineHeight="140%"
              >
                Someone else
              </ChakraText>
              <ChakraText
                {...overline}
                color={black[70]}
                id={`${TOP_LEVEL_ID}__someone-else__description`}
              >
                I am inquiring on behalf of my child, friend, family member,
                loved one, etc.
              </ChakraText>
            </ChakraBox>
          </ChakraFlex>
          <ChakraSimpleGrid
            alignItems="center"
            columns={3}
            display={INQUIRY_REFERRAL_DISPLAY_TYPE}
            spacing="16px"
          >
            <TextInput
              {...textInputLabelProps}
              formMarginBottom="16px"
              id={`${TOP_LEVEL_ID}__patient-first-name`}
              isDisabled={inquiryValueForMySelf}
              isRequired={inquiryValueForOthers}
              label="Patient's first name"
              onChange={(e) => {
                handleTextFieldChange('patientFirstName', e.target.value);
              }}
              value={patientFirstName}
            />
            <TextInput
              {...textInputLabelProps}
              formMarginBottom="16px"
              id={`${TOP_LEVEL_ID}__patient-last-name`}
              isDisabled={inquiryValueForMySelf}
              isRequired={inquiryValueForOthers}
              label="Patient's last name"
              onChange={(e) => {
                handleTextFieldChange('patientLastName', e.target.value);
              }}
              value={patientLastName}
            />
            <AutoComplete
              {...autoCompleteProps}
              endIcon={
                <AngleDown fill={teal[100]} height="15px" width="15px" />
              }
              hideAvatar
              id={`${TOP_LEVEL_ID}__relation`}
              isDisabled={inquiryValueForMySelf}
              isLoading={isRelationsToPatientLoading}
              isRequired={inquiryValueForOthers}
              isTextInput
              marginBottom="4px"
              onSelect={(selected) => {
                handleMenuSelect('relation', selected.value);
              }}
              options={relations ?? []}
              placeholder="I am a..."
              selected={[
                ...(selectedRelationOption ? [selectedRelationOption] : []),
              ]}
              title="Your relation"
              toggleListOnFocus
            />
          </ChakraSimpleGrid>
        </ChakraBox>
      </ChakraRadioGroup>
      <FormButtonBar
        {...captionSemibold}
        containerMarginTop="40px"
        id={TOP_LEVEL_ID}
        isLoading={isLoading}
        isProceedButtonDisabled={isInvalidInquiry}
        isRightIcon={false}
        onClickProceed={handleConfirm}
      />
    </ChakraBox>
  );
};

export default InquiryFormStepOne;
