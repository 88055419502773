import { useState } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraImage,
  ChakraText,
  CircularProgress,
  Close,
  Collapse,
  EquipUIFireflyV1Theme,
  ExpandAlt,
  Minimize,
  Modal,
} from '@equip.health/ui';

import { useChatAttachmentsContext } from '~/lib/context/ChatAttachmentsContext';

const { caption } = EquipUIFireflyV1Theme.typography;
const { black } = EquipUIFireflyV1Theme.colors;

const iconProps: {
  fill: EquipUIFireflyV1Theme.colors;
  height: string;
  width: string;
} = {
  fill: black[60],
  height: '20px',
  width: '20px',
};

const buttonProps: {
  height: string;
  padding: string;
  role: string;
  width: string;
} = {
  height: '40px',
  padding: '10px',
  role: 'button',
  width: '40px',
};

const AttachmentDetailModal = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { setShowChatAttachmentModal, showChatAttachmentModal } =
    useChatAttachmentsContext();
  const { name, url } = showChatAttachmentModal ?? {};

  const viewHeight = isFullScreen ? 'calc(100vh - 140px)' : '65vh';

  const handleClose = () => {
    setIsImageLoaded(false);
    setIsFullScreen(false);
    setShowChatAttachmentModal(null);
  };

  const handleDownloadFile = () => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = name;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Modal
      footerContent={null}
      hideCloseButton
      id="chat-attachment-detail"
      isOpen={Boolean(showChatAttachmentModal)}
      onClose={() => setShowChatAttachmentModal(null)}
      size={isFullScreen ? 'full' : '5xl'}
      title={
        <ChakraFlex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <ChakraText {...caption} color="black.60">
            {name}
          </ChakraText>
          <ChakraFlex>
            <ChakraBox
              {...buttonProps}
              marginRight="8px"
              onClick={handleDownloadFile}
            >
              <Minimize {...iconProps} />
            </ChakraBox>

            <ChakraBox
              {...buttonProps}
              marginRight="8px"
              onClick={() => {
                setIsFullScreen(!isFullScreen);
              }}
            >
              {isFullScreen ? (
                <Collapse {...iconProps} />
              ) : (
                <ExpandAlt {...iconProps} />
              )}
            </ChakraBox>
            <ChakraBox {...buttonProps} onClick={handleClose}>
              <Close {...iconProps} />
            </ChakraBox>
          </ChakraFlex>
        </ChakraFlex>
      }
    >
      <ChakraBox>
        <ChakraBox position="relative">
          <ChakraImage
            alt={name}
            objectFit="contain"
            onLoad={() => {
              setIsImageLoaded(true);
            }}
            src={url}
            style={{ height: viewHeight }}
            visibility={isImageLoaded ? 'visible' : 'hidden'}
            width="100%"
          />
          {!isImageLoaded && (
            <ChakraFlex
              alignItems="center"
              bottom={0}
              justifyContent="center"
              left={0}
              position="absolute"
              right={0}
              top={0}
            >
              <CircularProgress
                id="image-loading"
                isIndeterminate
                size="30px"
              />
            </ChakraFlex>
          )}
        </ChakraBox>
      </ChakraBox>
    </Modal>
  );
};

export default AttachmentDetailModal;
