import {
  ChakraBox,
  FireflyButton,
  Modal,
  Text,
  useBreakpointValue,
} from '@equip.health/ui';

import { ApiCommand } from '~/lib/Api';
import { CHAT_EMERGENCY_CONSENT } from '~/lib/constants';
import urlConstants from '~/lib/constants/url.constants';
import { useApi } from '~/lib/hooks';
import useLocalStorage from '~/lib/hooks/useLocalStorage';
import { ChatEmergencyConsent } from '~/lib/legalDocuments/legalDocuments';

const { consents: postConsentsUrl } = urlConstants.users;

const id = 'chat-emergency-consent-modal';

const ChatEmergencyConsentModal = () => {
  const {
    getValueFromLocalStorage: getChatEmergencyConsentFromLocalStorage,
    setValue: setChatEmergencyConsentInLocalStorage,
  } = useLocalStorage<boolean>(CHAT_EMERGENCY_CONSENT.localStorageKey, false);

  const { sendRequest: postConsentsRequest } = useApi();

  const modalSize = useBreakpointValue({ base: 'full', md: '2xl' });

  const isOpen = !getChatEmergencyConsentFromLocalStorage();
  return (
    <Modal
      hideCloseButton
      isOpen={isOpen}
      size={modalSize}
      title={
        <Text id={`${id}__header__title`} variant="h4">
          Disclaimer
        </Text>
      }
    >
      <ChakraBox>
        <ChakraBox>
          <Text marginBottom="8px" marginTop="8px" variant="h5">
            Your Equip team will not always be able to respond immediately.
          </Text>
          <Text marginBottom="32px">
            You should receive a response within 1-3 business days.
          </Text>
          <Text marginBottom="8px" variant="h5">
            Equip does not handle medical emergencies.
          </Text>
          <Text marginBottom="32px">
            Call 911 immediately if you believe you are experiencing a medical
            emergency. Do not rely on communication through this website for
            urgent medical needs.
          </Text>
        </ChakraBox>
        <ChakraBox marginBottom="8px" width="100%">
          <FireflyButton
            id={`${id}__footer__button`}
            onClick={() => {
              setChatEmergencyConsentInLocalStorage(true);
              postConsentsRequest({
                command: ApiCommand.POST,
                options: {
                  consents: ChatEmergencyConsent,
                },
                url: postConsentsUrl,
              });
            }}
          >
            I understand
          </FireflyButton>
        </ChakraBox>
      </ChakraBox>
    </Modal>
  );
};

export default ChatEmergencyConsentModal;
