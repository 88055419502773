import { ChangeEvent, FC } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraLink,
  ChakraText,
  Checkbox,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

import { isDevEnvironment } from '~/lib/util/EnvUtils';

const { VITE_EQUIP_PRIVACY_POLICY, VITE_EQUIP_TERMS_OF_USE } = import.meta.env;
const { captionSemibold } = EquipUIFireflyV1Theme.typography;

type PrivacyPolicyNoticeProps = {
  isConsentCheckboxSelected?: boolean;
  setIsConsentCheckboxSelected?: (value: boolean) => void;
};

const PrivacyPolicyLink: FC = () => {
  return (
    <>
      <ChakraLink color="teal.100" href={VITE_EQUIP_PRIVACY_POLICY} isExternal>
        Privacy Policy
      </ChakraLink>
      &nbsp;and&nbsp;
      <ChakraLink color="teal.100" href={VITE_EQUIP_TERMS_OF_USE} isExternal>
        Terms of Use
      </ChakraLink>
    </>
  );
};

const PrivacyPolicyNotice: FC<PrivacyPolicyNoticeProps> = ({
  isConsentCheckboxSelected,
  setIsConsentCheckboxSelected,
}) => (
  <ChakraBox marginTop="12px" paddingBottom="16px" width="100%">
    <ChakraText
      {...captionSemibold}
      color="grey"
      id="privacy-policy-text"
      paddingLeft="6px"
      textAlign="left"
    >
      {isDevEnvironment() ? (
        <ChakraFlex alignItems="center" gridGap="12px" width="100%">
          <Checkbox
            id="consent-check-box"
            isChecked={isConsentCheckboxSelected}
            marginBottom={4}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setIsConsentCheckboxSelected(event.target.checked);
            }}
            size="lg"
          />
          <ChakraBox>
            By checking this box and submitting this form you agree to receive
            communication from Equip Health including text messages. Msg & data
            rates may apply. You can reply STOP to unsubscribe from text
            messages or please contact our team directly, call (855) 387-4378 or
            email us at support@equip.health.&nbsp;
            <PrivacyPolicyLink />
          </ChakraBox>
        </ChakraFlex>
      ) : (
        <>
          By submitting this form, I agree to Equip&apos;s&nbsp;
          <PrivacyPolicyLink />
        </>
      )}
    </ChakraText>
  </ChakraBox>
);

export default PrivacyPolicyNotice;
