import { format } from '@equip.health/ui';

import { GOOGLE_ADDRESS_EMPTY_VALUE } from '~/lib/constants';
import { DateFormat } from '~/lib/constants/dateFormat.constants';
import { FormType } from '~/lib/constants/referral.constants';

export const getInitialInquiryFormValues = (): InquiryForm => ({
  additionalNotes: '',
  dateOfBirth: null,
  email: '',
  firstName: '',
  hearAboutEquip: '',
  informationSource: 'Webform',
  inquiryAbout: 'myself',
  isReferrerDetailsRequired: false,
  lastName: '',
  patientAge: undefined,
  patientFirstName: '',
  patientLastName: '',
  patientState: '',
  payor: '',
  payorExternalId: '',
  phoneNumber: '',
  referrerAffiliation: GOOGLE_ADDRESS_EMPTY_VALUE,
  referrerAffiliationNA: false,
  referrerFirstName: '',
  referrerLastName: '',
  relation: '',
  tellUsMore: '',
});

export const validateAgeField = (age: string): string =>
  age !== '0' ? age.replace(/\D/g, '') : '';

export const getDateOfBirth = (
  date: string,
  dateFormat: string = DateFormat.monthNumberDayYear,
): string => {
  if (date) return format(new Date(date), dateFormat);
  return '';
};

export const getErrorMessage = (formType: FormType): string =>
  `There was a problem submitting your ${formType}. Please try again.`;

const isValidAddress = (affiliation: GooglePlacesField): boolean =>
  Object.entries(affiliation).every(([key, val]: [string, string | number]) =>
    ['addressLine1', 'city', 'state', 'country', 'zipCode'].includes(key)
      ? val !== null && val.toString().trim() !== ''
      : true,
  ) ||
  Object.entries(affiliation).every(([key, val]: [string, string | number]) =>
    [
      'name',
      'addressLine1',
      'addressLine2',
      'city',
      'state',
      'country',
      'county',
      'zipCode',
    ].includes(key)
      ? val === null || val.toString().trim() === ''
      : val === undefined || Number.isNaN(val),
  );
export const isValidAddressRequiredFields = (
  affiliation: GooglePlacesField,
  requiredFields: Array<string> = [],
): boolean =>
  Object.entries(affiliation).every(([key, val]: [string, string | number]) =>
    requiredFields.includes(key)
      ? val !== null && val.toString().trim() !== ''
      : true,
  );
export const isValidAffiliation = (
  affiliationNA: boolean,
  affiliation: GooglePlacesField,
): boolean => affiliationNA || isValidAddress(affiliation);

export const isAllAddressFieldsEmpty = (
  addressData: GooglePlacesField,
): boolean =>
  Object.entries(addressData).every(([key, val]: [string, string | number]) =>
    [
      'addressLine1',
      'addressLine2',
      'city',
      'country',
      'county',
      'name',
      'state',
      'zipCode',
      'placeId',
      'description',
    ].includes(key)
      ? val === null || val.toString().trim() === ''
      : val === undefined || Number.isNaN(val),
  );

export const removeEmptyAttributes = (obj) => {
  const result = {};
  for (const key in obj) {
    if (obj[key] && obj[key] !== null && obj[key] !== undefined) {
      result[key] = obj[key];
    }
  }
  return result;
};
