import { setIsMessagesLoading as setIsMessagesLoadingAction } from '~/store/reducers/chat';

import { useAppDispatch, useAppSelector } from '.';

export const useChatSelector = () => {
  return useAppSelector((state) => state.chat);
};

export const useChatDispatch = () => {
  const dispatch = useAppDispatch();

  const setIsMessagesLoading = (enabled: boolean) => {
    dispatch(setIsMessagesLoadingAction(enabled));
  };

  return {
    setIsMessagesLoading,
  };
};
