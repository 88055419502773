import { FC } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraLink,
  ChakraText,
  EquipUITheme,
} from '@equip.health/ui';

import { TelephoneLayout } from './Layout';
import {
  StaticImportantPhoneNumbers,
  StaticLinkDetails,
} from '~/lib/constants/safetyplan.constants';

export const styles = {
  listWrapper: {
    flexDirection: { base: 'column', md: 'row' },
    marginY: '40px',
    gridGap: {
      base: '0px',
      md: '24px',
    },
  },
  listLeftItem: {
    color: 'black.60',
    fontSize: '15px',
    width: { base: '100%', md: '40%' },
  },
  listRightItem: {
    fontSize: '15px',
    marginTop: {
      base: '8px',
      md: '0px',
    },
    width: { base: '100%', md: '60%' },
  },
  telephoneLink: {
    color: 'teal.500',
    textDecoration: 'none !important',
  },
};

const StaticTelephoneSection: FC<{ input: string; title: string }> = ({
  input,
  title,
}) => {
  return (
    <li>
      {title}
      <ChakraText as="span" marginX="4px">
        <TelephoneLayout result={input} />
      </ChakraText>
    </li>
  );
};

const StaticLinkSection: FC<StaticLink> = ({ label, link, subLabel }) => {
  return (
    <ChakraFlex {...styles.listWrapper}>
      <ChakraBox {...styles.listLeftItem}>
        <ChakraText>{label}</ChakraText>
      </ChakraBox>
      <ChakraBox {...styles.listRightItem}>
        {subLabel && <ChakraText>{subLabel}</ChakraText>}
        <ChakraLink color="teal.500" href={link} isExternal>
          {link}
        </ChakraLink>
      </ChakraBox>
    </ChakraFlex>
  );
};
const { h5 } = EquipUITheme.typography;
export const RenderHTMLQuestion: FC<{ isMobile: boolean }> = ({ isMobile }) => {
  return (
    <ChakraBox>
      <ChakraBox>
        <ChakraText {...h5} color="black.90">
          Additional helpful information
        </ChakraText>
      </ChakraBox>
      <ChakraFlex {...styles.listWrapper}>
        <ChakraBox {...styles.listLeftItem}>
          <ChakraText>Hotlines</ChakraText>
        </ChakraBox>
        <ChakraBox {...styles.listRightItem}>
          <ul style={{ marginLeft: isMobile ? '24px' : null }}>
            {StaticImportantPhoneNumbers.map((items) => (
              <StaticTelephoneSection
                input={items.phone}
                key={`static-number-${items.phone}`}
                title={items.title}
              />
            ))}
          </ul>
        </ChakraBox>
      </ChakraFlex>
      {StaticLinkDetails.map((items) => (
        <StaticLinkSection
          key={`static-link-${items.label}`}
          label={items.label}
          link={items.link}
          subLabel={items?.subLabel}
        />
      ))}
    </ChakraBox>
  );
};
