import { FC, useEffect, useMemo, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { ChakraVStack, useDisclosure } from '@equip.health/ui';
import { isNil } from 'lodash';
import { Redirect, useLocation } from 'react-router-dom';

import AuthenticatedPageLoader from '~/components/common/AuthenticatedPageLoader';
import ErrorView from '~/components/common/ErrorView';
import GreetingHero from '~/components/common/GreetingCard';
import LegalDocumentsModal from '~/components/common/modals/LegalDocumentsModal';
import OnboardingModal, {
  OnboardingModalLocalStorageKey,
} from '~/components/common/modals/OnboardingModal';
import GroupClassesOverview from '~/components/groupClasses/GroupClassOverview';
import ProviderList from '~/components/provider-list/ProviderList';
import ScheduleOverview from '~/components/schedule/ScheduleOverview';
import ToDoList from '~/components/todoList';
import PatientVitalsSection from '~/components/vitals/overview/VitalsOverview';
import AuthenticatedPageLayout from '~/layouts/AuthenticatedPageLayout';
import { ApiCommand } from '~/lib/Api';
import {
  AUTH0_ERROR_CONSTANTS,
  MY_EQUIP_V2_DIMENSIONS,
  Path,
  ROUTE_PARAM_CONSTANTS,
} from '~/lib/constants';
import urlConstants from '~/lib/constants/url.constants';
import { TodoListContextProvider } from '~/lib/context/TodoListContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useApi } from '~/lib/hooks';
import useLocalStorage from '~/lib/hooks/useLocalStorage';
import { hideBeacon, showBeacon } from '~/lib/util/beacon.util';
import { isOrHasPatientInTreatment } from '~/lib/util/profile.util';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { PAGE_NAME } from '~/lib/constants/analytics';

const MyEquipPage: FC = () => {
  const {
    isAuthenticated,
    isLoading: isAuth0Loading,
    error: auth0Error,
  } = useAuth0();

  const { search } = useLocation();
  const { trackPageView } = useAnalytics();

  const { isUserProfileLoading, logout, pendingLegalDocuments, userProfile } =
    useUserProfileContext();

  const [isLegalDocumentsModalOpen, setIsLegalDocumentsModalOpen] =
    useState<boolean>(false);

  const {
    isOpen: isOnboardingModalOpen,
    onOpen: onOnboardingModalOpen,
    onClose: onOnboardingModalClose,
  } = useDisclosure();

  const {
    storedValue: hasDisplayedOnboardingModal,
    setValue: setOnboardingModallDisplayed,
  } = useLocalStorage<boolean>(OnboardingModalLocalStorageKey, false);
  const { sendRequest: revokeRefreshToken } = useApi();
  const revokeRefreshTokenOnLogout = () => {
    revokeRefreshToken({
      callback() {
        logout();
      },
      command: ApiCommand.PUT,
      url: urlConstants.users.logout,
    });
  };
  const displayOnboardingModalIfNeeded = () => {
    const isFirstLogin =
      userProfile.isFirstLogin && hasDisplayedOnboardingModal !== true;

    /** Display onboarding modal if contact preferences have not been updated by the user */
    const shouldUpdateContactPreferences =
      userProfile.preferencesLastUpdatedBy !== userProfile.externalId;

    if (isFirstLogin || shouldUpdateContactPreferences) {
      setOnboardingModallDisplayed(true);
      onOnboardingModalOpen();
      hideBeacon();
    }
  };

  const handleOnboardingModalClose = () => {
    showBeacon();
    onOnboardingModalClose();
  };

  useEffect(
    () => {
      if (userProfile && pendingLegalDocuments) {
        if (pendingLegalDocuments.length > 0) {
          setIsLegalDocumentsModalOpen(true);
          hideBeacon();
        } else {
          displayOnboardingModalIfNeeded();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userProfile, pendingLegalDocuments],
  );

  const routeParamErrorDescription = useMemo<string | null>(
    () =>
      URLSearchParams
        ? new URLSearchParams(search)?.get('error_description')
        : '',
    [search],
  );

  const handleLegalDocumentsModalClose = () => {
    setIsLegalDocumentsModalOpen(false);
    showBeacon();
    displayOnboardingModalIfNeeded();
  };

  useEffect(() => {
    trackPageView(PAGE_NAME.home);
  }, []);

  if (
    [auth0Error?.message, routeParamErrorDescription].includes(
      ROUTE_PARAM_CONSTANTS.userIsBlocked,
    )
  ) {
    return (
      <ErrorView
        errorMessage="This account is no longer active. Please reach out to
    support@equip.health for help."
      />
    );
  }

  if (auth0Error) {
    if (auth0Error?.message === AUTH0_ERROR_CONSTANTS.invalidState)
      revokeRefreshTokenOnLogout();
    else return <ErrorView errorMessage={auth0Error.message} />;
  }

  const isDataLoading = isAuth0Loading || isUserProfileLoading;
  const isGroupClassSectionEnabled = isOrHasPatientInTreatment(userProfile);

  if (isDataLoading || isNil(userProfile)) {
    return <AuthenticatedPageLoader />;
  }

  return !isAuthenticated ? (
    <Redirect to={Path.LOGIN_V2} />
  ) : (
    <AuthenticatedPageLayout>
      <OnboardingModal
        isOpen={isOnboardingModalOpen}
        onClose={handleOnboardingModalClose}
      />
      {pendingLegalDocuments && (
        <LegalDocumentsModal
          isOpen={isLegalDocumentsModalOpen}
          onClose={handleLegalDocumentsModalClose}
          pendingLegalDocuments={pendingLegalDocuments}
        />
      )}
      <ChakraVStack
        alignItems="normal"
        gridGap="40px"
        marginX="auto"
        maxWidth={MY_EQUIP_V2_DIMENSIONS.desktopMaxWidth}
        padding={{
          base: '0 24px',
          lg: '0 32px 40px',
        }}
        width="100%"
      >
        <GreetingHero />
        <ScheduleOverview />
        <TodoListContextProvider>
          <ToDoList />
        </TodoListContextProvider>
        {!userProfile.isPatient && <PatientVitalsSection />}
        {isGroupClassSectionEnabled && (
          <GroupClassesOverview currentUser={userProfile} />
        )}
        <ProviderList />
      </ChakraVStack>
    </AuthenticatedPageLayout>
  );
};

export default MyEquipPage;
