import { FC, useEffect, useMemo } from 'react';

import {
  AngleDown,
  AutoComplete,
  AutoCompleteOption,
  ChakraBox,
  ChakraGridItem,
  ChakraSimpleGrid,
  EquipUIFireflyV1Theme,
  GooglePlacesAutoComplete,
  MenuOption,
  TextInput,
} from '@equip.health/ui';

import { INQUIRY_REFERRAL_DISPLAY_TYPE } from '~/lib/constants';
import {
  autoCompleteProps,
  textInputLabelProps,
} from '~/lib/constants/inquiry.constants';
import {
  Enumerations,
  useEnumerationContext,
} from '~/lib/context/EnumerationContext';
import useFeatureFlags from '~/lib/hooks/useFeatureFlags';
import {
  getIsReferrerDataRequired,
  mapInquirySourceToAutocompleteOption,
} from '~/lib/util/inquiries.util';

const GOOGLE_PLACES_API_KEY = import.meta.env.VITE_GOOGLE_PLACES_API_KEY;
const { teal } = EquipUIFireflyV1Theme.colors;

type HearAboutEquipInputProps = {
  enterManuallySelected?: boolean;
  hearAboutValue: string;
  id?: string;
  isInquiry?: boolean;
  isReferrerDetailsRequired?: boolean;
  isValidAddress?: boolean;
  onHearAboutEquipFormChange: (
    key: keyof InquiryForm,
    value: string | ReferrerAffiliation | boolean,
  ) => void;
  paddingBottom?: string;
  referrerAffiliation?: ReferrerAffiliation;
  referrerAffiliationNA?: boolean;
  referrerFirstName?: string;
  referrerLastName?: string;
  tellUsMoreValue: string;
};

const HearAboutEquipInput: FC<HearAboutEquipInputProps> = ({
  enterManuallySelected,
  hearAboutValue,
  id,
  isInquiry,
  isReferrerDetailsRequired,
  isValidAddress,
  onHearAboutEquipFormChange,
  paddingBottom = '28px',
  referrerAffiliation,
  referrerAffiliationNA,
  referrerFirstName,
  referrerLastName,
  tellUsMoreValue,
}) => {
  const TOP_LEVEL_ID = `${id}__hear-about-equip`;

  const {
    fetchEnumerations,
    hearAboutEquipSources,
    equipInquirySources,
    isEquipInquirySourcesLoading,
    isHearAboutEquipSourcesLoading,
    isStatesLoading,
    states,
  } = useEnumerationContext();

  const { isInquiryV2Enabled } = useFeatureFlags();

  useEffect(() => {
    const sourcesEnumerationKey = isInquiryV2Enabled
      ? Enumerations.EQUIP_INQUIRY_SOURCES
      : Enumerations.HEAR_ABOUT_EQUIP_SOURCES;
    fetchEnumerations([sourcesEnumerationKey, Enumerations.STATES]);
  }, []);

  const statesMenuOptions = useMemo<MenuOption[]>(
    () =>
      (states ?? []).map(
        (value: { label: string; externalId: string }): MenuOption => ({
          node: value.label,
          value: value.externalId,
        }),
      ),
    [states],
  );

  // Not adding unit tests for this as it will be removed
  const hearAboutEquips = useMemo<AutoCompleteOption[]>(
    () =>
      (hearAboutEquipSources ?? [])
        .filter((record) => !record.isArchived)
        .map(({ label, id }: EquipSource) => ({
          primaryText: label,
          value: id,
        })),
    [hearAboutEquipSources],
  );

  const equipSourcesAutoCompleteOptions = useMemo<AutoCompleteOption[]>(
    () => mapInquirySourceToAutocompleteOption(equipInquirySources, isInquiry),
    [equipInquirySources],
  );

  const isReferrerDataRequired = useMemo<boolean>(() => {
    if (!isInquiry) {
      return false;
    }

    if (isInquiryV2Enabled) {
      return getIsReferrerDataRequired(
        hearAboutValue,
        equipSourcesAutoCompleteOptions,
      );
    }

    return getIsReferrerDataRequired(hearAboutValue, hearAboutEquips);
  }, [hearAboutValue, isInquiry]);

  useEffect(() => {
    onHearAboutEquipFormChange(
      'isReferrerDetailsRequired',
      isReferrerDataRequired,
    );
  }, [isReferrerDataRequired]);

  const autocompleteOptions = isInquiryV2Enabled
    ? equipSourcesAutoCompleteOptions
    : hearAboutEquips;
  return (
    <ChakraSimpleGrid
      background="rgba(0, 0, 0, 0.03)"
      borderRadius="20px"
      columns={1}
      display={INQUIRY_REFERRAL_DISPLAY_TYPE}
      marginTop="8px"
      padding="20px"
      paddingBottom={paddingBottom}
      spacing="16px"
    >
      <AutoComplete
        {...autoCompleteProps}
        endIcon={<AngleDown fill={teal[100]} height="15px" width="15px" />}
        hideAvatar
        id={TOP_LEVEL_ID}
        isLoading={
          isHearAboutEquipSourcesLoading || isEquipInquirySourcesLoading
        }
        isRequired
        isTextInput
        onSelect={({ value }) =>
          onHearAboutEquipFormChange('hearAboutEquip', value)
        }
        options={autocompleteOptions}
        placeholder="Select..."
        selected={
          hearAboutValue
            ? [
                autocompleteOptions.find(
                  ({ value }) => value === hearAboutValue,
                ),
              ]
            : []
        }
        title="How did you first hear about Equip?"
        toggleListOnFocus
      />
      {hearAboutValue && !isReferrerDetailsRequired && (
        <ChakraBox marginTop={{ '2xl': '-8px', base: '8px' }}>
          <TextInput
            {...textInputLabelProps}
            id={`${TOP_LEVEL_ID}__tell-us-more`}
            label="Tell us more (optional)"
            onChange={(e) => {
              onHearAboutEquipFormChange('tellUsMore', e.target.value);
            }}
            value={tellUsMoreValue}
          />
        </ChakraBox>
      )}
      {isReferrerDetailsRequired && (
        <ChakraSimpleGrid columns={2} spacing="16px">
          <TextInput
            {...textInputLabelProps}
            id={`${TOP_LEVEL_ID}__referrer-first-name`}
            isRequired={isReferrerDetailsRequired}
            label="Their first name"
            onChange={(e) => {
              onHearAboutEquipFormChange('referrerFirstName', e.target.value);
            }}
            value={referrerFirstName}
          />
          <TextInput
            {...textInputLabelProps}
            id={`${TOP_LEVEL_ID}__referrer-last-name`}
            isRequired={isReferrerDetailsRequired}
            label="Their last name"
            onChange={(e) => {
              onHearAboutEquipFormChange('referrerLastName', e.target.value);
            }}
            value={referrerLastName}
          />
          <ChakraGridItem colSpan={2}>
            <GooglePlacesAutoComplete
              {...autoCompleteProps}
              addressData={referrerAffiliation}
              apiKey={GOOGLE_PLACES_API_KEY}
              enterManuallySelected={enterManuallySelected}
              id={`${TOP_LEVEL_ID}__referrer-affiliation`}
              isGetStatesLoading={isStatesLoading}
              isInvalid={!isValidAddress}
              label="Their organization or employer"
              saveAddressData={(data: ReferrerAffiliation) =>
                onHearAboutEquipFormChange('referrerAffiliation', data)
              }
              selectedNA={referrerAffiliationNA}
              setEnterManually={(value: boolean) =>
                onHearAboutEquipFormChange('enterManuallySelected', value)
              }
              statesMenuOptions={statesMenuOptions}
              toggleNA={() =>
                onHearAboutEquipFormChange(
                  'referrerAffiliationNA',
                  !referrerAffiliationNA,
                )
              }
              updateAddress={(value: string, field: string) =>
                onHearAboutEquipFormChange('referrerAffiliation', {
                  ...referrerAffiliation,
                  [field]: value,
                })
              }
            />
          </ChakraGridItem>
        </ChakraSimpleGrid>
      )}
    </ChakraSimpleGrid>
  );
};

export default HearAboutEquipInput;
