import { FC, useMemo, useRef, useState } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraSimpleGrid,
  ChakraText,
  ChakraVStack,
  EquipUIFireflyV1Theme,
  TextAreaInput,
  formatCellPhoneNumber,
} from '@equip.health/ui';
import ReCAPTCHA from 'react-google-recaptcha';

import Callout from '~/components/common/Callout';
import FormBackButton from '~/components/common/FormBackButton';
import FormButtonBar from '~/components/common/FormButtonBar';
import HearAboutEquipInput from '~/components/common/HearAboutEquipInput';
import PrivacyPolicyNotice from '~/components/common/PrivacyPolicyNotice';
import ReviewReferralInformation from '~/components/common/ReviewReferralInformation';
import { ReferralMode } from '~/lib/constants';
import { FormType } from '~/lib/constants/referral.constants';
import { isDevEnvironment } from '~/lib/util/EnvUtils';
import { getErrorMessage } from '~/lib/util/inquiry.util';
import { capitalize } from '~/lib/utils';

import { ContactInfo } from './ContactInfoForm';
import { PatientInfo } from './PatientInfoForm';
import { ReferrerInfo } from './ReferrerInfoForm';

const TOP_LEVEL_ID = 'referral-step-four';

const GOOGLE_RECAPTCHA_KEY = import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY;

const { paragraph, bodySemibold, captionSemibold } =
  EquipUIFireflyV1Theme.typography;

const { black } = EquipUIFireflyV1Theme.colors;

const REVIEW_REFERRAL_INFO_VALUE_BASE_STYLES = {
  ...paragraph,
  color: 'mono.70',
};

const REVIEW_REFERRAL_INFO_KEY_BASE_STYLES = {
  ...captionSemibold,
  color: 'mono.70',
  fontSize: '16px',
};

export type NotesFormInfo = {
  additionalNotes: string;
  hearAboutEquip: string;
  tellUsMore: string;
};

type NotesFormProps = {
  addReferralLoading: boolean;
  contactInfoData: ContactInfo;
  error: string;
  formData: NotesFormInfo;
  patientInfoData: PatientInfo;
  previousStep: () => void;
  referrerData: ReferrerInfo;
  setIsCalloutModalOpen: (value: boolean) => void;
  submitReferral: (recaptchaToken: string) => void;
  updateTextField: (key: keyof InquiryForm, value: string) => void;
};

const NotesForm: FC<NotesFormProps> = ({
  addReferralLoading,
  contactInfoData,
  error,
  formData,
  patientInfoData,
  previousStep,
  referrerData,
  setIsCalloutModalOpen,
  submitReferral,
  updateTextField,
}) => {
  const {
    contact,
    patientPhoneNumber,
    primaryCarerFirstName,
    primaryCarerLastName,
    primaryCarerPhoneNumber,
  } = contactInfoData;
  const { age, dateOfBirth, firstName, lastName, state } = patientInfoData;
  const {
    affiliation,
    affiliationNA,
    email,
    firstName: referrerFirstName,
    lastName: referrerLastName,
    officeNumber,
    officeNumberExtension,
  } = referrerData;

  const [isConsentCheckboxSelected, setIsConsentCheckboxSelected] =
    useState<boolean>(!isDevEnvironment());

  const recaptchaRef = useRef(null);

  const executeRecaptcha = () => {
    recaptchaRef.current?.execute();
  };

  const handleSubmit = (recaptchaToken: string): void => {
    if (!recaptchaToken) return; // TODO better error handling
    submitReferral(recaptchaToken);
  };

  const displayDateOfBirth = (date: string): string => {
    return date ? `DOB ${date}` : '';
  };

  const patientInformation = useMemo<React.ReactElement[]>(() => {
    return [
      `${firstName} ${lastName}`,
      `${displayDateOfBirth(dateOfBirth)}`,
      `${age} years old`,
      `${capitalize(state)}`,
    ].map((value: string) => (
      <ChakraText
        {...REVIEW_REFERRAL_INFO_VALUE_BASE_STYLES}
        id={`${value}__text`}
        key={value}
      >
        {value}
      </ChakraText>
    ));
  }, [firstName, lastName, dateOfBirth, age, state]);

  const patientContactInformation = useMemo<React.ReactElement[]>(() => {
    return [
      {
        value: `Contact the ${
          contact === ReferralMode.PATIENT ? 'patient' : 'parent or guardian'
        }`,
      },
      {
        value: `${
          contact === ReferralMode.PRIMARY_CARER
            ? `${primaryCarerFirstName} ${primaryCarerLastName}`
            : ''
        }`,
      },
      {
        key: 'Mobile',
        value: `${
          contact === ReferralMode.PRIMARY_CARER
            ? formatCellPhoneNumber(primaryCarerPhoneNumber)
            : formatCellPhoneNumber(patientPhoneNumber)
        }`,
      },
    ].map(({ key, value }) => (
      <ChakraFlex key={value}>
        {key && (
          <>
            <ChakraText
              {...REVIEW_REFERRAL_INFO_KEY_BASE_STYLES}
              id={`${value}__key`}
            >
              {key ? `${key}:   ` : ''}
            </ChakraText>
          </>
        )}
        <ChakraText
          {...REVIEW_REFERRAL_INFO_VALUE_BASE_STYLES}
          id={`${value}__text`}
          marginTop="2px"
        >
          {value}
        </ChakraText>
      </ChakraFlex>
    ));
  }, [
    contact,
    primaryCarerFirstName,
    primaryCarerLastName,
    primaryCarerPhoneNumber,
    patientPhoneNumber,
  ]);

  const referrerInformation = useMemo<React.ReactElement[]>(() => {
    return [
      { value: `${referrerFirstName} ${referrerLastName}` },
      { key: 'Email', value: email },
      { key: 'Office', value: formatCellPhoneNumber(officeNumber) },
      { key: 'Office extension', value: officeNumberExtension },
    ].map(({ key, value }) => (
      <ChakraFlex alignItems="center" key={value}>
        {key && (
          <>
            <ChakraText
              {...REVIEW_REFERRAL_INFO_KEY_BASE_STYLES}
              id={`${value}__key`}
            >
              {key ? `${key}:  ` : ''}
            </ChakraText>
          </>
        )}
        <ChakraText
          {...REVIEW_REFERRAL_INFO_VALUE_BASE_STYLES}
          id={`${value}__text`}
        >
          {value}
        </ChakraText>
      </ChakraFlex>
    ));
  }, [referrerFirstName, referrerLastName, email, officeNumber]);

  const addressDetails = useMemo<React.ReactElement[]>(() => {
    const { addressLine1, addressLine2, city, name, state, zipCode, timezone } =
      affiliation;
    return [
      `${name}`,
      `${addressLine1}`,
      `${addressLine2 || ''}`,
      `${city ? city.concat(',') : ''} ${state} ${zipCode}`,
      `${timezone || ''}`,
    ].map((value: string) => (
      <ChakraText
        {...REVIEW_REFERRAL_INFO_VALUE_BASE_STYLES}
        fontSize="16px"
        id={`${value}__text`}
        key={value}
      >
        {value}
      </ChakraText>
    ));
  }, [affiliation]);

  return (
    <ChakraSimpleGrid columns={1} spacing="40px">
      <ChakraBox>
        <FormBackButton
          {...captionSemibold}
          id={TOP_LEVEL_ID}
          onClickBack={previousStep}
        />
        <ChakraText
          {...bodySemibold}
          color="mono.70"
          id={`${TOP_LEVEL_ID}__review-referral`}
          marginLeft="14px"
          paddingBottom="8px"
        >
          Review referral
        </ChakraText>
        <ChakraSimpleGrid
          background="mono.5"
          borderRadius="20px"
          columns={2}
          display={{ sm: 'grid' }}
          id={`${TOP_LEVEL_ID}__review-referral-content`}
          padding="20px"
          width="100%"
        >
          <ChakraVStack
            align="start"
            id={`${TOP_LEVEL_ID}__patient-information`}
            spacing="4px"
          >
            <ReviewReferralInformation
              {...captionSemibold}
              fontColor={black[70]}
              title="Patient information"
            >
              {patientInformation}
            </ReviewReferralInformation>
            <ReviewReferralInformation
              {...captionSemibold}
              fontColor={black[70]}
              marginTop="16px"
              title="Patient contact information"
            >
              {patientContactInformation}
            </ReviewReferralInformation>
          </ChakraVStack>
          <ChakraVStack
            align="start"
            id={`${TOP_LEVEL_ID}__referrer-information`}
            spacing="4px"
          >
            <ReviewReferralInformation
              {...captionSemibold}
              fontColor={black[70]}
              title="Your information"
            >
              {referrerInformation}
            </ReviewReferralInformation>
            {!affiliationNA && (
              <ReviewReferralInformation>
                {addressDetails}
              </ReviewReferralInformation>
            )}
          </ChakraVStack>
        </ChakraSimpleGrid>
      </ChakraBox>
      <HearAboutEquipInput
        hearAboutValue={formData.hearAboutEquip}
        id={TOP_LEVEL_ID}
        onHearAboutEquipFormChange={updateTextField}
        paddingBottom="16px"
        tellUsMoreValue={formData.tellUsMore}
      />
      <TextAreaInput
        {...captionSemibold}
        bodyFontSize="15px"
        bodyFontWeight="400"
        id={`${TOP_LEVEL_ID}__additional-notes`}
        label="Additional notes (optional)"
        onChange={(e) => {
          updateTextField('additionalNotes', e.target.value);
        }}
        value={formData.additionalNotes}
      />
      <Callout onClick={() => setIsCalloutModalOpen(true)} />
      <ChakraBox>
        {isDevEnvironment() && (
          <PrivacyPolicyNotice
            isConsentCheckboxSelected={isConsentCheckboxSelected}
            setIsConsentCheckboxSelected={(value) =>
              setIsConsentCheckboxSelected(value)
            }
          />
        )}
        <FormButtonBar
          {...captionSemibold}
          id={TOP_LEVEL_ID}
          isLoading={addReferralLoading}
          isProceedButtonDisabled={
            !formData.hearAboutEquip || !isConsentCheckboxSelected
          }
          isRightIcon={false}
          onClickProceed={executeRecaptcha}
          proceedButtonText="Send your referral"
        />
        {error && (
          <ChakraText
            {...paragraph}
            color="error"
            id={`${TOP_LEVEL_ID}__save-error`}
            paddingTop="8px"
          >
            {getErrorMessage(FormType.REFERRAL)}
          </ChakraText>
        )}
        {!isDevEnvironment() && <PrivacyPolicyNotice />}
        <ReCAPTCHA
          onChange={(token: string) => {
            handleSubmit(token);
          }}
          ref={recaptchaRef}
          sitekey={GOOGLE_RECAPTCHA_KEY}
          size="invisible"
        />
      </ChakraBox>
    </ChakraSimpleGrid>
  );
};

export default NotesForm;
