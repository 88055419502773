import { FC, useEffect, useMemo, useState } from 'react';

import {
  AngleDown,
  AutoComplete,
  AutoCompleteOption,
  ChakraBox,
  ChakraFlex,
  ChakraFormControl,
  ChakraFormLabel,
  ChakraSimpleGrid,
  Email,
  EquipUIFireflyV1Theme,
  GooglePlacesAutoComplete,
  MenuOption,
  PhoneNumber,
  RadioGroup,
  SelectOption,
  TextInput,
} from '@equip.health/ui';
import { validate as validateEmail } from 'email-validator';

import FormButtonBar from '~/components/common/FormButtonBar';
import {
  MAX_NAME_LENGTH,
  MAX_OFFICE_EXTENSION_LENGTH,
  PHONE_NUMBER_INPUT_PLACEHOLDER,
} from '~/lib/constants';
import {
  autoCompleteProps,
  textInputLabelProps,
} from '~/lib/constants/inquiry.constants';
import {
  TIMEZONE_CODE_KEY,
  referrerRoleOptions,
} from '~/lib/constants/referral.constants';
import {
  Enumerations,
  useEnumerationContext,
} from '~/lib/context/EnumerationContext';
import useFeatureFlags from '~/lib/hooks/useFeatureFlags';
import { isAllAddressFieldsEmpty } from '~/lib/util/inquiry.util';
import { validatePhoneNumber } from '~/lib/utils';

const GOOGLE_PLACES_API_KEY = import.meta.env.VITE_GOOGLE_PLACES_API_KEY;

const { brand, mono } = EquipUIFireflyV1Theme.colors;
const { body, captionSemibold } = EquipUIFireflyV1Theme.typography;

const TOP_LEVEL_ID = 'referrer-info-form';

export type ReferrerAffiliation = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  country: string;
  description: string;
  latitude: number;
  longitude: number;
  name: string;
  placeId: string;
  state: string;
  zipCode: string;
  timezone: string;
};

export type ReferrerInfo = {
  affiliation: ReferrerAffiliation;
  affiliationNA: boolean;
  email: string;
  firstName: string;
  lastName: string;
  officeNumber: string;
  officeNumberExtension: string;
  relationToPatient: string;
  roleType: string;
};

type ReferrerInfoProps = {
  advanceStep: () => void;
  formData: ReferrerInfo;
  updateAffiliationField: (key: string, value: string | number) => void;
  updateField: (
    key: keyof ReferrerInfo,
    value: string | ReferrerAffiliation | boolean,
  ) => void;
};

const ReferrerInfoForm: FC<ReferrerInfoProps> = ({
  advanceStep,
  formData,
  updateAffiliationField,
  updateField,
}) => {
  const {
    fetchEnumerations,
    isTimezonesLoading,
    relationsToPatientReferral,
    states,
    timezones,
    timezonesRaw,
  } = useEnumerationContext();

  const [enterManuallySelected, setEnterManually] = useState<boolean>(false);

  const { isInquiryV2Enabled } = useFeatureFlags();

  useEffect(
    () =>
      fetchEnumerations([
        Enumerations.RELATIONS_TO_PATIENT,
        Enumerations.STATES,
        Enumerations.TIMEZONES_WITHOUT_TOKENS,
      ]),
    [isInquiryV2Enabled],
  );

  const statesMenuOptions = useMemo<MenuOption[]>(
    () =>
      (states ?? []).map(
        ({ label, externalId }: { label: string; externalId: string }) => ({
          node: label,
          value: externalId,
        }),
      ),
    [states],
  );

  const timezoneMenuOptions = useMemo<MenuOption[]>(
    () =>
      (timezones ?? []).map((label) => ({
        node: label,
        value: label,
      })),
    [timezones],
  );

  const {
    affiliation,
    affiliationNA,
    email,
    firstName,
    lastName,
    officeNumber,
    officeNumberExtension,
    relationToPatient,
    roleType,
  } = formData;

  const getRequiredFieldValues = () => {
    if (!enterManuallySelected) {
      return Object.entries(affiliation).some(
        ([key, val]: [string, string | number]) =>
          ['addressLine1', 'city', 'name', 'state', 'zipCode'].includes(key) &&
          !val,
      );
    }
    return Object.entries(affiliation).some(
      ([key, val]: [string, string | number]) =>
        ['state'].includes(key) && !val,
    );
  };

  const formIsInvalid = useMemo<boolean>(
    () =>
      !validateEmail(email) ||
      !validatePhoneNumber(officeNumber) ||
      !firstName?.trim() ||
      !lastName?.trim() ||
      !relationToPatient?.trim() ||
      !roleType ||
      (officeNumberExtension ?? '').length > MAX_OFFICE_EXTENSION_LENGTH ||
      (!affiliationNA && getRequiredFieldValues()),
    [
      affiliation,
      affiliationNA,
      email,
      firstName,
      lastName,
      officeNumber,
      officeNumberExtension,
      relationToPatient,
      roleType,
    ],
  );

  const relationsToPatientOptions = useMemo<AutoCompleteOption[]>(() => {
    if (!isInquiryV2Enabled) {
      return ((relationsToPatientReferral as InquiryRelationToPatient[]) ?? [])
        .filter((item) => item?.subCategoryName === roleType)
        .map(
          ({
            relationToPatientExternalId,
            relationToPatientName,
          }: InquiryRelationToPatient) => ({
            primaryText: relationToPatientName,
            value: relationToPatientExternalId,
          }),
        );
    }
    return ((relationsToPatientReferral as InquiryRelationToPatientV2[]) ?? [])
      .filter((item) => item?.subcategory === roleType)
      .map(({ id, label }: InquiryRelationToPatientV2) => ({
        primaryText: label,
        value: id,
      }));
  }, [relationsToPatientReferral?.length, roleType]);

  const referrerRoles: SelectOption[] = useMemo(
    () =>
      referrerRoleOptions.map(({ title, value }) => ({
        label: (
          <ChakraFlex alignItems="center" {...body}>
            {title}
          </ChakraFlex>
        ),
        value,
      })),
    [referrerRoleOptions?.length],
  );

  const handleReferrerRoleChange = (selected: string): void => {
    updateField('roleType', selected);
  };

  const getRequiredFields = (): string[] => {
    if (enterManuallySelected) return ['state'];
    return ['addressLine1', 'city', 'state', 'name', 'zipCode'];
  };

  return (
    <ChakraBox>
      <ChakraSimpleGrid columns={1} spacing="48px">
        <ChakraSimpleGrid columns={2} display={{ sm: 'grid' }} spacing="16px">
          <TextInput
            {...textInputLabelProps}
            formMarginBottom="16px"
            id={`${TOP_LEVEL_ID}__first-name`}
            isRequired
            label="Your first name"
            onChange={(e) => {
              if (e.target.value.length <= MAX_NAME_LENGTH)
                updateField('firstName', e.target.value);
            }}
            value={firstName}
          />
          <TextInput
            {...textInputLabelProps}
            formMarginBottom="16px"
            id={`${TOP_LEVEL_ID}__last-name`}
            isRequired
            label="Your last name"
            onChange={(e) => {
              if (e.target.value.length <= MAX_NAME_LENGTH)
                updateField('lastName', e.target.value);
            }}
            value={lastName}
          />
        </ChakraSimpleGrid>
        <GooglePlacesAutoComplete
          {...autoCompleteProps}
          addressData={affiliation}
          apiKey={GOOGLE_PLACES_API_KEY}
          enterManuallySelected={enterManuallySelected}
          id={TOP_LEVEL_ID}
          isGetTimezonesLoading={isTimezonesLoading}
          label="Your organization or employer"
          requiredFields={
            isAllAddressFieldsEmpty(affiliation) ? [] : getRequiredFields()
          }
          saveAddressData={(data: ReferrerAffiliation) =>
            updateField('affiliation', data)
          }
          selectedNA={affiliationNA}
          setEnterManually={(value: boolean) => setEnterManually(value)}
          statesMenuOptions={statesMenuOptions}
          timezoneMenuOptions={timezoneMenuOptions}
          toggleNA={() => updateField('affiliationNA', !affiliationNA)}
          updateAddress={(value: string, field: string) => {
            let fieldValue = null;
            if (field === 'timezone') {
              // Find timezone-code from label
              const matchingTimezoneEntry = timezonesRaw.find(
                (timezone) => timezone.label === value,
              );
              fieldValue = matchingTimezoneEntry?.code;
              updateAffiliationField(
                TIMEZONE_CODE_KEY,
                isInquiryV2Enabled ? value : fieldValue,
              );
            }
            updateAffiliationField(field, value);
          }}
        />
        <TextInput
          {...textInputLabelProps}
          errorMessage="Please enter a valid email"
          formMarginBottom="5px"
          id={`${TOP_LEVEL_ID}__email`}
          isInvalid={email && !validateEmail(email)}
          isRequired
          label="Your email"
          onChange={(e) => {
            updateField('email', e.target.value);
          }}
          startIcon={<Email fill={mono[70]} width="20px" />}
          type="email"
          value={email}
        />
        <PhoneNumber
          formMarginBottom="5px"
          id={`${TOP_LEVEL_ID}__office-number`}
          isInvalid={!validatePhoneNumber(officeNumber, true)}
          isRequired
          label="Your office number"
          onChange={(value) => {
            updateField('officeNumber', value || '');
          }}
          placeholder={PHONE_NUMBER_INPUT_PLACEHOLDER}
          value={officeNumber}
        />
        <TextInput
          {...textInputLabelProps}
          errorMessage="Office extension cannot exceed 6 digits"
          formMarginBottom="5px"
          id={`${TOP_LEVEL_ID}__office-extension`}
          isInvalid={
            officeNumberExtension &&
            officeNumberExtension.length > MAX_OFFICE_EXTENSION_LENGTH
          }
          label="Office extension"
          onChange={(e, formattedValue) => {
            updateField(
              'officeNumberExtension',
              formattedValue.replace(/\D/g, ''),
            );
          }}
          type="text"
          value={officeNumberExtension}
        />
        <ChakraFormControl isRequired>
          <ChakraFormLabel {...captionSemibold} color="mono.70" fontSize="12px">
            You are
          </ChakraFormLabel>
          <RadioGroup
            {...body}
            direction="row"
            id={`${TOP_LEVEL_ID}__role`}
            onChange={handleReferrerRoleChange}
            options={referrerRoles}
            overflow="hidden"
            radioOptionBackground="white"
            radioOptionBorderRadius="16px"
            radioOptionColor="mono.70"
            stackBorder="none"
            value={roleType}
          />
        </ChakraFormControl>
        <AutoComplete
          {...autoCompleteProps}
          endIcon={<AngleDown fill={brand[500]} height="15px" width="15px" />}
          hideAvatar
          id={`${TOP_LEVEL_ID}__relation-to-patient`}
          isRequired
          isTextInput
          onSelect={({ primaryText }: AutoCompleteOption) =>
            updateField('relationToPatient', primaryText)
          }
          options={relationsToPatientOptions}
          placeholder="Select..."
          selected={
            relationToPatient
              ? [
                  relationsToPatientOptions.find(
                    ({ primaryText }: AutoCompleteOption) =>
                      primaryText === relationToPatient,
                  ),
                ]
              : []
          }
          title="Relation to patient"
          toggleListOnFocus
        />
      </ChakraSimpleGrid>
      <FormButtonBar
        {...captionSemibold}
        containerMarginTop="40px"
        id={TOP_LEVEL_ID}
        isProceedButtonDisabled={formIsInvalid}
        isRightIcon={false}
        onClickProceed={advanceStep}
      />
    </ChakraBox>
  );
};

export default ReferrerInfoForm;
