import AppointmentUserAvatarCard from '~/components/schedule/BookAppointment/cards/AppointmentUserAvatarCard';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { getAttendeeLabel } from '~/lib/util/schedule/bookAppointment.util';
import { getPreferredFullName } from '~/lib/utils';

interface AppointmentAttendeeCardProps {
  attendee: BookAppointmentAttendee;
  isHoverDisabled?: boolean;
  isRemoveButtonVisible?: boolean;
  isSelected?: boolean;
  onRemove?: (attendee: BookAppointmentAttendee) => void;
  onSelect?: (attendee: BookAppointmentAttendee) => void;
}

const AppointmentAttendeeCard = ({
  attendee,
  isHoverDisabled,
  isRemoveButtonVisible,
  isSelected,
  onRemove,
  onSelect,
}: AppointmentAttendeeCardProps) => {
  const {
    userProfile: { externalId: currentUserId },
  } = useUserProfileContext();

  const { firstName, lastName, chosenName, externalId } = attendee;
  const preferredName = getPreferredFullName(chosenName, firstName, lastName);
  const label = getAttendeeLabel(attendee, currentUserId);

  const handleRemoveAttendee = () => {
    onRemove?.(attendee);
  };

  const handleSelectAttendee = () => {
    onSelect?.(attendee);
  };

  return (
    <AppointmentUserAvatarCard
      id={externalId}
      isHoverDisabled={isHoverDisabled}
      isRemoveButtonVisible={isRemoveButtonVisible}
      isSelected={isSelected}
      onClick={handleSelectAttendee}
      onRemove={handleRemoveAttendee}
      primaryLabel={preferredName}
      secondaryLabel={label}
      userType={attendee.userType}
    />
  );
};

export default AppointmentAttendeeCard;
