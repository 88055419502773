import { FC, ReactNode } from 'react';

import { Modal, useBreakpointValue } from '@equip.health/ui';

import TaskModalBody from '../TaskModalBody';
import TaskModalFooter from '../TaskModalFooter';
import TaskModalTitle from '../TaskModalTitle';

export interface TaskModalProps {
  estimatedDuration: string;
  footerContent?: ReactNode;
  handleClose: () => void;
  taskName: string;
  taskDescription?: string;
  taskSubCategoryName?: string;
  id?: string;
  isOpen: boolean;
}

const TaskModal: FC<TaskModalProps> = ({
  estimatedDuration,
  footerContent,
  handleClose,
  id,
  isOpen,
  taskDescription,
  taskSubCategoryName,
  taskName,
}) => {
  const modalSize: 'full' | '3xl' = useBreakpointValue({
    base: 'full',
    lg: '3xl',
  });

  return (
    <Modal
      footerContent={footerContent}
      id={id ?? `task-detail`}
      isOpen={isOpen}
      onClose={handleClose}
      size={modalSize}
      title={<TaskModalTitle duration={estimatedDuration} title={taskName} />}
    >
      {taskDescription && <TaskModalBody taskDescription={taskDescription} />}
      <TaskModalFooter taskSubCategoryName={taskSubCategoryName} />
    </Modal>
  );
};

export default TaskModal;
