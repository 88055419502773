import { AutoCompleteOption } from '@equip.health/ui';
import { DateTime } from 'luxon';

import {
  OTHER_PAYOR,
  UtmParams,
  inquiryReferrerRequiredSourceOptions,
} from '~/lib/constants/inquiry.constants';

export const getIsReferrerDataRequired = (
  selectedValue: string,
  sources: AutoCompleteOption[],
) => {
  const isReferredDataRequired = inquiryReferrerRequiredSourceOptions.includes(
    [sources?.find(({ value }) => value === selectedValue)][0]?.primaryText,
  );
  return isReferredDataRequired;
};

export const mapInquirySourceToAutocompleteOption = (
  equipInquirySources: EquipInquirySource[],
  isInquiry: boolean,
): AutoCompleteOption[] => {
  const target: EquipInquirerRoleCategoryCode = isInquiry ? 'PLO' : 'REF';

  return (equipInquirySources ?? [])
    .filter(
      (record) =>
        !record.isArchived && record.inquirerRoleCategoryCode === target,
    )
    .map(({ name, id }: EquipInquirySource) => ({
      primaryText: name,
      value: id,
    }));
};

const EquipSourceToRelationToPatientMap = {
  Dietitian: 'Dietitian',
  'Doctor/clinician': 'Doctor',
  'Hospital/medical center (please specify)': 'Hospital',
  Therapist: 'Therapist',
};

export const getRelationToPatient = (
  heardAboutEquip: string,
  equipInquirySources: EquipInquirySource[],
): string => {
  const newArr = equipInquirySources.find((i) => i.id === heardAboutEquip);
  return EquipSourceToRelationToPatientMap[newArr?.name] ?? '';
};

export const generateCoverages = (
  payor: string,
  customPayorName?: string,
): Coverage[] =>
  !payor
    ? []
    : [
        {
          isActive: true,
          payor,
          customPayorName: payor === OTHER_PAYOR ? customPayorName : undefined,
        },
      ];

export const generateUtmParams = (
  utmParams: UtmParams,
): Record<string, ApiOptionsValue> => {
  return !utmParams
    ? {}
    : {
        adTracking: {
          gclId: utmParams.gclid ? utmParams.gclid : utmParams.msclkid || null,
          utmAdgroup: utmParams.utm_adgroup ? utmParams.utm_adgroup : null,
          utmCampaign: utmParams.utm_campaign ? utmParams.utm_campaign : null,
          utmId: utmParams.utm_id ? utmParams.utm_id : null,
          utmMedium: utmParams.utm_medium ? utmParams.utm_medium : null,
          utmSource: utmParams.utm_source ? utmParams.utm_source : null,
          utmTerm: utmParams.utm_term ? utmParams.utm_term : null,
        },
      };
};

export const getCurrentTimezoneCode = (): string => {
  try {
    return DateTime.local().zoneName;
  } catch (error) {
    console.log(error, 'failed to get timezone');
    return '';
  }
};
