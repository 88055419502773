import { FC, useEffect, useRef, useState } from 'react';

import { ChakraBox, FireflyButton } from '@equip.health/ui';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';

import TimeSlotGroup from '../base/TimeSlotGroup';
import {
  MaxAvailabilityRangeErrorView,
  NoAvailabilityErrorView,
} from '~/components/schedule/BookAppointment/base/AvailabilityErrorViews';
import ListLoadingView from '~/components/schedule/BookAppointment/base/LoadingView';
import BookAppointmentStepContainer, {
  ListContainer,
} from '~/components/schedule/BookAppointment/base/StepContainer';
import { ApiCommand } from '~/lib/Api';
import { BOOK_APPOINTMENT_STEP } from '~/lib/constants/bookAppointments';
import urlConstants from '~/lib/constants/url.constants';
import { useBookAppointmentContext } from '~/lib/context/BookAppointmentContext';
import { useApi } from '~/lib/hooks';
import { Recurrence } from '~/lib/util/schedule.util';
import {
  GroupedTimeSlots,
  getRelativeDayFromDate,
  groupTimeSlotsByDay,
} from '~/lib/util/schedule/bookAppointment.util';

const topLevelId = 'book-appointment__availability';

const { getAvailability: getAvailabilityUrl } = urlConstants.schedule;

/*
  Since Equip doesn't support bookings more than two months in advance,
  set the lower bound of the week offset to 6 weeks since the availability range is 2 weeks.

*/
const MAX_WEEK_OFFSET_LOWER_BOUND = 6;
const AVAILABILITY_RANGE_IN_WEEKS = 2;

const BookAppointmentAvailability: FC = () => {
  const {
    setNextStep,
    selectedAppointmentRecurrence,
    selectedAppointmentTypeId,
    patientId,
    supportAttendes,
    providerParticipants,
    isPatientAttending,
    selectedAppointmentType,
    setSelectedTimeSlot,
  } = useBookAppointmentContext();

  const weekOffsetRef = useRef<number>(0);

  const [groupedTimeSlots, setGroupedTimeSlots] = useState<GroupedTimeSlots>();

  const [highlightedTimeSlot, setHighlightedTimeSlot] =
    useState<TimeSlot>(null);

  const handleForwardNavigation = () => {
    setSelectedTimeSlot(highlightedTimeSlot.start, highlightedTimeSlot.end);
    setNextStep(BOOK_APPOINTMENT_STEP.overview);
  };

  const handleBackwardNavigation = () => {
    setNextStep(BOOK_APPOINTMENT_STEP.recurrence);
  };

  const {
    sendRequest: getAvailability,
    loading: isLoading,
    error,
  } = useApi<ScheduleAvailabilityResponse>();

  const selectedProvider = selectedAppointmentType;
  const appointmentType = selectedProvider?.appointmentTypes.find(
    (e) => e.appointmentTypeExtId === selectedAppointmentTypeId,
  );

  const isMaxWeekOffsetReached =
    weekOffsetRef.current === MAX_WEEK_OFFSET_LOWER_BOUND;
  const fetchAvailability = () => {
    const weekOffset = weekOffsetRef.current;
    // Adding a 1 hour buffer to the lower bound if the initial week is the current week.
    const lowerBoundHourOffset = weekOffset === 0 ? 1 : 0;
    const timeNow = DateTime.now();

    const timeNowString = timeNow
      .plus({ hour: lowerBoundHourOffset })
      .plus({ weeks: weekOffset })
      .toUTC()
      .toISO({ includeOffset: false });
    const futureTime = timeNow
      .plus({ weeks: AVAILABILITY_RANGE_IN_WEEKS + weekOffset })
      .toUTC()
      .toISO({ includeOffset: false });

    const formattedCurrentTime = `${timeNowString}Z`;
    const formattedFutureTime = `${futureTime}Z`;

    const { appointmentTypeLabel } = appointmentType;
    const hostExternalId = selectedProvider.providerExternalId;

    if (isNil(appointmentTypeLabel) || isNil(hostExternalId)) {
      return;
    }

    const options: ScheduleAvailabilityPayload = {
      appointmentType: appointmentTypeLabel,
      availabilityEndDateTime: formattedFutureTime,
      availabilityStartDateTime: formattedCurrentTime,
      hostExternalId,
      isPatientAttending,
      patientExternalId: patientId,
      providerParticipants: providerParticipants.map((e) => e.externalId),
      supportAttendeeExternalIds: supportAttendes.map((e) => e.externalId),
    };

    if (selectedAppointmentRecurrence !== Recurrence.DOES_NOT_REPEAT) {
      options.recurrenceFrequency = selectedAppointmentRecurrence.toLowerCase();
    }

    getAvailability({
      url: getAvailabilityUrl,
      command: ApiCommand.POST,
      options,
      callback: (response, statusCode) => {
        if (statusCode === 200) {
          if (response?.available_slots) {
            const responseGroupedTimeSlots = groupTimeSlotsByDay(
              response.available_slots,
            );
            setGroupedTimeSlots({
              ...groupedTimeSlots,
              ...responseGroupedTimeSlots,
            });
          } else {
            fetchNextAvailability();
          }
        }
      },
    });
  };

  useEffect(() => {
    fetchAvailability();
  }, []);

  const handleHighlightedTimeSlot = (timeSlot: TimeSlot) => {
    setHighlightedTimeSlot(timeSlot);
  };

  const fetchNextAvailability = () => {
    if (weekOffsetRef.current === MAX_WEEK_OFFSET_LOWER_BOUND) {
      return;
    }

    weekOffsetRef.current += AVAILABILITY_RANGE_IN_WEEKS;
    fetchAvailability();
  };

  const isEmptyTimeSlots = Object.keys(groupedTimeSlots || {}).length === 0;
  const shouldShowLoadMoreButton = !isEmptyTimeSlots && !isMaxWeekOffsetReached;
  const shouldShowMaxAvailabilityRangeError =
    isMaxWeekOffsetReached && isNil(error) && !isLoading;

  return (
    <BookAppointmentStepContainer
      isBackButtonVisible
      isContinueButtonDisabled={isNil(highlightedTimeSlot)}
      isForwardButtonHidden={!isLoading && isEmptyTimeSlots}
      isLoading={isLoading}
      isTitleHidden={isEmptyTimeSlots}
      onBackwardNavigation={handleBackwardNavigation}
      onForwardNavigation={handleForwardNavigation}
      stepId={topLevelId}
      title="Please choose from one of the following times"
    >
      <ListContainer gridGap="32px">
        {!isLoading && isEmptyTimeSlots && <NoAvailabilityErrorView />}
        {isLoading && isEmptyTimeSlots ? (
          <ListLoadingView listType="options" />
        ) : (
          groupedTimeSlots &&
          Object.keys(groupedTimeSlots).map((date) => {
            return (
              <TimeSlotGroup
                day={getRelativeDayFromDate(date)}
                id={date}
                key={date}
                onSelect={handleHighlightedTimeSlot}
                selectedTimeSlot={highlightedTimeSlot}
                timeSlots={groupedTimeSlots[date]}
              />
            );
          })
        )}
        {shouldShowLoadMoreButton && (
          <ChakraBox>
            <FireflyButton
              id={`${topLevelId}__load-more-button`}
              isLoading={isLoading}
              onClick={fetchNextAvailability}
              size="normal"
              variant="primary"
            >
              Load more availability
            </FireflyButton>
          </ChakraBox>
        )}
        {shouldShowMaxAvailabilityRangeError && (
          <MaxAvailabilityRangeErrorView />
        )}
      </ListContainer>
    </BookAppointmentStepContainer>
  );
};

export default BookAppointmentAvailability;
