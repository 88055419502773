import { GroupChannel, Member } from '@sendbird/chat/groupChannel';

import {
  PROVIDER_TYPE,
  PROVIDER_USER_TYPE,
  USER_ROLES,
} from '~/lib/constants/chat/chat.constants';
import {
  ChannelNameSegments,
  ChatUser,
  MemberMetaData,
} from '~/lib/types/chat';
import { getPreferredProfileFirstName } from '~/lib/utils';

export enum ChannelTypes {
  ADMISSION_SPECIALIST = 'Admissions Specialist',
  DIETITIAN = 'Dietitian',
  FAMILY_MENTOR = 'Family Mentor',
  MEDICAL_TEAM = 'Medical Team',
  OTHER = 'Other',
  PATIENT_AND_SUPPORTS_AND_PROVIDER_TEAM = '+ Supports + Provider Team',
  PEER_MENTOR = 'Peer Mentor',
  PROVIDER_TEAM = 'Provider Team',
  PROXY_AND_ADMISSIONS_TEAM = 'Supports + Admissions Team',
  PROXY_AND_FAMILY_MENTOR = 'Supports + Family Mentor',
  PROXY_AND_THERAPIST = 'Supports + Therapist',
  PROXY_AND_DIETITIAN = 'Supports + Dietitian',
  PROXY_AND_MEDICAL_TEAM = 'Supports + Medical Team',
  PROXY_AND_PSYCH_TEAM = 'Supports + Psychiatry Team',
  PSYCHIATRY_TEAM = 'Psychiatry Team',
  SUPPORTS_AND_TEAM_OR_ROLE = 'Supports + #teamOrRole',
  SUPPORTS_AND_PROVIDER_TEAM = 'Supports + Provider Team',
  THERAPIST = 'Therapist',
  UNKNOWN = 'Equip',
}

export enum ChannelId {
  CCT = 'MAUD_CARETEAM',
  ACT = 'ACT',
  PT_CCT = 'PT_CCT',
  PR_CCT = 'PR_CCT',
  PR_AS = 'PR_AS',
  PR_FAMILY = 'PR_FAMILY',
  PR_THERAPIST = 'PR_THERAPIST',
  PR_DIETITIAN = 'PR_DIETITIAN',
  PR_MEDICAL = 'PR_MEDICAL',
  PR_PSYCH = 'PR_PSYCH',
  PT_PR_CCT = 'PT_PR_CCT',
  PT_ADMIT = 'PT_ADMIT',
  PT_PEER = 'PT_PEER',
  PT_THERAPIST = 'PT_THERAPIST',
  PT_DIETITIAN = 'PT_DIETITIAN',
  PT_MEDICAL = 'PT_MEDICAL',
  PT_PSYCH = 'PT_PSYCH',
  SUP_THERAPIST = 'SUP_THERAPIST',
  SUP_DIETITIAN = 'SUP_DIETITIAN',
  SUP_FAMILY = 'SUP_FAMILY',
  SUP_MEDICAL = 'SUP_MEDICAL',
  SUP_PSYCH = 'SUP_PSYCH',
}

const CHANNEL_CLASSIFICATION = [
  { url: ChannelId.PR_AS, type: ChannelTypes.PROXY_AND_ADMISSIONS_TEAM },
  { url: ChannelId.PR_FAMILY, type: ChannelTypes.PROXY_AND_FAMILY_MENTOR },
  { url: ChannelId.PR_THERAPIST, type: ChannelTypes.PROXY_AND_THERAPIST },
  { url: ChannelId.PR_DIETITIAN, type: ChannelTypes.PROXY_AND_DIETITIAN },
  { url: ChannelId.PR_MEDICAL, type: ChannelTypes.PROXY_AND_MEDICAL_TEAM },
  { url: ChannelId.PR_PSYCH, type: ChannelTypes.PROXY_AND_PSYCH_TEAM },
  { url: ChannelId.PT_CCT, type: ChannelTypes.PROVIDER_TEAM },
  { url: ChannelId.PR_CCT, type: ChannelTypes.PROVIDER_TEAM },
  {
    url: ChannelId.PT_PR_CCT,
    type: ChannelTypes.PATIENT_AND_SUPPORTS_AND_PROVIDER_TEAM,
  },
  { url: ChannelId.PT_ADMIT, type: ChannelTypes.ADMISSION_SPECIALIST },
  { url: ChannelId.PT_DIETITIAN, type: ChannelTypes.DIETITIAN },
  { url: ChannelId.PT_PEER, type: ChannelTypes.PEER_MENTOR },
  { url: ChannelId.PT_THERAPIST, type: ChannelTypes.THERAPIST },
  { url: ChannelId.PT_MEDICAL, type: ChannelTypes.MEDICAL_TEAM },
  { url: ChannelId.PT_PSYCH, type: ChannelTypes.PSYCHIATRY_TEAM },
  { url: ChannelId.SUP_DIETITIAN, type: ChannelTypes.DIETITIAN },
  { url: ChannelId.SUP_FAMILY, type: ChannelTypes.FAMILY_MENTOR },
  { url: ChannelId.SUP_THERAPIST, type: ChannelTypes.THERAPIST },
  { url: ChannelId.SUP_MEDICAL, type: ChannelTypes.MEDICAL_TEAM },
  { url: ChannelId.SUP_PSYCH, type: ChannelTypes.PSYCHIATRY_TEAM },
];

const isChannelUrlStartsWith = (channel: GroupChannel, prefix: ChannelId) =>
  (channel?.url ?? '').startsWith(prefix);

const filterAdmissionSpecialists = (member) =>
  (member as ChatUser)?.metaData?.providerType ===
    PROVIDER_TYPE.ADMISSIONS_SPECIALIST &&
  (member as ChatUser)?.metaData?.userType === PROVIDER_USER_TYPE;

export const filterTaggableMembers = (channel: GroupChannel): Member[] => {
  if (isChannelUrlStartsWith(channel, ChannelId.PR_AS)) {
    return (channel?.members ?? []).filter(filterAdmissionSpecialists);
  }
  return channel?.members ?? [];
};

export const getMembersOfRole = (
  channelMembers: Member[],
  role: USER_ROLES,
): ChatUser[] => {
  if (channelMembers && role) {
    return [
      ...new Set(
        channelMembers
          .map((member) => {
            const memberMetaData = member.metaData as MemberMetaData;

            if (memberMetaData.userType && memberMetaData.userType === role) {
              const { userId, nickname, metaData } = member;
              return {
                metaData,
                nickname,
                userId,
              } as ChatUser;
            }

            return null;
          })
          .filter((x) => x),
      ),
    ];
  }

  return [];
};

export const getChannelType = (channel: GroupChannel): ChannelTypes => {
  if (channel) {
    return (
      CHANNEL_CLASSIFICATION.find((value) => channel.url?.startsWith(value.url))
        ?.type ?? ChannelTypes.UNKNOWN
    );
  }

  return ChannelTypes.UNKNOWN;
};

export const getUserFirstName = (nickname: string) => {
  if (nickname) {
    return nickname.split(' ')[0];
  }

  return '';
};

export const getChannelName = (
  channel: GroupChannel,
  patient: ChatUser,
  isPatientLoggedIn: boolean,
): ChannelNameSegments => {
  const channelType = getChannelType(channel);
  switch (channelType) {
    case ChannelTypes.PATIENT_AND_SUPPORTS_AND_PROVIDER_TEAM: {
      if (!isPatientLoggedIn && patient) {
        const patientName = getUserFirstName(patient.nickname);
        return {
          primaryName: `${patientName} ${channelType}`,
        };
      }

      return {
        primaryName: ChannelTypes.SUPPORTS_AND_PROVIDER_TEAM,
      };
    }

    case ChannelTypes.ADMISSION_SPECIALIST:
    case ChannelTypes.PEER_MENTOR:
    case ChannelTypes.FAMILY_MENTOR:
    case ChannelTypes.THERAPIST:
    case ChannelTypes.DIETITIAN:
    case ChannelTypes.MEDICAL_TEAM:
    case ChannelTypes.PSYCHIATRY_TEAM: {
      const providers = getMembersOfRole(channel.members, USER_ROLES.PROVIDER);
      if (providers?.length === 1) {
        return {
          primaryName: channelType,
          secondaryName: providers[0].nickname,
        };
      }
      if (providers?.length > 1) {
        return {
          primaryName: channelType,
          secondaryName: providers.map((p) => p.nickname).join(', '),
        };
      }
      return {
        primaryName: channelType,
      };
    }

    case ChannelTypes.SUPPORTS_AND_PROVIDER_TEAM: {
      const providers = getMembersOfRole(channel.members, USER_ROLES.PROVIDER);
      if (providers?.length > 0) {
        return {
          primaryName: channelType,
        };
      }
      return {
        primaryName: ChannelTypes.UNKNOWN,
      };
    }

    case ChannelTypes.PROVIDER_TEAM: {
      if (isPatientLoggedIn) {
        return {
          primaryName: ChannelTypes.PROVIDER_TEAM,
        };
      }

      return {
        /* 
          Note: We want to use Supports + Provider Team for the channel name
          so the supports know they are in the right channel.
         */
        primaryName: ChannelTypes.SUPPORTS_AND_PROVIDER_TEAM,
      };
    }
    default:
      return {
        primaryName: channelType,
      };
  }
};

export const supportInChannelMessage =
  'Please note that your Supports are in this channel';

export const getPatientInChannelMessage = (patientName: string): string => {
  if (!patientName) {
    return null;
  }
  return `Please note that ${patientName} is in this channel`;
};

export const getIsRoleInChannel = (
  selectedChannel: GroupChannel,
  role: USER_ROLES,
): boolean => {
  return getMembersOfRole(selectedChannel?.members, role).length > 0;
};

export const getChatMembersWarningMessage = (
  selectedChannel: GroupChannel,
  isCurrentUserPatient: boolean,
  selectedPatientForSupport?: UserProfile,
): string => {
  const isPatientInChannel = getIsRoleInChannel(
    selectedChannel,
    USER_ROLES.PATIENT,
  );

  const isSupportInChannel = getIsRoleInChannel(
    selectedChannel,
    USER_ROLES.SUPPORT,
  );

  if (isCurrentUserPatient && isSupportInChannel) {
    return supportInChannelMessage;
  }
  if (
    !isCurrentUserPatient &&
    isPatientInChannel &&
    selectedPatientForSupport
  ) {
    const preferredName = getPreferredProfileFirstName(
      selectedPatientForSupport,
    );
    return getPatientInChannelMessage(preferredName);
  }
  return null;
};
