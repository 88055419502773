import { useEffect, useMemo, useRef, useState } from 'react';

import { ConnectionHandler } from '@sendbird/chat';
import { GroupChannel, GroupChannelHandler } from '@sendbird/chat/groupChannel';
import { BaseChannel, ReactionEvent } from '@sendbird/chat/lib/__definition';
import {
  AdminMessage,
  BaseMessage,
  FileMessage,
  FileMessageCreateParams,
  PreviousMessageListQuery,
  PreviousMessageListQueryParams,
  UserMessage,
  UserMessageCreateParams,
} from '@sendbird/chat/message';
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';

import {
  ADMIN_MESSAGE_TYPES,
  ChatConnectionState,
  MESSAGES_PER_PAGE,
  PATIENT_ID_METADATA_KEY,
  SPACE,
} from '~/lib/constants/chat/chat.constants';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { useChatContext } from '~/lib/context/ChatContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { ChatIndicators, ChatMessage } from '~/lib/types/chat';
import { getMessagesChannelEventPayload } from '~/lib/util/analytics/messages.utils';
import ChatUtil, {
  filterUserChannels,
  getAdminMessageType,
  getPatientsWithUnreadMessages,
  getUserFromAdminMessage,
} from '~/lib/util/chat/chat.utils';
import { fetchMySendbirdChannels } from '~/lib/util/chat/sendbird.utils';
import { useChatDispatch } from '~/store/hooks/chat';

const UNIQUE_KEY = 'equip';

const loadPreviousMessagesParams: PreviousMessageListQueryParams = {
  includeParentMessageInfo: true,
  includeThreadInfo: true,
  includeReactions: true,
  limit: MESSAGES_PER_PAGE,
  reverse: true,
};

export interface UseChatProps {
  onChannelUpdated?: (updatedChannel: GroupChannel) => void;
  onNewFileMessage?: () => void;
  selectedChannel: GroupChannel;
}

const useChat = ({
  onChannelUpdated,
  onNewFileMessage,
  selectedChannel,
}: UseChatProps) => {
  const { sendBirdInstance } = useChatContext();
  const { track } = useAnalytics();
  const { setIsMessagesLoading } = useChatDispatch();
  const [channels, updateChannels] = useState<GroupChannel[]>(null);
  const [messages, updateChatMessages] = useState<ChatMessage[]>(null);
  const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(true);

  const [chatConnectionState, updateChatConnectionState] =
    useState<ChatConnectionState>(ChatConnectionState.NOT_CONNECTED);
  const [chatIndicators, updateChatIndicators] = useState<ChatIndicators>(null);
  const { userProfile, patientsWithTreatmentTag, isPatientTagsFetched } =
    useUserProfileContext();

  const chatUserId = userProfile?.externalId;

  const channelListRef = useRef<GroupChannel[]>(null);
  const messageListRef = useRef<ChatMessage[]>(null);

  channelListRef.current = channels;
  messageListRef.current = messages;

  const handleError = (errorMessage?: any, onFailure?: VoidFunction) => {
    errorMessage && Sentry.captureException(errorMessage);
    onFailure && onFailure();
    errorMessage && console.error(errorMessage);
  };

  const updateChannelList = (
    channelIndex: number,
    updatedChannel: GroupChannel,
  ) => {
    (channelListRef?.current ?? []).splice(channelIndex, 1, updatedChannel);
    updateChannels([...channelListRef?.current]);
  };

  const updateMessageList = (
    messageIndex: number,
    updatedMessage: ChatMessage,
  ) => {
    (messageListRef?.current ?? []).splice(messageIndex, 1, updatedMessage);
    updateChatMessages([...messageListRef?.current]);
  };

  const connectionHandler = new ConnectionHandler({
    onConnected: () => {
      updateChatConnectionState(ChatConnectionState.CONNECTED);
    },
    onDisconnected: () => {
      updateChatConnectionState(ChatConnectionState.DISCONNECTED);
    },
    onReconnectFailed: () => {
      updateChatConnectionState(ChatConnectionState.DISCONNECTED);
    },
    onReconnectStarted: () => {
      updateChatConnectionState(ChatConnectionState.CONNECTING);
    },
    onReconnectSucceeded: () => {
      updateChatConnectionState(ChatConnectionState.CONNECTED);
    },
  });

  const channelHandler = new GroupChannelHandler({
    onChannelChanged: async (channel) => {
      try {
        await channel.getMetaData([PATIENT_ID_METADATA_KEY]);
      } catch (error) {
        Sentry.captureException(error);
      }

      if (!chatIndicators?.unreadMessage) {
        updateChatIndicators({
          ...chatIndicators,
          patientsWithUnreadMessages: getPatientsWithUnreadMessages(
            channelListRef.current,
          ),
          unreadMessage: (channel as GroupChannel).unreadMessageCount > 0,
        });
      }

      onChannelUpdated?.(channel as GroupChannel);
      const channelIndex = channelListRef?.current.findIndex(
        (chn) => chn.url === channel.url,
      );

      if (channelIndex > -1) {
        updateChannelList(channelIndex, channel as GroupChannel);
      }
    },
    onThreadInfoUpdated: (channel, threadInfoUpdateEvent) => {
      console.log('Thread info updated');
    },
    onChannelDeleted: (channelUrl, channelType) => {
      console.log('channel frozen');
    },
    onChannelMemberCountChanged: (channels) => {
      console.log('channel member count changed');
    },
    onMessageDeleted: (channel, messageId) => {
      track(
        'File Message Removed by Provider',
        getMessagesChannelEventPayload(
          {
            primaryName: channel?.name,
          },
          channel.url,
        ),
      );
      if (selectedChannel.url === channel.url) {
        const updatedMessages = messages?.filter(
          (message) => message?.messageId !== messageId,
        );
        updateChatMessages(updatedMessages);
      }
    },

    onMessageReceived: (channel, message) => {
      const patientsWithUnreadMessages = getPatientsWithUnreadMessages(
        channelListRef.current,
      );

      const updatedChatIndicators = {
        ...chatIndicators,
        patientsWithUnreadMessages,
      };

      if (message.parentMessageId) {
        updatedChatIndicators.newReply = true;
      } else {
        updatedChatIndicators.newMessage = true;
      }
      updateChatIndicators(updatedChatIndicators);

      // Ignore messages received for channels other than currently selected channel
      if (message.channelUrl === selectedChannel.url) {
        const receivedMessage = processMessages([message])[0];
        if (receivedMessage.isFileMessage) {
          onNewFileMessage?.();
        }
        if (message.parentMessageId > 0) {
          const parentMessageIndex = ChatUtil.findMessageIndex(
            messageListRef?.current,
            message.parentMessageId,
          );

          if (parentMessageIndex > -1) {
            const parentMessage = messageListRef?.current?.[parentMessageIndex];

            parentMessage.hasThread = true;
            parentMessage.thread.push(receivedMessage);
            updateMessageList(parentMessageIndex, parentMessage);
          }
        } else {
          updateChatMessages([receivedMessage, ...messageListRef?.current]);
        }
      }
    },

    onReactionUpdated: (channel: BaseChannel, reactionEvent: ReactionEvent) => {
      const messageIndex = messageListRef?.current?.findIndex(
        (x) => x.messageId === reactionEvent.messageId,
      );
      if (messageIndex > -1) {
        messageListRef?.current[messageIndex].sbMessage.applyReactionEvent(
          reactionEvent,
        );
      }
    },
  });

  const addHandlers = () => {
    if (sendBirdInstance) {
      sendBirdInstance.addConnectionHandler(UNIQUE_KEY, connectionHandler);
      sendBirdInstance.groupChannel.addGroupChannelHandler(
        UNIQUE_KEY,
        channelHandler,
      );
    }
  };

  const loadChannels = (onFailure?: VoidFunction) => {
    if (sendBirdInstance) {
      try {
        const handleChannelsLoaded = (foundChannels: GroupChannel[]) => {
          updateChatConnectionState(ChatConnectionState.CHANNELS_LOADED);

          const patientsWithTags = userProfile?.isInTreatment
            ? [userProfile.externalId]
            : patientsWithTreatmentTag;

          const userChannels = filterUserChannels(
            foundChannels,
            chatUserId,
            patientsWithTags,
          );

          updateChannels(userChannels);

          const patientsWithUnreadMessages =
            getPatientsWithUnreadMessages(userChannels);

          updateChatIndicators({
            ...chatIndicators,
            patientsWithUnreadMessages,
            unreadMessage: userChannels.some(
              (channel) => channel.unreadMessageCount > 0,
            ),
          });
        };

        fetchMySendbirdChannels(
          sendBirdInstance.groupChannel,
          handleChannelsLoaded,
        );
      } catch (error) {
        handleError(error, onFailure);
      }
    } else {
      handleError(
        `Load channels called without instantiating SendBird for user: ${chatUserId}`,
        onFailure,
      );
    }
  };

  const processNonAdminMessage = (newMessage: BaseMessage): ChatMessage => {
    const {
      channelUrl,
      createdAt,
      data,
      mentionedUsers,
      messageId,
      parentMessageId,
      sender,
      threadInfo,
    } = newMessage.isFileMessage
        ? (newMessage as FileMessage)
        : (newMessage as UserMessage);

    const calculatedMentionedUserIds = (mentionedUsers ?? [])
      .map((x) => x.userId)
      .concat(sender.userId);

    const chatMessage: ChatMessage = {
      adminMessageType: ADMIN_MESSAGE_TYPES.UNKNOWN,
      allMentionedUserIds: ChatUtil.excludeCurrentUserId(
        calculatedMentionedUserIds,
        userProfile.externalId,
      ),
      channelUrl,
      createdAt,
      data,
      hasThread: !!threadInfo,
      isAdminMessage: false,
      isFileMessage: newMessage.isFileMessage(),
      isMentioned: calculatedMentionedUserIds.includes(chatUserId),
      isNewThread: false,
      isRead:
        selectedChannel?.isReadMessage(newMessage) ||
        sender.userId === chatUserId,
      mentionedUserIds: ChatUtil.excludeCurrentUserId(
        calculatedMentionedUserIds,
        userProfile.externalId,
      ),
      mentionedUsers: mentionedUsers ?? [],
      fileMessage: newMessage.isFileMessage() ? newMessage : null,
      message: newMessage.isFileMessage()
        ? ''
        : (newMessage as UserMessage).message,
      messageId,
      parentMessageId,
      relativeDate: ChatUtil.calculateRelativeDate(createdAt),
      sbMessage: newMessage,
      sender,
      thread: [],
      threadInfo,
      timestamp: DateTime.fromMillis(createdAt).toFormat('hh:mm a'),
    };

    return chatMessage;
  };

  const processAdminMessage = (newMessage: BaseMessage): ChatMessage => {
    const {
      channelUrl,
      createdAt,
      data,
      mentionedUserIds,
      mentionedUsers,
      message,
      messageId,
      parentMessageId,
      threadInfo,
    } = newMessage as AdminMessage;

    const chatMessage: ChatMessage = {
      adminMessageType: getAdminMessageType(message),
      allMentionedUserIds: mentionedUserIds,
      channelUrl,
      createdAt,
      data,
      hasThread: !!threadInfo,
      isAdminMessage: true,
      isFileMessage: false,
      isMentioned: false,
      isNewThread: false,
      isRead: selectedChannel?.isReadMessage(newMessage),
      mentionedUserIds,
      mentionedUsers,
      message,
      messageId,
      parentMessageId,
      relativeDate: ChatUtil.calculateRelativeDate(createdAt),
      sbMessage: newMessage,
      sender: getUserFromAdminMessage(message),
      thread: [],
      threadInfo,
      timestamp: DateTime.fromMillis(createdAt).toFormat('hh:mm a'),
    };

    return chatMessage;
  };

  const mentionsTag = (name) =>
    `<span  style='border-radius: 2px; color: #7F4F0A; height: 19px; background: #FEF6EC;  padding-left:1px; padding-right: 2px;'>${name}</span>`;

  const renderMentions = (messages: ChatMessage[]): ChatMessage[] => {
    return (messages ?? [])?.map((item) => {
      const segments = ChatUtil.getMentionedSegments(item);
      if (segments?.length > 0) {
        const joinedSegments = segments
          .map((segment) => {
            if (segment.startsWith('@')) {
              return mentionsTag(segment);
            }
            return segment;
          })
          .join(SPACE);

        return { ...item, message: joinedSegments };
      }
      return item;
    });
  };

  const processMessages = (newMessages: BaseMessage[]): ChatMessage[] => {
    const processedMessages: ChatMessage[] = [];

    for (const newMessage of newMessages) {
      if (!newMessage.isAdminMessage()) {
        processedMessages.push(processNonAdminMessage(newMessage));
      } else {
        processedMessages.push(processAdminMessage(newMessage));
      }
    }

    return renderMentions(processedMessages);
  };

  const messageQuery = useMemo<PreviousMessageListQuery>(() => {
    updateChatMessages(null);
    return selectedChannel?.createPreviousMessageListQuery(
      loadPreviousMessagesParams,
    );
  }, [selectedChannel?.url]);

  const loadMessages = async (
    onSuccess?: (messages: ChatMessage[], hasMoreMessages: boolean) => void,
  ) => {
    if (messageQuery?.hasNext && !messageQuery.isLoading) {
      setIsMessagesLoading(true);
      await messageQuery
        .load()
        .then((messages) => {
          const processedMessages = processMessages(messages);

          updateChatMessages([
            ...(messageListRef?.current ?? []),
            ...processedMessages,
          ]);
          onSuccess?.(processedMessages, messageQuery?.hasNext);
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        })
        .finally(() => {
          setHasMoreMessages(messageQuery?.hasNext);
          setIsMessagesLoading(false);
        });
    }
  };

  const retrieveChannel = (
    channelUrl: string,
    onSuccess: (retrievedChannel: GroupChannel) => void,
    onFailure: () => void,
  ) => {
    if (sendBirdInstance) {
      sendBirdInstance.groupChannel.getChannel(channelUrl).then(onSuccess);
    } else {
      Sentry.captureException(
        `Retrieve Channel called without SendBird Instance`,
      );
      onFailure();
    }
  };

  const sendUserMessage = (
    messageToSend: ChatMessage,
    onSuccess?: () => void,
  ) => {
    const sendMessageParams: UserMessageCreateParams = {
      data: messageToSend.data,
      mentionedUserIds: messageToSend.mentionedUserIds,
      mentionedUsers: messageToSend.mentionedUsers,
      message: messageToSend.message,
    };

    selectedChannel
      ?.sendUserMessage(sendMessageParams)
      .onSucceeded((sendableMessage) => {
        const processedMessages = processMessages([sendableMessage]);

        updateChatMessages([...processedMessages, ...messageListRef?.current]);

        retrieveChannel(
          sendableMessage.channelUrl,
          (retrievedChannel) => {
            onChannelUpdated?.(retrievedChannel);
          },
          () => { },
        );

        onSuccess?.();
      })
      .onFailed((error) => {
        Sentry.addBreadcrumb({ category: 'chat', message: 'sendUserMessage' });
        Sentry.captureException(error);
      });
  };

  const sendFileMessage = (
    file: File,
    fileName: string,
    fileS3Url: string,
    onSuccess?: () => void,
  ) => {
    const sendFileMessageParams: FileMessageCreateParams = {
      fileName,
      fileSize: file.size,
      fileUrl: fileS3Url,
      mimeType: file.type,
    };

    selectedChannel
      ?.sendFileMessage(sendFileMessageParams)
      .onSucceeded((sendableMessage) => {
        const processedMessages = processMessages([sendableMessage]);
        updateChatMessages([
          ...processedMessages,
          ...(messageListRef?.current ?? []),
        ]);

        onNewFileMessage?.();

        retrieveChannel(
          sendableMessage.channelUrl,
          (retrievedChannel) => {
            onChannelUpdated?.(retrievedChannel);
          },
          () => { },
        );
        onSuccess?.();
      })
      .onFailed((error) => console.log(error));
  };

  const markMessagesAsRead = (
    onSuccess?: () => void,
    onFailure?: () => void,
  ) => {
    selectedChannel
      ?.markAsRead()
      .then(() => {
        updateChatMessages([
          ...messageListRef?.current?.map((message) => {
            // mark replies as read
            const replies = message.thread.map((reply) => ({
              ...reply,
              isRead: true,
            }));

            // Mark message as read
            return { ...message, isRead: true, thread: replies };
          }),
        ]);

        updateChatIndicators({
          ...chatIndicators,
          newMessage: false,
          newReply: false,
          unreadMessage: false,
          patientsWithUnreadMessages: getPatientsWithUnreadMessages(
            channelListRef.current,
          ),
        });
        onSuccess?.();
      })
      .catch((reason) => {
        onFailure?.();
        console.error(reason);
        Sentry.captureException(reason);
      });
  };

  useEffect(() => {
    // Load channels by default if user is a patient.
    // If user is a support, load channels only after patient tags are fetched.

    const shouldLoadChannels = userProfile?.isPatient || isPatientTagsFetched;

    if (userProfile && sendBirdInstance && shouldLoadChannels) {
      loadChannels();
    }
  }, [userProfile, sendBirdInstance, patientsWithTreatmentTag]);

  useEffect(() => {
    if (selectedChannel) {
      addHandlers();
      loadMessages();
    }
  }, [selectedChannel]);

  return {
    channels,
    chatConnectionState,
    chatIndicators,
    hasMoreMessages,
    loadMessages,
    markMessagesAsRead,
    messages,
    sendFileMessage,
    sendUserMessage,
  };
};

export default useChat;
