import { TaskStatusLabel } from '~/lib/constants/tasks.constants';

export const getAnalyticsValueForTaskStatus = (
  taskStatus: string,
  isCompleted: boolean,
): string => {
  if (isCompleted && taskStatus) {
    return 'Completed';
  }

  switch (taskStatus) {
    case TaskStatusLabel.TO_DO:
      return 'Not Started';
    case TaskStatusLabel.IN_PROGRESS:
      return 'In Progress';
    case TaskStatusLabel.DONE:
      return 'Completed';
    case TaskStatusLabel.VOID:
      return 'Void';
    case TaskStatusLabel.CLOSED:
      return 'Closed';
    default:
      return undefined;
  }
};
