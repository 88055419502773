import { FC } from 'react';

const PDFIcon: FC<BaseSVG> = ({ height = '36', width = '36' }) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 36 36"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#FA0F00" height="36" rx="8" width="36" />
    <path
      d="M26.6132 20.2899C25.2686 18.852 21.5971 19.4378 20.7181 19.5443C19.4254 18.2661 18.5464 16.7218 18.2361 16.1893C18.7015 14.7514 19.0116 13.3135 19.0634 11.7692C19.0634 10.4378 18.5464 9 17.0984 9C16.5813 9 16.1158 9.31943 15.8572 9.74545C15.2368 10.8639 15.4953 13.1006 16.4778 15.3906C15.9091 17.0414 15.3919 18.639 13.944 21.4616C12.4444 22.1005 9.29003 23.5917 9.03144 25.1894C8.92805 25.6686 9.08314 26.1481 9.44511 26.5207C9.80708 26.8403 10.2724 27 10.7379 27C12.6512 27 14.5127 24.2842 15.8056 21.994C16.8915 21.6213 18.598 21.0887 20.3044 20.7691C22.3211 22.5798 24.0792 22.846 25.0101 22.846C26.2511 22.846 26.7166 22.3135 26.8716 21.8341C27.1301 21.3018 26.975 20.7159 26.6132 20.2899ZM25.3203 21.1953C25.2686 21.5681 24.8033 21.9407 23.9759 21.7278C22.9933 21.4616 22.1143 20.9822 21.3387 20.3431C22.0109 20.2365 23.5104 20.0768 24.5964 20.2899C25.0101 20.3964 25.4238 20.6627 25.3203 21.1953ZM16.6847 10.2247C16.7881 10.065 16.9433 9.95852 17.0984 9.95852C17.5637 9.95852 17.6671 10.5443 17.6671 11.0236C17.6154 12.1421 17.4085 13.2603 17.0467 14.3255C16.2709 12.1952 16.4261 10.704 16.6847 10.2247ZM16.5813 20.5562C16.995 19.7041 17.5638 18.2129 17.7706 17.5738C18.236 18.3725 19.0116 19.3312 19.4254 19.7573C19.4254 19.8106 17.8223 20.1301 16.5813 20.5562ZM13.5303 22.6864C12.341 24.7101 11.0999 25.9881 10.4276 25.9881C10.3243 25.9881 10.2208 25.9349 10.1174 25.8816C9.96217 25.7751 9.91047 25.6153 9.96217 25.4024C10.1174 24.6568 11.4619 23.6449 13.5303 22.6864Z"
      fill="white"
    />
  </svg>
);

export default PDFIcon;
