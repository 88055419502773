import {
  AddressData,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from '@equip.health/ui';

import StringUtil from './util/string.util';
import { VITE_MYEQUIP_APP_VERSION } from './constants/env';

// eslint-disable-next-line import/prefer-default-export
export const capitalize = (s: string): string =>
  s ? `${s[0].toUpperCase()}${s.slice(1)}` : '';

/**
 * @function isMobileDevice
 * Checks user agent and max touch points to detect mobile device
 */
export const isMobileDevice = (): boolean => {
  const { maxTouchPoints, userAgent } = navigator ?? {};

  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    ) ||
    (/Mac/i.test(userAgent) && maxTouchPoints > 2)
  );
};

export const joinArray = (stringArray: string[]): string => {
  if (stringArray.length === 1) return stringArray[0];
  if (stringArray.length === 2) return stringArray.join(' and ');
  if (stringArray.length > 2)
    return `${stringArray.slice(0, -1).join(', ')}, and ${stringArray.slice(
      -1,
    )}`;

  return '';
};

export const getName = (name: string): string => (name ?? '').trim();

export const getPreferredFirstName = (
  chosenName: string,
  firstName: string,
): string => {
  const chosen = getName(chosenName);
  const first = getName(firstName);
  return chosen.length ? chosen : first;
};

export const getPreferredProfileFirstName = (
  userProfile: UserProfile,
): string => {
  const { chosenName, firstName } = userProfile;
  const chosen = getName(chosenName);
  const first = getName(firstName);
  return chosen.length ? chosen : first;
};

export const getPreferredFullName = (
  chosenName: string,
  firstName: string,
  lastName: string,
): string => {
  const first = getPreferredFirstName(chosenName, firstName);
  const last = getName(lastName);
  return `${first} ${last}`.trim();
};

export const getPreferredProfileFullName = (
  userProfile: UserProfile,
): string => {
  const first = getPreferredProfileFirstName(userProfile);
  const last = getName(userProfile.firstName);
  return `${first} ${last}`.trim();
};

export const generateAddressText = (
  address: AddressData,
  timezoneString: string,
): string => {
  const {
    name,
    addressLine1,
    addressLine2,
    city,
    county,
    state,
    zip,
    timezone,
  } = address ?? {};

  let timeZoneStr = timezone || timezoneString || '';
  timeZoneStr = '\n' + timeZoneStr;

  const addressArray = [
    `${name ?? ''}`,
    `${addressLine1 || ''}`,
    `${addressLine2 || ''}`,
    `${city || ''}`,
    `${county || ''}`,
    `${state || ''}`,
    `${zip || ''}`,
    `${timeZoneStr}`,
  ];

  const addressStr = (addressArray ?? []).length
    ? addressArray
        .filter((str) => Boolean(str.length))
        .map((e, index) => (index === 0 ? e : '\n' + e))
        .join(' ')
    : '';

  return addressStr;
};

export const joinLanguagesList = (languages: string[]): string => {
  return StringUtil.joinStringList(languages);
};

export const getRelationshipToPatients = (patient: UserProfile): string => {
  if ((patient?.linkedPatients ?? []).length) {
    return joinArray(
      patient.linkedPatients.map((patient) => {
        const { firstName, chosenName, relationToPatient } = patient;

        const preferredFirstName = getPreferredFirstName(chosenName, firstName);

        return `${relationToPatient} to ${preferredFirstName}`;
      }),
    );
  }

  return null;
};

export const joinPatientSupportList = (
  supportList: SupportProfile[],
): string => {
  const supportCount = supportList?.length || 0;

  if (supportCount === 0) {
    return null;
  }

  const nameList = supportList.map((support) => {
    const preferredFirstName = getPreferredFirstName(
      support.chosenName,
      support.firstName,
    );
    const supportName = `${preferredFirstName} (${support.relationToPatient.toLocaleLowerCase()})`;

    return supportName;
  });

  return StringUtil.joinStringList(nameList);
};

export const parsePhoneNumberWithDefaultCountry = (phoneNumber = ''): string =>
  phoneNumber ? parsePhoneNumber(phoneNumber ?? '', 'US')?.number : '';

export const validateActiveTabsArray = (activeTabs: number[]) => {
  if (Array.isArray(activeTabs))
    return activeTabs.filter((tab) => tab && !isNaN(tab));
  return [];
};

export const validatePhoneNumber = (
  phoneNumber: string,
  allowEmpty = false,
): boolean =>
  (allowEmpty && (phoneNumber ?? '') === '') ||
  isPossiblePhoneNumber(phoneNumber ?? '', 'US');

export const getAppVersion = () => {
  return `Version ${VITE_MYEQUIP_APP_VERSION.replaceAll('v', '')}`;
};
