import { FC, ReactElement, createContext, useContext } from 'react';

import useSchedulePermissions, {
  UseSchedulePermissionsState,
} from '~/lib/hooks/useSchedulePermissions';

const SchedulingContext = createContext<
  UseSchedulePermissionsState | undefined
>(undefined);

interface SchedulePermissionsContextProviderProps {
  children: ReactElement | ReactElement[];
}

const SchedulePermissionsContextProvider: FC<
  SchedulePermissionsContextProviderProps
> = ({ children }: SchedulePermissionsContextProviderProps) => {
  const value = useSchedulePermissions();

  return (
    <SchedulingContext.Provider value={value}>
      {children}
    </SchedulingContext.Provider>
  );
};

const useSchedulePermissionsContext = (): UseSchedulePermissionsState => {
  const context = useContext(SchedulingContext);

  if (context === undefined) {
    throw new Error(
      'useSchedulePermissionsContext must be used within a SchedulePermissionsContextProvider',
    );
  }

  return context;
};

export { SchedulePermissionsContextProvider, useSchedulePermissionsContext };
