import { FC, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { ChakraBox } from '@equip.health/ui';
import { isNil } from 'lodash';
import { Redirect } from 'react-router-dom';

import AuthenticatedPageLoader from '~/components/common/AuthenticatedPageLoader';
import {
  PatientTabItemProps,
  PatientTabSelector,
  getProfilesToTabs,
} from '~/components/common/PatientTabSelector';
import SectionTitle from '~/components/common/SectionTitle';
import ProfileInfoView from '~/components/common/users/ProfilePage/ProfileInfoView';
import AuthenticatedPageLayout from '~/layouts/AuthenticatedPageLayout';
import { MY_EQUIP_V2_DIMENSIONS, Path } from '~/lib/constants';
import { useProfilePageContext } from '~/lib/context/ProfilePageContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { sortUserProfileByAgeASC } from '~/lib/util/profile.util';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { EVENT, PAGE_NAME } from '~/lib/constants/analytics';

const ProfilePage: FC = () => {
  const { isAuthenticated, isLoading: isAuth0Loading } = useAuth0();
  const { trackPageView, track } = useAnalytics();

  const {
    activeProfile,
    profileEditViewPermission,
    setActiveProfile,
    setProxyPatients,
    setUserProfile,
    proxyPatients,
  } = useProfilePageContext();

  const [userTabs, setUserTabs] = useState<PatientTabItemProps[]>([]);

  const {
    isFullProfileInfoFetched: isAllProfileInfoFetched,
    userProfile,
    fetchFullUserProfile,
    isFullProfileInfoLoading: isAllProfileInfoLoading,
    isBasicInfoLoading: isUserProfileLoading,
  } = useUserProfileContext();
  const isDataLoading = isAuth0Loading || isUserProfileLoading;

  useEffect(() => {
    trackPageView(PAGE_NAME.profile);
  }, []);
  useEffect(fetchFullUserProfile, []);

  useEffect(() => {
    if (isAllProfileInfoFetched && userProfile) {
      setUserProfile(userProfile);
      setActiveProfile(userProfile);
    }
  }, [isAllProfileInfoFetched]);

  useEffect(() => {
    const proxyPatients = (userProfile?.linkedPatients ?? [])
      .filter((patient) => patient.isProxy)
      .sort(sortUserProfileByAgeASC);

    setProxyPatients(proxyPatients);
    setUserTabs(getProfilesToTabs(userProfile, proxyPatients));
  }, [userProfile?.linkedPatients]);

  if (isAllProfileInfoLoading || isDataLoading || isNil(userProfile)) {
    return <AuthenticatedPageLoader />;
  }

  const Page: FC = () =>
    !isAuthenticated ? (
      <Redirect to={Path.LOGIN_V2} />
    ) : (
      <AuthenticatedPageLayout>
        <ChakraBox
          maxWidth={MY_EQUIP_V2_DIMENSIONS.desktopPageMaxWidth}
          padding={['40px 24px']}
          width="100%"
        >
          {userProfile?.isPatient ? (
            <SectionTitle id="profile" title="Profile" type="page" />
          ) : (
            <PatientTabSelector
              id="profile"
              initialPatientId={activeProfile?.externalId}
              onPatientClick={(userId) => {
                track(EVENT.ProfilePatientSelectorClick);
                if (userId === userProfile?.externalId) {
                  setActiveProfile(userProfile);
                  return;
                }

                setActiveProfile(
                  proxyPatients.find((p) => p.externalId === userId),
                );
              }}
              patientTabs={userTabs}
              title="Profiles"
              type="page"
            />
          )}
          {Boolean(activeProfile) && Boolean(profileEditViewPermission) && (
            <ProfileInfoView />
          )}
        </ChakraBox>
      </AuthenticatedPageLayout>
    );

  return <Page />;
};

export default ProfilePage;
