import { PropsWithChildren } from 'react';

import { ChakraBox, ChakraFlex } from '@equip.health/ui';

import BookAppointmentModalFooter, {
  ModalFooterProps,
} from '~/components/schedule/BookAppointment/base/ModalFooter';
import StepTitle from '~/components/schedule/BookAppointment/base/StepTitle';

interface BookAppointmentStepContainerProps extends ModalFooterProps {
  isTitleHidden?: boolean;
  title?: string;
}

const BookAppointmentStepContainer = ({
  auxButton,
  children,
  isBackButtonVisible,
  isContinueButtonDisabled,
  isForwardButtonHidden,
  isLastStep,
  isLoading,
  isTitleHidden,
  onBackwardNavigation,
  onForwardNavigation,
  stepId,
  title,
}: PropsWithChildren<BookAppointmentStepContainerProps>) => {
  return (
    <>
      <ChakraBox
        height={{ base: '100%', md: '50vh', lg: '55vh' }}
        id={`${stepId}__container`}
        overflow="scroll"
        paddingBottom="12px"
        paddingTop="24px"
      >
        {!isTitleHidden && <StepTitle stepId={stepId} title={title} />}
        {children}
      </ChakraBox>
      <BookAppointmentModalFooter
        auxButton={auxButton}
        isBackButtonVisible={isBackButtonVisible}
        isContinueButtonDisabled={isContinueButtonDisabled}
        isForwardButtonHidden={isForwardButtonHidden}
        isLastStep={isLastStep}
        isLoading={isLoading}
        onBackwardNavigation={onBackwardNavigation}
        onForwardNavigation={onForwardNavigation}
        stepId={stepId}
      />
    </>
  );
};

export const ListContainer = ({
  children,
  gridGap,
  marginTop,
}: PropsWithChildren<{ gridGap?: string; marginTop?: string }>) => {
  return (
    <ChakraFlex
      flexDirection="column"
      gridGap={gridGap || '8px'}
      marginTop={marginTop || '20px'}
    >
      {children}
    </ChakraFlex>
  );
};

export default BookAppointmentStepContainer;
