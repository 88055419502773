import { FC, useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  ChakraBox,
  ChakraFlex,
  ChakraLink,
  ChakraText,
  EquipUIFireflyV1Theme,
  PhoneCall,
} from '@equip.health/ui';
import { Link, useHistory } from 'react-router-dom';

import EquipBranding from '~/assets/svg/EquipBranding';
import { OnboardingModalLocalStorageKey } from '~/components/common/modals/OnboardingModal';
import {
  ADMISSIONS_PHONE_NUMBER,
  EquipAppPath,
  INQUIRY_REFERRAL_HORIZONTAL_MARGINS,
  Path,
} from '~/lib/constants';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { useTabletBreakpoint } from '~/lib/hooks/useBreakpoint';
import { FILE_MESSAGES_CACHE_KEY } from '~/lib/hooks/useChatAttachments';

const { VITE_EQUIP_WEBSITE_ADDRESS } = import.meta.env;

const { teal } = EquipUIFireflyV1Theme.colors;
const { captionSemibold } = EquipUIFireflyV1Theme.typography;

type NavbarProps = {
  id?: string;
};

const EquipAppLogin = {
  id: 'v2__equip-app-login',
  path: Path.LOGIN_V2,
  title: 'Log in',
};

const Navbar: FC<NavbarProps> = ({ id }: NavbarProps) => {
  const isSmallBreakpoint = useTabletBreakpoint();
  const history = useHistory();
  const { track } = useAnalytics();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handleAdmissionsLogin = useCallback(() => {
    track('Login Clicked', {
      'Is Authenticated': isAuthenticated,
    });

    if (isAuthenticated) {
      history.push(EquipAppPath.HOME);
    } else {
      localStorage.removeItem(OnboardingModalLocalStorageKey);
      localStorage.removeItem(FILE_MESSAGES_CACHE_KEY);

      loginWithRedirect({
        authorizationParams: {
          redirectUri: `${window.location.origin}${EquipAppPath.HOME}`,
        },
      });
    }
  }, [isAuthenticated]);

  const menuButtonProps = {
    ...captionSemibold,
    size: 'md',
    variant: 'outline',
  };

  return (
    <ChakraFlex
      alignItems="center"
      id={`${id}__logo-section`}
      justifyContent="space-between"
      paddingLeft={INQUIRY_REFERRAL_HORIZONTAL_MARGINS}
      paddingRight={INQUIRY_REFERRAL_HORIZONTAL_MARGINS}
      paddingTop={{
        base: '16px',
        md: '40px',
      }}
    >
      <ChakraBox>
        <Link
          id={`${id}__logo-image`}
          to={{ pathname: VITE_EQUIP_WEBSITE_ADDRESS }}
        >
          <EquipBranding />
        </Link>
      </ChakraBox>
      <ChakraFlex
        alignItems="center"
        gridGap={{
          base: '16px',
          md: '24px',
        }}
      >
        {!isSmallBreakpoint && (
          <ChakraText {...captionSemibold} align="right" color="teal.100">
            <Link
              id={`${id}__return-to-website-link`}
              to={{ pathname: VITE_EQUIP_WEBSITE_ADDRESS }}
            >
              Return to website
            </Link>
          </ChakraText>
        )}
        <ChakraLink
          {...captionSemibold}
          color="teal.100"
          display="flex"
          href={`tel:${ADMISSIONS_PHONE_NUMBER}`}
          id={`${id}__contact-us-phone-link`}
          justifyContent="center"
          sx={{ textDecoration: 'none !important' }}
        >
          <PhoneCall fill={teal[100]} height="16px" />
          {!isSmallBreakpoint && ADMISSIONS_PHONE_NUMBER}
        </ChakraLink>

        <Button
          {...menuButtonProps}
          id={`${id}_${EquipAppLogin.id}`}
          onClick={handleAdmissionsLogin}
        >
          {EquipAppLogin.title}
        </Button>
      </ChakraFlex>
    </ChakraFlex>
  );
};

export default Navbar;
