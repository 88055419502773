export const getNavTitleColor = (
  isSelected: boolean,
  isHighlighted: boolean,
  isPrimary = false,
): string => {
  if (isSelected) {
    return isPrimary ? 'colors.blue.link' : 'primary.100';
  }

  if (isHighlighted) {
    return isPrimary ? 'colors.blue.link' : 'black.90';
  }

  return isPrimary ? 'colors.black.90' : 'black.60';
};

export const getNavItemColor = (
  isSelected: boolean,
  isHighlighted: boolean,
  isPrimary = false,
): string => {
  if (isSelected || isHighlighted) {
    return isPrimary ? 'secondary.10' : 'colors.primary.100';
  }

  return isPrimary ? 'transparent' : 'colors.black.90';
};

export const DEFAULT_TIMEOUT_IN_MINUTES = 30;

export const parseTimeoutValue = (envTimeOutValue: string): number => {
  const parsedValue = parseInt(envTimeOutValue, 10);

  return !Number.isInteger(parsedValue) ||
    parsedValue <= 0 ||
    !Number.isFinite(parsedValue)
    ? DEFAULT_TIMEOUT_IN_MINUTES
    : parsedValue;
};

export const buildEquipSiteLink = (baseUrl: string, path: string): string => {
  return `${baseUrl}${baseUrl?.endsWith('/') ? '' : '/'}${path}`;
};
