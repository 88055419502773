import { FC } from 'react';

import {
  Avatar,
  ChakraBox,
  ChakraDivider,
  ChakraFlex,
  ChakraMenu,
  ChakraMenuButton,
  ChakraMenuItem,
  ChakraMenuList,
  ChevronDownIcon,
  FAIconName,
  Icon,
  Text,
} from '@equip.health/ui';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';

import { ApiCommand } from '~/lib/Api';
import { EquipAppPath } from '~/lib/constants';
import urlConstants from '~/lib/constants/url.constants';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useApi } from '~/lib/hooks';
import { FILE_MESSAGES_CACHE_KEY } from '~/lib/hooks/useChatAttachments';
import { getPreferredFirstName, getPreferredFullName } from '~/lib/utils';
import { EVENT } from '~/lib/constants/analytics';

interface AuthenticatedMenuProps {
  id?: string;
  isMobile?: boolean;
}

const AuthenticatedMenu: FC<AuthenticatedMenuProps> = ({
  id,
  isMobile,
}: AuthenticatedMenuProps) => {
  const history = useHistory();
  const { track } = useAnalytics();
  const {
    logout,
    patientsCompletedSafetyPlan,
    isSafetyPlanEnabled,
    updateIsSafetyPlanOpen,
    updateIsLinkedPatientModalOpen,
    userProfile,
  } = useUserProfileContext();
  const { sendRequest: revokeRefreshToken } = useApi();
  const revokeRefreshTokenOnLogout = () => {
    revokeRefreshToken({
      callback() {
        logout();
        track('Logout Clicked');
      },
      command: ApiCommand.PUT,
      url: urlConstants.users.logout,
    });
  };
  const handleLogout = () => {
    Sentry.setUser(null);
    localStorage.removeItem(FILE_MESSAGES_CACHE_KEY);
    revokeRefreshTokenOnLogout();
  };

  const preferredFullName = getPreferredFullName(
    userProfile?.chosenName,
    userProfile?.firstName,
    userProfile?.lastName,
  );

  const preferredFirstName = getPreferredFirstName(
    userProfile?.chosenName,
    userProfile?.firstName,
  );

  const safetyPlanHandler = () => {
    track(EVENT.SafetyPlanClick, {
      Source: 'Nav Menu',
    });

    if (patientsCompletedSafetyPlan?.length) {
      updateIsLinkedPatientModalOpen(true);
    } else {
      updateIsSafetyPlanOpen(true);
    }
  };

  const handleProfileClick = () => {
    track(EVENT.ProfilePageClick, {
      Source: 'Nav Menu',
    });
    history.push(EquipAppPath.PROFILE);
  };

  return (
    <ChakraMenu id={id}>
      <ChakraMenuButton>
        <ChakraFlex alignItems="center" flexDirection="row">
          <Avatar name={preferredFullName} size="sm" userType="patient" />
          {!isMobile && (
            <ChakraBox marginLeft="12px">
              <ChakraFlex alignItems="center" flexDirection="row">
                <Text id={`${id}__name`} variant="bodyMedium">
                  {preferredFirstName}
                </Text>
                <ChevronDownIcon color="black" />
              </ChakraFlex>
            </ChakraBox>
          )}
        </ChakraFlex>
      </ChakraMenuButton>
      <ChakraMenuList>
        <ChakraMenuItem onClick={handleProfileClick}>
          <Icon name={FAIconName.userRegular} />
          <Text id={`${id}__profile`} ml={2} variant="body">
            Profile
          </Text>
        </ChakraMenuItem>
        {isSafetyPlanEnabled && (
          <ChakraMenuItem onClick={safetyPlanHandler}>
            <Icon name={FAIconName.shieldRegular} />
            <Text id={`${id}__profile`} ml={2} variant="body">
              Safety plan
            </Text>
          </ChakraMenuItem>
        )}
        <ChakraDivider />
        <ChakraMenuItem onClick={handleLogout}>
          <Icon name={FAIconName.signOutRegular} />
          <Text id={`${id}__logout`} ml={2} variant="body">
            Log out
          </Text>
        </ChakraMenuItem>
      </ChakraMenuList>
    </ChakraMenu>
  );
};

export default AuthenticatedMenu;
