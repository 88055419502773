import { FC } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  FAIconName,
  Icon,
  Text,
} from '@equip.health/ui';

const topLevelId = 'book-appointment__confirmation';

const BookAppointmentConfirmationStep: FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  return (
    <ChakraFlex
      alignItems="start"
      flexDirection="column"
      gridGap={{ base: '20px', md: '12px' }}
      height="100%"
      id={`${topLevelId}__container`}
      justifyContent="flex-start"
      padding="12px 32px 48px"
    >
      <ChakraFlex justifyContent="flex-end" width="100%">
        <ChakraBox
          alignItems="center"
          as="button"
          cursor="pointer"
          height="30px"
          id={`${topLevelId}__header__close-button`}
          onClick={onClose}
        >
          <Icon
            color="neutral.primary"
            name={FAIconName.timesSolid}
            size="md"
          />
        </ChakraBox>
      </ChakraFlex>
      <Text align="center" id={`${topLevelId}__title`} variant="h3">
        Your appointment has been scheduled
      </Text>
      <Text align="center" id={`${topLevelId}__subtitle`} variant="body">
        You can view and join upcoming appointments in the Equip App
      </Text>
    </ChakraFlex>
  );
};

export default BookAppointmentConfirmationStep;
