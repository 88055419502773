import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraHeading,
  ChakraText,
  Checkbox,
  EquipUIFireflyV2Theme,
  EquipUITheme,
  FireflyButton,
  Modal,
} from '@equip.health/ui';
import * as Sentry from '@sentry/react';

import NavItem from '../NavItem';
import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useApi } from '~/lib/hooks';
import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';
import {
  LegalDocumentType,
  joinLegalDocumentNames,
} from '~/lib/legalDocuments/legalDocuments';

const { h4, paragraph } = EquipUITheme.typography;
const { colors } = EquipUIFireflyV2Theme;

const TOP_LEVEL_ID = 'legal-documents-modal';

type LegalDocumentsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  pendingLegalDocuments: LegalDocumentType[];
};

const consentsUrl = urlConstants.users.consents;

const LegalDocumentsModal: FC<LegalDocumentsModalProps> = ({
  isOpen,
  onClose,
  pendingLegalDocuments,
}) => {
  const isMobile = useMobileBreakpoint();
  const {
    error: postConsentsError,
    loading: isPostConsentsLoading,
    sendRequest: postConsentsRequest,
    statusCode: postConsentsStatusCode,
  } = useApi();

  const { setPendingLegalDocuments } = useUserProfileContext();

  const [isConsentCheckboxSelected, setIsConsentCheckboxSelected] =
    useState<boolean>(false);

  const [selectedConsentKey, setSelectedDocumentKey] = useState<string>(
    pendingLegalDocuments.length ? pendingLegalDocuments[0].key : undefined,
  );

  const legalDocumentNames = useMemo(() => {
    return joinLegalDocumentNames(pendingLegalDocuments);
  }, [pendingLegalDocuments]);

  const ActiveLegalDocument = useMemo(() => {
    const activeDocument = pendingLegalDocuments.find(
      (document) => document.key === selectedConsentKey,
    );

    return activeDocument?.Component;
  }, [selectedConsentKey, pendingLegalDocuments]);

  const handleActiveLegalDocumentChange = (
    legalDocument: LegalDocumentType,
  ) => {
    setSelectedDocumentKey(legalDocument.key);
  };

  const handlePerformConsents = () => {
    const consentsData = pendingLegalDocuments.map((document) => {
      return { consentName: document.key, consentVersion: document.version };
    });

    postConsentsRequest({
      callback: () => {
        onClose();
        setPendingLegalDocuments([]);
      },
      command: ApiCommand.POST,
      options: { consents: consentsData },
      url: consentsUrl,
    });
  };

  useEffect(() => {
    if (postConsentsError) {
      // eslint-disable-next-line no-console
      onClose();
      console.error('Error saving consents', postConsentsError);
      Sentry.captureException(`Error saving consents ${postConsentsError}`);
    }
  }, [postConsentsError, postConsentsStatusCode, onClose]);

  return (
    <Modal
      bodyStyle={{
        alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '720px',
      }}
      hideCloseButton
      hideHeader
      id={TOP_LEVEL_ID}
      isOpen={isOpen}
      modalContentPadding="16px 8px"
      onClose={onClose}
      shouldCloseOnEscape={false}
      shouldCloseOnOverlayClick={false}
      size="full"
    >
      <ChakraBox width="100%">
        <ChakraHeading
          as="h1"
          {...h4}
          fontSize="26px"
          fontWeight="700"
          marginBottom="16px"
          textAlign="left"
          textColor="#262626"
        >
          {legalDocumentNames}
        </ChakraHeading>
        <ChakraText
          marginBottom="24px"
          textAlign="left"
          {...paragraph}
          lineHeight="1.5"
          textColor="#262626"
        >
          {`Before continuing, you must read and accept Equip's ${legalDocumentNames}`}
        </ChakraText>
      </ChakraBox>
      <ChakraBox width="100%">
        <ChakraFlex alignItems="center">
          {pendingLegalDocuments.map((document) => (
            <NavItem
              isSelected={selectedConsentKey === document.key}
              key={`CONSENT_${document.key}`}
              marginRight="8px"
              onClick={() => handleActiveLegalDocumentChange(document)}
              title={document.name}
            />
          ))}
        </ChakraFlex>
      </ChakraBox>

      <ChakraBox
        borderRadius="12px"
        display="flex"
        flexDirection="column"
        marginY="24px"
        maxHeight="460px"
        overflowY="auto"
        sx={{
          background: colors.neutral.background.secondary,
          backgroundPosition:
            'bottom center, top center, bottom center, top center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 20px, 100% 20px, 100% 10px, 100% 10px',
          fontFamily: 'Pangea Text',
          fontSize: '15px',
          'h1, h2, h3, h4, h5': {
            fontWeight: '500',
            fontFamily: 'Pangea',
          },
          h2: {
            fontSize: '20px',
          },
          h3: {
            fontSize: '19px',
          },
          li: {
            marginLeft: '20px',
            marginTop: '10px',
          },
          ol: {
            fontWeight: '500',
          },
        }}
        width="100%"
      >
        {pendingLegalDocuments.length ? (
          <ChakraBox padding="24px">
            <ActiveLegalDocument />
          </ChakraBox>
        ) : null}
      </ChakraBox>

      <ChakraFlex
        alignItems={{ base: 'baseline', md: 'center' }}
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        width="100%"
      >
        <Checkbox
          id={`${TOP_LEVEL_ID}__consent-checkbox`}
          isChecked={isConsentCheckboxSelected}
          isLabelClickable
          label={`I have read and accept Equip's ${legalDocumentNames}.`}
          marginBottom={isMobile ? 4 : 0}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setIsConsentCheckboxSelected(event.target.checked);
          }}
          size="lg"
        />
        <FireflyButton
          id={`${TOP_LEVEL_ID}__consent-submit`}
          isDisabled={!isConsentCheckboxSelected}
          isFullWidth={isMobile}
          isLoading={isPostConsentsLoading}
          onClick={handlePerformConsents}
        >
          Continue
        </FireflyButton>
      </ChakraFlex>
    </Modal>
  );
};

export default LegalDocumentsModal;
