import { FC } from 'react';

import {
  AngleRight,
  Button,
  ChakraBox,
  ChakraFlex,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

const { mono } = EquipUIFireflyV1Theme.colors;

type FormButtonBarProps = {
  backButtonText?: string;
  backButtonWidth?: string | number;
  containerMarginTop?: string | number;
  fontFamily?: string;
  fontSize?: string | number;
  fontWeight?: string | number;
  id?: string;
  isLoading?: boolean;
  isProceedButtonDisabled?: boolean;
  isRightIcon?: boolean;

  onClickBack?: () => void;
  onClickProceed: () => void;
  proceedButtonText?: string;
  spaceBetween?: string | number;
};

const FormButtonBar: FC<FormButtonBarProps> = ({
  backButtonText = 'Back',
  backButtonWidth = '102px',
  containerMarginTop,
  fontFamily,
  fontSize,
  fontWeight,
  id = 'form-button-bar',
  isLoading = false,
  isProceedButtonDisabled = false,
  isRightIcon = true,

  onClickBack,
  onClickProceed,
  proceedButtonText = 'Next',
  spaceBetween = '16px',
}) => (
  <ChakraFlex marginTop={containerMarginTop}>
    {onClickBack && (
      <ChakraBox marginRight={spaceBetween} width={backButtonWidth}>
        <Button
          colorScheme="teal"
          fontFamily={fontFamily}
          fontSize={fontSize}
          fontWeight={fontWeight}
          id={`${id}__previous`}
          isFullWidth
          onClick={onClickBack}
          type="submit"
          variant="outline"
        >
          {backButtonText}
        </Button>
      </ChakraBox>
    )}
    <Button
      colorScheme="teal"
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      id={`${id}__proceed`}
      isDisabled={isProceedButtonDisabled}
      isFullWidth
      isLoading={isLoading}
      onClick={onClickProceed}
      rightIcon={
        isRightIcon && <AngleRight fill={mono.white} height="15.2px" />
      }
      type="submit"
    >
      {proceedButtonText}
    </Button>
  </ChakraFlex>
);

export default FormButtonBar;
