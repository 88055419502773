/* eslint-disable react/no-danger */
import { FC, useEffect } from 'react';

import {
  ChakraBox,
  ChakraButton,
  ChakraFlex,
  ChakraGridItem,
  ChakraHeading,
  ChakraImage,
  ChakraSimpleGrid,
  ChakraText,
  ChakraVStack,
  EquipUIFireflyV1Theme,
  Text,
} from '@equip.health/ui';
import { useHistory } from 'react-router-dom';

import GroupIllustration from '~/assets/img/group_illustration.png';
import Inquiry from '~/assets/img/inquiry.png';
import Referral from '~/assets/img/referral.png';
import Footer from '~/components/common/Footer';
import Navbar from '~/components/common/Navbar';
import { INQUIRY_REFERRAL_HORIZONTAL_MARGINS } from '~/lib/constants';
import { PAGE_NAME } from '~/lib/constants/analytics';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';

const { body, h4, h2, bodySemibold } = EquipUIFireflyV1Theme.typography;
const { mono, teal, blue, black } = EquipUIFireflyV1Theme.colors;

const NAVBAR_ID = 'navigation-bar';
const TOP_LEVEL_ID = 'landing-page';

const actionBoxTileProps = {
  border: '1px solid #D4D9DD',
  borderRadius: '24px 120px 24px 24px',
  gap: '24px',
  height: ['auto', 'auto', '800px', '700px', '560px'],
  maxWidth: ['100%', '100%', '352px'],
  minHeight: '560px',
  padding: [
    '32px 22px',
    '32px 22px',
    '60px 32px 46px',
    '80px 40px 56px',
    '80px 40px 56px',
  ],
  width: '100%',
};

const actionButtonProps = {
  ...bodySemibold,
  _hover: {
    shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    transform: 'translateY(-0.25rem)',
  },
  background: teal[100],
  borderRadius: '16px',
  color: mono.white,
  fontSize: '16px',
  height: '50px',
  padding: '14px 16px',
  size: 'md',
  transition: '.2s ease',
  width: '100%',
};

type ActionBoxProps = {
  buttonText: string;
  id: string;
  image: string;
  handleActionTileClick: () => void;
  subtext: string;
  title: string;
};

const ActionBox: FC<ActionBoxProps> = ({
  buttonText,
  id,
  image,
  handleActionTileClick,
  subtext,
  title,
}) => {
  return (
    <ChakraBox
      {...actionBoxTileProps}
      alignItems="flex-start"
      display="flex"
      flexDir="column"
      id={`${id}-section`}
    >
      <ChakraImage
        alt="Get in touch"
        height="100px"
        id={`${id}-image`}
        src={image}
        width="100px"
      />
      <ChakraHeading
        {...h4}
        color={blue.shade}
        id={`${id}-header`}
        marginTop="16px"
        onClick={handleActionTileClick}
        role="button"
      >
        {title}
      </ChakraHeading>
      <Text
        {...body}
        color={black[70]}
        id={`${id}-description`}
        whiteSpace="pre-line"
      >
        {subtext}
      </Text>
      <ChakraButton
        {...actionButtonProps}
        id={`${id}-start-btn`}
        marginTop="auto"
        onClick={handleActionTileClick}
      >
        {buttonText}
      </ChakraButton>
    </ChakraBox>
  );
};

const LandingPage: FC = () => {
  const history = useHistory();
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView(PAGE_NAME.landing);
  }, []);

  return (
    <ChakraBox id={TOP_LEVEL_ID}>
      <Navbar id={NAVBAR_ID} />
      <ChakraFlex
        display={{ md: 'flex' }}
        id={`${TOP_LEVEL_ID}__body-container`}
        justifyContent="space-between"
        margin={INQUIRY_REFERRAL_HORIZONTAL_MARGINS.map((m) => `74px ${m}`)}
      >
        <ChakraBox
          id={`${TOP_LEVEL_ID}__get-in-touch-section`}
          marginRight="50px"
          width={['100%', '100%', '325px', '380px', '380px']}
        >
          <ChakraImage
            alt="Get in touch"
            id={`${TOP_LEVEL_ID}__get-in-touch-image`}
            src={GroupIllustration}
          />
          <ChakraHeading
            {...h2}
            color={blue.shade}
            fontSize={['44px', '44px', '52px', '64px', '64px']}
            id={`${TOP_LEVEL_ID}__header`}
            marginTop="40px"
          >
            Get in touch with Equip
          </ChakraHeading>
          <ChakraVStack
            {...body}
            align="left"
            color={black[70]}
            id={`${TOP_LEVEL_ID}__description`}
            paddingTop="24px"
            spacing={6}
          >
            <ChakraText>
              Tell us how to reach you, and we’ll do the rest.
            </ChakraText>
            <ChakraText>
              Anything you provide is confidential. We won’t share it with
              anyone else.
            </ChakraText>
            <ChakraText>Our forms are HIPAA-compliant.</ChakraText>
          </ChakraVStack>
        </ChakraBox>

        <ChakraSimpleGrid
          alignItems="center"
          columns={{ lg: 2, md: 2, sm: 1 }}
          id={`${TOP_LEVEL_ID}__actions-section`}
          justifyContent="end"
          paddingTop={{ base: '30px', md: '10px' }}
          spacing="60px"
        >
          <ChakraGridItem>
            <ActionBox
              buttonText="Get in touch"
              handleActionTileClick={() => history.push('/inquiry')}
              id={`${TOP_LEVEL_ID}__inquiry-box`}
              image={Inquiry}
              subtext={
                'Interested in treatment for yourself?\n\nAre you a family member or friend of someone who’s struggling?'
              }
              title="You’re seeking care for yourself or a loved one"
            />
          </ChakraGridItem>
          <ChakraGridItem>
            <ActionBox
              buttonText="Get started"
              handleActionTileClick={() => history.push('/referral')}
              id={`${TOP_LEVEL_ID}__referral-box`}
              image={Referral}
              subtext={
                'Do you have a patient who needs treatment?\n\nDo you want to make a referral?'
              }
              title="You’re a provider, health plan employee, or other professional"
            />
          </ChakraGridItem>
        </ChakraSimpleGrid>
      </ChakraFlex>
      <Footer />
    </ChakraBox>
  );
};

export default LandingPage;
