import {
  ChakraBox,
  ChakraFlex,
  FAIconName,
  GroupClassRegistrationButton,
  Icon,
  Modal,
  Text,
  formatNextGroupClassStartDate,
  getGroupClassIconElement,
} from '@equip.health/ui';

import { Participant } from '~/components/common/Participant';
import useGroupClassRegistration, {
  GroupClassRegistrationChangeSource,
} from '~/lib/hooks/services/useGroupClassRegistration';
import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';
import useFeatureFlags from '~/lib/hooks/useFeatureFlags';
import {
  getGroupClassRecurrenceDisplayString,
  getGroupClassStartTime,
} from '~/lib/util/schedule/groupClasses.util';
import { Recurrence } from '~/lib/util/schedule.util';
import { getPreferredFullName } from '~/lib/utils';

interface GroupClassDetailModalProps {
  groupClass: GroupClass;
  isOpen: boolean;
  isProxy?: boolean;
  onClose: () => void;
  patientExternalId?: string;
  onClassRegistrationChange?: (
    isRegistered: boolean,
    groupClassId: string,
  ) => void;
}

const TOP_LEVEL_ID = 'group-class__detail';
const sensitiveGCTextForPatient =
  'Only you and your provider team can see your registration';
const sensitiveGCTextForProxy =
  'For this particular class, the patient will need to register via their own account.';

interface HostListProps {
  hosts: GroupClassHost[];
  groupClassId: string;
}

const HostList = ({ hosts, groupClassId }: HostListProps) => {
  return (
    <ChakraFlex
      flexWrap="wrap"
      gridGap="24px"
      id={`${groupClassId}__host-list`}
    >
      {hosts.map(({ externalId, providerType, firstName, lastName }) => (
        <Participant
          avatarSize="xs"
          id={`${groupClassId}__host`}
          key={externalId}
          primaryLabel={getPreferredFullName(null, firstName, lastName)}
          secondaryLabel={providerType}
          userId={externalId}
          userType="provider"
        />
      ))}
    </ChakraFlex>
  );
};

const GroupClassDetailModal = ({
  groupClass,
  isOpen,
  isProxy,
  onClose,
  patientExternalId,
  onClassRegistrationChange,
}: GroupClassDetailModalProps) => {
  const isMobileDevice = useMobileBreakpoint();
  const { isSensitiveGroupClassesEnabled } = useFeatureFlags();

  const {
    description,
    duration,
    endDate,
    frequency,
    groupClassExternalId,
    hosts,
    iconName,
    isUserRegistered: isUserRegisteredInitially,
    isSensitive,
    nextStartDate,
    startDate,
    title,
  } = groupClass || {};

  const {
    isLoading,
    isRegistered: isUserRegistered,
    registerForGroupClass,
    unregisterForGroupClass,
  } = useGroupClassRegistration(
    groupClassExternalId,
    isUserRegisteredInitially,
    patientExternalId,
  );

  if (!groupClass) {
    return null;
  }

  const groupClassId = `${TOP_LEVEL_ID}__${groupClassExternalId}`;
  const Illustration = getGroupClassIconElement(iconName);

  const handleRegisterForGroupClass = () => {
    registerForGroupClass(
      GroupClassRegistrationChangeSource.detail,
      (success) => {
        if (success) {
          onClassRegistrationChange?.(true, groupClassExternalId);
        }
      },
    );
  };

  const handleUnregisterForGroupClass = () => {
    unregisterForGroupClass(
      GroupClassRegistrationChangeSource.detail,
      (success) => {
        if (success) {
          onClassRegistrationChange?.(false, groupClassExternalId);
        }
      },
    );
  };

  const handleRegisterClick = () => {
    if (isUserRegistered) {
      handleUnregisterForGroupClass();
    } else {
      handleRegisterForGroupClass();
    }
  };

  const renderSensetiveGCText =
    (isSensitiveGroupClassesEnabled && isSensitive && !isProxy) ||
    (isSensitiveGroupClassesEnabled &&
      isSensitive &&
      isProxy &&
      isMobileDevice);

  return (
    <Modal
      bodyStyle={{
        alignItems: 'start',
        display: 'flex',
        flexDirection: 'column',
        padding: !isMobileDevice && '20px 30px',
      }}
      hideHeader
      id={TOP_LEVEL_ID}
      isOpen={isOpen}
      modalContentPadding={isMobileDevice ? '10px' : '32px'}
      onClose={onClose}
      shouldCloseOnEscape
      shouldCloseOnOverlayClick
      size={isMobileDevice ? 'full' : '4xl'}
    >
      <ChakraFlex
        flexDirection={isMobileDevice ? 'column' : 'row'}
        gap="38px"
        marginTop={isMobileDevice ? '60px' : '20px'}
        width="100%"
      >
        <ChakraFlex
          alignItems="start"
          flexDirection="column"
          gridGap="12px"
          id={groupClassId}
          width={isMobileDevice ? '100%' : '65%'}
        >
          <ChakraBox
            flexShrink="0"
            id={`${groupClassId}__illustration`}
            width="48px"
          >
            {Illustration}
          </ChakraBox>
          <Text id={`${groupClassId}__title`} variant="h2">
            {title}
          </Text>
          <HostList groupClassId={groupClassId} hosts={hosts} />
          <ChakraFlex alignItems="start" flexDirection="column">
            <Text id={`${groupClassId}__description`}>{description}</Text>
          </ChakraFlex>
        </ChakraFlex>
        <ChakraFlex
          alignItems={isMobileDevice ? 'start' : 'end'}
          flexDirection={isMobileDevice ? 'column-reverse' : 'column'}
          gridGap="20px"
        >
          <ChakraBox width={isMobileDevice ? '100%' : 'content'}>
            <GroupClassRegistrationButton
              handleRegistrationClick={handleRegisterClick}
              id={`${groupClassId}__registration`}
              isFullWidth
              isLoading={isLoading}
              isProxy={isSensitiveGroupClassesEnabled && isProxy}
              isRegistered={isUserRegistered}
              isSensitive={isSensitiveGroupClassesEnabled && isSensitive}
              isTooltipDisabled={isMobileDevice}
            />
          </ChakraBox>
          <ChakraFlex flexDirection="column" gridGap="20px">
            <ChakraFlex
              alignItems="start"
              gridGap="8px"
              justifyContent="start"
              width="100%"
            >
              <ChakraBox pt="2px">
                <Icon name={FAIconName.calendarRegular} size="sm" />
              </ChakraBox>
              <ChakraFlex flexDirection="column" gridGap="4px" width="100%">
                <Text id={`${groupClassId}__start-time`} variant="bodyMedium">
                  {getGroupClassStartTime(startDate, frequency as Recurrence)}
                </Text>
                <Text id={`${groupClassId}__recurrence`}>
                  {getGroupClassRecurrenceDisplayString(
                    startDate,
                    endDate,
                    frequency,
                  )}
                </Text>
                {nextStartDate && (
                  <Text id={`${TOP_LEVEL_ID}__next-start-date`}>
                    Next: {formatNextGroupClassStartDate(nextStartDate)}
                  </Text>
                )}
              </ChakraFlex>
            </ChakraFlex>

            <ChakraFlex
              alignItems="center"
              gridGap="8px"
              justifyContent="start"
              width="100%"
            >
              <Icon name={FAIconName.clockRegular} size="sm" />
              <Text id={`${groupClassId}__duration`} variant="bodyMedium">
                {duration} minutes
              </Text>
            </ChakraFlex>
          </ChakraFlex>
        </ChakraFlex>
      </ChakraFlex>
      {renderSensetiveGCText && (
        <ChakraFlex
          alignItems="center"
          background={
            (!isMobileDevice || !isProxy) && 'tertiary.marigold.background'
          }
          borderRadius="12px"
          id={`${TOP_LEVEL_ID}__sensitive-flag`}
          gap="10px"
          marginTop="24px"
          padding={(!isMobileDevice || !isProxy) && '12px 20px'}
          width="100%"
        >
          {(!isMobileDevice || !isProxy) && (
            <Icon
              color="tertiary.marigold.text"
              name="Eye Slash Regular"
              size="md"
            />
          )}

          <Text color={isProxy && 'neutral.secondary'}>
            {isProxy ? sensitiveGCTextForProxy : sensitiveGCTextForPatient}
          </Text>
        </ChakraFlex>
      )}
    </Modal>
  );
};

export default GroupClassDetailModal;
