import { FC, ReactElement, createContext, useContext } from 'react';

import useProfilePage, { ProfilePageState } from '~/lib/hooks/useProfilePage';

const ProfilePageContext = createContext<ProfilePageState | undefined>(
  undefined,
);

interface ProfilePageContextProviderProps {
  children: ReactElement | ReactElement[];
}

const ProfilePageContextProvider: FC<ProfilePageContextProviderProps> = ({
  children,
}: ProfilePageContextProviderProps) => {
  const value = useProfilePage();

  return (
    <ProfilePageContext.Provider value={value}>
      {children}
    </ProfilePageContext.Provider>
  );
};

const useProfilePageContext = (): ProfilePageState => {
  const context = useContext(ProfilePageContext);

  if (context === undefined) {
    throw new Error(
      'useProfilePageContext must be used within a ProfilePageContextProvider',
    );
  }

  return context;
};

export { ProfilePageContextProvider, useProfilePageContext };
