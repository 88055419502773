import { AdminEpisodeStatus } from '~/lib/constants/user.constants';
import {
  clearUserProfile as clearUserProfileAction,
  setUserProfile as setUserProfileAction,
} from '~/store/reducers/user';

import { useAppDispatch, useAppSelector } from '.';

export const useUserSelector = () => {
  return useAppSelector((state) => state.user);
};

export const useProxyPatientsSelector = () => {
  const user = useUserSelector();
  const proxyPatients = user.linkedPatients.filter(
    (patient) => patient.isProxy,
  );
  return proxyPatients;
};

export const useInTreatmentPatientsSelector = () => {
  const user = useUserSelector();
  const inTreatmentPatients = user.linkedPatients.filter(
    (patient) => patient.isInTreatment,
  );
  return inTreatmentPatients;
};

export const useInTreatmentProxyPatientsSelector = () => {
  const user = useUserSelector();
  const inTreatmentProxyPatients = user?.linkedPatients?.filter(
    (patient) =>
      patient.adminEpisodeStatus === AdminEpisodeStatus.inTreatment &&
      patient.isProxy,
  );
  return inTreatmentProxyPatients;
};

export const useUserDispatch = () => {
  const dispatch = useAppDispatch();

  const setUserProfile = (userProfile: UserProfile | undefined) => {
    dispatch(setUserProfileAction(userProfile));
  };

  const clearUserProfile = () => {
    dispatch(clearUserProfileAction());
  };

  return {
    clearUserProfile,
    setUserProfile,
  };
};
