import isNil from 'lodash/isNil';

import { sortGroupClassesByRegistrationStatusAndStartDate } from '~/lib/util/schedule/groupClasses.util';

import { useAppDispatch } from '.';
import { scheduleApi } from '../services/schedule';

// eslint-disable-next-line import/prefer-default-export
export const useGroupClassDispatch = () => {
  const dispatch = useAppDispatch();

  const sortGroupClasses = (patientId?: string) => {
    dispatch(
      scheduleApi.util.updateQueryData(
        'getGroupClasses',
        {
          patientId,
        },
        (draft) => {
          const shouldSortSensitiveGroupClasses = isNil(patientId);
          return sortGroupClassesByRegistrationStatusAndStartDate(
            draft,
            shouldSortSensitiveGroupClasses,
          );
        },
      ),
    );
  };

  return {
    sortGroupClasses,
  };
};
