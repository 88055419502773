import { FC } from 'react';

import {
  ChakraFlex,
  CircularProgress,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

const { colors } = EquipUIFireflyV1Theme;

interface LoadingProps {
  size?: string;
}

const Loading: FC<LoadingProps> = ({ size }) => (
  <ChakraFlex alignItems="center" justifyContent="center">
    <CircularProgress
      color={colors.primary[100]}
      id="page-content"
      isIndeterminate
      size={size}
    />
  </ChakraFlex>
);

export default Loading;
