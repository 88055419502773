import { FC, useState } from 'react';

import { isNil } from 'lodash';

import BookAppointmentStepContainer, {
  ListContainer,
} from '~/components/schedule/BookAppointment/base/StepContainer';
import AppointmentAttendeeCard from '~/components/schedule/BookAppointment/cards/AppointmentAttendeeCard';
import { BOOK_APPOINTMENT_STEP } from '~/lib/constants/bookAppointments';
import { useBookAppointmentContext } from '~/lib/context/BookAppointmentContext';

const topLevelId = 'book-appointment__optional-attendee';

const BookAppointmentOptionalAttendeesStep: FC = () => {
  const {
    setNextStep,
    availableAttendees,
    setSelectedOptionalAttendees,
    selectedOptionalAttendees: initialOptionalAttendees,
  } = useBookAppointmentContext();

  const [selectedAttendees, setSelectedAttendees] = useState<
    BookAppointmentAttendee[]
  >(initialOptionalAttendees);

  const optionalAttendees = availableAttendees?.filter(
    (e) => e.isRequired === false,
  );

  const handleNavigation = (step: BOOK_APPOINTMENT_STEP): void => {
    setSelectedOptionalAttendees(selectedAttendees);
    setNextStep(step);
  };

  const handleSelectedAttendee = (attendee: BookAppointmentAttendee) => {
    if (selectedAttendees.find((e) => e.externalId === attendee.externalId)) {
      setSelectedAttendees(
        selectedAttendees.filter((e) => e.externalId !== attendee.externalId),
      );
    } else {
      setSelectedAttendees([...selectedAttendees, attendee]);
    }
  };

  return (
    <BookAppointmentStepContainer
      isBackButtonVisible
      onBackwardNavigation={() =>
        handleNavigation(BOOK_APPOINTMENT_STEP.attendees)
      }
      onForwardNavigation={() =>
        handleNavigation(BOOK_APPOINTMENT_STEP.duration)
      }
      stepId={topLevelId}
      title="Who else would you like to invite to this appointment?"
    >
      <ListContainer>
        {optionalAttendees?.map((attendee) => {
          const selectedAttendee = selectedAttendees.find(
            (e) => e.externalId === attendee.externalId,
          );
          return (
            <AppointmentAttendeeCard
              attendee={attendee}
              isSelected={!isNil(selectedAttendee)}
              key={attendee.externalId}
              onSelect={handleSelectedAttendee}
            />
          );
        })}
      </ListContainer>
    </BookAppointmentStepContainer>
  );
};

export default BookAppointmentOptionalAttendeesStep;
