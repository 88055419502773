import urlConstants from '~/lib/constants/url.constants';

import api from './api';

const {
  chat: { getChatToken: chatTokenUrl },
} = urlConstants;

export const chatApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserChatToken: build.query<SendBirdApiToken, void>({
      query: () => chatTokenUrl,
    }),
  }),
});

export const { useLazyGetUserChatTokenQuery, useGetUserChatTokenQuery } =
  chatApi;
