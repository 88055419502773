import { FC } from 'react';

const Icon: FC<BaseSVG> = ({ height = '36', width = '36' }) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 36 36"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#8FAFAE" height="36" rx="8" width="36" />
    <g clipPath="url(#clip0_3732_72144)">
      <path
        clipRule="evenodd"
        d="M10.5 10.75C10.5 10.0596 11.0596 9.5 11.75 9.5L24.25 9.5C24.9404 9.5 25.5 10.0596 25.5 10.75L25.5 20.5L22.75 20.5C21.2312 20.5 20 21.7312 20 23.25L20 27.25C20 27.5593 20.1899 27.8369 20.4781 27.949C20.7664 28.0611 21.0939 27.9848 21.3029 27.7568L26.8029 21.7568C26.9297 21.6185 27 21.4376 27 21.25L27 10.75C27 9.23122 25.7688 8 24.25 8L11.75 8C10.2312 8 9 9.23122 9 10.75L9 25.25C9 26.7688 10.2312 28 11.75 28L17.5 28C17.9142 28 18.25 27.6642 18.25 27.25C18.25 26.8358 17.9142 26.5 17.5 26.5L11.75 26.5C11.0596 26.5 10.5 25.9404 10.5 25.25L10.5 10.75ZM24.5451 22L22.75 22C22.0596 22 21.5 22.5596 21.5 23.25L21.5 25.3219L24.5451 22Z"
        fill="white"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_3732_72144">
        <rect fill="white" height="20" transform="translate(8 8)" width="20" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
