import { FC, ReactNode } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraGridItem,
  ChakraImage,
  ChakraSimpleGrid,
  EquipUIFireflyV2Theme,
  FAIconName,
  FireflyButton,
  Icon,
  Text,
} from '@equip.health/ui';

import WelcomeScreenGroupImage from '~/assets/img/welcome_screen_group.png';
import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';

const { colors } = EquipUIFireflyV2Theme;

interface WelcomeScreenFeatureType {
  title: string;
  icon;
}

const WelcomeScreenFeatures: WelcomeScreenFeatureType[] = [
  {
    icon: (
      <Icon color={colors.primary[100]} name={FAIconName.calendarsRegular} />
    ),
    title: 'Join & schedule appointments',
  },
  {
    icon: (
      <Icon color={colors.primary[100]} name={FAIconName.messagesRegular} />
    ),
    title: 'Message providers & mentors',
  },
  {
    icon: (
      <Icon
        color={colors.primary[100]}
        name={FAIconName.clipboardCheckRegular}
      />
    ),
    title: 'Log vitals & complete other tasks',
  },
];
const TOP_LEVEL_ID = 'onboarding-welcome__step';

interface FeatureItemProps {
  title: string;
  children?: ReactNode;
}

const FeatureItem: FC<FeatureItemProps> = ({ title, children }) => {
  return (
    <ChakraBox marginY={{ md: '12px', sm: '6px' }}>
      <ChakraFlex alignItems="center">
        {children}
        <Text marginLeft="12px" variant="h5">
          {title}
        </Text>
      </ChakraFlex>
    </ChakraBox>
  );
};

type OnboardingWelcomeStepProps = {
  onComplete: () => void;
};

const WelcomeStep: FC<OnboardingWelcomeStepProps> = ({ onComplete }) => {
  const isMobile = useMobileBreakpoint();

  return (
    <ChakraSimpleGrid
      columns={{ lg: 2, md: 2, sm: 1 }}
      id={TOP_LEVEL_ID}
      maxWidth={{ md: '850px', sm: '327px' }}
      spacing={{ md: '60px', sm: '36px' }}
    >
      <ChakraGridItem>
        <ChakraImage
          alt="Welcome to Equip"
          id="welcome-to-equip-header-image"
          src={WelcomeScreenGroupImage}
        />
      </ChakraGridItem>
      <ChakraGridItem>
        <ChakraFlex
          alignItems="start"
          flexDirection="column"
          marginTop="30px"
          maxWidth="360px"
        >
          <Text variant="h2">Welcome to Equip!</Text>
          <Text paddingY="12px" variant="body">
            Your Equip account is the place to come for all things related to
            your treatment. From here you can:
          </Text>
          <ChakraFlex flexDirection="column" marginBottom="12px" width="100%">
            {WelcomeScreenFeatures.map(({ title, icon }) => {
              return (
                <FeatureItem key={`feature_list_${title}`} title={title}>
                  {icon}
                </FeatureItem>
              );
            })}
          </ChakraFlex>

          <FireflyButton
            id={`${TOP_LEVEL_ID}__confirm`}
            isFullWidth={isMobile}
            onClick={onComplete}
          >
            Start your journey
          </FireflyButton>
        </ChakraFlex>
      </ChakraGridItem>
    </ChakraSimpleGrid>
  );
};

export default WelcomeStep;
