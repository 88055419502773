import { FC } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraSimpleGrid,
  Modal,
  Text,
  useBreakpointValue,
} from '@equip.health/ui';
import { GroupChannel } from '@sendbird/chat/groupChannel';

import ModalHeader from '../common/modals/ModalHeader';
import { Participant } from '~/components/common/Participant';
import { ChatUser } from '~/lib/types/chat';
import {
  getInformationalModalGroupChannelDescription,
  groupedChatChannelsByGroupType,
} from '~/lib/util/chat/chat.utils';
import { getChannelName } from '~/lib/util/chat/chatChannel.util';

interface MessagesInformationalModalProps {
  channels: GroupChannel[];
  isOpen: boolean;
  isPatient: boolean;
  onClose: () => void;
  patient: ChatUser;
}

const id = 'messages-informational';

enum ChannelGroupSection {
  AdmissionsChannel = 'AdmissionsChannel',
  GroupChannels = 'GroupChannels',
  DirectMessageChannels = 'DirectMessageChannels',
}

interface ChannelSectionInfoProps {
  name: string;
  description: string;
  id: string;
}

const SectionInfo: Record<ChannelGroupSection, ChannelSectionInfoProps> = {
  [ChannelGroupSection.AdmissionsChannel]: {
    description:
      'Recommended for working with your admissions team for enrolling in Equip treatment',
    id: 'admissions-channel',
    name: 'Admissions channel',
  },
  [ChannelGroupSection.GroupChannels]: {
    description:
      'Recommended for treatment or medical-related questions (such as prescriptions, appointments) or providing a group update to the entire provider team',
    id: 'group-channels',
    name: 'Treatment group channels',
  },
  [ChannelGroupSection.DirectMessageChannels]: {
    description:
      'Recommended for private conversations with each person on your provider team',
    id: 'direct-message-channels',
    name: 'Treatment direct message channels',
  },
};

interface ChannelGridProps {
  children: React.ReactNode;
  section: ChannelGroupSection;
}

const ChannelSection: FC<ChannelGridProps> = ({ children, section }) => {
  const { name, description, id } = SectionInfo[section];
  return (
    <>
      <ChakraFlex
        direction="column"
        gridGap="6px"
        marginTop={{ base: '32px', md: '40px' }}
      >
        <Text id={`${id}__section__title`} variant="h5">
          {name}
        </Text>
        <Text
          color="neutral.secondary"
          id={`${id}__section__description`}
          variant="body"
        >
          {description}
        </Text>
      </ChakraFlex>
      <ChakraSimpleGrid
        alignItems="center"
        columns={{ base: 1, md: 2 }}
        id={`${id}_grid`}
        paddingTop="24px"
        spacingY={{ base: '20px', md: '24px' }}
      >
        {children}
      </ChakraSimpleGrid>
    </>
  );
};

const MessagesInformationalModal: FC<MessagesInformationalModalProps> = ({
  channels,
  isOpen,
  isPatient,
  onClose,
  patient,
}) => {
  const modalSize: 'full' | '3xl' = useBreakpointValue({
    base: 'full',
    md: '3xl',
  });

  const { directMessageChannels, groupChannels, admissionsChannel } =
    groupedChatChannelsByGroupType(channels);

  return (
    <Modal
      hideCloseButton
      hideHeader
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      size={modalSize}
    >
      <ChakraBox marginBottom="40px">
        <ModalHeader id={id} onClose={onClose} title="About messages" />
        {admissionsChannel.length > 0 && (
          <>
            <ChannelSection section={ChannelGroupSection.AdmissionsChannel}>
              {admissionsChannel.map((channel) => {
                const id = `admissions-channel-${channel.url}`;
                const channelName = getChannelName(channel, patient, isPatient);
                return (
                  <Participant
                    id={id}
                    isGroup
                    key={id}
                    primaryLabel={channelName.primaryName}
                    secondaryLabel={channelName.secondaryName}
                    userId={id}
                    userType="provider"
                  />
                );
              })}
            </ChannelSection>
          </>
        )}
        <>
          <ChannelSection section={ChannelGroupSection.GroupChannels}>
            {groupChannels.map((groupChannel) => {
              const id = `group-channel-${groupChannel.url}`;
              const channelName = getChannelName(
                groupChannel,
                patient,
                isPatient,
              );
              const channelDescription =
                getInformationalModalGroupChannelDescription(
                  groupChannel.url,
                  patient.nickname,
                  isPatient,
                );
              return (
                <Participant
                  id={id}
                  isGroup
                  key={id}
                  primaryLabel={channelName.primaryName}
                  secondaryLabel={channelDescription}
                  userId={id}
                  userType="provider"
                />
              );
            })}
          </ChannelSection>
        </>
        {directMessageChannels.length > 0 && (
          <>
            <ChannelSection section={ChannelGroupSection.DirectMessageChannels}>
              {directMessageChannels.map((channel) => {
                const id = `direct-message-channel-${channel.url}`;
                const channelName = getChannelName(channel, patient, isPatient);
                return (
                  <Participant
                    id={id}
                    key={id}
                    primaryLabel={channelName.primaryName}
                    secondaryLabel={channelName.secondaryName}
                    userId={id}
                    userType="provider"
                  />
                );
              })}
            </ChannelSection>
          </>
        )}
        <Text color="neutral.secondary" marginTop="40px" variant="caption">
          Your Equip team will not always be able to respond immediately. You
          should receive a response within 1-3 business days.
          <br />
          <br />
          Equip does not handle medical emergencies. Call 911 immediately if you
          believe you are experiencing a medical emergency. Do not rely on
          communication through this website for urgent medical needs.
        </Text>
      </ChakraBox>
    </Modal>
  );
};

export default MessagesInformationalModal;
