import { FC } from 'react';

import {
  ChakraFlex,
  ChakraText,
  EquipUIFireflyV1Theme,
  RechartCartesianGrid,
  RechartLine,
  RechartLineChart,
  RechartResponsiveContainer,
  RechartTooltip,
  RechartXAxis,
  RechartYAxis,
} from '@equip.health/ui';

import { PatientObservationData } from '~/lib/types/vitals';
import {
  getObservationDateTime,
  getObservationLoggerName,
} from '~/lib/util/vitals.util';

const { black } = EquipUIFireflyV1Theme.colors;
const { caption, h5 } = EquipUIFireflyV1Theme.typography;

type ChartDataPoint = {
  dateTime: string;
  formattedDate: string;
  id: string;
  loggedBy: string;
  value: number;
  unit: string;
};

interface YAxisTickProps {
  payload: {
    coordinate: number;
    isShow: boolean;
    offset: number;
    tickCoord: number;
    value: number;
  };
  x: number;
  y: number;
}

const YAxisTick: FC<YAxisTickProps> = ({ payload, x, y }) => {
  return (
    <g>
      <text x={x} y={y} {...caption} fill={black[60]}>
        {payload.value}
      </text>
    </g>
  );
};

interface RechartTooltipProps {
  active?: boolean;
  payload?: {
    payload: ChartDataPoint;
  }[];
}

const Tooltip = ({ active, payload }: RechartTooltipProps) => {
  if (!active || !payload?.length) {
    return null;
  }

  const { value, formattedDate, loggedBy, unit } = payload[0].payload;

  return (
    <ChakraFlex
      backgroundColor="white"
      border={`1px solid ${black[30]}`}
      borderRadius="10px"
      boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.16)"
      flexDirection="column"
      gridGap="16px"
      padding="16px"
    >
      <ChakraFlex
        alignItems="baseline"
        color="black.90"
        fontWeight="500"
        gridGap="2px"
        {...h5}
      >
        <ChakraText>{value}</ChakraText>
        <ChakraText fontSize="15px">{unit}</ChakraText>
      </ChakraFlex>
      <ChakraFlex
        color="black.60"
        flexDirection="column"
        gridGap="4px"
        {...caption}
      >
        <ChakraText>{formattedDate}</ChakraText>
        <ChakraText>{loggedBy}</ChakraText>
      </ChakraFlex>
    </ChakraFlex>
  );
};

interface ChartProps {
  observations: PatientObservationData[];
}

const LineChart: FC<ChartProps> = ({ observations }) => {
  const graphData: ChartDataPoint[] = observations
    .map((observationData) => ({
      dateTime: observationData.observation.observationDatetime,
      formattedDate: getObservationDateTime(observationData),
      id: observationData.observation.externalId,
      loggedBy: getObservationLoggerName(observationData),
      unit: observationData.observation.unit,
      value: observationData.observation.value,
    }))
    .reverse();

  return (
    <RechartResponsiveContainer height={120} width="100%">
      <RechartLineChart data={graphData}>
        <RechartCartesianGrid
          fill="#FDF6EB"
          stroke={black[20]}
          strokeDasharray="4 4"
          vertical={false}
        />

        <RechartYAxis
          axisLine={false}
          domain={[
            (dataMin: number) => Math.floor(dataMin),
            (dataMax: number) => Math.ceil(dataMax),
          ]}
          orientation="right"
          padding={{
            bottom: 10,
            top: 10,
          }}
          scale="linear"
          tick={YAxisTick}
          tickLine={false}
          type="number"
        />
        <RechartXAxis
          axisLine={false}
          domain={['dataMin', 'dataMax']}
          hide
          padding={{ left: 10, right: 10 }}
        />

        <defs>
          <linearGradient id="strokeColor" x1="0%" x2="100%" y1="0%" y2="0%">
            <stop offset="0%" stopColor="#FFD966" />
            <stop offset="100%" stopColor="#EFA53A" />
          </linearGradient>
        </defs>

        <RechartTooltip
          allowEscapeViewBox={{ x: true, y: true }}
          content={<Tooltip />}
          trigger="click"
          wrapperStyle={{ zIndex: 100 }}
        />

        <RechartLine
          activeDot={{
            fill: '#fff',
            r: 5,
            stroke: '#EFA53A',
            strokeWidth: 4,
          }}
          dataKey="value"
          dot={{ fill: '#EFA53A', r: 3, strokeWidth: 0 }}
          stroke="url(#strokeColor)"
          strokeWidth={3}
          type="monotone"
        />
      </RechartLineChart>
    </RechartResponsiveContainer>
  );
};

export default LineChart;
