import {
  ChangeEvent,
  Dispatch,
  FC,
  KeyboardEvent,
  SetStateAction,
  useMemo,
} from 'react';

import {
  Button,
  ChakraText,
  EquipUITheme,
  Modal,
  TextInput,
} from '@equip.health/ui';

import { VerifyStep } from '~/lib/util/survey.util';

const { paragraph } = EquipUITheme.typography;

const TOP_LEVEL_ID = 'verify-user';

type VerifyUserModalProps = {
  code: string;
  error: string;
  isOpen: boolean;
  isProcessing: boolean;
  onClose: () => void;
  onSubmit: () => void;
  otpPrompt: string;
  setCode: Dispatch<SetStateAction<string>>;
  setUserEmail: Dispatch<SetStateAction<string>>;
  step: VerifyStep;
  userEmail: string;
};

const VerifyUserModal: FC<VerifyUserModalProps> = ({
  code,
  error,
  isOpen,
  isProcessing,
  onClose,
  onSubmit,
  otpPrompt,
  setCode,
  setUserEmail,
  step,
  userEmail,
}) => {
  const { placeholder, prompt } = useMemo(() => {
    let placeholderValue = '';
    let promptValue = '';
    if (step === VerifyStep.REQUEST_OTP) {
      promptValue =
        'Please enter the email address associated with this survey.';
      placeholderValue = 'Enter email';
    }
    if (step === VerifyStep.CHECK_OTP) {
      promptValue = `${
        otpPrompt ||
        'A verification code has been sent to the phone number on record'
      }. Please enter this code below.`;
      placeholderValue = 'Enter code';
    }
    return { placeholder: placeholderValue, prompt: promptValue };
  }, [step]);

  const inputValue = useMemo(() => {
    if (step === VerifyStep.REQUEST_OTP) return userEmail;
    if (step === VerifyStep.CHECK_OTP) return code;
    return '';
  }, [code, step, userEmail]);

  const isDisabled = useMemo(
    () =>
      isProcessing ||
      (step === VerifyStep.REQUEST_OTP && !userEmail.trim()) ||
      (step === VerifyStep.CHECK_OTP && !code.trim()),
    [code, isProcessing, step, userEmail],
  );

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !isDisabled) onSubmit();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (step === VerifyStep.REQUEST_OTP) setUserEmail(e.target.value);
    if (step === VerifyStep.CHECK_OTP) setCode(e.target.value);
  };

  return (
    <Modal
      footerContent={
        <>
          <Button
            colorScheme="gray"
            id={`${TOP_LEVEL_ID}__cancel`}
            isDisabled={isProcessing}
            margin="0 16px 0 0"
            onClick={onClose}
            padding="14px 16px"
            size="56px"
            variant="ghost"
          >
            Cancel
          </Button>
          <Button
            boxShadow="0px 4px 32px 0px rgb(0 127 121 / 40%)"
            colorScheme="teal"
            id={`${TOP_LEVEL_ID}__confirm`}
            isDisabled={isDisabled}
            onClick={onSubmit}
            padding="14px 16px"
            size="56px"
            variant="solid"
          >
            Confirm
          </Button>
        </>
      }
      hideCloseButton
      id={TOP_LEVEL_ID}
      isOpen={isOpen}
      onClose={onClose}
      title={`Verification Step ${step + 1}: ${
        step === VerifyStep.REQUEST_OTP ? 'Email' : 'Phone'
      }`}
    >
      <ChakraText id={`${TOP_LEVEL_ID}__prompt`} marginBottom="16px">
        {prompt}
      </ChakraText>
      <TextInput
        id={`${TOP_LEVEL_ID}__${(placeholder ?? '')
          .toLowerCase()
          .split(' ')
          .join('-')}`}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={inputValue}
      />
      <ChakraText
        {...paragraph}
        color="error"
        height="52px"
        id={`${TOP_LEVEL_ID}__error`}
        padding="8px"
      >
        {error}
      </ChakraText>
    </Modal>
  );
};

export default VerifyUserModal;
