import { ChangeEvent, MutableRefObject } from 'react';

import useFileUpload from 'react-use-file-upload';

export interface UseFileLoaderProps {
  inputRef?: MutableRefObject<HTMLInputElement>;
}

export interface FileUploaderState {
  files: File[];
  fileNames: string[];
  removeFile: (index: number) => void;
  setFiles: (e: ChangeEvent | Event, mode?: 'a' | 'w') => void;
  handleLoadFiles: () => void;
  clearAllFiles: () => void;
}

export const useFileLoader = ({
  inputRef,
}: UseFileLoaderProps): FileUploaderState => {
  const { files, setFiles, fileNames, removeFile, clearAllFiles } =
    useFileUpload();

  const handleLoadFiles = () => {
    inputRef?.current?.click();
  };

  return {
    clearAllFiles,
    fileNames,
    files,
    handleLoadFiles,
    removeFile,
    setFiles,
  };
};
