import { FC } from 'react';

import { ChakraBox, ChakraSkeleton, ChakraStack } from '@equip.health/ui';

const LoadingView: FC = () => {
  return (
    <ChakraBox width="100%">
      <ChakraStack spacing="12px">
        {[...Array(5)]
          .map((_, i) => i + 1)
          .map((val) => (
            <ChakraSkeleton
              borderRadius="16px"
              endColor="#F7F7F740"
              height="72px"
              key={`skeleton_loader_${val}`}
              startColor="#F7F7F7"
            />
          ))}
      </ChakraStack>
    </ChakraBox>
  );
};

export default LoadingView;
