import { PropsWithChildren } from 'react';

import { ChakraSimpleGrid } from '@equip.health/ui';

interface BaseCardGridProps extends PropsWithChildren {
  spacing?: string;
  id?: string;
}

const BaseCardGrid = ({ children, spacing, id }: BaseCardGridProps) => {
  return (
    <ChakraSimpleGrid
      columns={{ base: 1, lg: 2, xl: 3 }}
      id={id}
      spacing={spacing || '24px'}
    >
      {children}
    </ChakraSimpleGrid>
  );
};

export default BaseCardGrid;
