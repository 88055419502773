import { ComponentType, FC, useMemo } from 'react';

import { Route, Switch } from 'react-router-dom';

import { SchedulePermissionsContextProvider } from '../lib/context/SchedulePermissionsContext';
import Page from '~/components/Page';
import {
  ExternalLinks,
  GAEventForRouting,
  NavFooterLinks,
} from '~/lib/constants';
import { ChatAttachmentsContextProvider } from '~/lib/context/ChatAttachmentsContext';
import { ChatContextProvider } from '~/lib/context/ChatContext';
import { EnumerationContextProvider } from '~/lib/context/EnumerationContext';
import InquiryReferralProvider from '~/lib/context/InquiryReferralContext';
import { ProfilePageContextProvider } from '~/lib/context/ProfilePageContext';
import { UserProfileContextProvider } from '~/lib/context/UserProfileContext';
import GAEventsTracker from '~/lib/util/useGAEventsTracker.util';
import PageNotFound from '~/pages/404';
import useGaTracker from '~/useGaTracker';

const { VITE_EQUIP_WEBSITE_ADDRESS } = import.meta.env;

export type RouteComponent = {
  path: string;
  component: ComponentType;
};

const ROUTES = import.meta.glob('/src/pages/**/[a-z[]*.tsx', { eager: true });

const parseRoutes = (
  rawRoutes: Record<string, { [key: string]: any }>,
): RouteComponent[] =>
  Object.keys(rawRoutes).map((route: string) => {
    const path = route

      // find all instances of '/src/pages', 'index', and '.tsx' and replace with '' (remove)
      .replace(/\/src\/pages|index|(\.tsx$)/g, '')

      // find all instances of '...' and replace with '*' (catch-all paths)
      .replace(/\[\.{3}.+\]/, '*')

      // split on each '/'
      .split('/')

      // find all instances of '[param]' and replace with ':param' (react router path params)
      .map((subPath: string) => subPath.replace(/^\[(.+)\]$/, ':$1'))

      // rebuild entire path string
      .join('/');
    return { component: rawRoutes[route].default, path };
  });

const Routes: FC = () => {
  useGaTracker();
  const routesLength = Object.keys(ROUTES).length;
  if (window.location.pathname !== '/thankyou') {
    GAEventsTracker(GAEventForRouting);
  }
  const routes = useMemo<RouteComponent[]>(
    () => parseRoutes(ROUTES),
    [routesLength],
  );

  return (
    <Page>
      <EnumerationContextProvider>
        <ChatContextProvider>
          <ChatAttachmentsContextProvider>
            <UserProfileContextProvider>
              <SchedulePermissionsContextProvider>
                <ProfilePageContextProvider>
                  <InquiryReferralProvider>
                    <Switch>
                      {routes.map(({ path, component }: RouteComponent) => (
                        <Route
                          component={component}
                          exact
                          key={`Routes::${path}`}
                          path={path}
                        />
                      ))}
                      {ExternalLinks.map((url: string) => (
                        <Route
                          component={() => {
                            window.location.replace(url);
                            return null;
                          }}
                          key={`Routes::${url}`}
                          path={`/${url}`}
                        />
                      ))}
                      {Object.keys(NavFooterLinks).map((key) =>
                        NavFooterLinks[key].map((item) => (
                          <Route
                            component={() => {
                              window.location.replace(
                                `${VITE_EQUIP_WEBSITE_ADDRESS}${item.path}`,
                              );
                              return null;
                            }}
                            key={`Routes::${item.path}`}
                            path={`/${VITE_EQUIP_WEBSITE_ADDRESS}${item.path}`}
                          />
                        )),
                      )}
                      <Route component={PageNotFound} />
                    </Switch>
                  </InquiryReferralProvider>
                </ProfilePageContextProvider>
              </SchedulePermissionsContextProvider>
            </UserProfileContextProvider>
          </ChatAttachmentsContextProvider>
        </ChatContextProvider>
      </EnumerationContextProvider>
    </Page>
  );
};

export default Routes;
