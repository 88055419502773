import { useEffect, useState } from 'react';

import { ChakraBox, useDisclosure } from '@equip.health/ui';

import CollapsibleList from '~/components/common/Collapsible/CollapsibleList';
import {
  PatientTabItemProps,
  PatientTabSelector,
} from '~/components/common/PatientTabSelector';
import { AdminEpisodeStatus } from '~/lib/constants/user.constants';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { hideBeacon, showBeacon } from '~/lib/util/beacon.util';
import { Recurrence } from '~/lib/util/schedule.util';
import { getPreferredFirstName } from '~/lib/utils';
import { useGroupClassDispatch } from '~/store/hooks/groupClasses';
import { useGetGroupClassesQuery } from '~/store/services/schedule';

import GroupClassCard from './GroupClassCard';
import GroupClassDetailModal from './GroupClassDetailModal';
import GroupClassLoader from './GroupClassLoader';
import SectionTitle from '../common/SectionTitle';
import { EVENT } from '~/lib/constants/analytics';

const TOP_LEVEL_ID = 'group_classes__overview';

interface GroupClassesOverviewProps {
  currentUser: UserProfile;
}

const GroupClassesOverview = ({ currentUser }: GroupClassesOverviewProps) => {
  const [selectedUserId, setSelectedUserId] = useState<string>(
    currentUser.externalId,
  );

  const [selectedGroupClass, setSelectedGroupClass] = useState<GroupClass>();

  const { sortGroupClasses } = useGroupClassDispatch();

  const queryUserId = () => {
    if (selectedUserId === currentUser.externalId) {
      return undefined;
    }
    if (selectedUserId) {
      return selectedUserId;
    }

    return undefined;
  };

  const {
    data: allGroupClasses,
    isFetching,
    isUninitialized,
  } = useGetGroupClassesQuery({
    patientId: queryUserId(),
  });

  useEffect(() => {
    return sortGroupClasses(queryUserId());
  }, []);

  const {
    isOpen: isGroupClassDetailModalOpen,
    onOpen: onGroupClassDetailModalOpen,
    onClose: onGroupClassDetailModalClose,
  } = useDisclosure();

  const { track } = useAnalytics();

  const getUserSelectorTabs = (): PatientTabItemProps[] => {
    if (currentUser.isPatient) {
      return [];
    }

    const patientsToTabs: PatientTabItemProps[] =
      currentUser.linkedPatients
        ?.filter(
          (p) =>
            p.isProxy &&
            p.adminEpisodeStatus === AdminEpisodeStatus.inTreatment,
        )
        .map((p) => ({
          patientId: p.externalId,
          patientName: getPreferredFirstName(p.chosenName, p.firstName),
        })) ?? [];

    return [
      {
        patientId: currentUser.externalId,
        patientName: 'You',
      },
      ...patientsToTabs,
    ];
  };

  const handleUserSelectorClick = (patientId: string) => {
    track(EVENT.HomeGroupClassListPatientSelectorClick);
    setSelectedUserId(patientId);
    sortGroupClasses(queryUserId());
  };

  const handleGroupClassClick = (groupClassId: string) => {
    const groupClass = allGroupClasses.find(
      (g) => g.groupClassExternalId === groupClassId,
    );
    setSelectedGroupClass(groupClass);
    hideBeacon();
    onGroupClassDetailModalOpen();
    track('Group Class Details Opened', {
      'Group Class ID': groupClassId,
    });
  };

  const handleGroupClassModalClose = () => {
    showBeacon();
    setSelectedGroupClass(undefined);
    onGroupClassDetailModalClose();
  };

  const isLoading = isUninitialized || isFetching;

  const patientExternalId =
    selectedUserId !== currentUser.externalId ? selectedUserId : undefined;

  const handleCollapseChange = () => {
    track('Group Classes List Expanded');
  };

  return (
    <>
      <GroupClassDetailModal
        groupClass={selectedGroupClass}
        isOpen={isGroupClassDetailModalOpen}
        isProxy={currentUser?.isProxy || !currentUser?.isPatient}
        onClose={handleGroupClassModalClose}
        patientExternalId={patientExternalId}
      />
      <ChakraBox width="100%">
        {!isLoading && currentUser.isPatient ? (
          <SectionTitle id={TOP_LEVEL_ID} title="Group Classes" />
        ) : (
          <PatientTabSelector
            id={TOP_LEVEL_ID}
            onPatientClick={handleUserSelectorClick}
            patientTabs={getUserSelectorTabs()}
            title="Group Classes"
          />
        )}
        <CollapsibleList
          isLoading={isLoading}
          items={allGroupClasses}
          listId={`${TOP_LEVEL_ID}__list`}
          numberOfCollapsedItems={6}
          onCollapseChange={handleCollapseChange}
          renderItem={(groupClass) => {
            const {
              title,
              description,
              startDate,
              endDate,
              groupClassExternalId,
              iconName,
              isUserRegistered,
              frequency,
              isSensitive,
            } = groupClass;
            return (
              <GroupClassCard
                description={description}
                endDate={endDate}
                frequency={frequency as Recurrence}
                groupClassExternalId={groupClassExternalId}
                iconName={iconName}
                isProxy={!currentUser?.isPatient || currentUser?.isProxy}
                isRegistered={isUserRegistered}
                isSensitive={isSensitive}
                key={groupClassExternalId}
                onClick={handleGroupClassClick}
                patientExternalId={patientExternalId}
                startDate={startDate}
                title={title}
              />
            );
          }}
          renderLoader={() => <GroupClassLoader />}
        />
      </ChakraBox>
    </>
  );
};

export default GroupClassesOverview;
