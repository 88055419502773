import { ChatMessage } from '~/lib/types/chat';

export const MESSAGES_PER_PAGE = 50;

export const MARK_READ_DURATION = 3000;

export const CHAT_LAYOUT = {
  channelListPanelWidthDesktop: '400px',
  headerHeight: '66px',
};

export enum ChatConnectionState {
  NOT_CONNECTED = 'Not Connected',
  CONNECTED = 'Connected',
  CONNECTING = 'Connecting',
  RECONNECTED = 'Reconnected',
  DISCONNECTED = 'Disconnected',
  RECONNECTING = 'Reconnecting',
  CHANNELS_LOADED = 'Channels Loaded',
}

export enum ADMIN_MESSAGE_TYPES {
  FROZEN = 'frozen',
  JOINED = 'joined',
  LEFT = 'left',
  UNFROZEN = 'unfrozen',
  UNKNOWN = 'unknown',
}

export const EM_DASH = '—';
export const TEXT_NEW_LINE = '\n';
export const HTML_LINE_BREAK = '<br/>';
export const SPACE = ' ';
export const HTTP = 'http';
export const UNREAD_MESSAGE_OFFSET = 100;
export const NAME_CONNECTOR = ' + ';

export const NEW_MESSAGE = {
  adminMessageType: ADMIN_MESSAGE_TYPES.UNKNOWN,
  allMentionedUserIds: [],
  channelUrl: '',
  createdAt: 0,
  data: '',
  hasThread: false,
  isAdminMessage: false,
  isFileMessage: false,
  isMentioned: false,
  isNewThread: false,
  isRead: false,
  mentionedUserIds: [],
  mentionedUsers: [],
  message: '',
  messageId: null,
  parentMessageId: -1,
  relativeDate: '',
  sbMessage: null,
  sender: null,
  thread: [],
  threadInfo: null,
  timestamp: '',
} as ChatMessage;

export const GROUP_CHANNEL_URL_PREFIXES = [
  'CCT',
  'ACT',
  'PR_AS',
  'PR_FAMILY',
  'PR_THERAPIST',
  'PR_DIETITIAN',
  'PR_MEDICAL',
  'PR_PSYCH',
  'PR_CCT',
  'PT_CCT',
  'PT_PR_CCT',
];

export const INFORMATIONAL_MODAL_GROUP_CHANNEL_PREFIXES = [
  'PR_CCT',
  'PR_FAMILY',
  'PR_THERAPIST',
  'PR_DIETITIAN',
  'PR_MEDICAL',
  'PR_PSYCH',
  'PT_CCT',
  'PT_PR_CCT',
];

export const ALLOWED_CHANNEL_PREFIXES = {
  admissions: ['PR_AS', 'PT_ADMIT'],
  inTreatment: [
    'PR_AS',
    'PR_FAMILY',
    'PR_THERAPIST',
    'PR_DIETITIAN',
    'PR_MEDICAL',
    'PR_PSYCH',
    'PR_CCT',
    'PT_ADMIT',
    'PT_CCT',
    'PT_DIETITIAN',
    'PT_PEER',
    'PT_PR_CCT',
    'PT_THERAPIST',
    'PT_MEDICAL',
    'PT_PSYCH',
    'SUP_DIETITIAN',
    'SUP_FAMILY',
    'SUP_THERAPIST',
    'SUP_MEDICAL',
    'SUP_PSYCH',
  ],
};

export const EMPTY_CHANNEL = {
  id: 'equip',
  name: 'Equip',
  members: [],
  url: 'equip',
};

// Empty Chat = No Channels for User
export const EMPTY_CHAT_VIEW = {
  message: 'You have no messages yet',
  subTitle:
    "Sit tight. Once your Equip team is assigned, you'll be able to message them here",
};

// Empty Messages = No Messages in Channel
export const EMPTY_MESSAGES_VIEW = {
  message: "There's nothing here yet",
  subTitle: "If you're waiting for someone, they'll probably be here soon.",
};

export enum USER_ROLES {
  PATIENT = 'Patient',
  PROVIDER = 'Provider',
  PROXY = 'Proxy',
  SUPPORT = 'Support',
  UNKNOWN = 'Unknown',
}

export enum CHAT_DIVIDER_TYPE {
  UNREAD_MESSAGE,
  DATE,
}

export enum PROVIDER_TYPE {
  ADMISSIONS_SPECIALIST = 'Admissions Specialist',
}

export const PROVIDER_USER_TYPE = 'Provider';

export const FROZEN = 'frozen';
export const UNFROZEN = 'unfrozen';
export const FROZEN_MESSAGE = 'This channel is no longer active';
export const UNFROZEN_MESSAGE = 'This channel is active';

export const FileValidationStatus: Record<
  FileValidationStatus,
  FileValidationStatus
> = {
  CLEAN: 'CLEAN',
  INVALID: 'INVALID',
  NEW: 'NEW',
  UNKNOWN: 'UNKNOWN',
};

export const PATIENT_ID_METADATA_KEY = 'patientId';
