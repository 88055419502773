import { FC } from 'react';

const DocIcon: FC<BaseSVG> = ({ height = '36', width = '36' }) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 36 36"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#1D61C0" height="36" rx="8" width="36" />
    <path
      d="M13.8981 21.6053C13.9405 21.9388 13.9687 22.2286 13.9817 22.4772H14.0311C14.05 22.2416 14.0892 21.9576 14.1489 21.6254C14.2086 21.2931 14.2624 21.0123 14.3103 20.783L16.5809 10.9827H19.5169L21.8723 20.6357C22.0091 21.2359 22.1071 21.8443 22.1655 22.4572H22.2044C22.2483 21.8634 22.3301 21.2731 22.4494 20.6899L24.3278 10.978H27L23.7001 25.0221H20.578L18.3404 15.7214C18.2756 15.454 18.2014 15.104 18.1201 14.674C18.0389 14.244 17.9882 13.9294 17.9682 13.7314H17.9293C17.9034 13.96 17.8528 14.2993 17.7774 14.7494C17.702 15.1995 17.6416 15.5325 17.596 15.7485L15.4926 25.0197H12.3176L9 10.9827H11.7205L13.7661 20.8029C13.8121 21.0044 13.8557 21.2731 13.8981 21.6053Z"
      fill="white"
    />
  </svg>
);

export default DocIcon;
