import { useCallback, useEffect, useRef, useState } from 'react';

import isNil from 'lodash/isNil';

import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import useApi from '~/lib/hooks/useApi';

const { getProviders: getProvidersUrl } = urlConstants.users;

export type UseCareTeamState = {
  isLoading?: boolean;
  isCareTeamFetched?: boolean;
  patientIdsWithCareTeam?: string[];
  careTeamForPatient?: (patientId: string) => CareTeamProvider[];
};

const useCareTeam = (): UseCareTeamState => {
  const { isFullProfileInfoFetched, userProfile: currentUser } =
    useUserProfileContext();

  const patientIdQueueRef = useRef<string[]>([]);
  const patientCareTeamMapperRef = useRef<Record<string, CareTeamProvider[]>>(
    {},
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCareTeamFetched, setIsCareTeamFetched] = useState<boolean>(false);
  const [patientIdsWithCareTeam, setPatientIdsWithCareTeam] = useState<
    string[]
  >([]);

  const { sendRequest: fetchPatientProviders } = useApi<CareTeamResponse>();

  useEffect(() => {
    const isSupportWithProfileInfoLoading =
      currentUser && !currentUser.isPatient && !isFullProfileInfoFetched;

    if (isNil(currentUser) || isSupportWithProfileInfoLoading) {
      return;
    }

    if (currentUser.isPatient) {
      patientIdQueueRef.current = [currentUser.externalId];
    } else {
      patientIdQueueRef.current = currentUser?.linkedPatients?.map(
        (patient) => patient.externalId,
      );
    }

    setIsLoading(true);
    processNextPatient();
  }, [isFullProfileInfoFetched, currentUser]);

  const updatePatientsWithCareTeam = () => {
    const patientIds = Object.keys(patientCareTeamMapperRef.current).filter(
      (patientId) => {
        return patientCareTeamMapperRef.current[patientId]?.length > 0;
      },
    );

    setPatientIdsWithCareTeam(patientIds);
  };

  const processNextPatient = useCallback(() => {
    if (patientIdQueueRef.current.length === 0) {
      setIsCareTeamFetched(true);
      setIsLoading(false);
      updatePatientsWithCareTeam();
      return;
    }

    const patientId = patientIdQueueRef.current[0]; // Remove and retrieve the first patient ID from the queue

    fetchPatientProviders({
      url: getProvidersUrl(patientId),
      command: ApiCommand.GET,
      callback: (response) => {
        const updatedMapper = { ...patientCareTeamMapperRef.current };
        updatedMapper[patientId] = response?.careTeam?.providers;

        patientCareTeamMapperRef.current = updatedMapper;

        patientIdQueueRef.current = patientIdQueueRef.current.slice(1); // Remove the first patient ID from the queue
        processNextPatient();
      },
    });
  }, [patientCareTeamMapperRef]);

  const careTeamForPatient = (patientId: string) => {
    return patientCareTeamMapperRef.current[patientId];
  };

  return {
    isLoading,
    isCareTeamFetched,
    patientIdsWithCareTeam,
    careTeamForPatient,
  };
};

export default useCareTeam;
