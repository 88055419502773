import { ChakraBox, ChakraFlex } from '@equip.health/ui';

import ChatChannelList from './ChatChannelList';
import ChatHeader from './ChatHeader';
import ChatPanel from './ChatPanel';
import {
  CHAT_LAYOUT,
  EMPTY_CHANNEL,
} from '~/lib/constants/chat/chat.constants';

interface EmptyChatProps {
  currentUserId: string;
  id: string;
  topMargin: number | string;
}

const idPrefix = 'Empty';
const idPostfix = 'Chat';

const EmptyChat = ({ currentUserId, id, topMargin }: EmptyChatProps) => {
  const messagesPanelContainerProps = {
    height: `calc(100vh - ${topMargin} - ${CHAT_LAYOUT.headerHeight})`,
    overflow: 'auto',
  };

  return (
    <ChakraFlex
      alignItems="start"
      direction="column"
      height="100%"
      id={`${idPrefix}__page__${idPostfix}`}
      width="100%"
    >
      <ChakraFlex
        direction="row"
        id={`${idPrefix}__container__${idPostfix}`}
        width="100%"
      >
        <ChakraBox
          flexGrow={0}
          flexShrink={0}
          id={`${idPrefix}__channel-list-container__${idPostfix}`}
          width={{
            base: '100%',
            md: CHAT_LAYOUT.channelListPanelWidthDesktop,
          }}
        >
          <ChatChannelList
            channels={[]}
            id="equip"
            isPatient={false}
            patient={null}
          />
        </ChakraBox>
        <ChakraFlex direction="column" width="100%">
          <ChatHeader {...EMPTY_CHANNEL} />
          <ChakraBox
            id={`${idPrefix}__messages-panel-container__${idPostfix}`}
            {...messagesPanelContainerProps}
          >
            <ChatPanel currentUserId={currentUserId} id="equip" isEmptyChat />
          </ChakraBox>
        </ChakraFlex>
      </ChakraFlex>
    </ChakraFlex>
  );
};

EmptyChat.displayName = 'EmptyChat';
export default EmptyChat;
