import {
  Context,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import * as Sentry from '@sentry/react';

import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { useApi } from '~/lib/hooks';

import { RelationToPatientCategory } from '../constants/enumeration.constants';
import useFeatureFlags from '../hooks/useFeatureFlags';

export enum Enumerations {
  CONTACT_PREFERENCES = 'contact-preferences',
  GENDERS = 'genders',
  HEAR_ABOUT_EQUIP_SOURCES = 'hearAboutEquipSources',
  EQUIP_INQUIRY_SOURCES = 'equipInquirySources',
  LANGUAGES = 'languages',
  PAYORS = 'payors',
  PRONOUNS = 'pronouns',
  RELATIONS_TO_PATIENT = 'relationsToPatient',
  STATES = 'states',
  TIMEZONES = 'timezones',
  TIMEZONES_WITHOUT_TOKENS = 'timeszones', // enum call is made without any auth token
}

type FetchEnumerationsParams = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFunction: (params: SendRequestParams<any>) => void;
  skipToken: boolean;
  url: string;
};

type EnumerationsInitState = Record<Enumerations, boolean>;

type EnumerationsInitAction = {
  field: Enumerations;
  init: boolean;
};

type EnumerationsInitReducer = (
  state: EnumerationsInitState,
  action: EnumerationsInitAction,
) => EnumerationsInitState;

const DEFAULT_INIT_STATE = {
  [Enumerations.CONTACT_PREFERENCES]: false,
  [Enumerations.EQUIP_INQUIRY_SOURCES]: false,
  [Enumerations.GENDERS]: false,
  [Enumerations.HEAR_ABOUT_EQUIP_SOURCES]: false,
  [Enumerations.LANGUAGES]: false,
  [Enumerations.PAYORS]: false,
  [Enumerations.PRONOUNS]: false,
  [Enumerations.RELATIONS_TO_PATIENT]: false,
  [Enumerations.STATES]: false,
  [Enumerations.TIMEZONES_WITHOUT_TOKENS]: false,
  [Enumerations.TIMEZONES]: false,
};

const enumerationsInitReducer: EnumerationsInitReducer = (
  state: EnumerationsInitState,
  { field, init }: EnumerationsInitAction,
): EnumerationsInitState => ({
  ...state,
  [field]: init,
});

export type TEnumerationContext = {
  contactPreferences: string[];
  fetchEnumerations: (enums: Enumerations[]) => void;
  genders: string[];
  isContactPreferencesLoading: boolean;
  isGendersLoading: boolean;
  isHearAboutEquipSourcesLoading: boolean;
  isLanguagesLoading: boolean;
  isPayorsLoading: boolean;
  isPronounsLoading: boolean;
  isRelationsToPatientLoading: boolean;
  isRelationsToPatientLoadingV2: boolean;
  isStatesLoading: boolean;
  isTimezonesLoading: boolean;
  hearAboutEquipSources: EquipSource[];
  equipInquirySources: EquipInquirySource[];
  isEquipInquirySourcesLoading: boolean;
  languages: string[];
  payors: Payor[];
  pronouns: string[];
  relationsToPatientInquiry:
    | InquiryRelationToPatient[]
    | InquiryRelationToPatientV2[];
  relationsToPatientReferral:
    | InquiryRelationToPatient[]
    | InquiryRelationToPatientV2[];
  states: { label: string; externalId: string }[];
  timezones: string[];
  timezonesRaw: { code: string; id: string; label: string }[];
};

export const EnumerationContext: Context<TEnumerationContext> =
  createContext<TEnumerationContext>({
    contactPreferences: [],
    equipInquirySources: [],
    fetchEnumerations: () => {},
    genders: [],
    hearAboutEquipSources: [],
    isContactPreferencesLoading: false,
    isEquipInquirySourcesLoading: false,
    isGendersLoading: false,
    isHearAboutEquipSourcesLoading: false,
    isLanguagesLoading: false,
    isPayorsLoading: false,
    isPronounsLoading: false,
    isRelationsToPatientLoading: false,
    isRelationsToPatientLoadingV2: false,
    isStatesLoading: false,
    isTimezonesLoading: false,
    languages: [],
    payors: [],
    pronouns: [],
    relationsToPatientInquiry: [],
    relationsToPatientReferral: [],
    states: [],
    timezones: [],
    timezonesRaw: [],
  });

export const EnumerationContextProvider = ({ children }: PropsWithChildren) => {
  const [initState, dispatch] = useReducer<EnumerationsInitReducer>(
    enumerationsInitReducer,
    DEFAULT_INIT_STATE,
  );

  const { isInquiryV2Enabled } = useFeatureFlags();

  const {
    data: contactPreferences,
    error: contactPreferenceError,
    loading: isContactPreferencesLoading,
    sendRequest: getContactPreferences,
  } = useApi<Enumeration[]>();

  const {
    data: genders,
    error: getGendersError,
    loading: isGendersLoading,
    sendRequest: getGenders,
  } = useApi<Enumeration[]>();

  const {
    data: hearAboutEquipSources,
    error: getHearAboutEquipSourcesError,
    loading: isHearAboutEquipSourcesLoading,
    sendRequest: getHearAboutEquipSources,
  } = useApi<EquipSource[]>();

  const {
    data: equipInquirySources,
    error: getEquipInquirySourcesError,
    loading: isGetEquipInquirySourcesLoading,
    sendRequest: getEquipInquirySources,
  } = useApi<EquipInquirySource[]>();

  const {
    data: languages,
    error: getLanguagesError,
    loading: isLanguagesLoading,
    sendRequest: getLanguages,
  } = useApi<Enumeration[]>();

  const {
    data: payors,
    error: getPayorsError,
    loading: isPayorsLoading,
    sendRequest: getPayors,
  } = useApi<Payor[]>();

  const {
    data: pronouns,
    error: getPronounsError,
    loading: isPronounsLoading,
    sendRequest: getPronouns,
  } = useApi<Enumeration[]>();

  const {
    data: relationsToPatientSource,
    error: getRelationsToPatientError,
    loading: isRelationsToPatientLoading,
    sendRequest: getRelationsToPatient,
  } = useApi<InquiryRelationToPatient[]>();

  const {
    data: relationsToPatientSourceV2,
    error: getRelationsToPatientErrorV2,
    loading: isRelationsToPatientLoadingV2,
    sendRequest: getRelationsToPatientV2,
  } = useApi<InquiryRelationToPatientV2[]>();

  const {
    data: timezones,
    error: getTimezonesError,
    loading: isTimezonesLoading,
    sendRequest: getTimezones,
  } = useApi<EnumerationWithCode[]>();

  const sortedContactPreferences = useMemo<string[]>(
    () => (contactPreferences ?? []).map((preference) => preference?.label),
    [contactPreferences],
  );

  const sortedGenders = useMemo<string[]>(
    () => (genders ?? []).map((gender) => gender?.label),
    [genders],
  );

  const sortedLanguages = useMemo<string[]>(
    () => (languages ?? []).map((language) => language?.label),
    [languages],
  );

  const sortedPronouns = useMemo<string[]>(
    () => (pronouns ?? []).map((pronoun) => pronoun?.label),
    [pronouns],
  );

  const sortedTimezones = useMemo<string[]>(
    () => (timezones ?? []).map(({ label }) => label),
    [timezones],
  );

  const {
    data: states,
    error: getStatesError,
    loading: isStatesLoading,
    sendRequest: getStates,
  } = useApi<State[]>();

  const fetchEnumerationsMap = useMemo<
    Record<Enumerations, FetchEnumerationsParams>
  >(
    () => ({
      [Enumerations.CONTACT_PREFERENCES]: {
        getFunction: getContactPreferences,
        skipToken: false,
        url: urlConstants.users.getContactPreferences,
      },
      [Enumerations.GENDERS]: {
        getFunction: getGenders,
        skipToken: false,
        url: urlConstants.users.getGenders,
      },
      [Enumerations.HEAR_ABOUT_EQUIP_SOURCES]: {
        getFunction: getHearAboutEquipSources,
        skipToken: true,
        url: urlConstants.inquiry_referral.listOfSources,
      },
      [Enumerations.LANGUAGES]: {
        getFunction: getLanguages,
        skipToken: false,
        url: urlConstants.users.getLanguages,
      },
      [Enumerations.EQUIP_INQUIRY_SOURCES]: {
        getFunction: getEquipInquirySources,
        skipToken: true,
        url: urlConstants.inquiry_referral.listEquipSources,
      },
      [Enumerations.PAYORS]: {
        getFunction: getPayors,
        skipToken: true,
        url: urlConstants.clinicalData.listPayors,
      },
      [Enumerations.PRONOUNS]: {
        getFunction: getPronouns,
        skipToken: false,
        url: urlConstants.users.getPronouns,
      },
      [Enumerations.RELATIONS_TO_PATIENT]: {
        getFunction: getRelationsToPatient,
        skipToken: true,
        url: urlConstants.inquiry_referral.getRelationsToPatient,
      },
      [Enumerations.STATES]: {
        getFunction: getStates,
        skipToken: true,
        url: urlConstants.inquiry_referral.getStates,
      },
      [Enumerations.TIMEZONES]: {
        getFunction: getTimezones,
        skipToken: false,
        url: urlConstants.users.getTimezones,
      },
      [Enumerations.TIMEZONES_WITHOUT_TOKENS]: {
        getFunction: getTimezones,
        skipToken: true,
        url: urlConstants.users.getTimezones,
      },
    }),
    [],
  );

  const fetchEnumerations = (enums: Enumerations[] = []): void => {
    if (isInquiryV2Enabled) {
      getRelationsToPatientV2({
        command: ApiCommand.GET,
        skipToken: true,
        url: urlConstants.users.getRelationsToPatient,
      });
      dispatch({ field: Enumerations.RELATIONS_TO_PATIENT, init: true });
    }
    enums.forEach((enumKey: Enumerations) => {
      if (!initState[enumKey]) {
        const { getFunction, skipToken, url } = fetchEnumerationsMap[enumKey];
        getFunction({ command: ApiCommand.GET, skipToken, url });
        dispatch({ field: enumKey, init: true });
      }
    });
  };

  const { relationsToPatientInquiry, relationsToPatientReferral } = useMemo<{
    relationsToPatientInquiry:
      | InquiryRelationToPatient[]
      | InquiryRelationToPatientV2[];
    relationsToPatientReferral:
      | InquiryRelationToPatient[]
      | InquiryRelationToPatientV2[];
  }>(() => {
    if (isInquiryV2Enabled) {
      const result = {
        [RelationToPatientCategory.FAMILY]: [],
        [RelationToPatientCategory.NON_FAMILY]: [],
      };

      for (const relation of relationsToPatientSourceV2 ?? []) {
        result[relation.category].push(relation);
      }

      return {
        relationsToPatientInquiry: result[RelationToPatientCategory.FAMILY],
        relationsToPatientReferral:
          result[RelationToPatientCategory.NON_FAMILY],
      };
    }
    const result = { inquiry: [], referral: [] };

    for (const relation of relationsToPatientSource ?? []) {
      result[relation.relationToPatientType].push(relation);
    }

    return {
      relationsToPatientInquiry: result.inquiry,
      relationsToPatientReferral: result.referral,
    };
  }, [relationsToPatientSource, relationsToPatientSourceV2]);

  useEffect(() => {
    if (contactPreferenceError) {
      Sentry.captureMessage(
        `Contact Preference Error - ${contactPreferenceError}`,
      );
    }
  }, [contactPreferenceError]);
  useEffect(() => {
    if (getGendersError) {
      Sentry.captureMessage(`Contact Preference Error - ${getGendersError}`);
    }
  }, [getGendersError]);
  useEffect(() => {
    if (getHearAboutEquipSourcesError || getEquipInquirySourcesError) {
      const error =
        getHearAboutEquipSourcesError || getEquipInquirySourcesError;
      Sentry.captureMessage(`Equip Inquiry Sources Error - ${error}`);
    }
  }, [getHearAboutEquipSourcesError, getEquipInquirySourcesError]);
  useEffect(() => {
    if (getLanguagesError) {
      Sentry.captureMessage(`Languages Error - ${getLanguagesError}`);
    }
  }, [getLanguagesError]);
  useEffect(() => {
    if (getPayorsError) {
      Sentry.captureMessage(`Payors Error - ${getPayorsError}`);
    }
  }, [getPayorsError]);
  useEffect(() => {
    if (getPronounsError) {
      Sentry.captureMessage(`Pronouns Error - ${getPronounsError}`);
    }
  }, [getPronounsError]);
  useEffect(() => {
    if (getRelationsToPatientError) {
      Sentry.captureMessage(
        `Relations To Patient Error - ${getRelationsToPatientError}`,
      );
    }
  }, [getRelationsToPatientError]);
  useEffect(() => {
    if (getRelationsToPatientErrorV2) {
      Sentry.captureMessage(
        `Relations To Patient V2 Error - ${getRelationsToPatientErrorV2}`,
      );
    }
  }, [getRelationsToPatientErrorV2]);
  useEffect(() => {
    if (getTimezonesError) {
      Sentry.captureMessage(`Timezones Error - ${getTimezonesError}`);
    }
  }, [getTimezonesError]);
  useEffect(() => {
    if (getStatesError) {
      Sentry.captureMessage(`States Error - ${getStatesError}`);
    }
  }, [getStatesError]);
  return (
    <EnumerationContext.Provider
      value={{
        contactPreferences: sortedContactPreferences,
        equipInquirySources,
        fetchEnumerations,
        genders: sortedGenders,
        hearAboutEquipSources,
        isContactPreferencesLoading,
        isEquipInquirySourcesLoading: isGetEquipInquirySourcesLoading,
        isGendersLoading,
        isHearAboutEquipSourcesLoading,
        isLanguagesLoading,
        isPayorsLoading,
        isPronounsLoading,
        isRelationsToPatientLoading,
        isRelationsToPatientLoadingV2,
        isStatesLoading,
        isTimezonesLoading,
        languages: sortedLanguages,
        payors,
        pronouns: sortedPronouns,
        relationsToPatientInquiry,
        relationsToPatientReferral,
        states,
        timezones: sortedTimezones,
        timezonesRaw: timezones,
      }}
    >
      {children}
    </EnumerationContext.Provider>
  );
};

export const useEnumerationContext = (): TEnumerationContext => {
  const context = useContext(EnumerationContext);
  if (!context) {
    throw new Error(
      'useEnumerationContext must be used within a EnumerationContextProvider.',
    );
  }
  return context;
};
