import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraImage,
  ChakraSkeleton,
  EquipUIFireflyV1Theme,
  FireflyButton,
  Text,
  Trash,
} from '@equip.health/ui';
import * as Sentry from '@sentry/react';

import DiscardChangesModal from '~/components/common/DiscardChangesModal';
import ErrorMessage from '~/components/common/ErrorMessage';
import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { useApi } from '~/lib/hooks';
import useS3FileUpload from '~/lib/hooks/useS3FileUpload';

const { black } = EquipUIFireflyV1Theme.colors;

export const enum CARD_SIDE {
  FRONT,
  BACK,
}

const ACCEPTED_IMAGE_EXTENSION = ['jpg', 'jpeg', 'png'];

const ACCEPTED_FILE_EXTENSIONS = [
  ...ACCEPTED_IMAGE_EXTENSION,
  'pdf',
  'doc',
  'docx',
];

const INPUT_ACCEPT = ACCEPTED_FILE_EXTENSIONS.map(
  (ext: string) => `.${ext}`,
).join(',');

const MAX_FILE_SIZE_IN_MB = 25;

const isAllowedFileSize = (file: File): boolean => {
  if (!file) return false;
  const sizeInBytes = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
  return file.size <= sizeInBytes;
};

export const getFileExtension = (filename: string) =>
  filename.split('.').pop().toLowerCase();

type AttachmentItemType = {
  attachmentId: string | null;
  cardSide: CARD_SIDE;
  isDisabled: boolean;
  noteId: string;
  setAttachmentId: Dispatch<SetStateAction<string | null>>;
  setIsFileUploading: Dispatch<SetStateAction<boolean>>;
};

const {
  deleteAttachment: deleteAttachmentURL,
  getAttachmentUpload: getAttachmentUploadURL,
} = urlConstants.tasks;

const CardFrontLoader = () => (
  <>
    <ChakraSkeleton height="25px" marginBottom="16px" width="40%" />
    <ChakraSkeleton height="10px" marginBottom="8px" width="100%" />
    <ChakraSkeleton height="10px" marginBottom="8px" width="85%%" />
    <ChakraSkeleton height="10px" marginBottom="50px" width="85" />
    <ChakraSkeleton height="10px" width="100%" />
  </>
);
const CardBackLoader = () => (
  <>
    <ChakraSkeleton
      height="10px"
      marginBottom="8px"
      marginTop="40px"
      width="100%"
    />
    <ChakraSkeleton height="10px" marginBottom="8px" width="100%" />
    <ChakraSkeleton height="10px" marginBottom="8px" width="100%" />
    <ChakraSkeleton height="10px" marginBottom="40px" width="100%" />
    <ChakraSkeleton height="10px" width="100%" />
  </>
);

const topLevelId = 'insurance-attachment-task__modal';

const AttachmentItem: FC<AttachmentItemType> = ({
  attachmentId,
  cardSide,
  isDisabled,
  noteId,
  setAttachmentId,
  setIsFileUploading,
}: AttachmentItemType) => {
  const [file, setFile] = useState<File>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const [showDiscardChangesModal, setShowDiscardChangesModal] =
    useState<boolean>(false);

  const [fileError, setFileError] = useState<string | null>(null);

  const fileUploadRef = useRef<HTMLInputElement>(null);

  const {
    loading: getAttachmentUploadUrlLoading,
    data: getAttachmentUploadData,
    error: getAttachmentUploadUrlError,
    sendRequest: getAttachmentUploadUrlSendRequest,
  } = useApi<{
    attachmentExternalId?: string;
    attachmentUploadUrl?: string;
  }>();

  const { error: deleteImageAttachmentError, sendRequest: deleteAttachment } =
    useApi();

  const {
    error: attachmentUploadError,
    isFileUploaded,
    loading: isAttachmentUploading,
    sendRequest: sendAttachmentUploadRequest,
  } = useS3FileUpload();

  const isLoading = getAttachmentUploadUrlLoading || isAttachmentUploading;

  const onDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (['dragenter', 'dragover'].includes(event?.type) && !isLoading && !file)
      setIsDragging(true);
    else if (event?.type === 'dragleave') setIsDragging(false);
  };

  const validateAndUploadFile = (updatedFile: File) => {
    if (file || isLoading) return;
    const { name } = updatedFile;
    setFileError(null);
    if (
      !ACCEPTED_FILE_EXTENSIONS.includes(getFileExtension(name.toLowerCase()))
    )
      setFileError(`We only accept ${ACCEPTED_FILE_EXTENSIONS.join(', ')}.`);
    else if (!isAllowedFileSize(updatedFile))
      setFileError('We do not accept files larger than 25 MB.');
    else setFile(updatedFile);
  };

  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    if ((event?.dataTransfer?.files ?? [])[0]) {
      validateAndUploadFile(event.dataTransfer.files[0]);
    }
  };

  const onFileSelect = (event) => {
    const inputElement = event?.target as HTMLInputElement;
    if ((event?.target?.files ?? []).length > 0)
      validateAndUploadFile(inputElement.files[0]);
  };

  const onDeleteImage = () => setShowDiscardChangesModal(true);

  const onDiscardImage = () => {
    if (attachmentId) {
      deleteAttachment({
        command: ApiCommand.DELETE,
        url: deleteAttachmentURL(noteId, attachmentId),
      });
    }
    setShowDiscardChangesModal(false);
    setAttachmentId(null);
    setFile(null);
  };

  useEffect(() => {
    if (getAttachmentUploadData?.attachmentUploadUrl) {
      const formData = new FormData();
      formData.append('attachment', file);

      sendAttachmentUploadRequest({
        file,
        url: getAttachmentUploadData.attachmentUploadUrl,
      });
    }
  }, [getAttachmentUploadData?.attachmentUploadUrl]);

  useEffect(() => {
    if (file) {
      getAttachmentUploadUrlSendRequest({
        command: ApiCommand.GET,
        url: getAttachmentUploadURL(
          noteId,
          `${Date.now()}-${file.name.toLowerCase()}`,
        ),
      });
    }
  }, [file]);

  useEffect(() => {
    setAttachmentId(getAttachmentUploadData?.attachmentExternalId);
  }, [isFileUploaded]);

  useEffect(() => {
    setIsFileUploading(getAttachmentUploadUrlLoading || isAttachmentUploading);
  }, [getAttachmentUploadUrlLoading, isAttachmentUploading]);

  useEffect(() => {
    if (attachmentUploadError || getAttachmentUploadUrlError) {
      setFile(null);
      setAttachmentId(null);
      Sentry.captureMessage(
        `Upload Attachment Error - ${
          attachmentUploadError || getAttachmentUploadUrlError
        }`,
      );
    }
  }, [attachmentUploadError, getAttachmentUploadUrlError]);

  useEffect(() => {
    if (deleteImageAttachmentError) {
      Sentry.captureMessage(
        `Delete Attachment Error - ${deleteImageAttachmentError}`,
      );
    }
  }, [deleteImageAttachmentError]);

  return (
    <ChakraBox>
      <ChakraBox
        background="black.5"
        border={
          file ? 'none' : `4px dashed ${isDragging ? black[30] : 'transparent'}`
        }
        borderRadius="12px"
        boxSizing="border-box"
        height="187px"
        marginBottom="12px"
        onDragEnter={onDrag}
        onDragLeave={onDrag}
        onDragOver={onDrag}
        onDrop={onDrop}
        overflow="hidden"
        position="relative"
        width="336px"
      >
        {isLoading ? (
          <ChakraBox height="100%" padding="16px 24px">
            {cardSide === CARD_SIDE.FRONT ? (
              <CardFrontLoader />
            ) : (
              <CardBackLoader />
            )}
          </ChakraBox>
        ) : (
          <>
            {file ? (
              <ChakraBox>
                {ACCEPTED_IMAGE_EXTENSION.includes(
                  getFileExtension(file.name.toLowerCase()),
                ) ? (
                  <ChakraImage
                    height="187px"
                    objectFit="cover"
                    src={URL.createObjectURL(file)}
                    width="336px"
                  />
                ) : (
                  <Text
                    color="neutral.secondary"
                    id={`${topLevelId}__file-name`}
                    marginLeft="24px"
                    marginTop="54px"
                    noOfLines={2}
                    variant="h5"
                  >
                    {file.name}
                  </Text>
                )}
                {!isDisabled && (
                  <ChakraFlex
                    alignItems="center"
                    background="white"
                    border={`2px solid ${black[90]}`}
                    borderRadius="50%"
                    bottom="16px"
                    cursor="pointer"
                    height="38px"
                    justifyContent="center"
                    onClick={onDeleteImage}
                    position="absolute"
                    right="16px"
                    width="38px"
                  >
                    <Trash fill={black[90]} height="20px" />
                  </ChakraFlex>
                )}
              </ChakraBox>
            ) : (
              <ChakraBox padding="16px 24px">
                <Text color="neutral.secondary" variant="h3">
                  {cardSide === CARD_SIDE.FRONT ? 'Front' : 'Back'}
                </Text>
                <Text marginY="24px" variant="h5">
                  Drag image here
                </Text>

                <FireflyButton
                  id={`${topLevelId}__upload-file`}
                  onClick={() => {
                    if (fileUploadRef?.current) fileUploadRef.current.click();
                  }}
                  variant="secondary"
                >
                  Or choose file
                </FireflyButton>

                <input
                  accept={INPUT_ACCEPT}
                  onChange={onFileSelect}
                  ref={fileUploadRef}
                  style={{ display: 'none' }}
                  type="file"
                />
              </ChakraBox>
            )}
          </>
        )}
      </ChakraBox>
      {(getAttachmentUploadUrlError || attachmentUploadError || fileError) && (
        <ChakraBox width="336px">
          <ErrorMessage
            id="attachment__upload-error"
            message={fileError ?? 'Unable to upload.'}
            textAlign="left"
          />
        </ChakraBox>
      )}
      <DiscardChangesModal
        confirmButtonText="Delete"
        description="This cannot be undone."
        handleClose={() => setShowDiscardChangesModal(false)}
        handleConfirmDiscard={onDiscardImage}
        isOpen={showDiscardChangesModal}
        showCancelButton
        title="Delete attachment?"
      />
    </ChakraBox>
  );
};

export default AttachmentItem;
