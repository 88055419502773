import {
  ChakraBox,
  ChakraImage,
  ChakraText,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

import GroupIllustrationImg from '~/assets/img/group_illustration.png';
import { SchedulerStep } from '~/lib/constants/inquiry.constants';
import { ReactNode } from 'react';

const { h3, paragraph } = EquipUIFireflyV1Theme.typography;

const TOP_LEVEL_ID = 'inquiry__schedule-appointment__header';

const defaultTitle = "We've received your information";

const title: Record<SchedulerStep, string> = {
  [SchedulerStep.SELECT_SLOT]: defaultTitle,
  [SchedulerStep.ADD_CONTACT_INFO]: defaultTitle,
  [SchedulerStep.THANK_YOU]: 'We look forward to talking to you soon!',
};

type HeaderProps = {
  showHeaderImage: boolean;
  step: SchedulerStep;
  subtitle: ReactNode;
};

const Header = ({ showHeaderImage, step, subtitle }: HeaderProps) => (
  <>
    {showHeaderImage && (
      <ChakraImage
        alt="Form sent"
        id={`${TOP_LEVEL_ID}__submit-image`}
        src={GroupIllustrationImg}
        width={['100%', '100%', '325px', '380px', '380px']}
      />
    )}
    <ChakraBox
      {...h3}
      id={`${TOP_LEVEL_ID}__title`}
      paddingBottom="24px"
      paddingTop={showHeaderImage ? '40px' : 'unset'}
      textAlign="center"
    >
      <ChakraText>{title[step]}</ChakraText>
    </ChakraBox>
    <ChakraBox
      {...paragraph}
      color="mono.70"
      fontWeight={400}
      id={`${TOP_LEVEL_ID}__subtitle`}
      paddingBottom="45px"
      textAlign="center"
    >
      {subtitle}
    </ChakraBox>
  </>
);

export default Header;
