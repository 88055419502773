import { FC } from 'react';

import { ChakraBox, ChakraText, EquipUITheme } from '@equip.health/ui';

import { LAYOUT_DIMENSIONS } from '~/lib/constants/survey.constants';
import { removeUrlsFromText } from '~/lib/util/forms.util';
import {
  getAnswerTitle,
  getQuestion,
  getQuestionTitle,
} from '~/lib/util/survey.util';
import { isMobileDevice } from '~/lib/utils';

const { h5, paragraph } = EquipUITheme.typography;

type SurveyResultsProps = {
  data: any;
  definition: SurveyDefinition;
  surveyCompletionDate: string;
  title: string;
};

const SurveyResults: FC<SurveyResultsProps> = ({
  data,
  definition,
  surveyCompletionDate,
  title,
}) => (
  <ChakraBox
    marginBottom={isMobileDevice() ? LAYOUT_DIMENSIONS.mobileBuffer : 0}
    paddingX={{ base: '8px', lg: '80px' }}
    paddingY="16px"
  >
    {surveyCompletionDate && (
      <ChakraText align="right" fontStyle="italic" marginBottom="24px">
        {`Completed: ${surveyCompletionDate}`}
      </ChakraText>
    )}
    {Object.entries(data ?? {})
      .filter(
        ([question, _answer]: [string, any[]]) =>
          !['currentPage', 'currentPageNo'].includes(question),
      )
      .map(([question, answer]: [question: string, answer: any]) => {
        const id = `survey-results__${title}__${question}`;
        const { html } = getQuestion(definition, question) ?? {};
        return (
          <ChakraBox id={id} key={id} marginBottom="16px">
            {html ? (
              <ChakraText
                {...h5}
                dangerouslySetInnerHTML={{
                  __html: html.replace(/\\"/g, '"'),
                }}
                id={`${id}__label`}
              />
            ) : (
              <ChakraText {...h5} id={`${id}__label`} marginBottom="8px">
                {getQuestionTitle(definition, question)}
              </ChakraText>
            )}
            {getAnswerTitle(definition, question, answer).map(
              (answerText: string) => {
                const parsedAnswerText = removeUrlsFromText(
                  answerText,
                  '[Link]',
                );
                return (
                  <ChakraText
                    {...paragraph}
                    id={`survey-results__answer__${answer}`}
                    key={parsedAnswerText}
                    marginLeft="16px"
                  >
                    {parsedAnswerText}
                  </ChakraText>
                );
              },
            )}
          </ChakraBox>
        );
      })}
  </ChakraBox>
);

export default SurveyResults;
