import { getAgeFromDob } from '@equip.health/ui';

import { AdminEpisodeStatus } from '../constants/user.constants';

export const getAgeFromProfile = (userProfile: UserProfile): number => {
  if (userProfile.dateOfBirth) {
    return getAgeFromDob(userProfile.dateOfBirth);
  }
  return null;
};

export const getIsPediatricProfile = (userProfile: UserProfile): boolean => {
  const age = getAgeFromProfile(userProfile);

  if (age) {
    return getAgeFromProfile(userProfile) < 10;
  }
  return false;
};

export const getIsTeenProfile = (userProfile: UserProfile): boolean => {
  const age = getAgeFromProfile(userProfile);
  if (age) {
    return age >= 10 && age <= 17;
  }

  return false;
};

export const sortUserProfileByAgeASC = (a: UserProfile, b: UserProfile) => {
  if (a.dateOfBirth < b.dateOfBirth) {
    return -1;
  }
  if (a.dateOfBirth > b.dateOfBirth) {
    return 1;
  }
  return 0;
};

export const getIsAdultProfile = (userProfile: UserProfile): boolean => {
  const age = getAgeFromProfile(userProfile);
  if (age) {
    return getAgeFromProfile(userProfile) >= 18;
  }
  return false;
};

export const formatGenderPronoun = (
  gender: string,
  pronouns: string[],
): string => {
  if (gender && pronouns?.length > 0)
    return `${gender} (${pronouns.join(', ')})`;
  if (!(gender || pronouns?.length > 0)) return '';
  return gender || pronouns?.join(', ');
};

export const isOrHasPatientInTreatment = (
  userProfile: UserProfile,
): boolean => {
  if (!userProfile) return false;
  if (userProfile.isPatient) {
    return userProfile?.adminEpisodeStatus === AdminEpisodeStatus.inTreatment;
  }
  return userProfile.linkedPatients?.some(
    (p) => p.adminEpisodeStatus === AdminEpisodeStatus.inTreatment,
  );
};

export const isPatientInTreatment = (userProfile: UserProfile): boolean => {
  return userProfile?.adminEpisodeStatus === AdminEpisodeStatus.inTreatment;
};
