import {
  ChakraBox,
  ChakraFlex,
  ChakraLink,
  EquipUIFireflyV2Theme,
  FAIconName,
  FireflyButton,
  Icon,
  Text,
} from '@equip.health/ui';

import {
  AUTHENTICATED_NAV_LAYOUT,
  FacebookLink,
  FooterLink,
  InstagramLink,
  LinkedInLink,
  MY_EQUIP_V2_DIMENSIONS,
  TwitterLink,
} from '~/lib/constants';
import { VITE_MYEQUIP_APP_VERSION } from '~/lib/constants/env';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';
import { buildEquipSiteLink } from '~/lib/util/authenticatedNav.util';
import { getAppVersion } from '~/lib/utils';

const { VITE_EQUIP_WEBSITE_ADDRESS } = import.meta.env;

const { primary } = EquipUIFireflyV2Theme.colors;
const FOOTER_LINKS = [
  {
    text: 'Terms of Use',
    url: buildEquipSiteLink(VITE_EQUIP_WEBSITE_ADDRESS, FooterLink.termsOfUse),
  },
  {
    text: 'Privacy Policy',
    url: buildEquipSiteLink(
      VITE_EQUIP_WEBSITE_ADDRESS,
      FooterLink.privacyPolicy,
    ),
  },
  {
    text: 'Resources',
    url: buildEquipSiteLink(VITE_EQUIP_WEBSITE_ADDRESS, FooterLink.resources),
  },
  { text: 'Contact us', url: 'mailto:support@equip.health' },
];

const FOOTER_SOCIAL_ICONS = [
  {
    name: FAIconName.instagramBrands,
    url: InstagramLink,
  },
  {
    name: FAIconName.xTwitterBrands,
    url: TwitterLink,
  },
  {
    name: FAIconName.facebookFBrands,
    url: FacebookLink,
  },
  {
    name: FAIconName.linkedinInBrands,
    url: LinkedInLink,
  },
];

const AnchorProps = {
  _focus: { outline: 'none' },
  rel: 'noreferrer',
  target: '_blank',
};

const AuthenticatedPageFooter = () => {
  const isMobileDevice = useMobileBreakpoint();
  const { track } = useAnalytics();
  const {
    patientsCompletedSafetyPlan,
    updateIsLinkedPatientModalOpen,
    updateIsSafetyPlanOpen,
    isSafetyPlanEnabled,
  } = useUserProfileContext();

  const safetyPlanHandler = () => {
    track('Safety Plan Clicked', {
      Source: 'Footer',
    });

    if (patientsCompletedSafetyPlan?.length) {
      updateIsLinkedPatientModalOpen(true);
    } else {
      updateIsSafetyPlanOpen(true);
    }
  };
  return (
    <ChakraBox
      background="mono.5"
      paddingBottom={{
        base: AUTHENTICATED_NAV_LAYOUT.mobileHeight,
        md: '0px',
      }}
    >
      <ChakraBox
        margin="auto auto"
        maxWidth={MY_EQUIP_V2_DIMENSIONS.desktopMaxWidth}
        paddingX={{ base: '24px', md: '32px' }}
        paddingY={{ base: '24px', md: '40px' }}
        width="100%"
      >
        <ChakraFlex
          alignItems="flex-start"
          direction={{ base: 'column', md: 'row-reverse' }}
          justifyContent="space-between"
          marginBottom={{ base: '24px', md: '0' }}
          width="100%"
        >
          <ChakraFlex direction="column" gridGap="24px">
            {isSafetyPlanEnabled && (
              <ChakraFlex justifyContent={{ base: 'start', md: 'end' }}>
                <FireflyButton
                  id="footer__safety-plan"
                  leftIcon={FAIconName.shieldRegular}
                  onClick={safetyPlanHandler}
                  variant="secondary"
                >
                  Safety Plan
                </FireflyButton>
              </ChakraFlex>
            )}
            <ChakraFlex gridGap="24px">
              {FOOTER_SOCIAL_ICONS.map((icon) => (
                <ChakraLink
                  {...AnchorProps}
                  background={primary['90.variant']}
                  borderRadius="12px"
                  height="24px"
                  href={icon.url}
                  key={icon.name}
                  padding="4px"
                >
                  <Icon color="white" name={icon.name} size="sm" />
                </ChakraLink>
              ))}
            </ChakraFlex>
          </ChakraFlex>
          <ChakraFlex
            direction={{ base: 'column', md: 'row' }}
            gridGap={{ base: '24px', md: '0' }}
            paddingTop={{ base: '26px', md: 0 }}
          >
            {FOOTER_LINKS.map((data, index) => (
              <ChakraFlex key={data.text}>
                <ChakraLink
                  _focus={{ outline: 'none' }}
                  _hover={{
                    textDecoration: 'underline',
                    textDecorationColor: primary[100],
                  }}
                  {...AnchorProps}
                  href={data.url}
                >
                  <Text color="primary.100" variant="bodyMedium">
                    {data.text}
                  </Text>
                </ChakraLink>
                {!isMobileDevice && index + 1 < FOOTER_LINKS.length && (
                  <Text marginX="24px">•</Text>
                )}
              </ChakraFlex>
            ))}
          </ChakraFlex>
        </ChakraFlex>
        <ChakraFlex
          marginTop="16px"
          gap="24px"
          flexDirection={isMobileDevice ? 'column' : 'row'}
        >
          <Text color="neutral.secondary">
            @ 2024 Copyright. Equip Health, Inc.
          </Text>
          {VITE_MYEQUIP_APP_VERSION && (
            <Text color="neutral.secondary">{getAppVersion()}</Text>
          )}
        </ChakraFlex>
      </ChakraBox>
    </ChakraBox>
  );
};

export default AuthenticatedPageFooter;
