import React from 'react';

// Hook to create a debounced function
const useDebounce = (timeLimitMs = 5000): ((func: () => void) => void) => {
  const timer = React.useRef(null);

  return (func: () => void) => {
    if (timer?.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (func) func();
    }, timeLimitMs);
  };
};

export default useDebounce;
