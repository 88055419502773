import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { v1 as uuidv1 } from 'uuid';

import {
  AUTHORIZATION_HEADER,
  UNIQUE_REQUEST_ID_HEADER,
} from './constants/api.constants';
import { VITE_API_GATEWAY, VITE_API_REQUEST_TIMEOUT_MS } from './constants/env';
import mocks from './mocks';

export enum ApiCommand {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

const getHeaders = (token: string) => {
  const headers = {
    [UNIQUE_REQUEST_ID_HEADER]: `MyEquip:${uuidv1()}`,
  };

  if (token) {
    headers[AUTHORIZATION_HEADER] = `Bearer ${token}`;
  }

  return headers;
};

class Api {
  static readonly axiosConfig: AxiosRequestConfig = {
    baseURL: VITE_API_GATEWAY,
    timeout: Number.parseInt(VITE_API_REQUEST_TIMEOUT_MS ?? '30000', 10),
    // withCredentials: true, TODO: enable when CORS is properly configured
    // headers: {
    //   Accept: 'application/json',
    //   'Access-Control-Allow-Origin': '*', //NOSONAR
    //   'Access-Control-Allow-Methods': 'OPTIONS, GET, POST',
    //   'Access-Control-Allow-Headers':
    //     'Content-Type, Depth, User-Agent, X-File-Size, X-Requested-With, If-Modified-Since, X-File-Name, Cache-Control',
    // },
  };

  static readonly instance: AxiosInstance = axios.create(Api.axiosConfig);

  /**
   * Get function for retrieving the client explicitly.
   */
  static get client(): AxiosInstance {
    // additional configurations can be added here, including interceptors
    return Api.instance;
  }

  static getEndpoint(
    command: string,
    url: string,
    queryString: string,
    mock: boolean,
  ): string {
    try {
      return mock
        ? mocks[`${url}${queryString}`][command]
        : `${VITE_API_GATEWAY}${url}${queryString}`;
    } catch {
      if (mock) {
        throw new Error(
          `No mock API available for command \`${command}\` at URL \`${url}\``,
        );
      }
    }

    return '';
  }

  static getQueryValue(value: ApiOptionsValue): string {
    if (Array.isArray(value)) {
      return value.join(',');
    }

    return `${value}`;
  }

  /**
   * Utility function that formats a key-value pair representing a single query param into the
   * format that the backend is expecting. The format is as follows:
   *
   * ```
   * key=value
   * ```
   *
   * where `value` can be any of the following:
   *
   * - value // a simple string
   * - 10 // an integer
   * - param1::value1%26param2::value2%26param2::value3... // a complex string represnting an array
   *
   * In the last example, the `::` character group delineates the param from its value, similar to
   * a JSON object's key to its value. The `%26` URI encoded character is the `&` (ampersand)
   * symbol in UTF, which is used to delineate the elements in the query param array.
   *
   * Example of calls to this function are as follows:
   *
   * buildQueryParam(['key', 'value']) –
   * Expected result: `key=value`
   *
   * buildQueryParam(['key', 10]) –
   * Expected result: `key=10`
   *
   * buildQueryParam(['key', {
   *   param1: 'value1',
   *   param2: 2,
   *   param3: ['value3', 'value4'],
   * }]) –
   * Expected result: `key=param1::value1%26param2::2%26param3::value3%26param3::value4`
   *
   * Notice that in the last example, the value of `param3`, which was an array, was flattened such
   * that `param3` was duplicated for each of its repspective values.
   *
   * @param _ Destructured tuple that represents the key-value pair of a query param.
   * @returns A string representing a single query param argument in requests (currently only GET).
   */
  static buildQueryParam([key, value]: [string, ApiOptionsValue]): string {
    const encodedAmpersand = encodeURIComponent('&');

    if (typeof value === 'object') {
      if (!Array.isArray(value)) {
        const param = Object.entries(value)
          .map(([paramKey, paramValue]) => {
            if (Array.isArray(paramValue)) {
              return paramValue
                .map((val: number | string) => `${paramKey}::${val}`)
                .join(encodedAmpersand);
            }

            return `${paramKey}::${paramValue}`;
          })
          .join(encodedAmpersand);

        return `${key}=${param}`;
      }
      // TODO: Potentially account for array type in the future
    }

    return `${key}=${value}`;
  }

  /**
   * Build the full query string with query params in the request.
   *
   * @param url The path relative to the gateway URL (i.e. '/users').
   * @param queryParams Object containing query params for a GET request.
   * @returns The full query string used to fetch data.
   */
  static buildRequestString(
    command: ApiCommand,
    url: string,
    queryParams: Record<string, ApiOptionsValue> = {},
    mock = false,
  ): string {
    let queryString = Object.entries(queryParams)
      .map(Api.buildQueryParam)
      .join('&');
    if (queryString) queryString = `?${queryString}`;
    return Api.getEndpoint(command, url, queryString, mock);
  }

  /**
   * DELETE request handler.
   *
   * @param token Access token generated via Auth0 to authorize the request.
   * @param url The path relative to the gateway URL (i.e. '/users').
   * @param queryParams Object containing query params for a DELETE request.
   * @returns The data from the DELETE request.
   */
  static async delete<T>(
    token: string,
    url: string,
    queryParams: Record<string, ApiOptionsValue> = {},
    mock = false,
  ): Promise<ApiResponse<T>> {
    const queryString = Api.buildRequestString(
      ApiCommand.DELETE,
      url,
      queryParams,
      mock,
    );

    try {
      const response = await Api.client.delete<T>(queryString, {
        headers: getHeaders(token),
      });

      return {
        data: response.data,
        statusCode: response.status,
      };
    } catch (err) {
      return {
        error: err?.response?.data,
        statusCode: 500,
      };
    }
  }

  /**
   * GET request handler.
   *
   * @param token Access token generated via Auth0 to authorize the request.
   * @param url The path relative to the gateway URL (i.e. '/users').
   * @param queryParams Object containing query params for a GET request.
   * @returns The data from the GET request.
   */
  static async get<T>(
    token: string,
    url: string,
    queryParams: Record<string, ApiOptionsValue> = {},
    mock = false,
  ): Promise<ApiResponse<T>> {
    const queryString = Api.buildRequestString(
      ApiCommand.GET,
      url,
      queryParams,
      mock,
    );

    const headers = getHeaders(token);

    try {
      const response = await Api.client.get<T>(queryString, {
        headers,
      });

      return {
        data: response.data,
        statusCode: response.status,
      };
    } catch (err) {
      const { code, message } = err as ApiError;
      return {
        error: { code, message },
        statusCode: 500,
      };
    }
  }

  /**
   * POST request handler.
   *
   * @param token Access token generated via Auth0 to authorize the request.
   * @param url The path relative to the gateway URL (i.e. '/users').
   * @param data Object containing the body of the POST request.
   * @returns The response from the POST request.
   */
  static async post<T>(
    token: string,
    url: string,
    options: Record<string, ApiOptionsValue> = {},
    mock = false,
    googleRecaptchaToken?: string,
  ): Promise<ApiResponse<T>> {
    const queryString = Api.buildRequestString(ApiCommand.POST, url, {}, mock);
    const headers = getHeaders(token);
    if (googleRecaptchaToken) {
      headers[AUTHORIZATION_HEADER] = googleRecaptchaToken;
    }

    try {
      const { data: response, status } = await Api.client.post<T>(
        queryString,
        options,
        {
          headers,
        },
      );

      return {
        data: response,
        statusCode: status,
      };
    } catch (err) {
      return {
        error: {
          code: err.code,
          message: err.message,
          data: err?.response?.data,
        },
      };
    }
  }

  /**
   * PUT request handler.
   *
   * @param token Access token generated via Auth0 to authorize the request.
   * @param url The path relative to the gateway URL (i.e. '/users').
   * @param data Object containing the body of the PUT request.
   * @returns The response from the PUT request.
   */
  static async put<T>(
    token: string,
    url: string,
    options: Record<string, ApiOptionsValue> = {},
    mock = false,
  ): Promise<ApiResponse<T>> {
    const queryString = Api.buildRequestString(ApiCommand.PUT, url, {}, mock);

    try {
      const { data: response, status } = await Api.client.put<T>(
        queryString,
        options,
        {
          headers: getHeaders(token),
        },
      );

      return {
        data: response,
        statusCode: status,
      };
    } catch (err) {
      return {
        error: {
          code: err.code,
          message: err.message,
        },
      };
    }
  }

  /**
   * PATCH request handler.
   *
   * @param token Access token generated via Auth0 to authorize the request.
   * @param url The path relative to the gateway URL (i.e. '/users').
   * @param data Object containing the body of the PATCH request.
   * @returns The response from the PATCH request.
   */
  static async patch<T>(
    token: string,
    url: string,
    options: Record<string, ApiOptionsValue> = {},
    mock = false,
  ): Promise<ApiResponse<T>> {
    const queryString = Api.buildRequestString(ApiCommand.PATCH, url, {}, mock);

    try {
      const { data: response, status } = await Api.client.patch<T>(
        queryString,
        options,
        {
          headers: getHeaders(token),
        },
      );

      return {
        data: response,
        statusCode: status,
      };
    } catch (err) {
      return {
        error: err?.response?.data,
        statusCode: 500,
      };
    }
  }
}

export default Api;
