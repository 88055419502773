// eslint-disable-next-line import/prefer-default-export

import { DateTime } from 'luxon';

export const getGreetingFromDate = (date: Date): string => {
  const currentHour = date.getHours();

  if (currentHour < 4) {
    return 'Good evening';
  }
  if (currentHour < 12) {
    return 'Good morning';
  }
  if (currentHour < 17) {
    return 'Good afternoon';
  }
  return 'Good evening';
};

export const getGreetingFromCurrentTime = (): string => {
  const today = new Date();
  const greeting = getGreetingFromDate(today);
  return greeting;
};

/**
 * Returns an ISO string from either a date time object or a date string
 *
 * Supported formats:
 *   - ANY date time object
 *   - ANY valid date string, e.g. LL-dd-yyyy, yyyy-LL-ddTHH:mm:ssZ, yyyy-LL-ddTHH:mm:ss.SSSZ
 */
export const toISO = (date: DateTime | string): string => {
  try {
    if (typeof date === 'string') {
      return new Date(date).toISOString();
    }
    return date.toJSDate().toISOString();
  } catch (e) {
    return null;
  }
};
