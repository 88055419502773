import { PropsWithChildren, useState } from 'react';

import { ChakraCollapse, ChakraFlex } from '@equip.health/ui';

import ShowMoreButton from '~/components/common/ShowMoreButton';

const TOP_LEVEL_ID = 'vitals__weight__overview';

interface CollapsibleContainerProps extends PropsWithChildren {
  collapsedTitle?: string;
  expandedTitle?: string;
  shouldHideButtonWhenExpanded?: boolean;
  isCollapsedInitially?: boolean;
  onCollapseChange?: (isCollapsed: boolean) => void;
}

const CollapsibleContainer = ({
  collapsedTitle,
  expandedTitle,
  children,
  shouldHideButtonWhenExpanded,
  isCollapsedInitially,
  onCollapseChange,
}: CollapsibleContainerProps) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedInitially);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
    onCollapseChange?.(!isCollapsed);
  };

  const shouldShowButton =
    isCollapsed || (!isCollapsed && !shouldHideButtonWhenExpanded);
  return (
    <ChakraFlex flexDirection="column" width="100%">
      <ChakraCollapse
        in={!isCollapsed}
        startingHeight="20px"
        style={{ position: 'relative' }}
      >
        <ChakraFlex
          alignItems="center"
          backgroundImage="linear-gradient(to bottom, rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 1))"
          height="20px"
          left={0}
          opacity={isCollapsed ? 1 : 0}
          position="absolute"
          right={0}
          top={0}
          transition={isCollapsed ? 'opacity 0.3s' : 'opacity 0.1s'}
        />
        {children}
      </ChakraCollapse>
      {shouldShowButton && (
        <ChakraFlex
          justifyContent="center"
          marginTop={isCollapsed ? '0' : '24px'}
          width="100%"
        >
          <ShowMoreButton
            id={`${TOP_LEVEL_ID}__collapse_toggle`}
            isExpanded={!isCollapsed}
            onClick={handleCollapseToggle}
            title={isCollapsed ? collapsedTitle : expandedTitle}
          />
        </ChakraFlex>
      )}
    </ChakraFlex>
  );
};

export default CollapsibleContainer;
