import { combineReducers } from '@reduxjs/toolkit';

import chat from '~/store/reducers/chat';
import user from '~/store/reducers/user';
import userPreferences from '~/store/reducers/userPreferences';
import api from '~/store/services/api';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  chat,
  user,
  userPreferences,
});

export default rootReducer;
