import { FC, useEffect, useState } from 'react';

import {
  Avatar,
  ChakraBox,
  ChakraFlex,
  ChakraImage,
  ChakraSkeleton,
  EquipUIFireflyV2Theme,
  Text,
} from '@equip.health/ui';
import { FileMessage, UserMessage } from '@sendbird/chat/message';
import { useChannelContext } from '@sendbird/uikit-react/Channel/context';
import EmojiReactions from '@sendbird/uikit-react/ui/EmojiReactions';
import MessageItemReactionMenu from '@sendbird/uikit-react/ui/MessageItemReactionMenu';
import Tooltip from '@sendbird/uikit-react/ui/Tooltip';
import TooltipWrapper from '@sendbird/uikit-react/ui/TooltipWrapper';
import * as Sentry from '@sentry/react';
import { isNil } from 'lodash';

import RawHtml from '~/components/common/RawHtml';
import { ApiCommand } from '~/lib/Api';
import {
  ADMIN_MESSAGE_TYPES,
  FileValidationStatus,
} from '~/lib/constants/chat/chat.constants';
import urlConstants from '~/lib/constants/url.constants';
import { useChatAttachmentsContext } from '~/lib/context/ChatAttachmentsContext';
import { useChatChannelContext } from '~/lib/context/ChatChannelContext';
import { useApi } from '~/lib/hooks';
import useFeatureFlags from '~/lib/hooks/useFeatureFlags';
import { ChatMessage } from '~/lib/types/chat';
import {
  getAdminMessageText,
  getUserTypeFromChatUser,
} from '~/lib/util/chat/chat.utils';
import { getFileExtension, isAllowedFileType } from '~/lib/util/file.util';
import {
  CHAT_ACCEPTED_IMAGE_EXTENSION,
  DocumentType,
  getDocumentTypeFromFileName,
} from '~/lib/util/patientDocuments.util';

import FileAttachmentTile from './FileAttachmentTile';

import './sendbird-reactions.css';

interface MessageTileProps {
  chatUserId: string;
  id: string;
  isAdminMessage: boolean;
  isAttachment: boolean;
  isMyMessage: boolean;
  message?: ChatMessage;
}

const idPrefix = 'Message';
const idPostfix = 'Tile';

const { colors } = EquipUIFireflyV2Theme;

const { getAttachmentDownloadUrl: getAttachmentDownloadPathUrl } =
  urlConstants.chat;

const messageParentContainerProps = {
  alignItems: 'start',
  gridGap: '8px',
  paddingBottom: '20px',
  width: '100%',
};

const messageSenderContainerProps = {
  color: 'neutral.secondary',
  gridGap: '8px',
  marginBottom: '6px',
};

const messageContainerProps = {
  maxWidth: '80%',
};

interface AttachmentViewProps {
  fileMessage: FileMessage;
}

const AttachmentView = ({ fileMessage }: AttachmentViewProps) => {
  const {
    getDownloadURLForS3Attachment,
    setDownloadURLForS3Attachment,
    setShowChatAttachmentModal,
  } = useChatAttachmentsContext();

  const fileName = fileMessage?.name;
  const fileType = getDocumentTypeFromFileName(fileName);
  const isImageDocument = fileType === DocumentType.IMAGE;

  const { patientId } = useChatChannelContext();

  const [fileUrl, setFileUrl] = useState<string | null>(() =>
    getDownloadURLForS3Attachment(fileName),
  );

  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const [isValidFile, setIsValidFile] = useState<boolean>(true);
  const [isValidatingFile, setIsValidatingFile] = useState<boolean>(false);

  const handleClick = () => {
    setShowChatAttachmentModal({
      name: fileName,
      url: fileUrl,
    });
  };

  const {
    error: getAttachmentDownloadUrlError,
    loading: isDownloadUrlLoading,
    sendRequest: getAttachmentDownloadUrlSendRequest,
  } = useApi<PatientDocumentDownloadUrlResponse>();

  useEffect(() => {
    if (getAttachmentDownloadUrlError) {
      Sentry.captureException(getAttachmentDownloadUrlError);
    }
  }, [[getAttachmentDownloadUrlError]]);

  const fetchDownloadURL = (completion?: (success?: boolean) => void) => {
    getAttachmentDownloadUrlSendRequest({
      callback: (response) => {
        const {
          attachmentDownloadUrl: responseUrl,
          isValidFile,
          fileValidationStatus,
        } = response || {};

        setIsValidFile(isValidFile);
        setIsValidatingFile(false);
        completion?.(false);

        const isNewFile = fileValidationStatus === FileValidationStatus.NEW;
        const isValidImage = isImageDocument && isValidFile;

        // Should only allow setting the download URL if the file is NOT new or if it's a valid image.
        // If it's a new file (not image), we should not set the download URL as it's not yet validated.
        if (!isNewFile || isValidImage) {
          setDownloadURLForS3Attachment(fileName, responseUrl);
          setFileUrl(responseUrl);
          setIsImageLoading(true);
          completion?.(true);
        } else {
          setIsImageLoading(false);
          completion?.(false);
        }
      },
      command: ApiCommand.GET,
      url: getAttachmentDownloadPathUrl(fileName, patientId),
    });
  };

  useEffect(() => {
    if (isNil(fileUrl) && isImageDocument) {
      fetchDownloadURL();
    }
  }, []);

  const isLoading = isDownloadUrlLoading || isImageLoading;

  const handleFileAttachmentClick = () => {
    if (!isValidFile) {
      return;
    }

    const url = getDownloadURLForS3Attachment(fileName);
    if (url) {
      window.open(url);
    } else {
      setIsValidatingFile(true);
      fetchDownloadURL((success) => {
        if (success) {
          handleFileAttachmentClick();
        }
      });
    }
  };

  return isAllowedFileType(
    getFileExtension(fileName),
    CHAT_ACCEPTED_IMAGE_EXTENSION,
  ) ? (
    <ChakraBox
      border={`1px solid ${colors.black[10]}`}
      borderRadius="10px"
      cursor="pointer"
      onClick={handleClick}
      overflow="hidden"
      position="relative"
    >
      {isLoading && (
        <ChakraSkeleton height="178px" position="absolute" width="178px" />
      )}

      <ChakraImage
        boxSize="178"
        fit="fill"
        loading="lazy"
        objectFit="cover"
        onLoad={() => {
          setIsImageLoading(false);
        }}
        src={fileUrl}
        style={{ opacity: !isLoading ? '1' : '0' }}
      />
    </ChakraBox>
  ) : (
    <ChakraBox
      background="white"
      cursor={isValidFile ? 'pointer' : 'not-allowed'}
      onClick={handleFileAttachmentClick}
    >
      <FileAttachmentTile
        fileName={fileName}
        isValidatingFile={isValidatingFile}
        isValidFile={isValidFile}
        topLevelId="chat_message_file_attachment"
      />
    </ChakraBox>
  );
};

interface MessageViewProps {
  isAttachment: boolean;
  id: string;
  message: ChatMessage;
}

const MessageView: FC<MessageViewProps> = ({ id, isAttachment, message }) => {
  return isAttachment ? (
    <AttachmentView fileMessage={message.fileMessage} />
  ) : (
    <Text id={`${idPrefix}-message-body__${id}__${idPostfix}`}>
      <RawHtml html={message?.message} id={id} />
    </Text>
  );
};

const MessageTile = ({
  id,
  isAdminMessage,
  isMyMessage,
  message,
  isAttachment,
  chatUserId,
}: MessageTileProps) => {
  const { isPatientMessageTabEmojisEnabled } = useFeatureFlags();
  const hasMessageReactions = message?.sbMessage.reactions.length > 0;
  const { emojiContainer, toggleReaction, nicknamesMap, currentGroupChannel } =
    useChannelContext();
  const channelIsFrozen = currentGroupChannel?.isFrozen;
  const [supposedHover, setSupposedHover] = useState<boolean>();
  if (isAdminMessage) {
    return (
      <ChakraFlex
        {...messageParentContainerProps}
        alignItems="center"
        direction="row"
        id={`${idPrefix}-parent-container__${id}__${idPostfix}`}
        justifyContent="center"
      >
        {![ADMIN_MESSAGE_TYPES.FROZEN, ADMIN_MESSAGE_TYPES.UNFROZEN].includes(
          message.adminMessageType,
        ) && (
          <Avatar
            id={`${idPrefix}-avatar__${id}__${idPostfix}`}
            name={message?.sender?.nickname}
            size="xs"
            userType="provider"
          />
        )}
        <Text
          color="neutral.secondary"
          id={`${idPrefix}-message-body__${id}__${idPostfix}`}
          variant="caption"
        >
          <RawHtml
            html={getAdminMessageText(
              message?.message,
              message?.sender?.nickname,
            )}
            id={id}
          />
        </Text>
      </ChakraFlex>
    );
  }

  if (isMyMessage) {
    return (
      <ChakraFlex
        direction="row-reverse"
        {...messageParentContainerProps}
        id={`${idPrefix}-parent-container__${id}__${idPostfix}`}
      >
        <ChakraBox
          id={`${idPrefix}-message-container__${id}__${idPostfix}`}
          {...messageContainerProps}
        >
          <ChakraFlex
            direction="column"
            id={`${idPrefix}-message-wrapper__${id}__${idPostfix}`}
          >
            <ChakraFlex
              direction="row-reverse"
              id={`${idPrefix}-message-sender-container__${id}__${idPostfix}`}
              {...messageSenderContainerProps}
            >
              <Text
                id={`${idPrefix}-message-sender__${id}__${idPostfix}`}
                variant="caption"
              >
                {message?.timestamp}
              </Text>
            </ChakraFlex>
            <ChakraFlex
              _hover={{ background: '#ffffff !important' }}
              className="sendbird-parent-message-info"
              direction="column"
              id={`${idPrefix}-single-message-column-wrapper__${id}__${idPostfix}`}
              width="100%"
            >
              <ChakraFlex
                direction="row-reverse"
                id={`${idPrefix}-single-message-row-wrapper__${id}__${idPostfix}`}
                width="100%"
              >
                <ChakraFlex
                  backgroundColor="primary.10"
                  borderRadius="14px 14px 0px 14px"
                  gridGap="24px"
                  padding="8px"
                >
                  <MessageView
                    id={id}
                    isAttachment={isAttachment}
                    message={message}
                  />
                </ChakraFlex>
                {isPatientMessageTabEmojisEnabled &&
                  !channelIsFrozen &&
                  !hasMessageReactions && (
                    <TooltipWrapper
                      hoverTooltip={
                        !supposedHover && <Tooltip>Add reaction</Tooltip>
                      }
                    >
                      <MessageItemReactionMenu
                        className={`sendbird-parent-message-info__reaction-menu sb-reaction-menu-outgoing ${
                          supposedHover ? 'sendbird-mouse-hover' : ''
                        }`}
                        emojiContainer={emojiContainer}
                        key={message?.messageId}
                        message={message?.sbMessage as UserMessage}
                        setSupposedHover={setSupposedHover}
                        toggleReaction={toggleReaction}
                        userId={chatUserId}
                      />
                    </TooltipWrapper>
                  )}
              </ChakraFlex>
            </ChakraFlex>
            <ChakraFlex
              direction="row-reverse"
              id={`${id}-emoji-reactions-row-container`}
              width="100%"
            >
              {isPatientMessageTabEmojisEnabled &&
                !channelIsFrozen &&
                hasMessageReactions && (
                  <ChakraFlex
                    className="emoji-reactions-wrapper-outgoing"
                    data-testid={`${id}__emoji-reactions-wrapper`}
                    id={`${id}__emoji-reactions-wrapper`}
                  >
                    <EmojiReactions
                      channel={currentGroupChannel}
                      emojiContainer={emojiContainer}
                      memberNicknamesMap={nicknamesMap}
                      message={message?.sbMessage as UserMessage}
                      toggleReaction={toggleReaction}
                      userId={chatUserId}
                    />
                  </ChakraFlex>
                )}
            </ChakraFlex>
          </ChakraFlex>
        </ChakraBox>
      </ChakraFlex>
    );
  }

  return (
    <ChakraFlex
      direction="row"
      gridGap="8px"
      id={`${idPrefix}-parent-container__${id}__${idPostfix}`}
    >
      <ChakraBox id={`${idPrefix}-avatar-container__${id}__${idPostfix}`}>
        <Avatar
          id={`${idPrefix}-avatar__${id}__${idPostfix}`}
          name={message?.sender?.nickname}
          size="2xs"
          userType={getUserTypeFromChatUser(message?.sender)}
        />
      </ChakraBox>

      <ChakraFlex direction="column" {...messageParentContainerProps}>
        <ChakraBox
          id={`${idPrefix}-message-container__${id}__${idPostfix}`}
          {...messageContainerProps}
        >
          <ChakraFlex
            direction="row"
            id={`${idPrefix}-message-wrapper__${id}__${idPostfix}`}
          >
            <ChakraFlex
              direction="row"
              id={`${idPrefix}-message-sender-container__${id}__${idPostfix}`}
              {...messageSenderContainerProps}
            >
              <Text
                id={`${idPrefix}-message-sender__${id}__${idPostfix}`}
                variant="caption"
              >
                {message?.sender.nickname}
              </Text>
              <Text
                id={`${idPrefix}-message-sender__${id}__${idPostfix}`}
                variant="caption"
              >
                {message?.timestamp}
              </Text>
            </ChakraFlex>
          </ChakraFlex>
        </ChakraBox>
        <ChakraFlex
          _hover={{ background: '#ffffff !important' }}
          className="sendbird-parent-message-info"
          direction="column"
          id={`${idPrefix}-single-message-column-wrapper__${id}__${idPostfix}`}
          padding="5px 10px 5px 10px"
          width="100%"
        >
          <ChakraFlex
            direction="row"
            id={`${idPrefix}-single-message-row-wrapper__${id}__${idPostfix}`}
            width="100%"
          >
            <MessageView
              id={id}
              isAttachment={isAttachment}
              message={message}
            />
            {isPatientMessageTabEmojisEnabled &&
              !channelIsFrozen &&
              !hasMessageReactions && (
                <TooltipWrapper
                  hoverTooltip={
                    !supposedHover && <Tooltip>Add reaction</Tooltip>
                  }
                >
                  <MessageItemReactionMenu
                    className={`sendbird-parent-message-info__reaction-menu sb-reaction-menu-incoming ${
                      supposedHover ? 'sendbird-mouse-hover' : ''
                    }`}
                    emojiContainer={emojiContainer}
                    key={message?.messageId}
                    message={message?.sbMessage as UserMessage}
                    setSupposedHover={setSupposedHover}
                    toggleReaction={toggleReaction}
                    userId={chatUserId}
                  />
                </TooltipWrapper>
              )}
          </ChakraFlex>
        </ChakraFlex>
        <ChakraFlex
          direction="row"
          id={`${id}-emoji-reactions-row-container`}
          width="100%"
        >
          {isPatientMessageTabEmojisEnabled &&
            !channelIsFrozen &&
            hasMessageReactions && (
              <ChakraFlex
                className="emoji-reactions-wrapper-incoming"
                data-testid={`${id}__emoji-reactions-wrapper`}
                id={`${id}__emoji-reactions-wrapper`}
              >
                <EmojiReactions
                  channel={currentGroupChannel}
                  emojiContainer={emojiContainer}
                  memberNicknamesMap={nicknamesMap}
                  message={message?.sbMessage as UserMessage}
                  toggleReaction={toggleReaction}
                  userId={chatUserId}
                />
              </ChakraFlex>
            )}
        </ChakraFlex>
      </ChakraFlex>
    </ChakraFlex>
  );
};

MessageTile.displayName = 'MessageTile';
export default MessageTile;
