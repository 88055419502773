import { PropsWithChildren } from 'react';

import { ChakraFlex, FAIconName, Icon } from '@equip.health/ui';

import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';
import { getCardHoverBackgroundColor } from '~/lib/util/schedule/bookAppointment.util';

export interface BaseCardProps extends PropsWithChildren {
  id: string;
  isHoverDisabled?: boolean;
  isRemoveButtonVisible?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
}

const BaseCard = ({
  children,
  id,
  isHoverDisabled,
  isRemoveButtonVisible,
  isSelected,
  onClick,
  onRemove,
}: BaseCardProps) => {
  const isMobile = useMobileBreakpoint();
  const shouldShowCheckIcon = !isMobile && isSelected;
  const backgroundColor =
    !isHoverDisabled && getCardHoverBackgroundColor(isSelected, isMobile);
  const isCursorDefault = isRemoveButtonVisible || isHoverDisabled;

  return (
    <ChakraFlex
      _hover={{ backgroundColor }}
      alignItems="center"
      backgroundColor={isSelected ? 'primary.10' : 'neutral.background.primary'}
      borderColor={isSelected ? 'transparent' : 'neutral.background.secondary'}
      borderRadius="12px"
      borderWidth="2px"
      cursor={isCursorDefault ? 'default' : 'pointer'}
      gridGap="12px"
      id={id}
      justifyContent="space-between"
      onClick={onClick}
      padding={{ base: '20px', md: '12px 24px 12px 18px' }}
      position="relative"
    >
      {children}
      {shouldShowCheckIcon && (
        <Icon color="primary.100" name={FAIconName.checkRegular} size="md" />
      )}
      {isRemoveButtonVisible && (
        <ChakraFlex
          alignItems="center"
          cursor="pointer"
          height="30px"
          id={`${id}__header__close-button}`}
          onClick={onRemove}
        >
          <Icon
            color="neutral.primary"
            name={FAIconName.timesSolid}
            size="md"
          />
        </ChakraFlex>
      )}
    </ChakraFlex>
  );
};

export default BaseCard;
