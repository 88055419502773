import { FC } from 'react';

import { ChakraBox, ChakraSkeleton, ChakraStack } from '@equip.health/ui';

import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';

interface ListLoadingViewProps {
  listType: 'userAvatar' | 'options';
}
const ListLoadingView: FC<ListLoadingViewProps> = ({ listType }) => {
  const isMobile = useMobileBreakpoint();

  const loaderHeight = isMobile && listType === 'userAvatar' ? '110px' : '72px';
  const numberOfLoaders = isMobile ? 4 : 6;

  return (
    <ChakraBox width="100%">
      <ChakraStack spacing="12px">
        {[...Array(numberOfLoaders)]
          .map((_, i) => i + 1)
          .map((val) => (
            <ChakraSkeleton
              borderRadius="16px"
              endColor="neutral.background.secondary"
              height={loaderHeight}
              key={`list_loader_${val}`}
              startColor="primary.10"
            />
          ))}
      </ChakraStack>
    </ChakraBox>
  );
};

export default ListLoadingView;
