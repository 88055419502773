import React, { useEffect, useRef } from 'react';

import {
  ChakraFlex,
  ChakraText,
  EquipUIFireflyV1Theme,
  InfiniteScroll,
} from '@equip.health/ui';

import {
  CHAT_DIVIDER_TYPE,
  EMPTY_CHAT_VIEW,
  EMPTY_MESSAGES_VIEW,
  MARK_READ_DURATION,
  UNREAD_MESSAGE_OFFSET,
} from '~/lib/constants/chat/chat.constants';
import { ChatMessage } from '~/lib/types/chat';
import { renderLinks, renderNewLine } from '~/lib/util/chat/chatSearch.util';

import { isChatAttachmentsEnabled } from './ChatAttachments';
import ChatDivider from './ChatDivider';
import MessageTile from './MessageTile';
import Loading from '../common/Loading';

interface ChatPanelProps {
  currentUserId: string;
  hasMoreMessages?: boolean;
  id: string;
  isEmptyChat?: boolean;
  isLoading?: boolean;
  loadMoreMessages?: () => void;
  markMessagesAsRead?: () => void;
  messages?: ChatMessage[];
}

const idPrefix = 'Channel';
const idPostfix = 'Panel';

const { h4, paragraph } = EquipUIFireflyV1Theme.typography;

const headerContainerProps = {
  gridGap: '6px',
  height: `calc(100% - 76px)`,
  padding: '26px 24px 0',
  width: '100%',
};

const ChatPanel = React.forwardRef<HTMLDivElement, ChatPanelProps>(
  (
    {
      currentUserId,
      hasMoreMessages,
      id,
      isEmptyChat,
      isLoading,
      loadMoreMessages,
      markMessagesAsRead,
      messages,
    }: ChatPanelProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const containerId = `${idPrefix}-container__${id}__${idPostfix}`;
    const newMessageDividerRef = useRef<HTMLDivElement>(null);
    const firstMessageRef = useRef<HTMLDivElement>(null);
    const firstUnreadMessageId = (messages ?? [])
      .slice()
      .reverse()
      .find((message) => !message.isRead)?.messageId;
    const markAsReadTimerHandle = useRef<number>(null);
    const markAsRead = () => {
      clearMarkAsReadTimer();

      if (markMessagesAsRead)
        markAsReadTimerHandle.current = setTimeout(
          markMessagesAsRead,
          MARK_READ_DURATION,
        );
    };

    useEffect(() => {
      if (firstUnreadMessageId) {
        newMessageDividerRef.current?.scrollIntoView({ behavior: 'auto' });
      } else {
        firstMessageRef.current?.scrollIntoView({ behavior: 'auto' });
      }

      if (newMessageDividerRef?.current?.offsetTop > 0) {
        markAsRead();
      }
    }, [messages]);

    if (isLoading) {
      return (
        <ChakraFlex
          alignItems="center"
          direction="column"
          id={`${idPrefix}-container__${id}__${idPostfix}`}
          justifyContent="center"
          ref={ref}
          {...headerContainerProps}
        >
          <Loading />
        </ChakraFlex>
      );
    }

    if (!messages || messages?.length === 0) {
      return (
        <ChakraFlex
          direction="column"
          id={`${idPrefix}-container__${id}__${idPostfix}`}
          ref={ref}
          {...headerContainerProps}
        >
          <ChakraText color="black.60" {...h4}>
            {isEmptyChat
              ? EMPTY_CHAT_VIEW.message
              : EMPTY_MESSAGES_VIEW.message}
          </ChakraText>
          <ChakraText color="black.60" {...paragraph}>
            {isEmptyChat
              ? EMPTY_CHAT_VIEW.subTitle
              : EMPTY_MESSAGES_VIEW.subTitle}
          </ChakraText>
        </ChakraFlex>
      );
    }

    const processedMessages = renderLinks(renderNewLine(messages));

    const clearMarkAsReadTimer = () => {
      if (markAsReadTimerHandle.current) {
        clearTimeout(markAsReadTimerHandle.current);
        markAsReadTimerHandle.current = null;
      }
    };

    const handleScroll = (e: MouseEvent) => {
      const { scrollTop } = e.target as HTMLDivElement;

      if (Math.abs(scrollTop) < UNREAD_MESSAGE_OFFSET) {
        markAsRead();
      } else {
        clearMarkAsReadTimer();
      }
    };

    return (
      <ChakraFlex
        direction="column-reverse"
        gridGap={6}
        id={containerId}
        onScroll={handleScroll}
        overflowY="auto"
        ref={ref}
        {...headerContainerProps}
      >
        <InfiniteScroll
          dataLength={(messages ?? []).length}
          fetchData={loadMoreMessages}
          hasMore={hasMoreMessages}
          hideCount
          id={id}
          inverse
          scrollableTarget={`${idPrefix}-container__${id}__${idPostfix}`}
          style={{ overflowX: 'hidden' }}
          totalCount={(messages ?? []).length * 1.1}
          width="100%"
        >
          {processedMessages.map((message, index) => (
            <React.Fragment key={message.messageId}>
              <MessageTile
                chatUserId={currentUserId}
                id={message?.messageId?.toFixed()}
                isAdminMessage={message.isAdminMessage}
                isAttachment={
                  isChatAttachmentsEnabled &&
                  message.isFileMessage &&
                  !!message.fileMessage
                }
                isMyMessage={message?.sender.userId === currentUserId}
                key={`${message?.messageId?.toFixed()}-${message?.channelUrl}`}
                message={message}
              />
              {message.messageId === firstUnreadMessageId && (
                <ChatDivider
                  ref={newMessageDividerRef}
                  text="New"
                  type={CHAT_DIVIDER_TYPE.UNREAD_MESSAGE}
                />
              )}
              {(index === messages.length - 1 ||
                message.relativeDate !== messages[index + 1]?.relativeDate) && (
                <ChatDivider
                  text={message.relativeDate}
                  type={CHAT_DIVIDER_TYPE.DATE}
                />
              )}
            </React.Fragment>
          ))}
        </InfiniteScroll>
      </ChakraFlex>
    );
  },
);

ChatPanel.displayName = 'ChatPanel';
export default ChatPanel;
