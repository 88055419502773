import { FC } from 'react';

import {
  ChakraFlex,
  ChakraText,
  Close,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

const { h4 } = EquipUIFireflyV1Theme.typography;
const { black } = EquipUIFireflyV1Theme.colors;

interface ModalHeaderProps {
  id: string;
  onClose: () => void;
  title: string;
}

const ModalHeader: FC<ModalHeaderProps> = ({ id, onClose, title }) => {
  return (
    <ChakraFlex
      alignItems="center"
      id={`${id}__header}`}
      justifyContent="space-between"
      width="100%"
    >
      <ChakraText {...h4} id={`${id}__header__title}`}>
        {title}
      </ChakraText>
      <ChakraFlex
        alignItems="center"
        cursor="pointer"
        height="30px"
        onClick={onClose}
      >
        <Close
          fill={black[90]}
          height="20px"
          id={`${id}__header__close-button}`}
          weight="20px"
        />
      </ChakraFlex>
    </ChakraFlex>
  );
};

export default ModalHeader;
