import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';

import { PatientObservationData } from '~/lib/types/vitals';
import { getPreferredFirstName } from '~/lib/utils';

export const getObservationDate = (
  observationData: PatientObservationData,
  format?: 'overview' | 'table',
): string => {
  const dateTime = observationData?.observation?.observationDatetime;
  if (isNil(dateTime)) {
    return null;
  }

  const formatValue = format === 'table' ? 'MM/dd/yy' : 'ccc, LLL dd';
  return DateTime.fromISO(
    observationData.observation.observationDatetime,
  ).toFormat(formatValue);
};

export const getObservationLabel = (
  observationData: PatientObservationData,
): string => {
  const unit = observationData?.observation?.unit;
  const date = getObservationDate(observationData);

  if (isNil(unit) || isNil(date)) {
    return null;
  }

  return `${unit} on ${date}`;
};

export const getObservationTime = (observationData: PatientObservationData) => {
  const dateTime = observationData?.observation?.observationDatetime;
  if (isNil(dateTime)) {
    return null;
  }

  return DateTime.fromISO(
    observationData.observation.observationDatetime,
  ).toFormat('h:mm a');
};

export const getObservationDateTime = (
  observationData: PatientObservationData,
): string => {
  const dateTime = observationData?.observation?.observationDatetime;
  if (isNil(dateTime)) {
    return null;
  }

  return DateTime.fromISO(
    observationData.observation.observationDatetime,
  ).toFormat("LLL dd 'at' h:mm a");
};

export const getObservationLoggerName = (
  observationData: PatientObservationData,
): string => {
  const firstName = observationData?.createdBy?.firstName;
  const chosenName = observationData?.createdBy?.chosenName;

  if (isNil(firstName)) {
    return null;
  }

  const name = getPreferredFirstName(chosenName, firstName);

  return name;
};

export const getLastObservationDescription = (
  observationData: PatientObservationData,
): string => {
  const firstName = observationData?.createdBy?.firstName;
  const chosenName = observationData?.createdBy?.chosenName;
  const date = getObservationDate(observationData);

  if (isNil(firstName) || isNil(date)) {
    return null;
  }

  const name = getPreferredFirstName(chosenName, firstName);

  return `Logged on ${date} by ${name}`;
};

/** Find the last observation in the last 7 days only if there are two or more weight logs in the last week
 * @param observations - list of observations sorted by date in descending order
 */
export const getLastWeekReferenceObservation = (
  observations: PatientObservationData[],
): PatientObservationData => {
  if (isNil(observations)) {
    return null;
  }

  const lastWeekObservations = observations?.filter((o) => {
    const diffNowInDays = DateTime.fromISO(
      o.observation.observationDatetime,
    ).diffNow('days').days;

    const roundedDiff = Math.round(diffNowInDays);
    return roundedDiff >= -7 && roundedDiff <= 0;
  });

  if (lastWeekObservations?.length <= 1) {
    return null;
  }

  return lastWeekObservations[lastWeekObservations.length - 1];
};

export const getWeightObservationValue = (
  observationData: PatientObservationData,
): string => {
  if (!observationData) return null;
  const value = observationData?.observation?.value;
  const unit = observationData?.observation?.unit;

  if (isNil(value) || isNil(unit)) {
    return null;
  }

  const stringValue = `${value} ${unit}`;
  return stringValue;
};

export const getPatientTrendValue = (
  recentObservation: PatientObservationData,
  referenceObservaton: PatientObservationData,
): number => {
  const recentObservationValue = recentObservation?.observation?.value;
  const referenceObservationValue = referenceObservaton?.observation?.value;

  if (isNil(recentObservationValue) || isNil(referenceObservationValue)) {
    return null;
  }

  const variationValue = recentObservationValue - referenceObservationValue;

  const roundedValue = Math.round(variationValue * 10) / 10;
  return roundedValue;
};

export const getWeightTrendValueString = (value: number): string => {
  if (value === 0) {
    return 'No weight change in the last 7 days';
  }

  if (value > 0) {
    return `+${value}`;
  }

  if (value < 0) {
    return `${value}`;
  }

  return 'No weight logged in the last 7 days';
};

export const getWeightTrendDescriptionLabel = (
  lastObservationInWeek?: PatientObservationData,
  nextObservation?: PatientObservationData,
): string => {
  if (lastObservationInWeek) {
    return 'in past 7 days';
  }

  if (nextObservation) {
    return 'difference in the last two weights recorded';
  }

  return null;
};
