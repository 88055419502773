import { FC, useCallback, useContext, useEffect, useState } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraImage,
  ChakraLink,
  ChakraSimpleGrid,
  ChakraText,
  EquipUIFireflyV1Theme,
  PhoneCall,
} from '@equip.health/ui';
import * as Sentry from '@sentry/react';
import { useCookies } from 'react-cookie';
import { Prompt, useHistory, useLocation } from 'react-router-dom';

import Referral from '~/assets/img/referral.png';
import CalloutModal from '~/components/common/CalloutModal';
import ExitConfirm from '~/components/common/ExitConfirm';
import Footer from '~/components/common/Footer';
import InquiryReferralConfirmation from '~/components/common/InquiryReferralConfirmation';
import Navbar from '~/components/common/Navbar';
import StepsComponent from '~/components/common/StepsComponent';
import ContactInfoForm, {
  ContactInfo,
} from '~/components/referral/ContactInfoForm';
import NotesForm, { NotesFormInfo } from '~/components/referral/NotesForm';
import PatientInfoForm, {
  PatientInfo,
} from '~/components/referral/PatientInfoForm';
import ReferrerInfoForm, {
  ReferrerAffiliation,
  ReferrerInfo,
} from '~/components/referral/ReferrerInfoForm';
import { ApiCommand } from '~/lib/Api';
import {
  GAEventForReferral,
  GAEventForRouting,
  INQUIRY_REFERRAL_HORIZONTAL_MARGINS,
  InquirerRole,
  InquiryChannelName,
  ReferralMode,
  SupportMailForReferral,
} from '~/lib/constants';
import { DateFormat } from '~/lib/constants/dateFormat.constants';
import {
  FormType,
  ReferralStep,
  TIMEZONE_CODE_KEY,
  defaultContactInfo,
  defaultNotesData,
  defaultPatientData,
  defaultReferrerData,
  stepsData,
} from '~/lib/constants/referral.constants';
import urlConstants from '~/lib/constants/url.constants';
import { InquiryReferralContext } from '~/lib/context/InquiryReferralContext';
import useApi from '~/lib/hooks/useApi';
import useFeatureFlags from '~/lib/hooks/useFeatureFlags';
import { generateUtmParams } from '~/lib/util/inquiries.util';
import { getDateOfBirth, removeEmptyAttributes } from '~/lib/util/inquiry.util';
import GAEventsTracker from '~/lib/util/useGAEventsTracker.util';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { PAGE_NAME } from '~/lib/constants/analytics';

const { bodySemibold, caption, captionSemibold, h1 } =
  EquipUIFireflyV1Theme.typography;
const { blue, mono, teal } = EquipUIFireflyV1Theme.colors;
const NAVBAR_ID = 'navigation-bar';
const TOP_LEVEL_ID = 'referral-page';

const ReferralPage: FC = () => {
  const {
    error: addReferralError,
    loading: isAddReferralLoading,
    sendRequest: addReferral,
    statusCode: addReferralStatusCode,
    data,
  } = useApi<SaveReferralResponseV1 | string>();
  // TODO: V1 response is an object, V2 response is a string, so we are handling both temporarily

  const [cookies] = useCookies();
  const utmParams = cookies.EQUIP;
  const { trackPageView } = useAnalytics();

  const [steps, setSteps] = useState<Step[]>(stepsData);
  const [selectedStep, setSelectedStep] = useState<ReferralStep>(
    ReferralStep.YOUR_INFORMATION,
  );
  const [referralDesiredPath, setReferralDesiredPath] = useState<any>(null);
  const [referralConfirmNavigation, setReferralConfirmNavigation] =
    useState<boolean>(false);
  const [isCalloutModalOpen, setIsCalloutModalOpen] = useState(false);

  const [timezoneCode, setTimezoneCode] = useState<string>(null);

  const referralHistory = useHistory();
  const referralLocation = useLocation();

  const [
    isReferralUnsavedChangesModalOpen,
    setIsReferralUnsavedChangesModalOpen,
  ] = useState<boolean>(false);

  const { isInquiryReferralEdited, setIsInquiryReferralEdited } = useContext(
    InquiryReferralContext,
  );

  const { isInquiryV2Enabled } = useFeatureFlags();

  /** Blocks navigation if the user is attempting to navigate away from this page with unsaved changes */
  const handleNavigateWithReferralUnsavedChanges = ({
    pathname,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any): boolean => {
    if (
      !referralConfirmNavigation &&
      isInquiryReferralEdited &&
      selectedStep !== ReferralStep.THANK_YOU
    ) {
      setIsReferralUnsavedChangesModalOpen(true);
      setReferralDesiredPath(
        pathname === referralLocation.pathname
          ? referralLocation.pathname
          : pathname,
      );
      return false;
    }
    return true;
  };

  const handleReferralExitCancel = (): void => {
    setReferralDesiredPath(null);
    setIsReferralUnsavedChangesModalOpen(false);
  };

  const handleCalloutModalClose = (): void => {
    setIsCalloutModalOpen(false);
  };

  const onStepChangeMount = useCallback(
    (currentStep) => {
      setSteps(
        steps.map((step: Step) => ({
          ...step,
          canEdit: step.id < currentStep,
          isStepActiveStatus: step.id === currentStep,
        })),
      );
      setSelectedStep(currentStep);
      document.getElementById(`${NAVBAR_ID}__logo-section`).scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
      if (currentStep === ReferralStep.REFERRED_PATIENT_INFORMATION) {
        window.history.replaceState(null, '', 'referral');
        GAEventsTracker(GAEventForRouting);
      }
    },
    [steps],
  );

  const [referrerData, setReferrerData] =
    useState<ReferrerInfo>(defaultReferrerData);
  const updateReferrerField = (
    key: keyof ReferrerInfo,
    value: string | ReferrerAffiliation,
  ) => {
    const newReferralData = { ...referrerData, [key]: value };
    if (key === 'roleType') newReferralData.relationToPatient = '';
    setReferrerData(newReferralData);
  };
  const updateReferrerAffiliationField = (
    key: string,
    value: string | number,
  ) => {
    if (key === TIMEZONE_CODE_KEY) {
      setTimezoneCode(value as string);
    } else {
      setReferrerData({
        ...referrerData,
        affiliation: {
          ...referrerData.affiliation,
          [key]: value,
        },
      });
    }
  };

  const [contactInfoData, setContactInfoData] =
    useState<ContactInfo>(defaultContactInfo);
  const updateContactField = (key: string, value: string) => {
    setContactInfoData({ ...contactInfoData, [key]: value });
  };

  const [patientData, setPatientData] =
    useState<PatientInfo>(defaultPatientData);

  const updatePatientFields = (keys, newPatientData) => {
    setPatientData({ ...patientData, ...newPatientData });
    if (keys.includes('age')) {
      setContactInfoData({
        ...contactInfoData,
        contact:
          newPatientData.age < 18
            ? ReferralMode.PRIMARY_CARER
            : ReferralMode.PATIENT,
      });
    }
  };

  const [notesData, setNotesData] = useState<NotesFormInfo>(defaultNotesData);
  const updateNotesField = (key: keyof InquiryForm, value: string) => {
    setNotesData({ ...notesData, [key]: value });
  };

  const handleCancel = (): void => {
    setReferrerData(defaultReferrerData);
    setContactInfoData(defaultContactInfo);
    setNotesData(defaultNotesData);
    setPatientData(defaultPatientData);
  };

  useEffect(() => {
    trackPageView(PAGE_NAME.referral);
  }, []);

  useEffect(() => {
    const referralStateData =
      referrerData || contactInfoData || notesData || patientData;
    const referralDefaultData =
      defaultReferrerData ||
      defaultContactInfo ||
      defaultNotesData ||
      defaultPatientData;
    if (
      JSON.stringify(referralStateData) !== JSON.stringify(referralDefaultData)
    ) {
      setIsInquiryReferralEdited(true);
    } else {
      setIsInquiryReferralEdited(false);
    }
  }, [contactInfoData, notesData, patientData, referrerData]);

  /** If user is trying to navigate away with unsaved changes, store confirmation in state, else close/clean up */
  const handleReferralExitConfirm = (): void => {
    if (referralDesiredPath) {
      setReferralConfirmNavigation(true);
    } else {
      handleCancel();
      referralHistory.push(referralLocation.pathname);
    }
  };

  // Navigate to the previous blocked location and close/clean up
  useEffect(() => {
    if (referralConfirmNavigation && referralDesiredPath) {
      referralHistory.push(referralDesiredPath);
      handleCancel();
    }
  }, [referralConfirmNavigation, referralDesiredPath]);

  const handleSubmitReferral = (googleRecaptchaToken: string): void => {
    const {
      affiliation,
      affiliationNA,
      email: referrerEmail,
      firstName: referrerFirstName,
      lastName: referrerLastName,
      officeNumber: referrerPhone,
      officeNumberExtension: referrerOfficeNumberExtension,
      relationToPatient,
    } = referrerData;
    const { additionalNotes, hearAboutEquip, tellUsMore } = notesData;
    const {
      age,
      dateOfBirth,
      firstName: patientFirstName,
      lastName: patientLastName,
      state,
    } = patientData;
    const {
      patientPhoneNumber,
      contact,
      primaryCarerFirstName,
      primaryCarerLastName,
      primaryCarerRelationWithPatient,
      primaryCarerPhoneNumber,
    } = contactInfoData;

    const patientContact = {
      firstName:
        contact === ReferralMode.PRIMARY_CARER ? primaryCarerFirstName : '',
      lastName:
        contact === ReferralMode.PRIMARY_CARER ? primaryCarerLastName : '',
      cellPhone:
        contact === ReferralMode.PRIMARY_CARER ? primaryCarerPhoneNumber : '',
      email: '',
      relationToPatient:
        contact === ReferralMode.PRIMARY_CARER
          ? primaryCarerRelationWithPatient
          : '',
    };

    const v1Options = {
      affiliationList: !affiliationNA
        ? [
            {
              addressLine1: affiliation.addressLine1,
              addressLine2: affiliation.addressLine2,
              city: affiliation.city,
              latitude: affiliation.latitude,
              longitude: affiliation.longitude,
              name: affiliation.name,
              state: affiliation.state,
              zipCode: affiliation.zipCode,
            },
          ]
        : null,
      carerEmail: '',
      carerFirstName:
        contact === ReferralMode.PRIMARY_CARER ? primaryCarerFirstName : '',
      carerLastName:
        contact === ReferralMode.PRIMARY_CARER ? primaryCarerLastName : '',
      carerMobile:
        contact === ReferralMode.PRIMARY_CARER ? primaryCarerPhoneNumber : '',
      carerPreferredContact: '',
      carerRelationWithPatient:
        contact === ReferralMode.PRIMARY_CARER
          ? primaryCarerRelationWithPatient
          : '',
      contactPatientDirectly: contact === ReferralMode.PATIENT ? 'yes' : '',
      equipSourceExternalId: hearAboutEquip,
      extension: referrerOfficeNumberExtension,
      informationSource: 'Webform',
      notes: additionalNotes,
      patientAge: age,
      patientBirthDate: getDateOfBirth(dateOfBirth),
      patientEmail: '',
      patientFirstName,
      patientLastName,
      patientMobile: contact === ReferralMode.PATIENT ? patientPhoneNumber : '',
      patientPreferredContact: '',
      patientState: state,
      preferredContact: '',
      referrerEmail,
      referrerFirstName,
      referrerLastName,
      referrerPhone,
      relationWithPatient: relationToPatient,
      tellUsMore,
    };

    const v2Options = {
      tellUsMore,
      equipSourceId: hearAboutEquip,
      channelName: InquiryChannelName.Referral,
      inquiryNotes: additionalNotes,
      inquirerRole: InquirerRole.REFERRER,
      patient: {
        age,
        dateOfBirth: getDateOfBirth(dateOfBirth, DateFormat.yearMonthDay),
        email: '',
        firstName: patientFirstName,
        lastName: patientLastName,
        phoneNumber: contact === ReferralMode.PATIENT ? patientPhoneNumber : '',
        state,
      },
      patientContacts: [
        ...(Object.keys(removeEmptyAttributes(patientContact)).length !== 0
          ? [
              {
                ...removeEmptyAttributes(patientContact),
                // These fields need to be defined, even if false
                isPrimaryContact: true, // We do not want to overwrite isPrimaryContact in user service
                isReferringProvider: false,
                ...(timezoneCode && { timezone: timezoneCode }),
              },
            ]
          : []),
        {
          affiliationName: affiliation.name,
          firstName: referrerFirstName,
          lastName: referrerLastName,
          officePhone: referrerPhone,
          officeExtension: referrerOfficeNumberExtension,
          email: referrerEmail,
          relationToPatient: relationToPatient,
          latitude: affiliation.latitude,
          longitude: affiliation.longitude,
          addressLine1: affiliation.addressLine1,
          addressLine2: affiliation.addressLine2,
          city: affiliation.city,
          state: affiliation.state,
          zipCode: affiliation.zipCode,
          timezone: timezoneCode,
          country: 'US',
          isReferringProvider: true,
          isPrimaryContact: false,
        },
      ],

      coverages: [],
    };

    const options: Record<string, ApiOptionsValue> = {
      ...(isInquiryV2Enabled ? v2Options : v1Options),
      ...generateUtmParams(utmParams),
    };

    if (!isInquiryV2Enabled && timezoneCode) {
      options.timezoneCode = timezoneCode;
    }

    addReferral({
      command: ApiCommand.POST,
      googleRecaptchaToken,
      options,
      skipToken: true,
      url: isInquiryV2Enabled
        ? urlConstants.inquiry_referral.addInquiryV2
        : urlConstants.inquiry_referral.addReferral,
    });
  };

  useEffect(() => {
    if (
      (!addReferralError &&
        !isAddReferralLoading &&
        addReferralStatusCode === 200) ||
      addReferralStatusCode === 201
    ) {
      // @TODO: Use V2 response only
      window.dataLayer?.push({
        referralExternalId:
          typeof data === 'string' ? data : data.referralExternalId,
        taskExternalId: typeof data === 'string' ? null : data.taskExternalId,
      });

      if (addReferralError) {
        Sentry.captureMessage(`Add Referral Error - ${addReferralError}`);
      }

      GAEventsTracker(GAEventForReferral);
      onStepChangeMount(ReferralStep.THANK_YOU);
    }
  }, [addReferralError, addReferralStatusCode, isAddReferralLoading]);

  return (
    <ChakraBox>
      <Prompt
        message={handleNavigateWithReferralUnsavedChanges}
        when={isInquiryReferralEdited}
      />
      <ExitConfirm
        handleClose={handleReferralExitCancel}
        handleConfirm={handleReferralExitConfirm}
        isOpen={isReferralUnsavedChangesModalOpen}
      />
      <CalloutModal
        handleClose={handleCalloutModalClose}
        isOpen={isCalloutModalOpen}
      />
      <Navbar id={NAVBAR_ID} />
      <ChakraSimpleGrid
        columns={2}
        display={{ md: 'flex' }}
        id={`${TOP_LEVEL_ID}__body-container`}
        marginTop="74px"
        minHeight="600px"
        paddingLeft={INQUIRY_REFERRAL_HORIZONTAL_MARGINS}
        paddingRight={INQUIRY_REFERRAL_HORIZONTAL_MARGINS}
        spacing="40px"
      >
        {selectedStep !== ReferralStep.THANK_YOU && (
          <ChakraBox
            id={`${TOP_LEVEL_ID}__left-section`}
            width={['100%', '100%', '100%', '40%', '50%']}
          >
            <ChakraImage
              alt="Referral"
              height="100px"
              id={`${TOP_LEVEL_ID}__image`}
              marginBottom="40px"
              src={Referral}
              width="100px"
            />
            <ChakraText
              {...h1}
              color={blue.shade}
              id={`${TOP_LEVEL_ID}__header`}
              marginBottom="24px"
            >
              Referral
            </ChakraText>

            <ChakraBox
              background="#FFF8E2" //TODO: To be replaced with equip-ui color yellow.shade
              border="none"
              borderRadius="16px"
              id={`${TOP_LEVEL_ID}__request-a-call-section`}
              padding="24px"
              width="fit-content"
            >
              <ChakraText
                {...bodySemibold}
                color={blue.shade}
                marginBottom="12px"
              >
                Want to learn more before referring? No problem.
              </ChakraText>
              <ChakraLink
                {...captionSemibold}
                color="brand.500"
                display="flex"
                fontWeight="500"
                href={SupportMailForReferral}
                marginLeft="0px"
              >
                <PhoneCall fill={teal[100]} height="15px" width="16px" />
                <ChakraText color="teal.100" marginLeft="12px">
                  Request a call with us
                </ChakraText>
              </ChakraLink>
            </ChakraBox>

            <ChakraBox
              {...caption}
              color={mono[70]}
              id={`${TOP_LEVEL_ID}__description`}
              marginBottom="40px"
              marginTop="24px"
            >
              <ChakraText as="span" fontWeight="bold" padding="0 8px 0 0">
                This form is HIPAA-compliant.
              </ChakraText>
              <br />
              <br />
              <ChakraText>
                Anything you provide is confidential. We won't share it with
                anyone else.
              </ChakraText>
            </ChakraBox>

            <StepsComponent
              onStepChangeMount={onStepChangeMount}
              selectedStep={selectedStep}
              steps={steps}
            />
          </ChakraBox>
        )}
        <ChakraBox
          id={`${TOP_LEVEL_ID}__form-section`}
          paddingTop={{ base: '50px', lg: '10px' }}
          width={
            selectedStep !== ReferralStep.THANK_YOU
              ? ['100%', '100%', '100%', '60%', '50%']
              : ['100%', '100%', '100%', '100%', '100%']
          }
        >
          {selectedStep === ReferralStep.YOUR_INFORMATION && (
            <ReferrerInfoForm
              advanceStep={() => onStepChangeMount(2)}
              formData={referrerData}
              updateAffiliationField={updateReferrerAffiliationField}
              updateField={updateReferrerField}
            />
          )}
          {selectedStep === ReferralStep.REFERRED_PATIENT_INFORMATION && (
            <PatientInfoForm
              advanceStep={() => onStepChangeMount(3)}
              formData={patientData}
              previousStep={() => onStepChangeMount(1)}
              updateTextFields={updatePatientFields}
            />
          )}
          {selectedStep === ReferralStep.CONTACT_INFORMATION && (
            <ContactInfoForm
              advanceStep={() => onStepChangeMount(4)}
              formData={contactInfoData}
              patientIsMinor={patientData.age < 18}
              previousStep={() => onStepChangeMount(2)}
              updateTextField={updateContactField}
            />
          )}
          {selectedStep === ReferralStep.REVIEW && (
            <NotesForm
              addReferralLoading={isAddReferralLoading}
              contactInfoData={contactInfoData}
              error={addReferralError}
              formData={notesData}
              patientInfoData={patientData}
              previousStep={() => onStepChangeMount(3)}
              referrerData={referrerData}
              setIsCalloutModalOpen={setIsCalloutModalOpen}
              submitReferral={handleSubmitReferral}
              updateTextField={updateNotesField}
            />
          )}
          {selectedStep === ReferralStep.THANK_YOU && (
            <InquiryReferralConfirmation
              formType={FormType.REFERRAL}
              patientAge={patientData?.age}
              startAnotherReferral={() => {
                onStepChangeMount(2);
                setPatientData({ ...defaultPatientData });
                setContactInfoData({ ...defaultContactInfo });
                updateNotesField('additionalNotes', '');
              }}
            />
          )}
          <br />
          <ChakraFlex
            {...bodySemibold}
            color="teal.100"
            id={`${TOP_LEVEL_ID}__get-in-touch-section`}
            justifyContent="center"
          >
            Want to learn more about referring? &nbsp;
            <ChakraLink href={SupportMailForReferral}>Get in touch</ChakraLink>
          </ChakraFlex>
        </ChakraBox>
      </ChakraSimpleGrid>
      <Footer />
    </ChakraBox>
  );
};

export default ReferralPage;
