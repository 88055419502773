import { FC } from 'react';

import { ChakraText, EquipUITheme, Modal } from '@equip.health/ui';

const { h5, paragraph } = EquipUITheme.typography;

const TOP_LEVEL_ID = 'survey-error';

type SurveyErrorModalProps = { isOpen: boolean };
const SurveyErrorModal: FC<SurveyErrorModalProps> = ({
  isOpen,
}: SurveyErrorModalProps) => {
  return (
    <>
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        hideCloseButton
        id={TOP_LEVEL_ID}
        isOpen={isOpen}
        size="lg"
        title="This assessment is no longer available"
        titleStyle={{ margin: 'auto', ...h5 }}
      >
        <ChakraText
          {...paragraph}
          id={`${TOP_LEVEL_ID}__body-msg-part-one`}
          marginBottom="16px"
        >
          Please reach out to your provider team if you have any questions or
          believe you are seeing this message incorrectly.
        </ChakraText>
        <ChakraText
          {...paragraph}
          id={`${TOP_LEVEL_ID}__body-msg-part-two`}
          marginBottom="16px"
        >
          Thank you!
        </ChakraText>
      </Modal>
    </>
  );
};

export default SurveyErrorModal;
