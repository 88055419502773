import { FC } from 'react';

import {
  ChakraFlex,
  ChakraMenu,
  ChakraMenuButton,
  ChakraMenuItem,
  ChakraMenuList,
  ChakraText,
  Check,
  ChevronDownIcon,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

import UnreadMessageIndicator from './UnreadMessageIndicator';
import { useChatChannelContext } from '~/lib/context/ChatChannelContext';
import { useChatContext } from '~/lib/context/ChatContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { getPreferredProfileFirstName } from '~/lib/utils';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { EVENT } from '~/lib/constants/analytics';

const { paragraph, paragraphSemibold } = EquipUIFireflyV1Theme.typography;
const { black } = EquipUIFireflyV1Theme.colors;

const TOP_LEVEL_ID = 'chat__patient-selector-menu';

interface ChatPatientMenuItemProps {
  isMessageIndicatorVisible: boolean;
  isSelected: boolean;
  name: string;
  onSelected: (patientId) => void;
  patientId: string;
  topLevelId: string;
}

const ChatPatientMenuItem: FC<ChatPatientMenuItemProps> = ({
  isMessageIndicatorVisible,
  isSelected,
  name,
  onSelected,
  patientId,
  topLevelId,
}) => {
  const { track } = useAnalytics();
  const handleClick = () => {
    track(EVENT.MessagesPatientSelectorClick);
    onSelected(patientId);
  };

  const id = `${topLevelId}__${patientId}`;

  return (
    <ChakraMenuItem id={id} onClick={handleClick}>
      <ChakraFlex
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <ChakraFlex alignItems="center" gridGap="6px">
          {isMessageIndicatorVisible && <UnreadMessageIndicator id={id} />}
          <ChakraText color="black.90" {...paragraph} id={`${id}__name`}>
            {name}
          </ChakraText>
        </ChakraFlex>

        {isSelected && <Check fill={black[100]} height="16px" width="16px" />}
      </ChakraFlex>
    </ChakraMenuItem>
  );
};

const ChatPatientSelectorMenu: FC = () => {
  const { userProfile } = useUserProfileContext();
  const { setPatientId, patientId } = useChatChannelContext();
  const { chatIndicatorCache } = useChatContext();
  const { patientsWithUnreadMessages } = chatIndicatorCache;

  const { isPatient, linkedPatients } = userProfile;

  const selectedPatient = linkedPatients?.find(
    (p) => p.externalId === patientId,
  );
  const preferredFirstName =
    selectedPatient && getPreferredProfileFirstName(selectedPatient);

  const isUnreadIndicatorVisible =
    patientsWithUnreadMessages.includes(patientId);

  if (isPatient) {
    return null;
  }

  return (
    <ChakraMenu id={TOP_LEVEL_ID}>
      <ChakraMenuButton>
        <ChakraFlex
          alignItems="center"
          backgroundColor="primary.10"
          borderRadius="10px"
          gridGap="16px"
          padding="12px 16px"
        >
          {isUnreadIndicatorVisible && (
            <UnreadMessageIndicator id={TOP_LEVEL_ID} />
          )}
          <ChakraText
            color="primary.100"
            {...paragraphSemibold}
            id={`${TOP_LEVEL_ID}__selected-patient__title`}
          >
            {preferredFirstName}
          </ChakraText>
          <ChevronDownIcon color="primary.100" />
        </ChakraFlex>
      </ChakraMenuButton>
      <ChakraMenuList>
        {linkedPatients?.map((patient) => {
          return (
            <ChatPatientMenuItem
              isMessageIndicatorVisible={patientsWithUnreadMessages.includes(
                patient.externalId,
              )}
              isSelected={patient.externalId === patientId}
              key={patient.externalId}
              name={getPreferredProfileFirstName(patient)}
              onSelected={setPatientId}
              patientId={patient.externalId}
              topLevelId={TOP_LEVEL_ID}
            />
          );
        })}
      </ChakraMenuList>
    </ChakraMenu>
  );
};

export default ChatPatientSelectorMenu;
