export enum TaskStatusLabel {
  TO_DO = 'To do',
  IN_PROGRESS = 'In progress',
  DONE = 'Done',
  VOID = 'Void',
  CLOSED = 'Closed',
}
export enum FamilyTaskSubcategory {
  INTAKE_SURVEY = 'Intake survey',
  INSURANCE_VERIFICATION = 'Insurance verification',
  MEDICAL_RECORDS_COORDINATION = 'Medical records coordination',
  MEDICAL_CLEARANCE = 'Medical clearance',
  CONSENT_FORMS = 'Consent forms',
  PROVIDER_ASSIGNMENT = 'Provider assignment',
  VITALS = 'Vitals',
  SUB_CATEGORY_TYPE_ASSESSMENT = 'Assess',
}

export enum EmailProvider {
  GMAIL = 'https://mail.google.com/',
  AOL = 'https://mail.aol.com/',
  OUTLOOK = 'https://outlook.office.com',
}
