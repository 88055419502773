import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import {
  Button,
  ChakraBox,
  ChakraFlex,
  Modal,
  useBreakpointValue,
} from '@equip.health/ui';
import * as Sentry from '@sentry/react';

import TaskModalTitle from '../../TaskModalTitle';
import AttachmentItem, { CARD_SIDE } from './AttachmentItem';
import DiscardChangesModal from '~/components/common/DiscardChangesModal';
import ErrorMessage from '~/components/common/ErrorMessage';
import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { useApi } from '~/lib/hooks';

const { deleteAttachment: deleteAttachmentURL, updateTask: updateTaskUrl } =
  urlConstants.tasks;

const AddInsuranceCardModal: FC<{
  reloadTodoList: () => void;
  showAddInsuranceModal: Task | null;
  setShowAddInsuranceModal: Dispatch<SetStateAction<Task | null>>;
}> = ({ reloadTodoList, showAddInsuranceModal, setShowAddInsuranceModal }) => {
  const [frontImageId, setFrontImageId] = useState<string | null>(null);
  const [backImageId, setBackImageId] = useState<string | null>(null);

  const [isFrontImageUploading, setIsFrontImageUploading] =
    useState<boolean>(false);
  const [isBackImageUploading, setIsBackImageUploading] =
    useState<boolean>(false);

  const [showDiscardChangesModal, setShowDiscardChangesModal] =
    useState<boolean>(false);

  const modalSize = useBreakpointValue({
    base: 'full',
    lg: '3xl',
  });

  const {
    error: completeTaskError,
    loading: completeTaskLoading,
    sendRequest: completeTask,
    statusCode: completeTaskStatusCode,
  } = useApi();

  const {
    error: deleteImageAttachmentError,
    sendRequest: deleteImageAttachment,
  } = useApi();

  const { linkedNoteId, taskExternalId } = showAddInsuranceModal ?? {};

  const onCloseModal = () => {
    if (
      frontImageId ||
      backImageId ||
      isFrontImageUploading ||
      isBackImageUploading
    )
      setShowDiscardChangesModal(true);
    else setShowAddInsuranceModal(null);
  };

  const onCompleteTask = () => {
    completeTask({
      command: ApiCommand.PUT,
      options: {
        taskStatusId: 3,
      },
      url: updateTaskUrl(taskExternalId),
    });
  };

  const onConfirmDiscard = () => {
    if (frontImageId) {
      deleteImageAttachment({
        command: ApiCommand.DELETE,
        url: deleteAttachmentURL(linkedNoteId, frontImageId),
      });
    }
    if (backImageId) {
      deleteImageAttachment({
        command: ApiCommand.DELETE,
        url: deleteAttachmentURL(linkedNoteId, backImageId),
      });
    }

    setShowDiscardChangesModal(false);
    setShowAddInsuranceModal(null);
  };

  useEffect(() => {
    if (completeTaskStatusCode === 200) {
      setShowAddInsuranceModal(null);
      reloadTodoList();
    }
  }, [completeTaskStatusCode]);

  useEffect(() => {
    if (deleteImageAttachmentError) {
      Sentry.captureMessage(
        `Delete Attachment Error - ${deleteImageAttachmentError}`,
      );
    }
  }, [deleteImageAttachmentError]);

  return (
    <>
      <Modal
        footerContent={
          <>
            <Button
              boxShadow="none"
              colorScheme="teal"
              onClick={onCloseModal}
              size="md"
              textDecoration="none"
              variant="link"
            >
              Cancel
            </Button>
            <ChakraBox marginLeft="32px">
              <Button
                boxShadow="none"
                colorScheme="teal"
                isDisabled={!(frontImageId && backImageId)}
                isLoading={completeTaskLoading}
                onClick={onCompleteTask}
                size="md"
                variant="solid"
              >
                Confirm
              </Button>
            </ChakraBox>
          </>
        }
        isLazy
        isOpen={Boolean(showAddInsuranceModal)}
        onClose={onCloseModal}
        size={modalSize}
        title={
          <ChakraBox marginRight="32px">
            <TaskModalTitle
              description={showAddInsuranceModal?.taskDescription ?? ''}
              duration={showAddInsuranceModal?.estimatedDuration ?? ''}
              title={showAddInsuranceModal?.taskName ?? ''}
            />
          </ChakraBox>
        }
      >
        <>
          <ChakraBox marginBottom="16px">
            {linkedNoteId && (
              <ChakraFlex
                alignItems={{ base: 'center', md: 'start' }}
                flexDirection={{ base: 'column', md: 'row' }}
                justifyContent="space-between"
              >
                <AttachmentItem
                  attachmentId={frontImageId}
                  cardSide={CARD_SIDE.FRONT}
                  isDisabled={completeTaskLoading}
                  noteId={linkedNoteId}
                  setAttachmentId={setFrontImageId}
                  setIsFileUploading={setIsFrontImageUploading}
                />
                <AttachmentItem
                  attachmentId={backImageId}
                  cardSide={CARD_SIDE.BACK}
                  isDisabled={completeTaskLoading}
                  noteId={linkedNoteId}
                  setAttachmentId={setBackImageId}
                  setIsFileUploading={setIsBackImageUploading}
                />
              </ChakraFlex>
            )}
          </ChakraBox>
          {completeTaskError && (
            <ChakraBox>
              <ErrorMessage
                id="complete-task___error"
                message="Unable to confirm."
                textAlign="right"
              />
            </ChakraBox>
          )}
        </>
      </Modal>

      <DiscardChangesModal
        confirmButtonText="Discard"
        description="Any images you've uploaded will be discarded."
        handleClose={() => setShowDiscardChangesModal(false)}
        handleConfirmDiscard={onConfirmDiscard}
        isOpen={showDiscardChangesModal}
        title="Discard changes?"
      />
    </>
  );
};

export default AddInsuranceCardModal;
