import { FC, useEffect, useState } from 'react';

import { ChakraFlex, EquipLogoWithText } from '@equip.health/ui';
import { Link, useHistory, useLocation } from 'react-router-dom';

import AuthenticatedMenu from './AuthenticatedMenu';
import MobileAuthenticatedNav from './MobileAuthenticatedNav';
import NavItem from './NavItem';
import { AUTHENTICATED_NAV_LAYOUT, EquipAppPath } from '~/lib/constants';
import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { EVENT } from '~/lib/constants/analytics';

const TOP_LEVEL_ID = 'equip_app__authenticated_nav';

interface AuthenticateNavProps {
  isShowIndicator: boolean;
}

const AuthenticatedNav: FC<AuthenticateNavProps> = ({
  isShowIndicator,
}: AuthenticateNavProps) => {
  const isMobileDevice = useMobileBreakpoint();
  const history = useHistory();
  const location = useLocation();
  const { track } = useAnalytics();

  const isHomeItemSelected = location.pathname === EquipAppPath.HOME;
  const isMessagesItemSelected = location.pathname === EquipAppPath.MESSAGES;
  const isScheduleSelected = location.pathname === EquipAppPath.SCHEDULE;

  const [shouldShowBorder, setShouldShowBorder] = useState<boolean>(
    isMessagesItemSelected,
  );

  const onScroll = (): void => setShouldShowBorder(window.scrollY > 0);

  useEffect(() => {
    if (!isMessagesItemSelected) {
      window.addEventListener('scroll', onScroll);
    }
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  const handleHomeClick = () => {
    track(EVENT.NavbarHomeClick);
    history.push(EquipAppPath.HOME);
  };

  const handleScheduleClick = () => {
    track(EVENT.NavbarScheduleClick);
    history.push(EquipAppPath.SCHEDULE);
  };
  const handleMessagesClick = () => {
    track(EVENT.NavbarMessagesClick);
    history.push(EquipAppPath.MESSAGES);
  };

  if (isMobileDevice) {
    return (
      <MobileAuthenticatedNav
        id={TOP_LEVEL_ID}
        isHomeSelected={isHomeItemSelected}
        isMessagesSelected={isMessagesItemSelected}
        isScheduleSelected={isScheduleSelected}
        isShowUnreadMessageIndicator={isShowIndicator}
        onHomeClick={handleHomeClick}
        onMessagesClick={handleMessagesClick}
        onScheduleClick={handleScheduleClick}
      />
    );
  }
  return (
    <ChakraFlex
      alignItems="center"
      backgroundColor="white"
      borderBottomColor="neutral.line"
      borderBottomWidth={shouldShowBorder ? '1px' : '0'}
      height={AUTHENTICATED_NAV_LAYOUT.desktopHeight}
      id={`${TOP_LEVEL_ID}_container`}
      justifyContent="space-between"
      padding="0 24px"
      position="fixed"
      width="100%"
      zIndex="100"
    >
      <Link
        id={`${TOP_LEVEL_ID}__logo-image`}
        to={{ pathname: EquipAppPath.HOME }}
      >
        <EquipLogoWithText />
      </Link>
      <ChakraFlex>
        <NavItem
          isSelected={location.pathname === EquipAppPath.HOME}
          onClick={handleHomeClick}
          title="Home"
          type="primary"
        />
        <NavItem
          isSelected={location.pathname === EquipAppPath.SCHEDULE}
          onClick={handleScheduleClick}
          title="Schedule"
          type="primary"
        />
        <NavItem
          isSelected={isMessagesItemSelected}
          isShowIndicator={isShowIndicator}
          onClick={handleMessagesClick}
          title="Messages"
          type="primary"
        />
        <ChakraFlex
          alignItems="center"
          id={`${TOP_LEVEL_ID}_profile_tab`}
          marginLeft="32px"
        >
          <AuthenticatedMenu />
        </ChakraFlex>
      </ChakraFlex>
    </ChakraFlex>
  );
};

export default AuthenticatedNav;
