import { FC } from 'react';

import { Text } from '@equip.health/ui';
import { Member } from '@sendbird/chat/groupChannel';
import { isNil } from 'lodash';

import { Participant } from '~/components/common/Participant';
import { getUserTypeFromChatUser } from '~/lib/util/chat/chat.utils';

interface ParticipantListProps {
  id: string;
  members: Member[];
}

const ParticipantList: FC<ParticipantListProps> = ({ id, members }) => {
  return (
    <>
      <Text
        color="black.60"
        id={`${id}__header`}
        marginBottom="24px"
        variant="caption"
      >
        {`${members.length} participants`}
      </Text>
      {members.map((member) => {
        if (isNil(member)) {
          return null;
        }

        const { providerType, userType } = (member.metaData ?? {}) as {
          providerType: string;
          userType: string;
        };

        return (
          <Participant
            id={id}
            key={member.userId}
            marginBottom="24px"
            primaryLabel={member.nickname}
            secondaryLabel={providerType || userType}
            userId={member.userId}
            userType={getUserTypeFromChatUser(member)}
          />
        );
      })}
    </>
  );
};

export default ParticipantList;
