import { FC } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraText,
  EquipUITheme,
  FAIconName,
  Icon,
} from '@equip.health/ui';

import { MyEquipNavItem } from '~/lib/constants/authenticatedNav.constants';
import {
  getNavItemColor,
  getNavTitleColor,
} from '~/lib/util/authenticatedNav.util';

const { typography } = EquipUITheme;

interface MobileAuthNavItemProps {
  isSelected: boolean;
  id?: string;
  onClick: () => void;
  itemType: MyEquipNavItem;
  title: string;
}

const getNavIconName = (nav: MyEquipNavItem): string | null => {
  if (nav === MyEquipNavItem.HOME) return FAIconName.homeRegular;
  if (nav === MyEquipNavItem.PROFILE) return FAIconName.userRegular;
  if (nav === MyEquipNavItem.MESSAGES) return FAIconName.messageRegular;
  if (nav === MyEquipNavItem.SCHEDULE) return FAIconName.calendarRegular;
  return null;
};

const MobileAuthNavItem: FC<MobileAuthNavItemProps> = ({
  id,
  onClick,
  itemType,
  isSelected,
  title,
}: MobileAuthNavItemProps) => {
  return (
    <ChakraBox
      backgroundColor={getNavItemColor(isSelected, false, true)}
      id={id}
      minWidth="80px"
      onClick={onClick}
      padding="12px 16px"
    >
      <ChakraFlex alignItems="center" flexDirection="column">
        <Icon
          color={getNavTitleColor(isSelected, false, true)}
          name={getNavIconName(itemType)}
        />
        <ChakraText
          {...typography.caption}
          align="center"
          color={getNavTitleColor(isSelected, false, true)}
          paddingTop="6px"
        >
          {title}
        </ChakraText>
      </ChakraFlex>
    </ChakraBox>
  );
};

export default MobileAuthNavItem;
