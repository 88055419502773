import { getNumberSuffix } from '@equip.health/ui';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';

import StringUtil from './string.util';
import {
  AVAILABILITY_RANGE_UPPER_BOUND_WEEKS,
  AVAILABILITY_SLOT_INTERVAL_MINUTES,
  CRONOFY_ELEMENT_VERSION,
  CalendarResource,
  CronofyAvailabilityError,
  LEAD_TIME_MINUTES,
  REQUESTED_TIME_SLOT_NOT_AVAILABLE_CLIENT_ERROR_MESSAGE,
  REQUESTED_TIME_SLOT_NOT_AVAILABLE_SERVER_ERROR_MESSAGE,
  SAVE_APPOINTMENT_ERROR_MESSAGE,
  UNRECOGNIZED_PARTICIPANT_REG_EXP,
  ZOOM_BEFORE_AVAILABLE_MINUTES,
} from '~/lib/constants/schedule.constants';
import { getPreferredFirstName, getPreferredFullName } from '~/lib/utils';

export const formatQueryPeriodDateString = (date: DateTime): string =>
  `${date.toJSDate().toISOString().split('.')[0]}Z`;

export const addMillis = (dateString: string): string =>
  `${dateString.slice(0, -1)}.000Z`;

export const formatSelectedSlotDateString = (date: DateTime): string =>
  `${date.toFormat('cccc, LLLL d')}${getNumberSuffix(date.day)}, at ${date
    .toFormat('h:mm a')
    .toLowerCase()} (${date.offsetNameShort})`;

type GenerateAvailabilityQueryParams = {
  availabilityRule: AppointmentType;
  end: DateTime;
  intervalInMinutes?: number;
  requiredParticipants?: number | 'all';
  start: DateTime;
  userCalendars: UserCalendarsInfoData['calendarInfos'];
};

export const generateAvailabilityQuery = ({
  availabilityRule,
  end,
  intervalInMinutes = AVAILABILITY_SLOT_INTERVAL_MINUTES,
  requiredParticipants = 1,
  start,
  userCalendars,
}: GenerateAvailabilityQueryParams): CalendarResourceConfigAvailabilityQuery => {
  const { appointmentType, bufferInMinutes, durationInMinutes } =
    availabilityRule;

  return {
    buffer: {
      after: { minutes: bufferInMinutes },
      before: { minutes: 0 },
    },
    participants: [
      {
        members: userCalendars.map(({ calendarId, subId }) => ({
          availability_rule_ids: [appointmentType],
          calendar_ids: [calendarId],
          managed_availability: true,
          sub: subId,
        })),
        required: requiredParticipants,
      },
    ],
    query_periods: [
      {
        end: formatQueryPeriodDateString(end),
        start: formatQueryPeriodDateString(start),
      },
    ],
    required_duration: {
      minutes: durationInMinutes,
    },
    response_format: 'overlapping_slots',
    start_interval: {
      minutes: intervalInMinutes,
    },
  };
};

export const generateParticipantNameString = (
  data: AppointmentDetailResponse,
  userProfile: UserProfile,
): string => {
  const {
    host,
    isPatientAttending,
    patient,
    providerAttendees,
    supportAttendees,
  } = data;
  let nameString = '';
  let loggedInSupportAttendedEvent = false;

  if (host && host.name) {
    nameString += host.name;
  }

  if (providerAttendees && providerAttendees.length) {
    providerAttendees.forEach((t) => {
      nameString += `, ${t.name}`;
    });
  }

  if (
    patient &&
    isPatientAttending &&
    patient.externalId !== userProfile.externalId
  ) {
    nameString += `, ${getPreferredFirstName(
      patient?.chosenName,
      patient?.firstName,
    )} `;
  }

  if (supportAttendees && supportAttendees.length) {
    supportAttendees.forEach((t) => {
      if (t.externalId !== userProfile.externalId) {
        nameString += `, ${getPreferredFirstName(t?.chosenName, t?.firstName)}`;
      } else {
        loggedInSupportAttendedEvent = true;
      }
    });
  }
  if (
    loggedInSupportAttendedEvent ||
    (patient &&
      isPatientAttending &&
      patient.externalId === userProfile.externalId)
  ) {
    nameString += ` & you`;
  }
  return nameString;
};

export const generateParticipantNameList = (
  data: AppointmentDetailResponse,
  currentUserId: string,
) => {
  const {
    host,
    isPatientAttending,
    patient,
    providerAttendees,
    supportAttendees,
  } = data;

  const participantList: AppointmentAttendee[] = [];
  if (host && host.name) {
    participantList.push({
      name: host.name,
      externalId: host.externalId,
      userType: 'host',
      role: host.providerType,
      isUser: host.externalId === currentUserId,
    });
  }

  if (providerAttendees && providerAttendees.length) {
    providerAttendees.forEach((attendee) => {
      participantList.push({
        name: attendee.name,
        externalId: attendee.externalId,
        userType: 'provider',
        role: attendee.providerType,
        isUser: false,
      });
    });
  }

  if (patient && isPatientAttending) {
    participantList.push({
      name: getPreferredFirstName(patient?.chosenName, patient?.firstName),
      externalId: patient.externalId,
      userType: 'patient',
      role: 'Patient',
      isUser: patient.externalId === currentUserId,
    });
  }

  if (supportAttendees && supportAttendees.length) {
    supportAttendees.forEach((t) => {
      participantList.push({
        name: getPreferredFirstName(t?.chosenName, t?.firstName),
        externalId: t.externalId,
        userType: 'support',
        role: 'Support',
        isUser: t.externalId === currentUserId,
      });
    });
  }

  return sortedParticipantList(participantList);
};

const sortedParticipantList = (participantList: AppointmentAttendee[]) => {
  participantList.sort((a, b) => {
    if (a.isUser) {
      return 1;
    }
    if (b.isUser) {
      return -1;
    }
    return 0;
  });

  return participantList;
};

export const transformDateFromISOtoLocal = (ISODate: string): string => {
  return DateTime.fromISO(ISODate)
    .toLocaleString({
      hour: 'numeric',
      hourCycle: 'h12',
      minute: '2-digit',
    })
    .toLowerCase()
    .replace(/\s/g, '');
};

export const isEventStartingSoon = (ISODate: string): boolean => {
  const today = DateTime.now();
  const difference = DateTime.fromISO(ISODate).diff(today, 'minute').toObject();
  return difference.minutes <= ZOOM_BEFORE_AVAILABLE_MINUTES;
};

export enum Recurrence {
  DOES_NOT_REPEAT = 'Does not repeat',
  WEEKLY = 'Weekly',
  BIWEEKLY = 'Biweekly',
  MONTHLY = 'Monthly',
}

export const getRecurrenceDisplayString = (
  start: string,
  recurrence: string,
): string => {
  const recurrenceOption = (recurrence as Recurrence).toLowerCase();
  const dayOfWeek = DateTime.fromISO(start).toFormat('cccc');
  if (recurrenceOption === Recurrence.WEEKLY.toLowerCase())
    return `Weekly on ${dayOfWeek}`;
  if (recurrenceOption === Recurrence.BIWEEKLY.toLowerCase())
    return `Every 2 weeks on ${dayOfWeek}`;
  if (recurrenceOption === Recurrence.MONTHLY.toLowerCase()) {
    return `Every 4 weeks on ${dayOfWeek}`;
  }

  return undefined;
};

export const checkIsToday = (comparingDay: string): boolean => {
  return (
    DateTime.now().toLocaleString() ===
    DateTime.fromISO(comparingDay).toLocaleString()
  );
};
export const checkIsTomorrow = (comparingDay: string): boolean => {
  return (
    DateTime.now().plus({ days: 1 }).toLocaleString() ===
    DateTime.fromISO(comparingDay).toLocaleString()
  );
};

export const formatAppointmentStartDate = (startDateTime: string): string => {
  if (checkIsToday(startDateTime)) return 'Today';
  if (checkIsTomorrow(startDateTime)) return 'Tomorrow';
  return DateTime.fromISO(startDateTime).toFormat('ccc, LLL d');
};

export const getAppointmentStartingSoonText = (
  startDateTime: string,
): string => {
  const today = DateTime.now();
  const startDate = DateTime.fromISO(startDateTime);
  const differenceInMinutes = startDate
    .diff(today, 'minute')
    .toObject().minutes;
  if (differenceInMinutes > 0) {
    return `Starting in ${Math.ceil(differenceInMinutes)}m`;
  }
  return 'Live now';
};

export const scheduledDateFormat = (dataString: string): string => {
  return DateTime.fromISO(dataString).toISODate();
};

export const groupScheduledEventByDate = (
  data: AppointmentDetailResponse[],
): GroupedScheduleDataListType[] => {
  if (!data) {
    return [];
  }
  const groupedSchedule = data?.reduce((prevGroupedSchedule, schedule) => {
    const date = scheduledDateFormat(schedule?.appointmentStartDateTime);
    return {
      ...prevGroupedSchedule,
      [date]: [...(prevGroupedSchedule[date] ?? []), schedule],
    };
  }, {});
  const mappedGroupedSchedule = Object.keys(groupedSchedule).map((elem) => {
    return {
      date: elem,
      schedules: groupedSchedule[elem],
    };
  });
  return mappedGroupedSchedule;
};

export const groupCombinedEventsByDate = (
  data: ScheduledEventItem[],
): GroupedCombinedScheduleDataListType[] => {
  if (!data) return [];
  const groupedSchedule = data.reduce((prevGroupedSchedule, schedule) => {
    const date = scheduledDateFormat(
      schedule.eventInfo[
        schedule.isGroupClassEvent ? 'startTime' : 'appointmentStartDateTime'
      ],
    );
    return {
      ...prevGroupedSchedule,
      [date]: [...(prevGroupedSchedule[date] ?? []), schedule],
    };
  }, {});
  return Object.keys(groupedSchedule).map((elem) => {
    return {
      date: elem,
      schedules: groupedSchedule[elem],
    };
  });
};

export const combineAppointmentsWithClasses = (
  _appointments: AppointmentDetailResponse[],
  _classes: GroupClassEventDetails[],
): ScheduledEventItem[] => {
  const appointments = _appointments ?? [];
  const classes = _classes ?? [];

  const result: ScheduledEventItem[] = [];
  let apptIndex = 0;
  let classIndex = 0;
  while (apptIndex < appointments.length || classIndex < classes.length) {
    let pushAppointment = false;
    if (!classes[classIndex]) {
      // no classes left -> push appointment
      pushAppointment = true;
    } else if (appointments[apptIndex]) {
      // both classes and appointments left -> push the earlier event
      const apptStart: DateTime = DateTime.fromISO(
        appointments[apptIndex].appointmentStartDateTime,
      );
      const classStart: DateTime = DateTime.fromISO(
        classes[classIndex].startTime,
      );
      pushAppointment = apptStart <= classStart;
    } // implied else: no appointments -> push class
    if (pushAppointment) {
      result.push({
        eventInfo: appointments[apptIndex],
        isGroupClassEvent: false,
      });
      apptIndex++;
    } else {
      result.push({
        eventInfo: classes[classIndex],
        isGroupClassEvent: true,
      });
      classIndex++;
    }
  }
  return result;
};

type AvailabilityRequestPayload = CalendarResourceConfigAvailabilityQuery;

/** Divide users into groups based on the MAX_PARTICIPANTS_PER_AVAILABILITY_QUERY and generate availability queries */
export const getBatchedAvailabilityRequests = (
  availabilityRule: AppointmentType,
  userCalendars: UserCalendarsInfoData['calendarInfos'],
  maxParticipants: number,
): AvailabilityRequestPayload[] => {
  const start = DateTime.now().plus({ minutes: LEAD_TIME_MINUTES });

  const availabilityRequestPayloads: AvailabilityRequestPayload[] = [];

  for (let i = 0; i < userCalendars.length; i += maxParticipants) {
    availabilityRequestPayloads.push(
      generateAvailabilityQuery({
        availabilityRule,
        end: start.plus({
          weeks: AVAILABILITY_RANGE_UPPER_BOUND_WEEKS,
        }),
        start,
        userCalendars: userCalendars.slice(i, i + maxParticipants),
      }),
    );
  }

  return availabilityRequestPayloads;
};

/**
 * Get subs for all participants who are available for any time slot by combining
 * the results of multiple availability API call responses
 */
const getParticipantSubsByTimeSlot = (
  availabilityResponse: AxiosResponse<ScheduleAvailabilityResponse>[],
): Record<string, string[]> => {
  const participantSubsByUniqueTimeSlot: Record<string, string[]> = {};

  availabilityResponse
    .reduce(
      (allSlots, result) =>
        allSlots.concat(result?.data?.available_slots ?? []),
      [],
    )
    .forEach((timeSlot: TimeSlot) => {
      const timeSlotKey = `${timeSlot.start}.${timeSlot.end}`;
      participantSubsByUniqueTimeSlot[timeSlotKey] = (
        participantSubsByUniqueTimeSlot[timeSlotKey] ?? []
      ).concat(timeSlot.participants.map(({ sub }) => sub));
    });

  return participantSubsByUniqueTimeSlot;
};

/**
 * Aggregate any number of availability responses into an array of TimeSlot objects
 * representing all participants who are available for each time slot
 */
export const getAggregateTimeSlots = (
  availabilityResponse: AxiosResponse<ScheduleAvailabilityResponse>[],
): TimeSlot[] => {
  const participantsByTimeSlot =
    getParticipantSubsByTimeSlot(availabilityResponse);

  return Object.entries(participantsByTimeSlot)
    .map(([timeSlotKey, subs]: [string, string[]]) => {
      const [start, end] = timeSlotKey.split('.');
      return {
        end,
        participants: [...new Set(subs)].map((sub: string) => ({ sub })),
        start,
      };
    })
    .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
};

export const getSaveAppointmentErrorMessage = (
  baseError: string,
  errorData: ApiErrorData,
): string => {
  let saveAppointmentErrorMessage = null;
  if (baseError || errorData) {
    saveAppointmentErrorMessage =
      errorData?._embedded?.errors?.[0]?.message ===
      REQUESTED_TIME_SLOT_NOT_AVAILABLE_SERVER_ERROR_MESSAGE
        ? REQUESTED_TIME_SLOT_NOT_AVAILABLE_CLIENT_ERROR_MESSAGE
        : SAVE_APPOINTMENT_ERROR_MESSAGE;
  }
  return saveAppointmentErrorMessage;
};

export const isRequestedTimeSlotNotAvailableClientError = (
  errorMessage: string,
): boolean =>
  errorMessage === REQUESTED_TIME_SLOT_NOT_AVAILABLE_CLIENT_ERROR_MESSAGE;

export const getCronofyElementHeaderValue = (
  calendarResource: CalendarResource = CalendarResource.DATE_TIME_PICKER,
): string =>
  `${CRONOFY_ELEMENT_VERSION}, ${calendarResource.split(' ').join('')}`;

// Get a list of Cronofy errors specifically related to fetching availability
export const getAvailabilityErrors = (
  error: AvailabilityErrorResponse,
  userCalendars: UserCalendarsInfoData['calendarInfos'],
  payload: AvailabilityRequestPayload,
): Record<string, string[]> => {
  const participantErrors: Record<string, string[]> = {};

  Object.entries(error.response.data?.errors ?? {}).forEach(
    ([errorKey, errorValue]: [
      string,
      { key: string; description: string }[],
    ]) => {
      let sub = errorKey.split('participant[sub=')[1]?.slice(0, -1);

      if (!sub) {
        const idx = Number.parseInt(
          errorKey.match(UNRECOGNIZED_PARTICIPANT_REG_EXP)?.[1],
          10,
        );
        sub = userCalendars.find(
          ({ subId }) => payload.participants[0].members[idx]?.sub === subId,
        )?.subId;
      }

      const hasErrorKey = (error: CronofyAvailabilityError): boolean =>
        errorValue.some(({ key }) => key === `errors.${error}`);

      if (sub) {
        participantErrors[sub] = participantErrors[sub] ?? [];

        if (
          hasErrorKey(CronofyAvailabilityError.AVAILABILITY_RULE_ID_UNKNOWN)
        ) {
          participantErrors[sub].push(
            CronofyAvailabilityError.AVAILABILITY_RULE_ID_UNKNOWN,
          );
        }
        if (hasErrorKey(CronofyAvailabilityError.CALENDAR_ID_INVALID)) {
          participantErrors[sub].push(
            CronofyAvailabilityError.CALENDAR_ID_INVALID,
          );
        }
        if (hasErrorKey(CronofyAvailabilityError.NOT_RECOGNIZED)) {
          participantErrors[sub].push(CronofyAvailabilityError.NOT_RECOGNIZED);
        }
        if (hasErrorKey(CronofyAvailabilityError.INVALID_FORMAT)) {
          participantErrors[sub].push(CronofyAvailabilityError.INVALID_FORMAT);
        }
      }
    },
  );

  return participantErrors;
};

// Convert a record of participant error messages (see "getAvailabilityErrors") into a single string for Sentry logging purposes
export const getAvailabilityErrorMessage = (
  participantErrors: Record<string, string[]>,
): string =>
  Object.entries(participantErrors)
    .map(
      ([sub, errorMessages]: [string, string[]]) =>
        `SUB_ID "${sub}" ERRORS = ${errorMessages.join(', ')}`,
    )
    .join('; ');

export const generateHostedByString = (
  hostsList: GroupClassEventHost[],
): string => {
  const nameList = hostsList.map((h) =>
    getPreferredFullName(null, h.firstName, h.lastName),
  );
  return generateHostedByStringFromNames(nameList);
};

export const generateHostedByStringFromNames = (nameList: string[]): string => {
  const joinedNames = StringUtil.joinStringList(nameList, '&');
  return joinedNames ? `Hosted by ${joinedNames}` : '';
};
