import {
  ChakraBox,
  ChakraFlex,
  ChakraText,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

import { ADMISSIONS_PHONE_NUMBER } from '~/lib/constants';

const TOP_LEVEL_ID = 'inquiry__appointment_scheduler__availability-error';

const { h4, paragraph, paragraphSemibold } = EquipUIFireflyV1Theme.typography;

const AvailabilityErrorMessage = () => {
  return (
    <ChakraFlex
      {...paragraph}
      color="mono.70"
      direction="column"
      textAlign="center"
    >
      <ChakraText {...h4} color="mono.100" id={`${TOP_LEVEL_ID}__title`}>
        We're sorry, we couldn't find any availability
      </ChakraText>
      <br />
      <ChakraText id={`${TOP_LEVEL_ID}__message_1`}>
        {`Unfortunately, we aren't able to schedule a call with an Equip representative at this time.
        We're sorry for any inconvenience this may have caused.`}
      </ChakraText>
      <br />
      <ChakraBox>
        <ChakraText as="span" id={`${TOP_LEVEL_ID}__message_2`}>
          {`However, we have your information, and someone from our team will reach out within 3 business days,
          or you can call our admissions team any time at `}
        </ChakraText>
        <ChakraText
          {...paragraphSemibold}
          as="span"
          id={`${TOP_LEVEL_ID}__message_3`}
        >
          {`${ADMISSIONS_PHONE_NUMBER}.`}
        </ChakraText>
      </ChakraBox>
    </ChakraFlex>
  );
};

export default AvailabilityErrorMessage;
