import { FC } from 'react';

import isNil from 'lodash/isNil';

import BookAppointmentStepContainer, {
  ListContainer,
} from '~/components/schedule/BookAppointment/base/StepContainer';
import AppointmentUserAvatarCard from '~/components/schedule/BookAppointment/cards/AppointmentUserAvatarCard';
import { BOOK_APPOINTMENT_STEP } from '~/lib/constants/bookAppointments';
import { useBookAppointmentContext } from '~/lib/context/BookAppointmentContext';
import { useSchedulePermissionsContext } from '~/lib/context/SchedulePermissionsContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';

const topLevelId = 'book-appointment__provider-type';

const BookAppointmentAppointmentType: FC = () => {
  const {
    patientId,
    setNextStep,
    selectedAppointmentType,
    setSelectedProvider,
  } = useBookAppointmentContext();

  const { patientAppointmentTypesMapper } = useSchedulePermissionsContext();
  const appointmentTypes = patientAppointmentTypesMapper[patientId];

  const {
    userProfile: { isPatient },
  } = useUserProfileContext();

  const handleForwardNavigation = () => {
    setNextStep(BOOK_APPOINTMENT_STEP.attendees);
  };
  const handleBackwardNavigation = () => {
    setNextStep(BOOK_APPOINTMENT_STEP.patient);
  };

  return (
    <BookAppointmentStepContainer
      isBackButtonVisible={!isPatient}
      isContinueButtonDisabled={isNil(selectedAppointmentType)}
      onBackwardNavigation={handleBackwardNavigation}
      onForwardNavigation={handleForwardNavigation}
      stepId={topLevelId}
      title="Who would you like to book an appointment with?"
    >
      <ListContainer>
        {appointmentTypes?.map((appointmentType) => {
          const { infoText, providerExternalId, providerName, providerType } =
            appointmentType;

          const isSelected =
            selectedAppointmentType?.providerExternalId === providerExternalId;

          return (
            <AppointmentUserAvatarCard
              id={providerExternalId}
              isSelected={isSelected}
              key={providerExternalId}
              onClick={() => setSelectedProvider(appointmentType)}
              primaryLabel={providerName}
              secondaryLabel={providerType}
              tooltip={infoText}
              userType="provider"
            />
          );
        })}
      </ListContainer>
    </BookAppointmentStepContainer>
  );
};

export default BookAppointmentAppointmentType;
