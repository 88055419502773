import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ChatSliceState {
  isMessagesLoading: boolean;
}

const initialState: ChatSliceState = {
  isMessagesLoading: true,
};

export const chatSlice = createSlice({
  initialState,
  name: 'chat',
  reducers: {
    setIsMessagesLoading: (state, action: PayloadAction<boolean>) => {
      state.isMessagesLoading = action.payload;
    },
  },
});

export const { setIsMessagesLoading } = chatSlice.actions;
export default chatSlice.reducer;
