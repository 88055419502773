import { createContext, PropsWithChildren, useState } from 'react';

import { InquiryReferralContextState } from './types';

const contextDefaultValues: InquiryReferralContextState = {
  isInquiryReferralEdited: false,
  setIsInquiryReferralEdited: (_value: boolean): void => {
    throw new Error('Function not implemented.');
  },
};

export const InquiryReferralContext =
  createContext<InquiryReferralContextState>(contextDefaultValues);

const InquiryReferralProvider = ({ children }: PropsWithChildren) => {
  const [isEditable, setIsEditable] = useState<boolean>(
    contextDefaultValues.isInquiryReferralEdited,
  );

  const setIsInquiryReferralEdited = (value: boolean) => setIsEditable(value);

  return (
    <InquiryReferralContext.Provider
      value={{
        isInquiryReferralEdited: isEditable,
        setIsInquiryReferralEdited,
      }}
    >
      {children}
    </InquiryReferralContext.Provider>
  );
};

export default InquiryReferralProvider;
