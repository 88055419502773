/* eslint-disable import/prefer-default-export */

const LAYOUT_DIMENSIONS = {
  top: 80,
  bottom: 90,
  left: 500,
  mobileBuffer: 120,
};

const ASSESSMENT_NOT_FOUND = 'Assessment not found';

export { LAYOUT_DIMENSIONS, ASSESSMENT_NOT_FOUND };
