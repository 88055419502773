import { FC } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  EquipUIFireflyV2Theme,
  FAIconName,
  Icon,
  Text,
} from '@equip.health/ui';

import BaseCard, {
  BaseCardProps,
} from '~/components/schedule/BookAppointment/base/BaseCard';

const { neutral } = EquipUIFireflyV2Theme.colors;

export interface AppointmentOptionsCardProps extends BaseCardProps {
  primaryLabel: string;
  tooltip?: string;
}

const topLevelId = 'appointment-options-card';

const AppointmentOptionsCard: FC<AppointmentOptionsCardProps> = ({
  id,
  isSelected,
  onClick,
  primaryLabel,
  tooltip,
}) => {
  return (
    <BaseCard
      id={`${topLevelId}__container__${id}`}
      isSelected={isSelected}
      onClick={onClick}
    >
      <ChakraFlex
        alignItems="center"
        justifyContent="flex-start"
        minHeight={{ base: '20px', md: '48px' }}
      >
        <ChakraFlex direction="column" gridGap="6px">
          <ChakraFlex alignItems="center" gridGap="8px">
            <Text
              color="neutral.primary"
              id={`${topLevelId}__name__${id}`}
              variant="bodyMedium"
            >
              {primaryLabel}
            </Text>
          </ChakraFlex>
          {tooltip && (
            <ChakraFlex alignItems="center" gridGap="6px">
              <ChakraBox>
                <Icon
                  color={neutral.secondary}
                  name={FAIconName.infoCircleRegular}
                  size="sm"
                />
              </ChakraBox>
              <Text
                color="neutral.secondary"
                id={`${topLevelId}__tooltip__${id}`}
                variant="caption"
              >
                {tooltip}
              </Text>
            </ChakraFlex>
          )}
        </ChakraFlex>
      </ChakraFlex>
    </BaseCard>
  );
};

export default AppointmentOptionsCard;
