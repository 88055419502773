import { FC } from 'react';

import { FireflyButton } from '@equip.health/ui';
import { useHistory } from 'react-router-dom';

import { TaskStatusLabel } from '~/lib/constants/tasks.constants';
import TaskModal from './TaskModal';

interface SurveyTaskModalProps {
  task: Task;
  isVisible: boolean;
  onClose: () => void;
}

const TOP_LEVEL_ID = 'INTAKE_SURVEY_FAMILY_TASK_MODAL';

const SurveyTaskModal: FC<SurveyTaskModalProps> = ({
  task,
  isVisible,
  onClose,
}) => {
  const {
    estimatedDuration,
    linkedSurveyId,
    taskName,
    taskDescription,
    taskStatus,
  } = task;
  const history = useHistory();
  const buttonTitle =
    linkedSurveyId && taskStatus === TaskStatusLabel.IN_PROGRESS
      ? 'Resume survey'
      : 'Start survey';
  const handleStartSurvey = () => {
    history.push('/survey', { surveyId: linkedSurveyId });
    onClose();
  };

  return (
    <TaskModal
      estimatedDuration={estimatedDuration}
      footerContent={
        <FireflyButton
          id={`${TOP_LEVEL_ID}__start`}
          onClick={handleStartSurvey}
        >
          {buttonTitle}
        </FireflyButton>
      }
      handleClose={onClose}
      id={`${TOP_LEVEL_ID}__detail`}
      isOpen={isVisible}
      taskDescription={taskDescription}
      taskName={taskName}
    />
  );
};

export default SurveyTaskModal;
