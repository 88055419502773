import { FC, useCallback, useRef, useState } from 'react';

import {
  Alert,
  ChakraBox,
  ChakraFlex,
  ChakraText,
  EquipUIFireflyV1Theme,
  FAIconName,
  Icon,
} from '@equip.health/ui';
import { Member } from '@sendbird/chat/groupChannel';
import { Mention, MentionsInput } from 'react-mentions';

import {
  AttachmentsButton,
  AttachmentsPreview,
  isChatAttachmentsEnabled,
} from '~/components/chat/ChatAttachments';
import { Participant } from '~/components/common/Participant';
import { TEXT_NEW_LINE, USER_ROLES } from '~/lib/constants/chat/chat.constants';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { useFileLoader } from '~/lib/hooks/useFileLoader';
import './MessageInputBox.css';
import { MemberMetaData, MentionsBoxUser } from '~/lib/types/chat';
import { getFileExtension, isAllowedFileType } from '~/lib/util/file.util';
import { CHAT_ACCEPTED_FILE_EXTENSION } from '~/lib/util/patientDocuments.util';

import style from './styles';

const { colors, typography } = EquipUIFireflyV1Theme;
const { paragraph } = typography;
const { critical } = colors;

interface MessageInputBoxProps {
  id: string;
  isUploadingAttachment: boolean;
  isDisabled: boolean;
  members: Member[];
  onSendMessage: (
    message: string,
    attachmentFiles?: File[],
    mentionedUsers?: string[],
    onMessageSent?: () => void,
  ) => void;
  onRemoveFile?: () => void;
}

const idPrefix = 'Message';
const idPostfix = 'InputBox';

const ENTER_KEY = 'Enter';
const DEFAULT_VALUE = '';

const defaultMentionStyle = {
  backgroundColor: '#004C494D',
  borderRadius: '2px',
  paddingRight: '1px',
};

const MessageInputBox: FC<MessageInputBoxProps> = ({
  id,
  isDisabled,
  isUploadingAttachment,
  members,
  onRemoveFile,
  onSendMessage,
}) => {
  const [value, setValue] = useState<string>(DEFAULT_VALUE);
  const [mentionedUsers, setMentionedUsers] = useState<MentionsBoxUser[]>([]);

  const [fileInputError, setFileInputError] = useState<boolean>(false);
  const showFileInputErrorRef = useRef<number>();

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { files, setFiles, removeFile, clearAllFiles } = useFileLoader({
    inputRef,
  });

  const { track } = useAnalytics();

  const formatInputValue = (messageToSend: string): string => {
    const isEmptyMessage =
      (messageToSend ?? '').trim().replaceAll(TEXT_NEW_LINE, '') === '';
    let outputValue = (messageToSend ?? '').trim();
    if (!isEmptyMessage) {
      for (const mentionedUser of mentionedUsers) {
        outputValue = outputValue.replace(`(${mentionedUser.id})`, '');
      }
    }
    return isEmptyMessage ? null : outputValue;
  };

  const handleRemoveFile = useCallback(
    (index: number) => {
      removeFile(index);
      onRemoveFile?.();
      track('Chat Attachment Removed');
    },
    [files],
  );

  const handleSendMessage = useCallback(() => {
    const message = (value ?? '').trim();
    const messageToSend = message !== DEFAULT_VALUE ? message : null;

    onSendMessage(
      formatInputValue(messageToSend),
      files,
      (mentionedUsers ?? []).map((x) => x.id),
      () => {
        if (isChatAttachmentsEnabled && files.length) {
          clearAllFiles();
        }
      },
    );
    setValue(DEFAULT_VALUE);
  }, [value, files]);

  const isTextInputDisabled = isDisabled || isUploadingAttachment;
  const isAttachmentsButtonDisabled = isTextInputDisabled || files.length > 0;
  const isSendButtonDisabled =
    isTextInputDisabled ||
    (value.trim() === '' && isChatAttachmentsEnabled && files.length === 0);

  const users = (members ?? [])
    .filter(
      (member) =>
        (member.metaData as MemberMetaData)?.userType?.toLowerCase() ===
        USER_ROLES.PROVIDER.toLowerCase(),
    )
    .map((member) => ({
      display: member?.nickname,
      id: member?.userId,
      metaData: member?.metaData,
    }));

  const showFileInputError = () => {
    setFileInputError(true);
    if (showFileInputErrorRef?.current)
      clearTimeout(showFileInputErrorRef.current);
    showFileInputErrorRef.current = setTimeout(
      () => setFileInputError(false),
      15000,
    );
  };

  const onInputChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileInputError(false);
    const file = e?.currentTarget?.files[0];
    if (
      isAllowedFileType(
        getFileExtension(file.name),
        CHAT_ACCEPTED_FILE_EXTENSION,
      )
    ) {
      setFiles(e, 'a');
      track('Chat Attachment Added', {
        'File Extension': getFileExtension(file.name),
      });
    } else showFileInputError();
  };

  return (
    <ChakraBox ref={containerRef}>
      {fileInputError && (
        <ChakraFlex marginTop="8px" marginX="24px">
          <Alert fill={critical.base} height="18px" width="18px" />
          <ChakraText
            {...paragraph}
            color="critical.base"
            id={`${idPrefix}-container__attachment_error__${id}__${idPostfix}`}
            marginLeft="8px"
          >
            Sorry, we can't accept that filetype. We accept JPG, JPEG, PNG, PDF,
            DOC, and DOCX.
          </ChakraText>
        </ChakraFlex>
      )}
      <ChakraFlex
        alignItems="center"
        direction="column"
        id={`${idPrefix}-container__${id}__${idPostfix}`}
        justifyContent="center"
        minHeight="60px"
        padding="16px 24px"
        width="100%"
      >
        <ChakraFlex gridGap="10px" width="100%">
          {isChatAttachmentsEnabled && (
            <AttachmentsButton
              isDisabled={isAttachmentsButtonDisabled}
              onInputChangeEvent={onInputChangeEvent}
            />
          )}

          <ChakraFlex
            id={`${idPrefix}-input-wrapper__${id}__${idPostfix}`}
            width="100%"
          >
            <MentionsInput
              allowSuggestionsAboveCursor
              autoFocus
              className="mentions-input"
              disabled={isTextInputDisabled}
              forceSuggestionsAboveCursor
              id={`mentions-input_${id}__box`}
              onChange={(event, newValue, newPlainTextValue, mentions) => {
                if (isDisabled) {
                  event.preventDefault();
                } else {
                  setValue(newValue);
                  setMentionedUsers(Array.from(mentions));
                }
              }}
              onKeyDown={(e) => {
                if (e.key === ENTER_KEY && !e.shiftKey && !e.ctrlKey) {
                  handleSendMessage();
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              placeholder={
                isDisabled ? 'Messaging is unavailable' : 'Type your message'
              }
              style={style}
              value={value}
            >
              <Mention
                appendSpaceOnAdd
                className="mention-indicator"
                data={users}
                displayTransform={(id, display) => `@${display}`}
                markup="@__display__(__id__)"
                renderSuggestion={(data) => (
                  <Participant
                    id={id}
                    primaryLabel={data?.display}
                    secondaryLabel={
                      data.metaData?.providerType || data.metaData?.userType
                    }
                    userId={data?.userId}
                  />
                )}
                style={defaultMentionStyle}
                trigger="@"
              />
            </MentionsInput>
          </ChakraFlex>
          <ChakraBox
            as="button"
            disabled={isSendButtonDisabled}
            onClick={handleSendMessage}
          >
            <Icon
              color={
                isSendButtonDisabled ? colors.black[30] : colors.primary[100]
              }
              name={FAIconName.paperplaneRegular}
              size="lg"
            />
          </ChakraBox>
        </ChakraFlex>
        {isChatAttachmentsEnabled && files.length > 0 && (
          <AttachmentsPreview
            files={files}
            isUploading={isUploadingAttachment}
            onRemoveFile={handleRemoveFile}
          />
        )}
      </ChakraFlex>
    </ChakraBox>
  );
};

MessageInputBox.displayName = 'MessageInputBox';
export default MessageInputBox;
