import { FC, ReactNode } from 'react';

import {
  Button,
  ChakraBox,
  ChakraFlex,
  ChakraText,
  Close,
  EquipUIFireflyV1Theme,
  MenuOption,
} from '@equip.health/ui';
import { useHistory } from 'react-router-dom';

import Navigation from './Navigation';
import SurveyHeader from './SurveyHeader';
import SurveyResults from './SurveyResults';
import { LAYOUT_DIMENSIONS } from '~/lib/constants/survey.constants';
import { getPageTitle } from '~/lib/util/survey.util';
import { isMobileDevice } from '~/lib/utils';

const { black } = EquipUIFireflyV1Theme.colors;
const { h4 } = EquipUIFireflyV1Theme.typography;

const TOP_LEVEL_ID = 'survey-layout';

export type LayoutProps = {
  children: ReactNode;
  currentPage?: number;
  data?: any;
  definition: SurveyDefinition;
  isFamilyTask: boolean;
  isLoading: boolean;
  isReadOnly?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  onSkip?: (name: string) => void;
  onSubmit?: () => void;
  pages?: MenuOption[];
  saveError: string;
  surveyCompletionDate: string;
  title: string;
};

const Layout: FC<LayoutProps> = ({
  children,
  currentPage,
  data,
  definition,
  isFamilyTask,
  isLoading,
  isReadOnly = false,
  onNext,
  onPrevious,
  onSkip,
  onSubmit,
  pages,
  saveError,
  surveyCompletionDate,
  title,
}: LayoutProps) => {
  const history = useHistory();
  return (
    <>
      {isFamilyTask ? (
        <ChakraFlex
          alignItems="center"
          height={`${LAYOUT_DIMENSIONS.top}px`}
          justifyContent="space-between"
          paddingX="32px"
          width="100%"
        >
          <ChakraText {...h4}>{title}</ChakraText>
          <ChakraBox
            cursor="pointer"
            onClick={() => {
              history.goBack();
            }}
          >
            <Close fill={black[90]} height="20px" width="20px" />
          </ChakraBox>
        </ChakraFlex>
      ) : (
        <SurveyHeader
          currentPage={currentPage}
          height={`${LAYOUT_DIMENSIONS.top}px`}
          isReadOnly={isReadOnly}
          pagesCount={pages?.length}
          title={title}
        />
      )}
      <ChakraBox
        background="white"
        borderRadius={0}
        height={`calc(100vh - ${LAYOUT_DIMENSIONS.top}px)`}
        overflow="scroll"
        position="fixed"
        top={`${LAYOUT_DIMENSIONS.top}px`}
        width="100%"
      >
        {isReadOnly ? (
          <SurveyResults
            data={data}
            definition={definition}
            surveyCompletionDate={surveyCompletionDate}
            title={title}
          />
        ) : (
          <>
            <ChakraBox
              overflow="hidden"
              width={{ base: 0, lg: `${LAYOUT_DIMENSIONS.left}px` }}
            >
              <ChakraFlex direction="column" marginBottom="40px" width="100%">
                {pages.map(({ value }: MenuOption, i: number) => (
                  <Button
                    boxShadow="none"
                    colorScheme={currentPage === i ? 'teal' : 'gray'}
                    height="auto"
                    id={`${TOP_LEVEL_ID}__side-nav__${value}`}
                    key={`${TOP_LEVEL_ID}__side-nav__${value}`}
                    margin="12px 40px 0 80px"
                    onClick={() => onSkip(value)}
                    padding="16px"
                    variant={currentPage === i ? 'solid' : 'outline'}
                    wrapText
                  >
                    {getPageTitle(definition, value)}
                  </Button>
                ))}
              </ChakraFlex>
            </ChakraBox>
            <ChakraBox
              height={`calc(100vh - ${
                LAYOUT_DIMENSIONS.bottom +
                LAYOUT_DIMENSIONS.top +
                (isMobileDevice() && !isFamilyTask
                  ? LAYOUT_DIMENSIONS.mobileBuffer
                  : 0)
              }px)`}
              left={{ base: 0, lg: `${LAYOUT_DIMENSIONS.left}px` }}
              overflow="scroll"
              padding="8px"
              position="fixed"
              top={`${LAYOUT_DIMENSIONS.top}px`}
              width={{
                base: '100%',
                lg: `calc(100vw - ${LAYOUT_DIMENSIONS.left}px)`,
              }}
            >
              {children}
            </ChakraBox>
            <Navigation
              currentPage={currentPage}
              isLoading={isLoading}
              left={`${LAYOUT_DIMENSIONS.left}px`}
              onNext={onNext}
              onPrevious={onPrevious}
              onSkip={onSkip}
              onSubmit={onSubmit}
              pages={pages}
              saveError={saveError}
            />
          </>
        )}
      </ChakraBox>
    </>
  );
};

export default Layout;
