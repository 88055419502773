import { FC, ReactNode } from 'react';

import { ChakraBox } from '@equip.health/ui';

interface PageProps {
  children: ReactNode;
}

const Page: FC<PageProps> = ({ children }) => (
  <ChakraBox
    bottom={0}
    maxWidth="calc(100vw)"
    position="absolute"
    top={0}
    width="100%"
  >
    {children}
  </ChakraBox>
);

export default Page;
