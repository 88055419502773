import { FC, ReactNode, useMemo } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  FAIconName,
  FireflyIconButton,
  Text,
  format,
  formatCellPhoneNumber,
  getAgeFromDob,
  getDateFromString,
} from '@equip.health/ui';

import { useProfilePageContext } from '~/lib/context/ProfilePageContext';
import {
  PROFILE_SECTION_TO_FIELD_MAPPER,
  ProfileSection,
} from '~/lib/hooks/useProfilePage';
import { formatGenderPronoun } from '~/lib/util/profile.util';
import {
  generateAddressText,
  getPreferredFullName,
  getRelationshipToPatients,
  joinLanguagesList,
  joinPatientSupportList,
} from '~/lib/utils';

import NonEditablePopoverToolTip from './NonEditablePopoverToolTip';
import ProfileEditMode from './ProfileEditMode';

const NOTIFICATION_SMS_TEXT = 'SMS';
const NOTIFICATION_EMAIL_TEXT = 'Email';

interface TitleAndValueProps {
  title: string;
}

interface TitleWrapperProps {
  sectionType: ProfileSection;
  isEditable?: boolean;
  title: string;
  children: ReactNode;
}

const TitleWrapper: FC<TitleWrapperProps> = ({
  sectionType,
  isEditable = true,
  title,
  children,
}) => {
  const { editMode, setEditMode } = useProfilePageContext();

  const isEditingSection = editMode === sectionType;

  return (
    <ChakraFlex
      _last={{ borderBottom: 'none' }}
      alignItems="center"
      borderBottom="1px solid rgba(0, 0, 0, 0.1)"
      justifyContent="space-between"
      opacity={editMode !== null && editMode !== sectionType ? 0.3 : 1}
      width="100%"
    >
      <ChakraBox flex="1" position="relative">
        <ChakraFlex
          alignItems="center"
          gridGap="4px"
          marginBottom={isEditingSection ? '32px' : '16px'}
          marginTop="34px"
        >
          <Text
            color="neutral.secondary"
            id={`${title}__text`}
            variant={isEditingSection ? 'h5' : 'caption'}
          >
            {title}
          </Text>
          {!isEditable && <NonEditablePopoverToolTip />}
        </ChakraFlex>
        {children}
      </ChakraBox>
      {editMode !== sectionType && isEditable && (
        <FireflyIconButton
          icon={FAIconName.penRegular}
          id="profile-info__edit"
          isDisabled={editMode !== null && editMode !== sectionType}
          onClick={() => {
            if (editMode !== null) return;
            setEditMode(sectionType);
          }}
          variant="secondary"
        />
      )}
    </ChakraFlex>
  );
};

const formatDOB = (dateOfBirth: string): string => {
  const age = getAgeFromDob(dateOfBirth);

  if (dateOfBirth) {
    return `${format(getDateFromString(dateOfBirth))} (${age}) years old`;
  }

  return '-';
};

const getProfileDetailString = (
  type: ProfileSection,
  profileDetail: UserProfile,
  isUserProfile: boolean,
): string => {
  switch (type) {
    case ProfileSection.BASIC_NAME:
      return getPreferredFullName(
        profileDetail?.chosenName,
        profileDetail?.firstName,
        profileDetail?.lastName,
      );
    case ProfileSection.BASIC_DOB:
      return formatDOB(profileDetail?.dateOfBirth);
    case ProfileSection.BASIC_GENDER:
      return formatGenderPronoun(
        profileDetail?.gender,
        profileDetail?.pronouns,
      );
    case ProfileSection.BASIC_RELATIONSHIP_TO_PATIENT:
      return isUserProfile
        ? getRelationshipToPatients(profileDetail)
        : profileDetail?.relationToPatient;
    case ProfileSection.BASIC_SUPPORTED_BY:
      return joinPatientSupportList(profileDetail?.supportedBy);
    case ProfileSection.BASIC_LANGUAGE_SPOKEN:
      return joinLanguagesList(profileDetail?.languages ?? []);
    case ProfileSection.CONTACT_EMAIL:
      return profileDetail?.email;
    case ProfileSection.CONTACT_PHONE_NUMBER:
      return formatCellPhoneNumber(profileDetail?.cellPhone ?? '');
    case ProfileSection.CONTACT_PREFERENCES:
      return (profileDetail?.contactPreferences ?? []).join(', ');
    case ProfileSection.CONTACT_ADDRESS:
      return generateAddressText(
        profileDetail?.address,
        profileDetail?.timezone,
      );
    case ProfileSection.CONTACT_TIMEZONE:
      return profileDetail?.timezone;
    case ProfileSection.NOTIFICATION_SETTINGS:
      if (profileDetail?.preferences?.notifications) {
        const { receiveEmails, receiveSms } =
          profileDetail?.preferences?.notifications;
        if (receiveEmails && receiveSms) return 'Both email and SMS';
        if (!receiveEmails && !receiveSms) return 'All notifications off';
        return receiveEmails ? NOTIFICATION_EMAIL_TEXT : NOTIFICATION_SMS_TEXT;
      }
      return '';
    default:
      return '';
  }
};

interface ProfileTitleAndValueProps extends TitleAndValueProps {
  sectionType: ProfileSection;
  title: string;
}

export const ProfileTitleAndValue: FC<ProfileTitleAndValueProps> = ({
  sectionType,
  title,
}) => {
  const {
    activeProfile,
    editMode,
    isUserProfile,
    profileEditViewPermission: permission,
    setEditMode,
  } = useProfilePageContext();

  const displayString = useMemo<string>(
    () => getProfileDetailString(sectionType, activeProfile, isUserProfile),
    [sectionType, activeProfile],
  );

  const isEditable = useMemo<boolean>(
    () =>
      (PROFILE_SECTION_TO_FIELD_MAPPER[sectionType] ?? []).some(
        (type) => permission[type]?.isEditable,
      ),
    [permission, sectionType],
  );

  const isViewable = useMemo<boolean>(
    () =>
      (PROFILE_SECTION_TO_FIELD_MAPPER[sectionType] ?? []).some(
        (type) => permission[type]?.isViewable,
      ),
    [permission, sectionType],
  );

  if (!isViewable) return null;

  return (
    <TitleWrapper
      isEditable={isEditable}
      sectionType={sectionType}
      title={title}
    >
      {isEditable && editMode === sectionType ? (
        <ProfileEditMode
          fields={PROFILE_SECTION_TO_FIELD_MAPPER[sectionType] ?? []}
          handleOnDismiss={() => setEditMode(null)}
        />
      ) : (
        <Text
          color="neutral.secondary"
          marginBottom="24px"
          style={{ wordBreak: 'break-all' }}
          whiteSpace="pre-line"
        >
          {displayString || '-'}
        </Text>
      )}
    </TitleWrapper>
  );
};

export default ProfileTitleAndValue;
