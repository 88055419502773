import { FC, ReactNode } from 'react';

import { ChakraBox, ChakraFlex, ChakraText } from '@equip.health/ui';

interface ReviewReferralInformationProps {
  children: ReactNode;
  fontFamily?: string;
  fontColor?: string;
  fontSize?: string | number;
  fontWeight?: string | number;
  letterSpacing?: string | number;
  lineHeight?: string | number;
  marginTop?: string;
  title?: string;
}

const ReviewReferralInformation: FC<ReviewReferralInformationProps> = ({
  children,
  fontColor,
  fontFamily,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  marginTop,
  title,
}) => {
  return (
    <ChakraBox>
      <ChakraText
        color={fontColor}
        fontFamily={fontFamily}
        fontSize={fontSize ?? '10px'}
        fontWeight={fontWeight}
        id={`${title}__text`}
        letterSpacing={letterSpacing}
        lineHeight={lineHeight}
        marginTop={marginTop}
      >
        {title}
      </ChakraText>
      <ChakraFlex flexDirection="column" marginTop="16px">
        {children}
      </ChakraFlex>
    </ChakraBox>
  );
};

export default ReviewReferralInformation;
