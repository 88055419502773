import { FC, useEffect, useState } from 'react';

import {
  ChakraFlex,
  Pagination,
  RowObject,
  Table,
  useBreakpointValue,
} from '@equip.health/ui';

import LineChart from '../LineChart';
import {
  EditIcon,
  TableLabel,
  desktopColumns,
  loadingRows,
  mobileColumns,
} from '~/components/vitals/history/TableComponents';
import EmptyWeightView from '~/components/vitals/overview/EmptyWeightView';
import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { getWeightObservationOptions } from '~/lib/constants/vitals.contants';
import { useApi } from '~/lib/hooks';
import { PatientObservationsResponse } from '~/lib/types/vitals';
import { AppMonitor } from '~/lib/util/appMonitor';
import {
  getObservationDate,
  getObservationLoggerName,
  getObservationTime,
  getWeightObservationValue,
} from '~/lib/util/vitals.util';

interface ObservationRowObject {
  date: string;
  time: string;
  loggedBy: string;
  weight: string;
}

interface VitalsTableProps {
  patientId: string;
}

const { getPatientVitals: getPatientVitalsUrl } = urlConstants.clinicalData;

const VitalsTable: FC<VitalsTableProps> = ({ patientId }) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [shouldShowPagination, setShouldShowPagination] =
    useState<boolean>(false);

  const { sendRequest: fetchWeightObservations, loading: isLoading } =
    useApi<PatientObservationsResponse>();

  useEffect(() => {
    if (patientId) {
      setPageNumber(1);
      fetchObservations(1);
      setShouldShowPagination(false);
    }
  }, [patientId]);

  const fetchObservations = (page: number) => {
    fetchWeightObservations({
      command: ApiCommand.GET,
      url: getPatientVitalsUrl(patientId),
      options: getWeightObservationOptions(page),
      callback: (response, statusCode, error) => {
        if (statusCode === 200 && response?.data) {
          setShouldShowPagination(true);
          setObservationsData(response);
        } else {
          AppMonitor.captureException(error);
        }
      },
    });
  };

  const [observationsData, setObservationsData] =
    useState<PatientObservationsResponse>();

  const rows: RowObject<ObservationRowObject>[] = observationsData?.data.map(
    (o) => {
      return {
        key: o.observation.externalId,
        value: o.observation.externalId,
        object: {
          date: <TableLabel>{getObservationDate(o, 'table')}</TableLabel>,
          time: <TableLabel>{getObservationTime(o)}</TableLabel>,
          loggedBy: <TableLabel>{getObservationLoggerName(o)}</TableLabel>,
          weight: <TableLabel>{getWeightObservationValue(o)}</TableLabel>,
        },
      };
    },
  );

  const handleEditObservation = (observationId: string) => {
    // Implement edit observation
  };

  const columns = useBreakpointValue({
    base: mobileColumns,
    md: desktopColumns,
  });

  const actionButtons = [
    {
      handleEditObservation,
      key: 'handleEditObservation',
      node: EditIcon,
    },
  ];

  const isEditObservationEnabled = false;
  const shouldShowEditObservationButton =
    isEditObservationEnabled && !isLoading;
  const pageCount = Math.ceil(
    observationsData?.count / observationsData?.pageSize,
  );

  const handlePageSelect = (page: number): void => {
    setPageNumber(page);
    fetchObservations(page);
  };

  const shouldShowEmptyWeightView =
    observationsData?.data.length === 0 && !isLoading;

  return (
    <ChakraFlex flexDirection="column" gridGap="24px" width="100%">
      {shouldShowEmptyWeightView ? (
        <ChakraFlex padding="64px 24px">
          <EmptyWeightView />
        </ChakraFlex>
      ) : (
        <>
          {observationsData && (
            <LineChart observations={observationsData?.data} />
          )}
          <Table
            actionButtons={shouldShowEditObservationButton ? actionButtons : []}
            columns={columns ?? []}
            rows={isLoading ? loadingRows : rows}
            variant="simple"
          />
          {shouldShowPagination && (
            <Pagination
              current={pageNumber}
              id="USER_TABLE_IDENTIFIER"
              onPageSelect={handlePageSelect}
              total={pageCount}
            />
          )}
        </>
      )}
    </ChakraFlex>
  );
};

export default VitalsTable;
