import { FC } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraHeading,
  ChakraImage,
  ChakraText,
  EquipUITheme,
  Text,
} from '@equip.health/ui';
import { Link } from 'react-router-dom';

import Facebook from '~/assets/svg/Facebook.svg';
import Insta from '~/assets/svg/Insta.svg';
import LinkedIn from '~/assets/svg/LinkedIn.svg';
import Logo from '~/assets/svg/Logo.svg';
import Twitter from '~/assets/svg/Twitter.svg';
import {
  FacebookLink,
  INQUIRY_REFERRAL_HORIZONTAL_MARGINS,
  InstagramLink,
  LinkedInLink,
  NavFooterLinks,
  TwitterLink,
} from '~/lib/constants';
import { VITE_MYEQUIP_APP_VERSION } from '~/lib/constants/env';
import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';
import { getAppVersion } from '~/lib/utils';

const { VITE_EQUIP_WEBSITE_ADDRESS } = import.meta.env;

const { paragraph } = EquipUITheme.typography;
const TOP_LEVEL_ID = 'footer';

const Footer: FC = () => {
  const isMobileDevice = useMobileBreakpoint();
  return (
    <ChakraFlex
      display={{ md: 'flex' }}
      id={TOP_LEVEL_ID}
      marginTop="150px"
      paddingBottom="90px"
      paddingLeft={INQUIRY_REFERRAL_HORIZONTAL_MARGINS}
      paddingRight={INQUIRY_REFERRAL_HORIZONTAL_MARGINS}
    >
      <ChakraBox width={['100%', '50%', '25%', '55%']}>
        <ChakraBox>
          <ChakraImage
            alt="Equip_Logo"
            id={`${TOP_LEVEL_ID}__equip-logo`}
            src={Logo}
          />
        </ChakraBox>
        <ChakraFlex marginTop="16px">
          <a href={InstagramLink} rel="noreferrer" target="_blank">
            <ChakraImage
              alt="Instagram"
              id={`${TOP_LEVEL_ID}__instagram-logo`}
              paddingRight="5px"
              src={Insta}
            />
          </a>
          <a href={TwitterLink} rel="noreferrer" target="_blank">
            <ChakraImage
              alt="Twitter"
              id={`${TOP_LEVEL_ID}__twitter-logo`}
              paddingLeft="5px"
              paddingRight="5px"
              src={Twitter}
            />
          </a>
          <a href={FacebookLink} rel="noreferrer" target="_blank">
            <ChakraImage
              alt="Facebook"
              id={`${TOP_LEVEL_ID}__facebook-logo`}
              paddingLeft="5px"
              paddingRight="5px"
              src={Facebook}
            />
          </a>
          <a href={LinkedInLink} rel="noreferrer" target="_blank">
            <ChakraImage
              alt="LinkedIn"
              id={`${TOP_LEVEL_ID}__linkedin-logo`}
              paddingLeft="5px"
              paddingRight="5px"
              src={LinkedIn}
            />
          </a>
        </ChakraFlex>
        <ChakraFlex
          marginTop="16px"
          gap="20px"
          flexDirection={isMobileDevice ? 'column' : 'row'}
        >
          <Text
            variant="caption"
            color="neutral.secondary"
            id={`${TOP_LEVEL_ID}__description`}
          >
            @ 2024 Copyright. Equip Health, Inc.
          </Text>
          {VITE_MYEQUIP_APP_VERSION && (
            <Text
              variant="caption"
              color="neutral.secondary"
              id={`${TOP_LEVEL_ID}__version`}
            >
              {getAppVersion()}
            </Text>
          )}
        </ChakraFlex>
      </ChakraBox>
      {Object.keys(NavFooterLinks).map((key) => (
        <ChakraBox
          key={key}
          paddingTop={{ base: '30px', md: '20px' }}
          width={['100%', '50%', '25%', '15%']}
        >
          <ChakraHeading
            as="h5"
            color="mono.100"
            fontSize="16px"
            id={`${TOP_LEVEL_ID}__heading-${key}`}
            lineHeight="21.6px"
            marginBottom="10px"
          >
            {key}
          </ChakraHeading>
          {NavFooterLinks[key].map((item) => (
            <ChakraText
              {...paragraph}
              color="mono.100"
              id={`${TOP_LEVEL_ID}__footer-links-${item.path}`}
              key={item.path}
            >
              <Link
                rel="noreferrer"
                target="_blank"
                to={{ pathname: `${VITE_EQUIP_WEBSITE_ADDRESS}${item.path}` }}
              >
                {item.value}
              </Link>
            </ChakraText>
          ))}
        </ChakraBox>
      ))}
    </ChakraFlex>
  );
};

export default Footer;
