import { FC, useEffect, useMemo } from 'react';

import {
  AngleDown,
  AutoComplete,
  AutoCompleteOption,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

import { autoCompleteProps } from '~/lib/constants/inquiry.constants';
import {
  Enumerations,
  useEnumerationContext,
} from '~/lib/context/EnumerationContext';

type PayorSelectProps = {
  label?: string;
  onSelect: (
    label: string,
    id: string,
    isInNetwork: boolean,
    isUserSelected?: boolean,
  ) => void;
  selectedPayor: string;
};

const { teal } = EquipUIFireflyV1Theme.colors;

const PayorSelect: FC<PayorSelectProps> = ({
  label,
  onSelect,
  selectedPayor,
}) => {
  const { fetchEnumerations, payors } = useEnumerationContext();

  useEffect(() => fetchEnumerations([Enumerations.PAYORS]), []);

  const options = useMemo<AutoCompleteOption[]>(() => {
    const activePayors = (payors ?? []).filter(
      ({ isActive }: Payor): boolean => isActive,
    );
    return (activePayors ?? []).map(
      ({ label: payorName, externalId }: Payor) => ({
        primaryText: payorName,
        value: externalId,
      }),
    );
  }, [payors?.length]);

  const handlePayorSelect = (option: AutoCompleteOption): void => {
    const { primaryText, value } = option;
    const selectedPayor = payors?.find(
      ({ externalId }: Payor): boolean => externalId === value,
    );

    onSelect(primaryText, value, selectedPayor?.isInNetwork);
  };

  return (
    <AutoComplete
      {...autoCompleteProps}
      endIcon={<AngleDown fill={teal[100]} height="15px" width="15px" />}
      hideAvatar
      id="payor"
      isTextInput
      marginBottom="16px"
      onSelect={handlePayorSelect}
      options={options}
      placeholder="Select..."
      selected={
        selectedPayor
          ? [options.find(({ primaryText }) => primaryText === selectedPayor)]
          : []
      }
      title={label}
      toggleListOnFocus
    />
  );
};

export default PayorSelect;
