import { FC, ReactElement, createContext, useContext, useState } from 'react';

import { GroupChannel } from '@sendbird/chat/groupChannel';

import { GroupChannelMetadata } from '~/lib/types/chat';

type ChatChannelContextState = {
  patientId: string;
  setSelectedChannel: (selectedChannel: GroupChannel) => void;
  setPatientId: (patientId: string) => void;
};

const useChatChannel = (): ChatChannelContextState => {
  const [patientId, setPatientId] = useState<string>();

  const setSelectedChannel = (selectedChannel: GroupChannel) => {
    setPatientId(
      (selectedChannel.cachedMetaData as GroupChannelMetadata).patientId,
    );
  };

  const setSelectedPatientId = (patientId: string) => {
    setPatientId(patientId);
  };

  return {
    patientId,
    setSelectedChannel,
    setPatientId: setSelectedPatientId,
  };
};

const ChatChannelContext = createContext<ChatChannelContextState | undefined>(
  undefined,
);

interface ChatChannelContextProviderProps {
  children: ReactElement | ReactElement[];
}

const ChatChannelContextProvider: FC<ChatChannelContextProviderProps> = ({
  children,
}: ChatChannelContextProviderProps) => {
  const value = useChatChannel();

  return (
    <ChatChannelContext.Provider value={value}>
      {children}
    </ChatChannelContext.Provider>
  );
};

const useChatChannelContext = (): ChatChannelContextState => {
  const context = useContext(ChatChannelContext);

  if (context === undefined) {
    throw new Error(
      'useChatChannelContext must be used within a ChatChannelContextProvider',
    );
  }

  return context;
};

export { ChatChannelContextProvider, useChatChannelContext };
