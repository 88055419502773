import { useEffect, useState } from 'react';

import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import {
  useRegisterForGroupClassMutation,
  useUnregisterForGroupClassMutation,
} from '~/store/services/schedule';

export enum GroupClassRegistrationChangeSource {
  overview = 'Overview',
  detail = 'Detail',
}

type GroupClassRegistrationCallback = (
  source: GroupClassRegistrationChangeSource,
  onCompletion: (success: boolean) => void,
) => void;

export type UseGroupClassRegistrationState = {
  isRegistered: boolean;
  registerForGroupClass?: GroupClassRegistrationCallback;
  unregisterForGroupClass?: GroupClassRegistrationCallback;
  isLoading: boolean;
};

const useGroupClassRegistration = (
  groupClassExternalId: string,
  isRegisteredInitially: boolean,
  patientExternalId?: string,
): UseGroupClassRegistrationState => {
  const [isRegistered, setIsRegistered] = useState(isRegisteredInitially);
  const { track } = useAnalytics();

  const [
    registerForGroupClassTrigger,
    { isLoading: isRegisterForGroupClassLoading },
  ] = useRegisterForGroupClassMutation();
  const [
    unregisterForGroupClassTrigger,
    { isLoading: isUnregisterForGroupClassLoading },
  ] = useUnregisterForGroupClassMutation();

  const isLoading =
    isRegisterForGroupClassLoading || isUnregisterForGroupClassLoading;
  useEffect(() => {
    setIsRegistered(isRegisteredInitially);
  }, [isRegisteredInitially]);

  const registerForGroupClass: UseGroupClassRegistrationState['registerForGroupClass'] =
    async (source, onCompletion) => {
      track('Group Class Registration Changed', {
        'Group Class ID': groupClassExternalId,
        'Is Registered': true,
        Source: source,
      });

      try {
        const response = await registerForGroupClassTrigger({
          groupClassExternalId,
          patientExternalId,
        }).unwrap();
        if (response.status === 'OK') {
          onCompletion?.(true);
          setIsRegistered(true);
        } else {
          onCompletion?.(false);
        }
      } catch (error) {
        onCompletion?.(false);
      }
    };

  const unregisterForGroupClass: UseGroupClassRegistrationState['unregisterForGroupClass'] =
    async (source, onCompletion) => {
      track('Group Class Registration Changed', {
        'Group Class ID': groupClassExternalId,
        'Is Registered': false,
        Source: source,
      });

      try {
        const response = await unregisterForGroupClassTrigger({
          groupClassExternalId,
          patientExternalId,
        }).unwrap();
        if (response.status === 'OK') {
          onCompletion?.(true);
          setIsRegistered(false);
        } else {
          onCompletion?.(false);
        }
      } catch (error) {
        onCompletion?.(false);
      }
    };

  return {
    isLoading,
    isRegistered,
    registerForGroupClass,
    unregisterForGroupClass,
  };
};

export default useGroupClassRegistration;
