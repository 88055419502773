import { FC } from 'react';

import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';

import { BookingErrorView } from '~/components/schedule/BookAppointment/base/AvailabilityErrorViews';
import BookAppointmentStepContainer, {
  ListContainer,
} from '~/components/schedule/BookAppointment/base/StepContainer';
import AppointmentOverviewCard, {
  AttendeeItemProps,
} from '~/components/schedule/BookAppointment/cards/AppointmentOverviewCard';
import { ApiCommand } from '~/lib/Api';
import {
  AppointmentStatus,
  BOOK_APPOINTMENT_STEP,
} from '~/lib/constants/bookAppointments';
import urlConstants from '~/lib/constants/url.constants';
import { useBookAppointmentContext } from '~/lib/context/BookAppointmentContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useApi } from '~/lib/hooks';
import {
  Recurrence,
  getRecurrenceDisplayString,
  transformDateFromISOtoLocal,
} from '~/lib/util/schedule.util';
import {
  getRelativeDayFromDate,
  mapAttendeeToListItem,
} from '~/lib/util/schedule/bookAppointment.util';

const topLevelId = 'book-appointment__overview';

const { saveAppointment: saveAppointmentUrl } = urlConstants.schedule;

const BookingOverviewStep: FC = () => {
  const {
    isPatientAttending,
    patientId,
    providerParticipants,
    requiredAttendees,
    selectedAppointmentRecurrence,
    selectedAppointmentType,
    selectedAppointmentTypeId,
    selectedOptionalAttendees,
    selectedTimeSlot,
    setNextStep,
    supportAttendes,
  } = useBookAppointmentContext();

  const {
    userProfile: { externalId: currentUserId },
  } = useUserProfileContext();

  const handleBackwardNavigation = () => {
    setNextStep(BOOK_APPOINTMENT_STEP.availability);
  };

  const handleConfirmBooking = () => {
    setNextStep(BOOK_APPOINTMENT_STEP.confirmation);
  };

  const {
    error,
    loading: isLoading,
    sendRequest: saveAppointmentRequest,
  } = useApi<SaveAppointmentResponse>();

  if (isNil(selectedAppointmentType)) {
    return null;
  }

  const attendeeList: AttendeeItemProps[] = [
    {
      id: selectedAppointmentType.providerExternalId,
      name: selectedAppointmentType.providerName,
      label: selectedAppointmentType.providerType,
      userType: 'provider',
    },
    ...requiredAttendees.map((attendee) =>
      mapAttendeeToListItem(attendee, currentUserId),
    ),
    ...selectedOptionalAttendees.map((attendee) =>
      mapAttendeeToListItem(attendee, currentUserId),
    ),
  ];

  const relativeStartDate = getRelativeDayFromDate(
    selectedTimeSlot.startTime,
    'short',
  );

  const startAppointmentTime = transformDateFromISOtoLocal(
    selectedTimeSlot.startTime,
  );

  const endAppointmentTime = transformDateFromISOtoLocal(
    selectedTimeSlot.endTime,
  );

  const recurrence = getRecurrenceDisplayString(
    selectedTimeSlot.startTime,
    selectedAppointmentRecurrence,
  );

  const saveAppointment = () => {
    const selectedProvider = selectedAppointmentType;
    const appointmentType = selectedProvider.appointmentTypes.find(
      (e) => e.appointmentTypeExtId === selectedAppointmentTypeId,
    );

    const { appointmentTypeLabel } = appointmentType;
    const hostExternalId = selectedAppointmentType.providerExternalId;

    if (isNil(appointmentTypeLabel) || isNil(hostExternalId)) {
      return;
    }

    const options: ConfirmAppointmentPayload = {
      appointmentEndDateTime: selectedTimeSlot.endTime,
      appointmentStartDateTime: selectedTimeSlot.startTime,
      appointmentType: appointmentTypeLabel,
      hostExternalId,
      isPatientAttending,
      patientExternalId: patientId,
      providerAttendeeExternalIds: providerParticipants.map(
        (e) => e.externalId,
      ),
      status: AppointmentStatus.SCHEDULED,
      supportAttendeeExternalIds: supportAttendes.map((e) => e.externalId),
      timeZoneId: DateTime.now().zoneName,
    };

    if (selectedAppointmentRecurrence !== Recurrence.DOES_NOT_REPEAT) {
      options.recurrenceFrequency = selectedAppointmentRecurrence;
    }

    saveAppointmentRequest({
      url: saveAppointmentUrl,
      command: ApiCommand.POST,
      options,
      callback: (response) => {
        if (response.data) {
          handleConfirmBooking();
        }
      },
    });
  };

  const isBookAppointmentError = !isNil(error);

  return (
    <BookAppointmentStepContainer
      isBackButtonVisible={!isLoading}
      isContinueButtonDisabled={isBookAppointmentError}
      isForwardButtonHidden={isBookAppointmentError}
      isLastStep
      isLoading={isLoading}
      isTitleHidden={isBookAppointmentError}
      onBackwardNavigation={handleBackwardNavigation}
      onForwardNavigation={saveAppointment}
      stepId={topLevelId}
      title="Please review the details of your appointment"
    >
      <ListContainer>
        {isBookAppointmentError ? (
          <BookingErrorView />
        ) : (
          <AppointmentOverviewCard
            appointmentTitle={selectedAppointmentType.providerType}
            attendeeList={attendeeList}
            date={relativeStartDate}
            id={`${topLevelId}__card`}
            recurrence={recurrence}
            time={`${startAppointmentTime} - ${endAppointmentTime}`}
          />
        )}
      </ListContainer>
    </BookAppointmentStepContainer>
  );
};

export default BookingOverviewStep;
