import { ChakraBox, ChakraText, EquipUIFireflyV1Theme } from '@equip.health/ui';
import { DateTime } from 'luxon';

import { ADMISSIONS_PHONE_NUMBER } from '~/lib/constants';
import { SchedulerStep } from '~/lib/constants/inquiry.constants';
import { formatSelectedSlotDateString } from '~/lib/util/schedule.util';

const { paragraph, paragraphSemibold } = EquipUIFireflyV1Theme.typography;

type HeaderSubtitleProps = {
  duration: number;
  startTime: string;
  step: SchedulerStep;
};

const HeaderSubtitle = ({ duration, startTime, step }: HeaderSubtitleProps) => {
  return (
    <ChakraBox {...paragraph} color="mono.70" textAlign="center">
      {step === SchedulerStep.THANK_YOU ? (
        <>
          <ChakraText as="span" marginRight="6px">
            We&apos;ll call you on
          </ChakraText>
          <ChakraText {...paragraphSemibold} as="span" marginRight="6px">
            {formatSelectedSlotDateString(DateTime.fromISO(startTime))}
          </ChakraText>
          <ChakraText as="span">{`for a ${duration}-minute conversation to learn more about treatment at Equip.`}</ChakraText>
          <br />
          <br />
          <ChakraText>
            We hope we can help you on your treatment journey.
          </ChakraText>
        </>
      ) : (
        <>
          <ChakraText>
            {`If you'd like to schedule a call, please choose a time on the right that works best for you. 
            These calls typically take about ${duration} minutes.`}
          </ChakraText>
          <br />
          <ChakraText>
            If you don't schedule a call, someone from our team will reach out
            within 3 business days.
          </ChakraText>
        </>
      )}
      <br />
      <ChakraBox
        backgroundColor="blue.info"
        borderRadius="10px"
        justifyContent="center"
        padding="12px 16px"
      >
        <ChakraText as="span" marginRight="6px">
          You can call our admissions team any time at
        </ChakraText>
        <ChakraText {...paragraphSemibold} as="span" color="blue.link">
          {ADMISSIONS_PHONE_NUMBER}
        </ChakraText>
      </ChakraBox>
    </ChakraBox>
  );
};

export default HeaderSubtitle;
