import { FAIconName, FireflyButton } from '@equip.health/ui';

interface ShowMoreButtonProps {
  id?: string;
  isExpanded?: boolean;
  onClick: () => void;
  title?: string;
}

const ShowMoreButton = ({
  id,
  isExpanded,
  onClick,
  title,
}: ShowMoreButtonProps) => {
  return (
    <FireflyButton
      id={id}
      onClick={onClick}
      rightIcon={
        isExpanded ? FAIconName.chevronUpRegular : FAIconName.chevronDownRegular
      }
      variant="tertiary"
    >
      {title || 'Show More'}
    </FireflyButton>
  );
};

export default ShowMoreButton;
