import { Recurrence } from '~/lib/util/schedule.util';

export enum BOOK_APPOINTMENT_STEP {
  patient = 'PATIENT',
  appointmentType = 'APPOINTMENT_TYPE',
  attendees = 'ATTENDEES',
  optionalAttendees = 'OPTIONAL_ATTENDEES',
  duration = 'DURATION',
  recurrence = 'RECURRENCE',
  availability = 'AVAILABILITY',
  overview = 'OVERVIEW',
  confirmation = 'CONFIRMATION',
}

export const appointmentRecurrenceOptions: AppointmentRecurrenceOption[] = [
  {
    name: 'One-time appointment',
    recurrence: Recurrence.DOES_NOT_REPEAT,
    id: 'one-time-appointment',
  },
  {
    name: 'Recurring weekly',
    recurrence: Recurrence.WEEKLY,
    tooltip: 'Please note, recurring appointments will be booked for 6 months',
    id: 'recurring-weekly',
  },
  {
    name: 'Recurring every other week',
    recurrence: Recurrence.BIWEEKLY,
    tooltip: 'Please note, recurring appointments will be booked for 6 months',
    id: 'recurring-biweekly',
  },
];

export enum AppointmentStatus {
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  SCHEDULED = 'scheduled',
}

export const AppointmentTypeGroup: Record<
  AppointmentTypeGroup,
  AppointmentTypeGroup
> = {
  DIETITIAN: 'DIETITIAN',
  DIETITIAN_INTAKE: 'DIETITIAN_INTAKE',
  FAMILY_MENTOR: 'FAMILY_MENTOR',
  FAMILY_MENTOR_INTAKE: 'FAMILY_MENTOR_INTAKE',
  MEDICAL: 'MEDICAL',
  MEDICAL_INTAKE: 'MEDICAL_INTAKE',
  PEER_MENTOR: 'PEER_MENTOR',
  PEER_MENTOR_INTAKE: 'PEER_MENTOR_INTAKE',
  PSYCH: 'PSYCH',
  PSYCH_INTAKE: 'PSYCH_INTAKE',
  THERAPY: 'THERAPY',
  THERAPY_INTAKE: 'THERAPY_INTAKE',
};

export enum ProgramName {
  EQUIP_ADULT = 'Equip Adult',
  EQUIP_TREATMENT = 'Equip treatment',
  RELAPSE_PREVENTION = 'Relapse prevention',
}

export enum SchedulePermissionTreatmentType {
  FBT = 'FBT',
  CBT_E = 'CBT-E',
  RELAPSE_PREVENTION = 'RELAPSE-PREVENTION',
}

export const programNameToTreatmentTypeMapper: Record<
  ProgramName,
  SchedulePermissionTreatmentType
> = {
  [ProgramName.EQUIP_ADULT]: SchedulePermissionTreatmentType.CBT_E,
  [ProgramName.EQUIP_TREATMENT]: SchedulePermissionTreatmentType.FBT,
  [ProgramName.RELAPSE_PREVENTION]:
    SchedulePermissionTreatmentType.RELAPSE_PREVENTION,
};

export enum SchedulePermissionActionType {
  ALLOW = 'ALLOW',
  CANCEL = 'CANCEL',
  DEFAULT = 'DEFAULT',
  OPTIONAL = 'OPTIONAL',
}
