import { FC } from 'react';

import { ChakraBox, EquipUITheme, Modal } from '@equip.health/ui';

import { Participant } from '../common/Participant';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';

const { h4, paragraph } = EquipUITheme.typography;

const TOP_LEVEL_ID = 'linked-patient';

type LinkedPatientModalProps = {
  isOpen: boolean;
  linkedPatients: UserProfile[];
  onClose: () => void;
};
const LinkedPatientModal: FC<LinkedPatientModalProps> = ({
  isOpen,
  linkedPatients,
  onClose,
}: LinkedPatientModalProps) => {
  const { updateIsSafetyPlanOpen, updateSelectedPatientForSafetyPlan } =
    useUserProfileContext();
  const safetyPlanHandler = (externalId: string) => {
    updateIsSafetyPlanOpen(true);
    updateSelectedPatientForSafetyPlan(externalId);
    onClose();
  };
  return (
    <Modal
      bodyStyle={{ textAlign: 'center' }}
      id={TOP_LEVEL_ID}
      isOpen={isOpen}
      onClose={onClose}
      size="xs"
      title="Choose patient"
      titleStyle={{ ...h4 }}
    >
      {(linkedPatients || []).map((patient) => (
        <ChakraBox
          cursor="pointer"
          key={patient.externalId}
          {...paragraph}
          marginBottom="40px"
          onClick={() => safetyPlanHandler(patient.externalId)}
        >
          <Participant
            id={patient.externalId}
            primaryLabel={patient.chosenName || patient.firstName}
            userId={patient.externalId}
            userType="patient"
          />
        </ChakraBox>
      ))}
    </Modal>
  );
};

export default LinkedPatientModal;
