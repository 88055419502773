import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { GAEventForRouting } from './lib/constants';

const useGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      if (window.location.pathname !== '/thankyou') {
        window.dataLayer?.push({ event: GAEventForRouting });
      }
    }
  }, [initialized, location]);
};

export default useGaTracker;
