import { useCallback, useEffect, useState } from 'react';

import {
  ChakraFlex,
  ChakraText,
  EquipUIFireflyV1Theme,
  useMediaQuery,
} from '@equip.health/ui';
import * as Sentry from '@sentry/react';
import axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';

import AddContactInfo from './AddContactInfo';
import AvailabilityErrorMessage from './AvailabilityErrorMessage';
import Header from './Header';
import HeaderSubtitle from './HeaderSubtitle';
import { ListContainer } from '~/components/schedule/BookAppointment/base/StepContainer';
import TimeSlotGroup from '~/components/schedule/BookAppointment/base/TimeSlotGroup';
import { ApiCommand } from '~/lib/Api';
import { SchedulerStep } from '~/lib/constants/inquiry.constants';
import {
  AVAILABILITY_BASE_URL,
  CRONOFY_ELEMENT_HEADER,
  CalendarResource,
  QUALIFYING_CALL,
  initializeSaveAppointmentRequest,
} from '~/lib/constants/schedule.constants';
import urlConstants from '~/lib/constants/url.constants';
import { useApi } from '~/lib/hooks';
import useScheduleElement from '~/lib/hooks/useScheduleElement';
import {
  addMillis,
  getAggregateTimeSlots,
  getAvailabilityErrorMessage,
  getAvailabilityErrors,
  getBatchedAvailabilityRequests,
  getCronofyElementHeaderValue,
  getSaveAppointmentErrorMessage,
} from '~/lib/util/schedule.util';

import './scheduler.css';
import {
  GroupedTimeSlots,
  getRelativeDayFromDate,
  groupTimeSlotsByDay,
} from '~/lib/util/schedule/bookAppointment.util';
import ScheduleLoader from '~/components/schedule/ScheduleLoader';
import {
  APPLICATION_JSON,
  CONTENT_TYPE_HEADER,
} from '~/lib/constants/api.constants';

const MAX_PARTICIPANTS_PER_AVAILABILITY_QUERY = Number.parseInt(
  import.meta.env.VITE_MAX_PARTICIPANTS_PER_AVAILABILITY_QUERY as string,
  10,
);

const {
  calendar: {
    getAppointmentTypes: getAppointmentTypesUrl,
    getUserInfo: getUserInfoUrl,
    saveAppointment: saveAppointmentUrl,
  },
} = urlConstants;

const { h4 } = EquipUIFireflyV1Theme.typography;

const TOP_LEVEL_ID = 'inquiry__appointment-scheduler';

type AvailabilityRequestPayload = CalendarResourceConfigAvailabilityQuery;

type SchedulerProps = {
  inquiryAppointmentDetails: InquiryAppointmentDetails;
};

const Scheduler = ({ inquiryAppointmentDetails }: SchedulerProps) => {
  const { email: inquiryEmail, phone: inquiryPhone } =
    inquiryAppointmentDetails ?? {};

  const [saveAppointmentRequest, setSaveAppointmentRequest] =
    useState<SaveAppointmentRequest>(null);
  const { appointmentStartDateTime } = saveAppointmentRequest ?? {};

  const [qualifyingCallApptType, setQualifyingCallApptType] =
    useState<AppointmentType>(null);

  const [groupedTimeSlots, setGroupedTimeSlots] =
    useState<GroupedTimeSlots>(null);

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot>(null);

  const { appointmentType, durationInMinutes } = qualifyingCallApptType ?? {};

  const [step, setStep] = useState<SchedulerStep>(SchedulerStep.SELECT_SLOT);

  const [validUserCalendars, setValidUserCalendars] = useState<
    UserCalendarsInfoData['calendarInfos']
  >([]);

  const { elementToken, generateElementToken } = useScheduleElement(
    CalendarResource.DATE_TIME_PICKER,
  );

  const getGroupedTimeSlots = async (): Promise<void> => {
    const availabilityRequestPayloads = getBatchedAvailabilityRequests(
      qualifyingCallApptType,
      validUserCalendars,
      MAX_PARTICIPANTS_PER_AVAILABILITY_QUERY,
    );

    let availabilityResponse =
      [] as AxiosResponse<ScheduleAvailabilityResponse>[];

    try {
      availabilityResponse = await Promise.all(
        availabilityRequestPayloads.map(
          async (payload: AvailabilityRequestPayload) => {
            let response: AxiosResponse<ScheduleAvailabilityResponse>;
            try {
              response = await axios.post<ScheduleAvailabilityResponse>(
                `${AVAILABILITY_BASE_URL}?et=${elementToken}`,
                payload,
                {
                  headers: {
                    [CRONOFY_ELEMENT_HEADER]: getCronofyElementHeaderValue(),
                    [CONTENT_TYPE_HEADER]: APPLICATION_JSON,
                  },
                },
              );
            } catch (e) {
              if (e.response?.status === 422) {
                const participantErrors = getAvailabilityErrors(
                  e,
                  validUserCalendars,
                  payload,
                );

                Sentry.captureMessage(
                  getAvailabilityErrorMessage(participantErrors),
                );

                setValidUserCalendars(
                  validUserCalendars.filter(
                    ({ subId }) =>
                      !Object.keys(participantErrors).includes(subId),
                  ),
                );
              }
            }
            return response;
          },
        ),
      );
    } catch (e) {
      Sentry.captureException(e);
      console.error(e.message);
    }

    if (availabilityResponse.every((res) => res?.status === 200)) {
      const aggregateTimeSlots = getAggregateTimeSlots(availabilityResponse);

      setGroupedTimeSlots(groupTimeSlotsByDay(aggregateTimeSlots));
    }
  };

  const [isLargeScreen] = useMediaQuery('(min-width: 1100px)');

  const {
    data: appointmentTypesData,
    error: appointmentTypesError,
    sendRequest: getAppointmentTypes,
  } = useApi<AppointmentType[]>();

  useEffect(() => {
    getAppointmentTypes({
      command: ApiCommand.GET,
      skipToken: true,
      url: getAppointmentTypesUrl,
    });
  }, []);

  useEffect(() => {
    if (appointmentTypesError) {
      Sentry.captureException(
        `Appointment types error - ${appointmentTypesError}`,
      );
    }
  }, [appointmentTypesError]);

  const {
    data: userCalendarsInfoData,
    error: userCalendarsInfoError,
    sendRequest: getUserCalendarsInfo,
  } = useApi<UserCalendarsInfoData>();
  const { calendarInfos } = userCalendarsInfoData ?? {};

  // Store qualifying appointment type and fetch calendar users associated with this type
  useEffect(() => {
    if (appointmentTypesData?.length) {
      const qualCallApptType = appointmentTypesData?.find(
        ({ appointmentType }) => appointmentType === QUALIFYING_CALL,
      );

      if (qualCallApptType) {
        setQualifyingCallApptType(qualCallApptType);
        getUserCalendarsInfo({
          command: ApiCommand.GET,
          options: {
            'appointment-type-ext-id': qualCallApptType.appointmentTypeExtId,
          },
          skipToken: true,
          url: getUserInfoUrl,
        });
      }
    }
  }, [appointmentTypesData]);

  useEffect(() => {
    if (calendarInfos) setValidUserCalendars(calendarInfos);
  }, [calendarInfos]);

  useEffect(() => {
    if (userCalendarsInfoError) {
      Sentry.captureException(
        `User calendar info error - ${userCalendarsInfoError}`,
      );
    }
  }, [userCalendarsInfoError]);

  /**
   * Generate an element token only after userCalendarsInfoData is returned
   * since we need to pass in the "sub" for each user
   */
  useEffect(() => {
    if (validUserCalendars.length) {
      generateElementToken(validUserCalendars.map(({ subId }) => subId));
    }
  }, [validUserCalendars.length]);

  /**
   * Scroll back to the top of the page any time the user switches between
   * selecting a slot and adding contact details
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  // Fetch PSR availability once the element token has been set
  useEffect(() => {
    if (elementToken) getGroupedTimeSlots();
  }, [elementToken]);

  const {
    error: saveAppointmentError,
    errorData: saveAppointmentErrorData,
    loading: isSaveAppointmentProcessing,
    resetError: resetSaveAppointmentError,
    sendRequest: saveAppointment,
  } = useApi<string>();

  const saveAppointmentErrorMessage = getSaveAppointmentErrorMessage(
    saveAppointmentError,
    saveAppointmentErrorData,
  );

  const onConfirm = useCallback(
    (email: string, phone: string, googleRecaptchaToken: string): void => {
      if (googleRecaptchaToken && googleRecaptchaToken != '')
        saveAppointment({
          callback: (_result: string, statusCode: number, _error: string) => {
            if (statusCode === 200) {
              setStep(SchedulerStep.THANK_YOU);
            } else {
              Sentry.captureException(
                `Save appointment error - ${saveAppointmentError}`,
              );
            }
          },
          command: ApiCommand.POST,
          googleRecaptchaToken,
          options: {
            ...saveAppointmentRequest,
            otherAttendees: [{ email, phone }],
            timeZoneId: DateTime.now().zoneName,
          },
          skipToken: true,
          url: saveAppointmentUrl,
        });
    },
    [saveAppointmentRequest],
  );

  const handleSelectTimeSlot = (timeSlot: TimeSlot) => {
    const { end, participants, start } = timeSlot;

    setSelectedTimeSlot(timeSlot);

    setSaveAppointmentRequest((originalRequest: SaveAppointmentRequest) => {
      const updatedData = {
        appointmentEndDateTime: addMillis(end),
        appointmentStartDateTime: addMillis(start),
        calendarIds: calendarInfos
          .filter(({ subId }) => participants.some(({ sub }) => sub === subId))
          .map(({ calendarId }) => calendarId),
      };

      if (originalRequest) {
        return { ...originalRequest, ...updatedData };
      }

      return initializeSaveAppointmentRequest({
        ...updatedData,
        ...inquiryAppointmentDetails,
        appointmentType,
        createdBy: null,
        hostExternalId: null,
        otherAttendees: [{ email: inquiryEmail, phone: inquiryPhone }],
        patientExternalId: null,
        subject: QUALIFYING_CALL,
      });
    });

    setStep(SchedulerStep.ADD_CONTACT_INFO);
  };

  const isColumn = !isLargeScreen || step === SchedulerStep.THANK_YOU;

  const isEmptyAvailableSlots =
    step === SchedulerStep.SELECT_SLOT &&
    groupedTimeSlots !== null &&
    !Object.keys(groupedTimeSlots).length;

  const shouldShowErrorMessage =
    appointmentTypesError || userCalendarsInfoError || isEmptyAvailableSlots;

  return (
    <ChakraFlex
      alignItems={isColumn ? 'center' : 'flex-start'}
      direction={isColumn ? 'column' : 'row'}
      id="schedule-appointment"
      justify="space-between"
      margin="auto"
      maxWidth="1200px"
      width="100%"
    >
      <ChakraFlex
        alignItems="center"
        direction="column"
        marginRight={isLargeScreen ? '40px' : '0px'}
        width={isLargeScreen ? '50%' : '100%'}
      >
        <Header
          showHeaderImage={isLargeScreen}
          step={step}
          subtitle={
            <HeaderSubtitle
              duration={durationInMinutes}
              startTime={appointmentStartDateTime}
              step={step}
            />
          }
        />
      </ChakraFlex>
      <ChakraFlex
        alignItems="center"
        direction="column"
        width={isLargeScreen ? '50%' : '100%'}
      >
        {shouldShowErrorMessage ? (
          <AvailabilityErrorMessage />
        ) : (
          <>
            {step === SchedulerStep.ADD_CONTACT_INFO && (
              <AddContactInfo
                email={inquiryAppointmentDetails?.email}
                error={saveAppointmentErrorMessage}
                isLoading={isSaveAppointmentProcessing}
                onBackClick={() => {
                  resetSaveAppointmentError();
                  setStep(SchedulerStep.SELECT_SLOT);
                }}
                onConfirmClick={onConfirm}
                phone={inquiryAppointmentDetails?.phone}
                selectedSlot={appointmentStartDateTime}
              />
            )}
            {step === SchedulerStep.SELECT_SLOT && (
              <>
                {groupedTimeSlots === null ? (
                  <ScheduleLoader isFullWidth isQualifyingCall />
                ) : (
                  <>
                    <ChakraText
                      {...h4}
                      id={`${TOP_LEVEL_ID}__time-slots-header`}
                      marginBottom="4px"
                    >
                      Schedule a call
                    </ChakraText>
                    <ListContainer gridGap="32px">
                      {Object.keys(groupedTimeSlots)
                        .sort(
                          (a, b) =>
                            new Date(a).getTime() - new Date(b).getTime(),
                        )
                        .map((date) => {
                          return (
                            <TimeSlotGroup
                              day={getRelativeDayFromDate(date)}
                              id={date}
                              key={date}
                              onSelect={handleSelectTimeSlot}
                              selectedTimeSlot={selectedTimeSlot}
                              timeSlots={groupedTimeSlots[date]}
                            />
                          );
                        })}
                    </ListContainer>
                  </>
                )}
              </>
            )}
          </>
        )}
      </ChakraFlex>
    </ChakraFlex>
  );
};

export default Scheduler;
