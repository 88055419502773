import { FC, PropsWithChildren, useCallback, useEffect } from 'react';

import {
  ChakraBox,
  ChakraButton,
  ChakraFlex,
  ChakraHeading,
  ChakraLink,
  ChakraText,
  EquipLogotype,
  useBreakpointValue,
} from '@equip.health/ui';
import { useHistory } from 'react-router-dom';

import ErrorBackgroundImage from '~/assets/img/error_background.png';
import ErrorSVG from '~/assets/svg/Error';
import { MY_EQUIP_V2_DIMENSIONS, Path } from '~/lib/constants';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useNewRelic } from '~/lib/context/NewRelicContext';

interface ErrorViewProps {
  errorMessage?: string;
}

const HelpfulLinkLabel = ({ children }: PropsWithChildren) => {
  return (
    <ChakraText
      color="brand.500"
      fontSize={['16', '16', '20']}
      fontWeight="600"
      paddingBottom="8px"
    >
      {children}
    </ChakraText>
  );
};

const ErrorView: FC<ErrorViewProps> = ({ errorMessage }) => {
  const history = useHistory();
  const { userProfile } = useUserProfileContext();
  const { newRelicAgent } = useNewRelic();

  const errorIconWidth = useBreakpointValue({
    base: '250',
    lg: '500',
    md: '280',
    sm: '270',
  });

  useEffect(() => {
    newRelicAgent?.noticeError(errorMessage);
  }, [userProfile, newRelicAgent]);

  const handleBackNavigation = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <ChakraFlex
      alignItems="center"
      backgroundImage={['none', 'none', 'none', ErrorBackgroundImage]}
      backgroundPosition="right center"
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      flexDirection="column"
      height="100vh"
      paddingLeft={['32px', '46px', '64px']}
      paddingRight={['32px', '46px', '64px']}
    >
      <ChakraBox
        maxWidth={MY_EQUIP_V2_DIMENSIONS.desktopMaxWidth}
        py={['32px', '46px', '64px']}
        width="100%"
      >
        <ChakraBox marginBottom={['80px', '80px', '100px']}>
          <EquipLogotype width={150} />
        </ChakraBox>
        <ChakraBox marginBottom="20">
          <ErrorSVG width={errorIconWidth} />
        </ChakraBox>
        <ChakraHeading
          fontSize={['22', '30', '40']}
          maxWidth="600px"
          paddingBottom="50"
        >
          {errorMessage}
        </ChakraHeading>
        <ChakraFlex alignItems="flex-start" flexDirection="column">
          <ChakraHeading paddingBottom="16px" size="md">
            Helpful Links
          </ChakraHeading>
          <ChakraButton onClick={handleBackNavigation} variant="link">
            <HelpfulLinkLabel>Back to previous page</HelpfulLinkLabel>
          </ChakraButton>
          <ChakraLink href={Path.HOME}>
            <HelpfulLinkLabel>Equip homepage</HelpfulLinkLabel>
          </ChakraLink>
          <ChakraLink href="mailto:info@equip.health">
            <HelpfulLinkLabel>Email us</HelpfulLinkLabel>
          </ChakraLink>
        </ChakraFlex>
      </ChakraBox>
    </ChakraFlex>
  );
};

export default ErrorView;
