import { FC } from 'react';

import { ChakraFlex, Text } from '@equip.health/ui';

const topLevelId = 'book-appointment__availability';

const MaxAvailabilityRangeErrorView: FC = () => {
  return (
    <ChakraFlex
      backgroundColor="black.5"
      borderRadius="8px"
      id={`${topLevelId}__max-availability-range__error-view`}
      justifyContent="center"
      padding="16px"
    >
      <Text color="neutral.secondary" variant="body">
        Equip doesn't support bookings more than two months in advance
      </Text>
    </ChakraFlex>
  );
};

interface BaseErrorViewProps {
  id: string;
  title: string;
  messages: string[];
}

const BaseErrorView: FC<BaseErrorViewProps> = ({ id, title, messages }) => {
  const baseId = `${id}__error-view`;
  return (
    <ChakraFlex
      flexDirection="column"
      gridGap="12px"
      id={baseId}
      justifyContent="start"
    >
      <Text color="neutral.primary" id={`${baseId}__title`} variant="h5">
        {title}
      </Text>
      {messages.map((message, index) => (
        <Text
          color="neutral.primary"
          key={`${baseId}__message__${index}`}
          variant="body"
        >
          {message}
        </Text>
      ))}
    </ChakraFlex>
  );
};

const NoAvailabilityErrorView: FC = () => {
  return (
    <BaseErrorView
      id="book-appointment__no-availability"
      messages={[
        "We're sorry, it looks like that Equip provider doesn't have any availability within the next two months",
        'Please message your provider directly to find a time',
      ]}
      title="No availability found"
    />
  );
};

const BookingErrorView: FC = () => {
  return (
    <BaseErrorView
      id="book-appointment__booking-error"
      messages={[
        "We're sorry, we are unable to book your appointment at this time.",
        'Please message your provider directly to request an appointment.',
      ]}
      title="Unable to book"
    />
  );
};

export {
  MaxAvailabilityRangeErrorView,
  NoAvailabilityErrorView,
  BookingErrorView,
};
