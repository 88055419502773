import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import isEmpty from 'lodash/isEmpty';
import mixpanel, { Mixpanel } from 'mixpanel-browser';

import { VITE_MIXPANEL_TOKEN } from '~/lib/constants/env';
import useFeatureFlags from '~/lib/hooks/useFeatureFlags';

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(
  undefined,
);

export const useAnalytics = () => useContext(AnalyticsContext);

interface MixpanelProviderProps {
  children: ReactElement | ReactElement[];
}

export const AnalyticsProvider: FC<MixpanelProviderProps> = ({ children }) => {
  const [mixpanelInstance, setMixpanelInstance] = useState<
    Mixpanel | undefined
  >(undefined);

  const { isAppAnalyticsEnabled, isEventAnalyticsEnabled } = useFeatureFlags();

  useEffect(() => {
    if (isAppAnalyticsEnabled && !isEmpty(VITE_MIXPANEL_TOKEN)) {
      mixpanel.init(VITE_MIXPANEL_TOKEN, { track_pageview: false });
      setMixpanelInstance(mixpanel);
    }
  }, [isAppAnalyticsEnabled]);

  const track = (event: string, props?: Record<string, any>) => {
    if (!isEventAnalyticsEnabled) {
      return;
    }
    mixpanelInstance?.track(event, props);
  };

  const trackPageView = (pageName: string) => {
    if (!isEventAnalyticsEnabled) {
      return;
    }
    mixpanelInstance?.track_pageview({
      'Page Name': pageName,
    });
  };

  const identify = (userIdentity: AnalyticsUserIdentity) => {
    const { userId, role, age } = userIdentity;
    mixpanelInstance?.identify(userId);
    mixpanelInstance?.people.set({
      $Age: age,
      $Role: role,
    });
  };

  const updateUserInfo = (userInfo: AnalyticsUserInfo) => {
    const {
      gender,
      numberOfLinkedPatients,
      numberOfSupports,
      adminEpisodeStatus,
    } = userInfo;

    mixpanelInstance?.people.set({
      $Admin_Episode_Status: adminEpisodeStatus,
      $Gender: gender,
      $Number_Of_Linked_Patients: numberOfLinkedPatients,
      $Number_Of_Supports: numberOfSupports,
    });
  };
  const reset = () => {
    mixpanelInstance?.reset();
  };

  const analytics = {
    identify,
    reset,
    track,
    trackPageView,
    updateUserInfo,
  };

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
