import { FC, useEffect, useState } from 'react';

import { ChakraFlex, ChakraImage, Text } from '@equip.health/ui';
import * as Sentry from '@sentry/react';
import isNil from 'lodash/isNil';

import { ApiCommand } from '~/lib/Api';
import { FileValidationStatus } from '~/lib/constants/chat/chat.constants';
import { ERROR_MESSAGES } from '~/lib/constants/patientDocuments.constants';
import urlConstants from '~/lib/constants/url.constants';
import { useChatAttachmentsContext } from '~/lib/context/ChatAttachmentsContext';
import { useChatChannelContext } from '~/lib/context/ChatChannelContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useApi } from '~/lib/hooks';
import {
  DocumentType,
  getDocumentDescription,
  getDocumentTypeFromFileName,
} from '~/lib/util/patientDocuments.util';

import { FileAttachmentThumbnailView } from './Common';

interface DocumentPreviewCardProps {
  document: PatientDocument;
}

const { getAttachmentDownloadUrl: getAttachmentDownloadPathUrl } =
  urlConstants.chat;

const topLevelId = 'document-preview-card';

const DocumentPreviewCard: FC<DocumentPreviewCardProps> = ({ document }) => {
  const { fileName, createdAt, createdBy } = document;
  const { userProfile } = useUserProfileContext();
  const { patientId } = useChatChannelContext();

  const [isValidatingFile, setIsValidatingFile] = useState<boolean>(false);
  const [isValidFile, setIsValidFile] = useState<boolean>(true);
  const fileType = getDocumentTypeFromFileName(document.fileName);
  const isImageDocument = fileType === DocumentType.IMAGE;

  const description = getDocumentDescription(
    userProfile.externalId,
    createdBy,
    createdAt,
  );

  const {
    getDownloadURLForS3Attachment,
    setDownloadURLForS3Attachment,
    setShowChatAttachmentModal,
  } = useChatAttachmentsContext();

  const compoundFileName = `${document.documentExternalId}-${document.fileName}`;

  const [fileUrl, setFileUrl] = useState<string | null>(() =>
    getDownloadURLForS3Attachment(compoundFileName),
  );

  const {
    loading: isDownloadUrlLoading,
    sendRequest: getAttachmentDownloadUrlSendRequest,
    error: getAttachmentDownloadUrlError,
  } = useApi<PatientDocumentDownloadUrlResponse>();

  const handleDownloadUrlError = () => {
    if (getAttachmentDownloadUrlError) {
      Sentry.captureException(getAttachmentDownloadUrlError);
    }
  };

  const fetchDownloadUrl = (completion?: (success?: boolean) => void) => {
    getAttachmentDownloadUrlSendRequest({
      callback: (response) => {
        const {
          attachmentDownloadUrl: responseUrl,
          isValidFile,
          fileValidationStatus,
        } = response || {};

        setIsValidFile(isValidFile);
        setIsValidatingFile(false);
        if (fileValidationStatus !== FileValidationStatus.NEW) {
          setDownloadURLForS3Attachment(compoundFileName, responseUrl);
          setFileUrl(responseUrl);
          completion?.(true);
        } else {
          completion?.(false);
          handleDownloadUrlError();
        }
      },
      command: ApiCommand.GET,
      url: getAttachmentDownloadPathUrl(compoundFileName, patientId),
    });
  };

  useEffect(() => {
    if (isImageDocument && isNil(fileUrl)) {
      fetchDownloadUrl();
    }
  }, [compoundFileName, isImageDocument, fileUrl, patientId]);

  const handleClick = () => {
    if (isImageDocument) {
      handleImageAttachmentPreview();
    } else {
      handleDocumentAttachmentPreview();
    }
  };

  const handleImageAttachmentPreview = () => {
    setShowChatAttachmentModal({
      url: fileUrl,
      name: fileName,
    });
  };

  const handleDocumentAttachmentPreview = () => {
    if (!isValidFile) {
      return;
    }

    const url = getDownloadURLForS3Attachment(compoundFileName);
    if (url) {
      window.open(url);
    } else {
      setIsValidatingFile(true);

      fetchDownloadUrl((success) => {
        setIsValidatingFile(false);
        if (success) {
          handleDocumentAttachmentPreview();
        }
      });
    }
  };

  return (
    <ChakraFlex
      _hover={isValidFile && { background: 'neutral.line' }}
      borderRadius="10px"
      cursor={isValidFile ? 'pointer' : 'not-allowed'}
      id={`${topLevelId}__${fileName}`}
      onClick={handleClick}
      padding="12px"
      position="relative"
    >
      <ChakraFlex
        alignItems="center"
        justifyContent="center"
        marginRight="12px"
        minHeight="40px"
        minWidth="40px"
      >
        {isImageDocument ? (
          <ChakraImage
            borderRadius="10px"
            boxSize="40px"
            loading="lazy"
            objectFit="cover"
            src={fileUrl}
          />
        ) : (
          <FileAttachmentThumbnailView
            fileName={fileName}
            isInvalidFile={!isValidFile}
            isValidatingFile={isValidatingFile || isDownloadUrlLoading}
          />
        )}
      </ChakraFlex>

      <ChakraFlex
        flexDirection="column"
        id={`${topLevelId}__file_name__${fileName}`}
        justifyContent="space-between"
        overflow="hidden"
      >
        <Text color="neutral.primary" isTruncated variant="bodyMedium">
          {fileName}
        </Text>
        <Text
          color="neutral.secondary"
          id={`${topLevelId}__file_extension__${fileName}`}
          variant="caption"
        >
          {isValidFile ? description : ERROR_MESSAGES.validationFailed}
        </Text>
      </ChakraFlex>
    </ChakraFlex>
  );
};

export default DocumentPreviewCard;
