import { FC } from 'react';

import { ChakraBox } from '@equip.health/ui';

const TermsOfService: FC = () => (
  <ChakraBox overflowY="scroll">
    <h2>Terms of Use</h2>
    <br />
    <p>
      If you think you have a medical emergency, call your doctor or 911
      immediately.
    </p>
    <br />
    <h3>Required Disclosures</h3>
    <br />
    <p>
      Read more about required disclosures and information relating to your use
      of this Website.
    </p>
    <br />
    <h3>Agreement and Terms</h3>
    <br />
    <ol>
      <li>
        <p>General Information.&nbsp;</p>
      </li>
    </ol>
    <br />
    <p>
      These Terms of Use (&quot;Agreement&quot;) form a legal agreement between
      Equip Health, Inc. and its affiliates (collectively &ldquo;Equip&rdquo;,
      &ldquo;We&rdquo;, &ldquo;Us&rdquo;, or &ldquo;our&rdquo;) and you, the
      individual (&quot;you&quot; and &quot;your&quot;) concerning your access
      to and use of the various services offered through the website (as defined
      herein). Equip owns and operates the website located at www.equip.health
      and other related websites and mobile applications (collectively, the
      &ldquo;Website&rdquo;). We offer online telehealth services enabling our
      patients (&ldquo;Patients&rdquo;) to report their health history and
      engage healthcare professionals (&ldquo;Healthcare Professionals&rdquo;)
      to obtain healthcare services (&ldquo;Services&rdquo;), as well as provide
      behavioral health mentoring from mentors and/or coaches (collectively
      &ldquo;Mentors&rdquo;) who do not provide professional or clinical
      services.
    </p>
    <p>
      <br />
    </p>
    <p>
      By accessing and using the Website, you agree to be bound by these Terms
      of Use and all other terms and policies that appear on the Website. If you
      do not wish to be bound by any of these Terms of Use, you may not use the
      Website or the Services.
    </p>
    <p>
      <br />
    </p>
    <p>
      These Terms are in addition to the terms of other supplemental agreements
      Equip will ask you to read and agree to prior to providing you access and
      use of the Services. You understand that you may not access or use the
      Services until you agree to the terms of such supplemental agreement(s).
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>
      THIS AGREEMENT CONTAINS AN ARBITRATION CLAUSE THAT REQUIRES MOST DISPUTES
      BETWEEN US TO BE RESOLVED THROUGH BINDING AND FINAL ARBITRATION INSTEAD OF
      IN COURT. SEE SECTION 14 FOR MORE INFORMATION REGARDING THIS ARBITRATION
      CLAUSE, AND HOW TO OPT OUT.
    </p>
    <p>
      <br />
    </p>
    <p>
      By accessing or using any website with an authorized link to this
      Agreement or accessing or using any content, information, services,
      features or resources available or enabled via such website, clicking on a
      button or taking another action to signify your acceptance of this
      Agreement, you: (1) agree to be bound by this Agreement and any future
      amendments and additions to this Agreement as published through the
      Website; (2) represent you are of legal age in your jurisdiction of
      residence to form a binding contract; and (3) represent that you have the
      authority to enter into this Agreement personally and, if applicable, on
      behalf of any company, organization or other person or legal entity on
      whose behalf you use the Website, including but not limited to your child.
      Except as otherwise provided herein, if you do not agree to be bound by
      this Agreement, you may not access or use the Website.
    </p>
    <p>
      <br />
    </p>
    <ol start={2}>
      <li>
        <p>Right to Modify</p>
      </li>
    </ol>
    <p>
      <br />
    </p>
    <p>
      Equip reserves the right to modify this Agreement or its policies relating
      to the Website at any time, effective upon posting of an updated version
      of this Agreement on the applicable Website. Continued use of the Site
      and/or Services following notice of any such changes will indicate your
      acknowledgement of such changes and agreement to be bound by the revised
      Terms of Use, inclusive of such changes. You should regularly review this
      Agreement, as your continued use of the Website after any such changes
      constitutes your agreement to such changes.
    </p>
    <p>
      <br />
    </p>
    <ol start={3}>
      <li>
        <p>Site Content</p>
      </li>
    </ol>
    <p>
      <br />
    </p>
    <p>
      The information contained on the Website (&ldquo;Content&rdquo;) is given
      for general information and general interest purposes only. The Content
      cannot, and is not intended to, replace the relationship that you have
      with your health care professionals. You should always talk to your health
      care professionals for diagnosis and treatment, including information
      regarding which drugs or treatment may be appropriate for you. None of the
      site Content should be considered medical advice or an endorsement,
      representation or warranty that any particular medication or treatment is
      safe, appropriate, or effective for you.
    </p>
    <p>
      <br />
    </p>
    <ol start={4}>
      <li>
        <p>Healthcare Services</p>
      </li>
    </ol>
    <p>
      <br />
    </p>
    <p>
      The healthcare professionals who deliver Services through Equip are
      independent professionals practicing in an independently-owned
      professional practice. Equip Health, Inc. does not give medical advice,
      practice medicine, psychotherapy or any other licensed profession, and
      does not interfere with the practice of healthcare professionals, each of
      whom is responsible for his or her services and compliance with the
      requirements applicable to his or her profession and license. Neither
      Equip Health, Inc., nor any third parties who promote the Services or
      provide you with a link to the Services shall be liable for any
      professional advice you obtain from a licensed healthcare professional
      through the Services.
    </p>
    <p>
      <br />
    </p>
    <ol start={5}>
      <li>
        <p>Notice Concerning Services of Equip Mentors</p>
      </li>
    </ol>
    <p>
      <br />
    </p>
    <p>
      Equip mentors are not licensed health care professionals and are not
      authorized to provide services requiring professional licensure, including
      psychotherapy. Your interactions with your Equip mentors are not intended
      to be a substitute for professional health care advice, diagnosis, or
      treatment. Always seek the advice of your physician or therapist with any
      questions you may have regarding your physical or mental health. Never
      disregard professional health care advice or delay in seeking it based on
      any interaction with your Equip mentor.
    </p>
    <p>
      <br />
    </p>
    <ol start={6}>
      <li>
        <p>Access to User Accounts</p>
      </li>
    </ol>
    <p>
      <br />
    </p>
    <p>
      You may be required to provide personal and/or demographic information as
      part of a registration or log-in process to access the Services. If you
      are the parent or guardian of a minor who is obtaining the services made
      available by Equip, the information you provide may be about you and also
      about the minor. &nbsp;In all cases, you agree to provide true, accurate,
      current and complete information during registration or log-in process.
      You are responsible for maintaining the confidentiality of your account
      password and for all activities that occur under your account. In no event
      and under no circumstances shall Equip be held liable to you for any
      liabilities or damages resulting from or arising out of your use of the
      Website, your use of the account information or your release of the
      account information to a third party.
    </p>
    <p>
      <br />
    </p>
    <ol start={7}>
      <li>
        <p>Fees and Payment.</p>
      </li>
    </ol>
    <p>
      <br />
    </p>
    <p>
      You agree to pay all fees associated with your use of the Services in
      accordance with billing terms in effect at the time a fee is due and
      payable. If your health plan, employer or other agency has arranged with
      Equip to pay the fee or any portion of the fee, that fee adjustment will
      be reflected in the fee that you are ultimately charged. Please check with
      your employer, health plan or agency to determine if any Services will be
      reimbursed. If you do not have insurance coverage for Services, or if your
      coverage is denied, you acknowledge and agree that you shall be personally
      responsible for all expenses incurred. Equip reserves the right to modify
      or implement a new pricing structure at any time prior to billing you for
      your initial payment or for future payments due pursuant to these Terms of
      Use.
    </p>
    <p>
      <br />
    </p>
    <ol start={8}>
      <li>
        <p>Text Messages and Electronic Communications</p>
      </li>
    </ol>
    <p>
      <br />
    </p>
    <p>
      Opt In. As part of providing you with the services, we may need to provide
      you with certain communications such as appointment reminders, service
      announcements, privacy notices, administrative messages and other
      communications about the services (&ldquo;Communications&rdquo;). Please
      review our Privacy Policy and required disclosures for further information
      on our promotional communications and privacy practices with respect to
      personal information that you submit through the Services.
    </p>
    <p>
      <br />
    </p>
    <p>
      You agree that Equip may send to you Communications through electronic
      means including but not limited to: (1) email, using the address that you
      provided to us during registration, (2) short messaging service
      (&ldquo;SMS&rdquo;) text message to the mobile number you provided us
      during registration (&ldquo;SMS Messages&rdquo;), (3) push notifications
      on your tablet or mobile device, or (4) by posting Communications on the
      Equip Website. The delivery of any Communications from us is effective
      when sent by us, regardless of whether you read the Communication. You can
      withdraw your consent to receive Communications by deactivating your
      account or contact Equip at support@equip.health. You acknowledge and
      consent to receive unsecure emails and texts containing information about
      the Equip program which may also include your confidential personal
      information and/or protected health information. Equip cannot ensure the
      security or confidentiality of messages sent by unsecured email or text.
      This means there is a risk of interception of the information by third
      parties.
    </p>
    <p>
      <br />
    </p>
    <p>
      You may opt to respond to Equip SMS messages with health information such
      weight, vital signs, and other basic medical data. This information is
      used for the express purpose of monitoring medical stability in a virtual
      setting. Despite any security procedure and methods of your wireless
      carrier, they may not prevent someone from intercepting your message.
      Therefore a text message is not considered 100% secure.
    </p>
    <p>
      <br />
    </p>
    <p>
      For this reason, Equip will take care to not send you text messages that
      contain any unnecessary or sensitive personal information. Nevertheless,
      because of the risk that a text message, even with non-sensitive
      information, could be intercepted, you have the choice whether to receive
      text messages from Equip or not.
    </p>
    <p>
      <br />
    </p>
    <p>
      A message addressed to your mobile phone number could be read by anyone
      who has access to that device. Some people have their phone set to have a
      text message appear on the screen even if the phone is locked. This may
      let someone else read the text message. You can change your phone settings
      to prevent this or you may choose not to send information to Equip via SMS
      text.
    </p>
    <p>
      <br />
    </p>
    <p>
      The number of SMS Messages we send as part of our Communications will
      depend on the frequency of your use of the Services. You can cancel the
      SMS service at any time. Just text &ldquo;STOP&rdquo; to the text you
      received from Us. After you send the SMS message &ldquo;STOP&rdquo; to us,
      we will send you an SMS message to confirm that you have been
      unsubscribed. After this, you will no longer receive SMS messages from us.
      If you want to join again, text &ldquo;UNSTOP&rdquo; to the text you
      received from us, and we will start sending SMS messages to you again. If
      you are experiencing issues with the messaging program you can reply with
      the keyword HELP for more assistance, or you can get help directly at
      support@equip.health.
    </p>
    <p>
      <br />
    </p>
    <p>Participating Carriers</p>
    <p>
      AT&amp;T, Verizon Wireless, Sprint, T-Mobile, U.S. Cellular, Boost Mobile,
      MetroPCS, Virgin Mobile, Alaska Communications Systems (ACS), Appalachian
      Wireless (EKN), Bluegrass Cellular, Cellular One of East Central, IL
      (ECIT), Cellular One of Northeast Pennsylvania, Cricket, Coral Wireless
      (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch
      (Elkhart Telephone), GCI, Golden State, Hawkeye (Chat Mobility), Hawkeye
      (NW Missouri), Illinois Valley Cellular, Inland Cellular, iWireless (Iowa
      Wireless), Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated
      or CTC Telecom), Nex-Tech Wireless, NTelos, Panhandle Communications,
      Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry (TMP
      Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero
      Wireless, and West Central (WCC or 5 Star Wireless).
    </p>
    <p>
      <br />
    </p>
    <p>T-Mobile is not liable for delayed or undelivered messages.</p>
    <p>
      <br />
    </p>
    <p>
      Message and data rates may apply for any messages sent to you from us and
      to us from you. If you have any questions about your text plan or data
      plan, it is best to contact your wireless provider.
    </p>
    <p>
      <br />
    </p>
    <ol start={9}>
      <li>
        <p>Ownership of and License to Use Equip Properties</p>
      </li>
    </ol>
    <p>
      <br />
    </p>
    <p>
      Use of the Website. Equip and its licensors own all rights, title and
      interest in the Website. The Website is protected by copyright and other
      intellectual property laws throughout the world. Subject to this
      Agreement, Equip grants you a limited license to use the Website solely
      for your personal non-commercial purposes. Any future release, update or
      other addition to the Website shall be subject to this Agreement. Equip
      and its licensors reserve all rights not granted in this Agreement.
    </p>
    <p>
      <br />
    </p>
    <p>
      Trademarks. Equip&rsquo;s stylized name and other related graphics, logos,
      service marks and trade names used on or in connection with the Website
      are the trademarks of Equip and may not be used without permission in
      connection with any third-party products or services. Other trademarks,
      service marks and trade names that may appear on or in the Website are the
      property of their respective owners. You will not remove, alter or obscure
      any copyright notice, trademark, service mark or other proprietary rights
      notices incorporated in or accompanying the Website.
    </p>
    <p>
      <br />
    </p>
    <p>
      Restrictions on Use of Website. The rights granted to you in this
      Agreement are subject to the following restrictions: (a) you shall not
      license, sell, rent, lease, transfer, assign, reproduce, distribute, host
      or otherwise commercially exploit the Website or any portion of the
      Website; (b) you shall not frame or use framing techniques to enclose any
      trademark, logo or Website (including images, text, page layout or form);
      (c) you shall not use any metatags or other &ldquo;hidden text&rdquo;
      using Equip&rsquo;s name or trademarks; (d) you shall not modify,
      translate, adapt, merge, make derivative works of, disassemble, decompile,
      reverse compile or reverse engineer any part of the Website except to the
      extent the foregoing restrictions are expressly prohibited by applicable
      law; (e) you shall not use any manual or automated software, devices or
      other processes (including but not limited to spiders, robots, scrapers,
      crawlers, avatars, data mining tools or the like) to &ldquo;scrape&rdquo;
      or download data from the Website (except that we grant the operators of
      public search engines revocable permission to use spiders to copy
      materials from the Website for the sole purpose of and solely to the
      extent necessary for creating publicly available searchable indices of the
      materials, but not caches or archives of such materials); (f) you shall
      not access the Website to build a similar or competitive website,
      application or service; (g) except as expressly stated herein, no part of
      the Website may be copied, reproduced, distributed, republished,
      downloaded, displayed, posted or transmitted in any form or by any means;
      (h) you shall not remove or destroy any copyright notices or other
      proprietary markings contained on or in the Website; (i) you shall not
      interfere with or attempt to interfere with the proper functioning of the
      Website or use the Website in any way not expressly permitted by this
      Agreement; and (j) you shall not attempt to harm the Website, including
      but not limited to, by violating or attempting to violate any related
      security features, introducing viruses, worms, or similar harmful code
      into the Website, or interfering or attempting to interfere with use of
      the Website by any other user, host or network, including by means of
      overloading, &ldquo;flooding,&rdquo; &ldquo;spamming,&rdquo; &ldquo;mail
      bombing&rdquo;, or &ldquo;crashing&rdquo; the Website. Any unauthorized
      use of the Website terminates the licenses granted by Equip pursuant to
      this Agreement.
    </p>
    <p>
      <br />
    </p>
    <p>
      Use of Website by Children&nbsp; &nbsp;&nbsp;With respect to any Equip
      service offerings, no one under 13 (or, if greater than 13, the minimum
      age at which a person may use the services under applicable state or
      federal law) (&ldquo;Minimum Age&rdquo;) is allowed to create an account
      or use Equip Wellness services. If you are under the Minimum Age, you may
      only use the Wellness services with the prior consent of your parent or
      legal guardian, and the account must be set up by your parent or legal
      guardian.
    </p>
    <p>
      <br />
    </p>
    <p>
      If you register as the parent or legal guardian on behalf of a minor with
      respect to any of Equip services, clinical and/or wellness, you will be
      fully responsible for complying with these Terms of Use for yourself and
      for your minor.
    </p>
    <p>
      <br />
    </p>
    <p>
      Equip does not seek through the Website to gather personal information
      from or about persons under the age of 13 without the consent of a parent
      or guardian.
    </p>
    <p>
      <br />
    </p>
    <p>
      Third-Party Links. The Website may contain links to third-party services
      such as third party websites, applications, or ads (&ldquo;Third-Party
      Links&rdquo;). When you click on such a link, we will not warn you that
      you have left the Website. Equip does not control and is not responsible
      for Third-Party Links. Equip provides these Third-Party Links only as a
      convenience and does not review, approve, monitor, endorse, warrant, or
      make any representations with respect to them, or any content, products or
      services accessible through such links. Your use of all Third-Party Links
      is at your own risk.
    </p>
    <p>
      <br />
    </p>
    <ol start={10}>
      <li>
        <p>Indemnification.</p>
      </li>
    </ol>
    <br />
    <p>
      You agree to indemnify and hold Equip, its corporate parents,
      subsidiaries, and affiliates, and the officers, directors, employees,
      agents, representatives, partners and licensors of each (collectively, the
      &ldquo;Equip Parties&rdquo;) harmless from any damages, losses, costs,
      liabilities and expenses (including reasonable attorneys&rsquo; fees)
      relating to or arising out of any claims concerning: (a) your misuse of
      the Website; (b) your violation of this Agreement; (c) your violation of
      any rights of another party; or (d) your violation of any applicable laws,
      rules or regulations. Equip reserves the right, at its own cost, to assume
      the exclusive defense and control of any matter otherwise subject to
      indemnification by you, in which event you will fully cooperate with Equip
      in asserting any available defenses. You agree that the provisions in this
      section will survive any termination of this Agreement or your access to
      the Website.
    </p>
    <p>
      <br />
    </p>
    <ol start={11}>
      <li>
        <p>Disclaimer of Warranties and Conditions.</p>
      </li>
    </ol>
    <br />
    <p>
      YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE FULLEST EXTENT PERMITTED BY
      APPLICABLE LAW, YOUR USE OF THE WEBSITE IS AT YOUR SOLE RISK, AND THE
      WEBSITE IS PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS
      AVAILABLE&rdquo; BASIS, WITH ALL FAULTS. TO THE FULLEST EXTENT PERMITTED
      BY APPLICABLE LAW, THE Equip PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES,
      REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
      INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
      NON-INFRINGEMENT ARISING FROM USE OF THE WEBSITE. Equip PARTIES MAKE NO
      WARRANTY, REPRESENTATION OR CONDITION THAT: (1) THE WEBSITE WILL MEET YOUR
      REQUIREMENTS OR (2) YOUR USE OF THE WEBSITE WILL BE UNINTERRUPTED, TIMELY,
      SECURE OR ERROR-FREE.
    </p>
    <br />
    <ol start={12}>
      <li>
        <p>Limitation of Liability</p>
      </li>
    </ol>
    <br />

    <p>
      Disclaimer of Certain Damages. TO THE FULLEST EXTENT PERMITTED BY
      APPLICABLE LAW, THE EQUIP PARTIES SHALL NOT BE LIABLE FOR ANY LOSS OF
      PROFITS OR REVENUE OR FOR INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY,
      SPECIAL OR CONSEQUENTIAL DAMAGES, OR DAMAGES OR COSTS DUE TO LOSS OF DATA,
      PRODUCTION, OR USE, BUSINESS INTERRUPTION OR PROCUREMENT OF SUBSTITUTE
      SERVICES, WHETHER OR NOT Equip HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
      DAMAGES.
    </p>
    <p>
      <br />
    </p>
    <p>
      Cap on Liability. UNDER NO CIRCUMSTANCES WILL THE EQUIP PARTIES BE LIABLE
      TO YOU FOR MORE THAN ONE HUNDRED DOLLARS ($100) IN THE TOTAL AGGREGATE
      AMOUNT.
    </p>
    <p>
      <br />
    </p>
    <p>
      Basis of the Bargain. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
      FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN EQUIP AND YOU.
    </p>
    <p>
      <br />
    </p>
    <ol start={13}>
      <li>
        <p>
          Suspension or Termination.
          <br />
        </p>
      </li>
    </ol>
    <p>
      At its sole discretion, Equip may modify or discontinue the Website, or
      may modify, suspend or terminate your access to the Website, for any
      reason, with or without notice to you and without liability to you or any
      third party. In addition to suspending or terminating your access to the
      Website, Equip reserves the right to take appropriate legal action,
      including without limitation pursuing civil, criminal or injunctive
      redress.
    </p>
    <br />
    <p>
      International Users. The Website is controlled and offered by Equip from
      its facilities in the United States of America. Equip makes no
      representations that the Website is appropriate or available for use in
      other locations. Those who access or use the Website from other countries
      do so at their own volition and are responsible for compliance with local
      law.
    </p>
    <p>
      <br />
    </p>

    <ol start={14}>
      <li>
        <p>Dispute Resolution</p>
      </li>
    </ol>
    <br />
    <p>
      Please read the following arbitration agreement in this Section
      (&ldquo;Arbitration Agreement&rdquo;) carefully. It requires you to
      arbitrate disputes with Equip and limits the manner in which you can seek
      relief from us.
    </p>
    <p>
      <br />
    </p>
    <p>
      Applicability of Arbitration Agreement. Unless otherwise prohibited by
      applicable state or federal law, You agree that any dispute between you
      and us relating in any way to the Website or this Agreement, will be
      resolved by binding arbitration, rather than in court, except that (1) you
      may assert claims in small claims court if your claims qualify; and (2)
      you or Equip may seek equitable relief in court for infringement or other
      misuse of intellectual property rights (such as trademarks, trade dress,
      domain names, trade secrets, copyrights, and patents). This Arbitration
      Agreement shall apply, without limitation, to all claims that arose or
      were asserted before the Effective Date of this Agreement or any prior
      version of this Agreement.
    </p>
    <p>
      <br />
    </p>
    <p>
      Arbitration Rules and Forum. The Federal Arbitration Act governs the
      interpretation and enforcement of this Arbitration Agreement. To begin an
      arbitration proceeding, you must send a letter requesting arbitration and
      describing your claim to Equip Health, Inc., 2659 State Street Suite 100
      #1012, Carlsbad, CA 92008. The arbitration will be conducted by JAMS, an
      established alternative dispute resolution provider. Disputes involving
      claims and counterclaims with an amount in controversy under $250,000, not
      inclusive of attorneys&rsquo; fees and interest, shall be subject to
      JAMS&rsquo; most current version of the Streamlined Arbitration Rules and
      procedures; all other claims shall be subject to JAMS&rsquo;s most current
      version of the Comprehensive Arbitration Rules and Procedures, available
      at http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS&rsquo;s
      rules are also available at www.jamsadr.com by calling JAMS at
      800-352-5267. If JAMS is not available to arbitrate, the parties will
      select an alternative arbitral forum. If the arbitrator finds that you
      cannot afford to pay JAMS&rsquo;s filing, administrative, hearing and/or
      other fees and you cannot obtain a waiver from JAMS, Equip will pay them
      for you.
    </p>
    <p>
      <br />
    </p>
    <p>
      You may choose to have the arbitration conducted by telephone, based on
      written submissions, or in person in the U.S. county where you live or at
      another mutually agreed location. Any judgment on the award rendered by
      the arbitrator may be entered in any court of competent jurisdiction.
    </p>
    <p>
      <br />
    </p>
    <p>
      Authority of Arbitrator. The arbitrator shall have exclusive authority to
      resolve any dispute related to the interpretation, applicability,
      enforceability or formation of this Arbitration Agreement including, but
      not limited to any claim that all or any part of this Arbitration
      Agreement is void or voidable. The arbitrator will decide the rights and
      liabilities, if any, of you and Equip. The arbitration proceeding will not
      be consolidated with any other matters or joined with any other
      proceedings or parties. The arbitrator shall have the authority to grant
      motions dispositive of all or part of any claim or dispute. The arbitrator
      shall have the authority to award monetary damages and to grant any
      non-monetary remedy or relief available to an individual party under
      applicable law, the arbitral forum&rsquo;s rules, and this Agreement
      (including the Arbitration Agreement). The arbitrator shall issue a
      written award and statement of decision describing the essential findings
      and conclusions on which any award (or decision not to render an award) is
      based, including the calculation of any damages awarded. The arbitrator
      shall follow the applicable law. The arbitrator has the same authority to
      award relief on an individual basis that a judge in a court of law would
      have. The award of the arbitrator is final and binding upon you and us.
    </p>
    <p>
      <br />
    </p>
    <p>
      Waiver of Jury Trial. YOU AND EQUIP HEREBY WAIVE ANY CONSTITUTIONAL AND
      STATUTORY RIGHTS TO SUE IN COURT (OTHER THAN SMALL CLAIMS COURT AND
      INTELLECTUAL PROPERTY CLAIMS AS PERMITTED HEREIN) AND HAVE A TRIAL IN
      FRONT OF A JUDGE OR A JURY. You and Equip are instead electing that all
      covered claims and disputes shall be resolved by arbitration under this
      Arbitration Agreement, except as specified in above. An arbitrator can
      award on an individual basis the same damages and relief as a court and
      must follow this Agreement as a court would. However, there is no judge or
      jury in arbitration, and court review of an arbitration award is subject
      to very limited review.
    </p>
    <p>
      <br />
    </p>
    <p>
      Waiver of Class or Other Non-Individualized Relief. ALL CLAIMS AND
      DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED
      ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE BASIS, ONLY
      INDIVIDUAL RELIEF IS AVAILABLE FOR CLAIMS COVERED BY THIS ARBITRATION
      AGREEMENT, AND CLAIMS OF MORE THAN ONE USER CANNOT BE ARBITRATED OR
      CONSOLIDATED WITH THOSE OF ANY OTHER USER OR PERSON. If a decision is
      issued stating that applicable law precludes enforcement of any of this
      Section&rsquo;s limitations as to a given claim for relief, then the
      applicable claim must be severed from the arbitration and brought in the
      state or federal courts located in the State of California in accordance
      with the Venue and Governing Law provisions below. All other claims shall
      be arbitrated.
    </p>
    <p>
      <br />
    </p>
    <ol start={15}>
      <li>
        <p>General Provisions</p>
      </li>
    </ol>
    <br />
    <p>
      Assignment.&nbsp;This Agreement, and your rights and obligations
      hereunder, may not be assigned, subcontracted, delegated or otherwise
      transferred by you without Equip&rsquo;s prior written consent, and any
      attempted assignment, subcontract, delegation, or transfer in violation of
      the foregoing will be null and void.
    </p>
    <p>
      <br />
    </p>
    <p>
      Exclusive Venue. To the extent the parties are permitted under this
      Agreement to initiate litigation in a court, both you and Equip agree that
      all claims and disputes arising out of or relating to this Agreement will
      be litigated exclusively in the state courts in San Diego County,
      California or federal courts located in the Southern District of
      California.
    </p>
    <p>
      <br />
    </p>
    <p>
      Governing Law. THIS AGREEMENT AND ANY ACTION RELATED THERETO WILL BE
      GOVERNED AND INTERPRETED BY AND UNDER THE LAWS OF THE STATE OF CALIFORNIA,
      CONSISTENT WITH THE FEDERAL ARBITRATION ACT, WITHOUT GIVING EFFECT TO ANY
      CONFLICT OF LAW OR OTHER PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF
      THE LAW OF ANOTHER JURISDICTION.
    </p>
    <p>
      <br />
    </p>
    <p>
      Waiver. Any waiver or failure to enforce any provision of this Agreement
      on one occasion will not be deemed a waiver of any other provision or of
      such provision on any other occasion.
    </p>
    <p>
      <br />
    </p>
    <p>
      Severability. If any portion of this Agreement is held invalid or
      unenforceable, that portion shall be construed in a manner to reflect, as
      nearly as possible, the original intention of the parties, and the
      remaining portions shall remain in full force and effect.
    </p>
    <p>
      <br />
    </p>
    <p>
      Notice. You may give notice to Equip at the following address: Equip
      Behavioral Health, Inc., 2659 State Street Suite 100 #1012, Carlsbad, CA
      92008. Such notice shall be deemed given when received by Equip by letter
      delivered by nationally recognized overnight delivery service or first
      class postage prepaid mail at the above address.
    </p>
    <p>
      <br />
    </p>
    <p>Copyright Infringement &ndash; DMCA Notice</p>
    <p>
      <br />
    </p>
    <p>
      The Digital Millennium Copyright Act of 1998 (the &ldquo;DMCA&rdquo;)
      provides recourse for copyright owners who believe that material appearing
      on the Internet infringes their rights under US copyright law. If you
      believe in good faith that content or material on this Website infringes a
      copyright owned by you, you (or your agent) may send the Company a notice
      requesting that the material be removed, or access to it blocked. This
      request should be sent to: e-mail support@equip.health or 2659 State
      Street Suite 100 #1012, Carlsbad, CA 92008.
    </p>
    <p>
      <br />
    </p>
    <p>
      The notice must include the following information: (a) a physical or
      electronic signature of a person authorized to act on behalf of the owner
      of an exclusive right that is allegedly infringed; (b) identification of
      the copyrighted work claimed to have been infringed; (c) identification of
      the material that is claimed to be infringing or the subject of infringing
      activity; (d) the name, address, telephone number, and email address of
      the complaining party; (e) a statement that the complaining party has a
      good faith belief that use of the material in the manner complained of is
      not authorized by the copyright owner, its agent or the law; and (f) a
      statement that the information in the notification is accurate and, under
      penalty of perjury, that the complaining party is authorized to act on
      behalf of the owner of an exclusive right that is allegedly infringed. If
      you believe in good faith that a notice of copyright infringement has been
      wrongly filed against you, the DMCA permits you to send us a
      counter-notice. Notices and counter-notices must meet the then-current
      statutory requirements imposed by the DMCA. Notices and counter-notices
      with respect to the Website should be sent to the address above.
    </p>
    <p>
      <br />
    </p>
    <p>
      Contact Information. If you would like to contact us with respect to the
      Website, please e-mail support@equip.health
    </p>
    <p>
      <br />
    </p>
    <p>Last Updated and Effective: August 10, 2023</p>
  </ChakraBox>
);

export default TermsOfService;
