import { FC } from 'react';

import {
  ChakraCheckbox,
  ChakraFlex,
  ChakraLink,
  ChakraTag,
  ChakraText,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

import { WarnChangesModalViewDataProps } from './WarnChangesModal';

const { captionSemibold, overline } = EquipUIFireflyV1Theme.typography;

const { VITE_EQUIP_PRIVACY_POLICY, VITE_EQUIP_TERMS_OF_USE } = import.meta.env;

export const SMSWarningData: WarnChangesModalViewDataProps = {
  cancelButtonText: 'Go back to change',
  confirmButtonText: 'Keep SMS off',
  description: (
    <>
      SMS is important for treatment, and we strongly recommend turning it on.
      <br />
      <br />
      You can always turn it off later.
    </>
  ),
  title: 'Are you sure you want SMS off?',
};

export const NotificationsOffWarningData: WarnChangesModalViewDataProps = {
  cancelButtonText: 'Go back to change',
  confirmButtonText: 'Keep notifications off',
  description: (
    <>
      SMS and email notifications are important for treatment (especially SMS).
      We recommend turning at least one on.
      <br />
      <br />
      You can always turn it off later.
    </>
  ),
  title: 'Are you sure you want notifications off?',
};

const TOP_LEVEL_ID = 'notification-preferences';
interface NotificationPreferencesProps {
  isSmsChecked: boolean;
  isEmailChecked: boolean;
  onEmailCheckboxChange: (isSelected: boolean) => void;
  onSmsCheckboxChange: (isSelected: boolean) => void;
}

const NotificationPreferences: FC<NotificationPreferencesProps> = ({
  isEmailChecked,
  isSmsChecked,
  onEmailCheckboxChange,
  onSmsCheckboxChange,
}) => {
  return (
    <ChakraFlex
      flexDirection="column"
      id={`${TOP_LEVEL_ID}__container`}
      marginBottom="40px"
    >
      <ChakraFlex>
        <ChakraCheckbox
          colorScheme="teal"
          id={`${TOP_LEVEL_ID}__sms-checkbox`}
          isChecked={isSmsChecked}
          marginRight="12px"
          onChange={(e) => {
            onSmsCheckboxChange(e.target.checked);
          }}
        >
          SMS
        </ChakraCheckbox>
        <ChakraTag
          {...captionSemibold}
          background="tertiary.marigold.background"
          borderRadius="4px"
          textColor="tertiary.marigold.text"
        >
          Recommended
        </ChakraTag>
      </ChakraFlex>
      <ChakraText
        {...overline}
        color="black.60"
        marginBottom="24px"
        marginTop="8px"
        maxWidth="460px"
      >
        By checking this box, you agree to receive text messages from Equip
        Health. To cancel, reply STOP or change your app preferences. Msg & data
        rates may apply. View our{' '}
        <ChakraLink color="teal" href={VITE_EQUIP_TERMS_OF_USE} isExternal>
          Terms & Conditions
        </ChakraLink>{' '}
        and{' '}
        <ChakraLink
          color="teal.100"
          href={VITE_EQUIP_PRIVACY_POLICY}
          isExternal
        >
          Privacy Policy
        </ChakraLink>
        .
      </ChakraText>
      <ChakraCheckbox
        colorScheme="teal"
        id={`${TOP_LEVEL_ID}__email-checkbox`}
        isChecked={isEmailChecked}
        onChange={(e) => onEmailCheckboxChange(e.target.checked)}
      >
        Email
      </ChakraCheckbox>
    </ChakraFlex>
  );
};

export default NotificationPreferences;
