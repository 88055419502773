import { FC, useEffect, useState } from 'react';

import {
  ChakraBox,
  ChakraCollapse,
  ChakraFlex,
  EquipUIFireflyV2Theme,
  FAIconName,
  Icon,
  Text,
} from '@equip.health/ui';

import PastEventList from './PastEventsList';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';

const { neutral } = EquipUIFireflyV2Theme.colors;

const TOP_LEVEL_ID = 'schedule-details';

interface PastEventsProps {
  selectedUserId?: string;
}

const PastEvents: FC<PastEventsProps> = ({ selectedUserId }) => {
  const { isFullProfileInfoFetched } = useUserProfileContext();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { track } = useAnalytics();
  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
    track('Past Events Visibility Changed', {
      'Is Visible': !isCollapsed,
    });
  };

  useEffect(() => {
    if (selectedUserId && isFullProfileInfoFetched) {
      setIsCollapsed(true);
    }
  }, [selectedUserId, isFullProfileInfoFetched]);

  return (
    <ChakraBox
      display={{ base: 'none', md: 'block' }}
      height="690px"
      marginLeft={{ base: '0', md: '36px', xl: '72px' }}
      marginTop={{ base: '36px', md: '0' }}
      position="relative"
      width={{ base: '100%', md: '200px', lg: '250px', xl: '365px' }}
    >
      <ChakraBox
        background="black.5"
        borderRadius={{ base: '0px', md: '16px' }}
        height="auto"
        padding="24px"
        width={{ base: '100%', md: '200px', lg: '250px', xl: '365px' }}
      >
        <ChakraFlex
          alignItems="center"
          cursor="pointer"
          justifyContent="space-between"
          onClick={handleCollapseToggle}
        >
          <Text
            color="neutral.primary"
            id={`${TOP_LEVEL_ID}__past-events__title`}
            variant="h5"
          >
            Past events
          </Text>
          <ChakraFlex
            alignItems="center"
            height="40px"
            justifyContent="center"
            transform={isCollapsed ? 'rotate(0)' : 'rotate(180deg)'}
            transition="transform 0.2s ease-in-out"
            width="40px"
          >
            <Icon
              color={neutral.primary}
              name={FAIconName.chevronDownRegular}
              size="lg"
            />
          </ChakraFlex>
        </ChakraFlex>

        <ChakraCollapse in={!isCollapsed}>
          <PastEventList
            isExpanded={!isCollapsed}
            selectedUserId={selectedUserId}
          />
        </ChakraCollapse>
      </ChakraBox>
    </ChakraBox>
  );
};

export default PastEvents;
