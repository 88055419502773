import { useCallback, useRef, useState } from 'react';

import * as Sentry from '@sentry/react';

import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { PATIENT_TAGS } from '~/lib/constants/user.constants';
import useApi from '~/lib/hooks/useApi';

const { getTags: getTagsUrl } = urlConstants.users;

export type UsePatientTagsState = {
  isLoading?: boolean;
  isPatientTagsFetched?: boolean;
  patientsWithTreatmentTag?: string[];
  fetchPatientTags?: (userProfile: UserProfile) => void;
};

const usePatientTags = (): UsePatientTagsState => {
  const patientIdQueueRef = useRef<string[]>([]);
  const patientTagsMapperRef = useRef<Record<string, string[]>>({});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientsWithTreatmentTag, setPatientsWithTreatmentTag] = useState<
    string[]
  >([]);
  const [isPatientTagsFetched, setIsPatientTagsFetched] =
    useState<boolean>(false);

  const { error: getTagsInfoError, sendRequest: getTagsRequest } =
    useApi<PatientTagsResponse>();

  const processNextPatient = useCallback(() => {
    if (patientIdQueueRef.current.length === 0) {
      updatePatientsWithTreatmentTag();
      setIsPatientTagsFetched(true);
      setIsLoading(false);
      return;
    }

    const patientId = patientIdQueueRef.current[0];

    getTagsRequest({
      command: ApiCommand.GET,
      url: getTagsUrl(patientId),

      callback: (result, statusCode) => {
        if (statusCode === 200 && result.status === 'OK') {
          patientTagsMapperRef.current = {
            ...patientTagsMapperRef.current,
            [patientId]: result.data,
          };

          patientIdQueueRef.current = patientIdQueueRef.current.slice(1); // Remove the first patient ID from the queue
          processNextPatient();
        } else if (getTagsInfoError) {
          Sentry.captureException(getTagsInfoError);
        }
      },
    });
  }, []);

  const fetchPatientTags = (userProfile: UserProfile) => {
    if (userProfile.isPatient) {
      return;
    }
    patientIdQueueRef.current = userProfile?.linkedPatients?.map(
      (patient) => patient.externalId,
    );
    setIsLoading(true);
    processNextPatient();
  };

  const updatePatientsWithTreatmentTag = () => {
    const patientIds = Object.keys(patientTagsMapperRef.current).filter(
      (patientId) => {
        return patientTagsMapperRef.current[patientId].includes(
          PATIENT_TAGS.EquipAppTreatment,
        );
      },
    );

    setPatientsWithTreatmentTag(patientIds);
  };

  return {
    fetchPatientTags,
    isLoading,
    isPatientTagsFetched,
    patientsWithTreatmentTag,
  };
};

export default usePatientTags;
