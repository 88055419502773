import { getIsAdultProfile } from './profile.util';
import ProfileField from './profileFields.util';

export type PermissionStateType = Record<ProfileField, FieldPermissionType>;

export enum ProfilePermissions {
  SUPPORT_PROFILE = 'SUPPORT_PROFILE',
  PATIENT_PROFILE_AS_PROXY_SUPPORT = 'PATIENT_AS_PROXY_SUPPORT',
  ADULT_PATIENT_PROFILE = 'ADULT_PATIENT_PROFILE',
  MINOR_PATIENT_PROFILE = 'MINOR_PATIENT_PROFILE',
}

const SUPPORT_PROFILE_PERMISSIONS: PermissionStateType = {
  [ProfileField.FIRST_NAME]: { isEditable: false, isViewable: true },
  [ProfileField.LAST_NAME]: { isEditable: false, isViewable: true },
  [ProfileField.CHOSEN_NAME]: { isEditable: true, isViewable: true },
  [ProfileField.GENDER]: { isEditable: true, isViewable: true },
  [ProfileField.PRONOUNS]: { isEditable: true, isViewable: true },
  [ProfileField.LANGUAGES]: { isEditable: true, isViewable: true },
  [ProfileField.DATE_OF_BIRTH]: { isEditable: false, isViewable: false },
  [ProfileField.RELATIONSHIP_TO_PATIENT]: {
    isEditable: false,
    isViewable: true,
  },
  [ProfileField.SUPPORTED_BY]: { isEditable: false, isViewable: false },
  [ProfileField.ADDRESS]: { isEditable: true, isViewable: true },
  [ProfileField.EMAIL]: { isEditable: false, isViewable: true },
  [ProfileField.CELLPHONE]: { isEditable: true, isViewable: true },
  [ProfileField.TIMEZONE]: { isEditable: true, isViewable: true },
  [ProfileField.CONTACT_PREFERENCES]: { isEditable: true, isViewable: true },
  [ProfileField.NOTIFICATION]: { isEditable: true, isViewable: true },
};

const PATIENT_PERMISSIONS_AS_PROXY_SUPPORT: PermissionStateType = {
  [ProfileField.FIRST_NAME]: { isEditable: false, isViewable: true },
  [ProfileField.LAST_NAME]: { isEditable: false, isViewable: true },
  [ProfileField.CHOSEN_NAME]: { isEditable: true, isViewable: true },
  [ProfileField.GENDER]: { isEditable: false, isViewable: true },
  [ProfileField.PRONOUNS]: { isEditable: false, isViewable: true },
  [ProfileField.LANGUAGES]: { isEditable: true, isViewable: true },
  [ProfileField.DATE_OF_BIRTH]: { isEditable: true, isViewable: true },
  [ProfileField.RELATIONSHIP_TO_PATIENT]: {
    isEditable: false,
    isViewable: true,
  },
  [ProfileField.SUPPORTED_BY]: { isEditable: false, isViewable: false },
  [ProfileField.ADDRESS]: { isEditable: true, isViewable: true },
  [ProfileField.EMAIL]: { isEditable: false, isViewable: true },
  [ProfileField.CELLPHONE]: { isEditable: true, isViewable: true },
  [ProfileField.TIMEZONE]: { isEditable: true, isViewable: true },
  [ProfileField.CONTACT_PREFERENCES]: { isEditable: true, isViewable: true },
  [ProfileField.NOTIFICATION]: { isEditable: false, isViewable: false },
};

const ADULT_PATIENT_PERMISSIONS: PermissionStateType = {
  [ProfileField.FIRST_NAME]: { isEditable: false, isViewable: true },
  [ProfileField.LAST_NAME]: { isEditable: false, isViewable: true },
  [ProfileField.CHOSEN_NAME]: { isEditable: true, isViewable: true },
  [ProfileField.GENDER]: { isEditable: false, isViewable: true },
  [ProfileField.PRONOUNS]: { isEditable: false, isViewable: true },
  [ProfileField.LANGUAGES]: { isEditable: true, isViewable: true },
  [ProfileField.DATE_OF_BIRTH]: { isEditable: true, isViewable: true },
  [ProfileField.RELATIONSHIP_TO_PATIENT]: {
    isEditable: false,
    isViewable: false,
  },
  [ProfileField.SUPPORTED_BY]: { isEditable: false, isViewable: true },
  [ProfileField.ADDRESS]: { isEditable: true, isViewable: true },
  [ProfileField.EMAIL]: { isEditable: false, isViewable: true },
  [ProfileField.CELLPHONE]: { isEditable: true, isViewable: true },
  [ProfileField.TIMEZONE]: { isEditable: true, isViewable: true },
  [ProfileField.CONTACT_PREFERENCES]: { isEditable: true, isViewable: true },
  [ProfileField.NOTIFICATION]: { isEditable: true, isViewable: true },
};

const MINOR_PATIENT_PERMISSIONS: PermissionStateType = {
  [ProfileField.FIRST_NAME]: { isEditable: false, isViewable: true },
  [ProfileField.LAST_NAME]: { isEditable: false, isViewable: true },
  [ProfileField.CHOSEN_NAME]: { isEditable: true, isViewable: true },
  [ProfileField.GENDER]: { isEditable: false, isViewable: true },
  [ProfileField.PRONOUNS]: { isEditable: false, isViewable: true },
  [ProfileField.LANGUAGES]: { isEditable: false, isViewable: false },
  [ProfileField.DATE_OF_BIRTH]: { isEditable: false, isViewable: true },
  [ProfileField.RELATIONSHIP_TO_PATIENT]: {
    isEditable: false,
    isViewable: false,
  },
  [ProfileField.SUPPORTED_BY]: { isEditable: false, isViewable: true },
  [ProfileField.ADDRESS]: { isEditable: false, isViewable: false },
  [ProfileField.EMAIL]: { isEditable: false, isViewable: false },
  [ProfileField.CELLPHONE]: { isEditable: false, isViewable: false },
  [ProfileField.TIMEZONE]: { isEditable: false, isViewable: false },
  [ProfileField.CONTACT_PREFERENCES]: { isEditable: false, isViewable: false },
  [ProfileField.NOTIFICATION]: { isEditable: true, isViewable: true },
};

export const PROFILE_PERMISSION_STATES: Record<
  ProfilePermissions,
  PermissionStateType
> = {
  [ProfilePermissions.ADULT_PATIENT_PROFILE]: ADULT_PATIENT_PERMISSIONS,
  [ProfilePermissions.MINOR_PATIENT_PROFILE]: MINOR_PATIENT_PERMISSIONS,
  [ProfilePermissions.PATIENT_PROFILE_AS_PROXY_SUPPORT]:
    PATIENT_PERMISSIONS_AS_PROXY_SUPPORT,
  [ProfilePermissions.SUPPORT_PROFILE]: SUPPORT_PROFILE_PERMISSIONS,
};

export const getPermissionsForProfile = (
  userProfile: UserProfile,
): ProfilePermissions => {
  if (!userProfile) {
    return null;
  }

  if (!userProfile.isPatient) {
    return ProfilePermissions.SUPPORT_PROFILE;
  }

  if (userProfile.isProxy) {
    return ProfilePermissions.PATIENT_PROFILE_AS_PROXY_SUPPORT;
  }

  if (getIsAdultProfile(userProfile)) {
    return ProfilePermissions.ADULT_PATIENT_PROFILE;
  }

  return ProfilePermissions.MINOR_PATIENT_PROFILE;
};
