import { jsPDF as JSPDF } from 'jspdf';

import logo from '~/assets/img/logo.png';
import {
  QuestionNameConstant,
  StaticImportantPhoneNumbers,
  StaticLinkDetails,
} from '~/lib/constants/safetyplan.constants';
import { parseTelephone } from '~/lib/util/survey.util';

const fontFace = 'helvetica';
const fontSize = 10;
const pageHeight = 297; // standard height of a4 document in millimeters standard width of a4 document in millimeters 210
const margin = 16;
const ptsPerInch = 12;
const maxLineWidth = 50;
const maxLineWidthAnswer = 130;
const innerQuestionMargin = 12;
const answerPositionX = 70;
const pdfOffsets = {
  completed: { x: 120, y: 25 },
  logo: { x: 0, y: 0 },
  patientName: { x: 0, y: 25 },
  resultsContainer: { x: 0, y: 40 },
};
const color = {
  answer: '#1A1A1A',
  phone: '#007F79',
  question: '#666666',
};
const questionPositionX = margin + pdfOffsets.resultsContainer.x;
let questionPositionY = pdfOffsets.resultsContainer.y;
type GeneratePDFParams = {
  endDate: string;
  jsonResult: Record<string, any>;
  patientName: string;
  surveyDefinition: SurveyDefinition;
  surveyName: string;
};

const checkObject = (result: Record<string, any>): boolean => {
  return (
    typeof result === 'object' && !Array.isArray(result) && result !== null
  );
};
const addPageIfRequired = (pdf: JSPDF): void => {
  if (questionPositionY + 15 > pageHeight) {
    pdf.addPage();
    questionPositionY = 20;
  }
};
const addInPDF = (
  resultString: string,
  questionPositionX: number,
  lineWidth: number,
  color: string,
  isQuestion: boolean,
  pdf: JSPDF,
  isBullet: boolean,
): void => {
  const lineHeight = pdf.getLineHeight();
  pdf.setTextColor(color);
  addPageIfRequired(pdf);
  let breakLineIfRequired = [];
  if (isBullet) {
    breakLineIfRequired = pdf.splitTextToSize(
      `\u2022 ${resultString}`,
      lineWidth,
    );
  } else {
    breakLineIfRequired = pdf.splitTextToSize(`${resultString}`, lineWidth);
  }
  if (!isQuestion) {
    breakLineIfRequired.forEach((line) => {
      pdf.text(line, questionPositionX, questionPositionY, {
        lineHeightFactor: 2,
      });
      questionPositionY += (fontSize * lineHeight) / ptsPerInch;
      addPageIfRequired(pdf);
    });
  } else {
    pdf.text(breakLineIfRequired, questionPositionX, questionPositionY, {
      lineHeightFactor: 2,
    });
  }
};
const handleArrayResponse = (
  pdf: JSPDF,
  questionName: string,
  result: Record<string, any>,
) => {
  // eslint-disable-next-line consistent-return
  result.forEach((elem) => {
    if (Object.keys(elem).length === 0) return null;
    let resultString = '';
    if (questionName === QuestionNameConstant.sp_names_phones_supports) {
      let firstString = '';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(elem).forEach(([key, value], i) => {
        if (i % 2) {
          addInPDF(
            ` - ${parseTelephone(value.toString())}`,
            answerPositionX + pdf.getTextWidth(firstString) + 2,
            maxLineWidthAnswer,
            color.phone,
            false,
            pdf,
            false,
          );
        } else {
          firstString = `${value}`;
          addInPDF(
            firstString,
            answerPositionX,
            maxLineWidthAnswer,
            color.answer,
            true,
            pdf,
            true,
          );
        }
        if (Object.entries(elem).length === 1) {
          questionPositionY += 8;
        }
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(elem).forEach(([key, value], i) => {
        if (i % 2) {
          resultString += ` - ${value}`;
        } else resultString += value;
      });
      addInPDF(
        resultString,
        answerPositionX,
        maxLineWidthAnswer,
        color.answer,
        false,
        pdf,
        true,
      );
    }
  });
};

const getAnswer = (
  questionName: string,
  result: Record<string, any>,
  pdf: JSPDF,
): void => {
  const isQuestion = false;
  if (questionName === QuestionNameConstant.sp_imminent_risk_statement) {
    const staticString = `\u2022 Call`;
    pdf.setTextColor(color.answer);
    pdf.text(staticString, answerPositionX, questionPositionY);
    pdf.setTextColor(color.phone);
    pdf.text(
      '911',
      answerPositionX + pdf.getTextWidth(staticString) + 2,
      questionPositionY,
    );
    pdf.setTextColor(color.answer);
    questionPositionY += 10;
    pdf.text(
      '\u2022 Go to the nearest emergency room',
      answerPositionX,
      questionPositionY,
    );
    return;
  }
  if (Array.isArray(result)) {
    handleArrayResponse(pdf, questionName, result);
    return;
  }
  if (checkObject(result)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(result).forEach(([_, value]) => {
      addInPDF(
        value,
        answerPositionX,
        maxLineWidthAnswer,
        color.answer,
        isQuestion,
        pdf,
        true,
      );
    });
    return;
  }
  addInPDF(
    `${
      questionName === QuestionNameConstant.sp_local_ems
        ? parseTelephone(result.toString())
        : result
    }`,
    answerPositionX,
    maxLineWidthAnswer,
    questionName === QuestionNameConstant.sp_local_ems
      ? color.phone
      : color.answer,
    isQuestion,
    pdf,
    false,
  );
};

const createStaticSection = (pdf: JSPDF): void => {
  addPageIfRequired(pdf);
  questionPositionY += innerQuestionMargin;
  pdf.setFont(fontFace, 'bold');
  pdf.text(
    `Additional helpful information`,
    questionPositionX,
    questionPositionY,
  );
  pdf.setFont(fontFace, 'normal');
  questionPositionY += innerQuestionMargin;
  addPageIfRequired(pdf);
  addInPDF(
    'Hotlines',
    questionPositionX,
    maxLineWidth,
    color.question,
    true,
    pdf,
    false,
  );
  StaticImportantPhoneNumbers.forEach((elem: StaticImportantPhoneNumber) => {
    const initialString = `\u2022 ${elem.title} -`;
    addPageIfRequired(pdf);
    addInPDF(
      initialString,
      answerPositionX,
      maxLineWidthAnswer,
      color.answer,
      true,
      pdf,
      false,
    );
    addInPDF(
      `${elem.phone}`,
      answerPositionX + pdf.getTextWidth(initialString) + 2,
      maxLineWidthAnswer,
      color.phone,
      false,
      pdf,
      false,
    );
  });
  questionPositionY += innerQuestionMargin;

  StaticLinkDetails.forEach((elem: StaticLink) => {
    addInPDF(
      `${elem.label} `,
      questionPositionX,
      maxLineWidth,
      color.question,
      true,
      pdf,
      false,
    );
    if (elem.subLabel) {
      addInPDF(
        `${elem.subLabel} `,
        answerPositionX,
        maxLineWidthAnswer,
        color.answer,
        false,
        pdf,
        false,
      );
    }
    pdf.setTextColor(color.phone);
    pdf.textWithLink(`${elem.link}`, answerPositionX, questionPositionY, {
      url: `${elem.link}`,
    });
    questionPositionY += innerQuestionMargin;
  });
};

const checkEligibleQuestion = (question: string): boolean => {
  return (
    question !== QuestionNameConstant.sp_risk_level &&
    question !== QuestionNameConstant.sp_resources
  );
};

const generatePDF = ({
  endDate,
  jsonResult,
  patientName,
  surveyDefinition,
  surveyName,
}: GeneratePDFParams): void => {
  const pdf = new JSPDF();
  pdf.setFont(fontFace, 'normal');
  pdf.setFontSize(fontSize);
  pdf.setLineHeightFactor(0.8);
  // Logo
  pdf.addImage(
    logo,
    'JPEG',
    margin + pdfOffsets.logo.x,
    margin + pdfOffsets.logo.y,
    0,
    0,
    'Equip logo',
  );

  pdf.setFont(fontFace, 'bold');
  pdf.setFontSize(14);
  // Patient name
  pdf.text(
    `${patientName}'s ${surveyName}`,
    margin + pdfOffsets.patientName.x,
    margin + pdfOffsets.patientName.y,
  );

  pdf.setFont(fontFace, 'normal');
  pdf.setFontSize(fontSize);
  pdf.setTextColor(color.question);
  // Completed date
  pdf.text(
    !endDate ? '' : `Completed on: ${endDate}`,
    margin + pdfOffsets.completed.x,
    margin + pdfOffsets.completed.y,
  );

  const isLowRisk =
    jsonResult[QuestionNameConstant.sp_risk_level]?.toLowerCase() === 'low';
  if (isLowRisk) {
    questionPositionY += innerQuestionMargin * 2;
    addInPDF(
      'Local EMS:',
      questionPositionX,
      maxLineWidth,
      color.question,
      true,
      pdf,
      false,
    );
    getAnswer(
      QuestionNameConstant.sp_local_ems,
      jsonResult[QuestionNameConstant.sp_local_ems],
      pdf,
    );
  } else {
    surveyDefinition.pages.forEach((page) => {
      page.elements.forEach((question) => {
        if (checkEligibleQuestion(question.name)) {
          questionPositionY += innerQuestionMargin;
          if (
            question.name === QuestionNameConstant.sp_imminent_risk_statement
          ) {
            addInPDF(
              'If you are at risk of hurting yourself: ',
              questionPositionX,
              maxLineWidth,
              color.question,
              true,
              pdf,
              false,
            );
          } else {
            addInPDF(
              question.title,
              questionPositionX,
              maxLineWidth,
              color.question,
              true,
              pdf,
              false,
            );
          }
          getAnswer(question.name, jsonResult[question.name], pdf);
        }
      });
    });
    createStaticSection(pdf);
  }
  questionPositionY = pdfOffsets.resultsContainer.y;
  pdf.save(`${patientName}_${surveyName}`);
};
export default generatePDF;
