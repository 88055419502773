import { FC, useEffect, useMemo, useRef, useState } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraSimpleGrid,
  ChakraText,
  EquipUIFireflyV1Theme,
  TextInput,
  getAgeFromDob,
} from '@equip.health/ui';
import * as Sentry from '@sentry/react';
import { useCookies } from 'react-cookie';
import ReCAPTCHA from 'react-google-recaptcha';

import FormBackButton from '~/components/common/FormBackButton';
import FormButtonBar from '~/components/common/FormButtonBar';
import HearAboutEquipInput from '~/components/common/HearAboutEquipInput';
import PrivacyPolicyNotice from '~/components/common/PrivacyPolicyNotice';
import { ApiCommand } from '~/lib/Api';
import {
  GAEventForInquiry,
  InquirerRole,
  InquiryChannelName,
  InquiryMode,
} from '~/lib/constants';
import { DateFormat } from '~/lib/constants/dateFormat.constants';
import {
  InquiryStep,
  OTHER_PAYOR,
  textInputLabelProps,
} from '~/lib/constants/inquiry.constants';
import { FormType } from '~/lib/constants/referral.constants';
import urlConstants from '~/lib/constants/url.constants';
import {
  Enumerations,
  useEnumerationContext,
} from '~/lib/context/EnumerationContext';
import useApi from '~/lib/hooks/useApi';
import useFeatureFlags from '~/lib/hooks/useFeatureFlags';
import { isDevEnvironment } from '~/lib/util/EnvUtils';
import {
  generateCoverages,
  generateUtmParams,
  getCurrentTimezoneCode,
  getRelationToPatient,
} from '~/lib/util/inquiries.util';
import {
  getDateOfBirth,
  getErrorMessage,
  isValidAddressRequiredFields,
  isValidAffiliation,
} from '~/lib/util/inquiry.util';
import GAEventsTracker from '~/lib/util/useGAEventsTracker.util';
const GOOGLE_RECAPTCHA_KEY = import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY;
const { bodySemibold, captionSemibold, paragraph } =
  EquipUIFireflyV1Theme.typography;
const { black } = EquipUIFireflyV1Theme.colors;

type secondaryText = {
  label?: string;
  text: string;
};
type SummaryInfoProps = {
  label: string;
  paddingLeft?: number | string;
  marginTop?: number | string;
  primaryText?: string;
  secondaryText: secondaryText[];
};

const SummaryInfo: FC<SummaryInfoProps> = ({
  label,
  marginTop = '0',
  paddingLeft = '0',
  primaryText,
  secondaryText,
}) => (
  <ChakraBox
    id="summary-information"
    marginTop={marginTop}
    paddingLeft={paddingLeft}
  >
    <ChakraText
      {...captionSemibold}
      color={black[70]}
      id="summary-info-label"
      marginBottom="16px"
    >
      {label}
    </ChakraText>
    {primaryText && (
      <ChakraText
        {...captionSemibold}
        color="mono.70"
        fontSize="16px"
        id="summary-primary-text"
        label={primaryText}
        marginTop="4px"
      >
        {primaryText}
      </ChakraText>
    )}
    {secondaryText.map((state: secondaryText) => (
      <ChakraFlex key={state.text}>
        {state.label && (
          <ChakraText
            {...paragraph}
            color="mono.70"
            id="summary-state-label"
            marginTop="4px"
          >
            {state.label}&nbsp;
          </ChakraText>
        )}
        <ChakraText
          {...paragraph}
          color="mono.70"
          id="summary-state-value"
          marginTop="4px"
        >
          {state.text}
        </ChakraText>
      </ChakraFlex>
    ))}
  </ChakraBox>
);

type InquiryFormStepThreeProps = {
  onStepChangeMount: (
    currentStep: InquiryStep,
    saveAppointmentRequest?: InquiryAppointmentDetails,
  ) => void;
  inquiryData: InquiryFormType;
};
const TOP_LEVEL_ID = 'inquiry-step-three';

const InquiryFormStepThree: FC<InquiryFormStepThreeProps> = ({
  onStepChangeMount,
  inquiryData,
}: InquiryFormStepThreeProps) => {
  const {
    additionalNotes,
    customPayorName,
    dateOfBirth,
    email,
    firstName,
    hearAboutEquip,
    informationSource,
    inquiryAbout,
    isPayorInNetwork,
    isReferrerDetailsRequired,
    lastName,
    patientAge,
    patientFirstName,
    patientLastName,
    patientState,
    payor,
    payorExternalId,
    phoneNumber,
    referrerAffiliation,
    referrerAffiliationNA,
    referrerFirstName,
    referrerLastName,
    relation,
    tellUsMore,
  }: InquiryForm = inquiryData.value;
  const { equipInquirySources, fetchEnumerations, timezonesRaw } =
    useEnumerationContext();
  const [isConsentCheckboxSelected, setIsConsentCheckboxSelected] =
    useState<boolean>(!isDevEnvironment());
  const { isInquiryV2Enabled } = useFeatureFlags();
  const { error, loading, sendRequest, statusCode, data } = useApi<
    SaveInquiryResponseV1 | string
  >();
  // TODO: V1 response is an object, V2 response is a string, so we are handling both temporarily

  const [cookies] = useCookies();
  const utmParams = cookies.EQUIP;

  const recaptchaRef = useRef(null);
  useEffect(() => {
    fetchEnumerations([
      Enumerations.EQUIP_INQUIRY_SOURCES,
      Enumerations.STATES,
      Enumerations.TIMEZONES_WITHOUT_TOKENS,
    ]);
  }, []);
  const inquiryValueForMySelf = inquiryAbout === InquiryMode.MYSELF;
  const getTimezone = (): string | undefined => {
    const selectedTimezone = timezonesRaw?.find(
      (p) => p.id === getCurrentTimezoneCode(),
    );
    return selectedTimezone?.label;
  };

  const handleFieldChange = (
    key: keyof InquiryForm,
    value: string | ReferrerAffiliation | boolean,
  ) => {
    inquiryData.setter({
      ...inquiryData.value,
      [key]: value,
    });
  };
  const isValidAddress = useMemo<boolean>(
    () =>
      isValidAffiliation(referrerAffiliationNA, referrerAffiliation) &&
      (referrerAffiliationNA ||
        isValidAddressRequiredFields(referrerAffiliation, [
          'addressLine1',
          'city',
          'name',
          'state',
          'zipCode',
        ])),
    [referrerAffiliation, referrerAffiliationNA],
  );

  const handleConfirm = (googleRecaptchaToken: string): void => {
    const options: Record<string, ApiOptionsValue> = {
      contactPatientDirectly: '',
      equipSourceExternalId: hearAboutEquip,
      informationSource,
      inquirerAbout: inquiryAbout,
      inquirerEmail: inquiryValueForMySelf ? '' : email,
      inquirerFirstName: inquiryValueForMySelf ? '' : firstName,
      inquirerLastName: inquiryValueForMySelf ? '' : lastName,
      inquirerPhone: inquiryValueForMySelf ? '' : phoneNumber,
      isPayorInNetwork,
      notes: additionalNotes,
      patientAge: patientAge ?? getAgeFromDob(dateOfBirth),
      patientBirthDate: getDateOfBirth(dateOfBirth),
      patientEmail: inquiryValueForMySelf ? email : '',
      patientFirstName: inquiryValueForMySelf ? firstName : patientFirstName,
      patientLastName: inquiryValueForMySelf ? lastName : patientLastName,
      patientPhone: inquiryValueForMySelf ? phoneNumber : '',
      patientState,
      payor,
      payorExternalId,
      referrerAffiliation:
        isReferrerDetailsRequired && !referrerAffiliationNA
          ? {
              ...referrerAffiliation,
            }
          : null,

      referrerFirstName: isReferrerDetailsRequired ? referrerFirstName : '',
      referrerLastName: isReferrerDetailsRequired ? referrerLastName : '',
      relationWithPatient: inquiryValueForMySelf ? '' : relation,
      tellUsMore,
      ...generateUtmParams(utmParams),
    };

    sendRequest({
      command: ApiCommand.POST,
      googleRecaptchaToken,
      options,
      skipToken: true,
      url: urlConstants.inquiry_referral.addInquiry,
    });
  };

  const handleConfirmV2 = (googleRecaptchaToken: string): void => {
    const patientContacts = [];
    if (isReferrerDetailsRequired) {
      const referralRecords = {
        firstName: referrerFirstName,
        lastName: referrerLastName,
        isReferringProvider: true,
        isPrimaryContact: false,
        referrerAffiliation: !referrerAffiliationNA
          ? {
              ...referrerAffiliation,
            }
          : null,
        relationToPatient: getRelationToPatient(
          hearAboutEquip,
          equipInquirySources,
        ),
      };
      patientContacts.push(referralRecords);
    }
    if (!inquiryValueForMySelf) {
      patientContacts.push({
        cellPhone: phoneNumber,
        email,
        firstName,
        isPrimaryContact: true,
        isReferringProvider: false,
        lastName,
        relationToPatient: relation,
        timezone: getTimezone(),
      });
    }

    const options: Record<string, ApiOptionsValue> = {
      channelName: InquiryChannelName.Direct,
      coverages: generateCoverages(payor, customPayorName),
      equipSourceId: hearAboutEquip,
      inquirerRole: inquiryValueForMySelf
        ? InquirerRole.PATIENT
        : InquirerRole.LOVED_ONE,
      inquiryNotes: additionalNotes,
      patient: {
        dateOfBirth: getDateOfBirth(dateOfBirth, DateFormat.yearMonthDay),
        email: inquiryValueForMySelf ? email : '',
        firstName: inquiryValueForMySelf ? firstName : patientFirstName,
        isPrimaryContact: inquiryValueForMySelf,
        lastName: inquiryValueForMySelf ? lastName : patientLastName,
        phoneNumber: inquiryValueForMySelf ? phoneNumber : '',
        state: patientState,
      },
      patientAge: patientAge ?? getAgeFromDob(dateOfBirth),
      patientContacts,
      tellUsMore,
      ...generateUtmParams(utmParams),
    };

    sendRequest({
      command: ApiCommand.POST,
      googleRecaptchaToken,
      options,
      skipToken: true,
      url: urlConstants.inquiry_referral.addInquiryV2,
    });
  };

  const handleSubmit = (googleRecaptchaToken: string) => {
    if (isInquiryV2Enabled) {
      handleConfirmV2(googleRecaptchaToken);
    } else {
      handleConfirm(googleRecaptchaToken);
    }
  };

  const executeRecaptcha = () => {
    recaptchaRef.current?.execute();
  };

  useEffect(() => {
    // Returns 200 in v1 endpoint
    // Returns 201 in v2 endpoint
    if (!loading && !error && (statusCode === 200 || statusCode === 201)) {
      // TODO: Use V2 response only
      const inquiryExternalId =
        typeof data === 'string' ? data : data.inquiryExternalId;
      const taskExternalId =
        typeof data == 'string' ? null : data.taskExternalId;

      window.dataLayer?.push({
        inquiryExternalId,
        taskExternalId,
      });

      GAEventsTracker(GAEventForInquiry);

      /*
        Explicitly checking for isPayorInNetwork === false because we want to show
        out of network message ONLY if user selected a payor that is not in network.
        Since this field is not required we should not use !isPayorInNetwork because
        a null / undefined / empty string value should not be considered as out of network.
      */
      if (isPayorInNetwork === false) {
        onStepChangeMount(InquiryStep.OUT_OF_NETWORK);
        return;
      }

      onStepChangeMount(InquiryStep.SCHEDULE_APPOINTMENT, {
        email,
        inquiryExternalId,
        patientName: inquiryValueForMySelf
          ? `${firstName} ${lastName}`
          : `${patientFirstName} ${patientLastName}`,
        phone: phoneNumber,
        taskExternalId,
      });
    }
  }, [error, loading, statusCode]);

  useEffect(() => {
    if (error) {
      Sentry.captureException(
        `Form Error - ${getErrorMessage(FormType.INQUIRY)}`,
      );
    }
  }, [error]);

  /** @TODO we can validation for more form elements here in future  */
  const isInvalidInquiry = useMemo<boolean>(
    () =>
      !hearAboutEquip ||
      !informationSource ||
      (isReferrerDetailsRequired &&
        !(
          referrerFirstName?.trim() &&
          referrerLastName?.trim() &&
          isValidAddress
        )),
    [
      hearAboutEquip,
      informationSource,
      isReferrerDetailsRequired,
      isValidAddress,
      referrerFirstName,
      referrerLastName,
    ],
  );
  return (
    <ChakraBox>
      <FormBackButton
        {...captionSemibold}
        id={TOP_LEVEL_ID}
        onClickBack={() => onStepChangeMount(InquiryStep.CONTACT_INFORMATION)}
      />
      <ChakraText
        {...bodySemibold}
        color="mono.70"
        id={`${TOP_LEVEL_ID}__review-information`}
        marginLeft="14px"
        paddingBottom="8px"
      >
        Review your information
      </ChakraText>
      <ChakraBox
        background="rgba(0, 0, 0, 0.03)"
        borderRadius="20px"
        id={`${TOP_LEVEL_ID}__review-information-content`}
        padding="20px"
        width="100%"
      >
        <ChakraSimpleGrid
          columns={2}
          display={{ sm: 'grid' }}
          padding="0 5px"
          spacing="16px"
        >
          <ChakraBox gap="2" id="inquiry-patient-information">
            <SummaryInfo
              label="Inquirer information"
              secondaryText={[
                { text: `${firstName} ${lastName}` },
                ...(!inquiryValueForMySelf ? [{ text: relation }] : []),
              ]}
            />
            {!inquiryValueForMySelf && (
              <SummaryInfo
                label="Patient information"
                marginTop="16px"
                secondaryText={[
                  { text: `${patientFirstName} ${patientLastName}` },
                  ...(dateOfBirth ? [{ text: `DOB: ${dateOfBirth}` }] : []),
                  { text: `${patientAge} years old` },
                  { text: patientState },
                  ...(payor ? [{ text: payor }] : []),
                  { text: payor === OTHER_PAYOR ? customPayorName : undefined },
                ]}
              />
            )}
          </ChakraBox>

          <SummaryInfo
            label="Contact information"
            secondaryText={[
              ...(phoneNumber ? [{ label: 'Mobile:', text: phoneNumber }] : []),
              ...(email ? [{ label: 'Email:', text: email }] : []),
            ]}
          />
        </ChakraSimpleGrid>
      </ChakraBox>
      <HearAboutEquipInput
        hearAboutValue={hearAboutEquip}
        id={TOP_LEVEL_ID}
        isInquiry
        isReferrerDetailsRequired={isReferrerDetailsRequired}
        isValidAddress={isValidAddress}
        onHearAboutEquipFormChange={handleFieldChange}
        referrerAffiliation={referrerAffiliation}
        referrerAffiliationNA={referrerAffiliationNA}
        referrerFirstName={referrerFirstName}
        referrerLastName={referrerLastName}
        tellUsMoreValue={tellUsMore}
      />
      <ChakraBox paddingBottom="10px" paddingTop="20px">
        <TextInput
          {...textInputLabelProps}
          fontSize="14px"
          id={`${TOP_LEVEL_ID}__additional-notes`}
          label="Anything else you'd like to tell us? (optional)"
          onChange={(e) => {
            handleFieldChange('additionalNotes', e.target.value);
          }}
          value={additionalNotes}
        />
      </ChakraBox>

      {isDevEnvironment() && (
        <PrivacyPolicyNotice
          isConsentCheckboxSelected={isConsentCheckboxSelected}
          setIsConsentCheckboxSelected={(value) =>
            setIsConsentCheckboxSelected(value)
          }
        />
      )}
      <FormButtonBar
        {...captionSemibold}
        id={TOP_LEVEL_ID}
        isLoading={loading}
        isProceedButtonDisabled={isInvalidInquiry || !isConsentCheckboxSelected}
        isRightIcon={false}
        onClickProceed={executeRecaptcha}
        proceedButtonText="Send"
      />
      {!isDevEnvironment() && <PrivacyPolicyNotice />}
      {error && (
        <ChakraText
          {...paragraph}
          color="error"
          data-testid={`${TOP_LEVEL_ID}__save-error`}
          paddingTop="8px"
        >
          {getErrorMessage(FormType.INQUIRY)}
        </ChakraText>
      )}
      <ReCAPTCHA
        onChange={handleSubmit}
        ref={recaptchaRef}
        sitekey={GOOGLE_RECAPTCHA_KEY}
        size="invisible"
      />
    </ChakraBox>
  );
};

export default InquiryFormStepThree;
