import React, { SetStateAction, useMemo } from 'react';

import {
  Avatar,
  ChakraBox,
  ChakraFlex,
  Divider,
  EquipUIFireflyV1Theme,
  FAIconName,
  Icon,
  Text,
} from '@equip.health/ui';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { UserMessage } from '@sendbird/chat/message';
import * as Sentry from '@sentry/react';

import {
  EMPTY_CHAT_VIEW,
  EMPTY_MESSAGES_VIEW,
  EM_DASH,
  NAME_CONNECTOR,
  SPACE,
} from '~/lib/constants/chat/chat.constants';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';
import { ChatUser } from '~/lib/types/chat';
import { getMessagesChannelEventPayload } from '~/lib/util/analytics/messages.utils';
import {
  calculateChannelRelativeDate,
  getAdminMessageText,
  getUserTypeFromChatUser,
  isIndividualChannel,
} from '~/lib/util/chat/chat.utils';
import { getChannelName } from '~/lib/util/chat/chatChannel.util';
import { useChatSelector } from '~/store/hooks/chat';

import { isChatAttachmentsEnabled } from './ChatAttachments';
import ChatGroupChannelAvatar from './ChatGroupChannelAvatar';
import UnreadMessageIndicator from './UnreadMessageIndicator';

interface ChannelTileProps {
  channel: GroupChannel;
  id: string;
  isDividerVisible?: boolean;
  isPatient: boolean;
  isSelected: boolean;
  onClick?: React.Dispatch<SetStateAction<string>>;
  patient: ChatUser;
  isEmptyChat?: boolean;
}

const { black } = EquipUIFireflyV1Theme.colors;

const idPrefix = 'Channel';
const idPostfix = 'Tile';

const ChannelTile = React.forwardRef<HTMLDivElement, ChannelTileProps>(
  (
    {
      channel,
      id,
      isDividerVisible,
      isPatient,
      isEmptyChat,
      isSelected,
      onClick,
      patient,
    }: ChannelTileProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { lastMessage, members, url, unreadMessageCount } = channel ?? {};
    const { track } = useAnalytics();
    const { isMessagesLoading } = useChatSelector();
    const channelName = getChannelName(channel, patient, isPatient);

    const isSmallBreakpoint = useMobileBreakpoint();

    const lastChannelMessage: UserMessage = lastMessage as UserMessage;

    const isLastMessageAttachment =
      isChatAttachmentsEnabled && lastMessage?.isFileMessage();

    const getLastMessageText = useMemo(() => {
      if (lastChannelMessage?.isAdminMessage()) {
        return getAdminMessageText(
          lastChannelMessage.message,
          lastChannelMessage.sender?.nickname ?? '',
        );
      }

      return isLastMessageAttachment
        ? '1 file'
        : lastChannelMessage?.message ??
            (isEmptyChat
              ? EMPTY_CHAT_VIEW.message
              : EMPTY_MESSAGES_VIEW.message);
    }, [lastChannelMessage]);

    const handleHover = () => {
      if (url) {
        track(
          'Chat Channel Highlighted',
          getMessagesChannelEventPayload(channelName, url),
        );
      }
    };

    const handleClick = () => {
      onClick?.(url);
      track(
        'Chat Channel Clicked',
        getMessagesChannelEventPayload(channelName, url),
      );
    };

    return (
      <>
        <ChakraFlex
          _hover={{
            backgroundColor: 'neutral.background.secondary',
          }}
          alignItems="start"
          backgroundColor={isSelected && !isSmallBreakpoint && 'secondary.10'}
          borderRadius="12px"
          cursor="pointer"
          gridGap="12px"
          id={`${idPrefix}-container__${id}__${idPostfix}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!isMessagesLoading) {
              handleClick();
            } else {
              Sentry.captureMessage(
                `Clicked channel while messages were still loading.`,
                {
                  level: 'warning',
                  tags: { channelUrl: url },
                },
              );
            }
          }}
          onMouseEnter={handleHover}
          padding="14px 12px"
          ref={ref}
          width="100%"
        >
          <ChakraBox
            alignItems="center"
            id={`${idPrefix}-avatar-container__${id}__${idPostfix}`}
          >
            {isIndividualChannel(channel?.url) ||
            (members ?? []).length === 0 ? (
              <Avatar
                id={`${idPrefix}-avatar__${id}__${idPostfix}`}
                name={channelName?.primaryName?.replace(NAME_CONNECTOR, SPACE)}
                userType="provider"
              />
            ) : (
              <ChatGroupChannelAvatar
                name1={members[1]?.nickname}
                name2={members[0]?.nickname}
                userType1={getUserTypeFromChatUser(members[1])}
                userType2={getUserTypeFromChatUser(members[0])}
              />
            )}
          </ChakraBox>
          <ChakraFlex
            alignItems="start"
            direction="column"
            id={`${idPrefix}-provider-container__${id}__${idPostfix}`}
            width="calc(100% - 52px)"
          >
            <ChakraFlex
              alignItems="center"
              gridGap="10px"
              justifyContent="center"
              marginBottom="6px"
            >
              {unreadMessageCount > 0 && <UnreadMessageIndicator id={id} />}
              <ChakraFlex alignItems="flex-start" flexShrink="0" gridGap="6px">
                {channel?.url && !isIndividualChannel(channel?.url) && (
                  <Icon name={FAIconName.userGroupSolid} size="sm" />
                )}
                <Text
                  color="black.90"
                  id={`${idPrefix}-primary-provider-name__${id}__${idPostfix}`}
                  variant="bodyMedium"
                >
                  {channelName?.primaryName}
                </Text>
              </ChakraFlex>
              {channelName?.secondaryName && (
                <>
                  <Text
                    color="black.60"
                    id={`${idPrefix}-secondary-provider-name__${id}__${idPostfix}`}
                    variant="captionMedium"
                  >
                    {EM_DASH}
                  </Text>
                  <Text
                    color="black.60"
                    id={`${idPrefix}-secondary-provider-name__${id}__${idPostfix}`}
                    noOfLines={1}
                    variant="captionMedium"
                  >
                    {channelName?.secondaryName}
                  </Text>
                </>
              )}
            </ChakraFlex>
            <ChakraFlex color="black.60" gridGap="6px" width="100%">
              {isLastMessageAttachment && (
                <Icon
                  color={black[60]}
                  name={FAIconName.fileRegular}
                  size="sm"
                />
              )}
              <Text
                flex={1}
                id={`${idPrefix}-provider-last-message__${id}__${idPostfix}`}
                overflow="hidden"
                textOverflow="ellipsis"
                variant="caption"
                whiteSpace="nowrap"
              >
                {getLastMessageText}
              </Text>
              <Text
                flex={0}
                id={`${idPrefix}-provider-last-message__${id}__${idPostfix}`}
                paddingLeft="6px"
                textAlign="right"
                textTransform="capitalize"
                variant="caption"
              >
                {lastMessage &&
                  calculateChannelRelativeDate(lastMessage?.createdAt)}
              </Text>
            </ChakraFlex>
          </ChakraFlex>
        </ChakraFlex>
        {isDividerVisible ? (
          <Divider id={`${idPrefix}-divider${id}__${idPostfix}`} />
        ) : null}
      </>
    );
  },
);

ChannelTile.displayName = 'ChannelTile';
export default ChannelTile;
