import { Text } from '@equip.health/ui';

interface SectionTitleProps {
  id: string;
  marginBottom?: string;
  marginTop?: string;
  title: string;
  type?: 'page' | 'section';
}

const SectionTitle = ({
  id,
  marginBottom,
  marginTop,
  title,
  type = 'section',
}: SectionTitleProps) => {
  return (
    <Text
      id={`${id}__title`}
      marginBottom={marginBottom}
      marginTop={marginTop}
      variant={type === 'page' ? 'h3' : 'h5'}
    >
      {title}
    </Text>
  );
};

export default SectionTitle;
