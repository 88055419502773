import { FC, ReactElement, createContext, useContext } from 'react';

import useTodoList, {
  TodoListState,
} from '../../components/todoList/useTodoList';

const TodoListContext = createContext<TodoListState | undefined>(undefined);

interface TodoListContextProviderProps {
  children: ReactElement | ReactElement[];
}

const TodoListContextProvider: FC<TodoListContextProviderProps> = ({
  children,
}: TodoListContextProviderProps) => {
  const value = useTodoList();

  return (
    <TodoListContext.Provider value={value}>
      {children}
    </TodoListContext.Provider>
  );
};

const useTodoListContext = (): TodoListState => {
  const context = useContext(TodoListContext);

  if (context === undefined) {
    throw new Error(
      'useTodoListContext must be used within a TodoListContextProvider',
    );
  }

  return context;
};

export { TodoListContextProvider, useTodoListContext };
