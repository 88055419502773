import { FC } from 'react';

const Icon: FC<BaseSVG> = ({ height = '36', width = '36' }) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 36 36"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#18874F" height="36" rx="8" width="36" />
    <path
      d="M9.95996 27L15.7842 17.9751L10.4478 9H14.7405L17.6527 14.7397C17.9215 15.285 18.1058 15.6898 18.2055 15.9571H18.2432C18.4346 15.5221 18.636 15.0997 18.8474 14.6898L21.9604 9.00303H25.9012L20.4288 17.9252L26.0402 27H21.8472L18.4834 20.6999C18.325 20.4319 18.1905 20.1503 18.0816 19.8586H18.0318C17.9333 20.1444 17.8025 20.418 17.6421 20.6743L14.1787 27H9.95996Z"
      fill="white"
    />
  </svg>
);

export default Icon;
