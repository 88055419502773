import {
  VITE_EQUIP_BEHAVIORAL_HEALTH_ADDRESS,
  VITE_EQUIP_WEBSITE_ADDRESS,
} from '~/lib/constants/env';

export const MAX_NAME_LENGTH = 60;

export const MAX_OFFICE_EXTENSION_LENGTH = 6;

export enum InquiryMode {
  SOMEONE_ELSE = 'someoneelse',
  MYSELF = 'myself',
}

export enum AUTH0ERROR {
  MISSING_REFRESH_TOKEN = 'missing_refresh_token',
  INVALID_GRANT = 'invalid_grant',
  LOGIN_REQUIRED = 'login_required',
}

export const InquiryChannelName: Record<
  EquipInquiryChannelName,
  EquipInquiryChannelName
> = {
  Direct: 'Direct',
  Referral: 'Referral',
};

export enum InquirerRole {
  LOVED_ONE = 'Loved one',
  PATIENT = 'Patient',
  REFERRER = 'Referrer',
}

export enum ReferralMode {
  PATIENT = 'patient',
  PRIMARY_CARER = 'primarycarer',
}

export const INQUIRY_REFERRAL_HORIZONTAL_MARGINS = [
  '20px',
  '40px',
  '40px',
  '80px',
  '80px',
];

export const MY_EQUIP_V2_DIMENSIONS = {
  desktopMaxWidth: '1440px',
  desktopPageMaxWidth: '1000px',
};

export const AUTHENTICATED_NAV_LAYOUT = {
  desktopHeight: '73px',
  mobileHeight: '64px',
};

export const INQUIRY_REFERRAL_DISPLAY_TYPE = ['', '', '', '', '', 'grid'];

export const Path = {
  HOME: '/',
  LOGIN_V1: VITE_EQUIP_BEHAVIORAL_HEALTH_ADDRESS,
  LOGIN_V2: '/login',
  LOGO: VITE_EQUIP_WEBSITE_ADDRESS,
  WEBSITE: VITE_EQUIP_WEBSITE_ADDRESS,
  WEBSITE_V2: '/myequip',
};

export const EquipAppPath = {
  HOME: '/myequip',
  MESSAGES: '/messages',
  PROFILE: '/profile',
  SCHEDULE: '/schedule',
  VITALS_WEIGHT: '/weight',
  SURVEY: '/survey',
};
export const PHONE_NUMBER_INPUT_PLACEHOLDER = '(XXX) XXX-XXXX';

export const ExternalLinks: string[] = [Path.LOGO, Path.WEBSITE, Path.LOGIN_V1];

type FooterLinks = {
  path: string;
  value: string;
  badgeText?: string;
};

export const CHAT_EMERGENCY_CONSENT = {
  apiPropValue: 'Emergencies For First Chat',
  localStorageKey: 'EmergenciesForFirstChat',
  version: '1.0',
};

export enum FooterLink {
  privacyPolicy = 'privacy-policy',
  termsOfUse = 'terms-of-use',
  resources = 'resources',
  contactUs = 'contact-us',
  careers = 'join-us',
  learnMore = 'learn-more',
  company = 'company',
}

export const NavFooterLinks: Record<string, FooterLinks[]> = {
  Menu: [
    { path: FooterLink.careers, value: 'Careers' },
    { path: FooterLink.company, value: 'Company' },
  ],
  Support: [
    { path: FooterLink.privacyPolicy, value: 'Privacy Policy' },
    { path: FooterLink.termsOfUse, value: 'Terms of Use' },
    { path: FooterLink.learnMore, value: 'Learn More' },
  ],
  Tools: [
    { path: 'is-it-an-eating-disorder', value: 'Is it an Eating Disorder?' },
  ],
};

export const InstagramLink = 'https://www.instagram.com/equiphealth/';

export const TwitterLink = 'https://twitter.com/joinequip';

export const FacebookLink = 'https://www.facebook.com/joinequip';

export const LinkedInLink =
  'https://www.linkedin.com/company/equip-behavioral-health/';

export const SupportMailForReferral = 'mailto:refer@equip.health';

export const GAEventForInquiry = 'inquiry-submit';

export const GAEventForReferral = 'referral-submit';

export const GAEventForRouting = 'route-change';
export const GOOGLE_ADDRESS_EMPTY_VALUE = {
  addressLine1: '',
  city: '',
  country: '',
  county: '',
  description: '',
  latitude: undefined,
  longitude: undefined,
  name: '',
  placeId: '',
  state: '',
  timezone: '',
  zipCode: '',
};

export const ROUTE_PARAM_CONSTANTS = { userIsBlocked: 'user is blocked' };
export const AUTH0_ERROR_CONSTANTS = { invalidState: 'Invalid state' };

export const ADMISSIONS_PHONE_NUMBER = '(855) 387-4378';
