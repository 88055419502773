import { FC, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { ChakraFlex } from '@equip.health/ui';
import { useHistory } from 'react-router-dom';

import Loading from '~/components/common/Loading';
import { OnboardingModalLocalStorageKey } from '~/components/common/modals/OnboardingModal';
import { EquipAppPath } from '~/lib/constants';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { PAGE_NAME } from '~/lib/constants/analytics';

const Login: FC = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const history = useHistory();
  const { trackPageView } = useAnalytics();

  /* 
  On Back Navigation Safari will restore the login page from cache,preventing re-renders.
  This will cause the Login page to get stuck in a `isLoading` state forever.
  https://web.dev/bfcache/
  The pageshow event has a persisted property which will be true if the page was restored 
  from bfcache (and false if not).

  If this is the case we want to reload the page to listen to auht0 state changes.
  */

  window.onpageshow = (event: PageTransitionEvent) => {
    if (event.persisted) {
      window.location.reload();
    }
  };

  useEffect(() => {
    trackPageView(PAGE_NAME.login);
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isAuthenticated) {
      history.push(EquipAppPath.HOME);
    } else {
      localStorage.removeItem(OnboardingModalLocalStorageKey);

      loginWithRedirect({
        authorizationParams: {
          redirectUri: `${window.location.origin}${EquipAppPath.HOME}`,
        },
      });
    }
  }, [isAuthenticated, loginWithRedirect, isLoading, history]);

  return (
    <ChakraFlex height="100vh" justifyContent="center">
      <Loading />
    </ChakraFlex>
  );
};

export default Login;
