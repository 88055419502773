import { FC } from 'react';

import { ChakraFlex, Tab } from '@equip.health/ui';

interface NavItemProps {
  title: string;
  isSelected: boolean;
  isShowIndicator?: boolean;
  onClick: () => void;
  marginRight?: string;
  marginLeft?: string;
  type?: 'primary' | 'secondary';
}

const PrimaryNavItem: FC<NavItemProps> = ({
  title,
  isSelected,
  isShowIndicator,
  onClick,
  marginLeft,
  marginRight,
}) => {
  return (
    <ChakraFlex
      id={`${title}__primary-nav-wrapper`}
      justifyContent="center"
      marginLeft={marginLeft}
      marginRight={marginRight}
      onClick={onClick}
      padding="12px"
      position="relative"
    >
      <Tab
        id={`${title}__primary-nav`}
        isActive={isSelected}
        showIndicator={isShowIndicator}
        title={title}
      />
    </ChakraFlex>
  );
};

const SecondaryNavItem: FC<NavItemProps> = ({
  title,
  isSelected,
  isShowIndicator,
  onClick,
  marginLeft,
  marginRight,
}) => {
  return (
    <ChakraFlex
      id={`${title}__secondary-nav-wrapper`}
      justifyContent="center"
      marginLeft={marginLeft}
      marginRight={marginRight}
      onClick={onClick}
      position="relative"
    >
      <Tab
        id={`${title}__secondary-nav`}
        isActive={isSelected}
        showIndicator={isShowIndicator}
        title={title}
      />
    </ChakraFlex>
  );
};

const NavItem: FC<NavItemProps> = ({
  type = 'secondary',
  title,
  isSelected,
  isShowIndicator,
  onClick,
  marginLeft,
  marginRight,
}) => {
  return type === 'primary' ? (
    <PrimaryNavItem
      isSelected={isSelected}
      isShowIndicator={isShowIndicator}
      marginLeft={marginLeft}
      marginRight={marginRight}
      onClick={onClick}
      title={title}
    />
  ) : (
    <SecondaryNavItem
      isSelected={isSelected}
      isShowIndicator={isShowIndicator}
      marginLeft={marginLeft}
      marginRight={marginRight}
      onClick={onClick}
      title={title}
    />
  );
};

export default NavItem;
