import { GroupClassPreviewCard } from '@equip.health/ui';

import useGroupClassRegistration, {
  GroupClassRegistrationChangeSource,
} from '~/lib/hooks/services/useGroupClassRegistration';
import useFeatureFlags from '~/lib/hooks/useFeatureFlags';
import {
  getGroupClassRecurrenceDisplayString,
  getGroupClassStartTime,
} from '~/lib/util/schedule/groupClasses.util';
import { Recurrence } from '~/lib/util/schedule.util';

interface GroupClassCardProps {
  description: string;
  endDate: string;
  frequency: Recurrence;
  groupClassExternalId: string;
  iconName: string;
  isProxy?: boolean;
  isRegistered?: boolean;
  isSensitive?: boolean;
  onClick?: (id: string) => void;
  onGroupClassRegistrationChange?: (
    isRegistered: boolean,
    groupClassId: string,
  ) => void;
  startDate: string;
  title: string;
  patientExternalId?: string;
}

const GroupClassCard = ({
  title,
  description,
  startDate,
  endDate,
  groupClassExternalId,
  iconName,
  frequency,
  onClick,
  isProxy,
  isRegistered: isUserRegisteredInitially,
  isSensitive,
  onGroupClassRegistrationChange,
  patientExternalId,
}: GroupClassCardProps) => {
  const {
    registerForGroupClass,
    unregisterForGroupClass,
    isRegistered,
    isLoading,
  } = useGroupClassRegistration(
    groupClassExternalId,
    isUserRegisteredInitially,
    patientExternalId,
  );
  const { isSensitiveGroupClassesEnabled } = useFeatureFlags();

  const handleRegistrationButtonClick = () => {
    if (isRegistered) {
      unregisterForGroupClass(
        GroupClassRegistrationChangeSource.overview,
        (success) => {
          if (success) {
            onGroupClassRegistrationChange?.(false, groupClassExternalId);
          }
        },
      );
    } else {
      registerForGroupClass(
        GroupClassRegistrationChangeSource.overview,
        (success) => {
          if (success) {
            onGroupClassRegistrationChange?.(true, groupClassExternalId);
          }
        },
      );
    }
  };

  return (
    <GroupClassPreviewCard
      description={description}
      iconName={iconName}
      id={groupClassExternalId}
      isLoading={isLoading}
      isProxy={isSensitiveGroupClassesEnabled && isProxy}
      isRegistered={isRegistered}
      isSensitive={isSensitiveGroupClassesEnabled && isSensitive}
      onClick={onClick}
      onRegistrationClick={handleRegistrationButtonClick}
      recurrenceLabel={getGroupClassRecurrenceDisplayString(
        startDate,
        endDate,
        frequency,
      )}
      startTime={getGroupClassStartTime(startDate, frequency)}
      title={title}
    />
  );
};

export default GroupClassCard;
