import { PropsWithChildren } from 'react';

import { ChakraFlex } from '@equip.health/ui';

interface BaseCardProps extends PropsWithChildren {
  gridGap?: string;
  id?: string;
  isBorderHidden?: boolean;
  onClick?: () => void;
  padding?: string;
}

const BaseCard = ({
  children,
  gridGap,
  id,
  isBorderHidden,
  onClick,
  padding,
}: BaseCardProps) => {
  const handleClick = () => {
    onClick?.();
  };
  return (
    <ChakraFlex
      _hover={{
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
        cursor: 'pointer',
      }}
      border={isBorderHidden ? 'none' : '1px solid #D4D9DD'}
      borderRadius="16px 16px 40px 16px"
      flexDirection="column"
      gridGap={gridGap}
      id={id}
      onClick={handleClick}
      overflow="hidden"
      padding={padding || '24px 28px'}
      transition="box-shadow 0.15s linear"
    >
      {children}
    </ChakraFlex>
  );
};

export default BaseCard;
