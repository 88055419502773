import { FC } from 'react';

import { Button, EquipUITheme } from '@equip.health/ui';

const { label } = EquipUITheme.typography;

const actionButtonStyles = {
  ...label,
  minWidth: '120px',
  padding: '14px 16px',
  size: '56px',
};

type DialogActionButtonProps = {
  handleClick: () => void;
  text: string;
  variant: 'ghost' | 'solid';
};

const DialogActionButton: FC<DialogActionButtonProps> = ({
  handleClick,
  text,
  variant,
}: DialogActionButtonProps) => {
  const TOP_LEVEL_ID = `dialog-actions__${text}`;

  if (variant === 'ghost') {
    return (
      <Button
        {...actionButtonStyles}
        colorScheme="gray"
        id={TOP_LEVEL_ID}
        margin="0 16px 0 0"
        onClick={handleClick}
        variant="ghost"
      >
        {text}
      </Button>
    );
  }

  if (variant === 'solid') {
    return (
      <Button
        {...actionButtonStyles}
        colorScheme="red"
        id={TOP_LEVEL_ID}
        onClick={handleClick}
        primary
        primaryError
        variant="solid"
      >
        {text}
      </Button>
    );
  }

  return null;
};

export default DialogActionButton;
