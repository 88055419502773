import {
  ChakraFlex,
  ChakraText,
  EquipUIFireflyV1Theme,
  FAIconName,
  Icon,
} from '@equip.health/ui';
import { GroupChannel } from '@sendbird/chat/groupChannel';

import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { GroupChannelMetadata } from '~/lib/types/chat';
import { getChatMembersWarningMessage } from '~/lib/util/chat/chatChannel.util';

const { caption } = EquipUIFireflyV1Theme.typography;
const { tertiary } = EquipUIFireflyV1Theme.colors;

interface ChatMembersWarningViewProps {
  selectedChannel?: GroupChannel;
}

const ChatMembersWarningView = ({
  selectedChannel,
}: ChatMembersWarningViewProps) => {
  const {
    userProfile: { isPatient: isCurrentUserPatient, linkedPatients },
  } = useUserProfileContext();

  const channelMetadata =
    selectedChannel.cachedMetaData as GroupChannelMetadata;

  if (channelMetadata.channelType === 'INDIVIDUAL') {
    return null;
  }

  const selectedPatient = linkedPatients?.find(
    (p) => p.externalId === channelMetadata.patientId,
  );

  const message = getChatMembersWarningMessage(
    selectedChannel,
    isCurrentUserPatient,
    selectedPatient,
  );

  if (!message) {
    return null;
  }

  return (
    <ChakraFlex padding="8px 24px">
      <ChakraFlex
        alignItems="center"
        backgroundColor="tertiary.marigold.background"
        borderRadius="12px"
        gridGap="6px"
        justifyContent="center"
        padding="8px"
        width="100%"
      >
        <Icon
          color={tertiary.marigold.text}
          name={FAIconName.exclamationTriangleRegular}
        />
        <ChakraText
          color="tertiary.orange.text"
          textAlign="center"
          {...caption}
        >
          {message}
        </ChakraText>
      </ChakraFlex>
    </ChakraFlex>
  );
};

export default ChatMembersWarningView;
