import { useState } from 'react';

import SendbirdChat, { ConnectionState } from '@sendbird/chat';
import {
  GroupChannelModule,
  SendbirdGroupChat,
} from '@sendbird/chat/groupChannel';
import * as Sentry from '@sentry/react';

import { VITE_SENDBIRD_APP_ID } from '~/lib/constants/env';
import { ChatIndicators, SendBirdProps } from '~/lib/types/chat';
import { useLazyGetUserChatTokenQuery } from '~/store/services/chat';

const useSendBird = (): SendBirdProps => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  const [isSendBirdLoading, setIsSendBirdLoading] = useState<boolean>(false);
  const [sendBirdInstance, setSendBirdInstance] =
    useState<SendbirdGroupChat | null>(null);

  const [chatIndicatorCache, setChatIndicatorCache] = useState<ChatIndicators>({
    newMessage: false,
    newPatientDocument: false,
    newReply: false,
    unreadMessage: false,
    patientsWithUnreadMessages: [],
  });

  const [getUserChatToken] = useLazyGetUserChatTokenQuery();

  const handleError = (errorMessage?: any, onFailure?: VoidFunction) => {
    errorMessage && Sentry.captureException(errorMessage);
    setIsSendBirdLoading(false);
    onFailure && onFailure();
    errorMessage && console.error(errorMessage);
  };

  const fetchUserAccessToken = (
    onSuccess?: (accessToken: string) => void,
    onNoUserToken?: () => void,
  ) => {
    getUserChatToken()
      .unwrap()
      .then((data) => {
        onSuccess?.(data?.token);
      })
      .catch(() => {
        handleError(
          `User Access Token Data not received for user: ${
            userProfile?.externalId ?? 'Id not available'
          }`,
          onNoUserToken,
        );
      });
  };

  const connect = (profile: UserProfile, onFailure?: VoidFunction) => {
    if (sendBirdInstance) return;

    if (profile) {
      setUserProfile(profile);
      fetchUserAccessToken((accessToken) => {
        let sb = null;
        try {
          sb = SendbirdChat.init({
            appId: VITE_SENDBIRD_APP_ID,
            localCacheEnabled: false,
            modules: [new GroupChannelModule()],
            newInstance: true,
          });
          sb.connect(profile?.externalId, accessToken).then(() => {
            setSendBirdInstance(sb);
            setIsSendBirdLoading(false);
          });
        } catch (error) {
          handleError(error, onFailure);
        }
      }, onFailure);
    }
  };

  const disconnect = () => {
    if (
      sendBirdInstance &&
      sendBirdInstance?.connectionState !== ConnectionState.CLOSED
    ) {
      sendBirdInstance.disconnect();
      setSendBirdInstance(null);
      setUserProfile(null);
    }
  };

  return {
    chatIndicatorCache,
    connect,
    disconnect,
    isSendBirdLoading,
    sendBirdInstance,
    setChatIndicatorCache,
  };
};

export default useSendBird;
