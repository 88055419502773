import { FC, useEffect, useState } from 'react';

import { ChakraBox, ChakraFlex, InfiniteScroll, Text } from '@equip.health/ui';
import isEmpty from 'lodash/isEmpty';

import { PastAppointmentItem, PastGroupClassItem } from '../PastScheduleItem';
import Loading from '~/components/common/Loading';
import { EMPTY_SCHEDULE_TEXT } from '~/lib/constants/schedule.constants';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import useScheduledEvents from '~/lib/hooks/useScheduledEvents';

const PAST_EVENT_ID = 'schedule-appointments-past';
const scrollableTargetPastAppointment = 'ScrollableTargetPastAppointment';

interface PastEventListProps {
  selectedUserId?: string;
  isExpanded?: boolean;
}

const PastEventList: FC<PastEventListProps> = ({
  selectedUserId,
  isExpanded,
}) => {
  const { userProfile } = useUserProfileContext();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const isProxySupportSelected = selectedUserId === userProfile?.externalId;
  const patientExternalId = isProxySupportSelected ? null : selectedUserId;

  const {
    fetchDataForPage: fetchPastEventDataForPage,
    isScheduleLoading: isPastScheduleLoading,
    resetAndLoadListData: resetAndReloadPastEvents,
    totalEventCount: totalPastEventCount,
    ungroupedEvents: pastEventList,
  } = useScheduledEvents({
    isPastEvents: true,
    patientExternalId,
  });

  const resetAndLoadListData = () => {
    resetAndReloadPastEvents();
  };

  useEffect(() => {
    if (isExpanded && !isInitialized) {
      resetAndLoadListData();
      setIsInitialized(true);
    }
  }, [selectedUserId, isExpanded]);

  useEffect(() => {
    setIsInitialized(false);
  }, [selectedUserId]);

  const shouldShowEmptyScheduleView =
    !isPastScheduleLoading && isEmpty(pastEventList);

  return (
    <ChakraBox
      id={scrollableTargetPastAppointment}
      maxHeight={{ base: 'unset', md: '55vh' }}
      minHeight={{ base: 'unset', md: '55vh' }}
      overflowX="hidden"
      overflowY="auto"
      paddingTop="24px"
    >
      {isPastScheduleLoading ? (
        <ChakraFlex justifyContent="center" padding="40px">
          <Loading size="20px" />
        </ChakraFlex>
      ) : (
        <InfiniteScroll
          dataLength={(pastEventList ?? []).length}
          fetchData={fetchPastEventDataForPage}
          hideCount
          id={PAST_EVENT_ID}
          scrollableTarget={scrollableTargetPastAppointment}
          showLoader={false}
          totalCount={totalPastEventCount ?? 0}
        >
          {pastEventList?.map((event) =>
            event.isGroupClassEvent ? (
              <PastGroupClassItem
                eventInfo={event.eventInfo as GroupClassEventDetails}
                key={
                  (event.eventInfo as GroupClassEventDetails)
                    .groupClassEventExternalId
                }
              />
            ) : (
              <PastAppointmentItem
                eventInfo={event.eventInfo as AppointmentDetailResponse}
                key={
                  (event.eventInfo as AppointmentDetailResponse)
                    .appointmentExternalId
                }
              />
            ),
          )}
        </InfiniteScroll>
      )}
      {shouldShowEmptyScheduleView && (
        <Text
          color="black.60"
          id="load_more_cta"
          textAlign="left"
          variant="body"
        >
          {EMPTY_SCHEDULE_TEXT.noPastEventsMessage}
        </Text>
      )}
    </ChakraBox>
  );
};

export default PastEventList;
