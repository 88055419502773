import {
  ChakraBox,
  ChakraFlex,
  ChakraText,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

import TherapistIcon from '~/assets/svg/Therapist';

const { h5, caption } = EquipUIFireflyV1Theme.typography;

const TOP_LEVEL_ID = 'vitals__empty-weight__view';

const EmptyWeightView = () => {
  return (
    <ChakraFlex
      alignItems="center"
      gridGap="32px"
      height="100%"
      id={`${TOP_LEVEL_ID}_container`}
    >
      <ChakraBox flexShrink={0} id={`${TOP_LEVEL_ID}_icon`}>
        <TherapistIcon />
      </ChakraBox>
      <ChakraFlex flexDirection="column" gridGap="16px">
        <ChakraText color="black.90" id={`${TOP_LEVEL_ID}_title`} {...h5}>
          No weight logged yet
        </ChakraText>
        <ChakraText
          color="black.60"
          id={`${TOP_LEVEL_ID}_description`}
          {...caption}
        >
          After you log a weight measurement, we'll display history and trends
          here.
        </ChakraText>
      </ChakraFlex>
    </ChakraFlex>
  );
};

export default EmptyWeightView;
