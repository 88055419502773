import { DateTime } from 'luxon';

import {
  AppointmentTypeGroup,
  SchedulePermissionActionType,
  SchedulePermissionTreatmentType,
} from '../../constants/bookAppointments';
import { checkIsToday, checkIsTomorrow } from '../schedule.util';
import { AttendeeItemProps } from '~/components/schedule/BookAppointment/cards/AppointmentOverviewCard';
import { getPreferredFullName } from '~/lib/utils';

export const getCardHoverBackgroundColor = (
  isSelected: boolean,
  isMobile: boolean,
) => {
  if (isMobile) {
    return isSelected ? 'primary.10' : 'white.100';
  }

  return isSelected ? 'primary.10' : 'black.5';
};

export const getAttendeeLabel = (
  attendee: BookAppointmentAttendee,
  currentUserId: string,
) => {
  const { providerType, userType, externalId } = attendee;

  if (externalId === currentUserId) {
    return 'You';
  }

  if (userType === 'patient') {
    return 'Patient';
  }

  if (userType === 'support') {
    return 'Support';
  }

  if (userType === 'provider') {
    return providerType;
  }

  return null;
};

export const getRelativeDayFromDate = (
  dateString: string,
  format?: 'short',
) => {
  if (checkIsToday(dateString)) {
    return 'Today';
  }

  if (checkIsTomorrow(dateString)) {
    return 'Tomorrow';
  }

  const date = DateTime.fromISO(dateString);
  if (format === 'short') {
    const dayName = date.toFormat('cccc, L/d');
    return dayName;
  }
  const dayName = date.toFormat('cccc, LLLL d');
  return dayName;
};

export interface GroupedTimeSlots {
  [date: string]: TimeSlot[];
}

export const groupTimeSlotsByDay = (
  timeSlots: TimeSlot[],
): GroupedTimeSlots => {
  const timeZone = DateTime.local().zoneName;

  const groupedTimeSlots: GroupedTimeSlots = timeSlots?.reduce(
    (groupedTimeSlots: GroupedTimeSlots, slot: TimeSlot) => {
      const date = DateTime.fromISO(slot.start, { zone: timeZone }).toISODate();
      return {
        ...groupedTimeSlots,
        [date]: [...(groupedTimeSlots[date] ?? []), slot],
      };
    },
    {},
  );

  return groupedTimeSlots;
};

export const mapAttendeeToListItem = (
  attendee: BookAppointmentAttendee,
  currentUserId: string,
): AttendeeItemProps => {
  return {
    id: attendee?.externalId,
    name: getPreferredFullName(
      attendee.chosenName,
      attendee.firstName,
      attendee.lastName,
    ),
    label: getAttendeeLabel(attendee, currentUserId),
    userType: attendee.userType,
  };
};

export const parseAppointmentPermissions = ({
  permissionsDefinitions,
  isSupport,
  isProxy,
  treatmentType,
}: {
  permissionsDefinitions: SchedulePermissionDefinition[];
  isSupport: boolean;
  isProxy: boolean;
  treatmentType: SchedulePermissionTreatmentType;
}): SchedulePermissionsMapper => {
  const permissionsMap: SchedulePermissionsMapper = Object.fromEntries(
    Object.values(AppointmentTypeGroup).map((group) => [
      group,
      { canCancel: false },
    ]),
  );
  return permissionsDefinitions
    .filter((p) => {
      const userTypeMatches = isSupport
        ? p.subjectType === 'SUPPORT'
        : p.subjectType === 'PATIENT';
      const supportTypeMatches =
        !isSupport ||
        p.subjectId === '*' ||
        (isProxy ? p.subjectId === 'PROXY' : p.subjectId === 'GENERAL');
      return (
        userTypeMatches &&
        supportTypeMatches &&
        p.targetSubTypeId === treatmentType
      );
    })
    .reduce((res, current) => {
      const currentPermission = res[current.targetId] ?? { canCancel: false };
      if (current.actionType === SchedulePermissionActionType.CANCEL) {
        currentPermission.canCancel = true;
      }
      return {
        ...res,
        [current.targetId]: currentPermission,
      };
    }, permissionsMap);
};
