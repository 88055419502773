import { FC, ReactElement, createContext, useContext } from 'react';

import useChatAttachments, {
  ChatAttachmentsState,
} from '~/lib/hooks/useChatAttachments';

const ChatAttachmentsContext = createContext<ChatAttachmentsState | undefined>(
  undefined,
);

interface ChatAttachmentsContextProviderProps {
  children: ReactElement | ReactElement[];
}

const ChatAttachmentsContextProvider: FC<
  ChatAttachmentsContextProviderProps
> = ({ children }: ChatAttachmentsContextProviderProps) => {
  const value = useChatAttachments();

  return (
    <ChatAttachmentsContext.Provider value={value}>
      {children}
    </ChatAttachmentsContext.Provider>
  );
};

const useChatAttachmentsContext = (): ChatAttachmentsState => {
  const context = useContext(ChatAttachmentsContext);

  if (context === undefined) {
    throw new Error(
      'useChatAttachmentsContext must be used within a ChatAttachmentsContextProvider',
    );
  }

  return context;
};

export { ChatAttachmentsContextProvider, useChatAttachmentsContext };
