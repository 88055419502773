import { ChannelNameSegments } from '~/lib/types/chat';

import { isIndividualChannel } from '../chat/chat.utils';

export const getMessagesChannelEventPayload = (
  channelName: ChannelNameSegments,
  channelUrl: string,
) => {
  const analyticsChannelType = isIndividualChannel(channelUrl)
    ? 'Direct Messages'
    : 'Group Channel';

  return {
    'Channel Name': channelName?.primaryName,
    'Channel Type': analyticsChannelType,
  };
};
