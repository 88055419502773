import { FC } from 'react';

import { AngleLeft, ChakraButton, EquipUIFireflyV1Theme } from '@equip.health/ui';

const { teal } = EquipUIFireflyV1Theme.colors;

type FormBackButtonProps = {
  fontFamily?: string;
  fontSize?: string | number;
  fontWeight?: string | number;
  id?: string;
  letterSpacing?: string | number;
  lineHeight?: string | number;
  onClickBack?: () => void;
};

const FormBackButton: FC<FormBackButtonProps> = ({
  fontFamily,
  fontSize,
  fontWeight,
  id = 'form-back-button',
  letterSpacing,
  lineHeight,
  onClickBack,
}) => (
  <ChakraButton
    color="brand.500"
    display="flex"
    fontFamily={fontFamily}
    fontSize={fontSize ?? '12px'}
    fontWeight={fontWeight ?? 400}
    id={`${id}__back-btn`}
    leftIcon={<AngleLeft fill={teal[100]} height="12px" width="12px" />}
    letterSpacing={letterSpacing}
    lineHeight={lineHeight}
    marginBottom="25px"
    onClick={onClickBack}
    variant="link"
    width="fit-content"
  >
    Back
  </ChakraButton>
);

export default FormBackButton;
