import { FC } from 'react';

import { ChakraText, EquipUITheme, Modal } from '@equip.health/ui';

import DialogActionButton from './DialogActionButton';

const { h6, label } = EquipUITheme.typography;
export interface ExitConfirmProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const ExitConfirm: FC<ExitConfirmProps> = ({
  isOpen,
  handleClose,
  handleConfirm,
}) => {
  const unsavedChangesFooterContent = (
    <>
      <DialogActionButton
        handleClick={handleClose}
        text="Return to editing"
        variant="ghost"
      />
      <DialogActionButton
        handleClick={handleConfirm}
        text="Discard changes"
        variant="solid"
      />
    </>
  );

  return (
    <Modal
      footerContent={unsavedChangesFooterContent}
      id="exit-confirm"
      isOpen={isOpen}
      onClose={handleClose}
      size="xs"
      title={<ChakraText {...h6}>Warning</ChakraText>}
    >
      <ChakraText {...label} color="mono.70" padding="9px 0px 8px">
        You’re exiting this page without saving your changes.
      </ChakraText>
    </Modal>
  );
};

export default ExitConfirm;
