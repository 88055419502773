import { useCallback, useEffect, useRef, useState } from 'react';

import isNil from 'lodash/isNil';

import { programNameToTreatmentTypeMapper } from '../constants/bookAppointments';
import {
  isOrHasPatientInTreatment,
  isPatientInTreatment,
} from '../util/profile.util';
import { parseAppointmentPermissions } from '../util/schedule/bookAppointment.util';
import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import useApi from '~/lib/hooks/useApi';

const {
  getPatientAppointmentTypes: getPatientAppointmentTypesUrl,
  getSchedulePermissions: getSchedulePermissionsUrl,
} = urlConstants.schedule;

export type UseSchedulePermissionsState = {
  appointmentTypesForPatient?: (
    patientId: string,
  ) => ProviderAppointmentTypes[];
  isBookingPermissionsFetched?: boolean;
  isLoading?: boolean;
  patientAppointmentTypesMapper: Record<string, ProviderAppointmentTypes[]>;
  patientIdsWithBookingPermissions?: string[];
  schedulePermissionsByPatientMapper: Record<string, SchedulePermissionsMapper>;
};

const useSchedulePermissions = (): UseSchedulePermissionsState => {
  const { isFullProfileInfoFetched, userProfile: currentUser } =
    useUserProfileContext();

  const patientIdQueueRef = useRef<string[]>([]);
  const patientAppointmentTypesMapperRef = useRef<
    Record<string, ProviderAppointmentTypes[]>
  >({});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    patientIdsWithBookingPermissions,
    setPatientIdsWithBookingPermissions,
  ] = useState<string[]>([]);
  const [isBookingPermissionsFetched, setIsBookingPermissionsFetched] =
    useState<boolean>(false);
  const [schedulePermissions, setSchedulePermissions] = useState<
    Record<string, SchedulePermissionsMapper>
  >({});

  const { sendRequest: getAppointmentTypes } =
    useApi<GetAppointmentTypesResponse>();
  const {
    sendRequest: getSchedulePermissions,
    loading: isSchedulePerissionsLoading,
  } = useApi<SchedulePermissionResponse>();

  const processSchedulePermissions = (res: SchedulePermissionResponse) => {
    const permissionsByPatient = {};
    if (currentUser.isPatient) {
      permissionsByPatient[currentUser.externalId] =
        parseAppointmentPermissions({
          permissionsDefinitions: res.data,
          isSupport: false,
          isProxy: false,
          treatmentType:
            programNameToTreatmentTypeMapper[currentUser.programName] ?? '',
        });
    } else {
      currentUser?.linkedPatients?.forEach((patient) => {
        permissionsByPatient[patient.externalId] = parseAppointmentPermissions({
          permissionsDefinitions: res.data,
          isSupport: true,
          isProxy: patient.isProxy,
          treatmentType:
            programNameToTreatmentTypeMapper[patient.programName] ?? '',
        });
      });
    }
    setSchedulePermissions(permissionsByPatient);
  };

  const updatePatientIdsWithBookingPermissions = () => {
    const patients = Object.keys(
      patientAppointmentTypesMapperRef.current,
    ).filter((patientId) => {
      return patientAppointmentTypesMapperRef.current[patientId]?.length > 0;
    });

    setIsBookingPermissionsFetched(true);
    setPatientIdsWithBookingPermissions(patients);
    setIsLoading(false);
  };

  const processNextPatient = useCallback(() => {
    if ((patientIdQueueRef?.current ?? []).length === 0) {
      updatePatientIdsWithBookingPermissions();
      return;
    }

    const patientId = patientIdQueueRef.current[0]; // Remove and retrieve the first patient ID from the queue

    getAppointmentTypes({
      url: `${getPatientAppointmentTypesUrl}/${patientId}`,
      command: ApiCommand.GET,
      callback: (response) => {
        const updatedMapper = { ...patientAppointmentTypesMapperRef.current };
        updatedMapper[patientId] = response?.data;

        patientAppointmentTypesMapperRef.current = updatedMapper;

        patientIdQueueRef.current = patientIdQueueRef.current.slice(1); // Remove the first patient ID from the queue
        processNextPatient();
      },
    });
  }, [patientAppointmentTypesMapperRef]);

  useEffect(() => {
    const isSupportWithProfileInfoLoading =
      currentUser && !currentUser.isPatient && !isFullProfileInfoFetched;

    if (isNil(currentUser) || isSupportWithProfileInfoLoading) {
      return;
    }

    const isPatientNotInTreatment =
      currentUser.isPatient && !isPatientInTreatment(currentUser);
    const isSupportWithNotInTreatmentPatient =
      !currentUser.isPatient && !isOrHasPatientInTreatment(currentUser);

    if (isPatientNotInTreatment || isSupportWithNotInTreatmentPatient) {
      /* 
        If the user is a patient and not in treatment, or if the user is a support 
        and has no patients in treatment, then we don't need to fetch 
        booking permissions otherwise this API call will return a 500 error 
        with No active admin episodes found.
      */
      return;
    }

    if (currentUser.isPatient) {
      patientIdQueueRef.current = [currentUser.externalId];
    } else {
      patientIdQueueRef.current = currentUser?.linkedPatients?.map(
        (patient) => patient.externalId,
      );
    }

    setIsLoading(true);
    getSchedulePermissions({
      callback: processSchedulePermissions,
      command: ApiCommand.GET,
      url: getSchedulePermissionsUrl,
    });
    processNextPatient();
  }, [isFullProfileInfoFetched, currentUser]);

  return {
    isBookingPermissionsFetched,
    isLoading: isLoading || isSchedulePerissionsLoading,
    patientAppointmentTypesMapper: patientAppointmentTypesMapperRef.current,
    patientIdsWithBookingPermissions,
    schedulePermissionsByPatientMapper: schedulePermissions,
  };
};

export default useSchedulePermissions;
