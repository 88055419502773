import { FC } from 'react';

import {
  Avatar,
  ChakraBox,
  ChakraFlex,
  EquipUIFireflyV2Theme,
  FAIconName,
  Icon,
  Text,
} from '@equip.health/ui';

import BaseCard, {
  BaseCardProps,
} from '~/components/schedule/BookAppointment/base/BaseCard';
import { EM_DASH } from '~/lib/constants/chat/chat.constants';

const { neutral } = EquipUIFireflyV2Theme.colors;

type AvatarProps = React.ComponentProps<typeof Avatar>;

interface AppointmentUserAvatarCardProps extends BaseCardProps {
  id: string;
  primaryLabel: string;
  secondaryLabel?: string;
  tooltip?: string;
  userType: AvatarProps['userType'];
}

const topLevelId = 'appointment-user-avatar-card';

const AppointmentUserAvatarCard: FC<AppointmentUserAvatarCardProps> = ({
  id,
  isHoverDisabled,
  isRemoveButtonVisible,
  isSelected,
  onClick,
  onRemove,
  primaryLabel,
  secondaryLabel,
  tooltip,
  userType,
}) => {
  return (
    <BaseCard
      id={`${topLevelId}__container__${id}`}
      isHoverDisabled={isHoverDisabled}
      isRemoveButtonVisible={isRemoveButtonVisible}
      isSelected={isSelected}
      onClick={onClick}
      onRemove={onRemove}
    >
      <ChakraFlex
        alignItems={{ base: 'flex-start', md: 'center' }}
        flexDirection={{ base: 'column', md: 'row' }}
        gridGap={{ base: '12px', md: '18px' }}
        justifyContent="flex-start"
      >
        <Avatar id={id} name={primaryLabel} size="sm" userType={userType} />
        <ChakraFlex direction="column" gridGap="6px">
          <ChakraFlex alignItems="center" gridGap="8px">
            <Text
              color="neutral.primary"
              id={`${topLevelId}__title__${id}`}
              variant="bodyMedium"
            >
              {primaryLabel}
            </Text>

            {secondaryLabel && (
              <>
                <Text color="neutral.secondary" variant="captionMedium">
                  {EM_DASH}
                </Text>
                <Text
                  color="neutral.secondary"
                  id={`${topLevelId}__subtitle__${id}`}
                  variant="captionMedium"
                >
                  {secondaryLabel}
                </Text>
              </>
            )}
          </ChakraFlex>
          {tooltip && (
            <ChakraFlex alignItems="center" gridGap="6px">
              <ChakraBox>
                <Icon
                  color={neutral.secondary}
                  name={FAIconName.infoCircleRegular}
                  size="sm"
                />
              </ChakraBox>
              <Text
                color="neutral.secondary"
                id={`${topLevelId}__caption__${id}`}
                variant="caption"
              >
                {tooltip}
              </Text>
            </ChakraFlex>
          )}
        </ChakraFlex>
      </ChakraFlex>
    </BaseCard>
  );
};

export default AppointmentUserAvatarCard;
