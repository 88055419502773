import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { ChakraBox, ChakraFlex, useBreakpointValue } from '@equip.health/ui';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { ChannelProvider } from '@sendbird/uikit-react/Channel/context';
import SendBirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import * as Sentry from '@sentry/react';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { use100vh } from 'react-div-100vh';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';

import ChatEmergencyConsentModal from '~/components/chat/ChatEmergencyConsentModal';
import MessagesContainer from '~/components/chat/MessagesContainer';
import AuthenticatedNav from '~/components/common/AuthenticatedNav';
import LinkedPatientModal from '~/components/safetyplan/LinkedPatientModal';
import SafetyPlanModal from '~/components/safetyplan/SafetyPlanModal';
import { ApiCommand } from '~/lib/Api';
import { AUTHENTICATED_NAV_LAYOUT, EquipAppPath } from '~/lib/constants';
import urlConstants from '~/lib/constants/url.constants';
import { ChatChannelContextProvider } from '~/lib/context/ChatChannelContext';
import { useChatContext } from '~/lib/context/ChatContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useApi } from '~/lib/hooks';
import useChat from '~/lib/hooks/chat/useChat';
import { parseTimeoutValue } from '~/lib/util/authenticatedNav.util';
import { hideBeacon, showBeacon } from '~/lib/util/beacon.util';
import { chatApi } from '~/store/services/chat';

import AuthenticatedPageFooter from './AuthenticatedPageFooter';
import { useNewRelic } from '~/lib/context/NewRelicContext';

const { VITE_INACTIVITY_TIMEOUT, VITE_SENDBIRD_APP_ID } = import.meta.env;

const { mobileHeight: mobileNavHeight, desktopHeight: desktopNavHeight } =
  AUTHENTICATED_NAV_LAYOUT;

interface AuthenticatedPageLayoutProps {
  children: ReactNode;
  hiddenHeader?: boolean;
  hiddenFooter?: boolean;
  isMessage?: boolean;
}

const IDLE_TIMEOUT_IN_MINUTES = parseTimeoutValue(VITE_INACTIVITY_TIMEOUT);

const AuthenticatedPageLayout: FC<AuthenticatedPageLayoutProps> = ({
  children,
  hiddenHeader,
  hiddenFooter,
  isMessage,
}) => {
  const { sendRequest: revokeRefreshToken } = useApi();
  const { isAuthenticated, isLoading: auth0Loading, logout } = useAuth0();

  const revokeRefreshTokenOnLogout = () => {
    revokeRefreshToken({
      callback() {
        logout({
          logoutParams: { returnTo: window.location.origin },
        });
      },
      command: ApiCommand.PUT,
      url: urlConstants.users.logout,
    });
  };
  const { chatIndicatorCache, setChatIndicatorCache } = useChatContext();
  const {
    isSafetyPlanOpen,
    isLinkedPatientModalOpen,
    patientsCompletedSafetyPlan,
    selectedPatientForSafetyPlan,
    userProfile,
    updateIsSafetyPlanOpen,
    updateIsLinkedPatientModalOpen,
  } = useUserProfileContext();
  const navHeight = useBreakpointValue({
    base: mobileNavHeight,
    md: desktopNavHeight,
  });
  const navBarHeight = hiddenHeader ? 0 : navHeight;

  const [selectedChannel, setSelectedChannel] = useState<GroupChannel>(null);
  const channelsRef = useRef<GroupChannel[]>(null);
  const location = useLocation();
  const { newRelicAgent } = useNewRelic();

  const handleNewFileMessage = () => {
    setChatIndicatorCache({ ...chatIndicatorCache, newPatientDocument: true });
  };

  const {
    channels,
    chatIndicators,
    hasMoreMessages,
    loadMessages,
    markMessagesAsRead,
    messages,
    sendFileMessage,
    sendUserMessage,
  } = useChat({
    onNewFileMessage: handleNewFileMessage,
    selectedChannel,
    onChannelUpdated(updatedChannel) {
      if (updatedChannel.url === selectedChannel.url) {
        setSelectedChannel(clone(updatedChannel));
      }
    },
  });

  if (channels) {
    channelsRef.current = channels;
  }

  const handleLogout = () => {
    Sentry.setUser(null);
    revokeRefreshTokenOnLogout();
  };

  const handleCloseSafetyPlan = () => {
    updateIsSafetyPlanOpen(false);
    updateIsLinkedPatientModalOpen(false);
  };
  const handleCloseLinkedPatient = () => {
    updateIsLinkedPatientModalOpen(false);
  };

  useIdleTimer({
    crossTab: true,
    onIdle: handleLogout,
    timeout: IDLE_TIMEOUT_IN_MINUTES * 60 * 1000,
    timers: workerTimers,
  });

  useEffect(() => {
    if (!auth0Loading && !isAuthenticated) {
      revokeRefreshTokenOnLogout();
    }
  }, [auth0Loading]);

  useEffect(() => {
    if (isAuthenticated) {
      /** To enable beacon when user is authenticated */
      if (
        location.pathname === EquipAppPath.MESSAGES ||
        location.pathname === EquipAppPath.SURVEY
      ) {
        hideBeacon();
      } else {
        showBeacon();
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    newRelicAgent?.setUserId(userProfile?.externalId);
  }, [userProfile, newRelicAgent]);

  const calcMobileNavHeight = hiddenHeader ? 0 : mobileNavHeight;
  const calcDesktopNavHeight = hiddenHeader ? 0 : desktopNavHeight;
  useEffect(() => {
    if (
      !isEmpty(chatIndicators) &&
      (chatIndicatorCache?.unreadMessage !== chatIndicators?.unreadMessage ||
        chatIndicatorCache?.newMessage !== chatIndicators?.newMessage ||
        chatIndicatorCache?.newPatientDocument !==
          chatIndicators?.newPatientDocument ||
        !isEqual(
          chatIndicatorCache?.patientsWithUnreadMessages,
          chatIndicators?.patientsWithUnreadMessages,
        ))
    ) {
      setChatIndicatorCache({
        ...chatIndicatorCache,
        newPatientDocument: chatIndicators?.newPatientDocument,
        unreadMessage: chatIndicators?.unreadMessage,
        newReply: chatIndicators?.newReply,
        newMessage: chatIndicators?.newMessage,
        patientsWithUnreadMessages: chatIndicators.patientsWithUnreadMessages,
      });
    }
  }, [chatIndicators]);

  const isChatIndicatorVisible =
    (chatIndicators ?? chatIndicatorCache)?.unreadMessage ||
    (chatIndicators ?? chatIndicatorCache)?.newMessage;

  const vh = use100vh();

  const { isLoading, data: sendirdAccessToken } =
    chatApi.endpoints.getUserChatToken.useQuery();

  return (
    /* 
      Temporarily wrapping V2 Authenticated Pages in EquipUIProvider with new theme
      to avoid overriding all the theme configuration in V1 pages and components like 
      homescreen and survey.
    */

    <ChakraBox>
      {!hiddenHeader && isAuthenticated && (
        <AuthenticatedNav isShowIndicator={isChatIndicatorVisible} />
      )}
      <ChakraFlex
        justifyContent="center"
        minHeight={`calc(${vh}px - ${navBarHeight})`}
        padding={{
          base: `0 0 ${calcMobileNavHeight}`,
          lg: `${calcDesktopNavHeight} 0 0`,
        }}
        width="100%"
      >
        {isMessage && !isLoading ? (
          <SendBirdProvider
            accessToken={sendirdAccessToken?.token}
            appId={VITE_SENDBIRD_APP_ID}
            userId={userProfile?.externalId}
          >
            <ChatEmergencyConsentModal />
            <ChatChannelContextProvider>
              <ChannelProvider channelUrl={selectedChannel?.url}>
                <MessagesContainer
                  channels={channels}
                  hasMoreMessages={hasMoreMessages}
                  loadMessages={loadMessages}
                  markMessagesAsRead={markMessagesAsRead}
                  messages={messages}
                  selectedChannel={selectedChannel}
                  sendFileMessage={sendFileMessage}
                  sendUserMessage={sendUserMessage}
                  setSelectedChannel={(channel: GroupChannel) =>
                    setSelectedChannel(channel)
                  }
                />
              </ChannelProvider>
            </ChatChannelContextProvider>
          </SendBirdProvider>
        ) : (
          children
        )}
      </ChakraFlex>
      {hiddenFooter ? null : <AuthenticatedPageFooter />}
      {selectedPatientForSafetyPlan && isSafetyPlanOpen && (
        <SafetyPlanModal
          isOpen={isSafetyPlanOpen}
          onClose={handleCloseSafetyPlan}
        />
      )}
      {patientsCompletedSafetyPlan &&
        patientsCompletedSafetyPlan.length > 1 &&
        isLinkedPatientModalOpen && (
          <LinkedPatientModal
            isOpen={isLinkedPatientModalOpen}
            linkedPatients={patientsCompletedSafetyPlan}
            onClose={handleCloseLinkedPatient}
          />
        )}
    </ChakraBox>
  );
};

export default AuthenticatedPageLayout;
