import { Fragment } from 'react';

import { ChakraSkeleton } from '@equip.health/ui';

import BaseCard from '../common/cards/BaseCard';
import BaseCardGrid from '../common/cards/BaseCardGrid';

const TOP_LEVEL_ID = 'schedule-loader';

const loaderStartColor = '#F7F7F7';
const loaderEndColor = '#F7F7F720';

const GroupClassLoader = () => {
  return (
    <BaseCardGrid>
      {[...Array(6)]
        .map((_, i) => i + 1)
        .map((val) => {
          return (
            <Fragment key={`${TOP_LEVEL_ID}_${val}`}>
              <BaseCard isBorderHidden padding="0">
                <ChakraSkeleton
                  endColor={loaderEndColor}
                  height="200px"
                  startColor={loaderStartColor}
                  width="100%"
                />
              </BaseCard>
            </Fragment>
          );
        })}
    </BaseCardGrid>
  );
};

export default GroupClassLoader;
