import { ChakraFlex, ChakraSimpleGrid } from '@equip.health/ui';

import CollapsibleContainer from './CollapsibleContainer';

interface CollapsibleListProps<T> {
  isLoading?: boolean;
  items: T[];
  listId?: string;
  numberOfCollapsedItems?: number;
  renderItem: (item: T) => React.ReactNode;
  renderLoader?: () => React.ReactNode;
  onCollapseChange?: (isCollapsed: boolean) => void;
}

const CollapsibleList = <T extends unknown>({
  isLoading,
  items,
  listId,
  numberOfCollapsedItems,
  onCollapseChange,
  renderItem,
  renderLoader,
}: CollapsibleListProps<T>) => {
  const collapsedItems = items?.slice(0, numberOfCollapsedItems);
  const expandedItems = items?.slice(numberOfCollapsedItems, items.length);

  const shouldShowCollapsibleContainer = expandedItems?.length > 0;

  return (
    <ChakraFlex
      flexDirection="column"
      gridGap="24px"
      id={listId}
      marginTop="24px"
    >
      {isLoading ? (
        renderLoader()
      ) : (
        <>
          <ChakraSimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing="24px">
            {collapsedItems?.map((item) => renderItem(item))}
          </ChakraSimpleGrid>
          {shouldShowCollapsibleContainer && (
            <CollapsibleContainer
              isCollapsedInitially
              onCollapseChange={onCollapseChange}
              shouldHideButtonWhenExpanded
            >
              <ChakraSimpleGrid
                columns={{ base: 1, lg: 2, xl: 3 }}
                spacing="24px"
              >
                {expandedItems.map((item) => renderItem(item))}
              </ChakraSimpleGrid>
            </CollapsibleContainer>
          )}
        </>
      )}
    </ChakraFlex>
  );
};

export default CollapsibleList;
