import { FC, PropsWithChildren, useEffect, useState } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  FAIconName,
  FireflyButton,
} from '@equip.health/ui';
import { useHistory } from 'react-router-dom';

import Loading from '../common/Loading';
import ProviderListItem from './ProviderListItem';
import {
  PatientTabItemProps,
  PatientTabSelector,
} from '~/components/common/PatientTabSelector';
import SectionTitle from '~/components/common/SectionTitle';
import { EquipAppPath } from '~/lib/constants';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import useCareTeam from '~/lib/hooks/useCareTeam';
import {
  getPreferredFirstName,
  getPreferredProfileFirstName,
} from '~/lib/utils';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { EVENT } from '~/lib/constants/analytics';

const getTitle = (name: string): string => {
  let title = '';
  if (name?.length) {
    const lastChar = name.charAt(name.length - 1);
    title = `${name}'${lastChar.toLowerCase() === 's' ? '' : 's'}`;
  }
  return `${title} provider team`;
};

interface SinglePatientProviderListProps {
  patient: UserProfile;
  careTeam: CareTeamProvider[];
}

const ViewMessagesButton = () => {
  const history = useHistory();
  return (
    <FireflyButton
      id="firefly-button"
      onClick={() => history.push(EquipAppPath.MESSAGES)}
      rightIcon={FAIconName.arrowRightRegular}
      variant="tertiary"
    >
      View messages
    </FireflyButton>
  );
};

const SectionContainer = ({ children }: PropsWithChildren) => (
  <ChakraFlex flexDirection="column" gridGap="24px">
    {children}
    <ChakraFlex
      alignItems="center"
      display={{ base: 'flex', md: 'none' }}
      justifyContent="center"
    >
      <ViewMessagesButton />
    </ChakraFlex>
  </ChakraFlex>
);

const SectionHeaderContainer = ({ children }: PropsWithChildren) => {
  return (
    <ChakraFlex alignItems="center" justifyContent="space-between" width="100%">
      {children}
      <ChakraBox display={{ base: 'none', md: 'block' }}>
        <ViewMessagesButton />
      </ChakraBox>
    </ChakraFlex>
  );
};

const topLevelId = 'provider-team';

const SinglePatientProviderList: FC<SinglePatientProviderListProps> = ({
  patient,
  careTeam,
}) => {
  const preferredFirstName = getPreferredProfileFirstName(patient);
  const title = getTitle(preferredFirstName);
  return (
    <SectionContainer>
      <SectionHeaderContainer>
        <SectionTitle id={`${topLevelId}__title`} title={title} />
      </SectionHeaderContainer>
      <ProviderListItem careTeam={careTeam} />
    </SectionContainer>
  );
};

interface ProviderTeamAsSupportProps {
  patients: UserProfile[];
  onPatientClick: (patientId: string) => void;
  selectedPatientId?: string;
}

const ProviderTeamAsSupport: FC<ProviderTeamAsSupportProps> = ({
  patients,
  onPatientClick,
  selectedPatientId,
}) => {
  const { careTeamForPatient } = useCareTeam();
  if (patients.length === 1) {
    return (
      <SinglePatientProviderList
        careTeam={careTeamForPatient(patients[0].externalId)}
        patient={patients[0]}
      />
    );
  }

  const patientsToTabs: PatientTabItemProps[] = patients?.map((p) => ({
    patientId: p.externalId,
    patientName: getPreferredFirstName(p.chosenName, p.firstName),
  }));

  return (
    <SectionContainer>
      <SectionHeaderContainer>
        <PatientTabSelector
          id={`${topLevelId}__tabSelecter`}
          onPatientClick={onPatientClick}
          patientTabs={patientsToTabs}
          title="Provider team"
        />
      </SectionHeaderContainer>
      <ProviderListItem careTeam={careTeamForPatient(selectedPatientId)} />
    </SectionContainer>
  );
};

const ProviderList = () => {
  const { userProfile } = useUserProfileContext();
  const { track } = useAnalytics();
  const { linkedPatients, isPatient, externalId: currentUserId } = userProfile;
  const {
    patientIdsWithCareTeam,
    isCareTeamFetched,
    isLoading,
    careTeamForPatient,
  } = useCareTeam();

  const patients = linkedPatients?.filter((p) =>
    patientIdsWithCareTeam.includes(p.externalId),
  );

  const [selectedPatientId, setSelectedPatientId] = useState<string>();

  useEffect(() => {
    if (isCareTeamFetched) {
      setSelectedPatientId(patientIdsWithCareTeam[0]);
    }
  }, [isCareTeamFetched, patientIdsWithCareTeam]);

  if (isLoading || isCareTeamFetched === false)
    return (
      <ChakraFlex
        alignItems="center"
        height="200px"
        justifyContent="center"
        width="100%"
      >
        <Loading />
      </ChakraFlex>
    );

  if (isCareTeamFetched && patientIdsWithCareTeam.length === 0) {
    return null;
  }

  return (
    <ChakraBox as="section" id={`${topLevelId}__section`}>
      {isPatient ? (
        <SectionContainer>
          <SectionHeaderContainer>
            <SectionTitle id={topLevelId} title="Your provider team" />
          </SectionHeaderContainer>
          <ProviderListItem careTeam={careTeamForPatient(currentUserId)} />
        </SectionContainer>
      ) : (
        <ProviderTeamAsSupport
          onPatientClick={(patientId) => {
            track(EVENT.HomeProviderListPatientSelectorClick);
            setSelectedPatientId(patientId);
          }}
          patients={patients}
          selectedPatientId={selectedPatientId}
        />
      )}
    </ChakraBox>
  );
};

export default ProviderList;
