import { FC, useState } from 'react';

import { EquipUIFireflyV2Theme, Modal } from '@equip.health/ui';

import NotificationPreferencesStep from '~/components/onboarding/NotificationPreferencesStep';
import WelcomeStep from '~/components/onboarding/WelcomeStep';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';

export const OnboardingModalLocalStorageKey = 'has_displayed_onboarding_modal';

const TOP_LEVEL_ID = 'onboarding-modal';

const { colors } = EquipUIFireflyV2Theme;

type OnboardingModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

enum OnboardingStep {
  Welcome = 'welcome',
  NotificationPreferences = 'notification-preferences',
}

const OnboardingModal: FC<OnboardingModalProps> = ({ isOpen, onClose }) => {
  const {
    userProfile: { isFirstLogin },
  } = useUserProfileContext();

  const initialStep = isFirstLogin
    ? OnboardingStep.Welcome
    : OnboardingStep.NotificationPreferences;

  const [currentStep, setCurrentStep] = useState<OnboardingStep>(initialStep);

  return (
    <Modal
      bodyStyle={{
        alignItems: 'center',
        background:
          currentStep === OnboardingStep.Welcome ? colors.primary[10] : 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      hideCloseButton
      hideHeader
      id={TOP_LEVEL_ID}
      isOpen={isOpen}
      modalContentPadding="0"
      onClose={onClose}
      shouldCloseOnEscape={false}
      shouldCloseOnOverlayClick={false}
      size="full"
    >
      {currentStep === OnboardingStep.Welcome && (
        <WelcomeStep
          onComplete={() =>
            setCurrentStep(OnboardingStep.NotificationPreferences)
          }
        />
      )}
      {currentStep === OnboardingStep.NotificationPreferences && (
        <NotificationPreferencesStep onComplete={onClose} />
      )}
    </Modal>
  );
};

export default OnboardingModal;
