import { isNil } from 'lodash';

const StringUtil = {
  escapeRegExp: (text: string): string => {
    if (text) return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

    return text;
  },

  joinStringList: (list: string[], endSeparator = 'and'): string | null => {
    if (isNil(list)) {
      return null;
    }

    const filteredList = list.filter((name) => name);

    const listLength = filteredList.length;
    const newString = filteredList.reduce<string>(
      (prevString, currentString, index) => {
        if (index === 0) {
          return currentString;
        }

        if (index === listLength - 1 && listLength === 2) {
          return `${prevString} ${endSeparator} ${currentString}`;
        }

        if (index === listLength - 1) {
          return `${prevString}, ${endSeparator} ${currentString}`;
        }

        return `${prevString}, ${currentString}`;
      },
      null,
    );

    return newString;
  },

  replaceAll: (text: string, match: string, replacement: string): string => {
    if (text && match && replacement) {
      return text.replace(
        new RegExp(StringUtil.escapeRegExp(match), 'g'),
        () => replacement,
      );
    }

    return text;
  },
};

export default StringUtil;
