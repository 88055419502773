import { FC } from 'react';

import { ChakraText, EquipUIFireflyV1Theme, Modal } from '@equip.health/ui';

const { paragraphSemibold, paragraph } = EquipUIFireflyV1Theme.typography;

const TOP_LEVEL_ID = 'call-out';

export interface CalloutModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const CalloutModal: FC<CalloutModalProps> = ({ isOpen, handleClose }) => {
  return (
    <Modal
      id={TOP_LEVEL_ID}
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
      title={
        <ChakraText
          {...paragraphSemibold}
          color="mono.70"
          fontSize="16px"
          id={`${TOP_LEVEL_ID}__title`}
        >
          What happens after submitting this form?
        </ChakraText>
      }
    >
      <ChakraText
        {...paragraph}
        color="mono.70"
        id={`${TOP_LEVEL_ID}__message`}
        padding="9px 0px 8px"
      >
        1. Our admissions team will reach out to the patient or parent/guardian.
        <br />
        2. We will reach out to you within 3 business days to discuss further.
      </ChakraText>
    </Modal>
  );
};

export default CalloutModal;
