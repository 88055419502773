import * as Sentry from '@sentry/react';
import isNil from 'lodash/isNil';

import urlConstants from '~/lib/constants/url.constants';
import { sortGroupClassesByRegistrationStatusAndStartDate } from '~/lib/util/schedule/groupClasses.util';

import api from './api';

const {
  registerForGroupClass: registerForGroupClassUrl,
  unregisterForGroupClass: unregisterForGroupClassUrl,
  getGroupClasses: getGroupClassesUrl,
} = urlConstants.schedule;

export interface GetGCRequest {
  patientId?: string;
}

interface GCRegistrationRequest {
  groupClassExternalId: string;
  patientExternalId?: string;
}

interface GroupClassRegistrationResponse {
  data?: string;
  status?: string;
}

const getGroupClassesOptions = (patientId?: string) => {
  const params = { 'page-size': 200 };

  if (patientId) {
    params['patient-external-id'] = patientId;
  }

  return {
    params,
    url: getGroupClassesUrl,
  };
};

export const scheduleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getGroupClasses: build.query<GroupClass[], GetGCRequest>({
      query: ({ patientId }) => getGroupClassesOptions(patientId),
      transformResponse: (
        response: GetGroupClassesResponse,
        meta,
        arg,
      ): GroupClass[] => {
        const {
          data: { data },
        } = response;

        const { patientId } = arg;

        /*
         If patientId is not provided, we assume it's fetching group classes
         for the current user. In this case, we want to include sensitive group classes
         in the sorting criteria.
        */
        const shouldSortSensitiveGroupClasses = isNil(patientId);
        const sortedGroupClasses =
          sortGroupClassesByRegistrationStatusAndStartDate(
            data,
            shouldSortSensitiveGroupClasses,
          );
        return sortedGroupClasses;
      },
    }),
    registerForGroupClass: build.mutation<
      GroupClassRegistrationResponse,
      GCRegistrationRequest
    >({
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        // Pessimistic Update - Wait for the query to be fulfilled before updating the cache

        try {
          await queryFulfilled;
          dispatch(
            scheduleApi.util.updateQueryData(
              'getGroupClasses',
              {
                patientId: arg.patientExternalId,
              },
              (draft) => {
                const groupClass = draft.find(
                  (gc) => gc.groupClassExternalId === arg.groupClassExternalId,
                );

                if (groupClass) {
                  groupClass.isUserRegistered = true;
                }
              },
            ),
          );
        } catch (error) {
          Sentry.captureException(error);
        }
      },
      query: ({ groupClassExternalId, patientExternalId }) => ({
        body: patientExternalId ? { patientExternalId } : {},
        method: 'POST',
        url: registerForGroupClassUrl(groupClassExternalId),
      }),
    }),
    unregisterForGroupClass: build.mutation<
      GroupClassRegistrationResponse,
      GCRegistrationRequest
    >({
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        // Pessimistic Update - Wait for the query to be fulfilled before updating the cache
        try {
          await queryFulfilled;
          dispatch(
            scheduleApi.util.updateQueryData(
              'getGroupClasses',
              {
                patientId: arg.patientExternalId,
              },
              (draft) => {
                const groupClass = draft.find(
                  (gc) => gc.groupClassExternalId === arg.groupClassExternalId,
                );

                if (groupClass) {
                  groupClass.isUserRegistered = false;
                }
              },
            ),
          );
        } catch (error) {
          Sentry.captureException(error);
        }
      },
      query: ({ groupClassExternalId, patientExternalId }) => ({
        body: patientExternalId ? { patientExternalId } : {},
        method: 'POST',
        url: unregisterForGroupClassUrl(groupClassExternalId),
      }),
    }),
  }),
});

export const {
  useGetGroupClassesQuery,
  useRegisterForGroupClassMutation,
  useUnregisterForGroupClassMutation,
} = scheduleApi;
