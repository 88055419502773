import { FC, useState } from 'react';

import { isNil } from 'lodash';

import BookAppointmentStepContainer, {
  ListContainer,
} from '~/components/schedule/BookAppointment/base/StepContainer';
import AppointmentOptionsCard from '~/components/schedule/BookAppointment/cards/AppointmentOptionsCard';
import {
  BOOK_APPOINTMENT_STEP,
  appointmentRecurrenceOptions,
} from '~/lib/constants/bookAppointments';
import { useBookAppointmentContext } from '~/lib/context/BookAppointmentContext';
import { Recurrence } from '~/lib/util/schedule.util';

const topLevelId = 'book-appointment__recurrence';

const BookAppointmentAppointmentRecurrence: FC = () => {
  const { setNextStep, setSelectedAppointmentRecurrence } =
    useBookAppointmentContext();

  const [selectedRecurrence, setSelectedRecurrence] =
    useState<Recurrence>(null);

  const handleForwardNavigation = () => {
    setNextStep(BOOK_APPOINTMENT_STEP.availability);
    setSelectedAppointmentRecurrence(selectedRecurrence);
  };

  const handleBackwardNavigation = () => {
    setNextStep(BOOK_APPOINTMENT_STEP.duration);
  };

  return (
    <BookAppointmentStepContainer
      isBackButtonVisible
      isContinueButtonDisabled={isNil(selectedRecurrence)}
      onBackwardNavigation={handleBackwardNavigation}
      onForwardNavigation={handleForwardNavigation}
      stepId={topLevelId}
      title="Would you like to book a recurring appointment?"
    >
      <ListContainer>
        {appointmentRecurrenceOptions?.map((option) => {
          const { recurrence, name, tooltip, id } = option;
          return (
            <AppointmentOptionsCard
              id={id}
              isSelected={selectedRecurrence === recurrence}
              key={id}
              onClick={() => setSelectedRecurrence(recurrence as Recurrence)}
              primaryLabel={name}
              tooltip={tooltip}
            />
          );
        })}
      </ListContainer>
    </BookAppointmentStepContainer>
  );
};

export default BookAppointmentAppointmentRecurrence;
