import { FC, useEffect } from 'react';
import { PAGE_NAME } from '~/lib/constants/analytics';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';

const ThankYouPage: FC = () => {
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView(PAGE_NAME.thankyou);
  }, []);

  useEffect(() => {
    window.location.href = '/';
  }, []);
  return null;
};

export default ThankYouPage;
