import { FC } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  ChakraText,
  EquipUITheme,
  FAIconName,
  FireflyButton,
  Icon,
  IconButton,
  Menu,
  MenuOption,
} from '@equip.health/ui';

const CTA_HEIGHT = '42px';

const { caption, paragraph } = EquipUITheme.typography;
const { brand } = EquipUITheme.colors;

const TOP_LEVEL_ID = 'survey-navigation';

type NavigationProps = {
  currentPage: number;
  isLoading: boolean;
  left: string;
  onNext: () => void;
  onPrevious: () => void;
  onSkip: (page: string) => void;
  onSubmit: () => void;
  pages: MenuOption[];
  saveError: string;
};

const Navigation: FC<NavigationProps> = ({
  currentPage,
  isLoading,
  left,
  onNext,
  onPrevious,
  onSkip,
  onSubmit,
  pages,
  saveError,
}) => {
  const proceedButtonText =
    currentPage === pages.length - 1 ? 'Submit' : 'Save and continue';

  const handleNextClick = () =>
    currentPage === pages.length - 1 ? onSubmit() : onNext();

  return (
    <ChakraFlex
      alignItems="center"
      background="white"
      bottom={0}
      boxShadow="2px -2px 2px 1px rgb(0 0 0 / 20%)"
      height="90px"
      left={{ base: '0px', lg: left }}
      paddingX="16px"
      position="fixed"
      width={{ base: '100%', lg: `calc(100vw - ${left})` }}
    >
      <ChakraFlex direction="column" width="100%">
        {saveError && (
          <ChakraText
            {...caption}
            bottom="70px"
            color="error"
            display={{ base: 'block', md: 'none' }}
            id="survey-navigation__error-small"
            position="absolute"
            right="24px"
            textAlign="right"
          >
            Error saving results
          </ChakraText>
        )}
        <ChakraFlex alignItems="center" justifyContent="space-between">
          <ChakraFlex alignItems="center" height="100%">
            {currentPage > 0 && (
              <ChakraBox marginRight="12px">
                <ChakraBox
                  display={{ base: 'block', md: 'none' }}
                  id={`${TOP_LEVEL_ID}__previous-small-container`}
                >
                  <IconButton
                    icon={
                      <Icon
                        color="white"
                        name={FAIconName.chevronLeftRegular}
                      />
                    }
                    id={`${TOP_LEVEL_ID}__previous-small`}
                    isDisabled={isLoading}
                    onClick={onPrevious}
                    variant="outline"
                  />
                </ChakraBox>
                <ChakraBox
                  display={{ base: 'none', md: 'block' }}
                  id={`${TOP_LEVEL_ID}__previous-large-container`}
                >
                  <FireflyButton
                    id={`${TOP_LEVEL_ID}__previous-large`}
                    isDisabled={isLoading}
                    leftIcon={FAIconName.chevronLeftRegular}
                    onClick={onPrevious}
                    variant="secondary"
                  >
                    Back
                  </FireflyButton>
                </ChakraBox>
              </ChakraBox>
            )}
            {pages.length > 1 && (
              <ChakraBox
                overflow={{ base: 'visible', lg: 'hidden' }}
                width={{ base: '200px', lg: 0 }}
              >
                <Menu
                  border={`1px solid ${brand[500]}`}
                  height={CTA_HEIGHT}
                  icon={
                    <Icon
                      color={brand[500]}
                      name={FAIconName.chevronDownRegular}
                    />
                  }
                  id={`${TOP_LEVEL_ID}__pages`}
                  listMaxHeight="220px"
                  onSelect={onSkip}
                  options={pages}
                  placeholder="Skip to section..."
                  placeholderFontSize="12px"
                />
              </ChakraBox>
            )}
          </ChakraFlex>
          <ChakraFlex
            alignItems="center"
            height="100%"
            justifyContent="flex-end"
            minWidth="max-content"
          >
            {saveError && (
              <ChakraText
                {...paragraph}
                color="error"
                display={{ base: 'none', md: 'block' }}
                id={`${TOP_LEVEL_ID}__error-large`}
                marginRight="8px"
              >
                Error saving results
              </ChakraText>
            )}
            <ChakraBox display={{ base: 'block', md: 'none' }}>
              <IconButton
                height={CTA_HEIGHT}
                icon={
                  <Icon color="white" name={FAIconName.chevronRightRegular} />
                }
                id={`${TOP_LEVEL_ID}__next-small`}
                isDisabled={isLoading}
                onClick={handleNextClick}
              />
            </ChakraBox>
            <ChakraBox display={{ base: 'none', md: 'block' }}>
              <FireflyButton
                id={`${TOP_LEVEL_ID}__next-large`}
                isDisabled={isLoading}
                onClick={handleNextClick}
                rightIcon={FAIconName.chevronRightRegular}
              >
                {proceedButtonText}
              </FireflyButton>
            </ChakraBox>
          </ChakraFlex>
        </ChakraFlex>
      </ChakraFlex>
    </ChakraFlex>
  );
};

export default Navigation;
