import { FC, useState } from 'react';

import isNil from 'lodash/isNil';

import BookAppointmentStepContainer, {
  ListContainer,
} from '~/components/schedule/BookAppointment/base/StepContainer';
import AppointmentOptionsCard from '~/components/schedule/BookAppointment/cards/AppointmentOptionsCard';
import { BOOK_APPOINTMENT_STEP } from '~/lib/constants/bookAppointments';
import { useBookAppointmentContext } from '~/lib/context/BookAppointmentContext';

const topLevelId = 'book-appointment__duration';

const BookAppointmentAppointmentDuration: FC = () => {
  const {
    setNextStep,
    selectedAppointmentType: selectedProvider,
    setSelectedAppointmentTypeId,
  } = useBookAppointmentContext();

  const appointmentOptions = selectedProvider?.appointmentTypes;
  const [selectedOptionId, setSelectedOptionId] = useState<string>(null);

  const handleForwardNavigation = () => {
    setNextStep(BOOK_APPOINTMENT_STEP.recurrence);
    setSelectedAppointmentTypeId(selectedOptionId);
  };

  const handleBackwardNavigation = () => {
    setNextStep(BOOK_APPOINTMENT_STEP.attendees);
  };

  return (
    <BookAppointmentStepContainer
      isBackButtonVisible
      isContinueButtonDisabled={isNil(selectedOptionId)}
      onBackwardNavigation={handleBackwardNavigation}
      onForwardNavigation={handleForwardNavigation}
      stepId={topLevelId}
      title="How long would you like to meet?"
    >
      <ListContainer>
        {appointmentOptions?.map((option) => {
          const { appointmentTypeExtId, durationInMinutes } = option;
          const durationLabel = `${durationInMinutes} minutes`;
          return (
            <AppointmentOptionsCard
              id={appointmentTypeExtId}
              isSelected={appointmentTypeExtId === selectedOptionId}
              key={appointmentTypeExtId}
              onClick={() => setSelectedOptionId(appointmentTypeExtId)}
              primaryLabel={durationLabel}
            />
          );
        })}
      </ListContainer>
    </BookAppointmentStepContainer>
  );
};

export default BookAppointmentAppointmentDuration;
