import { FC } from 'react';

import {
  BadFileAttachmentView,
  ChakraBox,
  ChakraFlex,
  ChakraImage,
  ChakraVStack,
  EquipUIFireflyV1Theme,
  FAIconName,
  Icon,
} from '@equip.health/ui';

import Loading from '~/components/common/Loading';
import {
  fileExtensionToIconMapper,
  getDocumentTypeFromFileName,
} from '~/lib/util/patientDocuments.util';

const { colors } = EquipUIFireflyV1Theme;
const { black } = colors;

export const LoaderOverlay: FC = () => {
  return (
    <ChakraFlex
      backgroundColor="#FFFFFFCC"
      borderRadius="10px"
      bottom="0"
      justifyContent="center"
      left="0"
      overflow="hidden"
      position="absolute"
      right="0"
      top="0"
    >
      <Loading size="24px" />
    </ChakraFlex>
  );
};

export const RemoveFileButton: FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <ChakraBox
    background="white"
    borderRadius="50%"
    cursor="pointer"
    height="24px"
    onClick={onClick}
    padding="3px"
    position="absolute"
    right="-10px"
    top="-10px"
    width="24px"
  >
    <ChakraBox background="black.60" borderRadius="50%" position="absolute">
      <Icon color="white" name={FAIconName.timesSolid} size="sm" />
    </ChakraBox>
  </ChakraBox>
);

interface ImageFileAttachmentTileProps {
  file: File;
  handleRemoveFile: () => void;
  isUploading: boolean;
  topLevelId: string;
}

export const ImageFileAttachmentTile: FC<ImageFileAttachmentTileProps> = ({
  file,
  handleRemoveFile,
  isUploading,
  topLevelId,
}) => (
  <ChakraVStack alignItems="start" spacing="8px">
    <ChakraBox id={`${topLevelId}__${file.name}`} position="relative">
      <ChakraBox
        border={`1px solid ${black[30]}`}
        borderRadius="10px"
        overflow="hidden"
      >
        <ChakraImage
          alt={file.name}
          boxSize="58"
          fit="cover"
          src={URL.createObjectURL(file)}
        />
      </ChakraBox>
      {isUploading ? (
        <LoaderOverlay />
      ) : (
        <RemoveFileButton onClick={handleRemoveFile} />
      )}
    </ChakraBox>
  </ChakraVStack>
);

interface FileAttachmentThumbnailViewProps {
  fileName: string;
  isValidatingFile: boolean;
  isInvalidFile: boolean;
}

export const FileAttachmentThumbnailView = ({
  fileName,
  isValidatingFile,
  isInvalidFile,
}: FileAttachmentThumbnailViewProps) => {
  if (isValidatingFile) {
    return <Loading size="28px" />;
  }

  if (isInvalidFile) {
    return <BadFileAttachmentView size="md" />;
  }

  const fileType = getDocumentTypeFromFileName(fileName);
  const FileIcon = fileExtensionToIconMapper[fileType];
  return <FileIcon width="40px" />;
};
