import { AddressData } from '@equip.health/ui';

import { GOOGLE_ADDRESS_EMPTY_VALUE } from '~/lib/constants';
import { parsePhoneNumberWithDefaultCountry } from '~/lib/utils';

export enum ProfileFieldActionType {
  INITIALIZE,
  UPDATE_FIRST_NAME,
  UPDATE_LAST_NAME,
  UPDATE_CHOSEN_NAME,
  UPDATE_DATE_OF_BIRTH,
  UPDATE_GENDER,
  UPDATE_PRONOUNS,
  UPDATE_LANGUAGE,
  UPDATE_EMAIL,
  UPDATE_PHONE_NUMBER,
  UPDATE_CONTACT_PREFERENCE,
  UPDATE_ADDRESS,
  UPDATE_TIMEZONE,
  UPDATE_NOTIFICATION_SMS,
  UPDATE_NOTIFICATION_EMAIL,
}

export type ProfileFieldReducerAction = {
  type: ProfileFieldActionType;
  value?: AddressData | UserProfile | string[] | string | boolean;
};

export type ProfileFieldReducerProps = (
  state: UserProfile,
  action: ProfileFieldReducerAction,
) => UserProfile;

const ProfileFieldReducer = (
  state: UserProfile,
  action: ProfileFieldReducerAction,
): UserProfile => {
  const { type, value } = action;
  switch (type) {
    case ProfileFieldActionType.INITIALIZE:
      return {
        ...(value as UserProfile),
        address: {
          ...GOOGLE_ADDRESS_EMPTY_VALUE,
          ...(value as UserProfile).address,
          zipCode: (value as UserProfile).address?.zip,
        },
        cellPhone: parsePhoneNumberWithDefaultCountry(
          (value as UserProfile)?.cellPhone ?? '',
        ),
      };
    case ProfileFieldActionType.UPDATE_FIRST_NAME:
      return {
        ...state,
        firstName: value as string,
      };
    case ProfileFieldActionType.UPDATE_LAST_NAME:
      return {
        ...state,
        lastName: value as string,
      };
    case ProfileFieldActionType.UPDATE_CHOSEN_NAME:
      return {
        ...state,
        chosenName: value as string,
      };
    case ProfileFieldActionType.UPDATE_GENDER:
      return {
        ...state,
        gender: value as string,
      };
    case ProfileFieldActionType.UPDATE_PRONOUNS:
      return {
        ...state,
        pronouns: value as string[],
      };
    case ProfileFieldActionType.UPDATE_DATE_OF_BIRTH:
      return {
        ...state,
        dateOfBirth: value as string,
      };
    case ProfileFieldActionType.UPDATE_PHONE_NUMBER:
      return {
        ...state,
        cellPhone: value as string,
      };
    case ProfileFieldActionType.UPDATE_EMAIL:
      return {
        ...state,
        email: value as string,
      };
    case ProfileFieldActionType.UPDATE_CONTACT_PREFERENCE:
      return {
        ...state,
        contactPreferences: value as string[],
      };
    case ProfileFieldActionType.UPDATE_LANGUAGE:
      return {
        ...state,
        languages: value as string[],
      };
    case ProfileFieldActionType.UPDATE_ADDRESS:
      return {
        ...state,
        address: value as AddressData,
      };
    case ProfileFieldActionType.UPDATE_NOTIFICATION_EMAIL:
      return {
        ...state,
        preferences: {
          notifications: {
            receiveEmails: value as boolean,
            receiveSms: state.preferences.notifications.receiveSms,
          },
        },
      };
    case ProfileFieldActionType.UPDATE_NOTIFICATION_SMS:
      return {
        ...state,
        preferences: {
          notifications: {
            receiveEmails: state.preferences.notifications.receiveEmails,
            receiveSms: value as boolean,
          },
        },
      };
    default:
      return { ...state };
  }
};

export default ProfileFieldReducer;
