import { SetStateAction, useEffect, useRef, useState } from 'react';

import { isNil } from 'lodash';

import useLocalStorage from './useLocalStorage';

export type ChatAttachmentDataType = {
  name: string;
  url: string;
};

interface S3AttachmentDataType {
  downloadTimestamp: number;
  expirationTimestamp: number;
  name: string;
  url: string;
}

export type ChatAttachmentsState = {
  getDownloadURLForS3Attachment: (
    fileName: string,
  ) => string | undefined | null;
  setDownloadURLForS3Attachment: (fileName: string, url: string) => void;
  setShowChatAttachmentModal: React.Dispatch<
    SetStateAction<ChatAttachmentDataType>
  >;
  showChatAttachmentModal: ChatAttachmentDataType | null;
};

export const FILE_MESSAGES_CACHE_KEY = 'equip.filemessages';

const useChatAttachments = (): ChatAttachmentsState => {
  const attachmentsRef = useRef<S3AttachmentDataType[]>([]);

  const [showChatAttachmentModal, setShowChatAttachmentModal] =
    useState<ChatAttachmentDataType | null>(null);

  const { setValue, getValueFromLocalStorage } = useLocalStorage(
    FILE_MESSAGES_CACHE_KEY,
    [],
  );

  useEffect(() => {
    attachmentsRef.current = getValueFromLocalStorage();
  }, []);

  const getDownloadURLForS3Attachment = (fileName: string) => {
    const attachment = attachmentsRef.current?.find(
      ({ name }) => name === fileName,
    );

    if (isNil(attachment)) {
      return null;
    }

    const expiration = attachment.expirationTimestamp;

    if (expiration < new Date().getTime()) {
      const newS3Attachments = attachmentsRef.current.filter(
        (attachment) => attachment.name !== fileName,
      );

      attachmentsRef.current = newS3Attachments;
      setValue(newS3Attachments);
      return null;
    }

    return attachment.url;
  };

  const setDownloadURLForS3Attachment = (fileName: string, url: string) => {
    const params = new URL(url).searchParams;
    const awsExpiration = params.get('X-Amz-Expires');
    const expirationTime = parseInt(awsExpiration);

    const newS3Attachments = [
      ...attachmentsRef.current,
      {
        name: fileName,
        url,
        downloadTimestamp: Date.now(),
        expirationTimestamp: Date.now() + 1000 * expirationTime,
      },
    ];

    attachmentsRef.current = newS3Attachments;
    setValue(newS3Attachments);
  };

  return {
    getDownloadURLForS3Attachment,
    setDownloadURLForS3Attachment,
    setShowChatAttachmentModal,
    showChatAttachmentModal,
  };
};

export default useChatAttachments;
