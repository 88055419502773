import { FC, useEffect, useState } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  EquipUIFireflyV2Theme,
  FAIconName,
  FireflyButton,
  Icon,
  Modal,
  Text,
} from '@equip.health/ui';

import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { useApi } from '~/lib/hooks';

const CANCELLATION_REASON = 'Equip patient or support needs to cancel';

const { neutral } = EquipUIFireflyV2Theme.colors;
const { deleteAppointment: deleteAppointmentURL } = urlConstants.schedule;

const CancelAppointmentModal: FC<{
  appointmentId: string;
  isOpen: boolean;
  isRecurring: boolean;
  onCancelAppointment: () => void;
  onClose: () => void;
}> = ({ appointmentId, isOpen, isRecurring, onCancelAppointment, onClose }) => {
  const [isCancelAllClicked, setCancelAllClicked] = useState<boolean>(false);
  const { error, loading, resetError, sendRequest, statusCode } = useApi();

  useEffect(() => {
    if (isOpen) {
      resetError();
      setCancelAllClicked(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (statusCode === 200) {
      onCancelAppointment();
      onClose();
    }
  });

  const cancelAppointment = (cancelAllFutureEvent: boolean) => {
    setCancelAllClicked(cancelAllFutureEvent);
    sendRequest({
      url: deleteAppointmentURL,
      command: ApiCommand.PATCH,
      options: {
        all: cancelAllFutureEvent,
        appointmentExternalId: appointmentId,
        cancellationReason: CANCELLATION_REASON,
      },
    });
  };

  return (
    <Modal
      hideCloseButton
      hideHeader
      isLazy
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <ChakraFlex alignItems="center" direction="column" marginBottom="20px">
        <ChakraFlex
          alignItems="center"
          direction="row-reverse"
          justifyContent="space-between"
          marginBottom="4px"
          width="100%"
        >
          <ChakraBox
            height="32px"
            onClick={onClose}
            paddingBottom="8px"
            paddingTop="8px"
            role="button"
            width="32px"
          >
            <Icon
              color={neutral.secondary}
              name={FAIconName.timesSolid}
              size="lg"
            />
          </ChakraBox>
        </ChakraFlex>
        <Text color="neutral.primary" textAlign="center" variant="h3">
          Cancel event
        </Text>
        <Text
          color="neutral.primary"
          marginBottom="24px"
          marginTop="12px"
          textAlign="center"
        >
          {isRecurring
            ? 'Would you like to cancel just this event or all future events in this series?'
            : 'Are you sure you want to cancel?'}
        </Text>

        <ChakraFlex>
          <ChakraBox marginRight="12px">
            <FireflyButton
              id="cancel-all-appointment-modal__cancel"
              isLoading={loading && isCancelAllClicked}
              onClick={() => {
                if (isRecurring) cancelAppointment(true);
                else onClose();
              }}
              size="normal"
              variant="secondary"
            >
              {isRecurring ? 'All future events' : 'No, go back'}
            </FireflyButton>
          </ChakraBox>
          <ChakraBox marginLeft="12px">
            <FireflyButton
              id="cancel-appointment-modal__cancel"
              isLoading={loading && !isCancelAllClicked}
              onClick={() => cancelAppointment(false)}
              size="normal"
              variant="primary"
            >
              {isRecurring ? 'Just this event' : 'Yes, cancel'}
            </FireflyButton>
          </ChakraBox>
        </ChakraFlex>
        {error && (
          <Text color="error" paddingTop="8px" variant="body">
            {error || 'Unable to cancel.'}
          </Text>
        )}
      </ChakraFlex>
    </Modal>
  );
};

export default CancelAppointmentModal;
