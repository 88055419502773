export const mapUserProfileToUserInfo = (
  userProfile: UserProfile,
): AnalyticsUserInfo => {
  const { adminEpisodeStatus, supportedBy, linkedPatients, gender } =
    userProfile;

  const numberOfSupports = supportedBy?.length;
  const numberOfLinkedPatients = linkedPatients?.length;

  return {
    adminEpisodeStatus,
    gender,
    numberOfLinkedPatients,
    numberOfSupports,
  };
};
