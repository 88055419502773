import { FC } from 'react';

import { isNil } from 'lodash';
import { DateTime } from 'luxon';

import DocIcon from '~/assets/svg/FileTypes/DocIcon';
import ExcelIcon from '~/assets/svg/FileTypes/ExcelIcon';
import GenericIcon from '~/assets/svg/FileTypes/GenericIcon';
import PDFIcon from '~/assets/svg/FileTypes/PDFIcon';
import { getFileExtension, getInputAccept } from '~/lib/util/file.util';
import { getPreferredFullName } from '~/lib/utils';

export const CHAT_ACCEPTED_IMAGE_EXTENSION = ['jpg', 'jpeg', 'png'];
export const CHAT_ACCEPTED_PDF_EXTENSION = ['pdf'];
export const CHAT_ACCEPTED_WORD_EXTENSION = ['doc', 'docx'];
export const CHAT_ACCEPTED_EXCEL_EXTENSION = ['xls', 'xlsx'];

export const CHAT_ACCEPTED_FILE_EXTENSION = [
  ...CHAT_ACCEPTED_IMAGE_EXTENSION,
  ...CHAT_ACCEPTED_PDF_EXTENSION,
  ...CHAT_ACCEPTED_WORD_EXTENSION,
  ...CHAT_ACCEPTED_EXCEL_EXTENSION,
];

export const CHAT_INPUT_ACCEPT = getInputAccept(CHAT_ACCEPTED_FILE_EXTENSION);

export enum DocumentType {
  PDF = 'pdf',
  WORD = 'word',
  EXCEL = 'excel',
  GENERIC = 'generic',
  IMAGE = 'image',
}

export const fileExtensionToIconMapper: Record<DocumentType, FC<BaseSVG>> = {
  [DocumentType.PDF]: PDFIcon,
  [DocumentType.WORD]: DocIcon,
  [DocumentType.EXCEL]: ExcelIcon,
  [DocumentType.GENERIC]: GenericIcon,
  [DocumentType.IMAGE]: GenericIcon,
};

export const getDocumentTypeFromFileName = (fileName: string): DocumentType => {
  if (!fileName) {
    return DocumentType.GENERIC;
  }

  const fileExtension = getFileExtension(fileName.toLowerCase());

  if (CHAT_ACCEPTED_PDF_EXTENSION.includes(fileExtension)) {
    return DocumentType.PDF;
  }

  if (CHAT_ACCEPTED_WORD_EXTENSION.includes(fileExtension)) {
    return DocumentType.WORD;
  }

  if (CHAT_ACCEPTED_EXCEL_EXTENSION.includes(fileExtension)) {
    return DocumentType.EXCEL;
  }

  if (CHAT_ACCEPTED_IMAGE_EXTENSION.includes(fileExtension)) {
    return DocumentType.IMAGE;
  }

  return DocumentType.GENERIC;
};

// a. if created date is 0-2 days ago, returns relative date (e.g. "yesterday", "today")
// b. else if created date was in the same year, returns "{dayOfWeek}, {month} {day}"
// c. else if created date was in any other year, returns "{month} {day}, {year}"
export const getRelativeCreationDate = (createdDate: string): string => {
  const date = DateTime.fromISO(createdDate);
  const today = DateTime.now();

  if (
    [
      today.toLocaleString(),
      today.minus({ days: 1 }).toLocaleString(),
    ].includes(date.toLocaleString())
  ) {
    return date.toRelativeCalendar({ unit: 'days' });
  }

  if (date.year === today.year) {
    const dateString = date
      .toFormat('ccc, LLLL d')
      .replace(today.year.toFixed(), '');
    return `on ${dateString}`;
  }

  const dateString = date.toFormat('LLLL d, yyyy');

  return `on ${dateString}`;
};

export const getAuthorName = (
  currentUserId: string,
  author: PatientDocumentAuthor,
): string => {
  if (isNil(author)) {
    return 'Sent';
  }

  if (author.externalId === currentUserId) {
    return 'Sent by you';
  }

  const { firstName, lastName, chosenName } = author;
  const authorName = getPreferredFullName(chosenName, firstName, lastName);

  return authorName ? `Sent by ${authorName}` : 'Sent';
};

export const getDocumentDescription = (
  currentUserId: string,
  author: PatientDocumentAuthor,
  createdDate: string,
): string => {
  const authorName = getAuthorName(currentUserId, author);
  const relativeDate = getRelativeCreationDate(createdDate);

  return `${authorName} ${relativeDate}`;
};
