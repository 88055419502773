import { useAppDispatch, useAppSelector } from '.';
import { setIsWeightPrivacyModeEnabled as setIsWeightPrivacyModeEnabledAction } from '~/store/reducers/userPreferences';

export const useUserPreferencesSelector = () => {
  return useAppSelector((state) => state.userPreferences);
};

export const useUserPreferencesDispatch = () => {
  const dispatch = useAppDispatch();

  const setIsWeightPrivacyModeEnabled = (enabled: boolean) => {
    dispatch(setIsWeightPrivacyModeEnabledAction(enabled));
  };

  return {
    setIsWeightPrivacyModeEnabled,
  };
}; 