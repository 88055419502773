import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';

import { ApiCommand } from '~/lib/Api';
import {
  CalendarResource,
  CalendarResourceMapper,
} from '~/lib/constants/schedule.constants';
import urlConstants from '~/lib/constants/url.constants';
import useApi from '~/lib/hooks/useApi';

const useScheduleElement = (
  resource: CalendarResource,
): {
  elementToken: string;
  generateElementToken: (subs?: string[]) => Promise<void>;
  options: CalendarResourceConfigOptions;
  refreshElement: () => void;
  setOptions: Dispatch<SetStateAction<CalendarResourceConfigOptions>>;
} => {
  const [element, setElement] = useState(null);
  const [elementToken, setElementToken] = useState<string>(null);
  const [options, setOptions] = useState<CalendarResourceConfigOptions>(null);

  const {
    data: elementTokenData,
    error: getElementTokenError,
    loading: isGetElementTokenProcessing,
    sendRequest: getElementToken,
  } = useApi<ElementTokenData>();

  const generateElementToken = async (subs?: string[]): Promise<void> => {
    if (!isGetElementTokenProcessing) {
      getElementToken({
        command: ApiCommand.POST,
        options: {
          origin: import.meta.env.VITE_MY_EQUIP_BASE_URL,
          subs,
        },
        skipToken: true,
        url: urlConstants.calendar.getElementToken,
      });
    }
  };

  useEffect(() => {
    setElementToken(
      (elementToken: string) =>
        elementTokenData?.elementToken?.token ?? elementToken,
    );
  }, [elementTokenData]);

  /** Handle initializing / updating calendar element */
  useEffect(() => {
    if (options?.target_id) {
      const newOptions = { ...options, element_token: elementToken };
      if (!element) {
        setElement(CalendarResourceMapper[resource].element(newOptions));
      } else {
        element.update(newOptions);
      }
    }
  }, [elementToken, options]);
  useEffect(() => {
    if (getElementTokenError) {
      Sentry.captureMessage(`Element Token Error - ${getElementTokenError}`);
    }
  }, [getElementTokenError]);

  /** Reloads an element to pull in any recent changes since the element first loaded */
  const refreshElement = (): void => {
    element.refresh();
  };

  return {
    elementToken,
    generateElementToken,
    options,
    refreshElement,
    setOptions,
  };
};

export default useScheduleElement;
