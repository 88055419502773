import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  ChakraBox,
  ChakraCollapse,
  ChakraFlex,
  DatePicker,
  EquipUIFireflyV2Theme,
  FAIconName,
  FireflyButton,
  Modal,
  NumberInput,
  Text,
  useBreakpointValue,
} from '@equip.health/ui';
import * as Sentry from '@sentry/react';

import TaskModalTitle from '../../TaskModalTitle';
import DiscardChangesModal from '~/components/common/DiscardChangesModal';
import ErrorMessage from '~/components/common/ErrorMessage';
import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useApi } from '~/lib/hooks';
import useLocalStorage from '~/lib/hooks/useLocalStorage';
import { getPreferredFirstName } from '~/lib/utils';

const { neutral } = EquipUIFireflyV2Theme.colors;
const { saveVitals: saveVitalsUrl } = urlConstants.clinicalData;

const LogWeightDescription: FC = () => (
  <>
    You or your loved one should take your weight in the morning prior to eating
    or drinking. Wear lightweight clothing like shorts and a t-shirt, and avoid
    placing objects in hair or on clothing. Please urinate prior to taking the
    weight.
    <br />
    <br />
    Use a scale to record the weight, then put the scale away until the next
    recommended weight check. If you're taking weight for a loved one, do not
    share the weight with them unless your treatment team has instructed you
    otherwise.
  </>
);

const VITALS_DESCRIPTION_VIEWED = 'VitalsDescriptionViewed';

const topLevelId = 'add-vitals__weight__modal';

const AddVitalsModal: FC<{
  reloadTodoList: () => void;
  showAddVitalModal: Task | null;
  setShowAddVitalModal: Dispatch<SetStateAction<Task | null>>;
}> = ({ reloadTodoList, showAddVitalModal, setShowAddVitalModal }) => {
  const { storedValue: showDescriptionLS, setValue: setShowDescriptionLS } =
    useLocalStorage(VITALS_DESCRIPTION_VIEWED, {});

  const [dateTime, setDateTime] = useState<Date>(new Date());
  const [vitalValue, setVitalValue] = useState<number | undefined>(undefined);

  const { userProfile } = useUserProfileContext();

  const [showDiscardChangesModal, setShowDiscardChangesModal] =
    useState<boolean>(false);
  const [hideDirections, setHideDirections] = useState<boolean>(false);

  const {
    error: completeTaskError,
    loading: completeTaskLoading,
    resetError: resetCompleteTaskError,
    sendRequest: completeTask,
    statusCode: completeTaskStatusCode,
  } = useApi();

  useEffect(() => {
    if (showAddVitalModal) {
      setHideDirections((showDescriptionLS ?? {})[userProfile?.externalId]);
      const object = {};
      object[userProfile?.externalId] = true;
      setShowDescriptionLS(object);

      try {
        setHideDirections((showDescriptionLS ?? {})[userProfile?.externalId]);
      } catch (e) {}
      resetCompleteTaskError();
      setDateTime(new Date());
      setVitalValue(undefined);
    }
  }, [showAddVitalModal]);

  useEffect(() => {
    if (completeTaskError) Sentry.captureException(completeTaskError);
  }, [completeTaskError]);

  const modalSize = useBreakpointValue({
    base: 'full',
    lg: '3xl',
  });

  useEffect(() => {
    if (completeTaskStatusCode === 200) {
      setShowAddVitalModal(null);
      reloadTodoList();
    }
  }, [completeTaskStatusCode]);

  const patientName = useMemo<string>(() => {
    if (!userProfile || !showAddVitalModal) return '';
    if (userProfile.isPatient)
      return getPreferredFirstName(
        userProfile.chosenName,
        userProfile.firstName,
      );

    const patient = (userProfile?.linkedPatients ?? []).filter(
      (patient) => patient?.externalId === showAddVitalModal?.patientId,
    );

    if (patient.length < 1) return '';

    return getPreferredFirstName(patient[0].chosenName, patient[0].firstName);
  }, [userProfile, showAddVitalModal]);

  const onCompleteTask = () => {
    completeTask({
      command: ApiCommand.POST,
      options: {
        observationDatetime: dateTime.toISOString(),
        source: 'Manual',
        status: 'Final',
        type: 'Weight',
        unit: 'lbs',
        value: vitalValue,
      },
      url: saveVitalsUrl(showAddVitalModal?.patientId),
    });
  };
  const onCloseModal = () => {
    setShowDiscardChangesModal(false);
    setShowAddVitalModal(null);
  };

  return (
    <>
      <Modal
        footerContent={
          <ChakraFlex alignItems="end" flexDirection="column" width="100%">
            <FireflyButton
              id="log-vitals"
              isDisabled={!vitalValue}
              isLoading={completeTaskLoading}
              onClick={onCompleteTask}
            >
              Log
            </FireflyButton>
            {completeTaskError && (
              <ErrorMessage
                id="complete-task___error"
                message="Unable to log."
                textAlign="right"
              />
            )}
          </ChakraFlex>
        }
        id="add-vitals__weight"
        isLazy
        isOpen={Boolean(showAddVitalModal)}
        onClose={() => setShowDiscardChangesModal(true)}
        padding="0"
        size={modalSize}
        title={
          <ChakraBox marginRight="32px">
            <TaskModalTitle
              duration={showAddVitalModal?.estimatedDuration ?? ''}
              title={
                <ChakraFlex alignItems="baseline" gridGap="8px">
                  <Text id="add-vital___title" variant="h3">
                    {showAddVitalModal?.taskName}
                  </Text>
                  <Text
                    color="neutral.secondary"
                    id="add-vital___patient-name"
                    variant="h4"
                  >
                    {`for ${patientName}`}
                  </Text>
                </ChakraFlex>
              }
            />
          </ChakraBox>
        }
      >
        <ChakraBox
          backgroundColor="black.5"
          borderRadius="16px"
          color="neutral.secondary"
          padding="16px"
        >
          <ChakraFlex
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Text color="neutral.secondary" variant="captionMedium">
              Directions
            </Text>
            <FireflyButton
              id={`${topLevelId}__directions__toggle`}
              leftIcon={
                hideDirections
                  ? FAIconName.chevronDownRegular
                  : FAIconName.chevronUpRegular
              }
              onClick={() =>
                setHideDirections((hideDirections) => !hideDirections)
              }
              variant="tertiary"
            >
              {hideDirections ? 'Show' : 'Hide'}
            </FireflyButton>
          </ChakraFlex>
          <ChakraCollapse in={!hideDirections}>
            <Text marginTop="16px" variant="caption">
              <LogWeightDescription />
            </Text>
          </ChakraCollapse>
        </ChakraBox>

        <Text marginY="32px">{`Log ${patientName}’s weight below.`}</Text>
        <ChakraFlex
          direction={{ base: 'column', md: 'row' }}
          marginBottom="16px"
        >
          <ChakraBox>
            <Text
              color="neutral.secondary"
              marginBottom="8px"
              variant="caption"
            >
              Weight
            </Text>
            <ChakraFlex
              alignItems="center"
              marginBottom={{ base: '16px', md: '0px' }}
              marginRight={{ base: '0px', md: '64px' }}
              width={{ base: '100%', md: '110px' }}
            >
              <NumberInput
                backgroundColor="white"
                color="neutral.primary"
                id={topLevelId}
                inputBorder={`1px solid ${neutral.line}`}
                inputBoxBorderRadius="12px"
                inputPadding="0 12px"
                maxLength={6}
                min={0}
                onChange={(val) => {
                  setVitalValue(val);
                }}
                value={vitalValue}
              />
              <Text marginLeft="8px">lbs</Text>
            </ChakraFlex>
            {(vitalValue ?? 0) >= 1000 && (
              <ErrorMessage
                id="weight-limit___error"
                message="Weight should be less than 1000 lbs"
                textAlign="right"
              />
            )}
          </ChakraBox>
          <ChakraBox width={{ base: '100%', md: '240px' }}>
            <Text
              color="neutral.secondary"
              marginBottom="8px"
              variant="caption"
            >
              When measured
            </Text>
            <DatePicker
              background="white"
              color="neutral.primary"
              id="vitals-date"
              maxDate={new Date()}
              onSelect={(date) => setDateTime(date)}
              showTimeSelect
              styleForMyEquip
              value={dateTime}
            />
          </ChakraBox>
        </ChakraFlex>
      </Modal>
      <DiscardChangesModal
        confirmButtonText="Discard"
        description="This cannot be undone."
        handleClose={() => setShowDiscardChangesModal(false)}
        handleConfirmDiscard={onCloseModal}
        isOpen={showDiscardChangesModal}
        showCancelButton
        title="Discard information?"
      />
    </>
  );
};

export default AddVitalsModal;
