import { DateTime } from 'luxon';

const getPageTitle = (definition: SurveyDefinition, pageName: string): string =>
  (definition?.pages ?? []).find(({ name }: SurveyPage) => name === pageName)
    ?.title ?? pageName;

/** Locate a question by iterating through page elements - some pages have elements nested within elements */
const getQuestion = (
  definition: SurveyDefinition,
  questionName: string,
): SurveyQuestion =>
  (definition?.pages ?? [])
    .flatMap((page: SurveyPage) =>
      page.elements.flatMap((element: SurveyQuestion) =>
        element.elements?.length ? element.elements : [element],
      ),
    )
    .find((element: SurveyQuestion) => element.name === questionName);

const getQuestionTitle = (
  definition: SurveyDefinition,
  questionName: string,
): string => {
  const question: SurveyQuestion = getQuestion(definition, questionName);
  return (
    question?.html
      ?.replace(/&nbsp;/g, ' ') // replace html non-breaking space escape character with a space
      .replace(/&#8226;/g, ' - ') // replace html bullet escape character with a dash
      .replace(/&#8220;|&#8221;/g, '"') // replace double quote escape characters with double quotes
      .replace(/<br>/g, '\n') // replace <br> html tag with new line escape character
      .replace(/<[^>]*>/g, '') ?? // remove all other html tags, e.g. <li>
    question?.title ??
    questionName
  );
};

const getSurveyAnswerOption = (
  options: SurveyAnswerOption[],
  answerValue: string,
): SurveyAnswerOption =>
  (options ?? []).find(
    ({ value }: SurveyAnswerOption) => value === answerValue,
  );

const isBoolOrStringOrNumber = (value: any): boolean =>
  ['boolean', 'string', 'number'].includes(typeof value);

/** Parse a value that can be of type string, string[], or any[] into a string[] */
const parseAnswerValueToStringArray = (answerValue: any): string[] => {
  if (isBoolOrStringOrNumber(answerValue)) {
    return [answerValue.toString()];
  }

  if (Array.isArray(answerValue)) {
    return answerValue.map((answerValuePart: any) => {
      if (isBoolOrStringOrNumber(answerValuePart)) {
        return answerValuePart.toString();
      }

      if (Object.keys(answerValuePart ?? {}).length) {
        return Object.values(answerValuePart)
          .map((value: any) =>
            !isBoolOrStringOrNumber(value) ? '' : value.toString(),
          )
          .join(' - ');
      }

      return '';
    });
  }

  return [];
};

const parseDate = (text: string): string =>
  DateTime.fromFormat(text, 'yyyy-LL-dd').toFormat('LL/dd/yyyy');

/**
 * Formats a US 10-digit telephone number as "(XXX) XXX-XXXX"
 * @function parseTelephone
 * @param {string} tel A telephone number containing exactly 10 digits
 * @return {string}
 */
const parseTelephone = (tel: string): string => {
  if (tel?.length !== 10) return tel;
  return `(${tel.slice(0, 3)}) ${tel.slice(3, 6)}-${tel.slice(6)}`;
};

const parseInputTypeMapper: Record<'date' | 'tel', (text: string) => string> = {
  date: parseDate,
  tel: parseTelephone,
};

const parseText = (inputType: string, text: string[]): string[] =>
  text.map(
    (textPart: string): string =>
      parseInputTypeMapper[inputType]?.(textPart) ?? textPart,
  );

const getAnswerTitle = (
  definition: SurveyDefinition,
  questionName: string,
  answerValue: any,
): string[] => {
  const { choices, inputType } = getQuestion(definition, questionName) ?? {};

  const result: string[] = parseAnswerValueToStringArray(answerValue);

  const parsedText = parseText(inputType, result);

  if (!choices?.length) return parsedText;

  return parsedText.map((answerValuePart: string) =>
    (
      getSurveyAnswerOption(choices, answerValuePart)?.text ?? answerValuePart
    ).toString(),
  );
};

const stringToJson = (data: string): any =>
  typeof data === 'string' ? JSON.parse(data) : null;

enum VerifyStep {
  REQUEST_OTP,
  CHECK_OTP,
}

/**
 * Filter the json results -- return all questions from the survey definition which have either html or title and where a response was given
 *
 * @param surveyDefinition
 * @param jsonResults
 * @returns Filtered jsonResults
 */
const filterSurveyDataWithNoTitles = (
  surveyDefinition: SurveyDefinition,
  jsonResults: Record<string, any>,
): Record<string, any> => {
  if (jsonResults && surveyDefinition) {
    return (surveyDefinition?.pages ?? [])
      .flatMap((page: SurveyPage) =>
        page.elements.flatMap((element: SurveyQuestion) =>
          element.elements?.length ? element.elements : [element],
        ),
      )
      .filter(
        (question: SurveyQuestion) =>
          question.html !== undefined ||
          (question.title !== undefined &&
            jsonResults[question.name] !== undefined),
      )
      .reduce(
        (cur: any, { name }: SurveyQuestion) =>
          Object.assign(cur, { [name]: jsonResults[name] }),
        {},
      );
  }

  return {};
};

export {
  filterSurveyDataWithNoTitles,
  getAnswerTitle,
  getPageTitle,
  getQuestion,
  getQuestionTitle,
  parseDate,
  parseTelephone,
  parseText,
  stringToJson,
  VerifyStep,
};
