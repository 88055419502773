import React from 'react';

import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { EquipUIProvider, Fonts } from '@equip.health/ui';
import { Provider } from 'react-redux';

import App from '~/App';
import { EquipAppPath } from '~/lib/constants';
import { AnalyticsProvider } from '~/lib/context/AppAnalyticsContext';
import { NewRelicProvider } from '~/lib/context/NewRelicContext';
import { ReduxSecurityWrapper } from '~/lib/security';
import { buildProvidersTree } from '~/lib/util/providerTree.util';
import store from '~/store';

const { VITE_AUTH0_AUDIENCE, VITE_AUTH0_CLIENT_ID, VITE_AUTH0_DOMAIN } =
  import.meta.env;

export const WrappingContexts = () => {
  const auth0ProviderOptions: Auth0ProviderOptions = {
    authorizationParams: {
      audience: VITE_AUTH0_AUDIENCE,
      redirect_uri: `${window.location.origin}${EquipAppPath.HOME}`,
      scope: 'profile email offline_access',
    },
    cacheLocation: 'localstorage',
    clientId: VITE_AUTH0_CLIENT_ID,
    domain: VITE_AUTH0_DOMAIN,
    useRefreshTokens: true,
  };

  const ProvidersTree = buildProvidersTree([
    [Auth0Provider, auth0ProviderOptions],
    [ReduxSecurityWrapper, {}],
    [Provider, { store }],
    [EquipUIProvider, { themeType: 'firefly_v2' }],
    [AnalyticsProvider, {}],
    [NewRelicProvider, {}],
  ]);

  return (
    <React.StrictMode>
      <ProvidersTree>
        <Fonts />
        <App />
      </ProvidersTree>
    </React.StrictMode>
  );
};
