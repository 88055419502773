import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Survey from '~/components/survey';
import AuthenticatedPageLayout from '~/layouts/AuthenticatedPageLayout';
import { PAGE_NAME } from '~/lib/constants/analytics';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';

const SurveyPage = () => {
  const location = useLocation<{ surveyId: string }>();
  const history = useHistory();
  const { trackPageView } = useAnalytics();

  if (!location?.state?.surveyId) history.goBack();

  useEffect(() => {
    trackPageView(PAGE_NAME.survey);
  }, []);

  return (
    <AuthenticatedPageLayout hiddenFooter hiddenHeader>
      <Survey
        isFamilyTask
        onComplete={() => history.goBack()}
        uuid={location?.state?.surveyId}
      />
    </AuthenticatedPageLayout>
  );
};

export default SurveyPage;
