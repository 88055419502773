import { FC, useEffect, useState } from 'react';

import {
  Button,
  ChakraBox,
  ChakraFlex,
  FireflyButton,
  InfiniteScroll,
  useDisclosure,
} from '@equip.health/ui';
import isEmpty from 'lodash/isEmpty';

import SectionTitle from '../common/SectionTitle';
import BookAppointmentModal from './BookAppointment/modal';
import { EmptyScheduleView } from './EmptyScheduleView';
import PastEvents from './PastEvents/PastEvents';
import ScheduleLoader from './ScheduleLoader';
import UpcomingScheduleView from './ScheduleViewContainer';
import {
  PatientTabSelector,
  getAllUserSelectorTabs,
} from '~/components/common/PatientTabSelector';
import { MY_EQUIP_V2_DIMENSIONS } from '~/lib/constants';
import { BookAppointmentContextProvider } from '~/lib/context/BookAppointmentContext';
import { useSchedulePermissionsContext } from '~/lib/context/SchedulePermissionsContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';
import useScheduledEvents from '~/lib/hooks/useScheduledEvents';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { EVENT } from '~/lib/constants/analytics';

const isBookAppointmentsEnabled =
  import.meta.env.VITE_BOOK_APPOINTMENTS_ENABLED === 'true';

const PAGE_SIZE = 20;
const TOP_LEVEL_ID = 'schedule-details';
const UPCOMING_EVENT_ID = 'schedule-appointments-upcoming';
const scrollableTargetUpcomingAppointment =
  'ScrollableTargetUpcomingAppointment';

const SchedulePageView: FC = () => {
  const [
    shouldShowBookAppointmentsButton,
    setShouldShowBookAppointmentsButton,
  ] = useState<boolean>(false);
  const [loadMoreCTAloading, setLoadMoreCTAloading] = useState<boolean>(false);

  const [shouldShowBorder, setShouldShowBorder] = useState<boolean>(false);

  const { userProfile, isFullProfileInfoFetched } = useUserProfileContext();

  const isSmallBreakpoint = useMobileBreakpoint();

  const { track } = useAnalytics();

  const {
    isOpen: isBookAppointmentModalOpen,
    onOpen: onBookAppointmentModalOpen,
    onClose: onBookAppointmentModalClose,
  } = useDisclosure();

  const {
    isBookingPermissionsFetched,
    patientAppointmentTypesMapper,
    patientIdsWithBookingPermissions,
  } = useSchedulePermissionsContext();

  const [selectedUserId, setSelectedUserId] = useState<string>(
    userProfile?.externalId,
  );

  const isProxySupportSelected = selectedUserId === userProfile?.externalId;

  const patientExternalId = isProxySupportSelected ? null : selectedUserId;

  const {
    fetchDataForPage: fetchUpcomingEventDataForPage,
    groupedEvents: groupedUpcomingEvents,
    isScheduleLoading: isUpcomingScheduleLoading,
    pageNumber: upcomingEventPageNumber,
    resetAndLoadListData: resetAndReloadUpcomingEvents,
    totalEventCount: totalUpcomingEventCount,
  } = useScheduledEvents({
    isPastEvents: false,
    onSuccessfulDataFetch: () => setLoadMoreCTAloading(false),
    patientExternalId,
  });

  const fetchUpcomingScheduleOnDemand = () => {
    fetchUpcomingEventDataForPage(upcomingEventPageNumber + 1);
    if (isSmallBreakpoint) {
      setLoadMoreCTAloading(true);
    }
  };

  useEffect(() => {
    if (
      isBookingPermissionsFetched &&
      patientIdsWithBookingPermissions.length > 0
    ) {
      setShouldShowBookAppointmentsButton(true);
    }
  }, [
    isBookingPermissionsFetched,
    patientAppointmentTypesMapper,
    patientIdsWithBookingPermissions,
  ]);

  const isBookAppointmentsButtonVisible =
    isBookAppointmentsEnabled && shouldShowBookAppointmentsButton;

  const resetAndLoadListData = () => {
    resetAndReloadUpcomingEvents();
  };

  useEffect(() => {
    if (selectedUserId && isFullProfileInfoFetched) {
      resetAndLoadListData();
    }
  }, [selectedUserId, isFullProfileInfoFetched]);

  const onScroll = (e: MouseEvent): void => {
    setShouldShowBorder((e.target as HTMLElement).scrollTop > 0);
  };

  return (
    <ChakraBox
      borderTopColor="neutral.line"
      borderTopWidth={shouldShowBorder ? '1px' : '0'}
      id={scrollableTargetUpcomingAppointment}
      maxHeight={{ base: 'unset', md: '100vh' }}
      maxWidth={MY_EQUIP_V2_DIMENSIONS.desktopMaxWidth}
      overflow="auto"
      padding={{
        base: '0 24px',
        lg: '0 40px',
      }}
      width="100%"
    >
      <BookAppointmentContextProvider>
        <BookAppointmentModal
          isOpen={isBookAppointmentModalOpen}
          onBookingComplete={resetAndLoadListData}
          onClose={onBookAppointmentModalClose}
        />
      </BookAppointmentContextProvider>
      <ChakraBox width="100%" zIndex="1">
        <ChakraFlex
          alignItems={{ base: 'flex-start', md: 'center' }}
          background="white"
          direction={{ base: 'column', md: 'row' }}
          gridGap="24px"
          justifyContent="space-between"
          marginY={{ base: '24px', md: '46px' }}
        >
          {userProfile.isPatient ? (
            <SectionTitle id="schedule" title="Schedule" />
          ) : (
            <PatientTabSelector
              id="schedule-page"
              onPatientClick={(patientId) => {
                track(EVENT.SchedulePatientSelectorClick);
                setSelectedUserId(patientId);
              }}
              patientTabs={getAllUserSelectorTabs(userProfile)}
              title="Schedule"
              type="page"
            />
          )}

          {isBookAppointmentsButtonVisible && (
            <FireflyButton
              id="book-appointment__modal__open"
              onClick={onBookAppointmentModalOpen}
              size="normal"
              variant="primary"
            >
              Book an appointment
            </FireflyButton>
          )}
        </ChakraFlex>
      </ChakraBox>
      <ChakraFlex
        flexDirection={{ base: 'column', md: 'row' }}
        maxWidth={MY_EQUIP_V2_DIMENSIONS.desktopMaxWidth}
      >
        {isUpcomingScheduleLoading ? (
          <ChakraBox flex="1" width="100%">
            <ScheduleLoader />
          </ChakraBox>
        ) : (
          <ChakraFlex direction="column" flex="1">
            {isEmpty(groupedUpcomingEvents) && (
              <EmptyScheduleView id={TOP_LEVEL_ID} />
            )}
            {!isSmallBreakpoint ? (
              <ChakraBox>
                <InfiniteScroll
                  dataLength={(groupedUpcomingEvents ?? []).length}
                  fetchData={fetchUpcomingScheduleOnDemand}
                  hideCount
                  id={UPCOMING_EVENT_ID}
                  onScroll={onScroll}
                  scrollableTarget={scrollableTargetUpcomingAppointment}
                  showLoader={false}
                  style={{ overflowX: 'hidden' }}
                  totalCount={totalUpcomingEventCount ?? 0}
                >
                  <UpcomingScheduleView
                    patientExternalId={patientExternalId}
                    resetAndLoadListData={resetAndLoadListData}
                    upcomingScheduleDataList={groupedUpcomingEvents}
                  />
                </InfiniteScroll>
              </ChakraBox>
            ) : (
              <ChakraBox
                display={{ base: 'block', md: 'none' }}
                style={{ overflowX: 'hidden' }}
              >
                <UpcomingScheduleView
                  patientExternalId={patientExternalId}
                  resetAndLoadListData={resetAndLoadListData}
                  upcomingScheduleDataList={groupedUpcomingEvents}
                />
                {totalUpcomingEventCount >
                  upcomingEventPageNumber * PAGE_SIZE && (
                  <Button
                    boxShadow="none"
                    colorScheme="teal"
                    id={`${TOP_LEVEL_ID}__cancel`}
                    isLoading={loadMoreCTAloading}
                    margin="0 16px 16px 0"
                    onClick={fetchUpcomingScheduleOnDemand}
                    padding="14px 16px"
                    variant="outline"
                    width="100%"
                  >
                    Load more
                  </Button>
                )}
              </ChakraBox>
            )}
          </ChakraFlex>
        )}

        <PastEvents selectedUserId={selectedUserId} />
      </ChakraFlex>
    </ChakraBox>
  );
};

export default SchedulePageView;
