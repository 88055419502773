import { FC, useEffect } from 'react';

import AuthenticatedPageLayout from '~/layouts/AuthenticatedPageLayout';
import { PAGE_NAME } from '~/lib/constants/analytics';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';

const MessagesPage: FC = () => {
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView(PAGE_NAME.messages);
  }, []);

  return <AuthenticatedPageLayout children="" hiddenFooter isMessage />;
};

export default MessagesPage;
