import { FC, useState } from 'react';

import {
  ChakraFlex,
  FireflyButton,
  Text,
  useDisclosure,
} from '@equip.health/ui';

import NotificationPreferences, {
  NotificationsOffWarningData,
  SMSWarningData,
} from '~/components/common/NotificationPreferences';
import WarnChangesModal, {
  WarnChangesModalViewDataProps,
} from '~/components/common/WarnChangesModal';
import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { useApi } from '~/lib/hooks';
import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';

const TOP_LEVEL_ID = 'onboarding__notification-preferences__step';

type NotificationPreferencesStepProps = {
  onComplete: () => void;
};

const {
  updatePatientNotificationPreference: updatePatientNotificationPreferenceUrl,
  updateSupportNotificationPreference: updateSupportNotificationPreferenceUrl,
} = urlConstants.users;

const NotificationPreferencesStep: FC<NotificationPreferencesStepProps> = ({
  onComplete,
}) => {
  const isMobile = useMobileBreakpoint();
  const { onNotificationSettingsUpdate, userProfile } = useUserProfileContext();

  // Email is checked by default.
  const [isSmsChecked, setIsSmsChecked] = useState(false);
  const [isEmailChecked, setIsEmailChecked] = useState(true);

  const [wawrningModalData, setWarningModalData] =
    useState<WarnChangesModalViewDataProps>();

  const {
    loading: isUpdateNotificationPreferencesLoading,
    sendRequest: updateNotificationPreferencesRequest,
  } = useApi();

  const {
    isOpen: isWarningModalOpen,
    onOpen: onWarningModalOpen,
    onClose: onWarningModalClose,
  } = useDisclosure();

  const handleNotificationPreferencesUpdate = () => {
    onNotificationSettingsUpdate();
    onComplete();
  };

  const onSaveNotification = () => {
    updateNotificationPreferencesRequest({
      callback: handleNotificationPreferencesUpdate,
      command: ApiCommand.PUT,
      options: {
        receiveNotificationEmails: isEmailChecked,
        receiveNotificationSms: isSmsChecked,
      },
      url: userProfile.isPatient
        ? updatePatientNotificationPreferenceUrl(userProfile?.externalId)
        : updateSupportNotificationPreferenceUrl(userProfile?.externalId),
    });
  };

  const handleSavePreferences = () => {
    const shouldShowSMSWarning = !isSmsChecked;

    const shouldShowNotificationWarning =
      shouldShowSMSWarning && !isEmailChecked;

    if (shouldShowNotificationWarning) {
      setWarningModalData(NotificationsOffWarningData);
      onWarningModalOpen();
    } else if (shouldShowSMSWarning) {
      setWarningModalData(SMSWarningData);
      onWarningModalOpen();
    }

    if (!shouldShowNotificationWarning && !shouldShowSMSWarning) {
      onSaveNotification();
    }
  };

  return (
    <>
      <WarnChangesModal
        cancelButtonText={wawrningModalData?.cancelButtonText}
        confirmButtonText={wawrningModalData?.confirmButtonText}
        description={wawrningModalData?.description}
        handleClose={onWarningModalClose}
        handleConfirmDiscard={() => {
          onSaveNotification();
          onWarningModalClose();
        }}
        isOpen={Boolean(isWarningModalOpen)}
        showCancelButton
        title={wawrningModalData?.title}
      />

      <ChakraFlex alignItems="start" flexDirection="column" maxWidth="480px">
        <Text marginBottom="12px" variant="h3">
          How would you like to be notified?
        </Text>
        <Text marginBottom="40px">
          We need notifications to notify you of new to-dos, new messages, and
          appointment updates.
        </Text>
        <NotificationPreferences
          isEmailChecked={isEmailChecked}
          isSmsChecked={isSmsChecked}
          onEmailCheckboxChange={(isSelected) => setIsEmailChecked(isSelected)}
          onSmsCheckboxChange={(isSelected) => setIsSmsChecked(isSelected)}
        />
        <FireflyButton
          id={`${TOP_LEVEL_ID}__confirm`}
          isFullWidth={isMobile}
          isLoading={isUpdateNotificationPreferencesLoading}
          onClick={handleSavePreferences}
        >
          Save preferences
        </FireflyButton>
      </ChakraFlex>
    </>
  );
};

export default NotificationPreferencesStep;
