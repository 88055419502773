import _ from 'lodash';

import StringUtil from '../string.util';
import ChatUtil from './chat.utils';
import {
  HTML_LINE_BREAK,
  SPACE,
  TEXT_NEW_LINE,
  HTTP,
} from '~/lib/constants/chat/chat.constants';
import { ChatMessage } from '~/lib/types/chat';

export type SearchResult = {
  foundMessageIds: number[];
  updatedMessages: ChatMessage[];
};

export const performSearch = (
  messages: ChatMessage[],
  searchKeyword: string,
): SearchResult => {
  const result: SearchResult = {
    foundMessageIds: [],
    updatedMessages: _.cloneDeep(messages ?? []),
  };

  if (messages && searchKeyword) {
    // Search count based on number of messages not number of occurrences
    const foundMessages = messages.filter(
      (x) => x.message?.search(new RegExp(searchKeyword, 'i')) > -1,
    );

    // Search within replies in case of threaded messages
    for (const message of messages) {
      if (
        message.thread?.some(
          ({ message }) => message.search(new RegExp(searchKeyword, 'i')) > -1,
        )
      ) {
        foundMessages.push(message);
      }
    }

    // Search should start from oldest message to newest message
    if (foundMessages.length > 0)
      result.foundMessageIds = [
        ...new Set(
          ChatUtil.sortByCreatedDate(foundMessages).map((x) => x.messageId),
        ),
      ];

    // Highlight searched keyword
    result.updatedMessages = messages.map((item) => {
      return {
        ...item,
        message: ChatUtil.replaceAll(
          item.message,
          searchKeyword,
          `<mark>#</mark>`,
        ),
        thread: (item.thread ?? []).map((reply) => {
          return {
            ...reply,
            message: ChatUtil.replaceAll(
              reply.message,
              searchKeyword,
              `<mark>#</mark>`,
            ),
          };
        }),
      };
    });
  }

  return result;
};

export const renderNewLine = (messages: ChatMessage[]): ChatMessage[] => {
  return (messages ?? []).map((item) => {
    return {
      ...item,
      message: StringUtil.replaceAll(
        item.message,
        TEXT_NEW_LINE,
        HTML_LINE_BREAK,
      ),
    };
  });
};

const anchorTag = (url, segment) =>
  `<a href='${url}' target='_blank' style='color: blue' rel='noreferrer noopener'>${segment}</a>`;

export const renderLinks = (messages: ChatMessage[]): ChatMessage[] => {
  const parsedMessages = (messages ?? []).map((item) => {
    const segments = item.message?.split(SPACE);
    if (segments?.length > 0) {
      const joinedSegments = segments
        .map((segment) => {
          if (segment.includes(HTTP)) {
            return segment
              .split(HTML_LINE_BREAK)
              .reduce((arr: Array<string>, section: string) => {
                const urlPosition = section.indexOf(HTTP);
                if (urlPosition !== -1) {
                  const url = section.substring(urlPosition);
                  arr.push(
                    `${section.substring(0, urlPosition)}${anchorTag(
                      ChatUtil.getUrl(url),
                      url,
                    )}`,
                  );
                } else {
                  arr.push(section);
                }
                return arr;
              }, [])
              .join(HTML_LINE_BREAK);
          } else {
            return segment;
          }
        })
        .join(SPACE);

      return { ...item, message: joinedSegments };
    }
    return item;
  });

  return parsedMessages;
};
