import { useBreakpoint } from '@equip.health/ui';

const useMobileBreakpoint = (): boolean => {
  const currentBreakPoint = useBreakpoint();
  return currentBreakPoint === 'sm' || currentBreakPoint === 'base';
};

const useTabletBreakpoint = (): boolean => {
  const currentBreakPoint = useBreakpoint();
  return (
    currentBreakPoint === 'md' ||
    currentBreakPoint === 'sm' ||
    currentBreakPoint === 'base'
  );
};

export { useMobileBreakpoint, useTabletBreakpoint };
