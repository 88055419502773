import { FC, useEffect } from 'react';

import { SimpleCardGrid } from '@equip.health/ui';
import { isNil } from 'lodash';

import EmptyListView from './EmptyListView';
import LoadingView from './LoadingView';
import ToDoListTaskCard from './TaskCard';
import { ApiCommand } from '~/lib/Api';
import { FamilyTaskSubcategory } from '~/lib/constants/tasks.constants';
import urlConstants from '~/lib/constants/url.constants';
import { useTodoListContext } from '~/lib/context/TodoListContext';
import { useApi } from '~/lib/hooks';

const { getToDoListForPatient, getToDoList } = urlConstants.tasks;
const { lastUpdatedUsers: lastUpdatedUsersUrlBuilder } = urlConstants.users;

const generateEmptyViewDescription = (
  isForCurrentUser: boolean,
  user: string,
): string | null =>
  isForCurrentUser ? 'You have no to-dos' : `${user} has no to-dos`;

interface TaskCardListProps {
  handleTaskStart: (task: Task) => void;
  isForCurrentUser: boolean;
  patientId: string;
  patientName?: string;
}

const TaskCardList: FC<TaskCardListProps> = ({
  handleTaskStart,
  isForCurrentUser,
  patientId,
  patientName,
}) => {
  const {
    refreshValue,
    updatePatientAllTodoCountMapper,
    updatePatientPendingTodoCountMapper,
  } = useTodoListContext();

  const {
    data: toDoListTasks,
    error: getToDoListError,
    loading: isGetToDoListLoading,
    sendRequest: getToDoListRequest,
  } = useApi<Task[]>();

  const {
    data: lastUpdatedUsers,
    error: getLastUpdatedErrors,
    loading: isLoadingLastUpdateUsers,
    sendRequest: getLastUpdatedUsers,
  } = useApi<LastUpdatedUserResponse[]>();

  const fetchToDoList = () => {
    updatePatientAllTodoCountMapper(patientId, null);
    updatePatientPendingTodoCountMapper(patientId, null);
    getToDoListRequest({
      command: ApiCommand.GET,
      url: isForCurrentUser ? getToDoList : getToDoListForPatient(patientId),
    });
  };

  useEffect(fetchToDoList, [refreshValue, patientId]);

  useEffect(() => {
    if (getToDoListError || getLastUpdatedErrors) {
      // Temp fix to address the root cause found in issue EQ-11464
      updatePatientAllTodoCountMapper(patientId, 0);
      updatePatientPendingTodoCountMapper(patientId, 0);
    } else if (toDoListTasks) {
      updatePatientAllTodoCountMapper(patientId, (toDoListTasks ?? []).length);
      updatePatientPendingTodoCountMapper(
        patientId,
        (toDoListTasks ?? []).filter((task) => isNil(task.completedAt)).length,
      );
    }
  }, [toDoListTasks, getToDoListError]);

  const fetchLastUpdatedUsers = (surveyLinkedIds: string[]) => {
    getLastUpdatedUsers({
      command: ApiCommand.GET,
      url: lastUpdatedUsersUrlBuilder(surveyLinkedIds),
    });
  };

  useEffect(() => {
    if (toDoListTasks) {
      const linkedSurveyIds = toDoListTasks
        .filter(
          (todo) =>
            todo.taskSubCategoryName ===
            FamilyTaskSubcategory.SUB_CATEGORY_TYPE_ASSESSMENT,
        )
        .map((el) => el.linkedSurveyId);
      fetchLastUpdatedUsers(linkedSurveyIds);
    }
  }, [toDoListTasks]);

  return (
    <>
      {isGetToDoListLoading || isLoadingLastUpdateUsers ? (
        <LoadingView />
      ) : (
        <>
          {(toDoListTasks ?? []).length === 0 && (
            <EmptyListView
              emptyDescription={generateEmptyViewDescription(
                isForCurrentUser,
                patientName,
              )}
            />
          )}
          <SimpleCardGrid id="task-card-list">
            {toDoListTasks?.map((task) => {
              const isCompleted = isNil(task.completedAt) === false;
              const lastUpdated = lastUpdatedUsers?.filter(
                (info) => info.responseExternalId === task.linkedSurveyId,
              )?.[0];

              return (
                <ToDoListTaskCard
                  duration={task.estimatedDuration}
                  familyAssigneeUsers={task.familyAssigneeUsers}
                  isCompleted={isCompleted}
                  isCurrentUser={isForCurrentUser}
                  key={task.taskExternalId}
                  lastUpdated={lastUpdated}
                  onStart={(taskId: string) => {
                    const selectedTask = toDoListTasks.find(
                      (task) => task.taskExternalId === taskId,
                    );
                    handleTaskStart(selectedTask);
                  }}
                  patientId={task?.patientId}
                  taskId={task.taskExternalId}
                  taskStatus={task?.taskStatus}
                  taskSubCategoryName={task.taskSubCategoryName}
                  title={task.taskName}
                />
              );
            })}
          </SimpleCardGrid>
        </>
      )}
    </>
  );
};

export default TaskCardList;
