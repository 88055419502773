import React from 'react';

import {
  ChakraDivider,
  ChakraFlex,
  ChakraText,
  EquipUIFireflyV1Theme,
} from '@equip.health/ui';

import { CHAT_DIVIDER_TYPE } from '~/lib/constants/chat/chat.constants';

const { caption } = EquipUIFireflyV1Theme.typography;

const DividerColorByType: Record<CHAT_DIVIDER_TYPE, string> = {
  [CHAT_DIVIDER_TYPE.UNREAD_MESSAGE]: 'new.base',
  [CHAT_DIVIDER_TYPE.DATE]: 'black.10',
};

const TextColorByType: Record<CHAT_DIVIDER_TYPE, string> = {
  [CHAT_DIVIDER_TYPE.UNREAD_MESSAGE]: 'new.base',
  [CHAT_DIVIDER_TYPE.DATE]: 'black.60',
};

interface ChatDividerProps {
  text: string;
  type: CHAT_DIVIDER_TYPE;
}

const ChatDivider = React.forwardRef<HTMLDivElement, ChatDividerProps>(
  (
    { text, type }: ChatDividerProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <ChakraFlex
        alignItems="center"
        height="40px"
        marginX="16px"
        ref={ref}
      >
        <ChakraDivider borderColor={DividerColorByType[type]} />
        <ChakraText
          color={TextColorByType[type]}
          paddingX="16px"
          textTransform="capitalize"
          whiteSpace="nowrap"
          {...caption}
        >
          {text}
        </ChakraText>
        <ChakraDivider borderColor={DividerColorByType[type]} />
      </ChakraFlex>
    );
  },
);

ChatDivider.displayName = 'ChatDivider';
export default ChatDivider;
