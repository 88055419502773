import { PropsWithChildren } from 'react';

import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';

let getAccessTokenSilently: (
  options?: GetTokenSilentlyOptions | undefined,
) => Promise<string>;

export const security = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
  ) => (getAccessTokenSilently = func),
};

export const ReduxSecurityWrapper = ({ children }: PropsWithChildren) => {
  const { getAccessTokenSilently } = useAuth0();
  security.setAccessTokenSilently(getAccessTokenSilently);

  return <>{children} </>;
};
