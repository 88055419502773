import { FC, useState } from 'react';

import { isNil } from 'lodash';

import BookAppointmentStepContainer, {
  ListContainer,
} from '~/components/schedule/BookAppointment/base/StepContainer';
import AppointmentUserAvatarCard from '~/components/schedule/BookAppointment/cards/AppointmentUserAvatarCard';
import { BOOK_APPOINTMENT_STEP } from '~/lib/constants/bookAppointments';
import { useBookAppointmentContext } from '~/lib/context/BookAppointmentContext';
import { useSchedulePermissionsContext } from '~/lib/context/SchedulePermissionsContext';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { getPreferredFullName } from '~/lib/utils';

const topLevelId = 'book-appointment__patient';

const BookAppointmentSelectPatient: FC = () => {
  const patients = useUserProfileContext().userProfile?.linkedPatients;
  const { setPatientId, patientId, setNextStep } = useBookAppointmentContext();
  const { patientIdsWithBookingPermissions } = useSchedulePermissionsContext();
  const [selectedPatientId, setSelectedPatientId] = useState<string>(patientId);

  const handleForwardNavigation = () => {
    setPatientId(selectedPatientId);
    setNextStep(BOOK_APPOINTMENT_STEP.appointmentType);
  };

  const patientsWithPermissions = patients?.filter((p) =>
    patientIdsWithBookingPermissions.includes(p.externalId),
  );

  return (
    <BookAppointmentStepContainer
      isContinueButtonDisabled={isNil(selectedPatientId)}
      onForwardNavigation={handleForwardNavigation}
      stepId={topLevelId}
      title="Which patient is this appointment for?"
    >
      <ListContainer>
        {patientsWithPermissions?.map((patient) => {
          const { firstName, lastName, chosenName, externalId } = patient;

          const preferredFullName = getPreferredFullName(
            chosenName,
            firstName,
            lastName,
          );

          return (
            <AppointmentUserAvatarCard
              id={externalId}
              isSelected={externalId === selectedPatientId}
              key={externalId}
              onClick={() => setSelectedPatientId(externalId)}
              primaryLabel={preferredFullName}
              userType="patient"
            />
          );
        })}
      </ListContainer>
    </BookAppointmentStepContainer>
  );
};

export default BookAppointmentSelectPatient;
