import type { ContactInfo } from '~/components/referral/ContactInfoForm';
import type { NotesFormInfo } from '~/components/referral/NotesForm';
import type { PatientInfo } from '~/components/referral/PatientInfoForm';
import type { ReferrerInfo } from '~/components/referral/ReferrerInfoForm';

export enum ReferralStep {
  YOUR_INFORMATION = 1,
  REFERRED_PATIENT_INFORMATION = 2,
  CONTACT_INFORMATION = 3,
  REVIEW = 4,
  THANK_YOU = 5,
}

export const stepsData: Step[] = [
  {
    canEdit: false,
    id: ReferralStep.YOUR_INFORMATION,
    isStepActiveStatus: true,
    name: 'Your information',
  },
  {
    canEdit: false,
    id: ReferralStep.REFERRED_PATIENT_INFORMATION,
    isStepActiveStatus: false,
    name: 'Referred patient information',
  },
  {
    canEdit: false,
    id: ReferralStep.CONTACT_INFORMATION,
    isStepActiveStatus: false,
    name: 'Contact information',
  },
  {
    canEdit: false,
    id: ReferralStep.REVIEW,
    isStepActiveStatus: false,
    name: 'Review',
  },
  {
    canEdit: false,
    id: ReferralStep.THANK_YOU,
    isStepActiveStatus: false,
    name: 'Thank You',
  },
];

export const defaultReferrerData: ReferrerInfo = {
  affiliation: {
    addressLine1: '',
    city: '',
    country: '',
    description: '',
    latitude: undefined,
    longitude: undefined,
    name: '',
    placeId: '',
    state: '',
    timezone: '',
    zipCode: '',
  },
  affiliationNA: false,
  email: '',
  firstName: '',
  lastName: '',
  officeNumber: '',
  officeNumberExtension: '',
  relationToPatient: '',
  roleType: 'provider',
};

export const defaultPatientData: PatientInfo = {
  age: undefined,
  dateOfBirth: null,
  firstName: '',
  lastName: '',
  state: '',
};

export const defaultContactInfo: ContactInfo = {
  contact: 'patient',
  patientPhoneNumber: '',
  primaryCarerFirstName: '',
  primaryCarerLastName: '',
  primaryCarerPhoneNumber: '',
  primaryCarerRelationWithPatient: '',
};

export const defaultNotesData: NotesFormInfo = {
  additionalNotes: '',
  hearAboutEquip: '',
  tellUsMore: '',
};

export enum FormType {
  INQUIRY = 'inquiry',
  REFERRAL = 'referral',
}

export const referrerRoleOptions: ReferrerRoleOptions[] = [
  {
    title: 'a provider',
    value: 'Provider',
  },
  {
    title: 'a health plan employee',
    value: 'Health plan employee',
  },
  {
    title: 'another professional',
    value: 'Another professional',
  },
];

export const TIMEZONE_CODE_KEY = 'timezoneCode';
