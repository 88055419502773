import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import ProfileField from '~/lib/util/profileFields.util';
import {
  PROFILE_PERMISSION_STATES,
  getPermissionsForProfile,
} from '~/lib/util/profilePermissions.util';

type PermissionStateType = Record<
  ProfileField,
  { isEditable: boolean; isViewable: boolean }
>;

export enum ProfileSection {
  BASIC_NAME,
  BASIC_DOB,
  BASIC_GENDER,
  BASIC_RELATIONSHIP_TO_PATIENT,
  BASIC_LANGUAGE_SPOKEN,
  BASIC_SUPPORTED_BY,
  CONTACT_EMAIL,
  CONTACT_PHONE_NUMBER,
  CONTACT_PREFERENCES,
  CONTACT_ADDRESS,
  CONTACT_TIMEZONE,
  NOTIFICATION_SETTINGS,
}

export const PROFILE_SECTION_TO_FIELD_MAPPER: Record<
  ProfileSection,
  ProfileField[]
> = {
  [ProfileSection.BASIC_NAME]: [
    ProfileField.FIRST_NAME,
    ProfileField.CHOSEN_NAME,
    ProfileField.LAST_NAME,
  ],
  [ProfileSection.BASIC_DOB]: [ProfileField.DATE_OF_BIRTH],
  [ProfileSection.BASIC_GENDER]: [ProfileField.GENDER, ProfileField.PRONOUNS],
  [ProfileSection.BASIC_RELATIONSHIP_TO_PATIENT]: [
    ProfileField.RELATIONSHIP_TO_PATIENT,
  ],
  [ProfileSection.BASIC_LANGUAGE_SPOKEN]: [ProfileField.LANGUAGES],
  [ProfileSection.BASIC_SUPPORTED_BY]: [ProfileField.SUPPORTED_BY],
  [ProfileSection.CONTACT_EMAIL]: [ProfileField.EMAIL],
  [ProfileSection.CONTACT_PHONE_NUMBER]: [ProfileField.CELLPHONE],
  [ProfileSection.CONTACT_PREFERENCES]: [ProfileField.CONTACT_PREFERENCES],
  [ProfileSection.CONTACT_ADDRESS]: [ProfileField.ADDRESS],
  [ProfileSection.CONTACT_TIMEZONE]: [ProfileField.TIMEZONE],
  [ProfileSection.NOTIFICATION_SETTINGS]: [ProfileField.NOTIFICATION],
};

export type ProfilePageState = {
  activeProfile: UserProfile;
  editMode: ProfileSection;
  isUserProfile: boolean;
  onProfileUpdateSuccessful: (updatedProfile: UserProfile) => void;
  profileEditViewPermission: PermissionStateType;
  proxyPatients: UserProfile[];
  setActiveProfile: Dispatch<SetStateAction<UserProfile>>;
  setEditMode: Dispatch<SetStateAction<ProfileSection>>;
  setProxyPatients: Dispatch<SetStateAction<UserProfile[]>>;
  setUserProfile: Dispatch<SetStateAction<UserProfile>>;
  userProfile: UserProfile;
};

const useProfilePage = (): ProfilePageState => {
  const [userProfile, setUserProfile] = useState<UserProfile>(null);
  const [proxyPatients, setProxyPatients] = useState<UserProfile[]>([]);

  const [editMode, setEditMode] = useState<ProfileSection>(null);

  const [activeProfile, setActiveProfile] = useState<UserProfile>(userProfile);

  const [profileEditViewPermission, setPermissionState] =
    useState<PermissionStateType>(
      () => PROFILE_PERMISSION_STATES[getPermissionsForProfile(activeProfile)],
    );

  useEffect(() => {
    setPermissionState(
      () => PROFILE_PERMISSION_STATES[getPermissionsForProfile(activeProfile)],
    );
    setEditMode(null);
  }, [activeProfile]);

  const isUserProfile = useMemo<boolean>(
    () => activeProfile?.externalId === userProfile?.externalId,
    [activeProfile],
  );

  const onProfileUpdateSuccessful = (updatedProfile: UserProfile) => {
    if (updatedProfile?.externalId === userProfile?.externalId) {
      setUserProfile(updatedProfile);
      setActiveProfile(updatedProfile);
    }

    const proxyPatientIndex = (proxyPatients ?? []).findIndex(
      (patient) => patient?.externalId === updatedProfile?.externalId,
    );

    if (proxyPatientIndex >= 0) {
      proxyPatients[proxyPatientIndex] = updatedProfile;
      setProxyPatients([...proxyPatients]);
      setActiveProfile(updatedProfile);
    }
  };

  return {
    activeProfile,
    editMode,
    isUserProfile,
    onProfileUpdateSuccessful,
    profileEditViewPermission,
    proxyPatients,
    setActiveProfile,
    setEditMode,
    setProxyPatients,
    setUserProfile,
    userProfile,
  };
};

export default useProfilePage;
