import { FC, useEffect, useState } from 'react';

import { ChakraBox, ChakraFlex } from '@equip.health/ui';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import VitalsTable from '~/components/vitals/history/VitalsTable';
import WeightPatientTabSelector from '~/components/vitals/WeightPatientTabSelector';
import AuthenticatedPageLayout from '~/layouts/AuthenticatedPageLayout';
import { EquipAppPath, MY_EQUIP_V2_DIMENSIONS } from '~/lib/constants';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { PAGE_NAME } from '~/lib/constants/analytics';

const TOP_LEVEL_ID = 'vitals__weight-observations__page';

interface LocationState {
  patientId?: string;
}

const WeightObservationsPage: FC = () => {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const { trackPageView } = useAnalytics();
  const initialPatientId = location?.state?.patientId;

  useEffect(() => {
    trackPageView(PAGE_NAME.weight);
  }, []);

  const [selectedPatientId, setSelectedPatientId] =
    useState<string>(initialPatientId);

  const handlePatientSelected = (patientId: string) => {
    setSelectedPatientId(patientId);
  };

  const handleBackButtonClick = () => {
    history.push(EquipAppPath.HOME);
  };

  if (!initialPatientId) {
    return <Redirect to={EquipAppPath.HOME} />;
  }

  return (
    <AuthenticatedPageLayout>
      <ChakraFlex
        flexDirection="column"
        id={TOP_LEVEL_ID}
        marginX="auto"
        maxWidth={MY_EQUIP_V2_DIMENSIONS.desktopMaxWidth}
        padding={{
          base: '0',
          lg: '0 32px',
        }}
        width="100%"
      >
        <ChakraBox padding="24px">
          <WeightPatientTabSelector
            initialPatientId={initialPatientId}
            isBackButtonVisible
            onBackButtonClick={handleBackButtonClick}
            onPatientSelected={handlePatientSelected}
          />
        </ChakraBox>
        <VitalsTable patientId={selectedPatientId} />
      </ChakraFlex>
    </AuthenticatedPageLayout>
  );
};

export default WeightObservationsPage;
