import { FC } from 'react';

import { ChakraFlex, Text } from '@equip.health/ui';

import AppointmentTimeSlotCard from '../cards/AppointmentTimeSlotCard';
import { transformDateFromISOtoLocal } from '~/lib/util/schedule.util';

interface TimeSlotGroupProps {
  day: string;
  id: string;
  onSelect: (timeSlot: TimeSlot) => void;
  selectedTimeSlot: TimeSlot;
  timeSlots: TimeSlot[];
}

const topLevelId = 'appointment__time-slot__group';

const TimeSlotGroup: FC<TimeSlotGroupProps> = ({
  day,
  id,
  onSelect,
  selectedTimeSlot,
  timeSlots,
}) => {
  const baseId = `${topLevelId}__${id}`;
  return (
    <ChakraFlex flexDirection="column" id={`${baseId}__container`}>
      <Text id={`${baseId}__title`} marginBottom="12px" variant="body">
        {day}
      </Text>

      <ChakraFlex flexWrap="wrap" gridGap="12px" id={`${baseId}__list`}>
        {timeSlots.map((timeSlot) => {
          const startAppointmentTime = transformDateFromISOtoLocal(
            timeSlot.start,
          );

          return (
            <AppointmentTimeSlotCard
              id={timeSlot.start}
              isSelected={selectedTimeSlot?.start === timeSlot.start}
              key={timeSlot.start}
              onClick={() => onSelect(timeSlot)}
              timeLabel={startAppointmentTime}
            />
          );
        })}
      </ChakraFlex>
    </ChakraFlex>
  );
};

export default TimeSlotGroup;
