import { FC } from 'react';

import { AdminEpisodeStatus } from '../../lib/constants/user.constants';
import {
  PatientTabItemProps,
  PatientTabSelector,
} from '../common/PatientTabSelector';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { getPreferredFirstName } from '~/lib/utils';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { EVENT } from '~/lib/constants/analytics';

interface WeightPatientTabSelectorProps {
  initialPatientId?: string;
  isBackButtonVisible?: boolean;
  onBackButtonClick?: () => void;
  onPatientSelected: (patientId: string) => void;
}

const WeightPatientTabSelector: FC<WeightPatientTabSelectorProps> = ({
  initialPatientId,
  isBackButtonVisible,
  onBackButtonClick,
  onPatientSelected,
}) => {
  const { userProfile } = useUserProfileContext();
  const { track } = useAnalytics();

  const activeProxyPatients = userProfile?.linkedPatients?.filter(
    (p) => p.isProxy && p.adminEpisodeStatus === AdminEpisodeStatus.inTreatment,
  );

  const patientsToTabs: PatientTabItemProps[] = activeProxyPatients?.map(
    (p) => ({
      patientId: p.externalId,
      patientName: getPreferredFirstName(p.chosenName, p.firstName),
    }),
  );

  return (
    <PatientTabSelector
      id="vitals__weight"
      initialPatientId={initialPatientId}
      isBackButtonVisible={isBackButtonVisible}
      onBackButtonClick={onBackButtonClick}
      onPatientClick={(patientId) => {
        track(EVENT.HomeWeightPatientSelectorClick);
        onPatientSelected(patientId);
      }}
      patientTabs={patientsToTabs}
      title="Weight"
    />
  );
};
export default WeightPatientTabSelector;
