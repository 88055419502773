import * as Sentry from '@sentry/react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';

import { WrappingContexts } from './lib/context/WrappingContexts';

const {
  VITE_ENVIRONMENT,
  VITE_EQUIP_APPS_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  VITE_SENTRY_DSN,
  VITE_MYEQUIP_APP_VERSION,
} = import.meta.env;

Sentry.init({
  dsn: VITE_SENTRY_DSN,
  environment: VITE_ENVIRONMENT,
  release: VITE_MYEQUIP_APP_VERSION,
});

const container = document.getElementById('root');
const root = createRoot(container);

const LDProvider = withLDProvider({
  clientSideID: VITE_EQUIP_APPS_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  context: {
    key: 'my-equip',
    kind: 'organization',
  },
})(WrappingContexts);

root.render(<LDProvider />);
