import * as Sentry from '@sentry/react';

import { getPreferredProfileFullName, isMobileDevice } from '~/lib/utils';

export const beaconIdentify = (userProfile: UserProfile): void => {
  if (window.Beacon) {
    window.Beacon('identify', {
      email: userProfile.email,
      name: getPreferredProfileFullName(userProfile),
    });
  } else {
    Sentry.captureException('Beacon Identify - Beacon not found');
    console.error('Beacon Identify - Beacon not found');
  }
};

export const showBeacon = (): void => {
  const beaconContainer = document.getElementById('beacon-container');
  if (beaconContainer?.style?.display) {
    beaconContainer.style.display = 'block';
  }
  const isSmallDevice = isMobileDevice();
  if (isSmallDevice) {
    const beaconButtonElements = document.getElementsByClassName(
      'BeaconFabButtonFrame',
    ) as HTMLCollectionOf<HTMLElement>;

    const beaconButton = beaconButtonElements[0];
    if (beaconButton) {
      beaconButton.style.bottom = '80px';
    }
  }
};

export const hideBeacon = (): void => {
  const beaconContainer = document.getElementById('beacon-container');
  if (beaconContainer?.style?.display) {
    beaconContainer.style.display = 'none';
  }
};
