export const PATIENT_TAGS = {
  EquipAppTreatment: 'EQ_APP_TREAT',
};

export enum AdminEpisodeStatus {
  new = 'New',
  assessing = 'Assessing',
  enrolled = 'Enrolled',
  inTreatment = 'In-treatment',
  paused = 'Paused',
  closed = 'Closed',
}
