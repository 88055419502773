import { FC } from 'react';

import {
  ChakraBox,
  ChakraFlex,
  EquipUIFireflyV2Theme,
  FAIconName,
  Icon,
  Text,
} from '@equip.health/ui';

import { useMobileBreakpoint } from '~/lib/hooks/useBreakpoint';
import { getCardHoverBackgroundColor } from '~/lib/util/schedule/bookAppointment.util';

const { primary } = EquipUIFireflyV2Theme.colors;

interface AppointmentTimeSlotCardProps {
  id: string;
  isSelected: boolean;
  onClick: () => void;
  timeLabel: string;
}

const topLevelId = 'appointment__time-slot__card';

const AppointmentTimeSlotCard: FC<AppointmentTimeSlotCardProps> = ({
  id,
  isSelected,
  onClick,
  timeLabel,
}) => {
  const isMobile = useMobileBreakpoint();
  const shouldShowCheckIcon = !isMobile && isSelected;
  const backgroundColor = getCardHoverBackgroundColor(isSelected, isMobile);

  const padding = '16px';

  return (
    <ChakraFlex
      _hover={{ backgroundColor }}
      alignItems="center"
      backgroundColor={isSelected ? 'primary.10' : 'white.100'}
      borderColor={isSelected ? 'transparent' : 'black.5'}
      borderRadius="12px"
      borderWidth="2px"
      cursor="pointer"
      gridGap="12px"
      id={`${topLevelId}__container__${id}`}
      justifyContent="space-between"
      onClick={onClick}
      padding={padding}
    >
      <Text
        color="neutral.primary"
        id={`${topLevelId}__caption__${id}`}
        variant="bodyMedium"
      >
        {timeLabel}
      </Text>
      <ChakraBox opacity={shouldShowCheckIcon ? 1 : 0}>
        <Icon color={primary[100]} name={FAIconName.checkRegular} size="sm" />
      </ChakraBox>
    </ChakraFlex>
  );
};

export default AppointmentTimeSlotCard;
