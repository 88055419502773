import { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';

import { useApi } from '.';
import { ApiCommand } from '~/lib/Api';
import urlConstants from '~/lib/constants/url.constants';
import { useUserProfileContext } from '~/lib/context/UserProfileContext';
import { checkIsToday, isEventStartingSoon } from '~/lib/util/schedule.util';

type UseZoomMeetingState = {
  closeZoomErrorModal: () => void;
  getZoomURLLoading: boolean;
  openZoomSession: () => void;
  shouldShowZoomButton: boolean;
  showZoomErrorModal: boolean;
};

type UseZoomMeetingProps = {
  eventExternalId: string;
  intervalCallback?: () => void;
  isGroupClass: boolean;
  patientExternalId?: string;
  startTime: string;
};

const useZoomMeeting = ({
  eventExternalId,
  intervalCallback,
  isGroupClass,
  patientExternalId,
  startTime,
}: UseZoomMeetingProps): UseZoomMeetingState => {
  const [zoomEventUrl, setZoomEventUrl] = useState<string>();
  const [showZoomErrorModal, setShowZoomErrorModal] = useState<boolean>(false);
  const [shouldShowZoomButton, setShouldShowZoomButton] =
    useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<number>(null);

  const { userProfile } = useUserProfileContext();

  const {
    error: getZoomURLError,
    loading: getZoomURLLoading,
    sendRequest: getZoomURL,
    statusCode: getZoomURLStatusCode,
  } = useApi<GetZoomUrlForGroupClassesResponse | GetZoomUrlResponse>();

  // Zoom button can only appear 15mins before the appointment.
  const checkIfEventIsStartingSoon = () => {
    setShouldShowZoomButton(isEventStartingSoon(startTime));
  };

  useEffect(() => {
    checkIfEventIsStartingSoon();

    if (checkIsToday(startTime)) {
      const id = setInterval(() => {
        checkIfEventIsStartingSoon();
        intervalCallback?.();
      }, 60 * 1000);
      setIntervalId(id);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  const returnZoomURL = (response) => {
    if (response?.data) {
      return response.data;
    }
    return response || {};
  };
  const handleZoomUrlResponse = (
    response: GetZoomUrlForGroupClassesResponse | GetZoomUrlResponse,
  ) => {
    const { hostUrl, joinUrl } = returnZoomURL(response);
    const url = hostUrl || joinUrl;
    if (url) {
      setZoomEventUrl(url);
    } else if (getZoomURLStatusCode === 403) {
      let message =
        `403 response when getting zoom url. Error: ${getZoomURLError}, eventId: ${eventExternalId}, ` +
        `requestingUserId: ${userProfile.externalId}`;
      if (patientExternalId)
        message = `${message}, patientId: ${patientExternalId}`;
      Sentry.captureMessage(message);
    } else {
      Sentry.captureMessage(`invalid zoom url for ${eventExternalId}`);
    }
  };

  useEffect(() => {
    if (shouldShowZoomButton) {
      getZoomURL({
        callback: handleZoomUrlResponse,
        command: ApiCommand.POST,
        options: {
          appointmentExternalId: eventExternalId,
        },
        url: isGroupClass
          ? urlConstants.schedule.joinGroupClassZoomMeeting(eventExternalId)
          : urlConstants.schedule.joinZoomMeeting,
      });
    }
  }, [eventExternalId, shouldShowZoomButton]);

  const redirectToZoomMeeting = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const openZoomSession = () => {
    if (zoomEventUrl) {
      redirectToZoomMeeting(zoomEventUrl);
    } else if (getZoomURLError) {
      setShowZoomErrorModal(true);
    }
  };

  return {
    closeZoomErrorModal: () => setShowZoomErrorModal(false),
    getZoomURLLoading,
    openZoomSession,
    shouldShowZoomButton,
    showZoomErrorModal,
  };
};

export default useZoomMeeting;
