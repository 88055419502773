import { FC } from 'react';

import {
  ChakraFlex,
  ChakraLink,
  ChakraSimpleGrid,
  ChakraText,
  EquipUITheme,
  ProgressBar,
} from '@equip.health/ui';
import { Link } from 'react-router-dom';

const { h3, h5 } = EquipUITheme.typography;

export type SurveyHeaderProps = {
  currentPage: number;
  height: string;
  isReadOnly?: boolean;
  pagesCount: number;
  title: string;
};

const SurveyHeader: FC<SurveyHeaderProps> = ({
  currentPage,
  height,
  isReadOnly = false,
  pagesCount,
  title,
}) => (
  <ChakraSimpleGrid
    alignItems="center"
    bgColor="teal.shade"
    className="header-container"
    color="white"
    columns={{ base: 2, md: 3 }}
    height={height}
    paddingX={{ base: '8px', lg: '80px' }}
    position="fixed"
    width="-webkit-fill-available"
  >
    <ChakraText
      {...h5}
      data-testid="survey-header::title"
      fontSize={{ base: '16px', lg: '20px' }}
      fontWeight={400}
      textAlign="left"
    >
      {title}
    </ChakraText>
    <ChakraText
      {...h3}
      display={{ base: 'none', md: 'block' }}
      fontWeight={400}
      textAlign="center"
    >
      <ChakraLink
        _hover={{ textDecoration: 'none' }}
        as={Link}
        data-testid="survey-header::logo"
        id="return-home-link"
        to="/"
      >
        EQUIP
      </ChakraLink>
    </ChakraText>
    <ChakraFlex justifyContent="flex-end">
      {!isReadOnly && currentPage !== undefined && pagesCount !== undefined ? (
        <ProgressBar id={title} max={pagesCount} min={0} value={currentPage} />
      ) : (
        <div />
      )}
    </ChakraFlex>
  </ChakraSimpleGrid>
);

export default SurveyHeader;
