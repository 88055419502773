import { FC, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import Survey from '~/components/survey';
import { PAGE_NAME } from '~/lib/constants/analytics';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';

const SurveyPage: FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView(PAGE_NAME.survey);
  }, []);

  return <Survey uuid={uuid} />;
};

export default SurveyPage;
