export const getFileExtension = (fileName: string): string => {
  if (!fileName) return '';
  const fileNameArray = fileName.split('.');
  return fileNameArray.pop() ?? '';
};

export const isAllowedFileType = (
  fileExtension: string,
  allowedExtensions: string[],
): boolean =>
  allowedExtensions?.some(
    (extension) => extension.toLowerCase() === fileExtension.toLowerCase(),
  );

export const getInputAccept = (extensions: string[]): string =>
  (extensions ?? []).map((ext: string) => `.${ext}`).join(',');
