import { FC } from 'react';

import { Avatar, ChakraBox } from '@equip.health/ui';

interface ChatGroupChannelAvatrProps {
  name1: string;
  name2: string;
  userType1: EquipUserType;
  userType2: EquipUserType;
}
const ChatGroupChannelAvatar: FC<ChatGroupChannelAvatrProps> = ({
  name1,
  name2,
  userType1,
  userType2,
}) => {
  return (
    <ChakraBox height="40px" position="relative" width="40px">
      <ChakraBox bottom="0" left="0" position="absolute">
        <Avatar name={name1} size="2xs" userType={userType1} />
      </ChakraBox>

      <ChakraBox
        background="white"
        borderRadius="50%"
        paddingBottom="2px"
        paddingLeft="2px"
        position="absolute"
        right="0"
        top="0"
      >
        <Avatar name={name2} size="2xs" userType={userType2} />
      </ChakraBox>
    </ChakraBox>
  );
};

export default ChatGroupChannelAvatar;
