import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

import { ApiCommand } from '~/lib/Api';
import { CHAT_EMERGENCY_CONSENT } from '~/lib/constants';
import urlConstants from '~/lib/constants/url.constants';
import { PATIENT_TAGS } from '~/lib/constants/user.constants';
import { useAnalytics } from '~/lib/context/AppAnalyticsContext';
import { useChatContext } from '~/lib/context/ChatContext';
import useApi from '~/lib/hooks/useApi';
import useLocalStorage from '~/lib/hooks/useLocalStorage';
import {
  Consent,
  LegalDocumentType,
  getPendingLegalDocuments,
} from '~/lib/legalDocuments/legalDocuments';
import { beaconIdentify } from '~/lib/util/beacon.util';
import { getAgeFromProfile } from '~/lib/util/profile.util';
import { useUserDispatch } from '~/store/hooks/user';

import usePatientTags from './services/usePatientTags';
import { mapUserProfileToUserInfo } from '../util/analytics/user.utils';

const {
  basicInfo: getBasicInfoUrl,
  consents: getConsentsInfoUrl,
  profile: getUserProfileUrl,
  getTags: getTagsUrl,
} = urlConstants.users;

export type UserProfileContextState = {
  errors: string[];
  fetchFullUserProfile: () => void;
  isBasicInfoLoading: boolean;
  isFullProfileInfoFetched: boolean;
  isFullProfileInfoLoading: boolean;
  isLinkedPatientModalOpen: boolean;
  isPatientTagsFetched?: boolean;
  isSafetyPlanEnabled: boolean;
  isSafetyPlanOpen: boolean;
  isUserProfileLoading: boolean;
  logout: () => void;
  onNotificationSettingsUpdate: () => void;
  patientsCompletedSafetyPlan: UserProfile[];
  patientsWithTreatmentTag?: string[];
  pendingLegalDocuments: LegalDocumentType[];
  selectedPatientForSafetyPlan: string;
  setPendingLegalDocuments: Dispatch<SetStateAction<LegalDocumentType[]>>;
  updateBasicInfo: (chosenName?: string) => void;
  updateIsLinkedPatientModalOpen: (open: boolean) => void;
  updateIsSafetyPlanOpen: (open: boolean) => void;
  updateSelectedPatientForSafetyPlan: (externalId: string) => void;
  userProfile: UserProfile;
};

const useUserProfile = (): UserProfileContextState => {
  const {
    isAuthenticated,
    error: auth0Error,
    logout: auth0Logout,
  } = useAuth0();

  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [pendingLegalDocuments, setPendingLegalDocuments] =
    useState<LegalDocumentType[]>();

  const { setValue: setChatEmergencyConsentInLocalStorage } =
    useLocalStorage<boolean>(CHAT_EMERGENCY_CONSENT.localStorageKey, false);

  const { connect, disconnect } = useChatContext();
  const { identify, reset, updateUserInfo } = useAnalytics();

  const [isFullProfileInfoFetched, setIsFullProfileInfoFetched] =
    useState<boolean>();

  const [errors, setErrors] = useState<string[]>([]);
  const [isSafetyPlanOpen, setIsSafetyPlanOpen] = useState<boolean>(false);
  const [isSafetyPlanEnabled, setIsSafetyPlanEnabled] =
    useState<boolean>(false);
  const [selectedPatientForSafetyPlan, setSelectedPatientForSafetyPlan] =
    useState<string>('');
  const [patientsCompletedSafetyPlan, setPatientsCompletedSafetyPlan] =
    useState<UserProfile[]>(null);
  const [isLinkedPatientModalOpen, setIsLinkedPatientModalOpen] =
    useState<boolean>(false);

  const {
    error: getBasicInfoError,
    data: getBasicInfoResponse,
    loading: isGetBasicInfoLoading,
    sendRequest: getBasicInfoRequest,
    statusCode: getBasicInfoStatusCode,
  } = useApi<UserProfile>();

  const {
    error: getFullUserProfileError,
    data: getFullUserProfileResponse,
    loading: isGetFullUserProfileLoading,
    sendRequest: getFullUserProfileRequest,
    statusCode: getFullUserProfileStatusCode,
  } = useApi<UserProfile>();

  const {
    error: getConsentsInfoError,
    data: getConsentsInfoResponse,
    loading: isGetConsentsInfoLoading,
    sendRequest: getConsentsInfoRequest,
    statusCode: getConsentsInfoStatusCode,
  } = useApi<Consent[]>();

  const {
    error: getTagsInfoError,
    loading: isGetTagsInfoLoading,
    sendRequest: getTagsRequest,
  } = useApi<PatientTagsResponse>();

  const { data: isSafetyPlanExist, sendRequest: checkSafetyPlanExist } =
    useApi<SafetyPlanExistsResponse>();

  const { isPatientTagsFetched, patientsWithTreatmentTag, fetchPatientTags } =
    usePatientTags();

  const { setUserProfile: dispatchSetUserProfile, clearUserProfile } =
    useUserDispatch();
  useEffect(() => {
    if (isAuthenticated) {
      getBasicInfoRequest({
        command: ApiCommand.GET,
        url: getBasicInfoUrl,
      });

      getConsentsInfoRequest({
        command: ApiCommand.GET,
        url: getConsentsInfoUrl,
      });
    } else {
      setUserProfile(null);
      setPendingLegalDocuments(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (auth0Error) {
      Sentry.captureException(auth0Error.message);
    }
  }, [auth0Error]);

  useEffect(() => {
    if (userProfile?.externalId) connect(userProfile);
  }, [userProfile?.externalId]);

  useEffect(() => {
    if (userProfile?.email) {
      beaconIdentify(userProfile);
    }
  }, [userProfile?.email]);

  useEffect(() => {
    if (getFullUserProfileStatusCode === 200) {
      setUserProfile({
        ...userProfile,
        ...getFullUserProfileResponse,
      });
      dispatchSetUserProfile(getFullUserProfileResponse);

      const userIdentity = mapUserProfileToUserInfo(getFullUserProfileResponse);
      updateUserInfo(userIdentity);

      const isSupportWithPatients =
        !userProfile?.isPatient &&
        getFullUserProfileResponse?.linkedPatients?.length > 0;
      if (isSupportWithPatients) {
        fetchPatientTags(getFullUserProfileResponse);

        const linkedPatients = getFullUserProfileResponse.linkedPatients ?? [];
        const ids = linkedPatients.map((patient) => patient.externalId);

        checkSafetyPlanExist({
          command: ApiCommand.GET,
          options: { ids },
          url: urlConstants.safetyPlan.isExist,
        });
      }
      setIsFullProfileInfoFetched(true);
    } else if (getFullUserProfileError) {
      // eslint-disable-next-line no-console
      console.error(
        'Error getting full user profile info',
        getFullUserProfileError,
      );
      Sentry.captureException(getFullUserProfileError);
    }
  }, [
    getFullUserProfileError,
    getFullUserProfileResponse,
    getFullUserProfileStatusCode,
  ]);

  useEffect(
    () => {
      if (getBasicInfoStatusCode === 200 && getConsentsInfoStatusCode === 200) {
        getFullUserProfileRequest({
          command: ApiCommand.GET,
          url: getUserProfileUrl,
        });

        const { externalId, isPatient } = getBasicInfoResponse;
        const age = getAgeFromProfile(getBasicInfoResponse);
        identify({
          age,
          role: isPatient ? 'Patient' : 'Support',
          userId: externalId,
        });

        if (getBasicInfoResponse.isPatient) {
          Sentry.setUser({ id: getBasicInfoResponse.externalId });
          getTagsRequest({
            command: ApiCommand.GET,
            url: getTagsUrl(getBasicInfoResponse.externalId),

            callback: (result, statusCode) => {
              if (statusCode === 200 && result.status === 'OK') {
                const inTreatment = result.data.includes(
                  PATIENT_TAGS.EquipAppTreatment,
                );

                dispatchSetUserProfile(getBasicInfoResponse);
                setUserProfile({
                  ...getBasicInfoResponse,
                  isInTreatment: inTreatment,
                });
              } else if (getTagsInfoError) {
                Sentry.captureException(getTagsInfoError);
                setErrors([...errors, getTagsInfoError]);
              }
            },
          });
          checkSafetyPlanExist({
            command: ApiCommand.GET,
            options: {
              ids: getBasicInfoResponse.externalId,
            },
            url: urlConstants.safetyPlan.isExist,
          });
        } else {
          setUserProfile(getBasicInfoResponse);
          dispatchSetUserProfile(getBasicInfoResponse);
        }

        setChatEmergencyConsentInLocalStorage(
          (getConsentsInfoResponse ?? [])
            .map((consent) => consent?.consentName)
            .includes(CHAT_EMERGENCY_CONSENT.apiPropValue),
        );

        const pendingLegalDocuments = getPendingLegalDocuments(
          getConsentsInfoResponse,
        );

        setPendingLegalDocuments(pendingLegalDocuments);
      } else if (getBasicInfoError || getConsentsInfoError) {
        // eslint-disable-next-line no-console
        console.error(
          'Error getting basic info',
          getBasicInfoError,
          getConsentsInfoError,
        );
        if (getBasicInfoError) {
          Sentry.captureException(getBasicInfoError);
          setErrors([...errors, getBasicInfoError]);
        }

        if (getConsentsInfoError) {
          Sentry.captureException(getConsentsInfoError);
          setErrors([...errors, getConsentsInfoError]);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getBasicInfoError,
      getBasicInfoResponse,
      getBasicInfoStatusCode,
      getConsentsInfoError,
      getConsentsInfoResponse,
      getConsentsInfoStatusCode,
    ],
  );

  useEffect(() => {
    if (isSafetyPlanExist?.status === 'OK') {
      const patientIDWithSafetyPlan: string[] = [];
      Object.entries(isSafetyPlanExist.data).forEach(([key, value]) => {
        if (value) {
          patientIDWithSafetyPlan.push(key);
        }
      });

      if (patientIDWithSafetyPlan?.length === 0) {
        setIsSafetyPlanEnabled(false);
        return;
      }

      if (userProfile?.isPatient && patientIDWithSafetyPlan?.length === 1) {
        setSelectedPatientForSafetyPlan(patientIDWithSafetyPlan[0]);
        setIsSafetyPlanEnabled(true);
        return;
      }

      const proxyPatientsWithSafetyPlan = userProfile?.linkedPatients?.filter(
        (patient) => {
          const hasSafetyPlan = patientIDWithSafetyPlan.includes(
            patient.externalId,
          );

          return hasSafetyPlan && patient.isProxy;
        },
      );

      setIsSafetyPlanEnabled(proxyPatientsWithSafetyPlan?.length > 0);

      if (proxyPatientsWithSafetyPlan?.length > 1) {
        setPatientsCompletedSafetyPlan(proxyPatientsWithSafetyPlan);
      } else if (proxyPatientsWithSafetyPlan?.length === 1) {
        setSelectedPatientForSafetyPlan(
          proxyPatientsWithSafetyPlan[0].externalId,
        );
      }
    }
  }, [isSafetyPlanExist?.status, userProfile?.linkedPatients]);

  const isLoading =
    isGetBasicInfoLoading || isGetConsentsInfoLoading || isGetTagsInfoLoading;

  const isUserProfileLoading =
    isGetBasicInfoLoading || isGetConsentsInfoLoading;

  const updateBasicInfo = (chosenName?: string) => {
    const updatedProfile: UserProfile = {
      ...userProfile,
      chosenName,
    };
    setUserProfile(updatedProfile);
  };

  const updateIsSafetyPlanOpen = (open: boolean) => {
    setIsSafetyPlanOpen(open);
  };

  const updateIsLinkedPatientModalOpen = (open: boolean) => {
    setIsLinkedPatientModalOpen(open);
  };
  const updateSelectedPatientForSafetyPlan = (externalId: string) => {
    setSelectedPatientForSafetyPlan(externalId);
  };

  const fetchFullUserProfile = () => {
    setIsFullProfileInfoFetched(false);
    getFullUserProfileRequest({
      command: ApiCommand.GET,
      url: getUserProfileUrl,
    });
  };

  const logout = () => {
    auth0Logout({ logoutParams: { returnTo: window.location.origin } });
    clearUserProfile();
    disconnect();
    reset();
  };

  const onNotificationSettingsUpdate = () => {
    setUserProfile({
      ...userProfile,
      preferencesLastUpdatedBy: userProfile.externalId,
    });
  };

  return {
    errors,
    fetchFullUserProfile,
    isBasicInfoLoading: isLoading,
    isFullProfileInfoFetched,
    isFullProfileInfoLoading: isGetFullUserProfileLoading,
    isLinkedPatientModalOpen,
    isPatientTagsFetched,
    isSafetyPlanEnabled,
    isSafetyPlanOpen,
    isUserProfileLoading,
    logout,
    onNotificationSettingsUpdate,
    patientsCompletedSafetyPlan,
    patientsWithTreatmentTag,
    pendingLegalDocuments,
    selectedPatientForSafetyPlan,
    setPendingLegalDocuments,
    updateBasicInfo,
    updateIsLinkedPatientModalOpen,
    updateIsSafetyPlanOpen,
    updateSelectedPatientForSafetyPlan,
    userProfile,
  };
};

export default useUserProfile;
