import { FC } from 'react';

import { ProviderTeamMemberCard, SimpleCardGrid } from '@equip.health/ui';

interface ProviderListItemProps {
  careTeam: CareTeamProvider[];
}
const ProviderListItem: FC<ProviderListItemProps> = ({ careTeam }) => {
  return (
    <SimpleCardGrid id="provider-team">
      {careTeam?.map((provider) => (
        <ProviderTeamMemberCard
          id={provider.providerExternalId}
          key={provider.providerExternalId}
          name={`${provider.firstName} ${provider.lastName}`}
          role={provider.providerTypeName}
        />
      ))}
    </SimpleCardGrid>
  );
};

export default ProviderListItem;
