import { FC } from 'react';

import { ChakraFlex, EquipUIFireflyV2Theme, Text } from '@equip.health/ui';

import { ERROR_MESSAGES } from '~/lib/constants/patientDocuments.constants';
import { removeUUIDFromFilename } from '~/lib/util/chat/chat.utils';
import { getFileExtension } from '~/lib/util/file.util';

import {
  FileAttachmentThumbnailView,
  LoaderOverlay,
  RemoveFileButton,
} from './Common';

const { neutral } = EquipUIFireflyV2Theme.colors;

interface FileAttachmentTileProps {
  fileName: string;
  handleRemoveFile?: () => void;
  isEditable?: boolean;
  isValidFile?: boolean;
  isUploading?: boolean;
  isValidatingFile?: boolean;
  topLevelId: string;
}

const FileAttachmentTile: FC<FileAttachmentTileProps> = ({
  fileName,
  handleRemoveFile,
  isEditable = false,
  isUploading = false,
  topLevelId,
  isValidFile = true,
  isValidatingFile = false,
}) => {
  const shouldShowLoadingOverlay = isUploading;
  const shouldShowRemoveButton = !isUploading && isEditable && handleRemoveFile;
  const shouldShowInvalidFileView = !isValidFile;

  return (
    <ChakraFlex
      _hover={{
        background:
          isEditable || shouldShowInvalidFileView
            ? 'neutral.background.secondary'
            : 'neutral.line',
      }}
      background={isEditable ? 'white' : 'neutral.background.secondary'}
      border={`1px solid ${neutral.line}`}
      borderRadius="10px"
      height="60px"
      id={`${topLevelId}__${fileName}`}
      padding="12px"
      position="relative"
      width={isEditable ? '192px' : '271px'}
    >
      <ChakraFlex marginRight="12px" minWidth="40px">
        <FileAttachmentThumbnailView
          fileName={fileName}
          isInvalidFile={shouldShowInvalidFileView}
          isValidatingFile={isValidatingFile}
        />
      </ChakraFlex>

      <ChakraFlex
        flexDirection="column"
        id={`${topLevelId}__file_name__${fileName}`}
        justifyContent="space-between"
        overflow="hidden"
      >
        <Text color="black.90" isTruncated variant="captionMedium">
          {removeUUIDFromFilename(fileName)}
        </Text>
        {shouldShowInvalidFileView ? (
          <>
            <Text
              color="black.60"
              id={`${topLevelId}__file_extension__${fileName}`}
              variant="caption"
            >
              {ERROR_MESSAGES.validationFailed}
            </Text>
          </>
        ) : (
          <Text
            color="black.60"
            id={`${topLevelId}__file_extension__${fileName}`}
            variant="caption"
          >
            {isValidatingFile
              ? 'Processing...'
              : getFileExtension(fileName).toUpperCase()}
          </Text>
        )}
      </ChakraFlex>
      {shouldShowLoadingOverlay && <LoaderOverlay />}
      {shouldShowRemoveButton && (
        <RemoveFileButton onClick={handleRemoveFile} />
      )}
    </ChakraFlex>
  );
};

export default FileAttachmentTile;
