import { FC } from 'react';

import { Text } from '@equip.health/ui';
import isEmpty from 'lodash/isEmpty';

import DocumentPreviewCard from '~/components/chat/DocumentPreviewCard';

interface DocumentListProps {
  documents: PatientDocument[];
}

const DocumentList: FC<DocumentListProps> = ({ documents }) => {
  return (
    <>
      {documents.map((document) => {
        return (
          <DocumentPreviewCard
            document={document}
            key={document.documentExternalId}
          />
        );
      })}
      {isEmpty(documents) && (
        <Text
          color="neutral.secondary"
          id="patient-document-list__empty-list"
          marginBottom="24px"
          variant="caption"
        >
          No files here yet
        </Text>
      )}
    </>
  );
};

export default DocumentList;
