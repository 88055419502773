import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UserSliceState {
  userId: string;
  isPatient: boolean;
  isSupport: boolean;
  linkedPatients: UserProfile[];
  proxyLinkedPatients: UserProfile[];
  proxyInTreatmentPatients: UserProfile[];
  inTreatmentPatients: UserProfile[];
}

const initialState: UserSliceState = {
  inTreatmentPatients: [],
  isPatient: false,
  isSupport: false,
  linkedPatients: [],
  proxyInTreatmentPatients: [],
  proxyLinkedPatients: [],
  userId: undefined,
};

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    clearUserProfile: () => {
      return initialState;
    },
    setLinkedPatients: (state, action: PayloadAction<UserProfile[]>) => {
      return {
        ...state,
        linkedPatients: action.payload,
      };
    },
    setUserProfile: (state, action: PayloadAction<UserProfile | undefined>) => {
      const { externalId, isPatient, linkedPatients } = action.payload;
      return {
        ...state,
        isPatient,
        isSupport: !isPatient,
        linkedPatients,
        userId: externalId,
      };
    },
  },
});

export const { setUserProfile, clearUserProfile } = userSlice.actions;
export default userSlice.reducer;
