import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Ajax } from '@newrelic/browser-agent/features/ajax';
import { JSErrors } from '@newrelic/browser-agent/features/jserrors';
import { Metrics } from '@newrelic/browser-agent/features/metrics';
import { PageViewEvent } from '@newrelic/browser-agent/features/page_view_event';
import { SessionTrace } from '@newrelic/browser-agent/features/session_trace';
import { Spa } from '@newrelic/browser-agent/features/spa';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

import {
  VITE_MYEQUIP_APP_VERSION,
  VITE_NR_ACCOUNT_ID,
  VITE_NR_AGENT_ID,
  VITE_NR_ALLOWED_ORIGIN,
  VITE_NR_APPLICATION_ID,
  VITE_NR_LICENSE_KEY,
  VITE_NR_TRUST_KEY,
} from '~/lib/constants/env';

const options = {
  init: {
    distributed_tracing: {
      enabled: true,
      cors_use_newrelic_header: true,
      cors_use_tracecontext_headers: true,
      allowed_origins: [VITE_NR_ALLOWED_ORIGIN],
    },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  }, // from NREUM.init
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: VITE_NR_LICENSE_KEY,
    applicationID: VITE_NR_APPLICATION_ID,
    sa: 1,
  }, // from NREUM.info
  loader_config: {
    accountID: VITE_NR_ACCOUNT_ID,
    trustKey: VITE_NR_TRUST_KEY,
    agentID: VITE_NR_AGENT_ID,
    licenseKey: VITE_NR_LICENSE_KEY,
    applicationID: VITE_NR_APPLICATION_ID,
  }, // from NREUM.loader_config
  features: [Metrics, Spa, SessionTrace, PageViewEvent, JSErrors, Ajax],
};

type NewRelicContextType = {
  newRelicAgent: BrowserAgent | null;
  setCurrentUserId: (userId: string) => void;
};

const NewRelicContext = createContext<NewRelicContextType | undefined>(
  undefined,
);

interface NewRelicProviderProps {
  children: ReactElement | ReactElement[];
}

export const NewRelicProvider: FC<NewRelicProviderProps> = ({ children }) => {
  const [newRelicAgent, setNewRelicAgent] = useState<BrowserAgent>(null);

  useEffect(() => {
    const newAgent = new BrowserAgent(options);
    newAgent.addRelease('My Equip App Version', VITE_MYEQUIP_APP_VERSION);
    setNewRelicAgent(newAgent);
  }, []);

  const setCurrentUserId = (userId: string) => {
    newRelicAgent?.setUserId(userId);
  };

  return (
    <NewRelicContext.Provider value={{ newRelicAgent, setCurrentUserId }}>
      {children}
    </NewRelicContext.Provider>
  );
};

export const useNewRelic = (): NewRelicContextType => {
  const context = useContext(NewRelicContext);
  if (!context) {
    throw new Error('useNewRelic must be used within a NewRelicProvider');
  }
  return context;
};
